import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlanningEquipesSupplier} from './PlanningEquipesSupplier';
import {UtilsService} from '../../../../core/utils/utils.service';
import {TYPE_TACHE} from '../../../../core/services/entities/type-tache.service';
import {MJour} from '../../../../core/models/gestion-unites-production/m-jour';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PlanningEquipesService} from '../../../../core/services/gestion-unites-productions/planning-equipes.service';
import {JoursDecalageSupplier} from './jours-decalage/JoursDecalageSupplier';
import {MJourDecalage} from '../../../../core/models/gestion-unites-production/m-jour-decalage';
import {UniteDeProduction_Planning_EquipeDTO} from '../../../../core/dtos/unite_de_production__planning__equipe_d_t_o';
import {DATAGRID_ROW_TYPES} from '../../../../core/services/technique/devextreme.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, UI_COLORS} from '../../../../core/constants';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {DxDataGridComponent} from 'devextreme-angular';

@Component({
  selector: 'yo-planning-equipes',

  templateUrl: './planning-equipes.component.html',
  styleUrls: ['./planning-equipes.component.scss'],
})
export class PlanningEquipesComponent implements OnInit, OnDestroy {


  @ViewChild('planningEquipesTable') planningEquipesTable: DxDataGridComponent;

  subRoute: Subscription;

  pes: PlanningEquipesSupplier;


  cols: any[] = [
    {field: 'equipeLibelle', header: 'Équipe'},
    {field: 'lundi', header: 'Lundi'},
    {field: 'mardi', header: 'Mardi'},
    {field: 'mercredi', header: 'Mercredi'},
    {field: 'jeudi', header: 'Jeudi'},
    {field: 'vendredi', header: 'Vendredi'},
    {field: 'samedi', header: 'Samedi'},
    {field: 'dimanche', header: 'Dimanche'},
  ];


  joursDecalageLundi: MJourDecalage[] = [];
  joursDecalageMardi: MJourDecalage[] = [];
  joursDecalageMercredi: MJourDecalage[] = [];
  joursDecalageJeudi: MJourDecalage[] = [];
  joursDecalageVendredi: MJourDecalage[] = [];
  joursDecalageSamedi: MJourDecalage[] = [];
  joursDecalageDimanche: MJourDecalage[] = [];

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-planning-equipes';

  constructor(public utils: UtilsService, private route: ActivatedRoute, public planningEquipesSvc: PlanningEquipesService) {

  }

  routeSubscription() {
    this.subRoute = this.route.data.subscribe((data: { planningEquipesSupplier: PlanningEquipesSupplier }) => {
      this.pes = data.planningEquipesSupplier;

      if (!this.utils.isNullOrEmpty(this.pes)) {

        this.joursDecalageLundi = this.planningEquipesSvc.getJourDecalageList(1, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);
        this.joursDecalageMardi = this.planningEquipesSvc.getJourDecalageList(2, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);
        this.joursDecalageMercredi = this.planningEquipesSvc.getJourDecalageList(3, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);
        this.joursDecalageJeudi = this.planningEquipesSvc.getJourDecalageList(4, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);
        this.joursDecalageVendredi = this.planningEquipesSvc.getJourDecalageList(5, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);
        this.joursDecalageSamedi = this.planningEquipesSvc.getJourDecalageList(6, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);
        this.joursDecalageDimanche = this.planningEquipesSvc.getJourDecalageList(7, this.pes.maxJoursDecalage, this.pes.cols, this.pes.sensJourDecalage);

      }

    });
  }





  ngOnInit(): void {

    // chargement du planning
    this.routeSubscription();


  }

  getCell(row: UniteDeProduction__EquipeDTO, col: MJour, typeTache: TYPE_TACHE) {

    return this.pes.cells[col.idJourSemaine + '-' + row.id + '-' + typeTache];

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }


  getJoursDecalageSupplier(jour: number, nbJourDecalage: number) {


    const selectedJourDecalage = new MJourDecalage('todo', nbJourDecalage);
    let jourDecalageList = [];
    switch (jour) {
      case 1 :
        jourDecalageList = this.joursDecalageLundi;
        break;
      case 2 :
        jourDecalageList = this.joursDecalageMardi;
        break;
      case 3 :
        jourDecalageList = this.joursDecalageMercredi;
        break;
      case 4 :
        jourDecalageList = this.joursDecalageJeudi;
        break;
      case 5 :
        jourDecalageList = this.joursDecalageVendredi;
        break;
      case 6 :
        jourDecalageList = this.joursDecalageSamedi;
        break;
      case 7 :
        jourDecalageList = this.joursDecalageDimanche;
        break;

    }

    return new JoursDecalageSupplier(selectedJourDecalage, jourDecalageList);

  }

  changeJoursDecalage(cell: UniteDeProduction_Planning_EquipeDTO, cells: Map<string, UniteDeProduction_Planning_EquipeDTO>, $event: MJourDecalage) {

    cell.joursDecalage = $event.nbJoursDecalage;

  }


  onCellPrepared(event: any) {

    this.cellActiveBackground(event);
  }

  onCellClick(event: any) {

    this.cellActiveBackground(event);

  }

  cellActiveBackground(event: any) {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      const cell = this.getCell(event.row.data, event.column.dataField, this.pes.typeTache);
      if (cell) {
        if (cell.actif) {
          event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        } else {
          event.cellElement.style.backgroundColor = UI_COLORS.NOT_CHECKED;
        }
      }
    }
  }

  onRowPrepared(event: any) {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.lineHeight = '40px';
    }
  }

  getTooltipSavePlanning(typeTache: TYPE_TACHE) {
    return `'Enregistrer le planning des équipes  ${this.getEquipeTypeTache(typeTache)}`;
  }

  getEquipeTypeTache(typeTache: TYPE_TACHE) {

    let message = '';

    switch (typeTache) {
      case TYPE_TACHE.LIVRAISON:
        message += 'de livraison';
        break;
      case TYPE_TACHE.SORTIE:
        message += 'de sorties de production';
        break;
      case TYPE_TACHE.FABRICATION:
        message += 'de fabrication';
        break;
      case TYPE_TACHE.CONDITIONNEMENT:
        message += 'de conditionnement';
        break;
      case TYPE_TACHE.APPROVISIONNEMENT:
        message += `d'approvisionnement`;
        break;
      case TYPE_TACHE.PREPA_LIVRAISON:
        message += 'de préparation de la livraison';
        break;

    }

    return message;
  }

  getSavedSuccessMessage(typeTache: TYPE_TACHE) {
    return `Le planning des équipes ${this.getEquipeTypeTache(typeTache)} a été enregistré avec succès.`;
  }

  savePlanning() {

    this.planningEquipesSvc.savePlanning(this.pes.cells)
      .subscribe(response => {
        if (response && !this.utils.isResponseSupplierError(response)) {
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, this.getSavedSuccessMessage(this.pes.typeTache));
        }
      });
  }

  getHelp(typeTache: TYPE_TACHE) {

    let dms = new DialogMsgSupplier();

    dms.title = `Planning des équipes ${this.getEquipeTypeTache(typeTache)}`;

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Les jours représentent les jours de ${this.getTypeJour(typeTache)}`;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: [p1]

    };

    return dms;
  }


  getTypeJour(typeTache: TYPE_TACHE) {

    let typeJour = '';

    switch (typeTache) {

      case TYPE_TACHE.PREPA_LIVRAISON:
        typeJour = 'livraison';
        break;
      case TYPE_TACHE.APPROVISIONNEMENT:
        typeJour = 'sortie';
        break;
      case TYPE_TACHE.CONDITIONNEMENT:
        typeJour = 'fabrication';
        break;
      case TYPE_TACHE.FABRICATION:
        typeJour = 'consommation';
        break;
      case TYPE_TACHE.SORTIE:
        typeJour = 'fabrication';
        break;
      case TYPE_TACHE.LIVRAISON:
        typeJour = 'livraison';
        break;
    }


    return typeJour;

  }


  cellActive(cell: UniteDeProduction_Planning_EquipeDTO, cells: Map<string, UniteDeProduction_Planning_EquipeDTO>) {
    cell.actif = !cell.actif;
  }
}
