import {Component, OnInit} from '@angular/core';
import {SecteursService} from '../../core/services/entities/secteurs.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-secteurs',
  templateUrl: './secteurs.component.html',
  styleUrls: ['./secteurs.component.scss']
})
export class SecteursComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/secteurs';

  constructor(public secteursSvc:SecteursService) { }

  ngOnInit() {
  }

}
