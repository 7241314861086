import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RoleDTO} from './dtos/role-dto';
import {HttpClient} from '@angular/common/http';
import {catchError, finalize} from "rxjs/operators";
import {UtilsService} from './utils/utils.service';

@Injectable()
export class RoleService {

  constructor(private http: HttpClient,
              private utils: UtilsService) { }

  /**
   * cf. RoleController#findAllActifs();
   * @returns {Observable<RoleDTO[]>}
   */
  findAllRolesActifs() {
    return this.http.get<RoleDTO[]>('dolrest/roles/findAllActifs').pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }
}
