<p-table [columns]="cols"
         [value]="appellationsSupplier.appellations"
         [totalRecords]="totalRecords"
         [scrollHeight]="utils.scrollHeightContent(204)"
         styleClass="p-datatable-sm"
         [scrollable]="true"
         dataKey="id"
         #dt>

  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

        <col *ngSwitchCase="'appellation.libelle'" [style.min-width.px]="300" [style.max-width.px]="300"
             [style.text-align]="'left'">
        <col *ngSwitchCase="'appellation.logo'" [style.min-width.px]="80" [style.max-width.px]="80"
             [style.text-align]="'center'">
        <col *ngSwitchDefault [style.min-width.px]="200" [style.max-width.px]="200" [style.text-align]="'center'">

      </ng-container>

    </colgroup>
  </ng-template>


  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn">
        <ng-container [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'appellation.libelle'">
            <th [style.min-width.px]="300" [style.max-width.px]="300">
              <p-checkbox class="mg-r-5" [(ngModel)]="selectAll" (onChange)="updateSelectAll($event)"
                          label=""
                          [disabled]="!gds.canModify(produit)"
                          binary="true"></p-checkbox>
              {{col.header}}
            </th>
          </ng-container>


          <ng-container *ngSwitchCase="'appellation.logo'">
            <th [style.min-width.px]="80" [style.max-width.px]="80">
              {{col.header}}
            </th>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <th [style.min-width.px]="200" [style.max-width.px]="200">
              {{col.header}}
            </th>
          </ng-container>

        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <ng-container *ngFor="let col of columns;  trackBy:utils.trackByFn">

        <ng-container [ngSwitch]="col.field">

          <!--APPELLATIONS-->
          <ng-container *ngSwitchCase="'appellation.libelle'">
            <td style="min-width:300px;max-width:300px;">
              <p-checkbox [ngModel]="getActiveRow(rowData.id)"
                          class="mg-r-5"
                          (ngModelChange)="setActiveRow(rowData.id,$event)"
                          [disabled]="!gds.canModify(produit)"
                          binary="true"></p-checkbox>

              <strong>{{rowData.libelle | uppercase}}</strong>
            </td>
          </ng-container>


          <!--LOGO-->
          <ng-container *ngSwitchCase="'appellation.logo'">
            <td [style.min-width.px]="80" [style.max-width.px]="80">
              <yo-img-entity [displayNoImage]="true"
                             format="thumb"
                             [entityId]="rowData.id"
                             [entityName]="appellationEntityName"
                             [refresh]="refreshImg"
                             [openable]="true"
                             [ordre]="1"
                             [height]="32"
                             [title]="rowData.libelle"
                             class="mg-r-5"
              ></yo-img-entity>
            </td>
          </ng-container>

          <!--DÉCLINAISONS-->
          <ng-container *ngSwitchDefault>
            <td [style.min-width.px]="200" [style.max-width.px]="200">
              <div class="text-center">
                <p-checkbox [ngModel]="getActiveProduitAppellation(rowData.id,col.field)"
                            (ngModelChange)="setActiveProduitAppellation(rowData.id,col.field,$event)"
                            [disabled]="!gds.canModify(produit)"
                            binary="true">
                </p-checkbox>
              </div>
            </td>
          </ng-container>

        </ng-container>

      </ng-container>
    </tr>
  </ng-template>

</p-table>


<div class="d-flex mg-t-5">
  <div class="mr-auto">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <em>Sélectionner les appellations...</em>
  </div>
  <div class="mg-r-5">
    <button pButton icon="fas fa-save" (click)="save()" [disabled]="!canModify()" pTooltip="Enregistrer"></button>
  </div>
  <div>
    <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="utils.sidenav=false"
            class="p-button-secondary"></button>
  </div>
</div>

