<p-card>
  <div class="card-size">

    <dx-data-grid
      [dataSource]="gridParametrage"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(382)"
      width="100%"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="130"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="Variante" [width]="130"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column *ngFor="let declinaison of declinaisonList"
                  dataField="declinaison.id"
                  cellTemplate="declinaisonCellTemplate"
                  [width]="400"
                  [caption]="declinaison.libelle">
      </dxi-column>
      <dxi-column dataField="tacheLabel" caption="Type de conditionnement"
                  [width]="250"
                  [allowFiltering]="false">
      </dxi-column>
      <dxi-column dataField="remarque" caption="Commentaires"
                  [width]="200"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          *ngFor="let declinaison of declinaisonList"
          name="SelectedRowsSummary"
          showInColumn="declinaison">
        </dxi-total-item>
      </dxo-summary>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} conditionnements">
      </dxo-pager>

      <div *dxTemplate="let cell of 'declinaisonCellTemplate'">

        <div *ngIf="mode === 'NOURRI_UN_CONVIVE'">
          <div>Effectif nourri : {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].effectifNourri }}</div>
          <div>Poids net : {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].poidsNet }} Kg(s)</div>
        </div>

        <div *ngIf="mode === 'NE_NOURRI_PAS_UN_CONVIVE'">
          <div>Effectif nourri : {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].effectifNourri }}</div>
          <div>{{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].contient }}
            {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].uniteAConditionner }} nourri(ssent) {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].effectifNourri }} convive(s)</div>
          <div>Poids net : {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].poidsNet }} Kg(s)</div>
        </div>

        <div *ngIf="mode === 'QUANTITE'">
          <div>Effectif nourri : {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].effectifNourri }}</div>
          <div>Poids net : {{ gridGenerated[mappingColumnsDeclinaisons[cell.columnIndex]][cell.row.data.id].poidsNet }} Kg(s)</div>
        </div>
      </div>

    </dx-data-grid>

    <dx-data-grid
      [dataSource]="[footerList]"
      height="100px"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="conditionnementLabel" caption="" [width]="130">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="" [width]="130">
      </dxi-column>
      <dxi-column *ngFor="let declinaison of declinaisonList"
                  cellTemplate="queueCellTemplate"
                  [width]="300"
                  [caption]="declinaison.libelle">
      </dxi-column>
      <dxi-column dataField="tache" caption="" [width]="250">
      </dxi-column>
      <dxi-column dataField="remarque" caption="" [width]="200">
      </dxi-column>

      <div *dxTemplate="let cell of 'queueCellTemplate'">
        {{ queuesConfiguration[mappingColumnsDeclinaisons[cell.columnIndex]] && queuesConfiguration[mappingColumnsDeclinaisons[cell.columnIndex]].resteLibelle ? queuesConfiguration[mappingColumnsDeclinaisons[cell.columnIndex]].resteLibelle : '' }}
      </div>

    </dx-data-grid>

  </div>
</p-card>
