<div *ngIf="displayProfil && calculerCoutGlobal$ | async">
  <div class="row mg-t-10">

    <div class="col-md-auto">

      <!--COUT MOYEN PERIODE-->
      <div class="mg-r-5 font-12" pTooltip="Coût Moyen {{libelleRepas}} sur la période" showDelay="500">CM
        = {{coutMoyenPeriodeRepas | currency:'EUR':'symbol':'1.4-4' }}</div>

      <!--COUT UNITAIRE PERIODE-->
      <ng-container *ngTemplateOutlet="tplCoutUnitaire;context:{
          coutUnitairePeriodeRepas:coutUnitairePeriodeRepas,
          coutTheoriquePeriodeRepas:coutTheoriquePeriodeRepas
      }">
      </ng-container>

      <!--COUT THEORIQUE PERIODE-->
      <div class="mg-r-5 font-12" pTooltip="Coût Théorique {{libelleRepas}} sur la période" showDelay="500">CT
        = {{coutTheoriquePeriodeRepas | currency:'EUR':'symbol':'1.4-4' }}</div>

      <!--COUT GLOBAL PERIODE-->
      <div class="mg-r-5 font-12" pTooltip="Coût Global {{libelleRepas}} sur la période" showDelay="500">CG
        = {{coutGlobalPeriodeRepas | currency:'EUR':'symbol':'1.4-4' }}</div>

    </div>

  </div>

</div>


<!--TEMPLATE COUT UNITAIRE affiche en rouge si cout unitaire > cout théroique-->
<ng-template #tplCoutUnitaire let-cu="coutUnitairePeriodeRepas" let-ct="coutTheoriquePeriodeRepas">
  <ng-container *ngIf="cu>ct">
    <div class="mg-r-5 font-12" [class.danger-color]="true" pTooltip="Attention, coût unitaire > coût repas"
         showDelay="500">CU = {{coutUnitairePeriodeRepas | currency:'EUR':'symbol':'1.4-4' }}</div>
  </ng-container>
  <ng-container *ngIf="cu<=ct">
    <div class="mg-r-5 font-12"
         [class.new-color]="true"
         pTooltip="Coût Unitaire {{libelleRepas}} sur la période" showDelay="500">CU
      = {{coutUnitairePeriodeRepas | currency:'EUR':'symbol':'1.4-4' }}</div>
  </ng-container>
</ng-template>
