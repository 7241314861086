import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {WorkflowInstanceDTO} from '../../../../core/dtos/workflow-instance-dto';
import {PlanProductionDTO} from '../../../../core/dtos/plan-production-dto';
import {ProductionService} from '../../../../core/services/gestion-production/production.service';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {WORKFLOW_TASKS} from '../../../../core/constants';
import {WorkflowsService} from '../../../../core/services/entities/workflows.service';
import {WorkflowDto} from '../../../../core/dtos/workflow-dto';
import {find as _find} from 'lodash';
import {UtilsService} from '../../../../core/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class GridDetailsPlanProductionResolverService implements Resolve<DetailsSupplier> {

  constructor(
    private prodSvc:ProductionService,
    private workflowSvc:WorkflowsService,
    private utils: UtilsService){
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DetailsSupplier> | Promise<DetailsSupplier> | DetailsSupplier => {

    let ds = new DetailsSupplier();
    const idWorkflowInstance: number = +route.parent.paramMap.get('idWorkflowInstance');

    const workflowInstance= new WorkflowInstanceDTO();
    workflowInstance.id=idWorkflowInstance;

    const planProd$ =  this.prodSvc.getPlanProd(workflowInstance);
    const equipeList$ = this.prodSvc.getEquipeList();
    const workflows$ = this.workflowSvc.getWorkflows([WORKFLOW_TASKS.SORTIES_PRODUCTION]);
    const hasNextStep$ = this.workflowSvc.hasNextStep(idWorkflowInstance,WORKFLOW_TASKS.SORTIES_PRODUCTION);
    const workflowInstance$ = this.workflowSvc.getWorkflowInstanceById(idWorkflowInstance);

    const all$ = combineLatest([planProd$,equipeList$,workflows$, hasNextStep$, workflowInstance$]);

    return all$.toPromise().then(response=>{

      ds.planProd = response[0].one;
      ds.udpEquipeList = response[1].resultList;
      const workflows = response[2].resultList ;
      ds.hasWkfSortiesProduction = this.hasWkfSortiesProduction(ds.planProd.workflowInstance,workflows);
      ds.hasNextStepsSortiesProduction = response[3].one;
      ds.workflowInstance = response[4].one;

      return ds;
    });
  };

  /**
   * Est ce que le workflowInstance fait partie d'un workflow qui a une tâche sortie de production ?
   * @param wi
   * @param workflows
   */
  private hasWkfSortiesProduction = (wi:WorkflowInstanceDTO,workflows:WorkflowDto[]) => {

    if(!this.utils.isCollectionNullOrEmpty(workflows)){
      const wkf= _find(workflows,{'id':wi.workflowId});
      if(wkf){
        return true;
      }
    }
    return false;
  };

}

export class DetailsSupplier {
  planProd:PlanProductionDTO;
  udpEquipeList: UniteDeProduction__EquipeDTO[] = [];
  hasWkfSortiesProduction: boolean;
  hasNextStepsSortiesProduction: boolean;
  workflowInstance: WorkflowInstanceDTO;
}
