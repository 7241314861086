import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {UtilsService} from '../../core/utils/utils.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {ParametresService} from '../../core/services/entities/parametres.service';
import {MessageService} from 'primeng/api';
import {MSG_KEY, MSG_SEVERITY} from '../../core/constants';

@Component({
  selector: 'yo-parametres',
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.scss']
})
export class ParametresComponent implements OnInit {

  form: FormGroup;
  refresh = 1;

  constructor(public utils: UtilsService,
              private msgSvc: MessageService,
              private gfs: GenericFormService,
              private parametresSvc: ParametresService) {
  }

  initForm = () => {

    this.form = new FormGroup({
      background: new FormControl(undefined),
      logo:new FormControl(undefined)
    });
  };

  ngOnInit() {

    this.initForm();

  }

  onChangeImage = (event, formControlName:string) => {
    console.log('onChangeImage');
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.form.controls[formControlName].setValue(file);
    }
  };


  save = () => {

    this.gfs.validateAllFormFields(this.form);

    if (this.form.valid) {

      this.parametresSvc.saveImages(this.form.controls['background'].value, this.form.controls['logo'].value).subscribe(response => {
        this.refresh = new Date().getTime();
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,`Fond d'écran et logo enregistré avec succès, veuillez recharger la page (touche F5) pour afficher la modification.`,);
      });

    }

  };


}
