import {DeclinaisonDTO} from "../../dtos/declinaison-dto";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/index";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";

export const URL_FIND_ALL = 'dolrest/declinaisons/findBySitesOfUser';
export const URL_FIND_ALL_FABRIQUE = 'dolrest/declinaisons/findBySitesOfUserAndFabrique';
export const URL_FIND_BY_PARAMS = 'dolrest/declinaisons/findAll';

@Injectable({
  providedIn: 'root'
})
export class DeclinaisonsService {

  constructor(private httpSvc: HttpService) { }

  findAll = (): Observable<ResponseWrapper<DeclinaisonDTO>> => this.httpSvc.get(URL_FIND_ALL);

  findAllFabrique = (): Observable<ResponseWrapper<DeclinaisonDTO>> => this.httpSvc.get(URL_FIND_ALL_FABRIQUE);

  findAllByParams = (isFabrique: boolean = true): Observable<ResponseWrapper<DeclinaisonDTO>> => this.httpSvc.get(`${URL_FIND_BY_PARAMS}?isFabrique=${isFabrique}`);

}
