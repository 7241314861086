<div class="d-flex mg-b-10">

  <div class="mg-r-5">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
  </div>

  <div class="mg-r-5">
    <button pButton
            label="AJOUTER"
            icon="fa fa-plus"
            [pTooltip]="'Ajouter un nouveau membre au groupe'"
            (click)="openAddUniteDeProduction()"
            [disabled]="!canAdd()">
    </button>

  </div>

  <div class="mg-r-5">
    <button pButton icon="fas fa-cog rotate-1-turn"
            [disabled]="utils.isCollectionNullOrEmpty(selectedMembers)"
            pTooltip="Appliquer la mise à jour des prix et des articles preferes"
            (click)="applyMajPrix(groupeAchat,selectedMembers)">
    </button>
  </div>

  <div class="mg-r-5">
    <button pButton icon="fas fa-trash "
            [disabled]="utils.isCollectionNullOrEmpty(selectedMembers)"
            pTooltip="Enlever des membres"
            (click)="removeMembers(selectedMembers)">
    </button>
  </div>

</div>

<dx-data-grid
  [dataSource]="membreGroupeAchatList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(180)"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [(selectedRowKeys)]="selectedMembers"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="uniteDeProduction.site" alignment="left" caption="Site"
              [allowFiltering]="false" [allowSorting]="false"
              cellTemplate="siteCellTemplate">
  </dxi-column>
  <dxi-column dataField="uniteDeProduction.libelle" caption="Unité de production" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="uniteDeProduction.code" caption="Code" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="traitementDate" caption="Date de traitement" cellTemplate="traitementDateCellTemplate" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="traitementLancePar" caption="Lancé par" [allowFiltering]="true">
  </dxi-column>

  <dxo-selection
    [selectAllMode]="true"
    [showCheckBoxesMode]="'always'"  mode="multiple">
  </dxo-selection>

  <!--    TEMPLATE TRAITEMENT_OK_DATE-->
  <div *dxTemplate="let cell of 'traitementDateCellTemplate'">
    {{cell.row.data.traitementDate | date:'dd/MM/yyyy HH:mm'}}
  </div>

  <!--    TEMPLATE ENGAGEMENT PRIX-->
  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell.data?.uniteDeProduction?.site?.libelle"
             [siteId]="cell.data?.uniteDeProduction?.site?.id">
    </yo-site>
  </div>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Membre(s)">
  </dxo-pager>

</dx-data-grid>



<yo-dialog-add-membre></yo-dialog-add-membre>


