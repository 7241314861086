import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {UnitesDeProductionService} from '../../core/services/entities/unites-de-production.service';
import {StocksService} from '../../core/services/gestion-stock/stocks.service';
import {TreeNode} from 'primeng/api';
import {StockMouvementStatutDTO} from '../../core/dtos/stock-mouvement-statut-dto';
import {StockMouvementStatutService} from '../../core/services/gestion-stock/stock-mouvement-statut.service';

@Injectable({
  providedIn: 'root'
})
export class StocksResolverService implements Resolve<StocksSupplier> {

  constructor(private udpSvc: UnitesDeProductionService,
              private stocksSvc: StocksService,
              private stockMvtStatutSvc: StockMouvementStatutService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StocksSupplier> | Promise<StocksSupplier> | StocksSupplier {
    const ss = new StocksSupplier();

    const prepareSearch$ = this.stocksSvc.prepareSearch();
    const stockMvtsStatuts$ = this.stockMvtStatutSvc.getAll();

    const all$ = combineLatest([prepareSearch$, stockMvtsStatuts$]);
    return all$.toPromise().then(response => {
      // arbre des unites de prod avec leurs lieux de stockage
      ss.tree = response[0].additionalProperties['tree'];
      ss.mouvementsStatuts = response[1].resultList;
      return ss;
    });
  }
}

export class StocksSupplier {
  unitesDeProduction: UniteDeProductionDTO[] = [];
  tree: TreeNode[] = [];
  mouvementsStatuts: StockMouvementStatutDTO[] = [];
}
