<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button pButton
            label="Créer"
            icon="fa fa-plus"
            (click)="createFiliale()"
            [disabled]="isDisabledCreateBtn()"
            pTooltip="Créer un fournisseur">
    </button>
  </ng-template>
</p-toolbar>


<dx-data-grid
  [dataSource]="fournisseurList"
  [allowColumnResizing]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(170)"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="id" caption="Actions" alignment="center" width="100"
              [allowEditing]="false" cellTemplate="actionCellTemplate"></dxi-column>
  <dxi-column dataField="libelle" caption="Filiale" alignment="center" width="300"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="code" caption="Code" alignment="center"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="adresse" caption="Adresse" alignment="center" width="400"
              [allowEditing]="false"
              cellTemplate="adresseCellTemplate"></dxi-column>
  <dxi-column dataField="adresse" caption="Téléphone" alignment="center"
              [allowEditing]="false"
              cellTemplate="telephoneCellTemplate"></dxi-column>

  <dxi-column dataField="secteurFournisseurList"
              caption="Secteurs"
              [cellTemplate]="cellTemplate"
              [width]="350">
    <dxo-lookup
      [dataSource]="secteurList"
      displayExpr="libelle"
      valueExpr="id">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="francoDePortActif" caption="Franco port (FP)" alignment="center"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="francoDePortMontant" caption="Montant minimum FP" alignment="center"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="francoDePortPoids" caption="Poid minimum FP" alignment="center"
              [allowEditing]="false"></dxi-column>


  <!--    TEMPLATE ADRESSE -->
  <div *dxTemplate="let cell of 'adresseCellTemplate'">
    <div>
      <span> {{getTooltipAdresse(cell.row.data.adresse)}}</span>
    </div>
  </div>

  <!--    TEMPLATE TELEPHONE-->
  <div *dxTemplate="let cell of 'telephoneCellTemplate'">
    <div>
      <span> {{cell.row.data.adresse.telephone}}</span>
    </div>
  </div>

  <!--    TEMPLATE TELEPHONE-->
  <div class="d-flex flex-row" *dxTemplate="let cell of 'actionCellTemplate'">
    <div class="mg-r-5">
      <button pButton
              icon="fas fa-edit"
              class="p-button-secondary"
              (click)="editFiliale(cell.row.data)"
              [disabled]="isDisabledCreateBtn()"
              pTooltip="Editer une filiale">
      </button>
    </div>
    <div >
      <button pButton
              icon="fas fa-book-open"
              class="p-button-secondary"
              (click)="openDialogCatalogue(cell.row.data)"
              pTooltip="Voir le catalogue articles de la filiale">
      </button>
    </div>
  </div>
</dx-data-grid>

<!--DIALOG FOURNISSEUR FICHE IDENTITE-->
<yo-filiale-form-dialog
  [displayDialog]="displayFormDialog"
  (onClose)="onCloseFormDialog($event)"
></yo-filiale-form-dialog>
