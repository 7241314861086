import {Component, EventEmitter, Input, Output} from "@angular/core";
import {UniteDeProduction__EquipeDTO} from "../../../../../core/dtos/unite-de-production__equipe";
import {DATEPICKER_FR} from "../../../../../core/constants";
import {UtilsService} from "../../../../../core/utils/utils.service";


@Component({
  selector: 'yo-bulk-edition-details-dialog',
  templateUrl: './bulk-edition-details-dialog.component.html',
  styleUrls: ['./bulk-edition-details-dialog.component.scss']
})

export class BulkEditionDetailsDialogComponent {

  @Input() udpEquipeList: UniteDeProduction__EquipeDTO[] = [];
  @Input() displayDialog: boolean;
  @Output() onClose = new EventEmitter<boolean>();
  @Output() emitBulkDetailSupplier = new EventEmitter<BulkDetailSupplier>();

  dateFab: Date;
  dlc: Date;
  udpEquipe: UniteDeProduction__EquipeDTO;
  localeFr = DATEPICKER_FR;

  constructor(private utils: UtilsService) {
  }


  closeDialog = () => {
    this.displayDialog = false;
    this.onClose.emit();
    this.clearData();
  };

  disabledSaveBtn = () => this.utils.isNullOrEmpty(this.dateFab) && this.utils.isNullOrEmpty(this.dlc) && this.utils.isNullOrEmpty(this.udpEquipe);

  sendBulkDetailSupplier = () => {

    let data: BulkDetailSupplier = new BulkDetailSupplier();
    let hasToSend: boolean = false;

    if (!this.utils.isNullOrEmpty(this.dateFab)) {
      data.dateFab = this.dateFab;
      hasToSend = true;
    }


    if (!this.utils.isNullOrEmpty(this.dlc)) {
      data.dlc = this.dlc;
      hasToSend = true;
    }

    if (!this.utils.isNullOrEmpty(data.dateFab) && !this.utils.isNullOrEmpty(data.dlc)) {
      data.isFabDateUpdate = false;
    } else if (this.utils.isNullOrEmpty(data.dateFab) && !this.utils.isNullOrEmpty(data.dlc)) {
      data.isFabDateUpdate = true;
    } else if (!this.utils.isNullOrEmpty(data.dateFab) && this.utils.isNullOrEmpty(data.dlc)) {
      data.isFabDateUpdate = false;
    }


    if (!this.utils.isNullOrEmpty(this.udpEquipe)) {
      data.udpEquipe = this.udpEquipe;
      hasToSend = true;
    }

    this.displayDialog = false;
    this.onClose.emit();
    if (hasToSend) {
      this.emitBulkDetailSupplier.emit(data);
    }

    this.clearData();
  };

  clearData = () => {
    this.udpEquipe = undefined;
    this.dateFab = undefined;
    this.dlc = undefined;
  };

}

export class BulkDetailSupplier {
  dateFab: Date;
  dlc: Date;
  dateLiv: Date;
  udpEquipe: UniteDeProduction__EquipeDTO;
  isFabDateUpdate: boolean;
}
