<div class="d-flex flex-column">

  <div class="flex-grow-1" style="overflow-y:auto;overflow-x: hidden;">
    <dx-data-grid
      [dataSource]="appellationList"
      keyExpr="id"
      [height]="catalogueAchatSvc.getMaxContentHeightDialogArticle(cads)"
      [hoverStateEnabled]="true"
      [selectedRowKeys]="[]"
      [allowColumnResizing]="true"
      [showBorders]="true"
      (onRowPrepared)="onRowPrepared($event)"
      #grid
    >
      <dxo-paging [pageSize]="50"></dxo-paging>

      <dxo-selection
        [selectAllMode]="true"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      ></dxo-selection>


      <dxi-column dataField="id" caption="Logo" cellTemplate="logoCellTemplate" alignment="left"
                  width="80"></dxi-column>
      <dxi-column dataField="libelle" caption="Appellation" cellTemplate="appellationCellTemplate"
                  width="350"></dxi-column>
      <dxi-column dataField="code" caption="Code" cellTemplate="codeCellTemplate"></dxi-column>

      <!--  LOGO CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'logoCellTemplate'">
        <yo-img-entity [entityName]="appellationSvc.getEntityName()"
                       [entityId]="cell.row.data.id"
                       [ordre]="1"
                       [height]="48"
                       format="thumb"
        ></yo-img-entity>
      </div>

      <!--  APPELLATION CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'appellationCellTemplate'">
        {{cell.row.data.libelle | uppercase}}
      </div>

      <!--  CODE CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'codeCellTemplate'">
        {{cell.row.data.code | uppercase}}
      </div>

    </dx-data-grid>
  </div>


  <div>
    <hr>

    <div class="d-flex">

      <div class="mr-auto">
        <label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i>Lors de l'enregistrement, les
          appellations
          seront remontées sur la denrée. Aussi, elles le seront lors de la mise à jour journalière des denrés.</label>
      </div>
      <div class="mg-r-5">
        <button *ngIf="this.catalogueAchat && utils.isNullOrEmpty(this.catalogueAchat.fournisseurOwnerLibelle)"
                pButton
                icon="fas fa-save"
                (click)="saveAppellations()"
                pTooltip="Enregistrer"
                tooltipPosition="top"
                [disabled]="!auth2Svc.isSiteLocal(uniteDeProduction.site.id)"
                showDelay="500" class="p-button-success">
        </button>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary"></button>
      </div>
    </div>
  </div>
</div>
