<div class="d-flex flex-row align-items-center">
  <h5 class="mg-b-0 mg-r-15">
    <label class="badge badge-secondary">
      {{'plan de production' | uppercase}}
    </label>
  </h5>
  <div><strong>"{{planProd?.libelle | uppercase}}"</strong>
    <yo-site class="mg-l-15" [siteLibelle]="planProd.site.libelle" [siteId]="planProd.site.id"></yo-site>
    <span [pTooltip]="typeEffectif" class="type-effectif mg-l-10">(C'est l'{{typeEffectif}} qui est utilsé pour le calcul du plan de production)</span>
  </div>
</div>

<div class="d-flex flex-row justify-content-between mg-b-10">
  <div>
    <button
      pButton
      pTooltip="Appliquer les modifications à toutes les lignes selectionnées"
      label="Traitement de masse"
      icon="fas fa-edit"
      [disabled] = "selectedDetails.length === 0"
      (click)="openBulkEditionDetailDialog()">
    </button>
  </div>
  <div>
    <span class="mg-r-5" pTooltip="Lancer le calcul du conditionnement">
      <button
        pButton
        pTooltip="Lancer le calcul du conditionnement"
        icon="fas fa-boxes"
        (click)="openPreparationConditionnement(planProd.id)">
      </button>
    </span>
    <span class="mg-r-5" pTooltip="Rafraîchir le calcul du conditionnement">
      <button
        pButton
        pTooltip="Rafraîchir le calcul du conditionnement"
        icon="fas fa-cogs"
        (click)="openPreparationConditionnement(planProd.id, true)">
      </button>
    </span>

    <span class="mg-r-5" pTooltip="Sorties de production">
      <button
        pButton
        pTooltip="Sorties de production"
        [disabled]="!hasWkfSortiesProduction"
        icon="fas fa-sign-out-alt"
        (click)="openSortiesProduction(planProd.workflowInstance)">
      </button>
    </span>

    <span class="mg-r-5">
      <button
        type="button"
        pButton
        icon="fas fa-undo-alt"
        (click)="undoModification()"
        pTooltip="Annuler les modifications">
      </button>
    </span>

    <button
      type="button"
      pButton
      icon="fas fa-print"
      (click)="openPrintSettingsFichesTechniques()"
      pTooltip="Sélectionner une impression">
    </button>
  </div>
</div>

<dx-data-grid
  id="gridDetailPlanProd"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowUpdated)="onRowUpdated($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onCellClick)="onCellClick($event)"
  (onEditingStart)="onEditingStart($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
  [dataSource]="details"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  #grid>
  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
  <dxi-column alignment="right" dataField="atelierLibelle" caption="Atelier" [allowEditing]="false" [groupIndex]="0"></dxi-column>
  <dxi-column alignment="right"
              dataField="equipeLibelle"
              caption="Équipe"
              cellTemplate="equipeCellTemplate"
              headerCellTemplate="updateHeaderCellTemplate"
              editCellTemplate="equipeEditCellTemplate"
              [allowEditing]="isEditable()">
  </dxi-column>
  <dxi-column alignment="right" dataField="uniteDeProductionLibelle" caption="Unité de Production" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="produitDeclinaisonLibelle" cellTemplate="produitDeclinaisonCellTemplate" [width]="250" caption="Nom de la fiche technique"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="declinaisonLibelle" caption="Déclinaison" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="contratMenuConviveLibelle" caption="Prestation" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="plcLibelle" caption="Point de livraison" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="nbParts" caption="Nb. portions" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="poidsNetTotal" caption="Poids net total (Unité technique)" [allowEditing]="false"></dxi-column>

  <dxi-column alignment="right" dataField="dateFab" caption="Date de fab." headerCellTemplate="updateHeaderCellTemplate"
              dataType="date" cellTemplate="dateFab" [allowEditing]="isEditable()"></dxi-column>
  <dxi-column alignment="right" dataField="dateConso" caption="Date de conso." [allowEditing]="false" dataType="date"
              cellTemplate="date"></dxi-column>
  <dxi-column alignment="right" dataField="dlc" caption="Date limite de conso." headerCellTemplate="updateHeaderCellTemplate"
              dataType="date" cellTemplate="dateDlc" [allowEditing]="isEditable()"></dxi-column>

  <dxo-summary>
    <dxi-total-item
      column="id"
      displayFormat="{0} éléments"
      showInColumn="produitDeclinaisonLibelle"
      summaryType="count">
    </dxi-total-item>
  </dxo-summary>

  <dxo-search-panel [visible]="true"></dxo-search-panel>

  <dxo-editing mode="cell" [allowUpdating]="isEditable()" [confirmDelete]="false"></dxo-editing>
  <dxo-selection
    [selectAllMode]="true"
    [showCheckBoxesMode]="'always'"
    mode="multiple">
  </dxo-selection>
  <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <!--  TEMPLATE HEADER-->
  <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
    <div tooltipPosition="top" [pTooltip]="'Colonne modifiable : ' + cell.column.caption">
      {{cell.column.caption}}
    </div>
  </div>

  <!--  TEMPLATE COLONNE DATE-->
  <div *dxTemplate="let cell of 'date'">
    {{cell.value | date:'E d LLL yyyy' | titlecase}}
  </div>

  <div *dxTemplate="let cell of 'dateDlc'">
    <i *ngIf="displayWarningDlc(cell)" class="fas fa-exclamation-triangle space"
       [pTooltip]="getToolTipDlc(cell)">
    </i>
    {{cell.value | date:'E d LLL yyyy' | titlecase}}
  </div>

  <div *dxTemplate="let cell of 'dateFab'">
    <i *ngIf="displayWarningFab(cell)" class="fas fa-exclamation-triangle space"
       pTooltip="Attention la date de fabrication est supérieure à la date de consommationn (Donnée non enregistrée)">
    </i>
    {{cell.value | date:'E d LLL yyyy' | titlecase}}
  </div>


  <!--  EQUIPE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'equipeGroupCellTemplate'">
    Équipe: {{cell.data.items[0].equipeLibelle}}
  </div>

  <div *dxTemplate="let cell of 'produitDeclinaisonCellTemplate'">
    <a pTooltip="Aller sur la liste de besoins" showDelay="500"
       (click)="goToProductPage(cell.data.idProduit)">
      <span class="cursor save-color">{{cell.value}}</span>
    </a>
  </div>

  <div *dxTemplate="let cell of 'equipeCellTemplate'">
    {{cell.value}}
  </div>
  <div *dxTemplate="let cell of 'equipeEditCellTemplate'">

    <dx-drop-down-box
      [dropDownOptions]="dropDownOptions"
      [dataSource]="udpEquipeListByUdp"
      [(value)]="cell.value"
      displayExpr="equipeLibelle"
      valueExpr="id"
      contentTemplate="contentTemplate">

      <div *dxTemplate="let e of 'contentTemplate'">
        <table id="equipeTable">
          <thead class="header">
          <tr>
            <th [style.width.px]="250"><i class="fas fa-store mg-r-5"></i> Atelier</th>
            <th [style.width.px]="250"><i class="fas fa-users mg-r-5"></i> Équipe</th>
          </tr>
          </thead>
          <tbody>
          <tr [class.active]="isDetailSelected(selectedUdpDetailPp, udpEquipe)" *ngFor="let udpEquipe of udpEquipeListByUdp"
              class="cursor" (click)="changeSelectedEquipe(udpEquipe, e ,cell)">
            <td [style.width.px]="250">{{udpEquipe.zoneDeProductionLibelle}}</td>
            <td>{{udpEquipe.equipeLibelle}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </dx-drop-down-box>

  </div>

</dx-data-grid>

<yo-print-settings
  [displayDialog]="displayPrintSettingsFichesTechniques"
  [datesConso]="datesConso"
  [datesFab]="datesFab"
  [equipes]="equipes"
  [planProd]="planProd" (onClose)="displayPrintSettingsFichesTechniques=$event">
</yo-print-settings>

<yo-bulk-edition-details-dialog
  [udpEquipeList]="udpEquipeList"
  [displayDialog]="displayBulkDialog"
  (onClose)="onCloseBulkDialog()"
  (emitBulkDetailSupplier)="onEmitBulkDetailSupplier($event)">
</yo-bulk-edition-details-dialog>

