import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ListeBesoinsDto} from '../../dtos/liste-besoins-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {WorkflowsService} from './workflows.service';
import {catchError} from 'rxjs/operators';
import {SiteDTO} from '../../dtos/site-dto';
import {
  find as _find,
  max as _max,
  min as _min,
  sortBy as _sortBy,
  startCase as _startCase,
  uniqBy as _uniqBy
} from 'lodash'
import * as moment from 'moment';
import {TreeNode} from 'primeng/api';
import {ListeBesoinsJourDto} from '../../dtos/liste-besoins-jour-dto';

import {ContratmenuDTO} from '../../dtos/contratmenu-dto';
import {ContratMenuConviveRepasDTO} from '../../dtos/contratmenuconviverepas-dto';

import {ObjectDTO} from '../../dtos/object-dto';
import {
  ColSupplier,
  MatrixSupplier,
  RowSupplier
} from '../../../gestion-processus/selection-menus/selection-menus.component';


export const URL_POST_SAVE_LB_PLANNING = `dolrest/gestion-listes-besoins/liste-besoins/save/effectifs-menus`;


@Injectable({
  providedIn: 'root'
})
export class ListeBesoinJoursService extends GenericHandler<ListeBesoinsJourDto> {


  constructor(
    private cwSvc: WorkflowsService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {

    super(utils, auth2Svc, router, http, title);

  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  createEmptyDTO(): ListeBesoinsJourDto {

    const lbj = new ListeBesoinsJourDto();

    lbj.site = new SiteDTO();

    lbj.site.id = this.auth2Svc.utilisateur.sitePrincipal.id;

    lbj.site.libelle = this.auth2Svc.utilisateur.sitePrincipal.libelle;

    lbj.actif = true;

    return lbj;
  }

  createCjDTO(idLb: number, cmcr: ContratMenuConviveRepasDTO, date: number): ListeBesoinsJourDto {

    const commandeJour = this.createEmptyDTO();

    commandeJour.date = date;
    commandeJour.idListeBesoin = idLb;
    commandeJour.idRepas = cmcr.repasId;
    commandeJour.idContratMenuConvive = cmcr.idContratMenuConvive;
    commandeJour.idJourSemaine = moment(date).clone().isoWeekday();

    return commandeJour;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEntityName(): string {
    return "listeBesoinJour";
  }

  getFields(dto: ListeBesoinsJourDto): FormFieldBaseSupplier<any>[] {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [];

    return formFields;
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return undefined;
  }

  getTitle(): string {
    return "";
  }

  /**
   *
   * @param matrix
   * @param lb liste besoin fournisseur
   * @return {Map<string, ListeBesoinsJourDto>}
   * @param mapLbj map des jours de repas de la liste de besoin
   */
  initMapCommandeJours(matrix: MatrixSupplier, lb: ListeBesoinsDto, mapLbj: Map<string, ListeBesoinsJourDto>): Map<string, ListeBesoinsJourDto> {

    const start = new Date();

    if (!mapLbj) {
      mapLbj = new Map();
    }

    if (!this.utils.isCollectionNullOrEmpty(matrix.rows)) {
      if (!this.utils.isCollectionNullOrEmpty(matrix.cols)) {
        matrix.rows.forEach(row => {
          matrix.cols.forEach(col => {

            const cjKey = this.cjKey(col.date, row.cmcr.repasId, row.cmc.id);

            const cjAlready = mapLbj.get(cjKey);

            // premiere init, on remplie avec les données du back
            if (this.utils.isNullOrEmpty(cjAlready)) {

              // timestamp de la colonne date
              const dateNumber: number = +col.date.clone().format('x');
              const cj = this.createCjDTO(lb.id, row.cmcr, dateNumber);
              cj.actif = this.isCjActif(col, row, lb);
              mapLbj.set(cjKey, cj);

            }

          })
        })
      }
    }

    const stop = new Date();

    // console.log('mapListeBesoinsJours', mapListeBesoinsJours);
    // console.log('init mapListeBesoinsJours ms', moment(stop).diff(moment(start)));

    return mapLbj;
  }

  setRowActive(row: RowSupplier, mapLbj: Map<string, ListeBesoinsJourDto>, active: boolean) {

    if (mapLbj) {
      mapLbj.forEach((value, key) => {
        if (row.cmcr.repasId === value.idRepas && value.idContratMenuConvive === row.cmcr.idContratMenuConvive) {
          value.actif = active;
        }
      });
    }
  }

  setColActive(col: ColSupplier, mapLbj: Map<string, ListeBesoinsJourDto>, checked: boolean) {

    if (mapLbj) {
      mapLbj.forEach((value, key) => {

        const strColDate = this.utils.getYYYYMMDD(col.date);
        const strCjDate = this.utils.getYYYYMMDD(moment(value.date));

        if (strColDate === strCjDate) {
          value.actif = checked;
        }
      });
    }
  }

  /**
   * On recherhce une liste besoin jour par son contrat menu convive, repas, date
   *
   * @param colDate
   * @param rowRepas
   * @param lb
   * @return {any}
   */
  isCjActif(colDate: ColSupplier, rowRepas: RowSupplier, lb: ListeBesoinsDto): boolean {

    if (!this.utils.isNullOrEmpty(lb)) {
      const cjList = lb.listeBesoinJourList;

      if (!this.utils.isCollectionNullOrEmpty(cjList)) {

        const cj = this.utils.getFirstElement(cjList.filter(cj => {

          // repas
          if (cj.idRepas === rowRepas.cmcr.repasId) {

            if (this.utils.isDateEqualsYYYYMMDD(moment(cj.date), colDate.date)) {

              // contrat menu convive
              if (cj.idContratMenuConvive === rowRepas.cmcr.idContratMenuConvive) {
                return true;
              }

            }
          }
          return false;
        }));

        if (!this.utils.isNullOrEmpty(cj)) {
          return cj.actif;
        }
      }
    }

    return false;
  }

  /**
   * Clé de la map des liste besoin jour
   * @param {moment.Moment} date
   * @returns {string}
   */
  cjKey(date: moment.Moment, idRepas: number, idCmc: number): string {

    const jourSemaine = date.clone().isoWeekday();
    return `${this.utils.getYYYYMMDD(date)}-${idRepas}-${jourSemaine}-${idCmc}`;
  }


  private pushNodeIfNotExists(selectedNodes: TreeNode[], node: TreeNode) {
    const exists = !!_find(selectedNodes, node);
    if (!exists) {
      selectedNodes.push(node);
    }
    return selectedNodes;
  }

  getDateCols(dateDebut: Date, dateFin: Date): ColSupplier[] {

    const days: ColSupplier[] = [];

    const momentDateFin = moment(dateFin).clone();
    const momentDateDebut = moment(dateDebut).clone();

    let cursor = momentDateDebut;

    while (cursor.isSameOrBefore(momentDateFin, 'day')) {

      days.push({date: cursor, colActive: false});

      cursor = cursor.clone().add(1, 'day');
    }

    return days;
  }

  /**
   * Sélectionner les noeuds venant du back pour l'arbre de selection des menus
   * Niveau 1 = site
   * Niveau 2 = contrat menu
   * Niveau 3 = contrat menu convive
   * Niveau 4 = contrat menu convive repas
   * @param {ListeBesoinsDto} lb
   * @param {TreeNode[]} nodes
   * @returns {TreeNode[]}
   */
  getSelectedCmcr(lb: ListeBesoinsDto, nodes: TreeNode[]): TreeNode[] {

    let selectedNodes = [];


    if (!this.utils.isNullOrEmpty(lb)) {

      const lbjList: ListeBesoinsJourDto[] = lb.listeBesoinJourList;

      if (!this.utils.isCollectionNullOrEmpty(lbjList)) {

        if (!this.utils.isCollectionNullOrEmpty(nodes)) {

          //get level 4 --> contrat menu convive repas
          nodes.forEach(n1 => {
            if (n1.children) {

              // site
              lbjList.forEach(lbj => {
                if (n1.data.value.id === lbj.idContratMenuSite) {
                  n1.expanded = true;

                  selectedNodes = this.pushNodeIfNotExists(selectedNodes, n1);
                }
              });

              n1.children.forEach(n2 => {

                if (n2.children) {

                  // contrat menu
                  lbjList.forEach(lbj => {
                    if (n2.data.value.id === lbj.idContratMenu) {
                      n2.expanded = true;

                      selectedNodes = this.pushNodeIfNotExists(selectedNodes, n2);
                    }
                  });

                  // contrat menu convive
                  n2.children.forEach(n3 => {

                    if (n3.children) {

                      lbjList.forEach(lbj => {

                        if (n3.data.value.id === lbj.idContratMenuConvive) {

                          n3.expanded = true;

                          selectedNodes = this.pushNodeIfNotExists(selectedNodes, n3);

                        }
                      });



                      // contrat menu convive repas
                      n3.children.forEach(n4 => {

                          lbjList.forEach(lbj => {

                            if (n4.data.value.repasId === lbj.idRepas
                            && n4.data.value.idContratMenuConvive === lbj.idContratMenuConvive) {

                              n4.expanded = true;

                              selectedNodes = this.pushNodeIfNotExists(selectedNodes, n4);

                            }
                          });



                      });

                    }

                  });

                }


              });


            }
          });
        }

      }
    }


     console.log('selectedNodes', selectedNodes);

    return selectedNodes;
  }

  /**
   * Créer un TreeNode de contrats qui descend jusqu'au repas
   * @param {ContratmenuDTO[]} cmList
   * @returns {any[]}
   */
  initNodes(cmList: ContratmenuDTO[]): TreeNode[] {

    let treeNode = [];

    if (!this.utils.isCollectionNullOrEmpty(cmList)) {

      cmList = _uniqBy(cmList, 'id');
      cmList = _sortBy(cmList, 'libelle');
      cmList = this.utils.filter(cmList, 'actif');

      if (!this.utils.isCollectionNullOrEmpty(cmList)) {

        let sites: SiteDTO[] = cmList.map(item => item.site);
        sites = _uniqBy(sites, 'id');

        sites.map(site => {
          const child1: TreeNode = {
            label: site.libelle.toUpperCase(),
            data: {
              level: 1,
              value: site
            },
            icon: "fa fa-map-marker",
            children: []
          };

          treeNode.push(child1);

          const cmListOfSite = cmList.filter(item => item.site.id = site.id);

          if (!this.utils.isCollectionNullOrEmpty(cmListOfSite)) {

            cmListOfSite.map(cm => {

              const child2: TreeNode = {
                label: cm.libelle.toUpperCase(),
                data: {
                  level: 2,
                  value: cm
                },
                children: []
              };

              child1.children.push(child2);

              let cmcList = cm.contratMenuConvives;

              if (!this.utils.isCollectionNullOrEmpty(cmcList)) {

                cmcList = _uniqBy(cmcList, 'id');
                cmcList = _sortBy(cmcList, 'libelle');
                cmcList = this.utils.filter(cmcList, 'actif');

                cmcList.map(cmc => {
                  const child3 = {
                    label: cmc.libelle,
                    data: {
                      level: 3,
                      value: cmc
                    },
                    children: []
                  };
                  child2.children.push(child3);

                  let repasList = cmc.contratsMenusConvivesRepas;

                  if (!this.utils.isCollectionNullOrEmpty(repasList)) {

                    repasList = _uniqBy(repasList, 'repasId');
                    repasList = _sortBy(repasList, 'libelle');
                    repasList = this.utils.filter(repasList, 'actif');
                    repasList.map(cmcr => {

                      const child4 = {
                        label: cmcr.repasLibelle,
                        data: {
                          sortLabel: `${cm.libelle}${cmc.libelle}${cmcr.repasLibelle}`,
                          level: 4,
                          value: cmcr,
                          cmc
                        }
                      };

                      child3.children.push(child4);


                    });
                  }
                });
              }
            });

          }


        });

      }
    }


    return treeNode;
  }


  /**
   * Ne recuperer que les noeud sélectionnes qui sont de niveua 4 et les trier selon leur label
   * @param {TreeNode[]} selectedNodes
   * @returns {TreeNode[]}
   */
  getRepasRows(selectedNodes: TreeNode[]): RowSupplier[] {


    let repasRows: RowSupplier[] = [];

    if (!this.utils.isCollectionNullOrEmpty(selectedNodes)) {

      let nodes = selectedNodes.filter(node => node.data.level === 4);
      nodes = _sortBy(nodes, ['data.sortLabel']);

      nodes.map(node => {
        let row: RowSupplier = {
          sortLabel: node.data.sortLabel,
          cmcr: node.data.value,
          cmc: node.data.cmc,
          label: node.label,
          rowActive: false
        }

        // console.log('row',row);
        repasRows.push(row);
      });


    }

    // console.log('repas rows', repasRows);

    return repasRows;

  }

  /**
   * Récupérer la date début pour sélectionner les appro à commander d'un menu
   */
  getDateDebutEffectifsMenus(lb: ListeBesoinsDto): Date {

    if (!this.utils.isNullOrEmpty(lb)) {

      const jours = lb.listeBesoinJourList;

      if (!this.utils.isCollectionNullOrEmpty(jours)) {

        const minDate = _min(jours.map(jour => jour.date));

        return moment(minDate).toDate();
      }
    }

    return moment().toDate();
  }

  /**
   * Récupérer la date fin pour sélectionner les appro à commander d'un menu
   */
  getDateFinEffectifsMenus(lb: ListeBesoinsDto): Date {

    if (!this.utils.isNullOrEmpty(lb)) {

      const jours = lb.listeBesoinJourList;

      if (!this.utils.isCollectionNullOrEmpty(jours)) {

        const maxDate = _max(jours.map(jour => jour.date));

        return moment(maxDate).toDate();
      }
    }

    return moment().toDate();
  }

  /**
   * Enregistrer le planning des listes de besoin
   *
   */
  saveListeBesoinsPlanning(cf: ListeBesoinsDto) {
    return this.http.post(URL_POST_SAVE_LB_PLANNING, cf)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }


}
