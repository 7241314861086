<p-dialog
  (onHide)="closeDialog()"
  [dismissableMask]="true"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [style]="{'width':'80wh'}"
  [modal]="true"
  appendTo="body"
>

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <span [innerHTML]="domSvc.bypassSecurityTrustHtml(ips?.title)"></span>
    </div>
  </ng-template>

  <form [formGroup]="form" >
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row" showDelay="500">
          <label for="workflow" class="col-sm-3 col-form-label">
            SCÉNARIO <span class="danger-color">*</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm ">
              <select [style.max-width.px]="'400'"
                      formControlName="workflow"
                      class="form-control form-control-sm mg-r-5"
                      id="workflow">
                <option *ngFor="let wkf of workflows" [ngValue]="wkf">{{wkf?.libelle | uppercase}}</option>
              </select>
              <button pButton type="button" (click)="showWorkflow($event)" icon="fas fa-search-plus"
                      pTooltip="Voir le scénario"></button>
            </div>
          </div>
        </div>
        <div class="form-group row" showDelay="500">
          <label for="site" class="col-sm-3 col-form-label">
            SITE <span class="danger-color">*</span>
          </label>
          <div class="col-sm-9">
            <p-listbox
              id="site"
              filter="true"
              [listStyle]="{'height':'150px'}"
              [style]="{'max-width':'100%'}"
              defaultLabel="Selectionner..."
              [options]="auth2Svc.utilisateur.siteListLocaux"
              optionLabel="libelle"
              class=""
              formControlName="sites"
            >
            </p-listbox>
          </div>
        </div>

        <div class="form-group row" showDelay="500">
          <label for="libelle" class="col-sm-3 col-form-label">
            LIBELLÉ <span class="danger-color">*</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm ">
              <input type="text" formControlName="libelle" id="libelle" class="width-400">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton
                (click)="save()"
                [disabled]="!canStartWorkflow()" icon="fas fa-play mg-r-5"
                [label]="ips?.actionStartWorkflowLabel">
        </button>
      </div>
    </div>


  </form>
</p-dialog>

<p-dialog

  [dismissableMask]="false"
  (onHide)="displayWorkflow=false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayWorkflow"
  [responsive]="true" showEffect="fade"
  [modal]="true"
  [style]="{'width':'800px'}">
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      SCÉNARIO {{form?.controls['workflow']?.value?.libelle | uppercase}}
    </div>
  </ng-template>
  <div class="card">
    <div class="card-body" style="max-height:95vh;overflow: auto">
      <div #mermaid></div>
    </div>
  </div>

</p-dialog>
