import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {MarcheDTO} from '../../dtos/gerstion-marche/marche-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldCalendarSupplier} from '../../suppliers/form-field-calendar-supplier';
import * as moment from 'moment';
import {FormFieldTextareaSupplier} from '../../suppliers/form-field-textarea-supplier';


@Injectable({
  providedIn: 'root'
})
export class MarcheService extends GenericHandler<MarcheDTO> {

  getFields(dto: MarcheDTO): FormFieldBaseSupplier<any>[] {

    const isReadonly = !this.canModify(dto);
    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
      dto.actif = true;
    }else{
      dto.dateDebut= moment( dto.dateDebut).toDate();
      dto.dateFin= moment( dto.dateFin).toDate();
    }

    let formFieldSite: FormFieldTextboxSupplier;

    if (dto.id > 0) {
      formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      })
    } else {
      formFieldSite = new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        required: true,
        options: this.optionsSites
      })
    }

    let formFieldCode: FormFieldTextboxSupplier;
    if (dto.id > 0) {
      formFieldCode = new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 4,
        readonly: true,
      })
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        readonly:isReadonly
      }),

      new FormFieldTextareaSupplier({
        key: 'descriptif',
        label: 'Description',
        minLength: 0,
        maxLength: 250,
        value: dto.descriptif,
        required: false,
        order: 3,
        readonly:isReadonly
      }),

      new FormFieldCalendarSupplier({
        key: 'dateDebut',
        label: 'Date de début',
        value: dto.dateDebut,
        required: true,
        showTime: false,
        dateFormat: 'dd/mm/yy',
        order: 5,
        readonly:isReadonly
      }),

      new FormFieldCalendarSupplier({
        key: 'dateFin',
        label: 'Date de fin',
        value: dto.dateFin,
        required: true,
        showTime: false,
        dateFormat: 'dd/mm/yy',
        order: 6,
        readonly:isReadonly
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 7,
        readonly: isReadonly,
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 8
      })
    ];

    if (dto.id > 0) {
      formFields.push(formFieldCode);
    }

    return formFields.sort((a, b) => a.order - b.order);
  }

  createEmptyDTO(): MarcheDTO {
    return new MarcheDTO();
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: MarcheDTO): string {
    return "";
  }

  getEntityName(): string {
    return 'marche';
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

}
