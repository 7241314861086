import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ProfilsService} from '../../core/services/entities/profils.service';
import {UtilsService} from '../../core/utils/utils.service';
import {ProfilDTO} from '../../core/dtos/profil-dto';
import {RoleDTO} from '../../core/dtos/role-dto';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProfilSupplier} from './profils-resolver.service';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash'
import {ConfirmationService} from 'primeng/api';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {UtilisateurService} from "../../core/utilisateur.service";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";

@Component({
  selector: 'yo-profils',
  templateUrl: './profils.component.html',
  styleUrls: ['./profils.component.scss']
})
export class ProfilsComponent implements OnInit, OnDestroy {

  subProfils: Subscription;
  profilList: ProfilDTO[];
  roles: RoleDTO[];
  displayProfilRoleDialog: boolean;
  isEditMode = false;
  dialogProfil: ProfilDTO;
  dialogErrMsgList: string[] = [];
  selectedRoles: RoleDTO[];

  pathFileProfils: string = HELP_FOLDERS.REFERENTIEL + '/profils';
  pathFileRoles: string = HELP_FOLDERS.REFERENTIEL + '/roles';

  constructor(private profilSvc: ProfilsService,
              private utilisateurSvc : UtilisateurService,
              private route: ActivatedRoute,
              private confirmationSvc:ConfirmationService,
              public utils: UtilsService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  this.subcriptionProfils();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subProfils);
  }

  subcriptionProfils = (): void => {
    this.subProfils = this.route.data.subscribe((data: { profilSupplier: ProfilSupplier }) => {
      this.profilList = data.profilSupplier.profils;
      this.roles = data.profilSupplier.roles;
    });
  };

  addProfilDialog = (): void => {
    this.selectedRoles= [];
    this.isEditMode = false;
    this.dialogErrMsgList = [];
    this.displayProfilRoleDialog = true;
    this.dialogProfil = this.profilSvc.createEmptyDTO();
    this.selectedRoles.push(this.addRoleUtilisateur(this.roles));
    this.dialogProfil.roleList = this.selectedRoles;
  };

  onChangeProfil = (profil:ProfilDTO): void => {
    if (!this.utils.isNullOrEmpty(profil)) {
      this.isEditMode = true;
      this.dialogErrMsgList = [];
      this.displayProfilRoleDialog = true;
      this.dialogProfil = _cloneDeep(profil);
      const preselectedRoles = [];
      for (const role of this.dialogProfil.roleList) {
        preselectedRoles.push(role);
      }
      this.selectedRoles = this.utils.preSelectMultiList(this.roles, preselectedRoles);
    }
  };

  closeDialog = (): void => {
    this.displayProfilRoleDialog = false;
  };

  changeRoles = (event: any): void => {
    this.selectedRoles = event.value
    this.dialogProfil.roleList =this.selectedRoles;
  };

  addRoleUtilisateur = (roles: RoleDTO[]): RoleDTO => {
    const roleUtilisateur = _find(roles, {'libelle': 'ROLE_UTILISATEUR'});
    return roleUtilisateur;
  };

  saveProfil = (): void => {
    this.dialogErrMsgList = this.validBeforeSave();
    if (this.utils.isCollectionNullOrEmpty(this.dialogErrMsgList)) {
      let isCreation = !this.dialogProfil.id;
      this.profilSvc.saveProfil(this.dialogProfil).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.dialogProfil = response.one;
          if (!isCreation) {
            const idx = this.profilList.findIndex(p => p.id === this.dialogProfil.id);
            this.profilList[idx] = this.dialogProfil;
          } else {
            this.profilList.push(this.dialogProfil);
          }
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Profil enregistré avec succès.`);
          this.closeDialog();
        }
      });
    }
  };

  validBeforeSave = (): string[] => {
    let errMsgList: string[] = [];
    if (this.utils.isNullOrEmpty(this.dialogProfil.libelle)) {
      errMsgList.push('Le profil est obligatoire.');
    }
    if (this.utils.isNullOrEmpty(this.dialogProfil.description)) {
      errMsgList.push('La description est obligatoire.');
    }
    return errMsgList;
  };

  deleteProfil = (profil:ProfilDTO): void => {
    this.confirmationSvc.confirm({
      message: `Supprimer le profil "${profil.libelle}" ?`,
      accept: () => {
        this.profilSvc.deleteProfil(profil.id).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Profil ${profil.libelle} supprimé.`);
            const idx = this.profilList.findIndex(p => p.id === profil.id);
            this.profilList.splice(idx, 1);
          }
        });
      }
    });
  };

  loadDataGrid = (): void => {
    this.utilisateurSvc.getProfilList().subscribe( (response : ResponseWrapper<ProfilDTO>) => {
      this.profilList = response.resultList;
    });
  };

  /**
   * On ne peut effectuer d'actions sur les profils par defaut
   * @param profil
   */
  canDoActions = (profil:ProfilDTO): boolean => {
    if(!this.utils.isNullOrEmpty(profil) ){
      const profilLibelle=profil.libelle.toLowerCase();
      if(profilLibelle==='administrateur' || profilLibelle==='import' || profilLibelle==='utilisateur'){
        return false;
      }
    }
    return true;
  };

  help = (): undefined => undefined;

}
