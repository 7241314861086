import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';
import {Router} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';

import {MenuItem} from 'primeng/api';
import {DOL_VERSION_JSON} from '../../../../dol-version';
import {TieredMenu} from 'primeng/tieredmenu';
import {interval, Subject, Subscription} from 'rxjs';
import {cloneDeep as _cloneDeep} from 'lodash';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'yo-left-app-menu',
  templateUrl: './left-app-menu.component.html',
  styleUrls: ['./left-app-menu.component.scss']
})
export class LeftAppMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  dolVersionJson = DOL_VERSION_JSON;
  subActivatedRoute: Subscription;

  @ViewChild('menu') menu: TieredMenu;


  subMenuItems: any[] = [];


  constructor(public itemNavSvc: ItemNavigationService, public router: Router, public utils: UtilsService, private renderer: Renderer2, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    // detecter la route active
    this.activatedRouteSubscription();
  }

  openMenu(idSubMenu: string, menu: TieredMenu) {


    // fix change detection p-tiered-menu
    document.getElementById(idSubMenu).click();
    setTimeout(()=>{
      document.getElementById(idSubMenu).click();
    },50);

  }

  activatedRouteSubscription() {
    this.subActivatedRoute = this.itemNavSvc.primaryRoute$.subscribe(event => {

      if (event) {
        const elts = this.itemNavSvc.selectMenuItem(event.url);
        this.itemNavSvc.selectedMenuItem = elts.selectedMenuItem;
        this.itemNavSvc.selectedFlMenuItem = elts.selectedFlMenuItem;
        this.itemNavSvc.newFlMenuItem = null;

        if(this.menu){
          this.menu.hide();
        }
      }

    });
  }


  openRealMenu(item: MenuItem, menu: TieredMenu, event: MouseEvent) {

    this.itemNavSvc.newFlMenuItem = item;

    // 1 seul niveau
    if (this.utils.isCollectionNullOrEmpty(item.items)) {

      // suppression de l'icone dans le sous menu
      const dupItem = _cloneDeep(item);
      dupItem.icon = '';
      this.subMenuItems = [dupItem];

    }
    // sous menu
    else {
      this.subMenuItems = item.items;
    }

    if (this.menu) {

      this.menu.show(event);

    }



  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subActivatedRoute);
  }

  ngAfterViewInit(): void {


  }

  forceShowMenu(event) {
    let nbRetries = 0;
    let elt = null;
    const stop$ = new Subject<boolean>();
    const source = interval(500);
    const source$ = source.pipe(
      tap(item => {

        this.menu.cd.reattach();
        this.menu.show(event);
        this.menu.cd.markForCheck();
        stop$.next(true);
      }),
      takeUntil(stop$)
    ).subscribe();
  }


}
