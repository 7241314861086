import {ObjectDTO} from "./object-dto";
import {UnitedemesureDTO} from "./unitedemesure-dto";

export class StockReservationDTO extends ObjectDTO {

  // stocks reservations en unité de stockage
  quantiteReservee : number;

  // catalogue achat
  idCatalogueAchat :number;
   ratioUS : number;

  // produit declinaison
  idProduitDeclinaison : number;
  ratioUT : number;
  libelleProduitDeclinaison:number;

  // unités de mesure
  uniteTechnique : UnitedemesureDTO;
  uniteDeStockage : UnitedemesureDTO;

  // produit article
  libelleProduitArticle : string;

  // lot article
  idLotArticle : number;
  numeroLotInterne : string;
  numeroLotFabriquant : string;

  // stock
  idStock : number;
  quantiteStock:number;

  // zone de stockage
  idZoneDeStockage : number;
  libelleZoneDeStockage:string;

  // champs calculés
  quantiteASortir:number;



}
