import {Component, OnDestroy, OnInit} from '@angular/core';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {ModelViewMouvementDTO} from '../model-view-mouvement';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ActivatedRoute} from '@angular/router';
import {StockMouvementService} from '../../../core/services/gestion-stock/stock-mouvement.service';
import {saveAs as fs_saveAs} from 'file-saver';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';


import {Auth2Service} from '../../../core/services/security/auth2.service';
import {
  utils as xlsxUtils,
  WorkBook as xlsxWorkBook,
  WorkSheet as xlsxWorkSheet,
  write as xlsxWrite,
  writeFile as xlsxWriteFile
} from 'xlsx';


@Component({
  selector: 'yo-grille-mouvements',
  templateUrl: './grille-mouvements.component.html',
  styleUrls: ['./grille-mouvements.component.scss']
})
export class GrilleMouvementsComponent implements OnInit, OnDestroy {

  subResultSearchMouvements: Subscription;
  subResultExportMouvements: Subscription;
  subResultSearchBalancesMouvements: Subscription;
  subRoute: Subscription;

  mouvements: ModelViewMouvementDTO[] = [];

  // criteres de recherche
  ssw: SearchSupplierWrapper;
  totalMouvements = 0;


  balanceEntreesHT = 0;
  balanceSortiesHT = 0;
  balanceHT = 0;
  balanceEntreesTTC = 0;
  balanceSortiesTTC = 0;
  balanceTTC = 0;

  itemsPrintMouvement: MenuItem[];

  readonly DELTA_WIDTH_MOUVEMENTS = 425;

  cols: any[] = [
    {field: 'action', header: 'Actions'},
    {field: 'entreeSortie', header: 'E/S'},
    {field: 'stockMouvementStatutCode', header: 'Motif'},
    {field: 'dateMouvement', header: 'Date du mouvement'},
    {field: 'zdsLibelle', header: 'Emplacement'},
    {field: 'lotArticleFournisseur', header: 'Lot Fournisseur'},
    {field: 'denominationArticle', header: 'Dénomination'},
    {field: 'fournisseurLibelle', header: 'Fournisseur filiale'},
    {field: 'quantite', header: 'Quantité'},
    {field: 'prix', header: 'Prix unitaire'},
    {field: 'tva', header: 'TVA'},
    {field: 'prixTotalHT', header: 'HT'},
    {field: 'prixTotalTTC', header: 'TTC'},

  ];

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private stocksSvc: StocksService,
              private stockMvtSvc: StockMouvementService,
              private gds: GenericDatagridService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subRoute = this.route.parent.url.subscribe(response => {
      const feature = response[0].path;
      this.stocksSvc.announceFeature(feature);

    });

    this.subResultSearchMouvements = this.stockMvtSvc.resultSearchMouvements$.subscribe(response => {
      this.mouvements = response.resultList;
      this.totalMouvements = response.totalElements;

      this.ssw = new SearchSupplierWrapper();
      this.ssw.filtersMap = response.additionalProperties['filters'];

    });

    this.subResultExportMouvements = this.stockMvtSvc.resultExportMouvements$.subscribe(response => {
      this.buildExcelAvecDetails(response.resultList);
    });

    this.subResultSearchBalancesMouvements = this.stockMvtSvc.resultSearchBalancesMouvements$.subscribe(response => {

      this.balanceEntreesHT = response.additionalProperties['balanceEntreesHT'];
      this.balanceSortiesHT = response.additionalProperties['balanceSortiesHT'];
      this.balanceHT = response.additionalProperties['balanceHT'];
      this.balanceEntreesTTC = response.additionalProperties['balanceEntreesTTC'];
      this.balanceSortiesTTC = response.additionalProperties['balanceSortiesTTC'];
      this.balanceTTC = response.additionalProperties['balanceTTC'];

    });

    this.itemsPrintMouvement = [{
      label: 'Choix d\'impression :',
      items: [
        {icon: 'far fa-lg fa-file-pdf', label: 'Mouvements de stocks', command: event => this.printMouvements()},
        {
          icon: 'far fa-lg fa-file-excel',
          label: 'Bilan mouvements de stock',
          command: event => this.exportMouvements()
        },
        {
          icon: 'far fa-lg fa-file-excel',
          label: 'Bilan mouvements de stock avec détail',
          command: event => this.exportMouvementsDetails()
        }
      ]
    }];
  }

  lazyLoad = (event: LazyLoadEvent) => {
    this.mouvements = [];

    const page = this.gds.getPage(event);
    const size = this.gds.getSize(event);

    // relancer une recherche mouvements avec la pagination demandée
    this.stockMvtSvc.announcePaginationSearchMouvement({page, size});
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchMouvements);
    this.utils.unsubscribe(this.subResultExportMouvements);
    this.utils.unsubscribe(this.subResultSearchBalancesMouvements);
    this.utils.unsubscribe(this.subRoute);
  }

  printMouvements = () => {

    console.log('print mouvements');

    this.stockMvtSvc.printMouvements(this.ssw).subscribe(response => {
      const blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, 'mouvements.pdf');
    });
  };

  exportMouvements = () => {
    this.buildExcel();
  };

  exportMouvementsDetails = () => {
    this.stockMvtSvc.announceExportMouvement();
  };

  getWorksheetMouvements = (): any => {
    let title = 'Bilan des mouvements de stock';

    let periode = '';
    const udp = {};
    const stockage = {};
    const statut = {};

    if (this.ssw.filtersMap['PERIODE'] && this.ssw.filtersMap['PERIODE'].values.length > 0) {
      periode = periode.concat(' du ');
      periode = periode.concat(this.formatDate(this.ssw.filtersMap['PERIODE'].values[0]));
      periode = periode.concat(' au ');
      periode = periode.concat(this.formatDate(this.ssw.filtersMap['PERIODE'].values[1]));
    }

    if (this.ssw.filtersMap['UDP_LIBELLES'] && this.ssw.filtersMap['UDP_LIBELLES'].values.length > 0) {
      udp['col1'] = 'UP :';
      udp['col2'] = '';
      let i = 3;
      for (const libelle of this.ssw.filtersMap['UDP_LIBELLES'].values) {
        udp['col' + i] = libelle;
        i++;
      }
    }

    if (this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'] && this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'].values.length > 0) {
      stockage['col1'] = 'Zone(s) de stockage :';
      stockage['col2'] = '';
      let i = 3;
      for (const libelle of this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'].values) {
        stockage['col' + i] = libelle;
        i++;
      }
    }

    if (this.ssw.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'] && this.ssw.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'].values.length > 0) {
      statut['col1'] = 'Statut(s)';
      statut['col2'] = '';
      let i = 3;
      for (const libelle of this.ssw.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'].values) {
        statut['col' + i] = libelle;
        i++;
      }
    }

    if (this.ssw.filtersMap['SENS'] && this.ssw.filtersMap['SENS'].values.length > 0) {
      if (this.ssw.filtersMap['SENS'].values.length === 1) {
        title = title.concat(' de type ');
        if (this.ssw.filtersMap['SENS'].values[0] === '0') {
          title = title.concat('sortie');
        } else {
          title = title.concat('entrée');
        }
      }
    }

    const jsons: any[] = [];

    jsons.push(
      {'col1': title, 'col2': '', 'col3': '', 'col4': '', 'col5': '', 'col6': ''});

    jsons.push(
      {'col1': '', 'col2': '', 'col3': '', 'col4': '', 'col5': '', 'col6': ''});

    jsons.push(
      {'col1': periode, 'col2': '', 'col3': '', 'col4': '', 'col5': '', 'col6': ''});

    jsons.push(
      {'col1': 'Entrées', 'col2': '', 'col3': 'Sorties', 'col4': '', 'col5': 'Balance', 'col6': ''},
      {
        'col1': 'Total € HT',
        'col2': 'Total € TTC',
        'col3': 'Total € HT',
        'col4': 'Total € TTC',
        'col5': 'Total € HT',
        'col6': 'Total € TTC'
      },
      {
        'col1': this.balanceEntreesHT,
        'col2': this.balanceEntreesTTC,
        'col3': this.balanceSortiesHT,
        'col4': this.balanceSortiesTTC,
        'col5': this.balanceHT,
        'col6': this.balanceTTC
      }
    );

    jsons.push(
      {'col1': '', 'col2': '', 'col3': '', 'col4': '', 'col5': '', 'col6': ''});

    jsons.push(udp);
    jsons.push(stockage);
    jsons.push(statut);

    const worksheet: xlsxWorkSheet = xlsxUtils.json_to_sheet(jsons, {skipHeader: true});
    worksheet['!merges'] = [{s: {c: 0, r: 0}, e: {c: 5, r: 1}},
      {s: {c: 0, r: 2}, e: {c: 5, r: 2}},
      {s: {c: 0, r: 3}, e: {c: 1, r: 3}},
      {s: {c: 2, r: 3}, e: {c: 3, r: 3}},
      {s: {c: 4, r: 3}, e: {c: 5, r: 3}}];

    if ((this.ssw.filtersMap['UDP_LIBELLES'] && this.ssw.filtersMap['UDP_LIBELLES'].values.length > 0)) {
      worksheet['!merges'].push({s: {c: 0, r: 7}, e: {c: 1, r: 7}});
    }

    if ((this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'] && this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'].values.length > 0)) {
      worksheet['!merges'].push({s: {c: 0, r: 8}, e: {c: 1, r: 8}});
    }

    if (this.ssw.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'] && this.ssw.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'].values.length > 0) {
      worksheet['!merges'].push({s: {c: 0, r: 9}, e: {c: 1, r: 9}});
    }

    return worksheet;
  };

  getWorksheetMouvementsDetails = (data: ModelViewMouvementDTO[]): any => {
    const jsons = [];

    for (const viewMvt of data) {
      const date = new Date(viewMvt.dateMouvement);
      const json = {
        'E/S': viewMvt.entreeSortie ? 'E' : 'S',
        'Motif': viewMvt.stockMouvementStatutLibelle,
        'Date du mouvement': (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear(),
        'Lot fournisseur': viewMvt.lotArticleFournisseur,
        'Dénomination': viewMvt.denominationArticle,
        'Quantité': viewMvt.quantiteEnUF,
        'Prix unitaire': viewMvt.prixUnitaire,
        'TVA': viewMvt.tva,
        'HT': viewMvt.prixTotalHT,
        'TTC': viewMvt.prixTotalTTC,
        'Remarque': viewMvt.remarque,
      }
      jsons.push(json);
    }

    return xlsxUtils.json_to_sheet(jsons);
  };


  buildExcel = () => {
    const worksheet: xlsxWorkSheet = this.getWorksheetMouvements();

    const workbook: xlsxWorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = xlsxWrite(workbook, {bookType: 'xlsx', type: 'array'});
    this.utils.saveAsExcelFile(excelBuffer, 'mouvements');
  };

  formatDate = (date: string): string => date.substring(6) + '/' + date.substring(4, 6) + '/' + date.substring(0, 4);


  buildExcelAvecDetails = (data: ModelViewMouvementDTO[]) => {
    const worksheet1: xlsxWorkSheet = this.getWorksheetMouvements();
    const worksheet2: xlsxWorkSheet = this.getWorksheetMouvementsDetails(data);

    const wb: xlsxWorkBook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(wb, worksheet1, 'mouvements');
    xlsxUtils.book_append_sheet(wb, worksheet2, 'détails');

    xlsxWriteFile(wb, 'mouvements_details.xlsx');
  };

  openModification = (mvmDto: ModelViewMouvementDTO) => {
    if (this.auth2Svc.isSiteLocal(mvmDto.siteUniteDeProductionId)) {
      console.log('openModification', mvmDto);
      this.stockMvtSvc.announceOpenDialogModificationMouvement(mvmDto);
    }
  };
}
