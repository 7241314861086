import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlanProductionDetailDTO} from '../../../../core/dtos/plan-production-detail-dto';
import {PlanProductionDTO} from '../../../../core/dtos/plan-production-dto';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ProductionService} from '../../../../core/services/gestion-production/production.service';
import {ActivatedRoute} from '@angular/router';
import {catchError, delay, switchMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {DATAGRID_ROW_TYPES} from '../../../../core/services/technique/devextreme.service';
import {
  FP_ROUTES,
  FS_ROUTES,
  MSG_KEY,
  MSG_SEVERITY,
  TYPE_EFFECTIF,
  UI_COLORS,
  WORKFLOW_REVISION,
  WORKFLOW_TASKS
} from '../../../../core/constants';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {DetailsSupplier} from './grid-details-pp-resolver.service';
import {DxDataGridComponent} from 'devextreme-angular';
import * as moment from 'moment';
import {uniq as _uniq} from 'lodash'
import {ConfirmationService} from 'primeng/api';
import {WorkflowInstanceDTO} from '../../../../core/dtos/workflow-instance-dto';
import {RunStepDTO} from '../../../../core/dtos/run-step-dto';
import {WorkflowsService} from '../../../../core/services/entities/workflows.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {BulkDetailSupplier} from './bulk-edition-details-dialog/bulk-edition-details-dialog.component';

@Component({
  selector: 'yo-grid-details-plan-production',
  templateUrl: './grid-details-pp.component.html',
  styleUrls: ['./grid-details-pp.component.scss']
})
export class GridDetailsPlanProductionComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;

  displayBulkDialog: boolean = false;

  planProd: PlanProductionDTO = new PlanProductionDTO();
  details: PlanProductionDetailDTO[] = [];
  selectedDetails: PlanProductionDetailDTO[] = [];
  workflowInstance: WorkflowInstanceDTO;
  typeEffectif :string;

  //Pour l'impression
  displayPrintSettingsFichesTechniques: boolean = false;
  datesConso: Date[] = [];
  datesFab: Date[] = [];
  equipes: UniteDeProduction__EquipeDTO[] = [];
  nbActifs: number;

  subDetails: Subscription;

  editedColumn: string;

  dropDownOptions = {width: 500};
  selectedUdpDetailPp: PlanProductionDetailDTO;

  udpEquipeList: UniteDeProduction__EquipeDTO[] = [];
  udpEquipeListByUdp: UniteDeProduction__EquipeDTO[] = [];

  udpEquipeForBulkList: UniteDeProduction__EquipeDTO[] = [];

  hasWkfSortiesProduction: boolean;
  hasNextStepsSortiesProduction: boolean;

  constructor(public utils: UtilsService,
              public prodSvc: ProductionService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private routeMapSvc: RoutemapService,
              public workflowSvc: WorkflowsService) {
  }

  ngOnInit(): void {
    this.initData();

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDetails);
  }

  initData = () => {
    this.utils.unsubscribe(this.subDetails);
    this.subDetails = this.route.data.pipe(
      delay(0)
    )
      .subscribe((data: { detailsSupplier: DetailsSupplier }) => {
        this.planProd = data.detailsSupplier.planProd;
        this.udpEquipeList = data.detailsSupplier.udpEquipeList;
        this.hasWkfSortiesProduction = data.detailsSupplier.hasWkfSortiesProduction;
        this.hasNextStepsSortiesProduction = data.detailsSupplier.hasNextStepsSortiesProduction;
        this.workflowInstance = data.detailsSupplier.workflowInstance;
        this.typeEffectif = this.setTypeEffectif(data.detailsSupplier.workflowInstance);


        this.prodSvc.getDetails(this.planProd.id).subscribe((response: ResponseWrapper<PlanProductionDetailDTO>) => {
          this.details = response.resultList;
          this.udpEquipeForBulkList = this.getUdpEquipeForBulkList(this.details);
        });

      });
  };

  setTypeEffectif = (workflowInstance: WorkflowInstanceDTO) => {
    if(workflowInstance.typeEffectif){
      switch (workflowInstance.typeEffectif) {
        case TYPE_EFFECTIF.facturation :
          return 'éffectif de facturation';
        case TYPE_EFFECTIF.fabrication :
          return 'éffectif de fabrication';
        default :
          return 'éffectif de previsionnel';
      }
    }else{
      return 'éffectif de previsionnel';
    }

  };


  getUdpEquipeForBulkList = (details: PlanProductionDetailDTO[]) => {

    let idUdpIdList: number[] = [];
    details.forEach(item => {
      idUdpIdList.push(item.uniteDeProductionid);
    });

    idUdpIdList = _uniq(idUdpIdList);

    let udpEquipeForBulkList: UniteDeProduction__EquipeDTO[] = [];

    if (idUdpIdList.length === 1) {
      udpEquipeForBulkList = this.getUdpEquipeListByUdp(idUdpIdList[0]);
    }

    return udpEquipeForBulkList;

  };

  openPrintSettingsFichesTechniques = (): void => {
    this.displayPrintSettingsFichesTechniques = false;

    this.prodSvc.getPrintSettings(this.planProd.id, this.nbActifs).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.displayPrintSettingsFichesTechniques = true;
        this.datesFab = response.additionalProperties['datesFab'];
        this.datesConso = response.additionalProperties['datesConso'];
        this.equipes = response.additionalProperties['equipes'];
      }
    });
  };


  onCellPrepared = (event: any) => {
    // si cell est un header
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER && this.isEditable()) {
      if (event.column.allowEditing === true) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }
  };

  onRowUpdated = (event: any) => {

    //ajout attribut modificationManuelle pour dans le onRowPrepared mettre en rouge la ligne
    event.data.modificationManuelle = true;
    if (this.editedColumn === 'dateFab') {
      event.data.fabDateUpdate = true;
      event.data.dlc = moment(event.data.dateFab).add(event.data.produitDeclinaisonDlc, 'days');
    }
    this.savePlanProdDetail(event.data);
  };

  onRowPrepared = (event: any) => {

    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.data.modificationManuelle) {
      event.rowElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
      event.rowElement.className = event.rowElement.className.replace('dx-row-alt', '');
    }
  };

  saveMassivePlanProdDetail = (selectedDetails: PlanProductionDetailDTO[]) => {
    let detailToSaveList: PlanProductionDetailDTO[] = [];
    let errorString: string = '';

    selectedDetails.forEach((detail: PlanProductionDetailDTO, index) => {
      if (detail.dateConso < detail.dateFab) {
        errorString += detail.produitDeclinaisonLibelle;
        errorString += index < this.selectedDetails.length - 1 ? ', ' : '.';
      } else {
        detailToSaveList.push(detail);
      }
    });

    if (errorString !== '') {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Attention la date de fabrication est supérieure à la date de consommation dans : ` + errorString);
    }

    this.selectedDetails = [];
    this.grid.instance.clearSelection();

    this.prodSvc.saveDetailsPlanProd(detailToSaveList).subscribe((response: ResponseWrapper<PlanProductionDetailDTO>) => {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `sauvegarde effectué.`);

      //permet de regenerer le grouping
      this.grid.instance.refresh();
    });
  };

  savePlanProdDetail = (planProdDetail: PlanProductionDetailDTO) => {

    let planProdDetailList: PlanProductionDetailDTO[] = [];

    planProdDetailList.push(planProdDetail);

    if (planProdDetail.dateConso < planProdDetail.dateFab) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Attention la date de fabrication est supérieure à la date de consommation.`);
    } else {
      this.prodSvc.saveDetailsPlanProd(planProdDetailList).subscribe((response: ResponseWrapper<PlanProductionDetailDTO>) => {


        if (this.editedColumn === 'dateFab') {
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `sauvegarde effectué, la date de fabrication a été automatiquement recalculé par rapport à la date limite de consommation de votre produit déclinaion ` + planProdDetail.produitDeclinaisonLibelle);
        } else {
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `sauvegarde effectué.`);
        }

        //permet de regenerer le grouping
        this.grid.instance.refresh();

        //initialisation de editedColumn
        this.editedColumn = '';

        //todo pour le moment on ne fait pas remonté les données de la DB car on ne veut pas perdre la notion de "modificationManuelle"
        // this.details = response.resultList;
      });
    }
  };

  isEditable = () => this.auth2Svc.isSiteLocal(this.planProd.site.id);


  onCellClick = (event: any) => {

    if (!this.utils.isNullOrEmpty(event.column)) {
      this.editedColumn = event.column.dataField;

      //récuperation de la liste des équipe concernant le
      if (event.column.dataField === 'equipeLibelle') {
        this.udpEquipeListByUdp = this.getUdpEquipeListByUdp(event.row.data.uniteDeProductionid);
      }
    }
  };

  getUdpEquipeListByUdp = (uniteDeProductionid: any) => {

    let udpEquipeListByUdp: UniteDeProduction__EquipeDTO[] = [];

    this.udpEquipeList.forEach((udpEquipe: UniteDeProduction__EquipeDTO) => {
      if (udpEquipe.idUniteDeProduction === uniteDeProductionid) {
        udpEquipeListByUdp.push(udpEquipe);
      }
    });

    return udpEquipeListByUdp;
  };

  changeSelectedEquipe = (udpEquipe: UniteDeProduction__EquipeDTO, e: any, cell: any) => {

    //mise a jour des informations udpEquipe en fonction du choix fait par le utilisateur
    this.selectedUdpDetailPp.udpEquipe = udpEquipe;
    this.selectedUdpDetailPp.equipeId = udpEquipe.idEquipe;
    this.selectedUdpDetailPp.udpEquipeId = udpEquipe.id;
    this.selectedUdpDetailPp.equipeLibelle = udpEquipe.equipeLibelle;
    this.selectedUdpDetailPp.atelierLibelle = udpEquipe.zoneDeProductionLibelle;
    this.selectedUdpDetailPp.udpZoneDeProdId = udpEquipe.idUdpZoneDeProduction;

    let event = {data: this.selectedUdpDetailPp};


    if (!this.utils.isNullOrEmpty(event)) {
      this.grid.onRowUpdated.emit(event);
    }
    e.component.close();
  };

  isDetailSelected = (selectedUdpDetailPp: PlanProductionDetailDTO, udpEquipe: UniteDeProduction__EquipeDTO) => selectedUdpDetailPp.udpEquipe.id === udpEquipe.id;

  onEditingStart = (event: any) => {
    //recuperation de la ligne a selectionner
    this.selectedUdpDetailPp = event.data;
  };

  displayWarningDlc = (cell: any) => (cell.row.data.dlc < cell.row.data.dateConso || moment(cell.row.data.dlc).diff(cell.row.data.dateFab, 'days') > cell.row.data.produitDeclinaisonDlc);

  displayWarningFab = (cell: any) => (cell.row.data.dateFab > cell.row.data.dateConso);

  getToolTipDlc = (cell: any) => {

    let tooltip: string = '';
    if (cell.row.data.dlc < cell.row.data.dateConso) {
      tooltip += 'Attention, la DLC est inférieure à la date de consommation';
    }

    const offsetDay = moment(cell.row.data.dlc).diff(cell.row.data.dateFab, 'days');

    if (offsetDay > cell.row.data.produitDeclinaisonDlc) {
      const datePreco = moment(cell.row.data.dateFab).add(cell.row.data.produitDeclinaisonDlc, 'days').format("DD/MM/YYYY");
      tooltip += 'Attention, la DLC saisie est supérieure à la DLC autorisée par le plat (DLC préconisée : ' + datePreco + ')';
    }
    return tooltip;
  };

  undoModification = () => {
    this.confirmationService.confirm({
      message: 'Etes vous sûr de vouloir réinitialiser les données ? Attention toutes les modifications seront perdues.',
      accept: () => {
        const runStep: RunStepDTO = new RunStepDTO();
        runStep.idWorkflowInstance = this.planProd.workflowInstance.id;
        runStep.nextStepCode = WORKFLOW_TASKS.CALCUL_PRODUCTION;
        this.workflowSvc.goToNextStep(runStep).subscribe(response => {
          this.routeMapSvc.goToSecondaryRoute(['gestion-processus', this.planProd.workflowInstance.id, 'pp-details'])
        });
      }
    });
  };

  openPreparationConditionnement = (pid: number, refresh?: boolean) => {
    if (pid) {
      this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_PREPARATION_CONDITIONNEMENTS, pid, refresh ? 'true' : 'false', 'calcul']);
    }
  };

  openSortiesProduction = (wi: WorkflowInstanceDTO) => {

    if (this.hasNextStepsSortiesProduction) {
      let runStep: RunStepDTO = new RunStepDTO();
      runStep.idWorkflowInstance = this.planProd.workflowInstance.id;
      runStep.revTypeValue = WORKFLOW_REVISION.DEBUTE;
      runStep.nextStepCode = WORKFLOW_TASKS.SORTIES_PRODUCTION;
      this.workflowSvc.goToNextStep(runStep).pipe(
        switchMap(data => {
          runStep.revTypeValue = WORKFLOW_REVISION.MODIFIE;
          return this.workflowSvc.runStep(runStep)
        }),
        catchError(err => this.utils.handleError(err))
      ).subscribe(response => {
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', runStep.idWorkflowInstance, 'sorties-production']);
        wi = response.one;
        this.workflowSvc.announceWorkflowInstance(wi);
      });
    } else {
      this.routeMapSvc.goToSecondaryRoute(['gestion-processus', wi.id, 'sorties-production']);
    }
  };

  openBulkEditionDetailDialog = () => {
    this.displayBulkDialog = true;
  };

  onSelectionChanged = (event: any) => {
    this.selectedDetails = event.selectedRowsData;
  };


  onCloseBulkDialog = () => {
    this.displayBulkDialog = false;
  };

  onEmitBulkDetailSupplier = (supplier: BulkDetailSupplier) => {
    //Affectation des donnnés du bulk
    this.selectedDetails.forEach((detail: PlanProductionDetailDTO) => {
      //Date
      detail.dateFab = this.utils.isNullOrEmpty(supplier.dateFab) ? detail.dateFab : supplier.dateFab;
      detail.dateLiv = this.utils.isNullOrEmpty(supplier.dateLiv) ? detail.dateFab : supplier.dateLiv;
      detail.dlc = this.utils.isNullOrEmpty(supplier.dlc) ? detail.dateFab : supplier.dlc;
      detail.isFabDateUpdate = supplier.isFabDateUpdate;

      //Equipe
      if (!this.utils.isNullOrEmpty(supplier.udpEquipe)) {
        detail.udpEquipe = supplier.udpEquipe;
        detail.udpEquipeId = supplier.udpEquipe.id;
        detail.equipeId = supplier.udpEquipe.idEquipe;
        detail.equipeLibelle = supplier.udpEquipe.equipeLibelle;
        detail.udpZoneDeProdId = supplier.udpEquipe.idUdpZoneDeProduction;
        detail.atelierLibelle = supplier.udpEquipe.zoneDeProductionLibelle;
      }

      detail.modificationManuelle = true;
    });

    if (this.selectedDetails.length > 0) {
      this.saveMassivePlanProdDetail(this.selectedDetails);
    }
  };

  goToProductPage = (idProduct: number) => {
    this.routeMapSvc.goToSecondaryRoute([FP_ROUTES.GPR, 'produit', 'true', idProduct, 'fichetechnique']);
  };

}
