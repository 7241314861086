import {Component, OnInit} from '@angular/core';

import {LoadingService} from "../../core/services/loading.service";

@Component({
  selector: 'yo-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  loading$ = this.loadingSvc.allLoading$;

  constructor(public loadingSvc: LoadingService) {
  }

  ngOnInit() {


  }

}
