import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {UniteDeProduction__denreesInterditesService} from '../../../../core/services/entities/unite-de-production__denrees-interdites.service';
import {Subscription} from 'rxjs/index';
import {ActivatedRoute} from '@angular/router';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {catchError, switchMap} from 'rxjs/operators';
import {UniteDeProduction__denreeInterditeDto} from '../../../../core/dtos/unite-de-production__denree-interdite-dto';
import {ObjectDTO} from '../../../../core/dtos/object-dto';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {IYoDatagrid} from '../../../../shared/IYoDatagrid';
import {DialogMsgSupplier} from '../../../../core/suppliers/dialog-msg-supplier';
import {ConfirmationService} from 'primeng/api';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';

@Component({
  selector: 'yo-grille-denrees-interdites',
  templateUrl: './grille-denrees-interdites.component.html',
  styleUrls: ['./grille-denrees-interdites.component.scss']
})
export class GrilleDenreesInterditesComponent extends IYoDatagrid implements OnInit, OnDestroy {


  help(): DialogMsgSupplier {
    return undefined;
  }

  initForm(dto: ObjectDTO) {
  }

  uniteDeProduction: UniteDeProductionDTO;
  denreesInterditesList: UniteDeProduction__denreeInterditeDto[];

  subRoute: Subscription;
  subLoadDenrees: Subscription;

  displayedColumns: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'denreeInterditeLibelle', header: 'Denrée interdite'},
    {field: 'denreeSubstitutionLibelle', header: 'Denrée de substitution'},
    {field: 'ratio', header: 'Ratio'},
  ];

  constructor(public utils: UtilsService,
              private confirmationService: ConfirmationService,
              public gds: GenericDatagridService,
              private udpDenreeInterditeSvc: UniteDeProduction__denreesInterditesService,
              private route: ActivatedRoute) {
    super();
  }


  ngOnInit() {
    this.subscriptionRouteData();
    this.subscriptionLoadDenrees();
    this.udpDenreeInterditeSvc.announceLoadDenreesByUdp();
  }
  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subLoadDenrees);
  }

  subscriptionRouteData() {
    this.subRoute = this.route.parent.parent.data.pipe(switchMap(data => {
        this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
        return this.udpDenreeInterditeSvc.getByIdUdp(this.uniteDeProduction.id);
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {
      this.denreesInterditesList = [];
      if (response && !this.utils.isCollectionNullOrEmpty(response.resultList)) {
        this.denreesInterditesList = response.resultList;
      }
    });
  }

  subscriptionLoadDenrees() {
    this.subLoadDenrees = this.udpDenreeInterditeSvc.loadDenreesByUdp$.pipe(
      switchMap(data => {
        return this.udpDenreeInterditeSvc.getByIdUdp(this.uniteDeProduction.id);
      }),
      catchError(err => this.utils.handleError(err)))
      .subscribe(response => {
        this.denreesInterditesList = [];
        if (response && !this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.denreesInterditesList = response.resultList;
        }
      });
  }

  openDeleteObject(udpDiDto: UniteDeProduction__denreeInterditeDto) {
    this.confirmationService.confirm({
      message: 'Êtes vous sûr ?',
      header: 'Confirmation',
      accept: () => {
        this.udpDenreeInterditeSvc.delete(udpDiDto.id)
          .subscribe(response => {
            if (!this.utils.isResponseSupplierError(response)) {
              this.denreesInterditesList = this.gds.refreshDatagridPrimengAfterDelete(this.denreesInterditesList, response, this.udpDenreeInterditeSvc.getEntityName()) as UniteDeProduction__denreeInterditeDto[];
              this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.INFO, `La denrée de substitution ${udpDiDto.denreeInterditeLibelle} est supprimée.`, '', 8000);
            }
          });
      }
    });
  }

  openEditObject(udpDiDto: UniteDeProduction__denreeInterditeDto) {
    // on ouvre le dialog 'Modification de la denrées interdites'
    if (this.uniteDeProduction) {
      this.udpDenreeInterditeSvc.announceOpenDialogModifierDenreeInterdite(udpDiDto);
    }
  }

}
