<p-dialog
  [dismissableMask]="false"
  [maximizable]="true"
  [(visible)]="displayDialog"
  [draggable]="false"
  [style]="{'width':'1010px'}"
  [contentStyle]="{'overflow':'visible'}"
  [modal]="true">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <span [innerHTML]="domSanitizer.bypassSecurityTrustHtml(getTitle(catalogueAchat))"> </span>
    </div>
  </ng-template>


  <p-tabMenu [model]="articleFeatures" [activeItem]="activeItem">
  </p-tabMenu>

  <div class="mg-t-5" [style.max-height.px]="getMaxBodyHeight(cads)" style=" overflow-y:auto;overflow-x: hidden">
    <router-outlet></router-outlet>
  </div>

  <p-toast [key]="MSG_KEY.DIALOG_EDIT_ARTICLE" [position]="MSG_POSITION.BOTTOM_RIGHT"></p-toast>


</p-dialog>
