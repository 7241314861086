import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ActivatedRoute} from "@angular/router";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {ConfirmationService} from "primeng/api";
import ModeleConditionnementPlcDTO from "../../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {ClientsBusinessService} from "../../../../core/services/gestion-clients/clients.service";
import {HELP_FOLDERS} from "../../../../core/constants";

@Component({
  selector: 'yo-mp-plc',
  templateUrl: './mp-points-livraison.component.html',
  styleUrls: ['./mp-points-livraison.component.scss']
})
export class PointsLivraisonComponent implements OnInit, OnDestroy {

  plcList: PointDeLivraisonDTO[] = [];
  modelePlc: ModeleConditionnementPlcDTO;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  subSupplier: Subscription;
  subBindPlcsSupplier: Subscription;

  hasIDistri = false;

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plc-dialog-plc';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private plcSvc: PointDeLivraisonService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private confirmationSvc: ConfirmationService,
              private clientsBusinessSvc: ClientsBusinessService){
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSupplier);
    this.utils.unsubscribe(this.subBindPlcsSupplier);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initDataFromSupplierSubscription();
    this.initListenerBindPlcDoneSubscription();
  }

  initListenerBindPlcDoneSubscription = () => {
    this.subBindPlcsSupplier = this.clientsBusinessSvc.bindPlcsDone$
      .subscribe(response => {
        this.initPlcSiteList(this.modelePlc.id);
      })
  };

  initDataFromSupplierSubscription = () => {
    this.subSupplier = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlcSupplier) {
          this.modelePlc = data.modelePlcSupplier.modelePlc;
          this.initPlcSiteList(data.modelePlcSupplier.idModeleConditionnementPlc);
        }
      });
  };

  initPlcSiteList = (mcpId): void => {
    this.plcSvc.getPlcWithMcPlcList(mcpId)
      .subscribe(response => {
        this.plcList = response.resultList
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canEdit = (): boolean => this.hasIDistri;

  help = (): void => {

  };

  convertNumber = (value): number => parseInt(value, 10);

  openDialogAjoutPlcs = (): void => {
    this.plcSvc.openDialogBindPlcsWithMcPlc(this.modelePlc);
  };

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) association(s) sélectionnée(s) ?',
      accept: () => {
        this.clientsBusinessSvc.deleteMultiplePlc(this.selectedRows)
          .subscribe(() => this.initPlcSiteList(this.modelePlc.id));
      }
    });
  };

}
