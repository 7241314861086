import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import ModeleConditionnementPlcDTO from "../../../dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {Subject} from "rxjs/index";
import {HttpService} from "../../technique/http.service";
import {ResponseWrapper} from "../../../suppliers/wrappers/response-wrapper";
import ResteDTO from "../../../dtos/conditionnement/reste-dto";
import {ConviveDTO} from "../../../dtos/convive-dto";

export const URL_GET_ALL_MODELES_PLC = `dolrest/conditionnements/modeles-conditionnements-plc/list`;
export const URL_GET_MODELE_PLC = `dolrest/conditionnements/modeles-conditionnements-plc/findById`;
export const URL_SAVE = `dolrest/conditionnements/modeles-conditionnements-plc/save`;
export const SAVE_PARAMETRAGE_MODELE_PLC = `dolrest/conditionnements/modeles-conditionnements-plc/saveParametrage`;
export const URL_SAVE_ROW_PARAMETRAGE = `dolrest/conditionnements/modeles-conditionnements-plc/saveRowParametrage`;
export const URL_DUPLICATE_MODELE = `dolrest/conditionnements/modeles-conditionnements-plc/duplicate`;

@Injectable({
  providedIn: 'root'
})
export class ModelesPlcService {

  private subMcPlcSaved = new Subject<MpPlcSavedSupplier>();
  mcPlcSaved$ = this.subMcPlcSaved.asObservable();

  private subCreationRowParametrageAsked = new Subject<ModeleConditionnementPlcDTO>();
  creationRowParametrageAsked$ = this.subCreationRowParametrageAsked.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles de conditionnement pour des points de livraison
   * @returns {Observable<ModeleConditionnementPlatDTO>}
   */
  getAllModelesConditionnementsPlc = (): Observable<ResponseWrapper<ModeleConditionnementPlcDTO>> => this.httpSvc.get(URL_GET_ALL_MODELES_PLC, null);

  findById = (id: number): Observable<ResponseWrapper<ModeleConditionnementPlcDTO>> => this.httpSvc.get(`${URL_GET_MODELE_PLC}/${id}`, null);

  /**
   * Enregistre un modèle plc
   * @param data modèle plc
   */
  save = (data: ModeleConditionnementPlcDTO) => this.httpSvc.post(URL_SAVE, data);

  duplicateModele = (modeleIdToDuplicate: number, duplicationSiteSelectedId: number) => this.httpSvc.post(`${URL_DUPLICATE_MODELE}/${modeleIdToDuplicate}/${duplicationSiteSelectedId}`, null);

  /**
   * Enregistre le paramètrage de la grille du modèle plc
   * @param data
   */
  saveParametrage = (data: ModelePlcParametrage) => this.httpSvc.post(SAVE_PARAMETRAGE_MODELE_PLC, data);

  /**
   * Annonce l'enregistrement d'un modèle plc
   * @param mcp modèle plc enregistré
   * @param isUpdate
   */
  announceMcPlcSaved = (mcPlc: ModeleConditionnementPlcDTO, isUpdate: boolean = false) => {
    this.subMcPlcSaved.next({ mcPlc, isUpdate } as MpPlcSavedSupplier);
  };

  /**
   * Annonce la création d'une ligne de paramétrage
   * @param mcplc (Ne doit jamais être renseigné si on respecte le cas général)
   */
  announceCreationRowParametrageAsked = (mcplc?: ModeleConditionnementPlcDTO) => {
    this.subCreationRowParametrageAsked.next(mcplc);
  };

  /**
   * Enregistre le paramétrage d'une ligne de la grille
   * @param modelePlc Le modèle conditionnement plc concerné par la configuration
   * @param mcpCvList L'ensemble des modèles conditionnement plat <-> conditionnement variante à gérer
   * @param prestationsList L'ensemble des prestations à gérer
   */
  saveRowParametrage = (modelePlc: ModeleConditionnementPlcDTO, idsMcpCvList: number[], prestationsList: ConviveDTO[]) => {
    const prestationsWithoutSite: number[] = prestationsList.map(p => p.id);
    return this.httpSvc.post(URL_SAVE_ROW_PARAMETRAGE, {
      modelePlcId: modelePlc.id,
      idsMcpCvList,
      idsPrestationsList: prestationsWithoutSite
    });
  };

}

export interface MpPlcSavedSupplier {
  isUpdate: boolean;
  mcPlc: ModeleConditionnementPlcDTO;
}

export interface ModelePlcParametrage {
  grid: any; // GridModelePlcParametrage
  idMcplc: number;
  queues: QueueModelePlcParametrage[];
}

export interface QueueModelePlcParametrage {
  idPrestation: number;
  idmcPlcPrestation: number;
  reste: ResteDTO;
}

export interface GridModelePlcParametrage {
  mcpLabel: string;
  conditionnementLabel: string;
  varianteLabel: string;
  idmcPlcMcpCV: number;
  id: number;
  data: DataGridModelePlcParametrage[];
}

export interface DataGridModelePlcParametrage {
  idPrestation: number;
  mcPlcPrestaMcPlcMcpCvId: number;
  mcplc__prestaid: number;
  mcplc__mcpCvid: number;
  actif: boolean;
}
