<div class="d-flex justify-content-between mg-b-10">
  <div>


    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button
      pButton
      label="CRÉER"
      icon="fa fa-plus"
      type="button"
      (click)="openEditDialog(undefined)"
      [disabled]="!canCreate()"
      [class.disable]="!canCreate()"
    >
    </button>
  </div>
  <ng-container *ngIf="!cmcEquipeSvc.checkUdpWithTypeFab(cmcEquipeList, udpEquipeList,tacheFabricationList)">
            <span class="floatright">
            <i class="fas fa-exclamation-triangle fa-2x danger-color space"></i>Plusieurs équipes sont affectées sur la même unité de production avec le même type de fabrication.
              </span>
  </ng-container>
  <div>

  </div>
</div>

<div class="border-empty-table">
  <p-table
    sortMode="single"
    [value]="cmcEquipeList"
    [columns]="cols"
    [scrollable]="true"
    [scrollHeight]="utils.scrollHeightContent(305)"
    styleClass="p-datatable-sm"
    [rows]="20"
    [loading]="loading"
    [rowsPerPageOptions]="[5,10,15,20,50,100]"
    [paginator]="true"
    [totalRecords]="totalRecords"
    dataKey="id"
    #dt
  >

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
          <col *ngSwitchCase="'actions'" style="width:50px">
          <col *ngSwitchCase="'tacheLibelle'" style="width:100px">
          <col *ngSwitchCase="'equipeLibelle'" style="width:100px">
          <col *ngSwitchCase="'zoneDeProductionLibelle'" style="width:100px">
          <col *ngSwitchCase="'uniteDeProductionLibelle'" style="width:100px">
          <col *ngSwitchCase="'jours'" style="width:250px">
          <col *ngSwitchCase="'actif'" style="width:50px">
        </ng-container>
      </colgroup>
    </ng-template>


    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">

          <ng-container [ngSwitch]="col.field">

            <ng-container *ngSwitchCase="'tacheLibelle'">
              <th [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'zoneDeProductionLibelle'">
              <th [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'uniteDeProductionLibelle'">
              <th [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'actions'">
              <th>
                Actions
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'equipeLibelle'">
              <th [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'jours'">
              <th>
                <div class="d-flex justify-content-between">
                  <div>{{col.header}}</div>
                  <p-checkbox label="Détails" [disabled]="cmcEquipeList.length === 0 " [(ngModel)]="moreDetails"
                              binary="true"></p-checkbox>
                </div>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'actif'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
          </ng-container>

        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">

      <tr>
        <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'actions'">
            <td>
              <ng-container *ngIf="auth2Svc.isSiteLocal(contratMenuConvive?.site?.id)">

                <yo-cell-button (yoNavigation)="openEditDialog(rowData)"
                                [yoTooltipShowDelay]="1500"
                                [yoMaxWidth]="true"
                                [yoWidthPercent]="30"
                                [yoTextAlign]="'center'"
                                yoIconClass="fa fa-edit"
                                pTooltip="Modifier"
                                tooltipPosition="right"
                                showDelay="500"
                ></yo-cell-button>

                <yo-cell-button (yoNavigation)="cmcEquipeSvc.openDeleteDialogSpec(cmcEquipeList,rowData,totalRecords)"
                                [yoTooltipShowDelay]="1500"
                                [yoMaxWidth]="true"
                                [yoWidthPercent]="30"
                                [yoTextAlign]="'center'"
                                [yoIconClass]="'fa fa-trash'"
                                pTooltip="Supprimer"
                                tooltipPosition="right"
                                showDelay="500"
                ></yo-cell-button>

              </ng-container>
              <ng-container *ngIf="!auth2Svc.isSiteLocal(contratMenuConvive?.site?.id)">
                <yo-cell-button (yoNavigation)="false"
                                [yoTooltipShowDelay]="1500"
                                [yoMaxWidth]="true"
                                [yoWidthPercent]="30"
                                [yoTextAlign]="'center'"
                                [yoIconClass]="'fa fa-eye'"
                                pTooltip="Voir"
                                tooltipPosition="right"
                                showDelay="500"
                ></yo-cell-button>
              </ng-container>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'uniteDeProductionLibelle'">
            <td>
              <label class="cursor space" pTooltip="Aller sur l'unité de production"
                     (click)="openUdpFabrication(rowData.idUniteDeProduction)">{{rowData.uniteDeProductionLibelle |
                uppercase}}</label>
            </td>
          </ng-container>


          <ng-container *ngSwitchCase="'zoneDeProductionLibelle'">
            <td>
              <label class="space"
                     pTooltip="Aller sur l'unité de production"
                     (click)="openUdpFabrication(rowData.idUniteDeProduction)">{{utils.capitalize(rowData.zoneDeProductionLibelle)}}</label>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'equipeLibelle'">
            <td class="no-overflow">
              <!--<label class="space">{{utils.capitalize(rowData.zoneDeProductionLibelle)}},</label>-->
              <label class="badge badge-secondary cursor no-overflow" (click)="openEditDialog(rowData)"
                     pTooltip="Modifier l'affectation"> {{rowData.equipeLibelle | uppercase}}</label>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'tacheLibelle'">
            <td>
              <label>{{rowData.tacheLibelle | uppercase}}</label>
            </td>
          </ng-container>


          <ng-container *ngSwitchCase="'jours'">
            <td style="vertical-align: top;">
              <!--afficher la relation entre jours de conso et jours de fab-->
              <yo-cmc-equipes-jours [contratMenuConvive]="contratMenuConvive"
                                    [moreDetails]="moreDetails"
                                    [cmcEquipe]="rowData"
                                    [udpEquipeList]="udpEquipeList">
              </yo-cmc-equipes-jours>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'actif'">
            <td>
              <table
                [escape]="false"
                [pTooltip]="cmcSvc.equipeActiveTooltip(rowData)">
                <tr>
                  <td>
                    <yo-readonly
                      [yoType]="'boolean'"
                      [yoValue]="cmcSvc.isEquipeActive(rowData)"></yo-readonly>
                  </td>
                  <td>
                    <i
                      *ngIf="!cmcSvc.isEquipeActive(rowData)"
                      class="fas fa-exclamation-triangle fa-2x danger-color"></i>
                  </td>
                </tr>
              </table>

            </td>
          </ng-container>
        </ng-container>

      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      <tr>
        <td style="text-align:left;">{{utils.getTotalRecordsLabel('équipe', totalRecords)}}</td>
      </tr>
    </ng-template>

  </p-table>
</div>


<yo-cmc-equipe-dialog-edit></yo-cmc-equipe-dialog-edit>
