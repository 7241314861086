<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [breakpoint]="640"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-trash mg-r-5"></i> Supprimer les lots périmés du stock
    </div>
  </ng-template>


  <div class="p-g" style="max-width:700px;margin-bottom:10px">

    <!--    afficher l'option de suppression des périmés d'un article que si on est sur un article qui a du stock-->
    <ng-container *ngIf="!utils.isNullOrEmpty(selectedMatierePremiere) && !selectedMatierePremiere.noStock">
      <div class="p-g-12">
        <p-radioButton name="group2" value="{{LOTS_PERIMES.DENREE_SELECTIONNEE}}"
                       label="Éliminer les lots périmés de la denrée sélectionnée" [(ngModel)]="deleteOption"
                       inputId="preopt2"></p-radioButton>
      </div>
    </ng-container>

    <div class="p-g-12">
      <p-radioButton name="group2" value="{{LOTS_PERIMES.TOUS}}"
                     label="Éliminer tous les lots périmés qui correspondent aux critères de recherche"
                     [(ngModel)]="deleteOption" inputId="preopt1"></p-radioButton>
    </div>
  </div>

  <ng-template pTemplate="footer">

    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-trash" (click)="deletePerimes()" pTooltip="Supprimer les lots périmés"
              label="Supprimer"
              showDelay="500"></button>
    </span>
    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-times" (click)="closeDialog()" pTooltip="Annuler" label="Annuler"
              class="p-button-secondary"
              showDelay="500"></button>
    </span>


  </ng-template>

</p-dialog>
