import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {ActivatedRoute} from '@angular/router';
import ConditionnementVarianteDTO from '../../../../core/dtos/conditionnement/conditionnement-variante-dto';
// tslint:disable-next-line:import-spacing
import ConditionnementVarianteDeclinaisonDTO
  from '../../../../core/dtos/conditionnement/conditionnement-variante-declinaison-dto';
// tslint:disable-next-line:import-spacing
import ModeleConditionnementPlatDeclinaisonDTO
  from '../../../../core/dtos/conditionnement/modele-conditionnement-plat-declinaison-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from '../../../../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto';
import {TachesService} from '../../../../core/services/entities/taches.service';
import {TacheDTO} from '../../../../core/dtos/tache-dto';
import {TYPE_TACHE} from '../../../../core/services/entities/type-tache.service';
import {DeclinaisonDTO} from '../../../../core/dtos/declinaison-dto';
import ResteDTO from '../../../../core/dtos/conditionnement/reste-dto';
import {RestesService} from '../../../../core/services/conditionnements/restes.service';
import {cloneDeep as _cloneDeep} from 'lodash';
import {
  ModelePlatParametrage,
  ModelesPlatsService
} from '../../../../core/services/conditionnements/modeles-plats.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';

@Component({
  selector: 'yo-mp-parametrage',
  templateUrl: './mp-parametrage.component.html',
  styleUrls: ['./mp-parametrage.component.scss']
})
export class ParametrageConditionnementModelesPlatsComponent implements OnInit, OnDestroy {

  subSupplier: Subscription;

  conditionnementVariantesList: ConditionnementVarianteDTO[];
  conditionnementVariantesDeclinaisonsList: ConditionnementVarianteDeclinaisonDTO[];
  modelesConditionnementsPlatDeclinaisonsList: ModeleConditionnementPlatDeclinaisonDTO[];
  mcpConditionnementVarianteList: ModeleConditionnementsPlatConditionnementsVariantesDTO[];

  /**
   * Totalité des déclinaisons
   */
  declinaisonList: DeclinaisonDTO[] = [];

  /**
   * Gestion des queues
   */
  queuesConfiguration: any = {};

  /**
   * Totalité des restes
   */
  resteList: ResteDTO[];

  /**
   *  Un trompe oeil pour DevExtreme pour avoir 1 footer d'une seule ligne.
   */
  footerList: any[] = [{id: 1}];

  /**
   * Mapping entre le n° de colonne DevExtreme et l'identifiant d'une déclinaison
   */
  mappingColumnsDeclinaisons: Map<number, number> = new Map<number, number>();

  /**
   * Totalité des tâches
   */
  tachesList: TacheDTO[];

  gridParametrage: any[];

  hasIDistri = false;

  idMcp: number;
  mode: string;

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plats-parametrage';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private resteSvc: RestesService,
              private tacheSvc: TachesService,
              private mpcSvc: ModelesPlatsService) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSupplier);
  }

  ngOnInit(): void {
    this.initDataFromSupplierSubscription();
    this.initHasGestionIDistri();
  }

  openCreateRowParametrage(): void {
    this.mpcSvc.announceCreationRowParametrageAsked();
  }

  initHasGestionIDistri(): void {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  }

  initDataFromSupplierSubscription() {
    this.subSupplier = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlatSupplier) {
          this.conditionnementVariantesList = data.modelePlatSupplier.conditionnementVarianteList;
          this.conditionnementVariantesDeclinaisonsList = data.modelePlatSupplier.conditionnementVarianteDeclinaisonList;
          this.modelesConditionnementsPlatDeclinaisonsList = data.modelePlatSupplier.mcpDeclinaisonList;
          this.mcpConditionnementVarianteList = data.modelePlatSupplier.mcpConditionnementVarianteList;
          this.idMcp = data.modelePlatSupplier.idModeleConditionnementPlat;
          this.mode = data.modelePlatSupplier.modelePlatConditionnement.mode;

          if(this.modelesConditionnementsPlatDeclinaisonsList) {
            this.declinaisonList = this.modelesConditionnementsPlatDeclinaisonsList
              .map(mcpd => mcpd.declinaison);
          }

          this.initQueuesConfiguration();
          this.initRestes();
          this.initTaches();
          this.initGridParametrage();
        }
      });
  }

  changeQueue($event, columnIndex) {
    if (this.queuesConfiguration[columnIndex]) {
      this.queuesConfiguration[columnIndex].reste = this.resteList.find(r => r.id === $event.value);
    }
  }

  private initQueuesConfiguration() {
    if (this.modelesConditionnementsPlatDeclinaisonsList) {
      this.modelesConditionnementsPlatDeclinaisonsList.forEach((mcpd, idx) => {
        // le mapping entre l'index et la déclinaison ID est expliquée plus bas (ligne 111) :
        this.queuesConfiguration[idx + 2] = {reste: mcpd.reste, idmcpd: mcpd.id}
      });
    }
  }

  private initRestes() {
    this.resteSvc.getAll()
      .subscribe(restes => this.resteList = restes.resultList );
  }

  private initTaches() {
    this.tacheSvc.getAll()
      .subscribe(taches => {
        this.tachesList = this.tacheSvc.filterByTypeTache(taches.resultList, TYPE_TACHE.CONDITIONNEMENT);
      });
  }

  private initGridParametrage() {
    if (this.conditionnementVariantesList) {
      this.gridParametrage = this.conditionnementVariantesList.map(cv => {
        const current = this.mcpConditionnementVarianteList.find((m: ModeleConditionnementsPlatConditionnementsVariantesDTO) => {
          return m.conditionnementVariante && m.conditionnementVariante.id === cv.id
        });

        const declinaisons = {};

        this.declinaisonList = this.declinaisonList.filter(declinaison => this.conditionnementVariantesDeclinaisonsList.find((cvd: ConditionnementVarianteDeclinaisonDTO) =>
          cvd.modeleConditionnementsPlat__variantes__conditionnements &&
          cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante &&
          cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante.id === cv.id &&
          cvd.modeleConditionnementPlat__declinaison.declinaison &&
          cvd.modeleConditionnementPlat__declinaison.declinaison.id === declinaison.id));

        this.declinaisonList.forEach((declinaison, idx) => {
          const currentCvd = this.conditionnementVariantesDeclinaisonsList.find((cvd: ConditionnementVarianteDeclinaisonDTO) =>
            cvd.modeleConditionnementsPlat__variantes__conditionnements &&
            cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante &&
            cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante.id === cv.id &&
            cvd.modeleConditionnementPlat__declinaison.declinaison &&
            cvd.modeleConditionnementPlat__declinaison.declinaison.id === declinaison.id
          );

          // + 2 car les premières colonnes sont conditionnement et variante :
          if (currentCvd) {
            const key = idx + 2;
            this.mappingColumnsDeclinaisons.set(key, declinaison.id);
            // declinaison => objet dont la clé est l'association de l'identifiant d'une condition variante et d'une déclinaison.
            // En effet, chaque valeur prescrite dans une cellule est faite pour une déclinaison d'une condition variante.
            declinaisons[key] = {
              [cv.id]: { // identifiant pour représenter la ligne de l'ihm
                mcp__did: currentCvd.modeleConditionnementPlat__declinaison.id,
                mcp__cvid: currentCvd.modeleConditionnementsPlat__variantes__conditionnements.id,
                cvdid: currentCvd.id, // Identifiant à utiliser pour le backend
                contient: currentCvd ? currentCvd.contient : 0.0,
                effectifNourri: currentCvd ? currentCvd.effectifNourri : 0.0,
                poidsNet: currentCvd ? currentCvd.poidsNet : 0.0,
                uniteAConditionner: currentCvd && currentCvd.modeleConditionnementPlat__declinaison && currentCvd.modeleConditionnementPlat__declinaison.modeleConditionnementPlat && currentCvd.modeleConditionnementPlat__declinaison.modeleConditionnementPlat.uniteAConditionner.abreviation ? currentCvd.modeleConditionnementPlat__declinaison.modeleConditionnementPlat.uniteAConditionner.abreviation : 'POR',
              }
            }
          }
        });

        const result = {
          id: cv.id,
          conditionnementLabel: cv.conditionnement ? cv.conditionnement.libelle : '',
          varianteLabel: cv.variante ? cv.variante.libelle : 'Aucune',
          remarque: current && current.remarque ? current.remarque : '',
          idtache: current && current.tache ? current.tache.id : null,
          idmcpcv: current.id,
          declinaisons
        };

        return (result);
      });
    }
  }

  /**
   * Enregistre les valeurs paramétrées de la grille.
   */
  save(): void {
    // Préparation de l'objet à envoyer au back-end :
    const grid = _cloneDeep(this.gridParametrage);
    grid.forEach(g => {

      g.data = [];
      Object.keys(g.declinaisons).forEach(col => {
        let idDeclinaison = null;
        const column = g.declinaisons[col];
          const idCol = parseInt(col, 10);
          Object.keys(column).forEach(c => {
            const idRow = parseInt(c, 10);
            idDeclinaison = this.mappingColumnsDeclinaisons.get(idCol);
            Object.keys(column[idRow]).forEach(rowcol => {
              const info = g.declinaisons[idCol][idRow];
              const { contient, effectifNourri, poidsNet, mcp__did, mcp__cvid  } = info;
              if (g.data.findIndex(i => i.idDeclinaison === idDeclinaison && i.idCvd === info.cvdid) < 0) {
                g.data.push({idDeclinaison, idCvd: info.cvdid, contient, effectifNourri, poidsNet, mcp__did, mcp__cvid });
              }
            });
        });
      });


      delete g.declinaisons;

    });

    const data: ModelePlatParametrage = {
      idMcp: this.idMcp,
      grid,
      queues: [],
      mode: this.mode
    };

    Object.keys(this.queuesConfiguration).forEach(idCol => {
      data.queues.push({
        idDeclinaison: this.mappingColumnsDeclinaisons.get(parseInt(idCol, 10)),
        reste: this.queuesConfiguration[idCol].reste,
        idmcpd: this.queuesConfiguration[idCol].idmcpd
      });
    });

    this.mpcSvc.saveParametrage(data)
      .subscribe(response => {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Paramétrage réalisé avec succès.');
      });
  }

  public help(): DialogMsgSupplier {
    const dms = new DialogMsgSupplier();
    dms.title = `Paramétrage des modèles de plats`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  }

}
