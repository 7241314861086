<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="input-group-sm ">
          <input pInputText formControlName="libelle"/>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Couleur <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <p-colorPicker [(ngModel)]="couleurSelected" formControlName="couleur"></p-colorPicker>
        <input class="ml-4" [(ngModel)]="couleurSelected" formControlName="couleur" />
        <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'couleur',label:'Couleur'}"></ng-container>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Icône">Icône <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <p-dropdown [options]="imagesPath" formControlName="icone" [(ngModel)]="iconPath" optionLabel="path" [showClear]="true"
                    placeholder="Sélectionner une icône">
          <ng-template pTemplate="selectedItem">
            <div class="icone-item text-center" *ngIf="iconPath">
              <img src="{{iconPath.path}}" style="width: 25px" />
            </div>
          </ng-template>
          <ng-template let-imagePath pTemplate="item">
            <div class="icone-item text-center">
              <img src="{{imagePath.path}}" style="width: 25px" />
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Ordre">Ordre <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <p-dropdown [options]="orders" [(ngModel)]="orderSelected" formControlName="ordre" optionLabel="value"
                    placeholder="Choississez un ordre d'affichage">
          <ng-template pTemplate="selectedItem">
            {{orderSelected.value}}
          </ng-template>
          <ng-template let-order pTemplate="item">
            {{order.value}}
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Type de tâche">Type de tâche <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <p-dropdown [options]="typesTacheList" formControlName="typeTache" optionLabel="libelle" placeholder="Choississez un type de tâche"></p-dropdown>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel" pTooltip="Types d'équipement">Types d'équipement</div>
      <div class="col-md-8">
        <p-multiSelect [options]="typesEquipement"
                       formControlName="typesEquipement"
                       [(ngModel)]="typesEquipementSelected"
                       defaultLabel="Sélectionner un ou des type(s) d'équipement"
                       optionLabel="libelle"
                       display="chip"></p-multiSelect>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel" pTooltip="Types d'utilisateur">Types d'utilisateur <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <p-multiSelect [options]="typesUtilisateur"
                       formControlName="typesUtilisateur"
                       [(ngModel)]="typesUtilisateurSelected"
                       defaultLabel="Sélectionner un ou des type(s) d'utilisateur"
                       optionLabel="libelle"
                       display="chip"></p-multiSelect>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel" pTooltip="Motifs de non conformité">Motifs de non conformité</div>
      <div class="col-md-8">
        <p-multiSelect [options]="motifs"
                       formControlName="motifsNonConformite"
                       [(ngModel)]="motifsSelected"
                       defaultLabel="Sélectionner un ou des motif(s) de non conformité"
                       optionLabel="libelle"
                       display="chip"></p-multiSelect>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Enregistrer"
        [disabled]="!canModify()"
        [class.disable]="!canModify()">
      </button>
      <button
        class="mg-l-5 p-button-secondary"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
