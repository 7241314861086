import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {ContratMenuService} from '../../../core/services/gestioncontrats/contrat-menu.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {GROUP_POSITION, GroupFormFields} from '../../../core/suppliers/group-formfields';
import {ContratmenuDTO} from '../../../core/dtos/contratmenu-dto';
import {FormOptions} from '../../../core/suppliers/form-options';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';

@Component({
  selector: 'yo-dialog-contrat-form',
  templateUrl: './dialog-contrat-form.component.html',
  styleUrls: ['./dialog-contrat-form.component.scss']
})
export class DialogContratFormComponent implements OnInit, OnDestroy{

  @Input() displayDialog:boolean = false;
  @Output() onClose = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<BonCfDTO>();

  formOptions: FormOptions = {width: 560};
  form: FormGroup;
  formContratMenu: FormGroup = new FormGroup({});
  contratMenu : ContratmenuDTO = new ContratmenuDTO();

  fields: FormFieldBaseSupplier<any>[];
  entityName: string;
  groups : GroupFormFields[] = [];

  subEditContratMenu : Subscription;
  subCreateContratMenu : Subscription;

  title: string;

  constructor(public utils: UtilsService,
              private contratSvc : ContratMenuService,
              private auth2Svc: Auth2Service,
              private gfs:GenericFormService){}

  ngOnInit(): void{
    this.initEmptyForm();
    this.initCreateMode();
    this.initEditMode();
  }

  ngOnDestroy(): void{
    this.utils.unsubscribe(this.subCreateContratMenu);
    this.utils.unsubscribe(this.subEditContratMenu);
  }

  initEmptyForm = (): void => {
    this.form = new FormGroup({
      formContratMenu: this.formContratMenu
    });
  };

  initCreateMode = (): void => {
    this.subCreateContratMenu = this.contratSvc.createContratMenu$.subscribe( response =>{
      this.contratMenu = new ContratmenuDTO();
      this.contratMenu.id = 0;

      this.title ='Création d\'une offre alimentaire';
      this.initGroups(this.contratSvc.createEmptyDTO());
    });
  };

  initEditMode = (): void => {
    this.subEditContratMenu = this.contratSvc.editContratMenu$.subscribe((response : ContratmenuDTO) => {
      this.contratMenu = response;
      this.title =`Edition de l'offre alimentaire ${response.libelle}`;
      this.initGroups(response);
    });
  };

  closeDialog = (response : any): void => {
    this.displayDialog = false;
    this.onClose.emit(response);
  };

  canModify = () => {
    if(!this.utils.isNullOrEmpty(this.formContratMenu.controls['site'])){
      let idSite: number = this.formContratMenu.controls['site'].value.id;
      return this.auth2Svc.isSiteLocal(idSite);
    }else{
      return true;
    }
  };

  initGroups = (contratMenu :ContratmenuDTO) => {

    const fieldsContratMenu= this.contratSvc.getFields(contratMenu);
    const fieldscontratKey = ['site','libelle','code','actif'];
    const fieldsInfo = this.gfs.getFieldsByKey(fieldscontratKey, fieldsContratMenu);
    this.formContratMenu = this.gfs.toFormGroup(fieldsInfo);

    this.form = new FormGroup({
      formContratMenu: this.formContratMenu
    });

    this.groups = [
      {
        titlePanel: 'Offre Alimentaire',
        fields: fieldsInfo,
        form: this.formContratMenu,
        titleIcon: 'fas fa-handshake',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['site','libelle','code','actif']
        }
      }
    ];
  };

  saveContratMenu = () => {
    this.contratMenu.site = this.formContratMenu.controls['site'].value;
    this.contratMenu.libelle = this.formContratMenu.controls['libelle'].value;
    this.contratMenu.code = this.formContratMenu.controls['code'].value;
    this.contratMenu.actif = this.formContratMenu.controls['actif'].value;

    this.gfs.saveOne(this.contratMenu, this.contratSvc.getEntityName()).subscribe( response => {
      this.utils.showMsg(MSG_KEY.ROOT,MSG_SEVERITY.SUCCESS,'L\'offre alimentaire '+this.contratMenu.libelle+' a bien été enregistré');
      this.contratSvc.announceSavedInstance(response);
      this.closeDialog(response);
    });
  };
}
