<p-dialog
  [(visible)]=" utils.isNullOrEmpty(displayDialog) ? false :  displayDialog"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [modal]="true"
  position="top"
  [style]="{'min-width':'90wh'}"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-edit mg-r-5"></i>
      <ng-container *ngIf="lotMarche?.id > 0">
        <span class="mg-l-5">{{lotMarche?.libelle}}</span>
      </ng-container>
      <ng-container *ngIf="lotMarche?.id === 0">
        <span class="mg-l-5">CRÉATION D'UN LOT MARCHÉ</span>
      </ng-container>
    </div>
  </ng-template>

  <p-tabMenu [model]="lotMarcheTabMenu" [activeItem]="activeItem">
  </p-tabMenu>

  <div class="mg-t-5" [style.max-height]="'calc(80vh)'" style=" overflow-y:auto;overflow-x: hidden">
    <router-outlet></router-outlet>
  </div>

  <p-toast [key]="MSG_KEY.DIALOG_EDIT_ARTICLE" [position]="MSG_POSITION.BOTTOM_RIGHT"></p-toast>


</p-dialog>
