import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {ListeBesoinsDenreeDto} from '../../dtos/liste-besoins-denree-dto';
import {ListeBesoinsDto} from '../../dtos/liste-besoins-dto';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {HttpService} from "../technique/http.service";

export const URL_GET_PLATS_DEMANDEURS_ARTICLE = `dolrest/gestion-listes-besoins/plats-demandeurs/article`;
export const URL_GET_BESOINS_ARTICLES = `dolrest/gestion-listes-besoins/liste-besoins/articles`;
export const URL_POST_REPLACE_LBD_ARTICLE = `dolrest/gestion-listes-besoins/liste-besoins/lbd/replace-article`;
export const URL_POST_SAVE_LBD_LIST = `dolrest/gestion-listes-besoins/liste-besoins/lbd/save-list`;
export const URL_GET_ARTICLES_DISPONIBLES = `dolrest/gestion-listes-besoins/liste-besoins/lbd/articles-disponibles`;
export const URL_POST_CHANGE_REGLE_PREFERE = `dolrest/gestion-listes-besoins/liste-besoins/change-regle-prefere`;

@Injectable({
  providedIn: 'root'
})
export class BesoinsService {

  private subjectPlatsDemandeurs = new Subject();
  platsDemandeurs$ = this.subjectPlatsDemandeurs.asObservable();

  private subjectLbdView = new Subject();
  lbdView$ = this.subjectLbdView.asObservable();

  private subjectCalculReglePrefereTermine = new Subject();
  calculReglePrefereTermine$ = this.subjectCalculReglePrefereTermine.asObservable();

  private subjectOpenPropositionDialogDenree = new Subject();
  openPropositionDialogDenree$ = this.subjectOpenPropositionDialogDenree.asObservable();

  private subjectOpenAjoutDenreePropositionCommandeDialog = new Subject();
  openAjoutDenreePropositionCommande$ = this.subjectOpenAjoutDenreePropositionCommandeDialog.asObservable();

  constructor(private prefUsrSvc: PreferencesUtilisateurService,
              private gds: GenericDatagridService,
              private utils: UtilsService,
              private httpSvc: HttpService) {
  }

  announcePlatsDemandeurs(event: any) {
    this.subjectPlatsDemandeurs.next(event);
  }

  announceLbdView(event: any) {
    this.subjectLbdView.next(event);
  }

  announceCalculReglePrefereTermine(event: any) {
    this.subjectCalculReglePrefereTermine.next(event);
  }

  announceOpenPropositionDialogDenree(event: any) {
    this.subjectOpenPropositionDialogDenree.next(event);
  }

  announceOpenAjoutDenreePropositionCommande(event: any) {
    this.subjectOpenAjoutDenreePropositionCommandeDialog.next(event);
  }

  /**
   * Indique si la {@link ListeBesoinsDto} passée en paramètre est modifiable.
   * @param listeBesoinDTO
   */
  canModify(listeBesoinDTO: ListeBesoinsDto): boolean {
    const canModify = this.gds.canModify(listeBesoinDTO) && !listeBesoinDTO.archive;
    return canModify;
  }

  getPlatsDemandeursOfArticle(lbd: ListeBesoinsDenreeDto) {
    const params: HttpParams = new HttpParams()
      .set('idListeBesoin', lbd.listeBesoinId + '')
      .set('idUniteDeProduction', lbd.uniteDeProductionId + '')
      .set('idProduitDeclinaison', lbd.produitDeclinaisonId + '')
      .set('dateConso', this.utils.getYYYYMMDD(moment(lbd.dateConsommation)));
    return this.httpSvc.get(URL_GET_PLATS_DEMANDEURS_ARTICLE, params);
  }

  getLbdList(idWorkflowInstance: number) {
    const params: HttpParams =new HttpParams().set('idWorkflowInstance', idWorkflowInstance + '');
    return this.httpSvc.get(URL_GET_BESOINS_ARTICLES, params);
  }

  saveLbdList(lbdList: ListeBesoinsDenreeDto[]) {
    return this.httpSvc.post(URL_POST_SAVE_LBD_LIST, lbdList);
  }

  replaceLbdArticle(lbd: ListeBesoinsDenreeDto) {
    return this.httpSvc.post(URL_POST_REPLACE_LBD_ARTICLE, lbd);
  }

  /**
   *
   * Relancer le calcul des articles preferes sur l'instance de workflow
   * @param workflowInstance
   */
  changeReglePrefere(workflowInstance: WorkflowInstanceDTO) {
    return this.httpSvc.post(URL_POST_CHANGE_REGLE_PREFERE, workflowInstance);
  }

  getArticlesDisponibles(idProduitDeclinaison: number, idUniteDeProduction: number) {
    const params: HttpParams = new HttpParams()
      .set('idProduitDeclinaison', idProduitDeclinaison + '')
      .set('idUniteDeProduction', idUniteDeProduction + '');
    return this.httpSvc.get(URL_GET_ARTICLES_DISPONIBLES, params);
  }
}
