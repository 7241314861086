<p-panel [header]="titleHeaderModels" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <div class="row">
    <div class="col-md-12">
      <button *ngFor="let elem of btnModelList"
        pButton class="mg-r-10 cursor" [label]="elem.label"  [disabled]="!hasRequeteurGraphQL" (click)="elem.action()"></button>
    </div>
  </div>
  <div *ngIf="dataModelLoaded" class="row position-relative">
    <button
      pButton class="mg-r-10 cursor close-data-model-graphql" label="FERMER" pTooltip="Fermer" [disabled]="!hasRequeteurGraphQL" (click)="closeModel()"></button>
    <div class="col-md-12 data-model-graphql" [innerHTML]="sanitizer.bypassSecurityTrustHtml(dataModel)">
    </div>
  </div>
</p-panel>
<p-panel [header]="titleHeaderParametersVizualisation" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <div class="row">
    <div class="col-md-12">
      <p-radioButton
        name="typeViewResult"
        class="mg-b-5 mg-l-15"
        [value]="TYPE_RESULT_VIEW_GRAPHQL.pretty_json"
        label="JSON"
        (ngModelChange)="clearExport()"
        [(ngModel)]="typeViewResult"
        inputId="type-view-result-{{TYPE_RESULT_VIEW_GRAPHQL.pretty_json}}">
      </p-radioButton>
      <p-radioButton
        name="typeViewResult"
        class="mg-b-5 mg-l-15"
        [value]="TYPE_RESULT_VIEW_GRAPHQL.plain_json"
        label="JSON brut"
        (ngModelChange)="clearExport()"
        [(ngModel)]="typeViewResult"
        inputId="type-view-result-{{TYPE_RESULT_VIEW_GRAPHQL.plain_json}}">
      </p-radioButton>
      <p-radioButton
        name="typeViewResult"
        class="mg-b-5 mg-l-15"
        [value]="TYPE_RESULT_VIEW_GRAPHQL.table"
        label="Tableau"
        (ngModelChange)="clearExport()"
        [(ngModel)]="typeViewResult"
        inputId="type-view-result-{{TYPE_RESULT_VIEW_GRAPHQL.table}}">
      </p-radioButton>
    </div>
  </div>
</p-panel>
<p-panel [header]="titleHeaderQuery" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <div class="row">
    <div class="col-md-12">
      <textarea rows="8" [(ngModel)]="queryGraphQL" [placeholder]="placeHolderQueryGraphQL"></textarea>
    </div>
    <div class="col-md-12 text-right">
      <button
        pButton class="mg-r-10 cursor" [label]="labelExec" pTooltip="Exécuter la requête" [disabled]="!hasRequeteurGraphQL" (click)="sendQuery()"></button>
    </div>
  </div>
</p-panel>
<p-panel [header]="titleHeaderViewResults" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
  <ng-container style="margin-bottom: 5px;" *ngIf="jsonForExport && (typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.pretty_json || typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.plain_json)">
    <a class="mg-r-10 export" title="Export JSON" [href]="jsonForExport" [download]="namefileJsonExport">Export JSON</a>
  </ng-container>
  <yo-view-json-graphql *ngIf="typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.pretty_json"></yo-view-json-graphql>
  <yo-view-plain-json-graphql *ngIf="typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.plain_json"></yo-view-plain-json-graphql>
  <yo-view-table-graphql *ngIf="typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.table"></yo-view-table-graphql>
</p-panel>
