import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {SortiePourProductionDTO} from '../../dtos/sortie-pour-production-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {catchError} from 'rxjs/operators';
import {SortieProdPrintDTO} from '../../dtos/gestion-production/sortie-prod-print-dto';

export const URL_GET_BY_ID_WORKFLOW_INSTANCE = `dolrest/gestion-sorties-pour-production/workflow-instance`;
export const URL_POST_SAVE_SORTIE_POUR_PRODUCTION = `dolrest/gestion-sorties-pour-production/saveOne`;
export const URL_GET_VALIDER_SORTIES_POUR_PRODUCTION = `dolrest/gestion-sorties-pour-production/valider-sorties`;
export const URL_GET_PRINT_SORTIE_PROD = `dolrest/gestion-sorties-pour-production/print`;
export const URL_GET_CAN_CHANGE_VIEW_SORTIEPOURPRODUCTION = `dolrest/gestion-sorties-pour-production/canChangeViewSortiePourProduction`;

export enum SORTIE_PROD_PRINT_MODE {
  vueArticle = 'vueAtelier',
  vueZoneDeStockage = 'vueZoneDeStockage'
};

@Injectable({
  providedIn: 'root'
})
export class SortiePourProductionService extends GenericHandler<SortiePourProductionDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO = (): SortiePourProductionDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEditObjectLabel = (data: ObjectDTO): string => '';

  getEntityName = (): string => 'SortiePourProduction';

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => '';

  getTotalRecordsLabel = (): string => '';

  getByIdWorkflowInstance = (idWorkflowInstance: number) => {

    return this.http.get(URL_GET_BY_ID_WORKFLOW_INSTANCE, {
      params: new HttpParams()
        .set('idWorkflowInstance', idWorkflowInstance + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  saveSortiePourProduction = (spp: SortiePourProductionDTO) => {

    return this.http.post(URL_POST_SAVE_SORTIE_POUR_PRODUCTION, spp)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  validerSorties = (idWorkflowInstance: number, idsSortiePourProduction: number[]) => {

    return this.http.get(URL_GET_VALIDER_SORTIES_POUR_PRODUCTION, {
      params: new HttpParams()
        .set('idWorkflowInstance', idWorkflowInstance + '')
        .set('idsSortiePourProduction', this.utils.isCollectionNullOrEmpty(idsSortiePourProduction) ? '' : idsSortiePourProduction.join(','))
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  printPdfSortieProd = (idWorkflowInstance: number, printMode: string) => {

    let sortieProdPrintDto: SortieProdPrintDTO = new SortieProdPrintDTO();
    sortieProdPrintDto.workflowInstanceId = idWorkflowInstance;
    sortieProdPrintDto.printMode = printMode;

    return this.http.post(URL_GET_PRINT_SORTIE_PROD, sortieProdPrintDto, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/pdf')
    }).pipe(
      catchError(error => this.utils.handleError(error))
    );
  };

  canChangeViewSortiePourProduction = (idWorkflowInstance: number) => {

    return this.http.get(URL_GET_CAN_CHANGE_VIEW_SORTIEPOURPRODUCTION, {
      params: new HttpParams().set('idWorkflowInstance', idWorkflowInstance + '')
    }).pipe(
      catchError(error => this.utils.handleError(error))
    );
  };
}
