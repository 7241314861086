import {ObjectDTO} from "../object-dto";
import {GroupeAchatDTO} from "./groupe-achat-dto";
import {FournisseurDTO} from "../fournisseur-d-t-o";


export class GroupeAchat__FournisseurDTO extends ObjectDTO {

  groupeAchat :GroupeAchatDTO
  fournisseur: FournisseurDTO;
  ratioPrixUF: number;

}
