import {ObjectDTO} from "./object-dto";
import {WorkflowInstanceDTO} from "./workflow-instance-dto";
import {SiteDTO} from './site-dto';

export class PlanProductionDTO extends ObjectDTO {

  code: string;
  libelle: string;
  actif: boolean;
  archive: boolean;
  date:number;

  workflowInstance: WorkflowInstanceDTO;


  constructor() {
    super();
    this.code = "";
    this.libelle = "";
    this.site = new SiteDTO();
    this.site.libelle = "";
  }
}
