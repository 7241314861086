import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {UnitesDeMesuresService} from '../entities/unites-de-mesures.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Subject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {TreeNode} from 'primeng/api';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {catchError, finalize} from 'rxjs/operators';
import {SearchSupplier} from '../../suppliers/search-supplier';
import * as moment from 'moment';
import {StockMouvementDTO} from '../../dtos/stock-mouvement-dto';
import {ModelViewMouvementDTO} from '../../../gestion-stocks/mouvements/model-view-mouvement';

export const URL_POST_SEARCH_MOUVEMENTS = `dolrest/gestion-stocks/search/mouvements`;
export const URL_POST_SEARCH_MOUVEMENTS_BALANCES = `dolrest/gestion-stocks/search/mouvements/balances`;
export const URL_POST_PRINT_MOUVEMENTS = `dolrest/gestion-stocks/print/mouvements`;
export const URL_POST_SAVE_MOUVEMENT = `dolrest/gestion-stocks/save/mouvement`;

@Injectable({
  providedIn: 'root'
})
export class StockMouvementService extends GenericHandler<StockMouvementDTO> {

  private subjectResultSearchMouvements = new Subject<ResponseWrapper<ModelViewMouvementDTO>>();
  resultSearchMouvements$ = this.subjectResultSearchMouvements.asObservable();

  private subjectResultSearchBalancesMouvements = new Subject<ResponseWrapper<ModelViewMouvementDTO>>();
  resultSearchBalancesMouvements$ = this.subjectResultSearchBalancesMouvements.asObservable();

  private subjectPaginationSearchMouvement = new Subject<any>();
  paginationSearchMouvement$ = this.subjectPaginationSearchMouvement.asObservable();

  private subjectExportMouvement = new Subject<any>();
  exportMouvement$ = this.subjectExportMouvement.asObservable();

  private subjectResultExportMouvements = new Subject<ResponseWrapper<ModelViewMouvementDTO>>();
  resultExportMouvements$ = this.subjectResultExportMouvements.asObservable();

  private subjectOpenDialogModificationMouvement = new Subject<DialogModificationMouvementSupplier>();
  openDialogModificationMouvement$ = this.subjectOpenDialogModificationMouvement.asObservable();


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService,
              private udmSvc: UnitesDeMesuresService) {
    super(utils, auth2Svc, router, http, title);

  }


  createEmptyDTO(): StockMouvementDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: StockMouvementDTO): string {
    return "";
  }

  getEntityName(): string {
    return "StockMouvement";
  }

  getFields(dto: StockMouvementDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "GESTION DES MOUVEMENTS DE STOCK";
  }

  getTotalRecordsLabel(): string {
    return "";
  }


  searchInMouvements(values: any, selectedNodes: TreeNode[], page: number, size: number) {

    const denominationArticle = values.denominationArticle;
    const referenceInterneArticle = values.referenceInterneArticle;
    const lotArticle = values.lotArticle;
    const lotArticleFournisseur = values.lotArticleFournisseur;
    const mouvements = values.mouvements;
    const periode = values.periode;
    const filterEntree = values.filterEntree;
    const filterSortie = values.filterSortie;
    const sens = [];

    if(!this.utils.isCollectionNullOrEmpty(filterEntree)) {
      sens.push(filterEntree[0]);
    }

    if(!this.utils.isCollectionNullOrEmpty(filterSortie)) {
      sens.push(filterSortie[0]);
    }

    const ssWrapper = new SearchSupplierWrapper();

    // filtrer par udp zone de stockage
    if (!this.utils.isCollectionNullOrEmpty(selectedNodes)) {
      const udpNodes = selectedNodes.filter(item => item.data.niveau === 1);

      if (!this.utils.isCollectionNullOrEmpty(udpNodes)) {
        const udpZdsIds = [];
        const udpZdsLibelles = [];
        const udpLibelles = [];

        for (const item of udpNodes) {
          udpLibelles.push(item.label);
          let i = 0;
          for (const child of item.children) {

            if (i = 0) { i++; } else {
              udpLibelles.push('');
            }
            udpZdsIds.push(child.data.id);
            udpZdsLibelles.push(child.label);
          }
        }

        ssWrapper.filtersMap['UDP_LIBELLES'] = new SearchSupplier(undefined, udpLibelles);
        ssWrapper.filtersMap['UDP_ZONES_DE_STOCKAGE_IDS'] = new SearchSupplier(undefined, udpZdsIds);
        ssWrapper.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'] = new SearchSupplier(undefined, udpZdsLibelles);
      }
    }

    // filtrer par ref article fournisseur
    if (!this.utils.isNullOrEmpty(referenceInterneArticle)) {
      ssWrapper.filtersMap['REFERENCE_INTERNE_ARTICLE'] = new SearchSupplier(referenceInterneArticle);
    }

    // filtrer par denomination article
    if (!this.utils.isNullOrEmpty(denominationArticle)) {
      ssWrapper.filtersMap['DENOMINATION_ARTICLE'] = new SearchSupplier(denominationArticle);
    }

    // filtrer par lot article
    if (!this.utils.isNullOrEmpty(lotArticle)) {
      ssWrapper.filtersMap['LOT_ARTICLE'] = new SearchSupplier(lotArticle);
    }

    // filtrer par lot article fournisseur
    if (!this.utils.isNullOrEmpty(lotArticleFournisseur)) {
      ssWrapper.filtersMap['LOT_ARTICLE_FOURNISSEUR'] = new SearchSupplier(lotArticleFournisseur);
    }


    // filtrer par periode
    if (!this.utils.isCollectionNullOrEmpty(periode)
      && !this.utils.isNullOrEmpty(periode[0])
      && !this.utils.isNullOrEmpty(periode[1])
    ) {
      ssWrapper.filtersMap['PERIODE'] = new SearchSupplier(undefined, [this.utils.getYYYYMMDD(moment(periode[0])), this.utils.getYYYYMMDD(moment(periode[1]))]);
    }

    // filtrer par mouvements
    if (!this.utils.isCollectionNullOrEmpty(mouvements)) {

      const mouvementIds = mouvements.map(item => item.id);
      const mouvementLibelles = mouvements.map(item => item.libelle);
      ssWrapper.filtersMap['STOCK_MOUVEMENT_STATUT_IDS'] = new SearchSupplier(undefined, mouvementIds);
      ssWrapper.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'] = new SearchSupplier(undefined, mouvementLibelles);
    }

    // filtrer par sens
    if (!this.utils.isCollectionNullOrEmpty(sens)) {
      ssWrapper.filtersMap['SENS'] = new SearchSupplier(undefined, sens);
    }
    const urlParams = `?page=${page}&size=${size}`;
    return this.http.post(URL_POST_SEARCH_MOUVEMENTS + urlParams, ssWrapper).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  }

  searchBalancesInMouvements(values: any, selectedNodes: TreeNode[]) {

    const denominationArticle = values.denominationArticle;
    const referenceInterneArticle = values.referenceInterneArticle;
    const lotArticle = values.lotArticle;
    const lotArticleFournisseur = values.lotArticleFournisseur;
    const mouvements = values.mouvements;
    const periode = values.periode;
    const sens = values.sens;

    const ssWrapper = new SearchSupplierWrapper();

    // filtrer par udp zone de stockage
    if (!this.utils.isCollectionNullOrEmpty(selectedNodes)) {

      const udpZdsNodes = selectedNodes.filter(item => item.data.niveau === 2);

      if (!this.utils.isCollectionNullOrEmpty(udpZdsNodes)) {

        const udpZdsIds = udpZdsNodes.map(item => item.data.id);
        const udpZdsLibelles = udpZdsNodes.map(item => item.data.libelle);
        ssWrapper.filtersMap['UDP_ZONES_DE_STOCKAGE_IDS'] = new SearchSupplier(undefined, udpZdsIds);
        ssWrapper.filtersMap['UDP_ZONES_DE_STOCKAGE_LIBELLES'] = new SearchSupplier(undefined, udpZdsLibelles);
      }
    }

    // filtrer par ref article fournisseur
    if (!this.utils.isNullOrEmpty(referenceInterneArticle)) {
      ssWrapper.filtersMap['REFERENCE_INTERNE_ARTICLE'] = new SearchSupplier(referenceInterneArticle);
    }

    // filtrer par denomination article
    if (!this.utils.isNullOrEmpty(denominationArticle)) {
      ssWrapper.filtersMap['DENOMINATION_ARTICLE'] = new SearchSupplier(denominationArticle);
    }

    // filtrer par lot article
    if (!this.utils.isNullOrEmpty(lotArticle)) {
      ssWrapper.filtersMap['LOT_ARTICLE'] = new SearchSupplier(lotArticle);
    }

    // filtrer par lot article fournisseur
    if (!this.utils.isNullOrEmpty(lotArticleFournisseur)) {
      ssWrapper.filtersMap['LOT_ARTICLE_FOURNISSEUR'] = new SearchSupplier(lotArticleFournisseur);
    }


    // filtrer par periode
    if (!this.utils.isCollectionNullOrEmpty(periode)
      && !this.utils.isNullOrEmpty(periode[0])
      && !this.utils.isNullOrEmpty(periode[1])
    ) {
      ssWrapper.filtersMap['PERIODE'] = new SearchSupplier(undefined, [this.utils.getYYYYMMDD(moment(periode[0])), this.utils.getYYYYMMDD(moment(periode[1]))]);
    }

    // filtrer par mouvements
    if (!this.utils.isCollectionNullOrEmpty(mouvements)) {

      const mouvementIds = mouvements.map(item => item.id);
      const mouvementLibelles = mouvements.map(item => item.libelle);
      ssWrapper.filtersMap['STOCK_MOUVEMENT_STATUT_IDS'] = new SearchSupplier(undefined, mouvementIds);
      ssWrapper.filtersMap['STOCK_MOUVEMENT_STATUT_LIBELLES'] = new SearchSupplier(undefined, mouvementLibelles);
    }

    // filtrer par sens
    if (!this.utils.isCollectionNullOrEmpty(sens)) {

      ssWrapper.filtersMap['SENS'] = new SearchSupplier(undefined, sens);

    }
    return this.http.post(URL_POST_SEARCH_MOUVEMENTS_BALANCES, ssWrapper).pipe(
      catchError(err => this.utils.handleError(err, true))
    );


  }

  announceResultSearchMouvements(response: ResponseWrapper<ModelViewMouvementDTO>) {
    this.subjectResultSearchMouvements.next(response);
  }

  announceResultExportMouvements(response: ResponseWrapper<ModelViewMouvementDTO>) {
    this.subjectResultExportMouvements.next(response);
  }

  announceExportMouvement() {
    this.subjectExportMouvement.next();
  }

  announceResultSearchBalancesMouvements(response: ResponseWrapper<ModelViewMouvementDTO>) {
    this.subjectResultSearchBalancesMouvements.next(response);
  }

  announcePaginationSearchMouvement(param: { size: number; page: number }) {
    this.subjectPaginationSearchMouvement.next(param);
  }

  announceOpenDialogModificationMouvement(mvmDto: ModelViewMouvementDTO) {
    const dmms = new DialogModificationMouvementSupplier(mvmDto);
    this.subjectOpenDialogModificationMouvement.next(dmms);
  }

  printMouvements(ssw: SearchSupplierWrapper) {
    return this.http.post(URL_POST_PRINT_MOUVEMENTS, ssw, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  }

  saveMouvement(viewMouvementDto: ModelViewMouvementDTO) {
    return this.http.post(URL_POST_SAVE_MOUVEMENT, viewMouvementDto)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }
}

export class DialogModificationMouvementSupplier{

  mvmDto:ModelViewMouvementDTO;

  constructor(mvmDto:ModelViewMouvementDTO) {
    this.mvmDto = mvmDto;
  }
}
