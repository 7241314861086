<p-dialog
  [dismissableMask]="false"

  [showHeader]="true"
  (onHide)="closeDialog()"
  positionTop="300"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  [style]="{'width': '20vw'}"
  minY=400
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-save mg-r-5"></i> <span>Confirmation sauvegarde de l'inventaire</span>
    </div>
  </ng-template>
  <!--  CONTENT-->
  <ng-container>

    <div class="d-flex justify-content-left">
      <div class="d-flex flex-column ">
        <label>Voulez-vous enregistrer les modifications ?</label>
        <br/>
        <p-checkbox
          name="group1"
          [value]=""
          label="se rappeler de mon choix"
          [(ngModel)]="isKeepItClose"
          (onChange)="onChangeIsKeepOpenable()">
        </p-checkbox>
      </div>
    </div>
  </ng-container>

  <ng-template pTemplate="footer">
    <span class="mg-r-5">
      <button type="button" pButton
              icon="fas fa-save"
              (click)="confirm()"
              pTooltip="Enregistrer les modifications l'inventaire"
              label="Enregistrer"></button>
    </span>
    <span class="mg-r-5">
      <button type="button" class="p-button-secondary" pButton icon="fas fa-times" (click)="closeDialog()"></button>
    </span>


  </ng-template>
</p-dialog>
