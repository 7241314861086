<p-dialog header="Préférences" [closable]="false" [modal]="true" [dismissableMask]="false" [style]="{'width':'900px'}"
          [contentStyle]="{'overflow':'visible'}" [(visible)]="displayPreferences">


  <div class="d-flex flex-column">

    <div class="d-flex flex-column flex-grow-1">

      <p-panel class="mg-t-5 mg-b-5" header="Profil(s)">
        {{auth2Svc?.utilisateur?.prenom | titlecase}} {{auth2Svc?.utilisateur?.nom | titlecase}}
        , <strong>{{auth2Svc?.utilisateur?.profil}}</strong>
      </p-panel>

      <p-panel class="mg-t-5 mg-b-5" header="Au démarrage de Datameal Online">
        <div class="d-flex flex-column">
          <p-radioButton *ngFor="let display of displays; trackBy:utils.trackByFn" inputId="'rb{{ display.id }}'"
                         class="radio-button" name="selectedDisplay" [value]="display" [label]="display.value"
                         [(ngModel)]="selectedDisplay"></p-radioButton>
        </div>
      </p-panel>

      <p-panel class="mg-t-5 mg-b-5" header="Notifications">
        <p-checkbox name="enableNotification" [(ngModel)]="enableNotification" label="Activer les notifications"
                    binary="true"></p-checkbox>
      </p-panel>

      <p-panel class="mg-t-5 mg-b-5" header="Emails">
        <p-checkbox name="enableCopyCarbone" [(ngModel)]="enableCopyCarbone"
                    label="Envoi systématique d'une copie carbone (CC)" binary="true"></p-checkbox>
      </p-panel>

    </div>


    <div class="d-flex mg-t-10">
      <div class="ml-auto">
            <span class="mg-r-5">
            <button pButton icon="fas fa-save" (click)="savePreferences()" showDelay="500"
                    pTooltip="Enregistrer les préférences"></button>
            </span>
        <span>
        <button pButton class="p-button-secondary mg-r-5" icon="fas fa-times" (click)="closePreferences()"
                showDelay="500" pTooltip="Fermer"></button>
        </span>

      </div>
    </div>

  </div>

</p-dialog>




