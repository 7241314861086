<a (click)="getHelp()" class="cursor">
  <i [class]="_iconClick"></i>

</a>
<!--<span [innerHTML]="domSanitizer.bypassSecurityTrustHtml(_iconClick)"></span>-->
<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"

  [style]="{'min-width':'500px','max-width':'800px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <span class="mg-r-5"><i [class]="dms?.logo"></i></span>{{dms?.title}}
    </div>
  </ng-template>

  <markdown [src]="'assets/markdown/help/' + lang + '/' + file + '.md'"></markdown>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="fas fa-times"
            class="p-button-secondary"
            (click)="closeDialog()" label="Fermer"></button>
  </ng-template>


</p-dialog>
