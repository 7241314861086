<p-card>
  <div class="card-size">

    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <div class="d-flex flex-rowa align-items-center">
          <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
          <div class="mg-l-5 mg-r-5">
            <button
              pButton
              class="mg-r-10 cursor"
              icon="fa fa-plus"
              label="CRÉER"
              (click)="openTypeTache(undefined)"
              [disabled]="!canEdit()"
              pTooltip="Créer un type de tâche">
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="right">
        <button
          pButton
          type="button"
          icon="fas fa-trash"
          (click)="deleteValues()"
          [disabled]="!canEdit() || !selectedRows.length"
          pTooltip="Supprimer les éléments sélectionnés">
        </button>
      </ng-template>
    </p-toolbar>

    <dx-data-grid
      [dataSource]="typesTacheList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(182)"
      width="100%"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      [(selectedRowKeys)]="selectedRows"
      #grid>
      <dxo-selection
        [selectAllMode]="allMode"
        [showCheckBoxesMode]="checkBoxesMode"
        mode="multiple"
      ></dxo-selection>
      <dxi-column dataField="id" caption="Actions" [width]="100"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  cellTemplate="actionsCellTemplate">
      </dxi-column>
      <dxi-column dataField="libelle" caption="Libellé" [width]="200"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true">
      </dxi-column>


      <!--  ACTIONS CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'actionsCellTemplate'">
        <yo-cell-button (yoNavigation)="openTypeTache(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoMaxWidth]="true"
                        [yoWidthPercent]="30"
                        [yoTextAlign]="'center'"
                        [yoIconClass]="canEdit()?'fa fa-edit':'fas fa-eye'"
                        [pTooltip]="canEdit()?'Modifier le type de tâche':'Voir le type de tâche'"
                        tooltipPosition="right"
                        showDelay="500">
        </yo-cell-button>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} type(s) de tâche">
      </dxo-pager>

    </dx-data-grid>
  </div>
</p-card>
<yo-pms-type-tache-dialog></yo-pms-type-tache-dialog>


