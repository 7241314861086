import {Component, OnInit} from '@angular/core';
import {LitigesService} from '../../core/services/entities/litiges.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-litiges',
  templateUrl: './litiges.component.html',
  styleUrls: ['./litiges.component.scss']
})
export class LitigesComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/litiges';

  constructor(public litigesSvc:LitigesService) { }

  ngOnInit() {
  }

}
