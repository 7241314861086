import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MonbureauComponent} from './monbureau.component';
import {MonbureauRoutingModule} from './monbureau-routing.module';
import {SharedModule} from '../shared/shared.module';
import {CoreModule} from '../core/core.module';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ButtonModule} from 'primeng/button';


@NgModule({
  imports: [
    CommonModule,
    MonbureauRoutingModule,
    SharedModule,
    CoreModule,

    PanelMenuModule,
    ButtonModule
  ],
  declarations: [MonbureauComponent],
  exports:[
    MonbureauComponent
  ]
})
export class MonbureauModule { }
