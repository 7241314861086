import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {PlanProductionDTO} from '../../../core/dtos/plan-production-dto';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {MessageService} from 'primeng/api';
import {ProductionService} from '../../../core/services/gestion-production/production.service';
import {PlanProductionDetailDTO} from '../../../core/dtos/plan-production-detail-dto';
import {UniteDeProduction__EquipeDTO} from '../../../core/dtos/unite-de-production__equipe';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {WorkflowDto} from '../../../core/dtos/workflow-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {WorkflowInstanceDTO} from '../../../core/dtos/workflow-instance-dto';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {Subscription} from 'rxjs';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {PlanProductionService} from '../../../core/services/entities/plan-production.service';

import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {InitProcessusSupplier} from "../../../core/suppliers/gestion-processus/init-processus-supplier";
import CustomStore from "devextreme/data/custom_store";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {Sort} from "../../../core/suppliers/generics/generic-request-supplier";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-plans-de-production',
  templateUrl: './plans-de-production.component.html',
  styleUrls: ['./plans-de-production.component.scss']
})
export class PlansDeProductionComponent implements OnInit, OnDestroy {

  totalRecords: number;
  planProductionItems: PlanProductionDTO[] = [];
  loadingTable = false;
  displayEditDialog: boolean = false;
  displayPrintSettings: boolean = false;
  details: PlanProductionDetailDTO[];
  planProd: PlanProductionDTO;
  workflows: WorkflowDto[];

  datesConso: Date[] = [];
  datesFab: Date[] = [];
  equipes: UniteDeProduction__EquipeDTO[] = [];
  subWorkflowInstance: Subscription;
  subWorkflowInstanceSaved: Subscription;

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;

  isActionDisabled: boolean = true;

  pathFile: string = HELP_FOLDERS.PLANS_PRODUCTION + '/plans-de-production';

  constructor(public utils: UtilsService,
              public workflowSvc: WorkflowsService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              public prodSvc: ProductionService,
              public msgSvc: MessageService,
              private routeMapSvc: RoutemapService,
              private planProdSvc: PlanProductionService,
              public auth2Svc: Auth2Service,
              private dxSvc: DevextremeService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.workflowInstanceSubscription();
    this.workflowInstanceSavedSubscription();
    this.initCustomStore();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subWorkflowInstance);
    this.utils.unsubscribe(this.subWorkflowInstanceSaved);
  }

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        let urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(pageSize, page, sorts);
        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
          // Si je coche tout => Il faut omettre la pagination
          urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(null, null, sorts);
        }

        return this.prodSvc.searchPlansProduction(new SearchSupplierWrapper(), urlPaginationParams).toPromise().then(response => {
          this.planProductionItems = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }


  refreshPlanProdInGrid = (workflowInstance: WorkflowInstanceDTO): void => {
    this.prodSvc.getPlanProd(workflowInstance).subscribe(response => {
      this.grid.instance.refresh();
    });
  };

  /**
   * Recharge le plan de prod de l'instance (il a pu être supprimé / recréé lors du parcours du workflow).
   */
  workflowInstanceSubscription = (): void => {
    this.subWorkflowInstance = this.workflowSvc.workflowInstance$.subscribe(workflowInstance => {
      this.refreshPlanProdInGrid(workflowInstance);
    });
  };

  workflowInstanceSavedSubscription = (): void => {
    this.subWorkflowInstanceSaved = this.workflowSvc.workflowInstanceSaved$.subscribe(workflowInstance => {
      if (!this.utils.isNullOrEmpty(workflowInstance)) {
        let pp: PlanProductionDTO = this.prodSvc.createPlanProdFromWorkflowInstance(workflowInstance);
        this.gfs.saveOne(pp, this.planProdSvc.getEntityName()).subscribe(response => {
          this.grid.instance.refresh();
          this.utils.showMsg(MSG_KEY.PLAN_PRODUCTION, MSG_SEVERITY.SUCCESS, `Plan de production '${pp.libelle.toUpperCase()}' initialisé.`);
          this.routeMapSvc.goToSecondaryRoute(['gestion-processus', pp.workflowInstance.id, pp.workflowInstance.codeWorkflowStatut])
        });
      }
    });
  };

  openInitProcessus = (): void => {
    const ips = new InitProcessusSupplier();
    ips.title = `<i class="fas fa-file-invoice mg-r-5"></i>CRÉER UN PLAN DE PRODUCTION`;
    ips.requiredSteps = ['calcul-production', 'production-liste-plats'];
    ips.actionStartWorkflowLabel = `DÉMARRER LE PLAN DE PRODUCTION`;
    this.workflowSvc.announceOpenDialogInitProcessus(ips);
  };

  deletePlanProdItems = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
      this.gfs.deleteList(this.planProdSvc.getEntityName(), rowsSelected)
        .subscribe(response => {
          this.grid.instance.refresh();
          this.utils.showMsg(MSG_KEY.PLAN_PRODUCTION, MSG_SEVERITY.SUCCESS, `Les plans de productions sélectionnés ont été supprimés.`);
        });
    });
  };

  openObject = (pp: PlanProductionDTO): void => {
    this.routeMapSvc.goToSecondaryRoute(['gestion-processus', pp.workflowInstance.id, 'pp-details']);
  };

  canCreatePlanProduction = (): boolean => this.auth2Svc.utilisateur.siteListLocaux && this.auth2Svc.utilisateur.siteListLocaux.length > 0;

  updateErrors = ($event: any): void => {
    if ($event) {
      this.msgSvc.clear();
      const erreursArr: any[] = [];
      for (let erreur of $event) {
        erreursArr.push({
          key: MSG_KEY.PLAN_PRODUCTION,
          severity: MSG_SEVERITY.ERROR,
          summary: erreur.message,
          detail: erreur.detail,
          life: 15000
        });
      }
      this.utils.showMsgs(erreursArr);
    }
  };

  changeValueLibelle = (newData, newText, currentRowData) => {
    const idx: number = this.planProductionItems.findIndex(p => p.id === currentRowData.id);
    if (idx >= 0)
      this.planProductionItems[idx].libelle = newText;
  }

  onEditorPreparing = ($event: any): void => {
    if ($event.dataField === "libelle" && $event.parentType === "dataRow") {
      const pp: PlanProductionDTO = $event.row.data;
      this.gfs.saveOne(pp, this.planProdSvc.getEntityName()).subscribe(response => {
      });
    }
  };

  help = () => undefined;

}
