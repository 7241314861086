import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../core/utils/utils.service';
import {Nav} from '../core/nav';
import {Title} from '@angular/platform-browser';
import {combineLatest, Observable} from 'rxjs';
import {Auth2Service} from '../core/services/security/auth2.service';
import {MenuItem} from 'primeng/api';
import {catchError, switchMap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs/index';
import {NAV_KEY} from "../core/models/technique/item-navigation";

@Component({
  templateUrl: './monbureau.component.html',
  styleUrls: ['./monbureau.component.scss']
})

export class MonbureauComponent implements OnInit, OnDestroy {


  subHasGestionIDistri : Subscription;
  subHasPortailAdmin : Subscription;
  subHasPms : Subscription;

  referentielItems: MenuItem[] = [];

  hasGestionFournisseurs$: Observable<boolean>;
  hasGestionMenus$: Observable<boolean>;
  hasAdministration$: Observable<boolean>;
  hasMonBureau$: Observable<boolean>;
  hasGestionContrats$: Observable<boolean>;
  hasGestionGemrcn$: Observable<boolean>;
  hasGestionCommandes$: Observable<boolean>;
  hasGestionBesoins$: Observable<boolean>;
  hasGestionProduction$: Observable<boolean>;
  hasStocks$: Observable<boolean>;
  hasFacturation$: Observable<boolean>;
  hasGestionIDistri$: Observable<boolean>;

  hasPms$: Observable<boolean>;

  hasGestionClients$: Observable<boolean>;
  hasPortailUtilisateur$: Observable<boolean>;
  hasGestionPlc$: Observable<boolean>;
  hasPortailAdmin$: Observable<boolean>;

  hasUniteDeProduction$: Observable<boolean>;
  hasUniteDeProductionLiv$: Observable<boolean>;
  hasUniteDeProductionFab$: Observable<boolean>;
  hasUniteDeProductionStocks$: Observable<boolean>;
  hasUniteDeProductionArticlesPreferes$: Observable<boolean>;
  hasUniteDeProductionDenreesInterdites$: Observable<boolean>;

  gestionProduitsIprod = false;
  gestionProduitsIapro = false;

  subAuth2: Subscription;


  constructor(
    private title: Title,
    private auth2Svc: Auth2Service,
    public utils: UtilsService) {
  }


  ngOnInit() {
    this.title.setTitle('MON BUREAU');
    this.subAuth2 = this.auth2Svc.hasGestionProduitsIapro$.pipe(
      switchMap(data => {
        this.gestionProduitsIapro = data;
        return this.auth2Svc.hasGestionProduitsIprod$;
      }),
      switchMap(data => {
        this.gestionProduitsIprod = data;
        return of(null);
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe();

    this.hasGestionFournisseurs$ = this.auth2Svc.hasGestionFournisseurs$;
    this.hasGestionMenus$ = this.auth2Svc.hasGestionMenus$;

    this.hasMonBureau$ = this.auth2Svc.hasMonBureau$;
    this.hasAdministration$ = this.auth2Svc.hasAdministration$;
    this.hasGestionContrats$ = this.auth2Svc.hasGestionContrats$;
    this.hasGestionGemrcn$ = this.auth2Svc.hasGestionGemrcn$;
    this.hasGestionCommandes$ = this.auth2Svc.hasGestionCommandes$;
    this.hasGestionProduction$ = this.auth2Svc.hasGestionProduction$;
    this.hasGestionBesoins$ = this.auth2Svc.hasGestionBesoins$;
    this.hasStocks$ = this.auth2Svc.hasStocks$;
    this.hasFacturation$ = this.auth2Svc.hasFacturation$;
    this.hasGestionIDistri$ = this.auth2Svc.hasGestionIDistri$;

    this.hasPms$ = this.auth2Svc.hasPms$;

    this.hasGestionClients$ = this.auth2Svc.hasGestionClients$;
    this.hasGestionPlc$ = this.auth2Svc.hasGestionPlc$;
    this.hasPortailUtilisateur$ = this.auth2Svc.hasPortailUser$;
    this.hasPortailAdmin$ = this.auth2Svc.hasPortailAdmin$;

    this.hasUniteDeProduction$ = this.auth2Svc.hasUniteDeProduction$;
    this.hasUniteDeProductionLiv$ = this.auth2Svc.hasUniteDeProductionLiv$;
    this.hasUniteDeProductionFab$ = this.auth2Svc.hasUniteDeProductionFab$;
    this.hasUniteDeProductionStocks$ = this.auth2Svc.hasUniteDeProductionStocks$;
    this.hasUniteDeProductionArticlesPreferes$ = this.auth2Svc.hasUniteDeProductionArticlesPreferes$;
    this.hasUniteDeProductionDenreesInterdites$ = this.auth2Svc.hasUniteDeProductionDenreesInterdites$;

    const referentielBaseItems: any[] = [
      {label: NAV_KEY.ALLERGENES, routerLink: '/administration/features/allergenes'},
      {label: NAV_KEY.APPELLATIONS, icon: '', routerLink: '/administration/features/appellations'},
      {label: NAV_KEY.PRESTATIONS, icon: '', routerLink: '/administration/features/convives'},
      {label: NAV_KEY.COMPOSANTES_REPAS, icon: '', routerLink: '/administration/features/decoupages-repas'},
      {label: NAV_KEY.DECLINAISONS, icon: '', routerLink: '/administration/features/declinaisons'},
      {label: NAV_KEY.FAMILLES_PRODUITS, icon: '', routerLink: '/administration/features/familles-produit'},
      {label: NAV_KEY.REPAS, icon: '', routerLink: '/administration/features/repas'},
      {label: NAV_KEY.UNITES_DE_MESURES, icon: '', routerLink: '/administration/features/unites-de-mesures'},
      {label: NAV_KEY.TAXES, icon: '', routerLink: '/administration/features/taxes'},
      {label: NAV_KEY.TYPES_DE_PRODUITS, icon: '', routerLink: '/administration/features/types-produit'},
      {label: NAV_KEY.TACHES, icon: '', routerLink: '/administration/features/taches'}
    ];

    this.referentielItems = [
      {
        label: NAV_KEY.EQUILIBRE_ALIMENTAIRE, icon: '', items: [
          {label: NAV_KEY.FAMILLES_GEMRCN, routerLink: '/administration/features/familles-gemrcn'},
          {label: NAV_KEY.REGIMES_ALIMENTAIRES, routerLink: '/administration/features/regimes-alimentaires'},
        ]
      },
      {
        label: NAV_KEY.GESTION_DES_UTILISATEURS, icon: '', items: [
          {label: NAV_KEY.SITES, icon: 'fa fa-map-marker yoni-color', routerLink: '/administration/features/sites'},
          {label: NAV_KEY.ENVIRONNEMENTS, icon: 'fa fa-sitemap yoni-color', routerLink: '/administration/features/environnements'},
          {label: NAV_KEY.ENVIRONNEMENTS_PLC, icon: 'fas fa-map-marker-alt', routerLink: '/administration/features/grid-environnement-plc'},
          {label: NAV_KEY.PROFILS, icon: 'fas fa-id-card-alt yoni-color ', routerLink: '/administration/features/profils'},
          {label: NAV_KEY.UTILISATEURS, icon: 'fa fa-user yoni-color', routerLink: '/administration/features/utilisateurs'},
          {label: NAV_KEY.CONNEXIONS_UTILISATEURS, icon: 'fa fa-link yoni-color', routerLink: '/administration/features/utilisateursconnexions'}
        ]
      },
      {
        label: NAV_KEY.PRODUCTION, icon: '', items: [
          {label: NAV_KEY.EQUIPES, icon: 'fa fa-users yoni-color', routerLink: '/administration/features/equipes'},
          {label: NAV_KEY.SECTEURS, icon: 'fa fa-map yoni-color', routerLink: '/administration/features/secteurs'},
          {label: NAV_KEY.ATELIERS, icon: 'fa fa-home yoni-color', routerLink: '/administration/features/zones-de-production'},
          {label: NAV_KEY.ZONES_DE_STOCKAGE, icon: 'fa fa-home yoni-color', routerLink: '/administration/features/zones-de-stockage'}
        ]
      },
      {
        label: NAV_KEY.RECEPTION, icon: '', items: [
          {label: NAV_KEY.LITIGES, icon: '', routerLink: '/administration/features/litiges'},
          {label: NAV_KEY.MOTIFS_DE_NON_CONFORMITE, icon: '', routerLink: '/administration/features/motifs-non-conformite'}
        ]
      },
      {
        label: NAV_KEY.REFERENTIEL_DE_BASE, icon: '', items: referentielBaseItems
      },
      {label: NAV_KEY.PARAMETRES, icon: 'fas fa-cog  yoni-color', routerLink: '/administration/features/parametres'},
    ];

    this.subHasGestionIDistri = this.hasGestionIDistri$.subscribe(response => {
      if (response) {
        this.referentielItems.splice(this.referentielItems.length - 1, 0, {
            label: NAV_KEY.CONDITIONNEMENTS,
            icon: '',
            items: [ { label: NAV_KEY.CONDITIONNEMENTS, routerLink: '/administration/features/conditionnements'},
                     { label: NAV_KEY.VARIANTES, routerLink: '/administration/features/variantes'},
                     { label: NAV_KEY.CONDITIONNEMENTS_VARIANTES, routerLink: '/administration/features/conditionnements-variantes'}]
        });
      }
    });

    this.subHasPms = this.hasPms$.subscribe(response => {
      if(response) {
        this.referentielItems.splice(this.referentielItems.length - 1, 0, {
          label: NAV_KEY.PMS,
          icon: '',
          items: [
            { label: NAV_KEY.EQUIPEMENTS_PMS, routerLink: '/administration/features/equipements-pms' },
            { label: NAV_KEY.EQUIPEMENTS_A_NETTOYER_PMS, routerLink: '/administration/features/equipements-a-nettoyer-pms' },
            { label: NAV_KEY.SEUILS_PMS, routerLink: '/administration/features/seuils-pms' },
            { label: NAV_KEY.SIGNATURES_UTILISATEUR_PMS, routerLink: '/administration/features/signatures-utilisateur-pms' },
            { label: NAV_KEY.TACHES_PMS, routerLink: '/administration/features/taches-pms' },
            { label: NAV_KEY.TYPES_EQUIPEMENT_PMS, routerLink: '/administration/features/types-equipement-pms' },
            { label: NAV_KEY.TYPES_TACHE_PMS, routerLink: '/administration/features/types-tache-pms' },
            { label: NAV_KEY.TYPES_UTILISATEUR_PMS, routerLink: '/administration/features/types-utilisateur-pms' },
            { label: NAV_KEY.UNITES_PMS, routerLink: '/administration/features/unites-pms' },
            { label: NAV_KEY.ZONES_PMS, routerLink: '/administration/features/zones-pms' }
          ]
        })
      }
    });

    const rightsStocks$ = combineLatest([this.hasAdministration$, this.hasStocks$]);
    rightsStocks$.subscribe(rights => {
      if (rights[0] && rights[1]) {
        this.referentielItems.splice(this.referentielItems.length - 2, 0, ({
          label: NAV_KEY.STOCKS, icon: '', items: [
            { label: NAV_KEY.STOCKS_MOUVEMENTS_STATUTS, icon: '', routerLink: '/administration/features/stocks-mouvements-statuts' },
          ]
        }) );
      }
    });

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAuth2);
    this.utils.unsubscribe(this.subHasGestionIDistri);
    this.utils.unsubscribe(this.subHasPortailAdmin);
    this.utils.unsubscribe(this.subHasGestionIDistri);
  }
}

export class DesktopTag {
  nav: Nav;
  iconClass: string;
}
