<div class="datagrid-container">
  <p-table
    [value]="denreesInterditesList"
    [columns]="displayedColumns"
    [totalRecords]="denreesInterditesList?denreesInterditesList.length:0"
    styleClass="p-datatable-sm"
    selectionMode="single"
    [scrollHeight]="utils.scrollHeightContent(240)"
    [responsive]="true"
    [scrollable]="true"
    dataKey="id" #ddi>

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
          <col *ngSwitchCase="'actions'" [style.width]="'80px'" [style.text-align]="'left'">
          <col *ngSwitchCase="'denreeInterditeLibelle'" [style.width]="'120px'" [style.text-align]="'right'">
          <col *ngSwitchCase="'denreeSubstitutionLibelle'" [style.width]="'120px'" [style.text-align]="'right'">
          <col *ngSwitchCase="'ratio'" [style.width]="'80px'" [style.text-align]="'right'">
        </ng-container>
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">

            <ng-container *ngSwitchCase="'actions'">
              <th>
                {{col.header}}
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'denreeInterditeLibelle'">
              <th [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'denreeSubstitutionLibelle'">
              <th [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <ng-container *ngSwitchCase="'ratio'">
              <th [pSortableColumn]="col.field" [style.text-align]="'right'">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

          </ng-container>
        </ng-container>

      </tr>

    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>

        <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'actions'">
            <td>
              <yo-cell-button (yoNavigation)="openEditObject(rowData)"
                              [yoTooltipShowDelay]="1500"
                              [yoMaxWidth]="true"
                              [yoHiddenMode]="!gds.canModify(uniteDeProduction)"
                              [yoWidthPercent]="45"
                              yoTextAlign="center"
                              yoIconClass="fa fa-edit"
                              yoTooltip="Modifier la denrée interdite"
              ></yo-cell-button>
              <yo-cell-button (yoNavigation)="openDeleteObject(rowData)"
                              [yoTooltipShowDelay]="1500"
                              [yoMaxWidth]="true"
                              [yoHiddenMode]="!gds.canModify(uniteDeProduction)"
                              [yoWidthPercent]="45"
                              yoTextAlign="center"
                              yoIconClass="fa fa-trash"
                              yoTooltip="Supprimer la denrée interdite"
              ></yo-cell-button>
            </td>
          </ng-container>


          <ng-container *ngSwitchCase="'denreeInterditeLibelle'">
            <td style="text-align: left;">
              {{rowData.denreeInterditeLibelle}}
            </td>
          </ng-container>


          <ng-container *ngSwitchCase="'denreeSubstitutionLibelle'">
            <td style="text-align: left;">
              {{rowData.denreeSubstitutionLibelle}}
            </td>
          </ng-container>


          <ng-container *ngSwitchCase="'ratio'">
            <td style="text-align: left;">
              {{rowData.ratio}}%
            </td>
          </ng-container>

        </ng-container>

      </tr>
    </ng-template>
  </p-table>

</div>


