<div class="d-flex flex-grow-1" [style.height]="'calc(68vh)'">
  <div class="d-flex flex-column">

    <div *ngIf="!utils.isNullOrEmpty(lotMarche)" class="flex-grow-1">
      <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getWindowAvailableWidth(300)}">
      </yo-form>
    </div>
  </div>
</div>
<hr/>
<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>
  </div>
  <div>
    <span class="mg-r-5">
      <button pButton icon="fas fa-save" (click)="saveFicheIdentite()"
              [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
              showDelay="500" class="p-button-success">
      </button>
    </span>
    <span>
      <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
              class="p-button-secondary">
      </button>
    </span>
  </div>
</div>

