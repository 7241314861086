<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button pButton
            label="CRÉER"
            icon="fa fa-plus"
            (click)="openGroupeAchat(undefined)"
            [disabled] = "!canCreate()"
            pTooltip="Créer un groupe">
    </button>
  </ng-template>
</p-toolbar>
<dx-data-grid
  [dataSource]="groupeAchatList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(150)"
  [width]="utils.getWindowAvailableWidth(0)"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #gridDenree>
  <dxi-column dataField="id" alignment="left" caption="Actions" [width]="80"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" alignment="left" caption="Site" [width]="300"
              [allowFiltering]="false" [allowSorting]="false"
              cellTemplate="siteCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" alignment="left" caption="Libellé" [width]="400"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="descriptif" alignment="left" caption="Descriptif" [width]="400"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" alignment="left" caption="Code" [width]="150"
              [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="actif" caption="Actif" [width]="60"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'">
      <span *ngIf="gds.canModify(cell.row.data); else visualisation">
      <yo-cell-button (yoNavigation)="openGroupeAchat(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      class="space"
                      [yoViewMode]="false"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="45"
                      yoTextAlign="center"
                      yoIconClass="fa fa-edit"
                      yoTooltip="Modifier l'unité de production"
      ></yo-cell-button></span>
    <ng-template #visualisation>
      <yo-cell-button (yoNavigation)="openGroupeAchat(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      class="space"
                      [yoViewMode]="true"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="45"
                      yoTextAlign="center"
                      yoIconClass="fa fa-edit"
                      yoTooltip="Visualiser l'unité de production"
      ></yo-cell-button>
    </ng-template>

    <yo-cell-button (yoNavigation)="deleteGroupeAchat(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoHiddenMode]="!gds.canModify(cell.row.data)"
                    [yoWidthPercent]="45"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer l'unité de production"
    ></yo-cell-button>
  </div>
  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell.data.site.libelle"
             [siteId]="cell.data.site.id">
    </yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="true"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Groupe(s) achats">
  </dxo-pager>
</dx-data-grid>
