import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {PreparationConditionnementSupplier} from "../../preparation-conditionnement-resolver.service";
import {PreparationConditionnementService} from "../../../core/services/conditionnements/preparation-conditionnement.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {MenuItem} from "primeng/api";
import {HELP_FOLDERS} from "../../../core/constants";

@Component({
  selector: 'yo-preparation-calcul-conditionnement',
  templateUrl: './preparation-calcul-conditionnement.component.html',
  styleUrls: ['./preparation-calcul-conditionnement.component.scss']
})
export class PreparationCalculConditionnementComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  idPlanProduction: number;

  headersResults: any[] = [];
  flatResults: any[] = [];

  idParentSelected: number;

  itemsPrintMenu: MenuItem[] = [{
    label: `Choix d'impression :`,
    items: [
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Sans détails PLC',
        command: event => this.pcSvc.printBonConditionnement(this.idPlanProduction, 'SIMPLE')
      },
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Avec détails PLC',
        command: event => this.pcSvc.printBonConditionnement(this.idPlanProduction, 'DETAILS')
      }
    ]
  }];

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-calcul-global';


  constructor(private route: ActivatedRoute,
              private pcSvc: PreparationConditionnementService,
              public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  ngOnDestroy(): void {
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { preparationConditionnementSupplier: PreparationConditionnementSupplier}) => {
        if (data.preparationConditionnementSupplier) {
          this.idPlanProduction = data.preparationConditionnementSupplier.idPlanProduction;

          if (data.preparationConditionnementSupplier.isRefresh) {
            this.pcSvc.deleteCalculation(this.idPlanProduction)
              .subscribe(r => {
                if (r && r.one && r.one.deletable) {
                  this.startCalculation();
                }
              });
          } else {
            this.pcSvc.getAllResults(this.idPlanProduction)
              .subscribe(results => {
                if (results.resultList && results.resultList.length) {
                  this.headersResults = results.resultList.filter(r => !r.distributionDirecte);
                } else {
                  this.startCalculation();
                }
              });
          }
        }
      });
  };

  startCalculation = () => {
    this.pcSvc.startCalculation(this.idPlanProduction)
      .subscribe(data => {
        this.pcSvc.getAllResults(this.idPlanProduction)
          .subscribe(data => {
            this.headersResults = data.resultList.length ? data.resultList.filter(d => !d.distributionDirecte) : data.resultList;
          });
      });
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.cursor = 'pointer';
    }
  };

  loadChildren = $event => {
    if($event.rowType === 'data') {
      const id = $event.data.id;
      this.idParentSelected = id;
    }
  };

  printLivraison = (pid: number) => {
    this.pcSvc.printBonLivraison(pid);
  };

  helpSummaries = () => {

  };

}
