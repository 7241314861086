<p-toast key="compteRendu" position="bottom-right"></p-toast>
  <div class="d-flex justify-content-between mg-b-10">
    <div>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="getHelp()" [width]="800"></yo-help>
      <strong>
        <span class="mg-r-5">Zones de stockage</span>
      </strong>
    </div>

    <button pButton icon="fas fa-save" (click)="saveZonesDeStockage()"
            [disabled]="!gds.canModify(uniteDeProduction)" pTooltip="Enregistrer" tooltipPosition="top"
            showDelay="500" >
    </button>
  </div>


  <fieldset>
    <div class="row">
      <!--COL 1-->
      <div class="col-md-auto">
        <!--JOURS-->
        <div class="row">
          <div class="col-md-12">
            <h3 class="first">Zones de stockage</h3>
            <p-listbox [options]="zoneDeStockageList"
                       multiple="true"
                       checkbox="true"
                       filter="true"
                       dataKey="id"
                       [(ngModel)]="zoneDeStockageSelectedList"
                       [style]="{'width':'350px'}"
                       optionLabel="libelle"
                       (onChange)="onChangeZoneDeStockage($event)"
                       [disabled]="!gds.canModify(uniteDeProduction)"
                       [listStyle]="{'max-height':'620px'}">
              <ng-template let-zoneDeStockage pTemplate="item">
                <div class="no-overflow"
                     [pTooltip]="tooltipZoneDeStockage(zoneDeStockage)"
                     showDelay="500"
                     style="display: inline-block">
                  <span>{{zoneDeStockage.libelle}}</span>
                  &nbsp;<i class="fas fa-exclamation-triangle danger-color space"
                           *ngIf="!zoneDeStockage.actif"></i>
                </div>
              </ng-template>
            </p-listbox>
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <div class="row">
    <div class="col-md-12 font-12">
      <p>
        🗣️<em> N'oubliez pas d'enregistrer.</em>
      </p>
    </div>
  </div>



