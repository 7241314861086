import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {ConfirmationService} from "primeng/api";
import {UnitePmsSavedSupplier, UnitePmsService} from "../../../../core/services/pms/unite-pms.service";
import {UnitePmsDto} from "../../../../core/dtos/pms/unite-pms-dto";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS} from "../../../../core/constants";

@Component({
  selector: 'yo-pms-unite-grille',
  templateUrl: './pms-unite-grille.component.html',
  styleUrls: ['./pms-unite-grille.component.scss']
})
export class PmsUniteGrilleComponent implements OnInit, OnDestroy {
  unitesList: UnitePmsDto[] = [];

  hasPms = false;

  subUniteSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-unites';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private confirmationSvc: ConfirmationService,
              private unitePmsSvc: UnitePmsService) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initUnitesPms();
    this.uniteSavedSubscription();
    this.initProperty();
  }

  initProperty = (): void => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subUniteSaved);
  }

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initUnitesPms = (): void => {
    this.unitePmsSvc.getAll()
      .subscribe(response => {
        this.unitesList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  uniteSavedSubscription = (): void => {
    this.subUniteSaved = this.unitePmsSvc.uniteSaved$
      .subscribe((response: UnitePmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.unitesList.push(response.unite);
        }
      });
  };

  openUnite = (u: UnitePmsDto): void => {
    this.unitePmsSvc.announceOpenDialog(u);
  };

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        const idsToDelete: number[] = this.selectedRows
          .map((idSelected: number) => this.unitesList.find(u => u.id === idSelected))
          .map((unite: UnitePmsDto) => unite.id);
        this.unitePmsSvc.deleteByIds(idsToDelete)
          .subscribe(o => this.unitesList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.unitesList));
      }
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les unités (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
