<p-table
  [scrollable]="true"
  styleClass="p-datatable-sm"
  [value]="_prw.rows">

  <!--HEADER-->
  <ng-template pTemplate="header">

    <tr>
      <th [style.width.px]="200">Prestations</th>
      <th [style.width.px]="100">Régimes</th>
      <ng-container *ngFor="let col of _prw.cols">
        <th [style.width.px]="colWidth">
          <div class="no-overflow" showDelay="500">
                        <span style="font-weight:normal;font-size:12px"
                              class="mg-r-5">{{col | date:'EEE d LLL'}}  </span>
          </div>
        </th>
      </ng-container>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr>

      <td class="no-overflow" [style.width.px]="200" [pTooltip]="rowData.cmcLibelle">
        {{rowData.cmcLibelle}}
      </td>
      <td class="no-overflow" [style.width.px]="100" [pTooltip]="rowData.libelle">
        {{rowData.libelle}}
      </td>

      <!--CELLULES-->
      <ng-container *ngFor="let col of _prw.cols">
        <ng-container *ngTemplateOutlet="tplCell;context:{cell:getCell(_prw,rowData,col)}"></ng-container>
      </ng-container>
    </tr>

  </ng-template>

</p-table>

<ng-template #tplCell let-cell="cell">

  <ng-container *ngIf="!utils.isNullOrEmpty(cell)">
    <td [style.width.px]="colWidth"
        [class.cell-saisie-effectifs]="cell.statutMenuCode === STATUTS_MENUS.SAISIE_EFFECTIFS && selectedCell!=cell"
        [class.cell-creation-menus]="cell.statutMenuCode === STATUTS_MENUS.CREATION_MENUS && selectedCell!=cell"
        [class.cell-selected]="selectedCell === cell"
        class=" cursor text-right" (click)="showRepas(cell,$event)">

      <ng-container [ngSwitch]="typeEffectif">

        <ng-container *ngSwitchCase="EffectifMode.Prevision">
          <!--          (focus)="showRepas(cell,$event)"-->
          <input pKeyFilter="pint"
                 type="number"
                 [class.disable]="isEffectifInputDisabled(cell)"
                 min="0"
                 (focusin)="showRepas(cell,$event)"
                 [ngModel]="cell.effectifPrevisionnel"
                 (ngModelChange)="changeEffectif($event,cell,'effectifPrevisionnel')"
                 (click)="$event.stopPropagation()"
                 class="cell text-right bold">
        </ng-container>

        <ng-container *ngSwitchCase="EffectifMode.Fabrication">
          <input pInputText pKeyFilter="pint"
                 [class.disable]="isEffectifInputDisabled(cell)"
                 min="0"
                 (focusin)="showRepas(cell,$event)"
                 [ngModel]="cell.effectifFabrication"
                 (ngModelChange)="changeEffectif($event,cell,'effectifFabrication')"
                 (click)="$event.stopPropagation()"
                 class=" cell text-right bold">
        </ng-container>

        <ng-container *ngSwitchCase="EffectifMode.Facturation">
          <input pInputText pKeyFilter="pint"
                 min="0"
                 (focusin)="showRepas(cell,$event)"
                 [class.disable]="isEffectifInputDisabled(cell)"
                 [ngModel]="cell.effectifFacture"
                 (click)="$event.stopPropagation()"
                 (ngModelChange)="changeEffectif($event,cell,'effectifFacture')"
                 class=" cell text-right bold">
        </ng-container>

      </ng-container>
    </td>
  </ng-container>

  <ng-container *ngIf="utils.isNullOrEmpty(cell)">
    <td [class.cell-selected]="false">
      <span>&nbsp;</span>
    </td>
  </ng-container>

</ng-template>
