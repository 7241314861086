import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {Subscription} from 'rxjs';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, TYPE_LIST_UDP} from '../../core/constants';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../core/dtos/object-dto';
import {UnitesDeProductionService} from '../../core/services/entities/unites-de-production.service';
import {SiteService} from '../../core/site.service';
import {SiteDTO} from '../../core/dtos/site-dto';
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'yo-grille-unites-production',
  templateUrl: './grille-unites-production.component.html',
  styleUrls: ['./grille-unites-production.component.scss']
})
export class GrilleUnitesProductionComponent implements OnInit, OnDestroy, AfterViewInit {

  subUnitesDeProduction: Subscription;
  subSaved: Subscription;

  displayedColumns = ['actions', 'siteLibelle', 'libelle', 'code', 'adresseFacturation', 'adresseLivraison', 'actif'];
  unitesDeProductionList: UniteDeProductionDTO[] = [];

  /**
   * Liste des sites locaux de l'utilisateur n'ayant pas de'unité de production
   */
  sitesLocauxUtilisateurSansUniteDeProduction: SiteDTO[] = [];

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              private udpSvc: UnitesDeProductionService,
              public genericManageDialogService: GenericManageDialogService,
              public title: Title,
              private confirmationSvc: ConfirmationService,
              private siteService: SiteService) {
    this.title.setTitle('Gestion des unités de production');
  }

  ngOnInit() {
    this.siteService.findSitesLocauxSansUniteDeProductionByUser().subscribe(data => {
      this.sitesLocauxUtilisateurSansUniteDeProduction = data;
      this.loadData();

      //Abonnement à la réponse d'enregistrement retournée par le back
      this.subSaved = this.udpSvc.savedDto$.subscribe(instanceObj => {
        this.siteService.findSitesLocauxSansUniteDeProductionByUser().subscribe(data => {
          this.sitesLocauxUtilisateurSansUniteDeProduction = data;
          this.loadData();
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subUnitesDeProduction);
    this.utils.unsubscribe(this.subSaved);
  }

  ngAfterViewInit(): void {
  }

  openEditObject = (dto: ObjectDTO) => {
    this.genericManageDialogService.openEdit(dto, FS_ROUTES.GUP_UPRO);
  };

  openDeleteObject = (dto: ObjectDTO) => {
    this.confirmationSvc.confirm({
      message: `Êtes vous sûr de vouloir supprimer ${dto.libelle} ?`,
      accept: () => {

        this.gfs.deleteOne(this.udpSvc.getEntityName(), dto.id).subscribe(response => {
          if (this.utils.isResponseSupplierError(response)) {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `${response.resultMessage}`, '', 8000);
          } else {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `${dto.libelle} supprimé avec succès.`);
            this.loadData();
          }
        })
      },
    });
    ;
  };

  loadData = () => {
    this.subUnitesDeProduction = this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal)
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.unitesDeProductionList = response.resultList;
        }
      });
  };

  public help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Les unités de production`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];
    dms.content = {
      intro: `Elles sont les centres de transformation des denrées en plats.`,
      paragraphes: []
    };
    return dms;
  };

  canCreate = (): boolean => {
    let response = this.auth2Svc.hasSitesLocaux() && this.sitesLocauxUtilisateurSansUniteDeProduction.length > 0;
    return response;
  };
}
