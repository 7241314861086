import {catchError, finalize} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AllergeneDTO} from '../../dtos/allergene-dto';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {DialogMsgSupplier, Paragraphe} from '../../suppliers/dialog-msg-supplier';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {ProduitAllergeneDTO} from '../../dtos/produit-allergene-dto';
import {Observable} from 'rxjs';
import {ProduitRouteMapService} from '../gestionproduits/produit-routemap.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldFileSupplier} from '../../suppliers/form-field-file-supplier';
import {startCase as _startCase} from 'lodash';
import {HttpService} from "../technique/http.service";

export const SANS_ALLERGENE = 'sans allergène';
export const INFO_INCOMPLETE = 'information incomplète';
export const CODE_INFO_INCOMPLETE = 'NONRENS';

export const URL_FIND_ALL_ALLERGENES=`dolrest/allergenes/findAll`;

@Injectable()
export class AllergenesService extends GenericHandler<AllergeneDTO> {

  getHelp = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage des allergènes `;
    dms.logo = 'fa fa-question-circle  yoni-color';


    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Pour ne pas afficher d'allergènes, il faut créer '${SANS_ALLERGENE}'.`,
      `Pour indiquer que la liste des allergènes est incomplète, il faut créer '${INFO_INCOMPLETE}'.`,
      `Sinon, vous ne pourrez pas paramétrer correctement la gestion produit.`
    ];


    dms.content = {
      intro: `Les allergènes`,
      paragraphes: [p1]

    };


    return dms;
  };


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getSort = (): string[] => ['code,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES ALLERGÈNES';

  getCreateNewObjectLabel = (): string => 'CRÉER UN ALLERGÈNE';


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private produitRouteMap: ProduitRouteMapService,
              private httpSvc :HttpService
  ) {
    super(utils, auth2Svc, router, http, title);
  }

  getEntityName = (): string => 'allergene';

  getFields = (dto: AllergeneDTO): FormFieldBaseSupplier<any>[] => {

    // Par défaut un Allergene est actif.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new AllergeneDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType:'string',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'libellelong',
        label: 'Nom familier',
        excelType:'string',
        readonly: !this.canModify(dto),
        value: dto.libellelong,
        maxLength: 255,
        required: false,
        order: 3
      }),


      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4
      }),

      new FormFieldFileSupplier({
        key: 'file',
        label: 'Logo',
        readonly: !this.canModify(dto),
        value: '',
        entityName: this.getEntityName().toLowerCase(),
        required: false,
        refresh: new Date().getTime(),
        ordre: 1,
        order: 5
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  };


  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): AllergeneDTO => new AllergeneDTO();


  getProduitAllergeneIsActif = (idProduitDeclinaison: number, idAllergene: number, arr: ProduitAllergeneDTO[]): boolean => {

    let paArr = arr.filter(item => item.produitDeclinaisonId == idProduitDeclinaison && item.allergeneId == idAllergene);
    if (!this.utils.isCollectionNullOrEmpty(paArr)) {
      return paArr[0].actif;
    }
    return false;
  };

  setProduitAllergeneIsActif = (idProduitDeclinaison: number, idAllergene: number, arr: ProduitAllergeneDTO[], event: boolean) => {

    //desactiver le sans allergene si un allergene est coché
    if (event) {
      // console.log('desactiver le sans allergene si un allergene est coché',item);

      arr.filter(item => item.produitDeclinaisonId == idProduitDeclinaison && item.allergeneLibelle.toLowerCase().trim() == SANS_ALLERGENE)[0].actif = false;
    }

    arr.filter(item => item.produitDeclinaisonId == idProduitDeclinaison && item.allergeneId == idAllergene)[0].actif = event;
  };


  /***
   * Si sans allergene = true, on décoche tous les allergens du produit décliné
   * @param {number} idProduitDecline
   * @param {number} idAllergene
   * @param {boolean} event
   */
  setSansAllergene = (idProduitDeclinaison: number, listProduitAllergenes: ProduitAllergeneDTO[], event: boolean) => {

    let pa: ProduitAllergeneDTO = listProduitAllergenes.filter(item => item.produitDeclinaisonId === idProduitDeclinaison && item.allergeneLibelle.toLowerCase().trim() === SANS_ALLERGENE)[0];
    pa.actif = event;

    //si sans allergne, on desactive toutes les coches du produit decline
    if (event === true) {
      listProduitAllergenes.filter(item => item.produitDeclinaisonId === idProduitDeclinaison && item.allergeneLibelle.toLowerCase().trim() !== SANS_ALLERGENE)
        .map(item => item.actif = false);
    }
  };

  isWithoutOrIncomplete = (allergene: AllergeneDTO): boolean => {
    // si id == 1 ou 2 c'est sans allergene ou information incomplete
    const allergeneStr = allergene.libelle.toLowerCase().trim();

    // console.log('allergeneStr',allergeneStr);
    if (allergeneStr === SANS_ALLERGENE || allergeneStr === INFO_INCOMPLETE) {
      // console.log('allergeneStr true',allergeneStr);
      return true;
    }

    return false;
  };

  isWithout = (allergene: AllergeneDTO): boolean => {
    // si id == 1
    if (allergene.libelle.toLowerCase().trim() === SANS_ALLERGENE) {
      return true;
    }
    return false;
  };


  /**
   * Initialisation du tableau des allergenes
   * @param {ProduitAllergeneDTO[]} startArr
   * @param {AllergeneDTO[]} listAllergenes
   * @param {ProduitDeclinaisonDTO[]} listProduitDeclinaison
   * @returns {ProduitAllergeneDTO[]}
   */
  initProduitAllergeneList = (startArr: ProduitAllergeneDTO[], listAllergenes: AllergeneDTO[], listProduitDeclinaison: ProduitDeclinaisonDTO[]): ProduitAllergeneDTO[] => {

    //on init tout à false
    const newArr: ProduitAllergeneDTO[] = this.initToFalse(listAllergenes, listProduitDeclinaison);

    //on met les valeurs de la db
    if (startArr && startArr.length > 0) {
      for (let na of newArr) {
        for (let sa of startArr) {
          if (na.produitDeclinaisonId == sa.produitDeclinaisonId && na.allergeneId == sa.allergeneId) {
            na.actif = sa.actif;
            na.id = sa.id;

          }
        }
      }
    }

    //on coche actif sur les sans allergeens s'il n'existe pas d'allergene sur le produit decline
    for (let pd of listProduitDeclinaison) {
      let tmpArr = newArr.filter(item => pd.id === item.produitDeclinaisonId && item.actif === true && item.allergeneLibelle.toLowerCase().trim() !== SANS_ALLERGENE);
      if (tmpArr && tmpArr.length > 0) {
        newArr.map(item => {

          if (item.produitDeclinaisonId === pd.id && item.allergeneLibelle.toLowerCase().trim() === SANS_ALLERGENE) {
            // console.log("actif false")
            item.actif = false;
          }
        });
      }
    }

    return newArr;
  };

  initToFalse = (listAllergenes: AllergeneDTO[], listProduitDeclinaison: ProduitDeclinaisonDTO[]): ProduitAllergeneDTO[] => {
    let newArr: ProduitAllergeneDTO[] = [];
    for (let pd of listProduitDeclinaison) {
      for (let a of listAllergenes) {
        let pa: ProduitAllergeneDTO = new ProduitAllergeneDTO();
        if (a.libelle.toLowerCase().trim() === SANS_ALLERGENE) {
          pa.actif = true;
        } else {
          pa.actif = false;
        }

        pa.allergeneId = a.id;
        pa.allergeneLibelle = a.libelle;
        pa.id = 0;
        pa.produitDeclinaisonId = pd.id;
        newArr.push(pa);
      }
    }
    // console.log(newArr);
    return newArr;
  };

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER L'ALLERGÈNE '${data.libelle.toUpperCase()}'`;

  save = (listProduitAllergene: ProduitAllergeneDTO[]): Observable<ProduitAllergeneDTO[]> => {
    return this.http.post<ProduitAllergeneDTO[]>(this.produitRouteMap.getSaveProduitsAllergenesSLink(), listProduitAllergene).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  /**
   * Récupère tous les allergenes auxquelles l'utilisateur connecté a droit
   */
  findAll = (isActif: boolean = true) => this.httpSvc.get(`${URL_FIND_ALL_ALLERGENES}?isActif=${isActif}`);
}
