import {NgModule} from '@angular/core';

import {GestionListesBesoinsRoutingModule} from './gestion-listes-besoins-routing.module';
import {SharedModule} from '../shared/shared.module';

import {GrilleListesBesoinsComponent} from './grille-listes-besoins/grille-listes-besoins.component';

import {CoreModule} from '../core/core.module';
import {GestionListesBesoinsRootComponent} from './gestion-listes-besoins-root/gestion-listes-besoins-root.component';
import {LbComponent} from './lb/lb.component';
import {GestionProcessusModule} from '../gestion-processus/gestion-processus.module';
import {BesoinsComponent} from './liste-besoins/besoins/besoins.component';
import {ArticlesComponent} from './liste-besoins/besoins/articles/articles.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DxDataGridModule, DxDropDownBoxModule, DxLookupModule, DxSelectBoxModule} from 'devextreme-angular';
import {BulkEditionArticleComponent} from './liste-besoins/besoins/articles/bulk-edition-article/bulk-edition-article.component';
import {TooltipModule} from 'primeng/tooltip';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {ToolbarModule} from 'primeng/toolbar';
import {RadioButtonModule} from "primeng/radiobutton";


@NgModule({
    imports: [
        GestionListesBesoinsRoutingModule,
        CoreModule,
        CommonModule,
        SharedModule,
        GestionProcessusModule,
        FormsModule,
        ReactiveFormsModule,

        TooltipModule,
        CardModule,
        TableModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        ToastModule,
        ButtonModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        SelectButtonModule,
        MultiSelectModule,
        OverlayPanelModule,
        ProgressBarModule,
        InputTextModule,
        DxSelectBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        DxLookupModule,
        ToolbarModule,
        RadioButtonModule


    ],
  entryComponents: [

  ],
  declarations: [
    GestionListesBesoinsRootComponent,
    GrilleListesBesoinsComponent,
    BesoinsComponent,
    LbComponent,
    ArticlesComponent,
    BulkEditionArticleComponent
  ],
})
export class GestionListesBesoinsModule {
}
