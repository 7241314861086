import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import {SignatureUtilisateurPmsDto} from "../../dtos/pms/signature-utilisateur-pms-dto";

export const URL_GET_ALL = 'dolrest/signatures-utilisateur-pms/all';
export const URL_SAVE = 'dolrest/signatures-utilisateur-pms/';
export const URL_DELETE = 'dolrest/signatures-utilisateur-pms/';

@Injectable({
  providedIn: 'root'
})
export class SignatureUtilisateurPmsService {
  private subjectOpenDialog = new Subject<SignatureUtilisateurPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectSignatureUtilisateurSaved = new Subject<SignatureUtilisateurPmsSavedSupplier>();
  signatureUtilisateurSaved$ = this.subjectSignatureUtilisateurSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  getEntityName = () : string => {
    return "utilisateurpms";
  }

  /**
   * Récupère toutes les signatures utilisateur pms
   * @returns {Observable<SignatureUtilisateurPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<SignatureUtilisateurPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);


  /**
   * Sauvegarde une signature utilisateur pms
   * @param signatureUtilisateur pms
   * @param file
   */
  save = (signatureUtilisateur: SignatureUtilisateurPmsDto, file: File) : Observable<ResponseWrapper<SignatureUtilisateurPmsDto>> => {
    let formData = new FormData();
    formData.set('signature', JSON.stringify(signatureUtilisateur));
    formData.set('file', file);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.httpSvc.post(URL_SAVE, formData);
  }

  /**
   * Supprime une ou plusieurs signature(s) utilisateur pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: SignatureUtilisateurPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (signatureUtilisateur: SignatureUtilisateurPmsDto, isUpdate: boolean = false) : void => {
    this.subjectSignatureUtilisateurSaved.next({ isUpdate, signatureUtilisateur } as SignatureUtilisateurPmsSavedSupplier);
  }
}

export interface SignatureUtilisateurPmsSavedSupplier {
  isUpdate: boolean;
  signatureUtilisateur: SignatureUtilisateurPmsDto;
}
