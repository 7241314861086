import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrintEffectifService, PrintEffectifSupplier} from '../../../core/services/gestion-plc/print-effectif.service';
import {switchMap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';
import {EffectifMode} from '../constant/menu-plc.constant';
import {RegimeAlimentaireService} from '../../../core/services/entities/regime-alimentaire.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from "../../../core/utils/utils.service";
import {PlcWeek} from "../menu-plc-resolver.service";
import {find as _find} from 'lodash';

@Component({
  selector: 'yo-print-effectif',
  templateUrl: './print-effectif.component.html',
  styleUrls: ['./print-effectif.component.scss']
})
export class PrintEffectifComponent implements OnInit, OnDestroy {

  selectedDate: Date;
  selectedPlc: PointDeLivraisonDTO;
  repasList: RepasDTO[];
  plcWeek: PlcWeek;
  selectedRepasList: RepasDTO[];

  displayDialog: boolean;
  details: boolean;
  modeOptionsList: SelectItem[];
  selectedMode: SelectItem[] = [];
  modeRegimesList: SelectItem[];
  selectedRegimes: SelectItem[] = [];

  // subscription
  subDisplayDialogPrintEffectif: Subscription;
  subRegimes: Subscription;

  constructor(private printEffectifService: PrintEffectifService,
              private gds: GenericDatagridService,
              private utils: UtilsService,
              private regimeAlimentaireService: RegimeAlimentaireService) {
  }

  ngOnInit(): void {
    this.initModeOptionsList();
    this.displayDialogSubscription();
    this.regimesSubscription();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subDisplayDialogPrintEffectif);
    this.utils.unsubscribe(this.subRegimes);
  }

  getPlcRepasList = (plcWeek: PlcWeek): RepasDTO[] => {
    let repasList = [];
    if (!this.utils.isNullOrEmpty(plcWeek)
      && !this.utils.isCollectionNullOrEmpty(plcWeek.repasList)) {
      repasList = plcWeek.repasList.map(item => item.repas);
    }
    return repasList;
  };

  public closeDialog = () => {
    this.displayDialog = false;
  };

  public printEffectif = () => {
    if (!this.utils.isNullOrEmpty(this.plcWeek) && !this.utils.isCollectionNullOrEmpty(this.plcWeek.repasList)) {
      let modeIdList: number[] = [];
      this.selectedMode.forEach((item: SelectItem) => {
        modeIdList.push(item.value);
      });

      let regimeIdList: number[] = [];
      if (this.selectedRegimes) {
        this.selectedRegimes.forEach((item: SelectItem) => {
          regimeIdList.push(item.value);
        });
      }

      const plcRepasWeekSelectedList = this.getPlcRepasWeekSelectedList(this.plcWeek);
      plcRepasWeekSelectedList.forEach(item => this.printEffectifService.exportExcel(item, this.selectedDate, this.selectedPlc, modeIdList, this.details, regimeIdList));
      const pes = new PrintEffectifSupplier(this.selectedPlc, this.selectedDate, this.plcWeek, false, this.details);
      this.printEffectifService.announceDisplayDialogPrintEffectif(pes);
    }
  };

  private getPlcRepasWeekSelectedList = (plcWeek: PlcWeek) => {
    const plcRepasWeekSelectedList = plcWeek.repasList.filter(item => {
      const elt = _find(this.selectedRepasList, {'id': item.repas.id})
      if (elt) {
        return true;
      }
      return false;
    });
    return plcRepasWeekSelectedList;
  };


  private initModeOptionsList = () => {

    this.modeOptionsList = [
      {label: "Prévision", value: EffectifMode.Prevision},
      {label: "Fabrication", value: EffectifMode.Fabrication},
      {label: "Facturation", value: EffectifMode.Facturation}
    ];
    this.selectedMode = this.modeOptionsList;
  };

  private regimesSubscription = () => {
    this.subRegimes = this.gds.getAll(this.regimeAlimentaireService.getEntityName(), this.regimeAlimentaireService.getSort(), true).subscribe(response => {
      this.modeRegimesList = [];
      for (let regime of response.resultList) {
        this.modeRegimesList.push({label: regime.libelle, value: regime.id})
      }
      this.selectedRegimes = this.modeRegimesList;
    });
  };

  private displayDialogSubscription = () => {
    this.subDisplayDialogPrintEffectif = this.printEffectifService.displayDialogPrintEffectif$
      .pipe(
        switchMap((response: PrintEffectifSupplier) => {
          this.displayDialog = response.display;
          this.details = response.details;
          this.plcWeek = response.plcWeek;

          this.repasList = this.getPlcRepasList(this.plcWeek);
          this.selectedDate = response.selectedDate;
          this.selectedPlc = response.selectedPlc;
          this.selectedRepasList = [...this.repasList];

          return of(null);
        })
      ).subscribe();
  };


  isPrintDisabled = () => {
    if (this.selectedMode.length === 0
      || this.utils.isCollectionNullOrEmpty(this.selectedRepasList)
      || this.utils.isCollectionNullOrEmpty(this.selectedRegimes)
    ) {
      return true;
    }
    return false;
  };
}
