<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [style]="{'width':'500px'}"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sort-numeric-up mg-r-5"></i>Prioriser les fournisseurs
    </div>
  </ng-template>

  <dx-data-grid
    #dataGridFournisseur
    [height]="440"
    [allowColumnResizing]="true"
    [dataSource]="fournisseurList"
    [showBorders]="true">

    <!--    COLONNE-->
    <dxi-column dataField="ordre" dataType="number" caption="Priorité" alignment="center" [allowEditing]="false"
                [width]="80"></dxi-column>
    <dxi-column dataField="libelle" caption="Fournisseurs" [width]="200"></dxi-column>

    <!--    OPTIONS-->
    <dxo-row-dragging
      [allowReordering]="true"
      [onReorder]="onReorder"
      [showDragIcons]="true">
    </dxo-row-dragging>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="none"></dxo-sorting>
  </dx-data-grid>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Réorganiser l'ordre de priorité des
        fournisseurs </label></div>
    </div>
    <div>
      <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
    </div>
  </div>
</p-dialog>
