import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AdressesService} from '../../../../core/services/entities/adresses.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {FormFieldBaseSupplier} from '../../../../core/suppliers/form-fieldbase-supplier';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {DialogMsgSupplier} from '../../../../core/suppliers/dialog-msg-supplier';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../../../core/services/entities/fournisseur.service';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {GROUP_POSITION, GroupFormFields} from '../../../../core/suppliers/group-formfields';
import {SecteurDTO} from '../../../../core/dtos/secteur-dto';
import {SecteurFournisseurDTO} from '../../../../core/dtos/secteurfournisseur-dto';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {FournisseurFicheIdentiteSupplier} from './fournisseur-fiche-identite-resolver.service';
import {Auth2Service} from "../../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-fournisseur-fiche-identite',
  templateUrl: './fournisseur-fiche-identite.component.html',
  styleUrls: ['./fournisseur-fiche-identite.component.scss']
})
export class FournisseurFicheIdentiteComponent implements OnInit {

  subSite: Subscription;

  subscriptionRoute: Subscription;
  subSidenav: Subscription;
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];

  groups: GroupFormFields[] = [];
  form: FormGroup;
  formFiliale: FormGroup = new FormGroup({});
  formAdresse: FormGroup = new FormGroup({});
  formSecteurs: FormGroup = new FormGroup({});
  formFraisPort: FormGroup = new FormGroup({});

  fournisseur: FournisseurDTO;
  adresse: AdresseDTO;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS + '/fournisseur-edition';

  constructor(public fournisseurSvc: FournisseurService,
              public gestionFournisseurScv: GestionFournisseurService,
              public adressesSvc: AdressesService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              public utils: UtilsService) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSidenav);
  }

  initData = () => {
    this.form = new FormGroup({
      formFiliale: this.formFiliale,
      formAdresse: this.formAdresse,
      formSecteurs: this.formSecteurs,
      formFraisPort: this.formFraisPort
    });

    this.subscriptionRoute = this.route.data
      .subscribe((data: { fournisseurSupplier: FournisseurFicheIdentiteSupplier }) => {
        this.fournisseur = data.fournisseurSupplier.fournisseur;
        this.adresse = this.fournisseur.adresse;
        this.initForm(this.fournisseur);
      });
  };



  initForm = (fournisseur: FournisseurDTO) => {

    const fieldsFiliale = this.fournisseurSvc.getFields(fournisseur);
    const fieldsAdresse = this.adressesSvc.getFields(fournisseur.adresse ? fournisseur.adresse : this.adressesSvc.createEmptyDTO());

    const fieldsInfoKey = ['site', 'libelle', 'code', 'siret', 'actif'];
    const fieldsInfo = this.gfs.getFieldsByKey(fieldsInfoKey, fieldsFiliale);
    this.formFiliale = this.gfs.toFormGroup(fieldsInfo);

    const fieldsSecteurKey = ['secteur'];
    const fieldSecteur = this.gfs.getFieldsByKey(fieldsSecteurKey, fieldsFiliale);
    this.formSecteurs = this.gfs.toFormGroup(fieldSecteur);

    const fieldsAdresseKey = ['nom', 'adresse1', 'adresse2', 'adresse3', 'codePostal', 'ville', 'telephone', 'fax', 'email'];
    const fieldAdresse = this.gfs.getFieldsByKey(fieldsAdresseKey, fieldsAdresse);
    this.formAdresse = this.gfs.toFormGroup(fieldAdresse);

    const fieldsFraisPortKey = ['francoDePortActif', 'francoDePortCout', 'francoDePortMontant', 'francoDePortPoids', 'francoDePortTva'];
    const fieldFraisPort = this.gfs.getFieldsByKey(fieldsFraisPortKey, fieldsFiliale);
    this.formFraisPort = this.gfs.toFormGroup(fieldFraisPort);

    this.form = new FormGroup({
      formFiliale: this.formFiliale,
      formSecteurs: this.formSecteurs,
      formAdresse: this.formAdresse,
      formFraisPort: this.formFraisPort
    });

    this.groups = [
      {
        titlePanel: 'Filiale',
        fields: fieldsInfo,
        form: this.formFiliale,
        titleIcon: 'fas fa-industry',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsInfoKey,
        }
      },
      {
        titlePanel: 'Frais de port',
        fields: fieldFraisPort,
        form: this.formFraisPort,
        titleIcon: 'fas fa-truck-moving',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsFraisPortKey,
        }
      },
      {
        titlePanel: 'Secteur',
        fields: fieldSecteur,
        form: this.formSecteurs,
        titleIcon: 'fas fa-map-marker-alt',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsSecteurKey,
        }
      },
      {
        titlePanel: 'Adresse',
        fields: fieldAdresse,
        form: this.formAdresse,
        titleIcon: 'fas fa-home',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsAdresseKey,
        }
      }
    ];
  };


  saveFicheIdentite = () => {

    // Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);

    // On enregsitre que si le formulaire est valide
    if (this.form.valid) {
      // Filiale
      // @ts-ignore
      this.fournisseur.site =  this.utils.getLightSite(this.formFiliale.controls['site'].value);
      this.fournisseur.libelle = this.formFiliale.controls['libelle'].value;
      this.fournisseur.code = this.formFiliale.controls['code'].value;
      this.fournisseur.siret = this.formFiliale.controls['siret'].value;
      this.fournisseur.actif = this.formFiliale.controls['actif'].value;
      this.fournisseur.francoDePortActif = this.formFraisPort.controls['francoDePortActif'].value;
      this.fournisseur.francoDePortMontant = this.formFraisPort.controls['francoDePortMontant'].value;
      this.fournisseur.francoDePortPoids = this.formFraisPort.controls['francoDePortPoids'].value;

      // todo A supprimer une fois la table fournisseur supprimer ttt
      this.fournisseur.idFournisseur = null;

      // cout du franco de port, si null on le force à 0
      const francoDePortCout = this.formFraisPort.controls['francoDePortCout'].value;
      this.fournisseur.francoDePortCout = this.utils.isNullOrEmpty(francoDePortCout) ? 0 : francoDePortCout;

      // franco de port TVA par défaut est à 20%
      const francoDePortTva = this.formFraisPort.controls['francoDePortTva'].value;
      this.fournisseur.francoDePortTva = this.utils.isNullOrEmpty(francoDePortTva) ? 20 : francoDePortTva;

      // Adresse
      this.fournisseur.adresse = this.adressesSvc.prepareAdresseForSave(this.formAdresse, this.fournisseurSvc.getEntityName());
      this.fournisseur.adresse.id = this.adresse.id;
      this.fournisseur.adresse.site = this.adresse.site;

      // Secteurs de livraison
      this.fournisseur.secteurFournisseurList = [];
      let selectedSecteurs: SecteurDTO[] = this.formSecteurs.controls['secteur'].value;

      if (this.utils.isCollectionNullOrEmpty(selectedSecteurs)) {
        selectedSecteurs = [];
      }
      selectedSecteurs.map(secteur => {
        const secteurFournisseur = new SecteurFournisseurDTO(this.fournisseur.id, secteur);
        this.fournisseur.secteurFournisseurList.push(secteurFournisseur);
      });

      this.gestionFournisseurScv.saveFournisseur(this.fournisseur).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {

          this.fournisseur = response.one;
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Enregistrement du fournisseur "${this.fournisseur.libelle.toUpperCase()}".`, '', 3000);

          // annoncer que la fiche d'identite a été sauvegardée. La grille des unites de prod doit être abonnée pour faire la mise à jour
          this.gestionFournisseurScv.announceRefreshGrid(this.fournisseur);

          // mise à jour de la liste des menus
          this.gestionFournisseurScv.announceFournisseur(this.fournisseur);
        }
      });
    }
  };

  help = (): DialogMsgSupplier => undefined;

  isDisabledSaveBtn = (): boolean =>
    this.fournisseur && this.fournisseur.id ? !(this.form.valid && this.auth2Svc.isSiteLocal(this.fournisseur.site.id)) : !this.form.valid;

}
