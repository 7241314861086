import {Component, OnDestroy, OnInit} from '@angular/core';
import {DATEPICKER_FR, STOCKS_FEATURES} from '../../../core/constants';
import {UtilsService} from '../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UniteDeProduction__zoneDeStockageService} from '../../../core/services/entities/unite-de-production__zone-de-stockage.service';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {CataloguesAchatsService} from '../../../core/services/entities/catalogues-achats.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {TreeNode} from 'primeng/api';
import {StocksSupplier} from '../../stocks/stocks-resolver.service';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {StockMouvementStatutDTO} from '../../../core/dtos/stock-mouvement-statut-dto';
import {StockMouvementService} from '../../../core/services/gestion-stock/stock-mouvement.service';
import * as moment from 'moment';
import {StockMouvementStatutService} from '../../../core/services/gestion-stock/stock-mouvement-statut.service';

@Component({
  selector: 'yo-search-panel-mouvements',
  templateUrl: './search-panel-mouvements.component.html',
  styleUrls: ['./search-panel-mouvements.component.scss']
})
export class SearchPanelMouvementsComponent implements OnInit, OnDestroy {
  get allSubscription(): () => void {
    return this._allSubscription;
  }

  set allSubscription(value: () => void) {
    this._allSubscription = value;
  }

  subFormSearch: Subscription;
  subFormSearchBalances: Subscription;
  subFeature:Subscription;
  subSearchBalanceMouvement:Subscription;
  subPaginationSearchMouvement:Subscription;
  subExportMouvement:Subscription;

  subRoute: Subscription;
  formSearch: FormGroup;
  feature:string;

  tree: TreeNode[];
  selectedNodes: TreeNode[];

  mouvementsStatut: StockMouvementStatutDTO[];

  filterEntree: boolean;
  filterSortie: boolean;

  localeFr = DATEPICKER_FR;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private udpZdsSvc: UniteDeProduction__zoneDeStockageService,
              private stocksSvc: StocksService,
              private catalogueAchatSvc: CataloguesAchatsService,
              private stocksMvtSvc:StockMouvementService,
              private stockMvtStatutSvc: StockMouvementStatutService) {
  }

  initForm = () => {

    const dateDebMonth = moment().startOf('month').toDate();
    const dateEndMonth = moment().endOf('month').toDate();

    this.formSearch = new FormGroup({
      denominationArticle: new FormControl(''),
      referenceInterneArticle: new FormControl(''),
      lotArticle: new FormControl(''),
      lotArticleFournisseur: new FormControl(undefined),
      mouvements: new FormControl([]),
      periode: new FormControl([dateDebMonth, dateEndMonth]),
      filterEntree: new FormControl(),
      filterSortie: new FormControl()
    });
  };

  ngOnInit() {
    this.initForm();
    this._allSubscription();
    this.subscriptionFormSearch();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subFeature);
    this.utils.unsubscribe(this.subFormSearch);
    this.utils.unsubscribe(this.subFormSearchBalances);
    this.utils.unsubscribe(this.subSearchBalanceMouvement);
    this.utils.unsubscribe(this.subPaginationSearchMouvement);
    this.utils.unsubscribe(this.subExportMouvement);
  }

  private _allSubscription = () => {
    // chargement de la grille avec les criteres par defaut
    this.subFeature = this.stocksSvc.feature$
      .pipe(
        filter(feature=> feature===STOCKS_FEATURES.MOUVEMENTS),
        switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value,this.selectedNodes,0,50)),
        map(response => this.stocksMvtSvc.announceResultSearchMouvements(response)),
        switchMap(values => this.stocksMvtSvc.searchBalancesInMouvements(this.formSearch.value,this.selectedNodes)),
        map(response => this.stocksMvtSvc.announceResultSearchBalancesMouvements(response)),
        // tap(response => console.log('in subscribe subFeature', response)),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe();

    // la pagination a changé
    this.subPaginationSearchMouvement = this.stocksMvtSvc.paginationSearchMouvement$.pipe(
      switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value,this.selectedNodes,values.page,values.size)),
      map(response => this.stocksMvtSvc.announceResultSearchMouvements(response)),
      // tap(response => console.log('in subscribe subPaginationSearchStock', response)),
      catchError(err => this.utils.handleError(err))
    ).subscribe();

    // Demande d'export excel
    this.subExportMouvement = this.stocksMvtSvc.exportMouvement$.pipe(
      switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value,this.selectedNodes,null,100000)),
      map(response => this.stocksMvtSvc.announceResultExportMouvements(response)),
      catchError(err => this.utils.handleError(err))
    ).subscribe();

    this.subRoute = this.route.data
      .subscribe((data: { stocksSupplier: StocksSupplier }) => {

        this.tree = data.stocksSupplier.tree;
        this.mouvementsStatut = data.stocksSupplier.mouvementsStatuts;
      });
  };

  filterTypeMouvements = ($event: any, typeMouvement: string): void => {
    if(typeMouvement === 'E') {
      this.filterEntree = $event.checked;
    } else {
      this.filterSortie = $event.checked;
    }
    if(!this.filterEntree && !this.filterSortie) {
      this.filterEntree = null;
      this.filterSortie = null;
    }
    this.stockMvtStatutSvc.getAll(this.filterSortie, this.filterEntree)
      .subscribe(res => this.mouvementsStatut = res.resultList);
  };

  onChangeLieuxDeStockage = ($event: any) => {
    // console.log('onChangeLieuxDeStockage', this.selectedNodes, $event);
    this.selectedNodes = $event;
    // déclencher la recherche avec un temps de latence debounceTiem du this.formSearch.valueChanges
    this.formSearch.updateValueAndValidity({onlySelf: false, emitEvent: true});

  };

  subscriptionFormSearch = (): void => {
    this.subFormSearch = this.formSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      // tap(values => console.log('subFormSearch selectedNodes', this.selectedNodes)),
      // map(values => this.searchStockSupplier = new SearchStockSupplier(values, this.selectedNodes, this.feature, 0, 50)),
      switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value, this.selectedNodes,0,50)),
      map(response => this.stocksMvtSvc.announceResultSearchMouvements(response)),
      switchMap(values => this.stocksMvtSvc.searchBalancesInMouvements(this.formSearch.value, this.selectedNodes)),
      map(response => this.stocksMvtSvc.announceResultSearchBalancesMouvements(response)),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe();
  };

}
