import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'yo-icon-lock',
  template: `
    <i class="fa fa-lock danger-color space"
       *ngIf="yoIsLocked" [pTooltip]="yoTooltipNo" [showDelay]="yoTooltipDurationMs"></i>
    <i class="fa fa-unlock save-color space"
       *ngIf="!yoIsLocked" [pTooltip]="yoTooltipYes" [showDelay]="yoTooltipDurationMs"></i>
  `,
  styleUrls: ['./icon-lock.component.scss']
})
export class IconLockComponent implements OnInit {

  /**
   * Indique si le verrou (lock) est fermé.
   */
  @Input() yoIsLocked : boolean;

  /**
   * Tooltip affiché quand le verrou (lock) est fermé (i.e. quand yoIsLocked vaut true).
   */
  @Input() yoTooltipNo : string;

  /**
   * Tooltip affiché quand le verrou (lock) est ouvert (i.e. quand yoIsLocked vaut false).
   */
  @Input() yoTooltipYes : string;
  @Input() yoColor : string;
  @Input() yoTooltipDurationMs : number = 500;


  constructor() { }

  ngOnInit() {
  }

}
