<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('required')">
  Le champ {{yoLabel | lowercase}} est obligatoire.
</div>
<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('minlength')">
  Le champ {{yoLabel | lowercase}} doit avoir au moins {{yoMinLength}} caractère(s).
</div>
<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('greaterThanZero')">
  Le champ {{yoLabel | lowercase}} doit être supérieur à 0.
</div>
<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('maxlength')">
  Le champ {{yoLabel | lowercase}} doit avoir au plus {{yoMaxLength}} caractère(s).
</div>
<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('lessThanOne')">
  Le champ {{yoLabel | lowercase}} doit être inférieur à 1.
</div>
<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('min')">
  Le champ {{yoLabel | lowercase}} doit être supérieur ou égal à {{yoMin}}.
</div>
<div class="error" *ngIf="yoFormControl.dirty && yoFormControl.hasError('max')">
  Le champ {{yoLabel | lowercase}} doit être inférieur ou égal à {{yoMax}}.
</div>
