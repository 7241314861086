<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div class="d-flex flex-rowa align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="openConditionnementVariante(undefined)"
          [disabled]="!canEdit()"
          pTooltip="Créer une association entre un conditionnement et une variante">
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="right">

    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canEdit() || !selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>

  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="conditionnementsVariantesList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="conditionnement.libelle" caption="Conditionnement" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="variante.libelle" caption="Variante" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} variantes">
  </dxo-pager>

</dx-data-grid>


<yo-conditionnement-variante></yo-conditionnement-variante>
