<p-card [style]="{'width': '300px',
'min-height':'calc(100vh - 180px)',
'max-height':'calc(100vh - 180px)',
'overflow-y': 'auto',
'overflow-x':'hidden',
'margin-right': '15px'}">
  <ng-template pTemplate="header">

    <i class="fas fa-search mg-r-5 mg-t-10 mg-l-15"></i> RECHERCHER
  </ng-template>

    <form [formGroup]="formSearch">

      <h3 class="first">Période</h3>
      <p-calendar
        firstDayOfWeek="1"
        appendTo="body"
        class="mg-r-40"
        dateFormat="dd/mm/yy"
        pTooltip="Sélection d'une période"
        tooltipPosition="bottom"
        showDelay="500"
        [style]="{'width':'100%'}"
        selectionMode="range"
        formControlName="periode"
        [readonlyInput]="true"
        [showButtonBar]="false"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true">
      </p-calendar>

      <h3 class="first">Mouvements</h3>
      <div class="mg-b-5">
        <div>
          <p-checkbox name="filterEntree" value="1" [formControl]="formSearch.controls['filterEntree']" (onChange)="filterTypeMouvements($event, 'E')"></p-checkbox>
            <i class="fas fa-arrow-right bold mg-l-5 mg-r-5 new-color"></i>Entrées en stock
        </div>
        <div>
          <p-checkbox name="filterSortie" value="0" [formControl]="formSearch.controls['filterSortie']" (onChange)="filterTypeMouvements($event, 'S')"></p-checkbox>
            <i class="fas fa-arrow-left bold  mg-l-5 mg-r-5 danger-color"></i>Sorties de stock
        </div>
      </div>
      <p-listbox [options]="mouvementsStatut"
                 formControlName="mouvements"
                 multiple="multiple"
                 checkbox="checkbox"
                 [style]="{'width':'252px'}"
                 optionLabel="libelle">
        <ng-template let-statut let-i="index" pTemplate="item">
          {{statut.libelle}}
        </ng-template>
      </p-listbox>

      <h3 class="first">Lieux de stockage</h3>
      <p-tree [value]="tree"
              [filter]="true"
              styleClass="no-overflow"
              filterMode="strict"
              emptyMessage="Aucune zone de stockage"
              (selectionChange)="onChangeLieuxDeStockage($event)"
              selectionMode="checkbox" [(selection)]="selectedNodes">
      </p-tree>


      <h3 class="first">Réf interne article </h3>
      <input type="text"
             formControlName="referenceInterneArticle"
             class="width-250">

      <h3 class="first">Dénomination article</h3>
      <input type="text"
             pInputText
             formControlName="denominationArticle"
             class="width-250">

      <h3 class="first">Lot</h3>
      <input type="text"
             pInputText
             formControlName="lotArticle"
             class="width-250">

      <h3 class="first">Lot Fournisseur</h3>
      <input type="text"
             pInputText
             formControlName="lotArticleFournisseur"
             class="width-250">
    </form>

</p-card>

