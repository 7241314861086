<p-dialog header="CRÉER UNE NOUVELLE PROPOSITION DE COMMANDE"
          (onHide)="closeDialog()"
          [dismissableMask]="false"
          [contentStyle]="{'overflow':'visible'}"
          [(visible)]="displayDialog"
          [responsive]="true"
          [modal]="true"
          [style]="{'width':'1200px','height':'200px'}">
  <p-toast key="pc" position="bottom-right"></p-toast>
  <form [formGroup]="form" novalidate (ngSubmit)="addPropositionCommande()">

  <!--UNITÉ DE PRODUCTION-->
  <div class="form-group row">
    <label for="udp" class="col-sm-2 col-form-label space">UNITÉ DE PRODUCTION&nbsp;<span class="required"></span></label>
    <div class="col-sm-10">
      <p-dropdown id="udp"
                  [options]="_uniteDeProductionList"
                  pTooltip="Sélection d'une unité de production"
                  tooltipPosition="bottom"
                  showDelay="500"
                  [style]="{'width':'500px'}"
                  [showClear]="false"
                  (onChange)="getSecteursFournisseurs($event)"
                  emptyFilterMessage=""
                  optionLabel="libelle"
                  placeholder="Choisir une unité de production..."
                  formControlName="uniteDeProduction"
                 filter="true">
        <!--template unité de production sélectionnéz-->
        <ng-template let-item pTemplate="selectedItem">

          <ng-container *ngIf="!utils.isNullOrEmpty(form.controls['uniteDeProduction'].value)">
            <label class="space no-overflow mg-b-0">
              <yo-site [siteId]="form.controls['uniteDeProduction'].value?.site?.id"
                       [siteLibelle]="form.controls['uniteDeProduction'].value?.libelle"></yo-site>
            </label>
          </ng-container>

        </ng-template>

        <!--template liste des unités de production-->
        <ng-template let-udp pTemplate="item">
          <span>
            <label class="no-overflow ">
              <yo-site [siteId]="udp?.site?.id"
                       [siteLibelle]="udp?.libelle"></yo-site>
            </label>
          </span>
        </ng-template>
      </p-dropdown>
      <yo-control-error label="Unité de production" [control]="form.controls['uniteDeProduction']"></yo-control-error>
    </div>
  </div>

  <!--SECTEUR FOURNISSEUR-->
  <div class="form-group row">
    <label for="fournisseur" class="col-sm-2 col-form-label space ">FOURNISSEUR&nbsp;<span class="required"></span></label>
    <div class="col-sm-10">
      <p-dropdown id="fournisseur"
                  [options]="secteurFournisseurList"
                  pTooltip="Sélection d'un fournisseur"
                  tooltipPosition="bottom"
                  showDelay="500"
                  [style]="{'width':'500px'}"
                  [showClear]="false"
                  (onChange)="getJoursLivraisons($event)"
                  emptyFilterMessage=""
                  optionLabel="fournisseurLibelle"
                  placeholder="Choisir un fournisseur..."
                  formControlName="udpSecteurFournisseur"
                  filter="true">
        <!--template unité de production sélectionnéz-->
        <ng-template let-item pTemplate="selectedItem">

          <ng-container *ngIf="!utils.isNullOrEmpty(item)">
            <label class="space no-overflow mg-b-0">
              {{item.fournisseurLibelle | uppercase}} ({{item.fournisseurCode}})
            </label>
          </ng-container>

        </ng-template>

        <!--template liste des  secteurs fournisseurs-->
        <ng-template let-item pTemplate="item">
          <span>
            <label class="no-overflow ">
              {{item.fournisseurLibelle | uppercase}} ({{item.fournisseurCode}})
            </label>
          </span>
        </ng-template>
      </p-dropdown>

      <yo-control-error label="Fournisseur" [control]="form.controls['udpSecteurFournisseur']"></yo-control-error>
    </div>
  </div>

  <!--DATE DE LIVRAISON-->
  <div class="form-group row">
    <label for="dateLiv" class="col-sm-2 col-form-label space">DATE DE LIVRAISON&nbsp;<span class="required"></span></label>
    <div class="col-sm-10">

      <p-calendar
        firstDayOfWeek="1"
        id="dateLiv"
        dateFormat="dd/mm/yy"
        tooltipPosition="bottom"
        formControlName="dateLivraison"
        [readonlyInput]="true"
        [locale]="fr"
        [showIcon]="true"
        [disabledDays]="disabledDays"
        [disabled]="isCalendarDisabled()"
        [showWeek]="true"
        [minDate]="minDate">
        <ng-template pTemplate="date" let-date>
          <ng-container
            *ngTemplateOutlet="tplDateMenuCompo;context:{isDateFournisseur:bonCfSvc.isDateFournisseur(date,this.joursSemaineFournisseur,this.udpJoursLivraison,this.delaiLivraison,this.heureLimite),date:date}"></ng-container>
        </ng-template>

      </p-calendar>

      <yo-control-error label="Date de livraison" [control]="form.controls['dateLivraison']"></yo-control-error>
    </div>
  </div>

  <div class="row mg-t-10">
    <div class="col-md-12 text-right">
        <span class="mg-r-5">
          <button type="submit" pButton icon="fas fa-plus"  label="Ajouter"></button>
        </span>
      <button type="button" pButton icon="fas fa-times"
              class="p-button-secondary"
              (click)="closeDialog()" label="Fermer"></button>

    </div>
  </div>

  </form>

</p-dialog>




<!--TEMPLATE DU CALENDRIER POUR LE HIGHLIGHT DES DATES QUI CORRESPONDENT AUX JOURS DE LIVRAISON DU SECTEUR FOURNISSEUR SÉLECTIONNÉ-->
<ng-template #tplDateMenuCompo let-isDateFournisseur="isDateFournisseur" let-date="date">

  <ng-container *ngIf="isDateFournisseur">
    <div class="creation-menus-day" pTooltip="Jour de livraison." showDelay="1500">{{date.day}}</div>
  </ng-container>


  <ng-container *ngIf="!isDateFournisseur">
    <div pTooltip="Jour de livraison NON paramétré pour ce fournisseur">{{date.day}}</div>
  </ng-container>


</ng-template>
