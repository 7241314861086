import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {RoutemapService} from "../../../../core/services/routemap.service";
import ModeleConditionnementPlcDTO from "../../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {ModelesPlcService} from "../../../../core/services/conditionnements/plc/modeles-plc.service";

@Component({
  selector: 'yo-mp-identite-plc',
  templateUrl: './mp-identite.component.html',
  styleUrls: ['./mp-identite.component.scss']
})
export class ModelePlcIdentiteComponent implements OnInit, OnDestroy {

  hasIDistri = false;

  modelePointLivraison: ModeleConditionnementPlcDTO = null;

  subRoute: Subscription;
  subSidenav: Subscription;

  form: FormGroup = new FormGroup({});

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-edition-modeles-plc';

  constructor(public utils: UtilsService,
              public gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private mcPlcSvc: ModelesPlcService) {}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subSidenav);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initSidenavSubscription();
    this.initDataFromSupplier();
    this.initForm();
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  initSidenavSubscription = (): void => {
    this.subSidenav = this.routeMapSvc.sidenav$.subscribe(sidenav => {
      this.initForm();
    });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initDataFromSupplier = () => {
    this.subRoute = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlcSupplier && data.modelePlcSupplier.modelePlc) {
          this.modelePointLivraison = data.modelePlcSupplier.modelePlc;
        } else {
          this.modelePointLivraison = new ModeleConditionnementPlcDTO();
          this.modelePointLivraison.id = 0;
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    const libelle = this.modelePointLivraison && this.modelePointLivraison.libelle ? this.modelePointLivraison.libelle : '';
    const code = this.modelePointLivraison && this.modelePointLivraison.code ? this.modelePointLivraison.code : '';
    const site = this.modelePointLivraison ? this.findAllLocalSites().find(s => s.id === this.modelePointLivraison.site?.id) : null;

    this.form = new FormGroup({
      site: new FormControl(site, [Validators.required]),
      libelle: new FormControl(libelle, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      code: new FormControl(code, [Validators.required, Validators.maxLength(50), Validators.minLength(2)])
    });
  };

  canModify = (): boolean => this.modelePointLivraison && this.hasIDistri && this.gds.canModify(this.modelePointLivraison);

  save = (): void => {
    if (this.form.valid) {
      this.modelePointLivraison.site = { id: this.form.controls['site'].value.id } as SiteDTO;
      this.modelePointLivraison.libelle = this.form.controls['libelle'].value;
      this.modelePointLivraison.code = this.form.controls['code'].value;
      this.mcPlcSvc.save(this.modelePointLivraison).subscribe(response => {
        const mcPlc: ModeleConditionnementPlcDTO = response.one;
        this.mcPlcSvc.announceMcPlcSaved(mcPlc, this.modelePointLivraison.id !== undefined && this.modelePointLivraison.id !== null && this.modelePointLivraison.id !== 0);
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde du modèle plc réalisée avec succès.');

        this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLC, mcPlc.id, 'ficheidentite']);

      });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Fiche d'identité`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
