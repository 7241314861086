import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ValueAccessorBase} from "../value-accessor-base";

@Component({
  selector: 'yo-input-text',
  templateUrl: './input-text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ],
  styleUrls: ['./input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextComponent extends ValueAccessorBase<string> {

  @Input() yoLabelTooltip: string;
  @Input() yoLabel: string;
  @Input() yoRequired: boolean;
  @Input() yoMinLength: number;
  @Input() yoMaxLength: number;
  @Input() yoFormControl: FormControl;
  @Input() yoInfo: string;
  @Input() yoDisabled: boolean;

  /**
   * Ce composant sera caché si sa value est à vide ou nulle
   * @type {boolean}
   */
  @Input() yoInputHidden: boolean = false;
  @Input() yoUppercase: boolean = false;
  @Input() yoLowercase: boolean = false;
  @Input() yoErase: boolean = false;


  @Output() yoOnErase = new EventEmitter();


  toUppercase(val: string) {

    if (val) {
      this.value = val.trim().toUpperCase();
    }
  }

  erase(val: string) {
    this.value = "";
    this.yoOnErase.emit(this.value);
  }

  toLowercase(val: string) {

    if (val) {
      this.value = val.trim().toLowerCase();
    }
  }
}


