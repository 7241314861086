import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {ConfirmationService} from "primeng/api";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TypeEquipementPmsDto} from "../../../../core/dtos/pms/type-equipement-pms-dto";
import {
  TypeEquipementPmsSavedSupplier,
  TypeEquipementPmsService
} from "../../../../core/services/pms/type-equipement-pms.service";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS} from "../../../../core/constants";

@Component({
  selector: 'yo-pms-type-equipement-grille',
  templateUrl: './pms-type-equipement-grille.component.html',
  styleUrls: ['./pms-type-equipement-grille.component.scss']
})
export class PmsTypeEquipementGrilleComponent implements OnInit, OnDestroy {
  typesEquipementList: TypeEquipementPmsDto[] = [];

  hasPms = false;

  subTypeEquipementSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-types-equipements';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private confirmationSvc: ConfirmationService,
              private typeEquipementPmsService: TypeEquipementPmsService) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initTypesEquipementPms();
    this.typeEquipementSavedSubscription();
    this.initProperty();
  }

  initProperty(): void {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTypeEquipementSaved);
  }

  canEdit(): boolean {
    return this.hasPms && this.auth2Svc.hasSitesLocaux();
  }

  initTypesEquipementPms(): void {
    this.typeEquipementPmsService.getAll()
      .subscribe(response => {
        this.typesEquipementList = response.resultList;
      });
  }

  initHasPms(): void {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  }

  typeEquipementSavedSubscription(): void {
    this.subTypeEquipementSaved = this.typeEquipementPmsService.typeEquipementSaved$
      .subscribe((response: TypeEquipementPmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.typesEquipementList.push(response.typeEquipement);
        }
      });
  }

  openTypeEquipement(t: TypeEquipementPmsDto): void {
    this.typeEquipementPmsService.announceOpenDialog(t);
  }

  deleteValues(): void {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        const idsToDelete: number[] = this.selectedRows
          .map((idSelected: number) => this.typesEquipementList.find(t => t.id === idSelected))
          .map((type: TypeEquipementPmsDto) => type.id);
        this.typeEquipementPmsService.deleteByIds(idsToDelete)
          .subscribe(o => this.typesEquipementList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.typesEquipementList));
      }
    });
  }

  public help(): DialogMsgSupplier {
    const dms = new DialogMsgSupplier();
    dms.title = `Les types d'équipement PMS (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  }
}
