import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModelesPlatsService} from '../../core/services/conditionnements/modeles-plats.service';
import ModeleConditionnementPlatDTO from '../../core/dtos/conditionnement/modele-conditionnement-plat-dto';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import {OverlayPanel} from "primeng/overlaypanel";
import {SiteDTO} from "../../core/dtos/site-dto";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-modeles-plats',
  templateUrl: './modeles-plats.component.html',
  styleUrls: ['./modeles-plats.component.scss']
})
export class ModelesPlatsComponent implements OnInit, OnDestroy {

  modelesPlatsList: ModeleConditionnementPlatDTO[] = [];

  subModelePlatSaved: Subscription;

  hasIDistri = false;

  exportBtnDisabled = false;
  exportOptionList: any[] = [];
  selectExportOption: any;

  duplicationSiteSelectedId: number;
  modeleIdToDuplicate: number;

  duplicationSiteSelected: SiteDTO;

  /**
   * La p-overlayPanel pour la duplication du modèle
   */
  @ViewChild('opDuplication') opDuplication: OverlayPanel;

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plats';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private modelsPlatsSvc: ModelesPlatsService,
              private genericManageDialogService: GenericManageDialogService) {
  }

  ngOnInit(): void {
    this.initModelesPlats();
    this.initHasGestionIDistri();
    this.initModelePlatSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subModelePlatSaved);
  }

  initModelesPlats = (): void => {
    this.modelsPlatsSvc.getAllModelesConditionnementsPlats()
      .subscribe(response => {
        this.modelesPlatsList = response.resultList;
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initModelePlatSavedSubscription = (): void => {
    this.subModelePlatSaved = this.modelsPlatsSvc.mcpSaved$
      .subscribe(response => {
        if (!response.isUpdate) {
          this.modelesPlatsList.push(response.mpc);
        } else {
          const idx = this.modelesPlatsList.findIndex(m => m.id === response.mpc.id);
          if (idx > -1) {
            this.modelesPlatsList[idx] = response.mpc;
          }
        }
      });
  };

  openModelePlat = (modelPlat: ModeleConditionnementPlatDTO): void => {
    this.genericManageDialogService.openEdit(modelPlat, FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT);
  };

  /**
   * ouverture p-overlayPanel pour la duplication du modèle
   * @param $event
   * @param rowData
   */
  openPanelDuplication = ($event, rowData: ModeleConditionnementPlatDTO) => {
    if (this.auth2Svc.utilisateur.siteListLocaux.length >= 1) {
      this.duplicationSiteSelected = this.auth2Svc.utilisateur.siteListLocaux[0];
    } else {
      this.duplicationSiteSelected = this.auth2Svc.utilisateur.sitePrincipal;
    }
    this.modeleIdToDuplicate = rowData.id;
    this.opDuplication.toggle($event);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les modèles de plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  canModify = (modelePlat: ModeleConditionnementPlatDTO): boolean => modelePlat.site && this.auth2Svc.isSiteLocal(modelePlat.site.id) && this.hasIDistri;

  canCreate = (): boolean => this.hasIDistri;


  duplicateModele = async (): Promise<void> => {
    let keepMcPlc = await confirm("Souhaitez-vous garder les liaisons avec le modèle conditionnement PLC concerné par le modèle à dupliquer ? (Veuillez faire en sorte que les prestations de vos modèles conditionnement PLC soient cohérentes pour éviter des résultats inattendus)", "Duplication du modèle");
    this.modelsPlatsSvc.duplicateModele(this.modeleIdToDuplicate, this.duplicationSiteSelected.id, keepMcPlc)
      .subscribe((response: ResponseWrapper<ModeleConditionnementPlatDTO>) => {
      const msg = `Modèle de plat dupliqué avec succès.`;
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, msg, '', 3000);
      this.modelsPlatsSvc.announceMcpSaved(response.one, false);
      this.opDuplication.hide();
    });
  };

}
