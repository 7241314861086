import {Component, OnDestroy, OnInit} from '@angular/core';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';
import {TachesService} from '../../../../../core/services/entities/taches.service';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {MParametrage} from '../../../../../core/models/gestion-unites-production/m-parametrage';

@Component({
  selector: 'yo-parametrage-previsualisation',
  templateUrl: './parametrage-previsualisation.component.html',
  styleUrls: ['./parametrage-previsualisation.component.scss']
})
export class ParametragePrevisualisationComponent implements OnInit, OnDestroy {

  elementsToPreview: MParametrage[] = [];

  subscriptionElementsToSendAreReady: Subscription;

  toolTipDeleteAssociation = 'Supprimer cette association';

  confirmDeletion = 'L\'association a été supprimée avec succès.';

  constructor(public utils: UtilsService,
              private tachesService: TachesService) {}

  ngOnInit(): void {
    this.elementsToSendAreReadySubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionElementsToSendAreReady);
  }

  elementsToSendAreReadySubscription(): void {
    this.subscriptionElementsToSendAreReady = this.tachesService.elementsToSendAreReady$.subscribe((elements) => {
      this.elementsToPreview = [];
      if (elements) {
        elements.forEach(element => {
          if (element.equipesList) {
            element.equipesList.forEach(equipe => {
              if (equipe.tachesList) {
                equipe.tachesList.forEach(tache => {
                  const parametrage: MParametrage = new MParametrage();
                  parametrage.udpId = element.udpId;
                  parametrage.idAtelier = element.id;
                  parametrage.idEquipe = equipe.id;
                  parametrage.libelleEquipe = equipe.libelle;
                  parametrage.libelleAtelier = element.libelle;
                  parametrage.idTypeTache = tache.idTypeTache;
                  parametrage.libelleTypeTache = tache.libelleTypeTache;
                  parametrage.libelleTache = tache.libelle;
                  parametrage.idTache = tache.id;
                  this.elementsToPreview.push(parametrage);
                });
              }
            });
          }
        });
      }
    });
  }

  deleteAssociation(udpId: number, idZdp: number, idEquipe: number, idTache: number): void {
    this.tachesService.deleteAssociation(udpId, idZdp, idEquipe, idTache)
      .subscribe(response => {
        if (!response.inError) {
          this.tachesService.announceLoadAssociations();
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.confirmDeletion);
        }
      });
  }

}
