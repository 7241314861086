import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';

export const URL_GET_MODEL_COMMON = `dolrest/search-graphql/model/global?html=true`;
export const URL_GET_MODEL_REFERENTIAL = `dolrest/search-graphql/model/referential?html=true`;
export const URL_GET_MODEL_MARCHE = `dolrest/search-graphql/model/marche?html=true`;
export const URL_GET_MODEL_FOURNISSEUR = `dolrest/search-graphql/model/fournisseur?html=true`;
export const URL_POST_QUERY = `dolrest/search-graphql`;

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {

  private subjectQueryReceived = new Subject<any>();
  queryReceived$ = this.subjectQueryReceived.asObservable();

  private subjectOpenDialogForDetailsRow = new Subject<any>();
  openDialogForDetailsRow$ = this.subjectOpenDialogForDetailsRow.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupére le modèle de données global GraphQL
   * @returns {Observable<string>}
   */
  getModelCommon(): Observable<string> {
    return this.httpSvc.get(URL_GET_MODEL_COMMON, null);
  }

  /**
   * Récupére le modèle de données référentiel GraphQL
   * @returns {Observable<string>}
   */
  getModelReferential(): Observable<string> {
    return this.httpSvc.get(URL_GET_MODEL_REFERENTIAL, null);
  }

  /**
   * Récupère le modèle de données marchés GraphQL
   */
  getModelMarche(): Observable<string> {
    return this.httpSvc.get(URL_GET_MODEL_MARCHE, null);
  }

  /**
   * Récupère le modèle de données fournisseurs GraphQL
   */
  getModelFournisseur(): Observable<string> {
    return this.httpSvc.get(URL_GET_MODEL_FOURNISSEUR, null);
  }

  /**
   * Envoie la requête GraphQL au serveur et retourne
   * les résultats au format JSON
   * @param query
   */
  sendQuery(query: string): Observable<any> {
    return this.httpSvc.post(URL_POST_QUERY, query, null);
  }

  /**
   * Lors de la réception des résultats GraphQL,
   * l'observateur va prévenir le(s) composant(s)
   * de rendu(s) GraphQL (Json / Tableau) de l'arrivée
   * de ces derniers.
   * @param data Résultats
   */
  announceQueryReceived(data: any): void {
    this.subjectQueryReceived.next(data);
  }

  /**
   * Permet l'exploitation d'une ligne GraphQL dans le détail.
   * @param originalRow Ligne GraphQL d'origine (sans modifications)
   */
  announceDialogDetails(originalRow: any): void {
    this.subjectOpenDialogForDetailsRow.next(originalRow);
  }

}

