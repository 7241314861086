import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../../../core/suppliers/form-fieldbase-supplier';
import {GROUP_POSITION, GroupFormFields} from '../../../../../core/suppliers/group-formfields';
import {ActivatedRoute} from '@angular/router';
import {InfoLotMarcheSupplier} from './info-lot-marche-resolver.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import {GenericFormService} from '../../../../../core/services/generics/generic-form.service';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {
  GestionLotMarcheService,
  TAB_NAME_LM
} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {FK_LM, LotMarcheService} from '../../../../../core/services/entities/lot-marche.service';
import {ResponseWrapper} from '../../../../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';


@Component({
  selector: 'yo-dialog-info-lot-marche',
  templateUrl: './dialog-info-lot-marche.component.html',
  styleUrls: ['./dialog-info-lot-marche.component.scss']
})
export class DialogInfoLotMarcheComponent implements OnInit, OnDestroy {

  lotMarche: LotMarcheDTO;

  subscriptionRoute: Subscription;

  form: FormGroup;
  formInfoGenerales: FormGroup = new FormGroup({});
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  groups: GroupFormFields[] = [];

  value: any;

  constructor(public utils: UtilsService,
              public gds: GenericDatagridService,
              private route: ActivatedRoute,
              private lotMarcheSvc: LotMarcheService,
              private gfs: GenericFormService,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              private auth2Svc: Auth2Service) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  initData() {
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });
    this.subscriptionRoute = this.route.data.subscribe((data: { infoLotMarcheSupplier: InfoLotMarcheSupplier }) => {
      this.lotMarche = data.infoLotMarcheSupplier.lotMarche;
      if (!this.utils.isNullOrEmpty(this.lotMarche)) {
        this.initForm(this.lotMarche);
      }
    });
  }

  initForm(lotMarche: LotMarcheDTO) {

    this.fieldsInfosGenerales = this.lotMarcheSvc.getFields(lotMarche);

    //desactiver les placeholder
    this.fieldsInfosGenerales.map(field => field.displayPlaceholder = false);
    this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });

    this.groups = [
      {
        titlePanel: 'Lot marché',
        fields: this.fieldsInfosGenerales,
        form: this.formInfoGenerales,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: lotMarche.id > 0 ? [FK_LM.libelle, FK_LM.code, FK_LM.descriptif, FK_LM.dateDebut, FK_LM.dateFin, FK_LM.valeurEngagement, FK_LM.actif]
            : [FK_LM.libelle, FK_LM.descriptif, FK_LM.dateDebut, FK_LM.dateFin, FK_LM.valeurEngagement, FK_LM.actif]
        }
      }
    ];

    //annonce le display du dialog pour eviter de la latence
    this.gestionLotMarcheSvc.announceOpenDialogLotMarche(lotMarche, TAB_NAME_LM.info);
  }

  saveFicheIdentite() {

    this.lotMarche.libelle = this.formInfoGenerales.controls[FK_LM.libelle].value;
    this.lotMarche.dateDebut = this.formInfoGenerales.controls[FK_LM.dateDebut].value;
    this.lotMarche.dateFin = this.formInfoGenerales.controls[FK_LM.dateFin].value;
    this.lotMarche.descriptif = this.formInfoGenerales.controls[FK_LM.descriptif].value;
    this.lotMarche.actif = this.formInfoGenerales.controls[FK_LM.actif].value;
    this.lotMarche.valeurEngagement = this.formInfoGenerales.controls[FK_LM.valeurEngagement].value;

    if (this.checkDateFin(this.lotMarche.dateDebut, this.lotMarche.dateFin)) {
      this.gestionLotMarcheSvc.saveLotMarche(this.lotMarche).subscribe((response: ResponseWrapper<LotMarcheDTO>) => {
        //routing vers context
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, 'Informations enregistrées', '');
        this.gestionLotMarcheSvc.announceLotMarche(response.one, TAB_NAME_LM.info);
      });
    }
  }

  isDisabledSaveBtn() {
    if (this.lotMarche.marche) {
      return !(this.form.valid && this.auth2Svc.isSiteLocal(this.lotMarche.marche.site.id));
    }
  }

  closeDialog() {
    this.gestionLotMarcheSvc.announceCloseDialogLotMarche(true);
  }

  checkDateFin(dateDebut: Date, dateFin: Date) {
    if (dateFin >= dateDebut) {
      return true;
    } else {
      this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.WARN, `Attention la "Date de fin" doit être supérieure ou égale à la "Date de début" `, '', 5000);
      this.formInfoGenerales.controls[FK_LM.dateFin].markAsDirty();
      return false;
    }
  }
}
