
<div class="cursor" [pTooltip]="!utils.isCollectionNullOrEmpty( ligne.platGemrcnList)?'Cliquer, pour voir le détail':''" tooltipPosition="right" (click)="showPlats=!showPlats">
  <yo-couleur [hexColor]="ligne.familleGemrcn.couleur.hexa"></yo-couleur>
  {{ligne.familleGemrcn.libelle}}
</div>


<div class="mg-t-10" *ngIf="showPlats" @animateTransition>
  <ng-container *ngFor="let platGemrcn of ligne.platGemrcnList">
    <div class="font-12 mg-l-5 ">
      <span class="mg-r-20">{{platGemrcn.produitDeclinaisonLibelle}}</span>
      <span class="genlabel">({{platGemrcn.repasLibelle}}, {{platGemrcn.decoupageRepasLibelle | titlecase}}
        , {{utils.getFrenchDate_ddd_ll(platGemrcn.dateMenu)}}
        )</span>
    </div>
  </ng-container>
</div>



