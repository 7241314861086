import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ConfigurationPortailDTO} from "../../../core/dtos/portail/configuration-portail-dto";
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../core/utils/utils.service";
import {ConfigurationPortailService} from "../../../core/services/portail/configuration-portail.service";
import {ConfigurationPortailPlcGridSupplier} from "./configuration-portail-plc-grid-resolver.service";
import {DialogMsgSupplier} from "../../../core/suppliers/dialog-msg-supplier";
import { confirm } from 'devextreme/ui/dialog';
import {HELP_FOLDERS} from "../../../core/constants";

@Component({
  selector: 'yo-configuration-portail-plc-grid',
  templateUrl: './configuration-portail-plc-grid.component.html',
  styleUrls: ['./configuration-portail-plc-grid.component.scss']
})
export class ConfigurationPortailPlcGridComponent implements OnInit, OnDestroy {

  configurationPortailPlcList: ConfigurationPortailDTO[] = [];
  subRoute: Subscription;
  subRefreshGrid: Subscription;
  subConfigSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/portail-configuration-plc';

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private configurationPortailSvc: ConfigurationPortailService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit(): void {
    this.subInitDataRoute();
    this.subcriptionRefreshGrid();
    this.subscriptionConfigurationSaved();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subRefreshGrid);
    this.utils.unsubscribe(this.subConfigSaved);
  }

  subInitDataRoute = (): void => {
    this.subRoute = this.route.data.subscribe((data: { configurationPortailPlcGridSupplier: ConfigurationPortailPlcGridSupplier }) => {
      this.configurationPortailPlcList = data.configurationPortailPlcGridSupplier.configurationPortailPlcList;
    });
  }

  subcriptionRefreshGrid = (): void => {
    this.subRefreshGrid = this.configurationPortailSvc.subjectOpenDialogFormConfigPortailPlc$.subscribe( data =>{
      this.configurationPortailSvc.getAll().subscribe(data => {
        this.configurationPortailPlcList =  data.resultList;
      });
    });
  }

  subscriptionConfigurationSaved = (): void => {
    this.subConfigSaved = this.configurationPortailSvc.subjectRefreshConfigPlcList$.subscribe(() => {
      this.configurationPortailSvc.getAll().subscribe(data => {
        this.configurationPortailPlcList = data.resultList;
      });
    });
  }

  openEditConfigurationPortailPlc = (row: ConfigurationPortailDTO): void => {
    this.configurationPortailSvc.announceOpenDialogAddPlc(row);
  }

  deleteValues = (): void => {
    let result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation de suppression');
    result.then((dialogResult) => {
      if (dialogResult)
        this.configurationPortailSvc.deleteByIds(this.selectedRows)
                        .subscribe(o => this.configurationPortailPlcList = this.configurationPortailPlcList.filter(cfg => !this.selectedRows.includes(cfg.id)));
      });
  };

  help = (): DialogMsgSupplier => undefined;

  createEmptyPortailCfg = () :ConfigurationPortailDTO => {
    return {
      id: 0,
      uniteBlocage: 'week',
      nombreUniteBlocage: 1,
      jourLimiteSaisi: 'Lundi',
      minValeurSaisie: 1,
      maxValeurSaisie: 10,
      heureLimiteSaisie: 16,
    } as ConfigurationPortailDTO;
  }

}
