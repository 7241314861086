<form [formGroup]="form" (ngSubmit)="saveFicheIdentite()" novalidate>
  <div class="card ">
    <div class="card-header">
      <label class="mg-r-5 bold">Prestation {{contratMenuConvive?.convive?.libelle | uppercase}}</label>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <span class="floatright">
                <yo-button-save
                  [yoDisabled]="!gds.canModify(contratMenuConvive)"
                  yoType="submit">
           </yo-button-save>
          <yo-button-delete
            [yoDisabled]="!canDelete(contratMenuConvive)"
            yoType="button"
            [yoHiddenMode]="!canDelete(contratMenuConvive)"
            (click)="openDeleteDialog(contratMenuConvive)">
        </yo-button-delete>
          </span>
    </div>
    <div class="card-body" style="overflow: auto;max-height:85vh;">

      <ng-container *ngFor="let field of fieldsInfosGenerales">
        <yo-dynamic-form-field-bs [fields]="fieldsInfosGenerales" [field]="field" [form]="formInfoGenerales"></yo-dynamic-form-field-bs>
      </ng-container>

    </div>
  </div>
</form>

