import {ModeleDeclinaisonPoidsNetFtDto} from "./modele-declinaison-poids-net-ft-dto";
import {ModeleDeclinaisonNbUcDto} from "./modele-declinaison-nb-uc-dto";
import {InstructionDeclinaisonModel} from "../../../models/gestion-conditionnements/instruction-declinaison";

export class ModeleImplementationMcpDto {

  id: number;

  udpMcpId: number;

  mcpId: number;

  produitId: number;

  uniteAConditionnerId: number;

  remarques: string;

  poidsUc: number;

  poidsNetFTByDeclinaisons: ModeleDeclinaisonPoidsNetFtDto[] = [];

  nbUcByDeclinaisons: ModeleDeclinaisonNbUcDto[] = [];

  instructions: InstructionDeclinaisonModel[] = [];

  udpLibelle: string;

  mcpLibelle: string;

  mode: string;

  update: boolean;

}
