<p-toast key="pp" position="bottom-right"></p-toast>

<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button
      pButton label="CRÉER"
      pTooltip="Créer un plan de production"
      [disabled]="!canCreatePlanProduction()"
      icon="fa fa-plus"
      type="button"
      (click)="openInitProcessus()">
    </button>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      icon="fa fa-trash"
      (click)="deletePlanProdItems()"
      [disabled]="isActionDisabled"
      pTooltip="Supprimer les plans de production sélectionnés">
    </button>
  </ng-template>
</p-toolbar>
<dx-data-grid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onSelectionChanged)="checkDisabledButtons()"
  #grid>
  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100" cellTemplate="templateActions" [allowSorting]="false" alignement="left" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" cellTemplate="templateSite" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="libelle" caption="Plan de production" cellTemplate="templateLibelle" [setCellValue]="changeValueLibelle" [allowEditing]="true"></dxi-column>
  <dxi-column dataField="date" caption="Date" cellTemplate="templateDate" [allowEditing]="false" alignment="left"></dxi-column>
  <dxi-column dataField="code" caption="Code" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="workflowInstance.libelle" caption="Processus" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="workflowInstance.libelleWorkflowStatut" caption="Avancement" cellTemplate="templateAvancement" [allowSorting]="false" [allowEditing]="false" alignment="left"></dxi-column>
  <div *dxTemplate="let cell of 'templateActions'">
    <td>
      <button pButton icon="fas fa-edit"
              class="mg-l-5 p-button-secondary"
              pTooltip="Visualiser le plan de production"
              tooltipPosition="top"
              showDelay="500"
              (click)="workflowSvc.openProductionListPlatsTask(cell.row.data.workflowInstance.id)"
              [disabled]="!gds.canModify(cell.row.data)">
      </button>
      <yo-button-next-steps
        class="mg-l-5"
        [play]="true"
        (onError)="updateErrors($event)"
        [workflowInstance]="cell.row.data.workflowInstance">
      </yo-button-next-steps>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateSite'">
    <yo-site [siteLibelle]="cell.row.data.site.libelle"
             [siteId]="cell.row.data.site.id"></yo-site>
  </div>
  <div *dxTemplate="let cell of 'templateLibelle'">
    <strong>{{ cell.value }}</strong>
  </div>
  <div *dxTemplate="let cell of 'templateDate'">
    <td style="text-align: left;">
      {{cell.row.data.date | date:'E d LLL yyyy' | titlecase}}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateAvancement'">
    <td (click)="workflowSvc.openTask(cell.row.data.workflowInstance)"
        [class.hover-background-grey]="cell.row.data.workflowInstance.codeWorkflowStatut !== 'end'">
      <div>
        <i class="fas fa-square fa-lg  mg-r-10"
           [style.color]="'#'+cell.row.data.workflowInstance.hexCouleurWorkflowStatut"></i>
        <label class="cursor">{{cell.row.data.workflowInstance.libelleWorkflowStatut  | uppercase}}</label>
      </div>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} plan(s) de production">
  </dxo-pager>

</dx-data-grid>

<!--DIALOG IMPRESSION D'UN PLAN DE PRODUCTION-->
<yo-print-settings [displayDialog]="displayPrintSettings"
                   [datesConso]="datesConso"
                   [datesFab]="datesFab"
                   [equipes]="equipes"
                   [planProd]="planProd" (onClose)="displayPrintSettings=$event">
</yo-print-settings>

<yo-init-processus></yo-init-processus>

