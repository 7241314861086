import {Component, OnInit} from '@angular/core';
import {MotifsNonConformiteService} from '../../core/services/entities/motifs-non-conformite.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-motifs-non-conformite',
  templateUrl: './motifs-non-conformite.component.html',
  styleUrls: ['./motifs-non-conformite.component.scss']
})
export class MotifsNonConformiteComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/motifs-non-conformite';

  constructor(public motifNonConformiteSvc:MotifsNonConformiteService) { }

  ngOnInit() {
  }

}
