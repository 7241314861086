import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {BonCfDetailDTO} from '../../dtos/boncf-detail-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {CatalogueAchatDTO} from '../../dtos/catalogue-achat-dto';
import {BonCfDTO} from '../../dtos/boncfs-dto';
import {BehaviorSubject} from 'rxjs';
import {startCase as _startCase} from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class BoncfDetailService extends GenericHandler<BonCfDetailDTO> {

  private subjectAvailableArticles = new BehaviorSubject(undefined);
  availableArticles$ = this.subjectAvailableArticles.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO(): BonCfDetailDTO {
    return undefined;
  }

  createEmptyDTOForPropositionCommande(ca: CatalogueAchatDTO, bonCf: BonCfDTO): BonCfDetailDTO {

    const bcfDetail = new BonCfDetailDTO();

    bcfDetail.idCatalogueAchat = ca.id;
    bcfDetail.lmPdUpCa=ca.lmPdUpCa;
    bcfDetail.description = ca.produitArticle.libelle;
    bcfDetail.idBonCf = bonCf.id;
    bcfDetail.minimumDeCommande = ca.minimumDeCommande;
    bcfDetail.ratioUC = ca.ratioUniteCommandeUniteBase;
    bcfDetail.ratioUF = ca.ratioUniteFacturationUniteBase;
    bcfDetail.ratioUT = ca.produitArticle.produitDeclinaison.ratioUniteTechnique;
    bcfDetail.referenceArticle = ca.referenceArticle;

    bcfDetail.uniteDeCommande = ca.uniteDeCommande;
    bcfDetail.uniteDeFacturation = ca.uniteDeFacturation;
    bcfDetail.uniteTechnique = ca.produitArticle.produitDeclinaison.uniteDeMesure;

    bcfDetail.quantiteACommander = 0;
    bcfDetail.quantiteACommanderAjustee = ca.quantite;

    bcfDetail.prixUnitaire = ca.prix;
    bcfDetail.quantiteAFacturer = this.utils.convertQuantiteUdm1ToQuantiteUdm2(ca.quantite, bcfDetail.ratioUC, bcfDetail.ratioUF);
    bcfDetail.prixTotal = bcfDetail.prixUnitaire *  bcfDetail.quantiteAFacturer;
    bcfDetail.quantiteADestocker = 0;
    bcfDetail.quantiteBesoin = 0;
    bcfDetail.tvaArticle = parseFloat(ca.produitArticle.produitDeclinaison.taxe.valeur);


    // TODO stocks
    bcfDetail.stock = null;

    return bcfDetail;
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEntityName(): string {
    return "bonCfDetail";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "ARTICLES À COMMMANDER";
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  announceAvailableArticles(bonCf: BonCfDTO) {
    this.subjectAvailableArticles.next(bonCf);
  }

  reComputeMontantHT(bonCfDetails: BonCfDetailDTO[]) {

    let montantHT = 0;

    if (!this.utils.isCollectionNullOrEmpty(bonCfDetails)) {
      bonCfDetails.forEach(item => montantHT += item.prixTotal);
    }

    return montantHT;
  }

  reComputePoids(lignes: BonCfDetailDTO[]) {
    let poids = 0;

    if (!this.utils.isCollectionNullOrEmpty(lignes)) {
      lignes.forEach(item => poids += item.ratioUC * item.quantiteACommanderAjustee);
    }

    return poids;

  }

  /**
   * Calculer le franco de port :
   * Si montant mini ou poids mini pas atteint, on applique le franco de port
   * Le poids mini se calcule par rapport à l'unité de commande et doit être convertit au KG
   * @param bonCf
   */
  computeFrancoDePort(bonCf: BonCfDTO, lignes: BonCfDetailDTO[]) {

     // console.log('calcul frais de port');

    let coutFrancoDePort = 0;

    if (
      !this.utils.isNullOrEmpty(bonCf)
      && !this.utils.isCollectionNullOrEmpty(lignes)
      && bonCf.fournisseurFdpActif
    ) {


      const montant = this.reComputeMontantHT(lignes);
      const poids = this.reComputePoids(lignes);
      let poidsInf = false;
      let montantInf=false;
      let montantActif = this.utils.isNumberGt0(bonCf.fournisseurFdpMontant);
      let poidsActif=this.utils.isNumberGt0(bonCf.fournisseurFdpPoids);


      // par montant
      if(montantActif){
        if (montant < bonCf.fournisseurFdpMontant){
          montantInf=true;
        }
      }

      // par poids
      if(poidsActif){
        if (poids < bonCf.fournisseurFdpPoids){
          poidsInf=true;
        }
      }

      // si le montant mini et le poids mini sont renseignés
      if(montantActif && poidsActif){

        // si le montant mini ou le poids mini ne sont pas atteint, on applique les frais de port
        if(poidsInf && montantInf){
          coutFrancoDePort = bonCf.fournisseurFdpCout;
        }

      }
      // si seulement montant renseigné
      else if(montantActif && montantInf){

          coutFrancoDePort = bonCf.fournisseurFdpCout;

      }
      // si seulement poids renseigné
      else if(poidsActif && poidsInf){

        coutFrancoDePort = bonCf.fournisseurFdpCout;
      }



    }



    return coutFrancoDePort;

  }

  computeTotalFacture(bonCf: BonCfDTO, lignes: BonCfDetailDTO[]) {
    const montantHT = this.reComputeMontantHT(lignes);
    const francoDePort = this.computeFrancoDePort(bonCf, lignes);

    return francoDePort + montantHT;
  }
}

export class AvailableArticlesSupplier {
  bonCf: BonCfDTO;
  articles: CatalogueAchatDTO[] = [];
}
