<p-dialog
  [(visible)]="displayDialog"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [modal]="true"
  position="top"
  [style]="{'min-width':'65wh'}"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Ajouter des conditionnements variante
    </div>
  </ng-template>


  <dx-data-grid
    [dataSource]="conditionnementVariantesList"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(250)"
    (onSelectionChanged)="onChangeCondiVarianteSelection($event)"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true">
    <dxi-column dataField="conditionnement.libelle" caption="Conditionnement" [width]="300"
                [filterOperations]="['contains']"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="variante.libelle" caption="Variantes" [width]="300"
                [filterOperations]="['contains']"
                [allowFiltering]="true">
    </dxi-column>
    <dxo-selection mode="multiple"
                   [selectAllMode]="true"
                   [showCheckBoxesMode]="'always'">
    </dxo-selection>
  </dx-data-grid>


  <div class="d-flex justify-content-end">
    <button type="button" pButton label="Ajouter" icon="fas fa-plus"
            (click)="addContionnementVariantesSelect()"
            [disabled]="condiVariantesSelectedList.length === 0">
    </button>

    <button type="button" pButton class="close-button p-button-secondary" icon="fas fa-times" (click)="closeDialog()">
    </button>
  </div>
</p-dialog>
