import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitArticleDTO} from '../../../core/dtos/produit-article-dto';
import {ActivatedRoute} from '@angular/router';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {Subscription} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {LazyLoadEvent} from 'primeng/api';
import {ModelViewMatierePremiere} from './model-view-matiere-premiere';
import {StockDTO} from '../../../core/dtos/stock-dto';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';

@Component({
  selector: 'yo-matieres-premieres',
  templateUrl: './matieres-premieres.component.html',
  styleUrls: ['./matieres-premieres.component.scss']
})
export class MatieresPremieresComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subResultSearchStocks: Subscription;
  subLoadStocks:Subscription;


  articles: ProduitArticleDTO[] = [];
  matieresPremieres: ModelViewMatierePremiere[] = [];
  selectedMatierePremiere: ModelViewMatierePremiere;
  totalDenrees = 0;
  stocks:StockDTO[]=[];

  // criteres de la recherche du panneau gauche
  ssw : SearchSupplierWrapper;

  cols: any[] = [
    {field: 'action', header: 'Actions'},
    {field: 'libelle', header: 'Denrée'},
    {field: 'code', header: 'Code'},
    {field: 'quantiteEnKilo', header: 'Qté en Kilo'},
    {field: 'pump', header: 'P.U.M.P'},
    {field: 'totalHT', header: 'Total HT'},
  ];


  constructor(public utils: UtilsService,
              private stocksSvc: StocksService,
              private produitDeclinaisonSvc:ProduitDeclinaisonService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subscritionRouteData();
    // s'abonner à la recherche des articles dans le stock
    this.subscritionResultSearchStocks();
    // declencher la recuperation des lignes de stock d'une matiere premiere
    this.subscritionLoadStocks();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchStocks);
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subLoadStocks);
  }

  subscritionResultSearchStocks = () => {
    this.subResultSearchStocks = this.produitDeclinaisonSvc.resultSearchStocksForMatieresPremieres$
      .subscribe(response => {
        this.stocks=[];
        this.matieresPremieres = response.resultList;
        this.totalDenrees = response.additionalProperties['totalDenrees'];
        this.ssw = new SearchSupplierWrapper();
        this.ssw.filtersMap = response.additionalProperties['filters'];

        // recharger les lignes de lot si une matiere premiere est selectionnée et toujours visible
        this.selectedMatierePremiere = this.utils.preSelectSingleList(this.matieresPremieres,this.selectedMatierePremiere);

        if(!this.utils.isNullOrEmpty(this.selectedMatierePremiere)){
          this.stocksSvc.announceLoadStocks(this.selectedMatierePremiere)
        }
      });
  };

  subscritionRouteData = () => {
    this.subRoute = this.route.url.subscribe(response => {
      const feature = response[0].path;
      this.stocksSvc.announceFeature(feature);

    });
  };

  subscritionLoadStocks = () => {
    this.subLoadStocks = this.stocksSvc.loadStocks$.pipe(
      switchMap(selectedMatierePremiere => {
        const filterUdpZdsIds = this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_IDS'];
        let idsUdpZds = [];
        if (!this.utils.isNullOrEmpty(filterUdpZdsIds) && !this.utils.isCollectionNullOrEmpty(filterUdpZdsIds.values)) {
          idsUdpZds = filterUdpZdsIds.values;
        }
        console.log('subLoadStock', this.ssw.filtersMap['UDP_ZONES_DE_STOCKAGE_IDS']);
        return this.produitDeclinaisonSvc.getStocks(selectedMatierePremiere, idsUdpZds);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(response => {
        this.stocks = response.resultList;
      });
  };

  lazyLoad = ($event: LazyLoadEvent) => {
    this.stocks=[];
    this.produitDeclinaisonSvc.lazyLoadStocks($event);
  };

  onRowSelect = ($event: any) => {
    this.stocks = [];
    if (!this.selectedMatierePremiere.noStock) {
      this.stocksSvc.announceLoadStocks(this.selectedMatierePremiere);
    }
  };

  openDialogPerimes = () => {
    // on ouvre le dialog que si le champ 'Périmé le' est renseigné et qu'il existe des stocks
    if(this.utils.isNullOrEmpty(this.ssw)
      || this.utils.isNullOrEmpty(this.ssw.filtersMap['PERIME_LE'] )
    ){
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN,`Renseignez le champ de recherche 'Périmé le'`,`Le champ de recherche 'Périmé le' doit être renseigné pour activer la suppression des périmés.`,8000);
    }else{
      this.produitDeclinaisonSvc.announceOpenDialogPerimes(this.ssw,this.selectedMatierePremiere);
    }
  };

  openDialogAjoutLot = (selectedMatierePremiere : ModelViewMatierePremiere) => {
    this.selectedMatierePremiere = selectedMatierePremiere;
    this.produitDeclinaisonSvc.announceOpenDialogAjoutLot(this.selectedMatierePremiere, null);
  };

  getNoUniteDeStockTooltip = (rowData:ModelViewMatierePremiere) => {
    if(!this.utils.isCollectionNullOrEmpty(rowData.articlesSansUniteDeStockage)){
      return `Articles sans ratio unité de stockage : <br>`+rowData.articlesSansUniteDeStockage.join(`,<br> `);
    }
    return '';
  };
}
