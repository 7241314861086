import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpParams} from "@angular/common/http";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../dtos/unite-de-production-modele-conditionnement-plat-dto";
import {IsDeletableObject} from "../../models/is-deletable-object";

export const URL_FIND_ALL = `dolrest/conditionnements/udp-mcp/list`;
export const URL_GET_ALL = `dolrest/conditionnements/udp-mcp/listByIdUdp/`;
export const URL_GET_ALL_BY_MCP_ID = `dolrest/conditionnements/udp-mcp/listByIdMcp/`;
export const URL_DELETE = `dolrest/conditionnements/udp-mcp/`;
export const URL_DELETE_BY_MCP_ID = `dolrest/conditionnements/udp-mcp/byMcpIp/`;
export const URL_DELETE_BY_MCP_ID_AND_UDP_IDS = `dolrest/conditionnements/udp-mcp/byMcpIpUdpIds/`;
export const URL_SAVE = `dolrest/conditionnements/udp-mcp/`;

@Injectable({
  providedIn: 'root'
})
export class GestionUniteProduction__ModeleConditionnementPlatService {

  private subjectOpenDialog = new Subject<Number>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectElementsSaved = new Subject<UniteDeProduction__ModeleConditionnementPlatDto[]>();
  elementsSaved$ = this.subjectElementsSaved.asObservable();

  private subjectSuppression = new Subject<number[]>();
  announceSuppression$ = this.subjectSuppression.asObservable();

  constructor(private httpSvc: HttpService) {}

  findAll(): Observable<ResponseWrapper<UniteDeProduction__ModeleConditionnementPlatDto>> {
    return this.httpSvc.get(`${URL_FIND_ALL}`, null);
  }

  /**
   * Récupère toutes les associations d'une unité de production
   * @param idUdp Identifiant de l'unité de production lié aux modèles de conditionnements plats
   * @returns {Observable<UniteDeProduction__ModeleConditionnementPlatDto>}
   */
  getAll(idUdp: number): Observable<ResponseWrapper<UniteDeProduction__ModeleConditionnementPlatDto>> {
    return this.httpSvc.get(`${URL_GET_ALL}${idUdp}`, null);
  }

  /**
   * Récupère toutes les associations d'une unité de production et d'un modèle conditionnement plat
   * @param idUdp Identifiant du modèle conditionnement plat lié aux unités de production
   * @returns {Observable<UniteDeProduction__ModeleConditionnementPlatDto>}
   */
  getAllByMcpId(mcpId: number): Observable<ResponseWrapper<UniteDeProduction__ModeleConditionnementPlatDto>> {
    return this.httpSvc.get(`${URL_GET_ALL_BY_MCP_ID}${mcpId}`, null);
  }

  /**
   * Sauvegarde une ou plusieurs association(s)
   * @param elementsList
   */
  save(liaisonsList: UniteDeProduction__ModeleConditionnementPlatDto[]) {
    return this.httpSvc.post(URL_SAVE, liaisonsList);
  }

  /**
   * Supprime une ou plusieurs association(s)
   * @param idsToDelete Identifiants des associations à supprimer
   */
  deleteByIds(idsToDelete: number[]): Observable<any> {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  deleteByMcpId(id: number): Observable<IsDeletableObject> {
    return this.httpSvc.delete(`${URL_DELETE_BY_MCP_ID}${id}`, null);
  }

  deleteByMcpIdAndUdpIds(id: number, idsUdpList: number[]): Observable<IsDeletableObject> {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsUdpList.join(','));
    return this.httpSvc.delete(`${URL_DELETE_BY_MCP_ID_AND_UDP_IDS}${id}`, params);
  }

  announceOpenDialogAjout(idUdp: number) {
    this.subjectOpenDialog.next(idUdp);
  }

  announceAssociationsSaved(elementsList: UniteDeProduction__ModeleConditionnementPlatDto[]) {
    this.subjectElementsSaved.next(elementsList);
  }

  announceSuppression(idsToDelete: number[]) {
    this.subjectSuppression.next(idsToDelete);
  }

}
