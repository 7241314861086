import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {combineLatest, Subscription} from 'rxjs';
import {FormGroup} from "@angular/forms";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {ProduitDeclinaisonService} from "../../../../core/services/entities/produit-declinaison.service";
import {GestionUniteProduction__DenreeBlackListBesoinService} from "../../../../core/services/gestion-unites-productions/gestion-unite-production__denree-black-list-besoin.service";
import {SearchSupplierWrapper} from "../../../../core/suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../../../core/suppliers/search-supplier";
import {ProduitDeclinaisonDTO} from "../../../../core/dtos/produit-declinaison-dto";
import {UniteDeProduction__DenreeBlackListBesoinDTO} from "../../../../core/dtos/unite-de-production__denree-black-list-besoin.-dto";
import {ActivatedRoute} from "@angular/router";
import {catchError, debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'yo-dialog-ajout-produits-declinaisons-non-approvisionnes',
  templateUrl: './dialog-ajout-up-produits-declinaisons-non-approvisionnes.html',
  styleUrls: ['./dialog-ajout-up-produits-declinaisons-non-approvisionnes.scss']
})
export class DialogAjoutUpProduitsDeclinaisonsNonApprovisionnes implements OnInit, OnDestroy {

  hasUniteDeProduction = false;

  displayDialog = false;

  subOpenDialog: Subscription;
  subAllDenrees: Subscription;
  subAllDenreesConfigurees: Subscription;

  idUdp: number;
  denreesBlackListeesList: UniteDeProduction__DenreeBlackListBesoinDTO[] = [];
  denreesToSave: ProduitDeclinaisonDTO[] = [];

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Ajouter une denrée à ne pas approvisionner';

  denrees: ProduitDeclinaisonDTO[] = [];
  denreesSelected: ProduitDeclinaisonDTO[] = [];

  subRoute: Subscription;
  subSuppression: Subscription;

  totalProduits = 0;
  loadingData = false;
  columns: any[] = [{field: 'libelle', header: 'Denrée'}];

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private pdSvc: ProduitDeclinaisonService,
              private udpBlbSvc: GestionUniteProduction__DenreeBlackListBesoinService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initUdpSubscription();
    this.initHasUniteDeProductionSubscription();
    this.initAllDenreesSubscription();
    this.initSubAllDenreesConfigureesSubscription();
    this.openDialogconditionnementVarianteSubscription();
    this.initSuppressionSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subAllDenrees);
    this.utils.unsubscribe(this.subAllDenreesConfigurees);
    this.utils.unsubscribe(this.subSuppression);
  }

  initSuppressionSubscription = (): void => {
    this.subSuppression = this.udpBlbSvc.announceSuppression$
      .subscribe(idsToDelete => {
        this.denreesSelected = this.denreesSelected.filter(pd => !idsToDelete.includes(pd.id));
        this.initSubAllDenreesConfigureesSubscription();
      });
  };

  initUdpSubscription = (): void => {
    const all$ = combineLatest([this.route.parent.data]);
    this.subRoute = all$.subscribe(data => {
      this.idUdp = data[0].uniteDeProductionSupplier.uniteDeProduction.id;
    });
  };

  initAllDenreesSubscription = (): void => {
    const ssWrapper = new SearchSupplierWrapper();
    ssWrapper.filtersMap['fabrique'] = new SearchSupplier('0');
    this.subAllDenrees = this.pdSvc.getProduitDeclinaisonByTypeFabrique(ssWrapper, '')
      .subscribe(response => this.denrees = response.resultList);
  };

  initSubAllDenreesConfigureesSubscription = (): void => {
    this.subAllDenreesConfigurees = this.udpBlbSvc.getAll(this.idUdp)
      .subscribe(response => this.denreesBlackListeesList = response.resultList);
  };

  openDialogconditionnementVarianteSubscription = (): void => {
    this.subOpenDialog = this.udpBlbSvc.openDialog$
      .subscribe((idUdp: number) => {
        this.idUdp = idUdp;
        this.displayDialog = true;
        this.loadData({ first: 0, rows: 50 });
      });
  };

  initHasUniteDeProductionSubscription = (): void => {
    this.auth2Svc.hasUniteDeProduction$.subscribe(response => this.hasUniteDeProduction = response);
  };

  canSave = (): boolean => this.hasUniteDeProduction;

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    this.denreesToSave = this.denreesSelected.filter(denree => this.denreesBlackListeesList.filter(denreeBlackListee => denreeBlackListee.idProduitDeclinaison === denree.id ).length > 0 ? false : true);

    const data: UniteDeProduction__DenreeBlackListBesoinDTO[] = this.denreesToSave.map(denree => {
      let u = { idUniteDeProduction: this.idUdp, idProduitDeclinaison: denree.id } as UniteDeProduction__DenreeBlackListBesoinDTO;
      return u;
    });

    this.udpBlbSvc.save(data)
      .subscribe(response => {
        this.initSubAllDenreesConfigureesSubscription();
        this.udpBlbSvc.announceDenreeSaved(response.resultList);
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Ajout de la denrée à ne pas approvisionner réalisée avec succès.');
        this.closeDialog();
    });
  };

  loadData = $event => {
    this.loadingData = true;

    let urlParams = `?`;

    if ($event.first === 0) {
      urlParams += `page=0`;
    } else {
      const page = $event.first / $event.rows;
      urlParams += `page=${page}`;
    }

    urlParams += `&size=${$event.rows}`;

    const ssWrapper = new SearchSupplierWrapper();
    ssWrapper.filtersMap['fabrique'] = new SearchSupplier('0');

    if ($event.filters) {
      const filterDenree = $event.filters['denree'];
      if (!this.utils.isNullOrEmpty(filterDenree)) {
        ssWrapper.filtersMap['denree'] = new SearchSupplier(filterDenree.value);
      }
    }

    const produits$ = this.pdSvc.getProduitDeclinaisonByTypeFabrique(ssWrapper, urlParams);

    produits$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {

      this.loadingData = false;

      // TODO : Denrées récupérées - Denrées déjà configurées :
      const idsProduitDeclinaisonsConfigureesList = this.denreesBlackListeesList.map(d => d.idProduitDeclinaison);

      this.denrees = response.resultList;
      this.denrees = this.denrees.filter(d => !idsProduitDeclinaisonsConfigureesList.includes(d.id));
      this.totalProduits = response.totalElements;
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Ajouter une denrée à ne pas approvisionner`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
