<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button
      pButton
      icon="fa fa-plus"
      label="CRÉER"
      pTooltip="Créer une implémentation d'un modèle de conditionnement plat"
      (click)="openEditObject(undefined)"
      [disabled]="!canEdit()">
    </button>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>
  </ng-template>
</p-toolbar>
<dx-data-grid
  [dataSource]="associationsList"
  keyExpr="udpMcpId"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="udpMcpId" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="udpLibelle" caption="Unité de production" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="mcpLibelle" caption="Modèle de conditionnement plat" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <div *dxTemplate="let cell of 'actionsCellTemplate'">

    <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="canEdit()?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="canEdit()?'Modifier le modèle':'Voir le modèle'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>

    <yo-cell-button (yoNavigation)="visualize(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fas fa-calculator'"
                    [pTooltip]="'Visualiser l\'implémentation du modèle'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>

  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} association(s)">
  </dxo-pager>

</dx-data-grid>
<yo-dialog-edit-implementation-modele-conditionnement-produit></yo-dialog-edit-implementation-modele-conditionnement-produit>
