<div class="card ">
  <div class="card-header">
    <label><i class="fa fa-wrench orangecolor space"></i>RÉGLAGES AVANCÉS DE L'AFFICHAGE DES MENUS</label>
    <span class="floatright">
            <!--sauvegarde-->
      <yo-button-save [yoType]="'submit'" [yoDisabled]="!form.valid"
                      (onClick)="savePreferencesUtilisateur()">
      </yo-button-save>
        </span>
  </div>
  <div class="scrollLayout">
    <form [formGroup]="form">
      <table class="table table-striped">
        <tbody>

        <!--JOURS À AFFICHER-->
        <tr>
          <td class="colWidth250" pTooltip="Choisir les jours de la semaine à  afficher"
              showDelay="500">
            <label class="space">Nombre de jours à afficher</label><span class="danger-color">*</span>
          </td>
          <td class="colWidth500">
            <p-inputNumber class="wh-90 text-right" formControlName="nbJoursCtrl"></p-inputNumber>
            <span class="mg-l-5 mg-r-5">Jour(s)</span>
            <yo-validation [yoMax]="90" [yoMin]="1"
                           [yoFormControl]="form.controls['nbJoursCtrl']"></yo-validation>

          </td>
        </tr>

        <!--JOURS DE LA SEMAINE-->
        <tr>
          <td class="colWidth250"
              pTooltip="Jours de la semaine qui seront visibles. Ils n'apparaissent que s'ils sont paramétrés dans les offres alimentaires sélectionnées."
              showDelay="500"
          >
            <label class="space">Jours de la semaine</label><span class="danger-color">*</span>
          </td>
          <td class="colWidth500">
            <p-listbox [options]="joursSemaineToDisplay"
                formControlName="joursSemaineCtrl"
                       [checkbox]="true"
                       [multiple]="true" optionLabel="viewValue" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}">
              <ng-template let-day pTemplate="item">
                  <div>{{day.viewValue}}</div>
              </ng-template>
            </p-listbox>
            <yo-validation [yoFormControl]="form.controls['joursSemaineCtrl']"></yo-validation>
          </td>
        </tr>

        <!--MODE DE RECHERCHE DES PLATS-->
        <tr>
          <td class="colWidth250" showDelay="500">
            <label>Mode de recherche des plats</label>
          </td>
          <td>
            <p-selectButton [options]="searchModes" formControlName="searchModeCtrl"></p-selectButton>
            <yo-validation [yoFormControl]="form.controls['searchModeCtrl']"></yo-validation>
          </td>
        </tr>


        <!--ALLERGENES-->
        <tr>
          <td class="colWidth250" pTooltip="" showDelay="500">
            <label>Afficher les allergènes</label>
          </td>
          <td>
            <p-checkbox binary="true" [formControl]="form.controls['displayAllergenesCtrl']">
            </p-checkbox>
          </td>
        </tr>

        <!--APPELLATIONS-->
        <tr>
          <td class="colWidth250" pTooltip="" showDelay="500">
            <label>Afficher les appellations</label>
          </td>
          <td>
            <p-checkbox binary="true" [formControl]="form.controls['displayAppellationsCtrl']">
            </p-checkbox>
          </td>
        </tr>

        <!--familles GEMRCN-->
        <tr>
          <td class="colWidth250" pTooltip="" showDelay="500">
            <label>Afficher les familles GEMRCN</label>
          </td>
          <td>
            <p-checkbox binary="true" [formControl]="form.controls['displayFamillesGemrcnCtrl']">
            </p-checkbox>
          </td>
        </tr>

        <!--taux de prise-->
        <tr>
          <td class="colWidth250" pTooltip="" showDelay="500">
            <div>
              Afficher les taux de prise
            </div>
          </td>
          <td>
            <p-checkbox binary="true" [formControl]="form.controls['displayTauxDePriseCtrl']">
            </p-checkbox>
          </td>
        </tr>

        <!-- Cout repas-->
        <tr>
          <td class="colWidth250" pTooltip="" showDelay="500">
            <div>
              Afficher les coûts unitaires
            </div>
          </td>
          <td>
            <p-checkbox binary="true" [formControl]="form.controls['displayCoutsUnitairesCtrl']">
            </p-checkbox>
          </td>
        </tr>

        <!-- Cout detaille repas-->
        <tr>
          <td class="colWidth250" pTooltip="" showDelay="500">
            <div>
              Afficher les coûts unitaires pondérés
            </div>
          </td>
          <td>
            <p-checkbox binary="true" [formControl]="form.controls['displayCoutsUnitairesPonderesCtrl']">
            </p-checkbox>
          </td>
        </tr>


        </tbody>
      </table>
    </form>
  </div>


</div>

