<p-dialog
  [dismissableMask]="false"
  [style]="{'min-width':'50vw', 'max-width':'50vw', 'min-height':'50vh', 'max-height':'50vh'}"
  [showHeader]="true"
  (onHide)="closeDialog()"
  positionTop="100"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  minY=400>


  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-print mg-r-5"></i> Calcul du coût de revient d'un plat
    </div>
  </ng-template>
  <div class="size-dialog">
    <p-steps [model]="items" [(activeIndex)]="activeIndex" (activeIndexChange)="changeStep($event)"
             [readonly]="selectedPlcs.length === 0"></p-steps>
    <div class="d-flex justify-content-start mg-t-30 mg-b-30">
      <div *ngIf="activeIndex === STEP_PRINT_COUT_REVIENT_PLAT.selectionPlc">

        <strong class="mg-b-5">Choisir les points de livraison client :</strong>
        <p-tree [value]="treePlc"
                class="mg-b-20"
                (selectionChange)="onChangeSelectionRepas($event)"
                [style]="{'width':'calc(45vw)','height':'auto','max-height':'calc(35vh)','overflow-y': 'scroll'}"
                selectionMode="checkbox" [(selection)]="selectedPlcs">
        </p-tree>
      </div>

      <div *ngIf="activeIndex === STEP_PRINT_COUT_REVIENT_PLAT.selectionEffectifDate">
        <div class="d-flex flex-column">
          <strong class="mg-b-5">Choisir l'effectif sur lequel sera fait le calcul :</strong>
          <p-radioButton name="effectif"
                         class="mg-b-5 mg-l-15"
                         [value]="TYPE_EFFECTIF.fabrication"
                         label="Effectif de fabrication"
                         [(ngModel)]="typeEffectif"
                         inputId="preopt2">
          </p-radioButton>
          <p-radioButton name="effectif"
                         class="mg-b-5 mg-l-15"
                         [value]="TYPE_EFFECTIF.facturation"
                         label="Effectif de facturation"
                         [(ngModel)]="typeEffectif"
                         inputId="preopt3">
          </p-radioButton>

          <strong class="mg-t-15 mg-b-5">Sélection du jour de consommation :</strong>
          <p-calendar
            firstDayOfWeek="1"
            class="mg-r-40"
            dateFormat="dd/mm/yy"
            selectionMode="single"
            [numberOfMonths]="2"
            [readonlyInput]="true"
            [(ngModel)]="dateProductionSelected"
            (onSelect)="changeDates($event)"
            (onMonthChange)="changeDates($event)"
            (onYearChange)="changeDates($event)"
            [locale]="localeFr"
            [showIcon]="true"
            [showWeek]="true">
            <ng-template pTemplate="date" let-date>
              <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                             [dateCreationMenu]="utils.isDateInList(date,dateCreationMenu)"></yo-date-dispo>
            </ng-template>
          </p-calendar>

          <strong class="mg-t-15 mg-b-5">Sélection de la période (sert pour calculer le prix de revient sur la
            période):</strong>
          <p-calendar [(ngModel)]="periodeSelected"
                      firstDayOfWeek="1"
                      [locale]="localeFr"
                      dateFormat="dd/mm/yy"
                      selectionMode="range"
                      [showWeek]="true"
                      [showIcon]="true"
                      [numberOfMonths]="2"
                      [readonlyInput]="true">

          </p-calendar>
        </div>
      </div>

    </div>
  </div>
  <ng-template pTemplate="footer">

     <span class="mg-r-5">
      <button
        *ngIf="displayPreviewBtn"
        pButton
        type="button"
        label="Précédent"
        class="p-button-primary mg-r-5"
        (click)="goBackToStep()"
        pTooltip="Vers sélection des repas"
        tooltipPosition="bottom"
        showDelay="800"
        icon="fas fa-angle-left">
      </button>
     </span>

    <span class="mg-r-5">
        <button
          *ngIf="displayNextBtn"
          [disabled]="this.selectedPlcs.length === 0"
          pButton
          iconPos="right"
          type="button"
          class="p-button-primary"
          label="Suivant"
          (click)="goToStep()"
          pTooltip="Vers sélection de la période"
          tooltipPosition="bottom"
          showDelay="800"
          icon="fas fa-angle-right">
        </button>
     </span>

    <span class="mg-r-5">
        <button *ngIf="displayPrintBtn"
                pButton
                type="button"
                icon="fas fa-print"
                (click)="printCouRevientPlat()"
                pTooltip="Imprimer le cout de revient du plat"
                label="Imprimer"
                showDelay="500">
        </button>
    </span>
    <span class="mg-r-5">
      <button type="button" class="p-button-secondary" pButton icon="fas fa-times" (click)="closeDialog()"
              class="p-button-secondary"
              pTooltip="Annuler"
              showDelay="500">
      </button>
    </span>
  </ng-template>


</p-dialog>
