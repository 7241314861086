import {Component, OnDestroy, OnInit} from "@angular/core";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {ProduitDTO} from "../../../../core/dtos/produit-dto";
import {Subscription} from "rxjs/index";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ActivatedRoute} from "@angular/router";
import {ProduitSupplier} from "../../../../core/services/entities/produits.service";
import {ImplementationModelesConditionnementsPlatsService} from "../../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {ModeleImplementationMcpDto} from "../../../../core/dtos/conditionnement/plat/modele-implementation-mcp-dto";
import {ConfirmationService} from "primeng/api";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {HELP_FOLDERS} from "../../../../core/constants";

@Component({
  selector: 'yo-implementation-modele-conditionnement-plat',
  templateUrl: './implementation-modele-conditionnement-plat.component.html',
  styleUrls: ['./implementation-modele-conditionnement-plat.component.scss']
})
export class ImplementationModeleConditionnementPlatComponent implements OnInit, OnDestroy {

  hasIDistri = false;
  subscriptionRoute: Subscription;
  subscriptionModelSaved: Subscription;

  produit: ProduitDTO;
  isFabrique: boolean;

  associationsList: ModeleImplementationMcpDto[] = [];

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-conditionnement-modele-conditionnement-plat';

  constructor(private gds: GenericDatagridService,
              public utils: UtilsService,
              private route: ActivatedRoute,
              private implMcpSvc: ImplementationModelesConditionnementsPlatsService,
              private confirmationSvc: ConfirmationService,
              private routeMapSvc: RoutemapService,
              private authSvc: Auth2Service) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionModelSaved);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.routeSubscription();
    this.modelSavedSubscription();
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { produitSupplier: ProduitSupplier }) => {
        this.produit = data.produitSupplier.produitDTO;
        this.isFabrique = data.produitSupplier.isFabrique;
        this.implMcpSvc.findAll(this.produit.id)
          .subscribe(result => this.associationsList = result.resultList);
      });
  };

  modelSavedSubscription = () => {
    this.subscriptionModelSaved = this.implMcpSvc.modelSaved$
      .subscribe(response => {
        if (!response.isUpdate) {
          this.associationsList.push(response.model);
        } else {
          const idx = this.associationsList.findIndex(m => m.udpMcpId === response.model.udpMcpId && m.produitId === response.model.produitId);
          if (idx > -1) {
            this.associationsList[idx] = response.model;
          }
        }
      });
  };

  initHasGestionIDistri = (): void => {
    this.authSvc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canEdit = (): boolean => this.produit && this.hasIDistri;

  openEditObject = (model: ModeleImplementationMcpDto): void => {
    this.implMcpSvc.announceOpenDialogEdit(model);
  };

  visualize = (model: ModeleImplementationMcpDto): void => {
    this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', this.produit.typeProduit.fabrique, this.produit.id, 'conditionnement-visualization', model.udpMcpId]);
  };

  deleteValues = () => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        this.implMcpSvc.delete(this.selectedRows, this.produit.id)
          .subscribe(o => {
            this.associationsList = this.associationsList.filter(o => !this.selectedRows.includes(o.udpMcpId));
          });
      }
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Implémentation du conditionnement d'un produit`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
