import {ObjectDTO} from "../object-dto";
import {TypeTachePmsDto} from "./type-tache-pms-dto";
import {TypeEquipementPmsDto} from "./type-equipement-pms-dto";
import {MotifNonConformiteDTO} from "../motif-non-conformite-dto";
import {TypeUtilisateurPmsDto} from "./type-utilisateur-pms-dto";

export class TachePmsDto extends ObjectDTO {
  libelle: string;
  icone: string;
  couleur: string;
  ordre: number;
  lienExterne: string;
  typeTache: TypeTachePmsDto;
  typesEquipement: TypeEquipementPmsDto[];
  motifsNonConformite: MotifNonConformiteDTO[];
  typesUtilisateur: TypeUtilisateurPmsDto[];
}
