import {ObjectDTO} from "./object-dto";

export class StockMouvementStatutDTO extends ObjectDTO {

  code: string;
  libelle: string;
  description: string;
  entree: boolean;
  sortie: boolean;
  modifiable: boolean;

}
