import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  GestionLotMarcheService,
  TAB_NAME_LM
} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {IncoherenceLotMarcheSupplier} from './incoherence-lot-marche-resolver.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {Subscription} from 'rxjs';
import {LotMarcheIncoherenceDTO} from '../../../../../core/dtos/lot-marche-incoherence-dto';
import {ResponseWrapper} from '../../../../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';

@Component({
  selector: 'yo-dialog-incoherence-lot-marche',
  templateUrl: './dialog-incoherence-lot-marche.component.html',
  styleUrls: ['./dialog-incoherence-lot-marche.component.scss']
})
export class DialogIncoherenceLotMarcheComponent implements OnInit, OnDestroy {

  lotMarche :LotMarcheDTO;
  incoherenceList: LotMarcheIncoherenceDTO[] = [];

  subscriptionRoute :Subscription;

  constructor(public utils: UtilsService,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              private route :ActivatedRoute){
  }

  ngOnInit(): void {
    this.subscriptionRoute = this.route.data.subscribe((data: { incoheranceLotMarcheSupplier: IncoherenceLotMarcheSupplier }) => {
      this.lotMarche = data.incoheranceLotMarcheSupplier.lotMarche;
      this.incoherenceList = data.incoheranceLotMarcheSupplier.incoherenceList;
      if( this.incoherenceList.length === 0){
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.INFO, 'Aucune incohérance détectée.', '', 8000);
      }
      this.gestionLotMarcheSvc.announceOpenDialogLotMarche(this.lotMarche, TAB_NAME_LM.incoherence);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  closeDialog = () => {
    this.gestionLotMarcheSvc.announceCloseDialogLotMarche(true);
  };

  resolveIncoherence = (cell: LotMarcheIncoherenceDTO) => {
    this.gestionLotMarcheSvc.resolveIncoherence(cell, this.lotMarche.id).subscribe( (response: ResponseWrapper<LotMarcheIncoherenceDTO>) => {
      this.incoherenceList = response.resultList;
    });
  };
}
