<dx-popup
    [showTitle]="true"
    title="Ajouter un/des article(s) du fournisseur {{bonCf?.fournisseurLibelle | uppercase}} à la proposition {{bonCf?.numeroBcf | uppercase}}"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    [fullScreen]="false"
    (onHiding)="closeDialog()"
    container=".dx-viewport"
    [style]="{'width':'82vw','height':utils.getWindowAvailableHeight(120)+'px'}"
    [(visible)]="displayDialog"
  >
  <div class="row mg-b-15">
    <div class="col-md-3">
      <dx-drop-down-box
        [(value)]="treeBoxValue"
        valueExpr="id"
        displayExpr="name"
        placeholder="Filtrer par famille de produit..."
        [showClearButton]="true"
        [dataSource]="treeDataSource"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view
            #treeViewProduitDeclinaison
            [dataSource]="familleProduitList"
            [height]="150"
            dataStructure="plain"
            keyExpr="id"
            parentIdExpr="parent.id"
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            [selectNodesRecursive]="true"
            displayExpr="libelle"
            [selectByClick]="true"
            (onItemSelectionChanged)="onChangeParentDenreeSelection($event)"
          >
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
    </div>
  </div>
  <dx-data-grid
      [dataSource]="dataSource"
      [remoteOperations]="true"
      [height]="580"
      keyExpr="id"
      width="100%"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="id" cellTemplate="templateSelection" caption="Sélection" [width]="200" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="referenceArticle" cellTemplate="templateReference" caption="Référence"></dxi-column>
      <dxi-column dataField="produitArticle.libelle" cellTemplate="templateArticle" caption="Article"></dxi-column>
      <dxi-column dataField="prixAchat" cellTemplate="templatePrix" caption="Prix d'achat" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="uniteTechnique" cellTemplate="templateUT" caption="UT" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="uniteDeCommande" cellTemplate="templateUC" caption="UC" [allowSorting]="false" [allowFiltering]="false"></dxi-column>

      <div *dxTemplate="let cell of 'templateSelection'">
        <td>
          <yo-cell-button (click)="addArticle(cell.row.data)"
                          [class.hidden]="cell.row.data.quantite && cell.row.data.quantite>=0"
                          [yoTooltipShowDelay]="1500"
                          [yoMaxWidth]="true"
                          [yoWidthPercent]="30"
                          [yoTextAlign]="'center'"
                          class="space"
                          [yoIconClass]="'fas fa-cart-plus'"
                          [pTooltip]="'Ajouter un article'"
                          tooltipPosition="right"
                          showDelay="500"
          ></yo-cell-button>
          <yo-cell-button (click)="removeArticle(cell.row.data)"
                          [class.hidden]="!cell.row.data.quantite || cell.row.data?.quantite <= 0"
                          [yoTooltipShowDelay]="1500"
                          [yoMaxWidth]="true"
                          [yoWidthPercent]="30"
                          [yoTextAlign]="'center'"
                          class="space"
                          [yoIconClass]="'fas fa-minus'"
                          [pTooltip]="'Enlever un article'"
                          tooltipPosition="right"
                          showDelay="500"
          ></yo-cell-button>
          <ng-container id="input" *ngIf="cell.row.data.quantite && cell.row.data.quantite > 0">
            <span style="padding: 0 5px;">
                          <input pInputText pKeyFilter="pnum"
                                 [style.width.px]="62"
                                 min="0"
                                 [(ngModel)]="cell.row.data.quantite">
                          <span>{{cell.row.data.uniteDeCommande.abreviation}}</span>
                        </span>
          </ng-container>
          <yo-cell-button (click)="addArticle(cell.row.data)"
                          [class.hidden]="!cell.row.data.quantite || cell.row.data.quantite<=0"
                          [yoTooltipShowDelay]="1500"
                          [yoMaxWidth]="true"
                          [yoWidthPercent]="30"
                          [yoTextAlign]="'center'"
                          class="space"
                          [yoIconClass]="'fas fa-plus'"
                          [pTooltip]="'Ajouter un article'"
                          tooltipPosition="right"
                          showDelay="500"
          ></yo-cell-button>
        </td>
      </div>
      <div *dxTemplate="let cell of 'templateReference'">
        {{ cell.row.data.referenceArticle | uppercase}}
      </div>
      <div *dxTemplate="let cell of 'templateArticle'">
        <div class="d-flex">
          <div class="width-25 size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
               [pTooltip]="'Hérité du founisseur : ' + cell.row.data.fournisseurOwnerLibelle">
                        <span class="fa-stack fa-2x">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <strong class="fa-stack-1x custom-letter">H</strong>
                        </span>
          </div>
          <div class="width-25" *ngIf="utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"></div>
          <div>
            {{cell.row.data.produitArticle.libelle | uppercase}}
          </div>
        </div>

        <yo-info-lotmarche-article [uniteDeProductionId]="bonCf?.uniteDeProductionId"
                                   [lotMarchePdLumpCa]="cell.row.data.lmPdUpCa"></yo-info-lotmarche-article>
      </div>
      <div *dxTemplate="let cell of 'templatePrix'">
        <ng-container
          *ngTemplateOutlet="tplGroupeAchatPrix;context:{catalogueAchat:cell.row.data}"></ng-container>
      </div>
      <div *dxTemplate="let cell of 'templateUT'">
        <div class="no-overflow"
             [pTooltip]="cell.row.data.produitArticle.produitDeclinaison.uniteDeMesure.libelle"
             showDelay="500">
          {{cell.row.data.produitArticle.produitDeclinaison.uniteDeMesure.libelle | uppercase}}
        </div>
      </div>
      <div *dxTemplate="let cell of 'templateUC'">
        <div class="no-overflow" [pTooltip]="cell.row.data.uniteDeCommande.libelle" showDelay="500">
          {{cell.row.data.uniteDeCommande.libelle | uppercase}}
        </div>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="single"></dxo-sorting>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} article(s)">
      </dxo-pager>

    </dx-data-grid>
  <!--FOOTER-->
  <div class="row mg-t-10">
    <div class="col-md-12 text-right">
      <span class="mg-r-5">
        {{selectedArticles.length}} article(s) sélectionné(s)
      </span>
      <span class="mg-r-5">
        <button type="button" pButton
                icon="fas fa-plus"
                (click)="addArticlesForPropositionCommande()"
                label="Ajouter"
                [disabled]="!canAddArticlesForPropositionCommande()"
        ></button>
      </span>
      <button type="button" pButton icon="fas fa-times"
              class="p-button-secondary"
              (click)="closeDialog()" label="Fermer"></button>
    </div>
  </div>

</dx-popup>

<ng-template #tplGroupeAchatPrix let-catalogueAchat="catalogueAchat">

  <ng-container [ngSwitch]="catalogueAchat.groupeAchatCaList.length">

    <!--    1 seul groupement d'achat pour l'article-->
    <ng-container *ngSwitchCase="1">
      <div class="d-flex">
        <div class="width-54">
        </div>
        <div>
          {{catalogueAchat.groupeAchatCaList[0].prixUF | currency:'EUR':'symbol':'1.4-4'}}
          / {{catalogueAchat.uniteDeFacturation.libelle | slice:0:5}}
        </div>
      </div>


    </ng-container>

    <!--    plusieurs groupements d'achats pour l'article-->
    <ng-container *ngSwitchDefault>
      <ng-container *ngFor="let gaca of catalogueAchat.groupeAchatCaList">


        <div class="d-flex">

          <!-- selectionner le prix-->
          <div class="width-40">
            <p-radioButton [name]="'groupname'+catalogueAchat.id" [value]="gaca.prixUF"
                           [(ngModel)]="catalogueAchat.prix"></p-radioButton>
          </div>

          <!-- afficher le prefere-->
          <div class="width-24">
            <i *ngIf="gaca.prefere" class="mg-r-5 fas fa-star cursor" pTooltip="Article préféré"></i>
          </div>

          <!-- prix en uf-->
          <div class="width-150">
            {{gaca.prixUF | currency:'EUR':'symbol':'1.4-4'}}
            / {{catalogueAchat.uniteDeFacturation.libelle | slice:0:5}}
          </div>

          <!-- groupement d'achat-->
          <div class="width-100 font-14 cursor" pTooltip="Groupement d'achat">
            ({{gaca.libelleGroupeAchat}})
          </div>
        </div>


      </ng-container>

    </ng-container>

  </ng-container>

</ng-template>

