<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Libellé <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="input-group-sm ">
          <input pInputText formControlName="libelle" />
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Image">Image</div>

      <div class="col-md-8">
        <input type="file" [style.max-width.px]="field.width"
               [id]="field.key"
               (change)="onFileChange($event)"
               accept="image/jpeg, image/png"
               [readonly]="field.readonly">
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4">
        <ng-container *ngIf="!utils.isNullOrEmpty(previewFile)">
          <div class="col-md-auto">
            <p class="font-12 genlabel">Prévisualisation avant enregistrement</p>
            <img id="previewImage" src="#" height="64">
          </div>
        </ng-container>
      </div>

      <div class="col-md-4">
        <ng-container *ngIf="!utils.isNullOrEmpty(previewFile)">
          <p class="font-12 genlabel">Image enregistrée</p>
        </ng-container>
        <yo-img-entity *ngIf="!equipement || !equipement.id"
                       [refresh]="1"
                       [height]="64"
                       format="thumb"
                       [displayNoImage]="true"
                       [entityName]="field.entityName"
                       [ordre]="1"></yo-img-entity>
        <yo-img-entity *ngIf="equipement && equipement.id"
                       [entityId]="equipement.id" [refresh]="1"
                       [height]="64"
                       [openable]="true"
                       format="thumb"
                       [entityName]="field.entityName"
                       [ordre]="1"></yo-img-entity>

              <!--n'afficher le bouton de suppression que pour les images qui existent réellement-->
              <!--<ng-container *ngIf="!docError">
                <i style="vertical-align: top;" class="fas fa-times mg-r-20 danger-color cursor"
                   (click)="deleteFile(field)" pTooltip="Enlever la {{field.label}}" showDelay="500"></i>
              </ng-container>-->
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Seuil">Seuil</div>
      <div class="col-md-8">
        <div *ngIf="forUpdate && equipement.seuil; else elseBlock">
          <select formControlName="seuil" [compareWith]="compare">
            <option *ngFor="let s of seuilsList" [ngValue]="s">
              {{ s.libelle }}
            </option>
          </select>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'seuil',label:'Seuil'}"></ng-container>
        </div>
        <ng-template #elseBlock>
          <select formControlName="seuil">
            <option [ngValue]="null" disabled>Choississez un seuil</option>
            <option *ngFor="let seuil of seuilsList" [ngValue]="seuil">
              {{ seuil.libelle }}
            </option>
          </select>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'seuil',label:'Seuil'}"></ng-container>
        </ng-template>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Type d'équipement">Type d'équipement</div>
      <div class="col-md-8">
        <div *ngIf="forUpdate && equipement.type; else elseBlock2">
          <select formControlName="type" [compareWith]="compare">
            <option *ngFor="let t of typesList" [ngValue]="t">
              {{ t.libelle }}
            </option>
          </select>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'type',label:'Type d\'équipement'}"></ng-container>
        </div>
        <ng-template #elseBlock2>
          <select formControlName="type">
            <option [ngValue]="null" disabled>Choississez un type d'équipement</option>
            <option *ngFor="let t of typesList" [ngValue]="t">
              {{ t.libelle }}
            </option>
          </select>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'type',label:'Type d\'équipement'}"></ng-container>
        </ng-template>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Enregistrer"
        [disabled]="!canModify()"
        [class.disable]="!canModify()">
      </button>
      <button
        class="mg-l-5 p-button-secondary"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
