

<div class="d-flex flex-column mg-t-10" [style.max-width.px]="utils.getMaxWidthBody()+50">

  <div  [style.height.px]="utils.getWindowAvailableHeight(214)" style="overflow: auto;">
    <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getMaxWidthBody()}">
    </yo-form>
  </div>

  <div>
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        N'oubliez pas d'enregistrer votre travail.
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-save" (click)="saveFicheIdentite()"
                [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500" class="ui-button-success"></button>
      </div>
      <div>
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="utils.sidenav=false"
                class="ui-button-secondary"></button>
      </div>
    </div>
  </div>
</div>

