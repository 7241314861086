import {ObjectDTO} from "./object-dto";
import {RegimeAlimentaireDTO} from "./regime-alimentaire-dto";

export class ConviveDTO  extends ObjectDTO{

  // convive
  code: string;
  libelle: string;
  actif:boolean;

  regimes: RegimeAlimentaireDTO[];

}
