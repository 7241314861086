import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {ConfirmationService} from "primeng/api";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {EquipementANettoyerPmsDto} from "../../../../core/dtos/pms/equipement-a-nettoyer-pms-dto";
import {
  EquipementANettoyerPmsSavedSupplier,
  EquipementANettoyerPmsService
} from "../../../../core/services/pms/equipement-a-nettoyer-pms.service";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";

@Component({
  selector: 'yo-pms-equipement-a-nettoyer-grille',
  templateUrl: './pms-equipement-a-nettoyer-grille.component.html',
  styleUrls: ['./pms-equipement-a-nettoyer-grille.component.scss']
})
export class PmsEquipementANettoyerGrilleComponent implements OnInit, OnDestroy {
  equipementsANettoyerList: EquipementANettoyerPmsDto[] = [];

  hasPms = false;

  subEquipementANettoyerSaved: Subscription;

  entityName: string;
  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-equipement-a-nettoyer';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private confirmationSvc: ConfirmationService,
              private equipementANettoyerPmsService: EquipementANettoyerPmsService) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initEquipementsANettoyerPms();
    this.equipementANettoyerSavedSubscription();
    this.initProperty();
  }

  initProperty = (): void => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.entityName = this.equipementANettoyerPmsService.getEntityName().toLowerCase();
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subEquipementANettoyerSaved);
  }

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initEquipementsANettoyerPms = (): void => {
    this.equipementANettoyerPmsService.getAll()
      .subscribe(response => {
        this.equipementsANettoyerList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  equipementANettoyerSavedSubscription = (): void => {
    this.subEquipementANettoyerSaved = this.equipementANettoyerPmsService.equipementNettoyerSaved$
      .subscribe((response: EquipementANettoyerPmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.equipementsANettoyerList.push(response.equipementNettoyer);
        }
      });
  };

  openEquipementANettoyer = (e: EquipementANettoyerPmsDto): void => {
    this.equipementANettoyerPmsService.announceOpenDialog(e);
  };

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        const idsToDelete: number[] = this.selectedRows
          .map((idSelected: number) => this.equipementsANettoyerList.find(e => e.id === idSelected))
          .map((equipement: EquipementANettoyerPmsDto) => equipement.id);
        this.equipementANettoyerPmsService.deleteByIds(idsToDelete)
          .subscribe(o => {
            this.equipementsANettoyerList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.equipementsANettoyerList)
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,  `La suppression a bien été prise en compte`);
          });
      }
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les équipements à nettoyer PMS (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
