<div class="d-flex" *ngIf="displayPlanning()">

  <div  style="width:30px; margin-top:40px;">

    <div class="sticker-repas" (click)="displayDialogCoupageSetting($event)" pTooltip="Visibilité des découpages repas"
         tooltipPosition="right" showDelay="500">
      <i class="fas fa-cog rotate-1-turn"></i>
    </div>
    <ng-container *ngFor="let repas of mp2Svc?.planningMenus?.repasList">
      <div class="sticker-repas" (click)="scrollToRepas('repas'+repas.id)"
           [class.repas-actif]="isRepasActif('repas'+repas.id)">
        {{repas.libelle.toUpperCase()}}
      </div>
    </ng-container>
  </div>

    <div id="paneHScroll" class="pane-hscroll" #paneHScroll (scroll)="scrollHPane($event)"
         [style.width.px]="getPlanningWidth(mp2Svc.planningMenus.tableauSize)">
      <table>
        <thead>
        <tr>
          <ng-container *ngFor="let date of mp2Svc.planningMenus.dateList; trackBy: trackByDate">
            <ng-container *ngFor="let cmc of mp2Svc.planningMenus.contratMenuConviveList; trackBy: trackByCmc">
              <th [style.width]="mp2Svc.planningMenus.tableauSize.thLevel1Width"
                  [style.height.px]="36"
                  [attr.colspan]="mp2Svc.planningMenus.contratMenuConviveList.length">

                {{utils.getFrenchDate_dddd_Do_MMMM_YYYY(date.date)}}
                <i class="fas fa-trash fa-sm float-right cursor delete-icon" (click)="initMenuDateAnShowMenuDate($event, cmc,date.date,mp2Svc.planningMenus.repasList)"
                   *ngIf="canDelete() && !date.nonModifiable"></i>
                <i (click)="annulerValidation(cmc,date.date)"
                   class="far fa-check-circle fa-lg float-right green-check-icon" *ngIf="date.nonModifiable"
                   pTooltip="Menus validés" tooltipPosition="top"></i>

                <p-menu #menuDate appendTo="body" [popup]="true" [model]="itemsMenuDate"></p-menu>
              </th>
            </ng-container>
          </ng-container>
        </tr>
        </thead>
      </table>

      <div class="pane-vscroll" #paneVScroll [style.width.px]="vPaneWidth"
           [style.height]="utils.scrollHeightContent(126)" id="paneVScroll">
        <table>
          <tbody>
          <ng-container *ngFor="let rowData of rows; index as rowIndex; ">

            <tr
              [attr.id]="rowGroups[rowData.idRepas].index === rowIndex?'repas'+rowData.idRepas:'rowIndex'+rowIndex"
              [class.highlight-border-top]="highlightTop(rowData,mp2Svc.getRepasActifHtmlId())"
              [class.highlight-border-bottom]="highlightBottom(rowData,mp2Svc.getRepasActifHtmlId())">

              <ng-container *ngFor="let date of mp2Svc.planningMenus.dateList;  index as i;trackBy: trackByDate;">

                <ng-container *ngFor="let cmc of mp2Svc.planningMenus.contratMenuConviveList;trackBy: trackByCmc; ">

                  <!--DECOUPAGE REPAS-->
                  <ng-container *ngIf="rowData.typeLigne===1">

                    <td style="vertical-align: top;" [style.width]="mp2Svc.planningMenus.tableauSize.tdWidth"
                        [class.non-modifiable]="date.nonModifiable"
                        [class.highlight-border-left]="highlightLeft(rowData,mp2Svc.getRepasActifHtmlId(),date.date)"
                        [class.highlight-border-right]="highlightRight(rowData,mp2Svc.getRepasActifHtmlId(),date.date)">

                      <yo-decoupage-repas2
                        [cellule]="getCell(cmc.id,rowData.idRepas,rowData.idDecoupageRepas,date.date)"></yo-decoupage-repas2>
                    </td>
                  </ng-container>

                  <!--COUTS REPAS-->
                  <ng-container *ngIf="rowData.typeLigne===TYPES_LIGNES.COUT">
                    <ng-container
                      *ngTemplateOutlet="tplCoutRepas;context:{rowData:rowData,coutRepas:mp2Svc.computeCoutUnitairePondereRepas(cmc,date.date,rowData.idRepas,mp2Svc.planningMenus.contrainteAlimentaire),coutTheoriqueRepas:mp2Svc.getCoutTheoriqueRepas(cmc,date.date,rowData.idRepas), repasExists:mp2Svc.isRepasExists(cmc,date.date,rowData.idRepas),date:date.date}"></ng-container>
                  </ng-container>


                  <!--          SEPARATEUR-->
                  <ng-container *ngIf="rowData.typeLigne===TYPES_LIGNES.SEPARATEUR ">
                    <td class="separator">

                    </td>
                  </ng-container>


                </ng-container>

              </ng-container>

            </tr>
          </ng-container>

          </tbody>
        </table>
      </div>
    </div>
  </div>


<!--TEMPLATE AFFICHAGE DES COUTS PERIODE ET ANALYSE-->
<ng-template #tplCoutRepasPeriode let-displayEconomat="displayEconomat" let-rowData="rowData"
             let-menuEffectifRepas="menuEffectifRepas">

  <!--AFFICHAGE  DES COUTS SI VUE ECONOMAT ACTIVÉE-->
  <ng-container *ngIf="displayEconomat">
    <div class="row mg-t-10">

      <div class="col-md-auto">

        <!--RAFRAICHIR LES COUTS PERIODE-->
        <!--<button pButton pTooltip="Rafraichir le calcul des coûts de la période" showDelay="500" type="button"-->
        <!--(click)="refreshCoutsPeriode()" icon="fas fa-refresh" class="cursor"></button>-->


        <yo-cout-periode [dateList]="mp2Svc.planningMenus.dateList"
                         [contratMenuConviveList]="mp2Svc.planningMenus.contratMenuConviveList"
                         [idRepas]="rowData.idRepas"
                         [libelleRepas]="rowData.libelleRepas"
        ></yo-cout-periode>

      </div>


    </div>

  </ng-container>

</ng-template>

<ng-template #tplShowRepasViewer>

</ng-template>


<!--template cout unitaire repas-->
<ng-template #tplCoutRepas let-coutRepas="coutRepas" let-coutTheoriqueRepas="coutTheoriqueRepas" let-rowData="rowData"
             let-repasExists="repasExists" let-date="date">
  <ng-container *ngIf="repasExists">

<!--    [class.highlight-border-left]="highlightLeft(rowData,mp2Svc.getRepasActifHtmlId(),date.date)"-->
<!--    [class.highlight-border-right]="highlightRight(rowData,mp2Svc.getRepasActifHtmlId(),date.date)"-->
    <td
      [class.bg-checked]="coutRepas<=coutTheoriqueRepas"
      [class.bg-edit-color]="coutRepas>coutTheoriqueRepas"
      [style.vertical-align]="'middle'"
      [style.height.px]="28"
      [style.padding.px]="4"
      [style.width]="mp2Svc.planningMenus.tableauSize.tdWidth">

      <yo-cout-repas [libelleRepas]="rowData.libelleRepas" [coutTheoriqueRepas]="coutTheoriqueRepas"
                     [coutRepas]="coutRepas"></yo-cout-repas>
    </td>
  </ng-container>
  <ng-container *ngIf="!repasExists">
    <td></td>
  </ng-container>


</ng-template>

<!--DIALOG RECHERCHE PLATS-->
<yo-dialog-recherche-plats></yo-dialog-recherche-plats>

<!--DIALOG MENU INFO-->
<yo-infos-menucompo></yo-infos-menucompo>

<!--CONFIG REGLAGE DECOUPAGE REPAS-->
<p-overlayPanel appendTo="body" #opConfigMenu [dismissable]="true" [showCloseIcon]="true">
  <strong>Visibilité menu : </strong>

  <p-tree [value]="decoupageRepasNode"
          (selectionChange)="onChangeSelectionDecoupageRepas($event)"
          [style]="{'width':'auto','height':'auto'}"
          selectionMode="checkbox" [selection]="selectedDecoupageRepasNode">
  </p-tree>

</p-overlayPanel>
