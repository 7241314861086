import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {UtilsService} from "../../utils/utils.service";
import {HttpParams} from "@angular/common/http";

export const URL_POST_COMPUTE_PRICE_MEAL_FROM_UDP = `dolrest/gestion-unites-de-production/compute-price-meal-from-udp`;

@Injectable({
  providedIn: 'root'
})
export class GestionUniteProductionService{

  constructor(private httpSvc: HttpService, private utils: UtilsService) {
  }

  /**
   * Lance la mise à jour des prix des plats pour une unité de production
   * @param idUdp
   */
  computePriceMealFromUdp(idUdp: number){

    const params = new HttpParams()
      .set('idUdp', idUdp + '');

    return this.httpSvc.post(URL_POST_COMPUTE_PRICE_MEAL_FROM_UDP, params);
  }

}
