import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {UtilsService} from "../../utils/utils.service";
import {TachePmsDto} from "../../dtos/pms/tache-pms-dto";
import {HttpParams} from "@angular/common/http";

export const URL_GET_ALL = 'dolrest/taches-pms/all';
export const URL_SAVE = 'dolrest/taches-pms/';
export const URL_DELETE = 'dolrest/taches-pms/';
export const URL_GET_ALL_ORDERS = 'dolrest/taches-pms/findAllOrders';
export const URL_GET_BY_PARAM = 'dolrest/taches-pms/find';

@Injectable({
  providedIn: 'root'
})
export class TachePmsService {
  private subjectOpenDialog = new Subject<TachePmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectTacheSaved = new Subject<TachePmsSavedSupplier>();
  tacheSaved$ = this.subjectTacheSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Récupère toutes les tâches
   * @return {Observable<TachePmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<TachePmsDto>> => this.httpSvc.get(URL_GET_ALL, null);


  /**
   * Récupère tous les ordres
   * @return {Observable<number>}
   */
  getAllOrders = () : Observable<ResponseWrapper<number>> => this.httpSvc.get(URL_GET_ALL_ORDERS, null);


  /**
   * Récupère une tâche selon un paramètre
   * @param id
   * @param ordre
   */
  find = (id: number, ordre: number) : Observable<ResponseWrapper<TachePmsDto>> => {
    const params: HttpParams = new HttpParams().set("ordre", ordre);
    params.set("id", id);
    return this.httpSvc.get(URL_GET_BY_PARAM, params);
  }

  /**
   * Sauvegarde une tâche pms
   * @param tache
   * @param order
   */
  save = (tache: TachePmsDto, order?: number) : Observable<ResponseWrapper<TachePmsDto>> => {
    const params: HttpParams = new HttpParams().set("order", order);
    return this.httpSvc.post(URL_SAVE, tache, params);
  }

  /**
   * Supprime un ou plusieurs tâche(s) pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: TachePmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (tache: TachePmsDto, isUpdate: boolean = false) : void => {
    this.subjectTacheSaved.next({ isUpdate, tache } as TachePmsSavedSupplier);
  }
}

export interface TachePmsSavedSupplier {
  isUpdate: boolean;
  tache: TachePmsDto;
}
