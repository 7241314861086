import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MAT_DISPLAY} from '../../../core/constants';


@Component({
  selector: 'yo-button-save',
  template: `
    
    <!--Affichage en mini-->
    <button pButton  *ngIf="yoMatDisplay === 'mini'"            
            [pTooltip]="yoMdTooltip"
            [showDelay]="yoMdTooltipShowDelay"            
            [type]="yoType"         
            icon="fas fa-save"
            (click)="sendClickEvent($event)"
            [disabled]="yoDisabled"></button>   
  

    <!--Affichage en  grand-->
    <button pButton  *ngIf="yoMatDisplay === 'normal'"           
            [pTooltip]="yoMdTooltip"
            [showDelay]="yoMdTooltipShowDelay"
            [type]="yoType"
            [label]="yoLabel | uppercase"
            (click)="sendClickEvent($event)"
            icon="fas fa-save"            
            [disabled]="yoDisabled"></button>
  
  `,
  styleUrls: ['./button-save.component.scss']
})
export class ButtonSaveComponent implements OnInit {

  @Input() yoLabel: string = 'Enregistrer';
  @Input() yoDisabled: boolean = false;
  @Input() yoMdTooltip: string = 'Enregistrer';
  @Input() yoType: string = 'button';
  @Input() yoMdTooltipShowDelay: number = 1500;
  @Input() yoMatDisplay : MAT_DISPLAY = 'mini';

  @Output() onClick = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  sendClickEvent(event){
    this.onClick.emit(event);
  }
}
