<p-toolbar styleClass="p-mb-4" >
  <div class="p-toolbar-group-left">

    <!--SELECTION CONTRAT-->
    <p-dropdown #dropDownContrat [options]="gms?.contrats"
                class="mg-l-15 mg-r-5"
                tooltipPosition="bottom"
                pTooltip="Offre alimentaire"
                showDelay="1500"
                appendTo="body"
                [style]="{'min-width':'200px','max-width':'650px' }"
                optionLabel="libelle"
                showClear="true"
                placeholder="Choisir une offre alimentaire..."
                (onChange)="changePlanning($event,chooserPlanning.CONTRAT)"
                [(ngModel)]="selectedContratMenu" filter="true">
      <!--template contrat sélectionné-->
      <ng-template let-item pTemplate="selectedItem" class="option-dropdown">

        <ng-container *ngIf="!utils.isNullOrEmpty(selectedContratMenu)">
          <label class="space no-overflow max-width-300 mg-b-0 font-style">
            <yo-icon-lock [yoIsLocked]="menusToolbarSvc.isLockedContratMenu(selectedContratMenu)"
                          yoTooltipNo="Vous n'avez pas les droits pour créer des menus sur cette offre alimentaire"
                          yoTooltipYes="Vous avez les droits pour créer des menus sur cette offre alimentaire"
            ></yo-icon-lock>

            {{selectedContratMenu.libelle | uppercase}}</label> <label
          class="font-12 floatright no-overflow max-width-300">
          <yo-site
            [siteId]="selectedContratMenu?.site?.id" [siteLibelle]="selectedContratMenu?.site?.libelle"></yo-site>
        </label>
        </ng-container>
      </ng-template>


      <!--template liste de contrats-->
      <ng-template let-contratMenu pTemplate="item">
        <div pTooltip="{{contratMenu.libelle | uppercase}} - {{contratMenu.site.libelle  | uppercase}}"
             showDelay="800"
             tooltipPosition="right"
             tooltipStyleClass="tooltip-style">
          <label class="no-overflow max-width-300 mg-b-0 font-style">
            <yo-icon-lock
              class="mg-r-5"
              [yoIsLocked]="!canModify(contratMenu)"
              yoTooltipNo="Vous n'avez pas les droits pour créer des menus sur cette offre alimentaire"
              yoTooltipYes="Vous avez les droits pour créer des menus sur cette offre alimentaire">
            </yo-icon-lock>
            {{contratMenu.libelle | uppercase}}
          </label>
          <span class="font-style floatright">
              <label class="no-overflow max-width-300">
                      <yo-site [siteId]="contratMenu.site.id"
                               [siteLibelle]="contratMenu.site.libelle"></yo-site>
              </label>
          </span>
        </div>
      </ng-template>
    </p-dropdown>

    <!--SELECTION PRESTATIONS-->
    <p-dropdown [options]="prestations"
                class="mg-r-5"
                tooltipPosition="bottom"
                pTooltip="Prestation"
                showDelay="1500"
                appendTo="body"
                [style]="{'min-width':'50px','max-width':'250px'}"
                optionLabel="libelle"
                showClear="true"
                placeholder="Choisir une prestation..."
                (onChange)="changePlanning($event,chooserPlanning.PRESTATION)"
                [(ngModel)]="selectedPrestation" filter="true">


      <ng-template let-item pTemplate="selectedItem">
        <span class="mg-r-5 font-style">{{item.libelle}}</span>

      </ng-template>
      <ng-template let-item pTemplate="item">
        <span class="mg-r-5 font-style">{{item.libelle}}</span>
      </ng-template>

    </p-dropdown>

    <!--SELECTION RÉGIME-->
    <p-dropdown [options]="regimes"
                class="mg-r-5"
                tooltipPosition="bottom"
                pTooltip="Régimes"
                showDelay="1500"
                appendTo="body"
                [style]="{'min-width':'50px','max-width':'250px'}"
                optionLabel="libelle"
                showClear="true"
                (onChange)="changePlanning($event,chooserPlanning.REGIME)"
                placeholder="Choisir un régime..."
                [(ngModel)]="selectedRegime" filter="true">
      <ng-template let-item pTemplate="selectedItem">

        <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="item.referent"
           pTooltip="Régime référent"
           showDelay="500"></i>
        <span class="font-style">{{item.libelle}}</span>
      </ng-template>
      <ng-template let-item pTemplate="item">

        <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="item.referent"
           pTooltip="Régime référent"
           showDelay="500"></i>
        <span class="font-style">{{item.libelle}}</span>
      </ng-template>
    </p-dropdown>

    <!--CALENDRIER-->
    <i class="fas fa-chevron-left cursor" (click)="goToPreviousDate()" pTooltip="Période précédente"
       tooltipPosition="bottom" showDelay="500"></i>
    <span style="margin-left:2px;margin-right:2px">
  <p-calendar
    dateFormat="dd/mm/yy"
    firstDayOfWeek="1"
    tooltipPosition="bottom"
    showDelay="500"

    [numberOfMonths]="2"
    [readonlyInput]="true"
    [(ngModel)]="selectedStartDateMenus"
    (onSelect)="yoStartDateMenusChange($event)"
    (onFocus)="getMonthMenusCompoDates($event)"
    (onMonthChange)="getMonthMenusCompoDates($event)"
    (onYearChange)="getMonthMenusCompoDates($event)"
    [disabledDays]="disabledDays"
    [minDate]="dateMin"
    [locale]="fr"
    [showIcon]="false"
    [showWeek]="true">
  <ng-template pTemplate="date" let-date>
    <!--TEMPLATE DU CALENDRIER POUR LE HIGHLIGHT DES DATES QUI ONT DES MENUS COMPOS-->
    <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                   [dateCreationMenu]="utils.isDateInList(date,datesCreationMenus)"></yo-date-dispo>
  </ng-template>
</p-calendar>
    </span>
    <i class="fas fa-chevron-right cursor mg-r-5" (click)="goToNextDate()" pTooltip="Période suivante"
       tooltipPosition="bottom" showDelay="500"></i>

    <!--DUPLICATION DES MENUS-->
    <button class="mg-r-5" pButton pTooltip="Dupliquer des menus" showDelay="500"
            tooltipPosition="bottom"
            (click)="openDuplicationDialog()"
            [disabled]="isDisabledBySelectionOrEnvironnement(selectedContratMenu?.site?.id)"
            icon="fas fa-copy">
    </button>

    <!--DIALOGUE D'IMPRESSION DES MENUS-->
    <!--    <button class="mg-r-5" pButton [disabled]="printButtonDisabled()" pTooltip="Impression du menu" showDelay="500" (click)="openPrintDialog()" icon="fas fa-print"></button>-->
    <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
    <button type="button"
            pButton
            icon="fas fa-print"
            class="mg-r-5"
            (click)="printMenu.toggle($event)"
            pTooltip="Impression"
            tooltipPosition="bottom"
            showDelay="500" [disabled]="isDisabledActionButton()">
    </button>

    <!--GEMRCN-->
    <p-menu appendTo="body" #analyseMenu [popup]="true" [model]="itemsAnalyseMenu"></p-menu>
    <button class="mg-r-5" pButton
            tooltipPosition="bottom"
            pTooltip="Analyses"
            showDelay="500"
            (click)="analyseMenu.toggle($event)"
            icon="fas fa-chart-line"
            [disabled]="isDisabledActionButton()">
    </button>

    <!--VALIDATION DES MENUS-->
    <button class="mg-r-5" pButton
            icon="far fa-calendar-check"
            tooltipPosition="bottom"
            pTooltip="Envoi des menus sur les PLC associés à cette prestation" showDelay="500"
            (click)="openValidationMenu()"
            [disabled]="isDisabledBySelectionOrEnvironnement(selectedContratMenu?.site?.id)">
    </button>

  </div>
  <div class="p-toolbar-group-right">
    <!--afficher/cacher les famnut, allergenes, appellations, effectifs... -->
    <ng-container *ngIf="prefsUser">
      <ng-container *ngTemplateOutlet="tplSettings; context:{}">
      </ng-container>
    </ng-container>
  </div>
</p-toolbar>

<yo-validation-menu></yo-validation-menu>

<!--TEMPLATE CASES À COCHER PARAMÉTRAGE-->
<ng-template #tplSettings>

  <button pButton (click)="menusToolbarSvc.openSettings()"
          class="mg-r-15"
          icon="fas fa-wrench"
          pTooltip="Réglages du planning"
          showDelay="500">
  </button>

</ng-template>


<!--DIALOG DE DUPLICATION DES MENUS-->
<yo-duplication></yo-duplication>

<!--DIALOG PRINT COUT MATIERE-->
<yo-print-cout-matiere
  [contratsMenuConvive]="selectedPrestation"
  [selectedStartDateMenus]="selectedStartDateMenus"
  [selectedRegime]="selectedRegime"
  [regimeList]="regimes"
  [repasList]="mp2Svc.planningMenus ? mp2Svc.planningMenus.repasList : undefined">
</yo-print-cout-matiere>

<!--DIALOG PRINT BILAN ALLERGENE-->
<yo-print-bilan-allergene
  [selectedStartDateMenus]="selectedStartDateMenus"
  [contratsMenuConvives]="[selectedPrestation]"
  [regimeList]="regimes">
</yo-print-bilan-allergene>

<yo-print-menu
  [contratsMenuConvives]="[selectedPrestation]"
  [regimeList]="regimes"
  [selectedRegime]="selectedRegime"
  [selectedStartDateMenus]="selectedStartDateMenus">
</yo-print-menu>


