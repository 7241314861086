import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {SecteursFournisseursService} from '../../../core/services/entities/secteurs-fournisseurs.service';
import {SecteurDTO} from '../../../core/dtos/secteur-dto';
import {ProduitRouteMapService} from '../../../core/services/gestionproduits/produit-routemap.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';

@Injectable()
export class UpLivraisonResolverService implements Resolve<UniteDeProductionSupplier> {

  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private produitRouteMap: ProduitRouteMapService,
              private secteursFournisseursSvc: SecteursFournisseursService,
              private gds: GenericDatagridService,
              private utils: UtilsService) {
  }

  /**
   * Charger les données necessaires àl'affichage de l'unite de production
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<UniteDeProductionSupplier>}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UniteDeProductionSupplier> | Promise<UniteDeProductionSupplier> | UniteDeProductionSupplier {

    const udps = new UniteDeProductionSupplier();
    const idUniteDeProduction: number = +route.paramMap.get('idUniteDeProduction');
    let obsUniteDeProduction$ = this.gds.getOne('uniteDeProduction', idUniteDeProduction);
    let obsSecteurList$ = this.gds.getAll('secteur', ['code,asc'], true);
    let obsLivraisonsOfUDP$ = this.secteursFournisseursSvc.findLivraisonsOfUniteDeProduction(idUniteDeProduction);

    // MODIFICATION, on recupere l'udp du back, on recupere les secteurs
    if (this.utils.isNumberGt0(idUniteDeProduction)) {
      const initAll$ = combineLatest(obsSecteurList$, obsUniteDeProduction$, obsLivraisonsOfUDP$);

      return initAll$.toPromise().then(items => {
        if (!this.utils.isResponseSupplierError(items[0]) && !this.utils.isResponseSupplierError(items[1])) {
          udps.secteurList = items[0].resultList;
          udps.uniteDeProduction = items[1].one;
          udps.livraisonsOfUDP = items[2].resultList;

          //si on a des secteurs associés à l'unité de prod, on recupere les fournisseurs associés.
          if(!this.utils.isCollectionNullOrEmpty(udps.uniteDeProduction.uniteDeProduction__secteurList)){

            let udpSecteurs : SecteurDTO[] = [];
            udpSecteurs = udps.uniteDeProduction.uniteDeProduction__secteurList.map(item=>{
              let secteur = new SecteurDTO();
              secteur.id=item.idSecteur;
              return secteur;
            });

            return this.secteursFournisseursSvc.findSecteursFournisseursBySecteurs(udpSecteurs)
              .toPromise()
              .then(response => {
                if(!this.utils.isResponseSupplierError(response)){
                  udps.secteurFournisseurList = response.resultList;
                }
                return udps;
              });
          }

          return udps;
        }
      });
    }

  }



}
