import {Component, OnDestroy, OnInit} from "@angular/core";
import {MenusPlanning2Service} from "../../../core/services/gestionmenus/menus-planning2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {MenusToolbarService} from "../../../core/services/gestionmenus/menus-toolbar.service";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {DATEPICKER_FR} from "../../../core/constants";
import {debounceTime, filter, switchMap} from "rxjs/operators";
import {DisplayEgalimResponse} from "../../../core/dtos/gestionmenus/planning/egalim/display-egalim-response-dto";

@Component({
  selector: 'yo-menus-egalim',
  templateUrl: './menus-egalim.component.html',
  styleUrls: ['./menus-egalim.component.scss']
})
export class MenusEgalimComponent implements OnInit, OnDestroy {

  subAnalyseEgalim: Subscription;
  subChangeValues: Subscription;

  selectedPeriode: Date[] = [new Date(), new Date()];
  effectif: number = 10;
  localeFr: any = DATEPICKER_FR;
  idContratMenuConvive: number;

  dataGraphic: DisplayEgalimResponse;

  subjectSelectedEffectif: BehaviorSubject<any> = new BehaviorSubject(undefined);
  selectedEffectif$: Observable<any> = this.subjectSelectedEffectif.asObservable();

  subjectSelectedPeriode: BehaviorSubject<any> = new BehaviorSubject(undefined);
  selectedPeriodes$: Observable<any> = this.subjectSelectedPeriode.asObservable();

  constructor(public mp2Svc: MenusPlanning2Service,
              public utils: UtilsService,
              public menuToolbarSvc: MenusToolbarService) {
  }

  ngOnInit(): void {
    this.idContratMenuConvive = this.mp2Svc.planningMenus.contratMenuConviveList && this.mp2Svc.planningMenus.contratMenuConviveList.length ? this.mp2Svc.planningMenus.contratMenuConviveList[0].id : -1;
    const dates = this.mp2Svc.planningMenus.dateList;
    this.selectedPeriode = [new Date(dates[0].date), new Date(dates[dates.length - 1].date)];
    this.findAnalyseEgalimSubscription();
    this.changeValuesSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAnalyseEgalim);
    this.utils.unsubscribe(this.subChangeValues);
  }

  findAnalyseEgalimSubscription = () => {
    this.subAnalyseEgalim = this.mp2Svc.findAnalyseEgalim(this.idContratMenuConvive, this.effectif, this.selectedPeriode[0], this.selectedPeriode[1])
      .subscribe(response => {
        this.dataGraphic = response.one;
      });
  }

  changeValuesSubscription: () => void = () => {
    const all$ = combineLatest([this.selectedEffectif$, this.selectedPeriodes$]);
    this.subChangeValues = all$.pipe(
      debounceTime(500),
      filter(data => this.effectif && this.selectedPeriode && this.selectedPeriode.length > 0),
      switchMap(response => this.mp2Svc.findAnalyseEgalim(this.idContratMenuConvive, this.effectif, this.selectedPeriode[0], this.selectedPeriode[1]))
    ).subscribe(response => {
      this.dataGraphic = response.one;
    });
  }

  canDisplay: () => (boolean) = () =>
    !this.utils.isNullOrEmpty(this.mp2Svc.planningMenus) && !this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.contratMenuConviveList);

  changeEffectif = (): void => {
    if (this.effectif)
      this.subjectSelectedEffectif.next(this.effectif);
  };

  changeSelectedPeriode = (): void => {
    if (this.selectedPeriode[0] && this.selectedPeriode[1])
      this.subjectSelectedPeriode.next(this.selectedPeriode);
  };

  getText = (item: any, value: string) => (item.index === 0) ? `Pourcentage de produits durables - ${value} % (${this.dataGraphic.totalMontantProduitsDurablesPeriod} €)` : `Pourcentage de produits bio ${value} % (${this.dataGraphic.totalMontantProduitsBioPeriod} €)`;

  customizeTooltip = (arg): any => ({
    text: this.getText(arg, arg.valueText),
  });

  customizeText = (arg): any => this.getText(arg.item, arg.text);

  customizeTextLabel: (arg) => string = arg => `${arg.valueText} %`;

}
