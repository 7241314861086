import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from '../core/core.module';
import {GestionProductionRoutingModule} from './gestion-production-routing.module';
import {GestionProductionRootComponent} from './gestion-production-root/gestion-production-root.component';
import {PpComponent} from './pp/pp.component';
import {SharedModule} from '../shared/shared.module';
import {PlansDeProductionComponent} from './pp/plans-de-production/plans-de-production.component';
import {DetailsComponent} from './pp/plans-de-production/details/details.component';
import {ErreursComponent} from './pp/plans-de-production/details/erreurs/erreurs.component';
import {PrintSettingsComponent} from './pp/plans-de-production/print-settings/print-settings.component';
import {GestionProcessusModule} from '../gestion-processus/gestion-processus.module';
import {InitProcessusComponent} from '../gestion-processus/init-processus/init-processus.component';
import {ValidationStocksComponent} from './pp/plans-de-production/validation-stocks/validation-stocks.component';
import {GridDetailsPlanProductionComponent} from './pp/plans-de-production/grid-details-pp/grid-details-pp.component';
import {BulkEditionDetailsDialogComponent} from './pp/plans-de-production/grid-details-pp/bulk-edition-details-dialog/bulk-edition-details-dialog.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {SortiesProductionComponent} from './pp/sorties-production/sorties-production.component';
import {DxCheckBoxModule, DxDataGridModule, DxDropDownBoxModule, DxTagBoxModule} from 'devextreme-angular';
import {TooltipModule} from 'primeng/tooltip';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {ToolbarModule} from 'primeng/toolbar';
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {OverlayPanelModule} from "primeng/overlaypanel";


@NgModule({
    imports: [
        CommonModule,
        GestionProductionRoutingModule,
        SharedModule,
        GestionProcessusModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,


        TooltipModule,
        CardModule,
        TableModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        ToastModule,
        ButtonModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        SelectButtonModule,
        MultiSelectModule,

        DxCheckBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        ToolbarModule,
        ConfirmPopupModule,
        OverlayPanelModule,
        DxTagBoxModule
    ],
  entryComponents:[
    InitProcessusComponent
  ],
  declarations: [
    GestionProductionRootComponent,
    PpComponent,
    PlansDeProductionComponent,
    DetailsComponent,
    ErreursComponent,
    PrintSettingsComponent,
    ValidationStocksComponent,
    GridDetailsPlanProductionComponent,
    SortiesProductionComponent,
    BulkEditionDetailsDialogComponent
  ]
})
export class GestionProductionModule { }
