<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div>
      <i class="fas fa-cubes mg-r-5 mg-t-10 mg-l-15"></i> MOUVEMENTS
    </div>
  </ng-template>
  <ng-template pTemplate="right">
    <button pButton icon="fas fa-print"
            class="mg-r-15"
            (click)="printMouvement.toggle($event)"
            showDelay="1000"
            pTooltip="Extraction des mouvements">
    </button>
    <p-menu appendTo="body" #printMouvement [popup]="true" [model]="itemsPrintMouvement"></p-menu>
  </ng-template>
</p-toolbar>

<div [style.width.px]="utils.getScreenAvailableWidth(DELTA_WIDTH_MOUVEMENTS)">
  <p-table
    [value]="mouvements"
    [columns]="cols"
    [responsive]="true"
    [scrollable]="true"
    [rows]="50"
    [rowsPerPageOptions]="[5,10,15,20,50,100,200,500]"
    [paginator]="true"
    [scrollHeight]="utils.scrollHeightContent(305)"
    styleClass="p-datatable-sm"
    [totalRecords]="totalMouvements"
    (onLazyLoad)="lazyLoad($event)"
    [lazy]="true"
    dataKey="id"
    #dt>

    <!--COLGROUP-->
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
          <col *ngSwitchCase="'action'" style="width:80px">
          <col *ngSwitchCase="'entreeSortie'" >
          <col *ngSwitchCase="'stockMouvementStatutCode'" >
          <col *ngSwitchCase="'lotArticleFournisseur'" >
          <col *ngSwitchCase="'dateMouvement'" >
          <col *ngSwitchCase="'zdsLibelle'" >
          <col *ngSwitchCase="'denominationArticle'" >
          <col *ngSwitchCase="'fournisseurLibelle'" >
          <col *ngSwitchCase="'quantite'" >
          <col *ngSwitchCase="'uniteDeStockage'" >
          <col *ngSwitchCase="'prix'" >
          <col *ngSwitchCase="'tva'" >
          <col *ngSwitchCase="'prixTotalHT'" >
          <col *ngSwitchCase="'prixTotalTTC'" >
        </ng-container>
      </colgroup>
    </ng-template>

    <!--HEADER-->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'action'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'entreeSortie'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'stockMouvementStatutCode'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'lotArticleFournisseur'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'dateMouvement'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'zdsLibelle'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'denominationArticle'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'fournisseurLibelle'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'quantite'">
            <th pTooltip="quantité en unité de stockage" showDelay="500">
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'uniteDeStockage'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'prix'">
            <th pTooltip="Prix unitaire en unité de facturation" showDelay="500">
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'tva'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'prixTotalHT'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'prixTotalTTC'">
            <th>
              {{col.header}}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>

    <!--BODY-->
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
      <tr>
        <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
          <td *ngSwitchCase="'action'" style="text-align: left">
            <yo-cell-button (click)="openModification(rowData)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="30"
                            [yoTextAlign]="'center'"
                            [yoIconClass]="'fa fa-edit'"
                            [pTooltip]="'modifier le mouvement'"
                            [yoDisabled]="!auth2Svc.isSiteLocal(rowData.siteUniteDeProductionId)"
                            tooltipPosition="right"
                            showDelay="500"
            ></yo-cell-button>
          </td>
          <td *ngSwitchCase="'entreeSortie'" style="text-align: left">
            <ng-container *ngTemplateOutlet="tplEntreeSortie;context:{rowData:rowData}"></ng-container>
          </td>
          <td *ngSwitchCase="'stockMouvementStatutCode'" style="text-align: left">
                    <span [pTooltip]="rowData.stockMouvementStatutLibelle"
                          showDelay="500">{{rowData.stockMouvementStatutLibelle}}</span>
          </td>
          <td *ngSwitchCase="'lotArticleFournisseur'" style="text-align: left">
            {{rowData.lotArticleFournisseur}}
          </td>
          <td *ngSwitchCase="'dateMouvement'" style="text-align: left">
            {{rowData.dateMouvement | date:'dd/MM/yyyy'}}
          </td>
          <td *ngSwitchCase="'zdsLibelle'" style="text-align: left">
            <div>
              <i class="fas fa-industry mg-r-5"></i>{{rowData.udpLibelle}}
            </div>
            <div class="mg-l-10 mg-t-5">
              <i class="fas fa-cubes mg-r-5"></i>{{rowData.zdsLibelle}}
            </div>
          </td>
          <td *ngSwitchCase="'denominationArticle'" style="text-align: left">
            {{rowData.denominationArticle}}
            <span class="mg-r-5" *ngIf="rowData.ratioUS === 0"><i
              class="fas fa-exclamation-triangle danger-color space"
              pTooltip="Le ratio d'unité de stockage de cet article est égal à 0"></i>
                </span>
          </td>
          <td *ngSwitchCase="'fournisseurLibelle'" style="text-align: left">
            {{rowData.fournisseurLibelle}}
          </td>
          <td *ngSwitchCase="'quantite'" style="text-align: right">
                    <span
                      pTooltip="Quantité en unité de facturation : {{rowData.quantiteEnUF}} {{rowData.uniteDeFacturation}} "
                      showDelay="500">{{rowData.quantite | number:'1.4-4'}}   {{rowData.uniteDeStockage}}</span>
          </td>
          <td *ngSwitchCase="'uniteDeStockage'" style="text-align: left">
            {{rowData.uniteDeStockage}}
          </td>
          <td *ngSwitchCase="'prix'" style="text-align: right">
            {{rowData.prixUnitaire  | currency:'EUR':'symbol':'1.4-4' }} / {{rowData.uniteDeFacturation}}
          </td>
          <td *ngSwitchCase="'tva'" style="text-align: right">
            {{rowData.tva  | number:'1.2-2' }}%
          </td>
          <td *ngSwitchCase="'prixTotalHT'" style="text-align: right;">
            {{rowData.prixTotalHT  | currency:'EUR':'symbol':'1.4-4' }}
          </td>
          <td *ngSwitchCase="'prixTotalTTC'" style="text-align: right;">
            {{rowData.prixTotalTTC | currency:'EUR':'symbol':'1.4-4' }}
          </td>
        </ng-container>
      </tr>
    </ng-template>

    <!--SUMMARY-->
    <ng-template pTemplate="summary">
      <tr>
        <td style="text-align:left;">
          {{utils.getTotalRecordsLabel('mouvement', totalMouvements)}}
      </tr>
    </ng-template>

  </p-table>
</div>

<div class="row mg-t-20" [style.width.px]="utils.getScreenAvailableWidth(DELTA_WIDTH_MOUVEMENTS)">
  <div class="col-md-2">
  </div>
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-2 text-right">
        <span class="bold genlabel">Entrées</span>
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5 new-color">  {{balanceEntreesHT | currency:'EUR':'symbol':'1.4-4' }}</strong>HT
      </div>
      <div class="col-md-2 text-right">
        <span class="bold genlabel">Sorties</span>
      </div>
      <div class="col-md-2">
        <strong
          class="mg-r-5 danger-color">  {{balanceSortiesHT | currency:'EUR':'symbol':'1.4-4' }}</strong>HT
      </div>
      <div class="col-md-2 text-right">
        <span class="bold genlabel">Balance</span>
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5">  {{balanceHT | currency:'EUR':'symbol':'1.4-4' }}</strong>HT
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5 new-color">  {{balanceEntreesTTC | currency:'EUR':'symbol':'1.4-4' }}</strong>TTC
      </div>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <strong
          class="mg-r-5 danger-color">  {{balanceSortiesTTC | currency:'EUR':'symbol':'1.4-4' }}</strong>TTC
      </div>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5">  {{balanceTTC | currency:'EUR':'symbol':'1.4-4' }}</strong>TTC
      </div>
    </div>
  </div>
</div>

<!--TEMPLATE ENTREE / SORTIE-->
<ng-template #tplEntreeSortie let-rowData="rowData">
  <ng-container *ngIf="rowData.entreeSortie">
    <i class="fas fa-arrow-right bold mg-l-5 mg-r-5 new-color" pTooltip="Entrée de stock"
       showDelay="500"></i>
  </ng-container>
  <ng-container *ngIf="!rowData.entreeSortie">
    <i class="fas fa-arrow-left bold  mg-l-5 mg-r-5 danger-color" pTooltip="Sortie de stock"
       showDelay="500"></i>
  </ng-container>
</ng-template>

<yo-dialog-modification-mouvement></yo-dialog-modification-mouvement>
