<!--statut du bon de réception-->
<label *ngIf="bonReception"
  [pTooltip]="bonReception.receptionStatut.libelle"
  showDelay="500"
  class="badge mg-r-5 cursor"
  [class.badge-primary]="isStatut(receptionStatutCode.NOUVEAU)"
  [class.badge-warning]="isStatut(receptionStatutCode.EN_COURS)"
  [class.badge-danger]="isStatut(receptionStatutCode.RECEPTION_PARTIELLE)"
  [class.badge-success]="isStatut(receptionStatutCode.TERMINE)"
>
  {{getLabel(bonReception.receptionStatut.libelle)}}
</label>

<!--statut du bon de réception ligne-->
<label *ngIf="bonReceptionLigne"
       [pTooltip]="bonReceptionLigne.receptionStatutCode"
       showDelay="500"
       class="badge mg-r-5 cursor"
       [class.badge-primary]="isStatutLigne(receptionStatutCode.NOUVEAU)"
       [class.badge-warning]="isStatutLigne(receptionStatutCode.EN_COURS)"
       [class.badge-danger]="isStatutLigne(receptionStatutCode.RECEPTION_PARTIELLE)"
       [class.badge-success]="isStatutLigne(receptionStatutCode.TERMINE)"
>
  {{getLabel(bonReceptionLigne.receptionStatutCode)}}

</label>
