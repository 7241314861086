import {Component} from '@angular/core';
import {AllergenesService} from '../../core/services/entities/allergenes-service.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-allergenes',
  templateUrl: './allergenes.component.html',
  styleUrls: ['./allergenes.component.scss']
})
export class AllergenesComponent {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/allergenes';

  constructor(public allergenesSvc: AllergenesService,
              public gds: GenericDatagridService) {
  }

}
