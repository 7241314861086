import {Component, Input} from '@angular/core';

@Component({
  selector: 'yo-badge-notification',
  templateUrl: './badge-notification.component.html',
  styleUrls: ['./badge-notification.component.scss']
})
export class BadgeNotificationComponent {

@Input() itemList : any[] = [];

}
