import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {UnitePmsDto} from "../../../../core/dtos/pms/unite-pms-dto";
import {UnitePmsService} from "../../../../core/services/pms/unite-pms.service";
import {SeuilPmsDto} from "../../../../core/dtos/pms/seuil-pms-dto";
import {SeuilPmsService} from "../../../../core/services/pms/seuil-pms.service";
import {ImagePmsDto} from "../../../../core/dtos/pms/image-pms-dto";
import json from "../../../../../assets/logo/logos.json";

@Component({
  selector: 'yo-pms-seuil-dialog',
  templateUrl: './pms-seuil-dialog.component.html',
  styleUrls: ['./pms-seuil-dialog.component.scss']
})
export class PmsSeuilDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  seuil: SeuilPmsDto;
  unitesList: UnitePmsDto[];
  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'un seuil';

  /**
   * Déclaration du chemin de l'icône à afficher
   */
  iconPath: ImagePmsDto;

  /**
   * Déclaratione et implémentation de nos données stockées sous format JSON
   */
  jsonFile: any = json;

  /**
   * Liste des icones
   */
  imagesPath: ImagePmsDto[] = [];

  /**
   * Couleur sélectionné
   */
  couleurSelected: string;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private seuilPmsSvc: SeuilPmsService,
              private unitePmsSvc: UnitePmsService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initIconPath();
    this.initUnitesPms();
    this.initHasPms();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  initIconPath = (): void => {
    for(const icon of this.jsonFile.icons) {
      this.imagesPath.push({
        path: `${this.utils.getIconPath(icon + '.png')}`,
        value: icon + '.png'
      });
    }
  };

  initUnitesPms = (): void => {
    this.unitePmsSvc.getAll()
      .subscribe(response => {
        this.unitesList = response.resultList;
      });
  };

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.seuilPmsSvc.openDialog$
      .subscribe((s: SeuilPmsDto) => {
        this.displayDialog = true;
        if (!s) {
          this.forUpdate = false;
          this.seuil = new SeuilPmsDto();
          this.seuil.id = null;
          this.dialogTitle = 'Création d\'un seuil';
        } else {
          this.forUpdate = true;
          this.iconPath = new ImagePmsDto();
          this.iconPath.value = s.icone;
          this.iconPath.path = `${window.location.origin}/assets/logo/${s.icone.split(".")[0]}.png`
          this.seuil = s;
          this.dialogTitle = 'Modification d\'un seuil';
        }
        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  compareUnites = (data1: any, data2: any): boolean => {
    if (data1 && data2) {
      return data1.id === data2.id;
    }
    return null;
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.iconPath = null;
  };

  canModify = (): boolean => this.seuil && this.hasPms;

  save = (): void => {
    if (this.form.valid) {
      this.seuil.libelle = this.form.controls['libelle'].value;
      this.seuil.min = this.form.controls['min'].value;
      this.seuil.max = this.form.controls['max'].value;
      this.seuil.couleur = this.form.controls['couleur'].value;
      this.seuil.icone = this.iconPath.value;
      this.seuil.description = this.form.controls['description'].value;
      this.seuil.unite = this.form.controls['unite'].value;

      this.seuilPmsSvc.save(this.seuil)
        .subscribe(response => {
          this.seuilPmsSvc.announceSaved(response.one, this.seuil.id !== undefined && this.seuil.id !== null && this.seuil.id !== 0);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde du seuil pms réalisée avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.seuil ? this.seuil.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      min: new FormControl(this.seuil ? this.seuil.min : ''),
      max: new FormControl(this.seuil ? this.seuil.max : ''),
      couleur: new FormControl(this.seuil ? this.seuil.couleur : '', [Validators.required]),
      icone: new FormControl(this.seuil ? this.seuil.icone : '', []),
      description: new FormControl(this.seuil ? this.seuil.description : '', [Validators.required]),
      unite: new FormControl(this.seuil ? this.seuil.unite : null, [Validators.required]),
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Seuils (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
