import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {ProduitDeclinaisonService} from "../../../../../core/services/entities/produit-declinaison.service";
import {ProduitDeclinaisonDTO} from "../../../../../core/dtos/produit-declinaison-dto";
import {Subscription} from "rxjs/index";
import {UnitedemesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {UnitesDeMesuresService} from "../../../../../core/services/entities/unites-de-mesures.service";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";

@Component({
  selector: 'yo-dialog-change-unite-technique',
  templateUrl: './dialog-change-unite-technique.component.html',
  styleUrls: ['./dialog-change-unite-technique.component.scss']
})
export class DialogChangeUniteTechniqueComponent implements OnInit, OnDestroy {

  displayDialog: boolean = false;
  denree: ProduitDeclinaisonDTO;
  pourcentageSubstitution: number = 100;

  uniteTechniqueSubstitution: UnitedemesureDTO;

  subInitDialog: Subscription;

  uniteDeMesureList: UnitedemesureDTO[] = [];
  qteOldUnitedeMesure: number;

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-declinaison-changement-unite-technique';

  constructor(public utils: UtilsService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private uniteDeMesureSvc: UnitesDeMesuresService) {
  }

  ngOnInit() {
    this.subcriptionInitDialog();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subInitDialog);
  }

  subcriptionInitDialog = () => {
    this.subInitDialog = this.produitDeclinaisonSvc.openDialogChangeUniteTechnique$.subscribe(data => {
      this.denree = data;
      this.displayDialog = true;
    });
    this.uniteDeMesureSvc.getAllUniteDeMesure().subscribe((data: ResponseWrapper<UnitedemesureDTO>) => {
      this.uniteDeMesureList = data.resultList
    });
  };

  closeDialog() {
    this.displayDialog = false;
  }

  help() {

    let dms = new DialogMsgSupplier();
    dms.title = `Changement de l'unité technique `;
    dms.logo = 'fa fa-question-circle  yoni-color';


    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Cette action va permettre de changer l'unité technique d'une denrée.`,
      `Ce changement  aura un impact sur la recette d'un plat utilisant cette denrée`,
      `Le grammage de la recette ne va pas changer. Mais le nombre d'unité technique `,
      `utilisé necessaire pour réaliser la recette  peut changer en fonction du `,
      `pourcentage de subtitution.`
    ];


    dms.content = {
      intro: `Unité technique`,
      paragraphes: [p1]

    };

    return dms;
  }

  saveUniteTechnique() {
    this.produitDeclinaisonSvc.saveChangeUniteTechnique(this.denree.id,
      this.denree.uniteDeMesure.id,
      this.uniteTechniqueSubstitution.id,
      this.pourcentageSubstitution).subscribe((data: ResponseWrapper<any>) => {
      this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, 'Changement d\'unité technique réussi', `Le changement a impacté ${data.one} recettes`);
      this.produitDeclinaisonSvc.announceNewUniteTechnique(data.additionalProperties['produitDeclinaison']);
      this.closeDialog();
    });
  }

  onSelectionChanged(event: any) {
    this.uniteTechniqueSubstitution = event.selectedRowsData[0];
  }

  getMesureForOldUniteDeMesure() {
    if (this.pourcentageSubstitution <= 0 ||
      this.denree.ratioUniteTechnique <= 0) {
      return 0;
    } else {
      this.qteOldUnitedeMesure = (1 / this.denree.ratioUniteTechnique);
      return this.qteOldUnitedeMesure;
    }
  }

  getMesureForNewUniteDeMesure() {
    if (this.pourcentageSubstitution <= 0 ||
      this.denree.ratioUniteTechnique <= 0) {
      return 0;
    } else {
      return (this.qteOldUnitedeMesure * (this.pourcentageSubstitution / 100));
    }
  }
}
