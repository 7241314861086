<form [formGroup]="formGroupCtrl" (ngSubmit)="save()" novalidate>

  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">

      <!--Nouvel environnement -->
      <ng-container *ngIf="yoEnvironnement.id === 0">
        <label>Nouvel environnement</label>

      </ng-container>

      <!--MODIFICATION ENVIRONNEMENT-->
      <ng-container *ngIf="yoEnvironnement.id != 0">
        <label>Environnement : {{yoEnvironnement.libelle}}</label>
      </ng-container>

    </ng-template>

    <ng-template pTemplate="right">

    </ng-template>
  </p-toolbar>

  <div class="d-flex flex-column">
    <div class="d-flex">

      <fieldset formGroupName="environnementGroup" class="mg-t-10">

        <div class="inputLibelle">
          <yo-input-text

            (yoOnErase)="change()"
            [yoUppercase]="true"
            [yoLowercase]="true"
            formControlName="libelle"
            [yoFormControl]="libelleCtrl"
            [yoMinLength]="3"
            [yoMaxLength]="100"
            [yoLabel]="'Libellé'"

            [yoRequired]="true"
            [(ngModel)]="yoEnvironnement.libelle"></yo-input-text>
        </div>

        <div class="inputLibelle">
          <yo-input-text

            (yoOnErase)="change()"
            [yoUppercase]="true"
            [yoLowercase]="true"
            formControlName="code"
            [yoFormControl]="codeCtrl"
            [yoMinLength]="3"
            [yoMaxLength]="50"
            [yoLabel]="'Code'"

            [yoRequired]="true"
            [(ngModel)]="yoEnvironnement.code"></yo-input-text>
        </div>

      </fieldset>
    </div>
    <div class="d-flex overflow-auto" [style.height.px]="utils.getWindowAvailableHeight(250)" >
      <table class="table">
        <tbody>
        <tr>
          <td rowspan="2">
            <div class="cellule autoscroll">

              <label for="listBoxSitesStatiques" class="bold">Tous les sites</label>

              <p-listbox id="listBoxSitesStatiques"
                         [options]="sitesStatiques"
                         filter="true"
                         pDraggable="sitesStatiques"
                         [listStyle]="{'max-height':'420px'}"
                         [style]="{'width':'300px'}"
                         [(ngModel)]="selectedsitesStatiques" [ngModelOptions]="{standalone: true}">
              </p-listbox>
            </div>

          </td>
          <td>
            <div class="cellule">
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesStatiques < 0)"
                      (click)="statiquesVersVisibles(true)"
                      [pTooltip]="messageAjouterAuxSitesLocaux()"

              >
                <i class="fa fa-plus white space"></i>
                Sites locaux
              </button>
              <br/>
              <br/>
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesPrincipaux < 0) "
                      (click)="visiblesVersStatiques(true)"
                      [pTooltip]="messageRetirerDesSitesLocauxOuReferents(true)"

              >
                <i class="fa fa-minus white space"></i>
                Sites locaux
              </button>
            </div>
          </td>
          <td>

            <div class="cellule autoscroll">
              <label class="bold">Sites locaux</label>
              <p-listbox
                [listStyle]="{'max-height':'250px'}"
                [style]="{'width':'300px'}"
                filter="true"
                [options]="sitesPrincipaux"
                pScrollableView="true"
                [(ngModel)]="selectedsitesPrincipaux"
                [ngModelOptions]="{standalone: true}"
                pDroppable="sitesStatiques">
              </p-listbox>
            </div>
          </td>
        </tr>
        <tr>

          <td>
            <div class="cellule">
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesStatiques < 0)"
                      (click)="statiquesVersVisibles(false)"
                      [pTooltip]="messageAjouterAuxSitesReferents()">
                <i class="fa fa-plus white space"></i>
                Sites référents
              </button>
              <br/>
              <br/>
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesVisibles < 0)"
                      (click)="visiblesVersStatiques(false)"
                      [pTooltip]="messageRetirerDesSitesLocauxOuReferents(false)"

              >
                <i class="fa fa-minus white space"></i>
                Sites référents
              </button>
            </div>
          </td>
          <td>
            <div class="cellule autoscroll">
              <label class="bold">Sites référents</label>

              <p-listbox
                [listStyle]="{'max-height':'250px'}"
                [style]="{'width':'300px'}"
                [options]="sitesVisibles"
                filter="true"
                [(ngModel)]="selectedsitesVisibles"
                [ngModelOptions]="{standalone: true}">
              </p-listbox>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex mg-t-10">
      <div class="mr-auto"></div>
      <div class="d-flex">
        <button pButton type="submit"  name="save" class="mg-r-5" pTooltip="Enregistrer environnement" icon="fas fa-save"
                [disabled]="!formGroupCtrl.valid"></button>

        <button pButton type="button" name="delete" class="mg-r-30 p-button-danger" (click)="confirmDeleteEnvironnement(yoEnvironnement)"
                pTooltip="Supprimer l environnement {{yoEnvironnement.libelle}}" icon="fas fa-trash"
                [disabled]="yoEnvironnement.id===0"></button>
      </div>
    </div>

  </div>


</form>
