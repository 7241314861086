<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>

    <button
      pButton
      class="mg-r-20"
      label="AJOUTER"
      pTooltip=" Ajouter un composant"
      icon="fa fa-plus"
      [disabled]="!gds.canModify(yoProduit)"
      (click)="enableDisplaySearchPanel()">
    </button>

    <span class="mg-r-20">
      <p-dropdown
        [options]="uniteDeProductionList"
        [filter]="true"
        [style]="{'max-width':'200px','width':'200px'}"
        optionLabel="libelle"
        [(ngModel)]="selectedUniteDeProduction"
        (ngModelChange)="onChangeSelectedUniteDeProduction($event)">
      </p-dropdown>
    </span>

    <strong>RECETTE pour
      <input
        type="number"
        pKeyFilter="pint"
        class="platesNumber"
        name="platesNumber"
        min="0"
        max="1000"
        [ngModel]="yoProduit?.recetteNbPortions"
        (ngModelChange)="setPlatesNumberModelValue($event)">
      {{title}}(s)
    </strong>
  </ng-template>
  <ng-template pTemplate="right">
    <!--selection vue quantite / cout -->
    <p-selectButton class="mg-r-20" [options]="views" [ngModel]="selectedView"
                    (ngModelChange)="setViewModelValue($event)">
    </p-selectButton>

    <button
      class="mg-r-5"
      pButton icon="fas fa-print"
      (click)="imprimerFicheTechnique()"
      pTooltip="Impression de la fiche technique">
    </button>

    <!--enregistrer la fiche technique-->
    <yo-button-save
      (click)="save()"
      yoMdTooltip="Enregistrer la recette"
      [yoDisabled]="!canModify()">
    </yo-button-save>

  </ng-template>
</p-toolbar>

<p-table [columns]="cols"
         [value]="rowsPd"
         [scrollable]="true"
         [scrollHeight]="utils.scrollHeightContent(350)"
         styleClass="p-datatable-sm"
         (onRowReorder)="onRowReorder(rowsPd,cellsMap,$event)"
         dataKey="id">

  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

        <col *ngSwitchCase="'actions'" style="width:88px" [style.text-align]="'left'">
        <col *ngSwitchCase="'composants'" style="width:493px" [style.text-align]="'left'">
        <ng-container *ngIf="hasRightsIDistri">
          <col *ngSwitchCase="'conditionneAPart'" style="width:120px" [style.text-align]="'left'">
        </ng-container>
        <col *ngSwitchCase="'codes'" style="width:143px" [style.text-align]="'left'">
        <col *ngSwitchCase="'prixAchat'" style="width:149px" [style.text-align]="'right'">
        <col *ngSwitchCase="'uniteDeMesure'" style="width:91px" [style.text-align]="'right'">
        <col *ngSwitchDefault style="width:137px" [style.text-align]="'right'">

      </ng-container>

    </colgroup>
  </ng-template>


  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns; trackBy:utils.trackByFn" [pSortableColumn]="col.field"
          pTooltip="{{col.tooltip}}">
        <ng-container [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'actions'">
            {{col.header}}
          </ng-container>

          <ng-container *ngSwitchCase="'composants'">
            {{col.header}}
          </ng-container>

          <ng-container *ngIf="hasRightsIDistri">
            <ng-container *ngSwitchCase="'conditionneAPart'">
              {{col.header}}
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'codes'">
            {{col.header}}
          </ng-container>

          <ng-container *ngSwitchCase="'prixAchat'">
            {{col.header}}
          </ng-container>

          <ng-container *ngSwitchCase="'uniteDeMesure'">
            {{col.header}}
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div style="display: inline-block;">
              {{col.header}}
            </div>
            <div style="display: inline-block;float:right;">

              <!--n'afficher le bouton de duplication que s'il y a au moins 2 convives-->
              <ng-container *ngIf="!utils.isCollectionNullOrEmpty(colsPd) && colsPd.length>1">
                <i class="fas fa-xs fa-copy cursor" (click)="enableDisplayDuplication(col.field)"
                   pTooltip="Dupliquer {{col.header}} sur les autres convives" showDelay="500"></i>
              </ng-container>

            </div>

          </ng-container>

        </ng-container>
      </th>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
    <tr [pReorderableRow]="index">
      <td *ngFor="let col of columns; trackBy:utils.trackByFn">

        <ng-container [ngSwitch]="col.field">


          <ng-container *ngSwitchCase="'actions'">
            <i *ngIf="gds.canModify(yoProduit)" [class]="classDeplacerComponent()"
               pTooltip="Déplacer le composant" showDelay="500"
               pReorderableRowHandle
            >
            </i>
            <i *ngIf="!gds.canModify(yoProduit)" [class]="classDeplacerComponent()"
               pTooltip="Déplacer le composant" showDelay="500"
            >
            </i>

            <yo-cell-button (yoNavigation)="removeComponent(rowData)"
                            [yoTooltipShowDelay]="500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="30"
                            [yoTextAlign]="'center'"
                            yoIconClass="fa fa-trash"
                            pTooltip="Enlever le composant"
                            tooltipPosition="right"
                            [yoDisabled]="!gds.canModify(yoProduit)"
                            showDelay="500"
            ></yo-cell-button>

          </ng-container>

          <ng-container *ngSwitchCase="'composants'">

            <a class="cursorPn" (click)="openFicheTechnique(rowData)">

              <div class="d-flex flex-row">
                <yo-icon-produitfab [fabrique]="rowData.fabrique"></yo-icon-produitfab>
                <i *ngIf="rowData.erreurPrix"
                   class="fas fa-exclamation-triangle danger-color mg-r-5 mg-l-5 align-self-center"
                   [pTooltip]="rowData.messageErreurPrix">
                </i>
                <div *ngIf="!rowData.subtitution">
                  <span class="firstcol-label">{{rowData.libelle}}</span>
                  <span class="firstcol-decli"> {{rowData.declinaison.libelle}} </span>
                </div>
                <div *ngIf="rowData.subtitution">
                  <span class="mg-r-5 line-through">{{rowData.pdOriginalLibelle}}</span>
                  <i class="fas fa-arrow-right mg-r-5"></i>
                  <span>{{rowData.libelle}}</span>
                </div>
              </div>

            </a>
          </ng-container>

          <ng-container *ngIf="hasRightsIDistri">
            <ng-container *ngSwitchCase="'conditionneAPart'">
              <ng-container *ngTemplateOutlet="tplConditionneAPart;context:{rowData:rowData}">
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'codes'">
            {{rowData.code}}
          </ng-container>


          <ng-container *ngSwitchCase="'prixAchat'">
            <div style="text-align: right;">{{rowData.prixAchat   | currency:'EUR':'symbol':'1.4-4'}}
              / {{rowData?.uniteDeMesure.libelle}}</div>
          </ng-container>

          <ng-container *ngSwitchCase="'uniteDeMesure'">

            <!-- on  affiche la quantité en kg dans le cas d'une sous recette-->
            <ng-container *ngIf="rowData.fabrique">
              <div>
                <div> {{rowData?.uniteDeMesure.libelle}}</div>
                <div class="badge badge-secondary font-10">(sous recette)</div>
              </div>

            </ng-container>
            <ng-container *ngIf="!rowData.fabrique">
              {{rowData?.uniteDeMesure.libelle}}
            </ng-container>

          </ng-container>

          <ng-container *ngSwitchDefault>
            <!-- Quand utilisateur n'a pas les droits IDistri le col.field ci-dessous n'est pas l'id de produit declinaison mais 'conditionneAPart' -->
            <ng-container *ngIf="col.field !== 'conditionneAPart'">
              <ng-container *ngTemplateOutlet="tplCell;context:{cell:getCell(rowData?.id,col.field),rowData:rowData}"></ng-container>
            </ng-container>
          </ng-container>

        </ng-container>

      </td>
    </tr>
  </ng-template>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <ng-container
      *ngTemplateOutlet="tplTotalCout;context:{pdDeclines:colsPd,view:selectedView}">
    </ng-container>
  </ng-template>
</p-table>


<!--TEMPLATE CELLULE-->
<ng-template #tplCell let-cell="cell" let-rowData="rowData">

  <!-- COUTS MATIERES-->
  <ng-container *ngIf="selectedView === 'cout'">
    <ng-container
      *ngTemplateOutlet="tplCout;context:{cell:cell,row:rowData}"></ng-container>
  </ng-container>

  <!--QUANTITES-->
  <ng-container *ngIf="selectedView === 'quantite'">
    <ng-container
      *ngTemplateOutlet="tplQuantite;context:{cell:cell,rowData:rowData}"></ng-container>
  </ng-container>

</ng-template>

<!--TEMPLATE COUTS TOTAUX-->
<ng-template #tplTotalCout let-pdDeclines="pdDeclines" let-view="view">
  <tr *ngIf="view==='cout'">
    <td colspan="6"></td>
    <ng-container *ngFor="let pdDecline of pdDeclines">
      <td>
        <div style="text-align: right">{{getCoutProduitDecline(pdDecline) |  currency:'EUR':'symbol':'1.4-4' }} /
          pers.
        </div>
      </td>
    </ng-container>
  </tr>

  <ng-container *ngIf="view==='quantite'">
    <tr>
      <td colspan="4"></td>
      <td colspan="2">
        <div class="d-flex flex-column justify-content-end">
          <div>Poids brut pour 1 UT:</div>
          <div class="mg-l-5 font-size-gramme">(en gramme)</div>
        </div>
      </td>
      <ng-container *ngFor="let pdDecline of pdDeclines">
        <td>
        {{getPoidsBrut(pdDecline)| number: '1.0-2':'fr'}} g
        </td>
      </ng-container>
    </tr>
    <tr>
      <td colspan="4"></td>
      <td colspan="2">
        <div class="d-flex flex-column justify-content-end">
          <div>Poids net pour 1 UT:</div>
          <div class="mg-l-5 font-size-gramme">(en gramme)</div>
        </div>
      </td>
      <ng-container *ngFor="let pdDecline of pdDeclines">
        <td>

          <input
            class="quantite mg-r-2"
            type="number"
            pKeyFilter="pnum"
            [ngModel]="pdDecline?.totalPoidsNetRecetteUT"
            (ngModelChange)="seTotalPoidsNetRecetteUt(pdDecline, $event) "
            [disabled]="!gds.canModify(yoProduit)">
        </td>
      </ng-container>
    </tr>

  </ng-container>
</ng-template>


<!--TEMPLATE COUTS-->
<ng-template #tplCout let-cell="cell" let-row="row">
  <div
    style="color:slategray; font-size: 14px;text-align: right;">{{getPrixAchatComposant(cell, row) |  currency:'EUR':'symbol':'1.4-4' }}
    / {{this.yoProduit.uniteDeMesure.libelle}}
  </div>
</ng-template>

<!--TEMPLATE QUANTITES-->
<ng-template #tplQuantite let-cell="cell" let-rowData="rowData">
  <div style="display: inline-block;width:70px;" [pTooltip]="tooltipQuantiteEnKg(cell)"
       showDelay="500">

    <input
      class="quantite mg-r-2"
      type="number"
      pKeyFilter="pnum"
      [ngModel]="getCellModelValue(cell)"
      (ngModelChange)="setCellModelValue(cell,$event)"
      [disabled]="!gds.canModify(yoProduit)">

  </div>
  <div style="display: inline; width:auto;">
    <em class="genlabel-color" style="font-size: 12px">{{uniteQuantiteHelper(cell)}}</em>
  </div>


</ng-template>

<!-- TEMPLATE CONDITIONNE A PART -->
<ng-template #tplConditionneAPart let-cells="cells" let-rowData="rowData">
  <div style="display: inline-block;width:70px;"
       showDelay="500">

    <input
      class="checkbox mg-r-2"
      type="checkbox"
      [ngModel]="getConditionneAPartLigne(rowData.id)"
      (ngModelChange)="setConditionneAPartValue(rowData.id, $event)"
      [disabled]="!gds.canModify(yoProduit)"
    />

  </div>

</ng-template>


<!--PANEL DE DUPLICATION RECETTE-->
<p-dialog [(visible)]="displayDuplication"
          [contentStyle]="{'overflow':'visible'}"
          [modal]="true"

          (onHide)="onHideDuplication()"
          [style]="{width: '800px', minWidth: '800px'}"
          [dismissableMask]="false">

  <p-toast position="bottom-right" key="duplication"></p-toast>

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-copy mg-r-5"></i>Copier la recette vers d'autres convives
    </div>
  </ng-template>

  <!--CONTENU-->
  <div class="row" *ngIf="!utils.isNullOrEmpty(selectedSourceProduitDecline)">
    <div class="col-md-auto">
      Recette {{selectedSourceProduitDecline.declinaison.libelle}}   <strong class="genlabel">à copier vers</strong>
    </div>
    <div class="col">
      <ng-container *ngFor="let target of getTargetsCopy(selectedSourceProduitDecline)">
        <div>
          <p-checkbox name="duplication" [value]="target.id" [(ngModel)]="selectedTargetsId"
                      [label]="target.declinaison.libelle"></p-checkbox>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i><em>La duplication ne sera effective que
      lorsque vous aurez enregistré la recette.</em>
    </div>
  </div>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <button pButton (click)="duplicate(selectedSourceProduitDecline, selectedTargetsId)" icon="fas fa-copy"
            label="Copier"></button>
    <button pButton (click)="onHideDuplication()"
            class="p-button-secondary"
            icon="fas fa-times" label="Fermer"></button>
  </ng-template>

</p-dialog>


<!--PANEL DE RECHERCHE DE COMPOSANTS-->
<p-dialog [(visible)]="displaySearchPanel"
          [contentStyle]="{'overflow':'visible'}"
          [modal]="true"
          (onHide)="onHideSearchPanel()"
          [style]="{width: '1200px', minWidth: '1200px',height:'540px'}">

  <p-toast position="bottom-right" key="ajoutcomposant"></p-toast>

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-search-plus mg-r-5"></i>AJOUTER DES COMPOSANTS À LA RECETTE
    </div>
  </ng-template>

  <!--CONTENU-->
  <yo-ft-search-panel [udpId]="selectedUniteDeProduction === undefined ? 0 : selectedUniteDeProduction.id  " [produit]="yoProduit"></yo-ft-search-panel>
</p-dialog>


<!--PANEL D'IMPRESSION DE LA FICHE TECHNIQUE-->
<p-dialog [(visible)]="displayPrintPanel"
          appendTo="body"
          [contentStyle]="{'overflow':'visible'}"
          [modal]="true"

          (onHide)="onHidePrintPanel()"
          [style]="{width: '1200px', minWidth: '1200px',height:'540px'}"
          [dismissableMask]="false">

  <p-toast position="bottom-right" key="ajoutcomposant"></p-toast>

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-file-pdf mg-r-5"></i>IMPRIMER LA FICHE TECHNIQUE
    </div>
  </ng-template>

  <!--CONTENU-->
  <p-dropdown [options]="colsPd"
              filter="true"
              optionLabel="declinaison.libelle"
              defaultLabel="Choisir une déclinaison cibles"
              [style]="{'left-width':'20%','min-width':'50%','max-width':'100%'}"
              [(ngModel)]="selectedPd"></p-dropdown>

  <ng-template pTemplate="footer">
    <button type="button" pButton label="Valider" icon="fas fa-check" (click)="exportPdf()"></button>
    <button type="button" pButton label="Annuler" icon="fas fa-times" (click)="onHidePrintPanel()"></button>
  </ng-template>
</p-dialog>

