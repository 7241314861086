<div class="card-size">

  <div class="mg-b-10">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>

    <button pButton
            class="mg-r-15"
            label="CRÉER"
            icon="fa fa-plus"
            [pTooltip]="'Création d\'un lot marché'"
            (click)="openEditLotMarche(undefined)"
            [disabled]="!canCreate()">
    </button>
  </div>
</div>

<dx-data-grid
  [dataSource]="lotMarcheList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(180)"
  width="100%"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" alignment="center" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Lot marché" [width]="300" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Code" [width]="150" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="descriptif" caption="Description" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="dateDebut" alignment="center" caption="Date de début" [width]="150" dataType="date"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="dateFin" alignment="center" caption="Date de fin" [width]="150" dataType="date"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="valeurEngagement" alignment="center" caption="Engagement (€)" [width]="140"
              cellTemplate="engagementPrixCellTemplate" [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="actif" alignment="center" caption="Actif" [width]="80"
              [allowFiltering]="true">
  </dxi-column>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <ng-container *ngIf="canModify()">
      <yo-cell-button (yoNavigation)="openEditLotMarche(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      yoIconClass="fa fa-edit"
                      pTooltip="Modifier le lot marché"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>

      <yo-cell-button (yoNavigation)="openDeleteLotMarche(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-trash'"
                      pTooltip="Supprimer le lot marché"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </ng-container>


    <ng-container *ngIf="!canModify()">
      <yo-cell-button (yoNavigation)="openEditLotMarche(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-eye'"
                      pTooltip="Voir le lot marché"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </ng-container>
  </div>

  <!--    TEMPLATE ENGAGEMENT PRIX-->
  <div *dxTemplate="let cell of 'engagementPrixCellTemplate'">
    {{cell.row.data.valeurEngagement  | currency:'EUR':'symbol':'1.4-4'}}
  </div>

  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} lots marché">
  </dxo-pager>

</dx-data-grid>

<yo-dialog-lot-marche></yo-dialog-lot-marche>


