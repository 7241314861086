import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import ZoneANettoyerPmsDto from "../../dtos/pms/zone-a-nettoyer-pms-dto";

export const URL_GET_ALL = 'dolrest/zones-pms/all';
export const URL_SAVE = 'dolrest/zones-pms/';
export const URL_DELETE = 'dolrest/zones-pms/';

@Injectable({
  providedIn: 'root'
})
export class ZoneANettoyerPmsService {
  private subjectOpenDialog = new Subject<ZoneANettoyerPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectZoneSaved = new Subject<ZoneANettoyerPmsSavedSupplier>();
  zoneSaved$ = this.subjectZoneSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Récupère toutes les zones à nettoyer pms
   * @returns {Observable<ZoneANettoyerPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<ZoneANettoyerPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde une zone à nettoyer pms
   * @param zone pms
   * @param file
   */
  save = (zone: ZoneANettoyerPmsDto, file: File) : Observable<ResponseWrapper<ZoneANettoyerPmsDto>> => {
    let formData = new FormData();
    formData.set('zone', JSON.stringify(zone));
    formData.set('file', file);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.httpSvc.post(URL_SAVE, formData, null);
  }

  /**
   * Supprime une ou plusieurs zone(s) à nettoyer pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: ZoneANettoyerPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (zone: ZoneANettoyerPmsDto, isUpdate: boolean = false) : void => {
    this.subjectZoneSaved.next({ isUpdate, zone } as ZoneANettoyerPmsSavedSupplier);
  }

  public getEntityName = () : string => {
    return "zoneanettoyerpms";
  }
}

export interface ZoneANettoyerPmsSavedSupplier {
  isUpdate: boolean;
  zone: ZoneANettoyerPmsDto;
}
