<form [formGroup]="formGroupCtrl">

  <p-card>
    <div class="card-size">


      <h5>
      <label class="badge badge-secondary">
        <i class="fa fa-map-marker yoni-color  mg-r-5"></i>
         <ng-container *ngIf="yoSite.id === 0; else editMode">
           {{'Création d\'un site' | uppercase }}
         </ng-container>
         <ng-template #editMode>
           {{yoSite.libelle | uppercase}}
         </ng-template>
      </label>
      </h5>


      <div class="d-flex justify-content-end mg-b-10">
        <yo-button-save
          (click)="save()"
          pTooltip="Enregistrer le site"
          yoType="submit">
        </yo-button-save>
      </div>


      <p-panel header="INFORMATIONS GÉNÉRALES" [style]="{'margin-bottom': '10px'}">

        <div class="d-flex flex-row align-items-center" formGroupName="siteGroup">
          <yo-input-text
            class=" mg-r-50"
            formControlName="libelle"
            [yoFormControl]="libelleCtrl"
            [yoMaxLength]="100"
            [yoLabel]="'Libellé'"
            [yoRequired]="true"
            [(ngModel)]="yoSite.libelle">
          </yo-input-text>

          <yo-input-text
            class= "mg-r-50"
            formControlName="reference"
            [yoFormControl]="referenceCtrl"
            [yoMaxLength]="100"
            [yoLabel]="'Code'"
            [yoRequired]="true"
            [(ngModel)]="yoSite.reference">
          </yo-input-text>

          <p-checkbox
            formControlName="actif"
            binary="true"
            value="actif"
            label="Actif"
            [(ngModel)]="yoSite.actif">
          </p-checkbox>
        </div>

      </p-panel>

      <p-panel header="ADRESSE">
        <div class="d-flex flex-row align-items-start" formGroupName="adressGroup">

          <div class="d-flex flex-column mg-r-50">
            <yo-input-text
              formControlName="adresse1"
              [yoFormControl]="adresse1Ctrl"
              [yoMaxLength]="100"
              [yoLabel]="'Adresse 1'"
              [(ngModel)]="yoSite.adresse1">
            </yo-input-text>

            <yo-input-text
              formControlName="adresse2"
              [yoFormControl]="adresse2Ctrl"
              [yoMaxLength]="100"
              [yoLabel]="'Adresse 2'"
              [(ngModel)]="yoSite.adresse2">
            </yo-input-text>

            <yo-input-text
              formControlName="adresse3"
              [yoFormControl]="adresse3Ctrl"
              [yoMaxLength]="100"
              [yoLabel]="'Adresse 3'"
              [(ngModel)]="yoSite.adresse3">
            </yo-input-text>

            <yo-input-text
              formControlName="codePostal"
              [yoFormControl]="codePostalCtrl"
              [yoMaxLength]="5"
              [yoLabel]="'Code postal'"
              [yoRequired]="false"
              [(ngModel)]="yoSite.codePostal">
            </yo-input-text>

            <yo-input-text
              formControlName="ville"
              [yoFormControl]="villeCtrl"
              [yoMaxLength]="50"
              [yoLabel]="'Ville'"
              [(ngModel)]="yoSite.ville">
            </yo-input-text>

          </div>

          <div class="d-flex flex-column align-items-start mg-r-50">
            <yo-input-text
              formControlName="telephone"
              [yoFormControl]="telephoneCtrl"
              [yoMaxLength]="100"
              [yoLabel]="'Téléphone'"
              [(ngModel)]="yoSite.telephone">
            </yo-input-text>

            <yo-input-text
              formControlName="fax"
              [yoFormControl]="faxCtrl"
              [yoMaxLength]="100"
              [yoLabel]="'Fax'"
              [(ngModel)]="yoSite.fax">
            </yo-input-text>


            <yo-input-text
              formControlName="email"
              [yoFormControl]="emailCtrl"
              [yoMaxLength]="50"
              [yoLabel]="'Email'"
              [yoRequired]="false"
              [(ngModel)]="yoSite.email">
            </yo-input-text>
          </div>

        </div>
      </p-panel>

    </div>
  </p-card>
</form>
