import {Component, OnInit} from '@angular/core';
import {RegimeAlimentaireService} from '../../core/services/entities/regime-alimentaire.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-regimes-alimentaires',
  templateUrl: './regimes-alimentaires.component.html',
  styleUrls: ['./regimes-alimentaires.component.scss']
})
export class RegimesAlimentairesComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/regimes-alimentaires';

  constructor(public regimeAlimentaireSvc:RegimeAlimentaireService) { }

  ngOnInit() {
  }

}
