import {Component, OnDestroy, OnInit} from '@angular/core';
import {MZoneProduction} from '../../../../../core/models/gestion-unites-production/m-zone-production';
import {TachesService} from '../../../../../core/services/entities/taches.service';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {MEquipe} from '../../../../../core/models/gestion-unites-production/m-equipe';
import {MTache} from '../../../../../core/models/gestion-unites-production/m-tache';
import {PTreeNode} from '../../../../../core/models/technique/ptree-node';
import {ZoneDeProductionDTO} from '../../../../../core/dtos/zone-de-production-dto';
import {EquipeDTO} from '../../../../../core/dtos/equipe-dto';
import {TacheDTO} from '../../../../../core/dtos/tache-dto';
import {UniteDeProduction__ZoneDeProductionDTO} from '../../../../../core/dtos/unite-de-production__zone-de-production';
import {UniteDeProduction__EquipeDTO} from '../../../../../core/dtos/unite-de-production__equipe';
import {UniteDeProductionDTO} from '../../../../../core/dtos/unite-de-production-dto';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UpEquipesSupplier} from '../up-parametrage-resolver.service';
import {groupBy as _groupBy} from 'lodash';

@Component({
  selector: 'yo-parametrage-association',
  templateUrl: './parametrage-association.component.html',
  styleUrls: ['./parametrage-association.component.scss']
})
export class ParametrageAssociationComponent implements OnInit, OnDestroy {

  messageHelpForUser: string;

  desactiverSelectionEquipes: boolean;

  desactiverSelectionTaches: boolean;

  selectedZdp: UniteDeProduction__ZoneDeProductionDTO;

  selectedEq: UniteDeProduction__EquipeDTO;

  selectedTaches: PTreeNode[] = [];

  placeholderAtelier = 'Etape 1 : Sélectionnez un atelier';

  placeholderEquipe = 'Etape 2 : Sélectionnez une équipe';

  placeholderTache = 'Etape 3 : Sélectionnez une tâche';

  uniteProduction: UniteDeProductionDTO;

  ateliers: ZoneDeProductionDTO[];

  equipes: EquipeDTO[];

  taches: TacheDTO[];

  treeTaches: PTreeNode[];

  associations: MZoneProduction[] = [];

  mappingBuildingElementsPreview = new Map();

  elementsToSend: MZoneProduction[] = [];

  elementsForPreview: PTreeNode[] = [];

  equipesPreselectionnesIds: number[] = [];

  associationsPreselectionnesIds: number[] = [];

  tachesPreselectionnesIds: number[] = [];

  subscriptionRoute: Subscription;

  subscriptionLoadAssociations: Subscription;

  constructor(private tachesService: TachesService,
              public utils: UtilsService,
              private route: ActivatedRoute) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionLoadAssociations);
  }

  ngOnInit(): void {
    this.routeSubscription();
    this.loadAssociationsSubscription();
  }

  routeSubscription(): void {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { upEquipesSupplier: UpEquipesSupplier }) => {
        this.uniteProduction = data.upEquipesSupplier.uniteDeProduction;
        this.ateliers = data.upEquipesSupplier.ateliers;
        this.equipes = data.upEquipesSupplier.equipes;
        this.taches = data.upEquipesSupplier.taches;
        this.associations = data.upEquipesSupplier.tachesConfigurees;
        this.initMappingBuildingElementsPreview();
        this.initTreeTaches(this.taches);
        this.initAteliersConfiguresIds();
      });
  }

  loadAssociationsSubscription(): void {
    this.subscriptionLoadAssociations = this.tachesService.loadAssociations$
      .subscribe(() => this.loadAssociations());
  }

  /**
   * Initialisation d'une structure en tableau associative (Map)
   * me permettant de créer une liste de MZoneProduction qui servira
   * pour la persistence et la preview des informations.
   */
  initMappingBuildingElementsPreview(): void {
    this.mappingBuildingElementsPreview = new Map();
    this.associations.forEach(atelierConfigure => {
      const equipes = new Map();
      if (atelierConfigure.equipesList) {
        atelierConfigure.equipesList.forEach(equipeConfiguree => {
          const tachesList = equipeConfiguree.tachesList.slice();
          equipes.set(equipeConfiguree.id, tachesList);
        });
      }
      this.mappingBuildingElementsPreview.set(atelierConfigure.id, equipes);
    });
    this.buildElementsToSend();
  }

  /**
   * Initialisation des ateliers prédéfinis par la configuration.
   */
  initAteliersConfiguresIds(): void {
    this.associationsPreselectionnesIds = this.associations ? this.associations.map((atelier: MZoneProduction) => atelier.id) : [];
  }

  /**
   * Initialisation des tâches pour le composant p-tree
   * @param taches Liste de tâches DTO
   */
  initTreeTaches(taches: TacheDTO[]): void {
    if (taches) {
      const tachesByTypeTaches = _groupBy(taches, (tache) => tache.typeTacheLibelle);
      this.treeTaches = Object.keys(tachesByTypeTaches)
        .map((typeTacheLibelle) => {
          const tachesChildren = tachesByTypeTaches[typeTacheLibelle];
          const children = tachesChildren.map((tache: TacheDTO) => new PTreeNode(tache.id, tache.libelle, '', '', null, { idTypeTache: tache.typeTacheId, libelleTypeTache: tache.typeTacheLibelle }));
          return new PTreeNode(-1, typeTacheLibelle, 'pi pi-folder-open', 'pi pi-folder', children);
        });
      this.lockTaches(this.treeTaches);
    }
  }

  /**
   * Désactive la sélection de tâches
   * @param tachesChildren
   */
  lockTaches(tachesChildren: PTreeNode[]): void {
    if (tachesChildren) {
      this.treeTaches = tachesChildren.map((tache: PTreeNode) => {
        if (tache.children) {
          tache.children = tache.children.map((child: PTreeNode) => {
            return {...child, selectable: false};
          });
        }
        return {...tache, selectable: false};
      });
    }
  }

  /**
   * Active la sélection de tâches
   * @param tachesChildren
   */
  unlockTaches(tachesChildren: PTreeNode[]): void {
    this.treeTaches = tachesChildren.map((tache: PTreeNode) => {
      if (tache.children) {
        tache.children = tache.children.map((child: PTreeNode) => {
          return {...child, selectable: true};
        });
      }
      return {...tache, selectable: true};
    });
  }

  /**
   * Ferme les noeuds parents qui n'ont pas d'enfants préconfigurés
   */
  closeTachesParentsNode(): void {
    this.treeTaches.forEach((tacheNode: PTreeNode) => {
      tacheNode.expanded = false;
    });
  }

  /**
   * Callback lorsque l'on change d'atelier : Préselection des équipes liées à l'atelier
   */
  onChangeAtelier(): void {
    this.equipesPreselectionnesIds = [];
    this.desactiverSelectionEquipes = false;
    this.desactiverSelectionTaches = true;
    this.lockTaches(this.treeTaches);
    this.closeTachesParentsNode();

    const atelier = this.associations.find((a: MZoneProduction) => a.id === this.selectedZdp.id);
    this.tachesPreselectionnesIds = [];
    if (atelier && atelier.equipesList) {
      this.equipesPreselectionnesIds = atelier.equipesList ? atelier.equipesList.map((equipe: MEquipe) => equipe.id) : [];
    }
    this.messageHelpForUser = 'Veuillez sélectionner une équipe';
  }

  /**
   * Callback lorsque l'on change d'équipes : Préselection des tâches liées à l'équipe
   */
  onChangeEquipe(): void {
    this.desactiverSelectionTaches = false;
    this.unlockTaches(this.treeTaches);

    const atelier = this.associations.find((a: MZoneProduction) => a.id === this.selectedZdp.id);
    const equipe = (atelier && atelier.equipesList) ? atelier.equipesList.find((e: MEquipe) => e.id === this.selectedEq.id) : null;
    if (equipe && equipe.tachesList) {
      equipe.tachesList.forEach((tache: MTache) => {
        this.treeTaches.forEach((tacheNode: PTreeNode) => {
          const nodeFound = tacheNode.children.find((child: PTreeNode) => child.id === tache.id);
          if (tacheNode.children.length === 0) {
            tacheNode.expanded = false;
          }
          if (nodeFound) {
            tacheNode.expanded = true;
            this.selectedTaches.push(nodeFound);
          }
        });
      });
    } else {
      this.closeTachesParentsNode();
    }
    this.messageHelpForUser = 'Veuillez sélectionner une tâche';
  }


  /**
   * Lorsqu'une tâche est décochée de p-tree, on l'enlève de la liste mZoneProduction
   * @param $event
   */
  onNodeUnselect($event): void {
    const node = $event.node;
  }

  onSelectTache(): void {
    // On met à jour la preview + delete preview :
    if (!this.mappingBuildingElementsPreview.has(this.selectedZdp.id)) {
      const equipes = new Map();
      equipes.set(this.selectedEq.id, []);
      this.mappingBuildingElementsPreview.set(this.selectedZdp.id, equipes);
    }

    const currentEquipes = this.mappingBuildingElementsPreview.get(this.selectedZdp.id);
    let currentTaches = [];
    if (currentEquipes && currentEquipes.has(this.selectedEq.id)) {
      currentTaches = currentEquipes.get(this.selectedEq.id);
    }
    this.selectedTaches.forEach(st => {
      if (!currentTaches.find(ct => ct.id === st.id) && st.id > 0) {
        currentTaches.push({id: st.id, libelle: st.label, idTypeTache: st.extra['idTypeTache'], libelleTypeTache: st.extra['libelleTypeTache'] });
      }
    });
    currentEquipes.set(this.selectedEq.id, currentTaches);
    this.mappingBuildingElementsPreview.set(this.selectedZdp.id, currentEquipes);

    this.buildElementsToSend();
  }

  buildElementsToSend(): void {
    if (this.mappingBuildingElementsPreview) {
      this.elementsToSend = [];
      this.elementsForPreview = [];
      this.mappingBuildingElementsPreview.forEach((equipesMap, atelierId) => {
        const equipesList = [];
        const pEquipesNodeList = [];
        equipesMap.forEach((tachesValues, equipeId) => {

          const finalEquipe = new MEquipe();
          finalEquipe.id = equipeId;
          finalEquipe.libelle = this.equipes.find(e => e.id === equipeId).libelle;
          finalEquipe.tachesList = tachesValues.slice();
          equipesList.push(finalEquipe);

          const pEquipeNode = new PTreeNode(equipeId, null, null, null, null);
          pEquipeNode.data = {libelleAtelier: '', libelleEquipe: finalEquipe.libelle, libelleTache: ''};

          finalEquipe.tachesList.forEach((tache) => {
            const pTacheNode = new PTreeNode(tache.id, null, null, null, null,
                                        { idTypeTache: tache.idTypeTache, libelleTypeTache: tache.libelleTypeTache });
            pTacheNode.data = {
              libelleAtelier: '', libelleEquipe: '', libelleTache: tache.libelle,
              udpId: this.uniteProduction.id, zdpId: atelierId, equipeId: equipeId, tacheId: tache.id
            };
            pEquipeNode.children.push(pTacheNode);
          });
          pEquipesNodeList.push(pEquipeNode);
        });
        const finalAtelier = new MZoneProduction();
        finalAtelier.id = atelierId;
        finalAtelier.udpId = this.uniteProduction.id;
        finalAtelier.libelle = this.ateliers.find(a => a.id === atelierId).libelle;
        finalAtelier.equipesList = equipesList.slice();
        this.elementsToSend.push(finalAtelier);
      });
    }
    this.tachesService.announceElementsToSendAreReady(this.elementsToSend);
  }

  reinit(): void {
    this.equipesPreselectionnesIds = [];
    this.tachesPreselectionnesIds = [];
    this.selectedEq = null;
    this.selectedZdp = null;
    this.selectedTaches = [];
    this.desactiverSelectionEquipes = true;
    this.desactiverSelectionTaches = true;
    this.messageHelpForUser = 'Veuillez sélectionner un atelier';
  }

  loadAssociations(): void {
    this.tachesService.findTachesByZoneProductionAndEquipes(this.uniteProduction.id).subscribe(taches => {
      this.associations = taches.resultList;
      this.initMappingBuildingElementsPreview();
      this.initTreeTaches(this.taches);
      this.initAteliersConfiguresIds();
      this.reinit();
    });
  }

}
