import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs";
import {catchError} from "rxjs/operators";
import {UtilsService} from "../../utils/utils.service";
import {PrintCoutRevientPlatDTO} from "../../dtos/clients/print-cout-revient-plat-dto";


export const URL_GET_PLC_TREE_NODE= `dolrest/gestion-clients/plc-tree-node`;
export const URL_POST_PRINT_COUT_REVIENT_PLAT= `dolrest/gestion-clients/print-cout-revient-plat`;

@Injectable()
export class PrintCoutRevientPlatService {

  constructor(private http: HttpClient,
              private utils : UtilsService){}

  private subjectDisplayDialogPrintCoutRevientPlat = new Subject();
  public displayDialogPrintCoutRevientPlat$ = this.subjectDisplayDialogPrintCoutRevientPlat.asObservable();

  public announceDisplayDialogPrintCoutRevientPlat = (displayDialog:boolean) => {
    this.subjectDisplayDialogPrintCoutRevientPlat.next(displayDialog);
  };

  public getPlcTreeNode = () => {
    return this.http.get(URL_GET_PLC_TREE_NODE)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  print = (printCoutRevientPlat : PrintCoutRevientPlatDTO) => {
    return this.http.post(URL_POST_PRINT_COUT_REVIENT_PLAT,printCoutRevientPlat, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/pdf')
    }).pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

}
