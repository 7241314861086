import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from "../../../../core/utils/utils.service";
import {Environnement_PlcDTO} from "../../../../core/dtos/administration/environnement-plc-dto";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {Subscription} from "rxjs/index";
import {EnvironnementPlcService} from "../../../../core/services/portail/environnement-plc.service";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {IsDeletableObject} from "../../../../core/models/is-deletable-object";
import {HELP_FOLDERS, MSG_KEY} from "../../../../core/constants";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'yo-grid-environnement-plc',
  templateUrl: './grid-environnement-plc.component.html',
  styleUrls: ['./grid-environnement-plc.component.scss']
})
export class GridEnvironnementPlcComponent implements OnInit, OnDestroy {

  hasPortailAdmin: boolean;

  environnementPlcList: Environnement_PlcDTO[] = [];

  subHasPortailAdmin: Subscription;
  subEnvironnementPlcSaved: Subscription;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/portail-environnement-plc';

  constructor(public utils: UtilsService,
              private confirmationService : ConfirmationService,
              public auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private environnemetnPlcSvc :EnvironnementPlcService) {
  }

  ngOnInit() {
    this.loadData();
    this.subscriptionHasPortailAdminSubscription();
    this.subscriptionEnvironnemetnPlcSaved();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subHasPortailAdmin);
    this.utils.unsubscribe(this.subEnvironnementPlcSaved);
  }

  help = () => undefined;

  loadData = () => {
    this.environnemetnPlcSvc.getAll().subscribe((data: ResponseWrapper<Environnement_PlcDTO>) => {
        this.environnementPlcList = data.resultList;
      });
  };

  subscriptionHasPortailAdminSubscription = () => {
    // les droits utilisateurs
    this.subHasPortailAdmin = this.auth2Svc.hasMarche$.subscribe(response => {
      this.hasPortailAdmin = response;
    });
  };

  subscriptionEnvironnemetnPlcSaved = () => {
    this.subEnvironnementPlcSaved = this.environnemetnPlcSvc.environnementPlc$.subscribe((data : Environnement_PlcDTO) => {
      this.loadData();
    });
  };

  openEditEnvironnementPlc = (row: Environnement_PlcDTO) => {
    if (this.utils.isNullOrEmpty(row)) {
      row = new Environnement_PlcDTO();
      row.id = 0;
    }
    this.routeMapSvc.goToSecondaryRoute(['administration-sidebar-environnement-plc', row.id]);
  };

  deleteEnvironnementPlc = (row: Environnement_PlcDTO) => {

    this.confirmationService.confirm({
      message: `Etes vous sûr(e) de vouloir supprimer l\'env.point de livraison ${row.libelle}  sélectionné ?`,
      accept: () => {

        this.environnemetnPlcSvc.deleteById(row.id)
          .subscribe((response: ResponseWrapper<IsDeletableObject>) => {
            this.utils.deleteMessage(response, MSG_KEY.ROOT);
            this.loadData();
          });
      }
    });

  };

  canModify = (row: Environnement_PlcDTO) => {
    return row.site && this.auth2Svc.isSiteLocal(row.site.id);
    // return row.site && this.auth2Svc.isSiteLocal(row.site.id) && this.hasPortailAdmin;
  };

  canCreate = () => {
  };

}
