import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ConditionnementDTO from '../../dtos/conditionnement/conditionnement-dto';
import {HttpParams} from "@angular/common/http";

export const URL_GET_ALL = `dolrest/conditionnements/list`;
export const URL_SAVE = `dolrest/conditionnements/save`;
export const URL_DELETE = `dolrest/conditionnements/delete`;

@Injectable({
  providedIn: 'root'
})
export class ConditionnementsService {

  private subjectOpenDialogConditionnement = new Subject<ConditionnementDTO>();
  openDialogConditionnement$ = this.subjectOpenDialogConditionnement.asObservable();

  private subjectConditionnementSaved = new Subject<ConditionnementSavedSupplier>();
  conditionnementSaved$ = this.subjectConditionnementSaved.asObservable();

  constructor( private httpSvc: HttpService) { }

  /**
   * Récupérer tous les conditionnements
   * @returns {Observable<ConditionnementDTO>}
   */
  getAll = (): Observable<ResponseWrapper<ConditionnementDTO>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde un conditionnement
   * @param conditionnement
   */
  save = (cdt: ConditionnementDTO): Observable<ResponseWrapper<ConditionnementDTO>> => this.httpSvc.post(URL_SAVE, cdt);

  announceOpenDialogConditionnement = (param: ConditionnementDTO) => {
    this.subjectOpenDialogConditionnement.next(param);
  };

  announceConditionnementSaved = (conditionnement: ConditionnementDTO, isUpdate: boolean = false) => {
    this.subjectConditionnementSaved.next({ isUpdate, conditionnement } as ConditionnementSavedSupplier);
  };

  /**
   * Supprime un ou plusieurs conditionnement(s) en fonction de
   * leurs identifiants.
   * @param conditionnementsIdsToDelete
   */
  deleteByIds = (conditionnementsIdsToDelete: number[]): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", conditionnementsIdsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

}

export interface ConditionnementSavedSupplier {
  isUpdate: boolean;
  conditionnement: ConditionnementDTO;
}

