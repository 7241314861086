import {Component, OnInit} from '@angular/core';
import {ZonesDeProductionService} from '../../core/services/entities/zones-de-production.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-zones-de-production',
  templateUrl: './zones-de-production.component.html',
  styleUrls: ['./zones-de-production.component.scss']
})
export class ZonesDeProductionComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/zones-de-production';

  constructor(public zoneDeProductionSvc:ZonesDeProductionService) { }

  ngOnInit() {
  }

}
