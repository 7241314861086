import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {LazyLoadEvent} from 'primeng/api';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {catchError, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UniteDeProduction__denreeInterditeDto} from '../../../../core/dtos/unite-de-production__denree-interdite-dto';
import {UniteDeProduction__denreesInterditesService} from '../../../../core/services/entities/unite-de-production__denrees-interdites.service';
import {SearchSupplier} from '../../../../core/suppliers/search-supplier';
import {ProduitDeclinaisonDTO} from '../../../../core/dtos/produit-declinaison-dto';
import {ProduitDeclinaisonService} from '../../../../core/services/entities/produit-declinaison.service';

@Component({
  selector: 'yo-dialog-ajout-denree-interdite',
  templateUrl: './dialog-ajout-denree-interdite.component.html',
  styleUrls: ['./dialog-ajout-denree-interdite.component.scss']
})
export class DialogAjoutDenreeInterditeComponent implements OnInit, OnDestroy {

  colsInterdit: any[] = [
    {field: 'denree', header: 'Sélectionner la denrée à interdire'},
  ];
  colsRemplacement: any[] = [
    {field: 'denree', header: 'Sélectionner la denrée de substitution'},
  ];

  totalProduits = 0;

  subAction: Subscription;
  subscriptionRoute: Subscription;
  idUniteDeProduction: number;

  produitInterdit: ProduitDeclinaisonDTO;
  produitRemplacement: ProduitDeclinaisonDTO;
  pourcentageSubstitution = 100;

  displayDialog = false;
  loadingData = false;
  ssw: SearchSupplierWrapper;

  localeFr = DATEPICKER_FR;

  produitListInterdit: ProduitDeclinaisonDTO[] = [];
  produitListRemplacement: ProduitDeclinaisonDTO[] = [];

  constructor(public utils: UtilsService,
              private udpDenreeInterditeSvc: UniteDeProduction__denreesInterditesService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService) {
  }

  ngOnInit() {
    this.subAction = this.udpDenreeInterditeSvc.openDialogAjoutDenree$.pipe(
      catchError(err=>this.utils.handleError(err))
    ).subscribe(response => {
      this.idUniteDeProduction = response.idUniteDeProduction;
      this.displayDialog = true;

      let event: LazyLoadEvent = {
        first: 0,
        rows: 50,
      };
      this.loadData(event, 2);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAction);
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  loadData($event, interditRemplacement: number) {

    this.loadingData = true;

    if (interditRemplacement === 0) {
      this.produitListInterdit = [];
    } else if (interditRemplacement === 1) {
      this.produitListRemplacement = [];
    } else {
      this.produitListInterdit = [];
      this.produitListRemplacement = [];
    }

    let urlParams = `?`;

    if ($event.first === 0) {
      urlParams += `page=0`;
    } else {
      const page = $event.first / $event.rows;
      urlParams += `page=${page}`;
    }

    urlParams += `&size=${$event.rows}`;


    const ssWrapper = new SearchSupplierWrapper();

    ssWrapper.filtersMap['fabrique'] = new SearchSupplier('0');

    if ($event.filters) {

      let filterDenree;

      if (interditRemplacement === 0) {
        filterDenree = $event.filters['denreeInterdite'];
      } else if (interditRemplacement === 1) {
        filterDenree = $event.filters['denreeRemplacement'];
      } else {
        filterDenree = $event.filters['denree'];
      }

      if (!this.utils.isNullOrEmpty(filterDenree)) {
        ssWrapper.filtersMap['denree'] = new SearchSupplier(filterDenree.value);
      }

    }

    const produits$ = this.produitDeclinaisonSvc.getProduitDeclinaisonByTypeFabrique(ssWrapper, urlParams);

    produits$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {

      this.loadingData = false;

      if (interditRemplacement === 0) {
        this.produitListInterdit = response.resultList;
      } else if (interditRemplacement === 1) {
        this.produitListRemplacement = response.resultList;
      } else {
        this.produitListInterdit = response.resultList;
        this.produitListRemplacement = response.resultList;
      }

      this.totalProduits = response.totalElements;

    });
  }

  closeDialog() {
    this.displayDialog = false;
  }

  startError(property: string) {
    return `Le champ "${property.toUpperCase()}" `;
  }

  ajouterDenreeDeSubstitution(){

    let upddiDTO: UniteDeProduction__denreeInterditeDto = new UniteDeProduction__denreeInterditeDto();
    upddiDTO.id = 0;
    upddiDTO.idUniteDeProduction = this.idUniteDeProduction;
    upddiDTO.denreeInterditeId = this.produitInterdit.id;
    upddiDTO.denreeInterditeLibelle = this.produitInterdit.libelle;
    upddiDTO.denreeSubstitutionId = this.produitRemplacement.id;
    upddiDTO.denreeSubstitutionLibelle = this.produitRemplacement.libelle;
    upddiDTO.ratio = this.pourcentageSubstitution;

    this.udpDenreeInterditeSvc.save(upddiDTO).subscribe(response=>{
      if (!this.utils.isNullOrEmpty(response.one)) {
        this.udpDenreeInterditeSvc.announceLoadDenreesByUdp();
        this.closeDialog();
      } else {
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.ERROR, `Sauvegarde impossible : veuillez vérifier que la denrée sélectionnée n'est pas déjà substituée`, '', 8000);
      }
    });
  }

  getMesureDenreeSubstitution() {
    if (this.pourcentageSubstitution === 0 ||
      this.produitInterdit.ratioUniteTechnique === 0 ||
      this.produitRemplacement.ratioUniteTechnique === 0) {
      return 0;
    } else {
      return (this.pourcentageSubstitution / 100 * (1 * this.produitInterdit.ratioUniteTechnique / this.produitRemplacement.ratioUniteTechnique));
    }
  }
}
