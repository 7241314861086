<div class="card mg-b-10 card-size" *ngIf="canDisplay()">
  <div class="card-header">

    <div class="d-flex">
      <div class="flex-grow-1">
        <span class="mg-r-5">Analyse des coûts pour la prestation</span>
        <strong>{{mp2Svc?.planningMenus?.contratMenuConviveList[0]?.contratMenuLibelle}}
          - {{mp2Svc?.planningMenus?.contratMenuConviveList[0]?.libelle}}</strong>
      </div>

      <div class="mg-r-5">
        <p-multiSelect
          [options]="mp2Svc.planningMenus.repasList"
          [(ngModel)]="selectedRepas"
          (onChange)="changeSelectedRepas($event)"
          optionLabel="libelle"
          [displaySelectedLabel]="true"
          maxSelectedLabels="7"
          [defaultLabel]="'Repas...'"
          [filterPlaceHolder]="'Repas...'"
          [style]="{'width':'250px'}"
          appendTo="body">
        </p-multiSelect>
      </div>
      <div class="mg-r-5">
        <p-multiSelect
          [options]="mp2Svc.planningMenus.contratMenuConviveList[0].contratMenuConvive__contrainteAlimList"
          [(ngModel)]="selectedRegimes"
          (onChange)="changeSelectedRegimes($event)"
          optionLabel="libelle"
          [displaySelectedLabel]="true"
          maxSelectedLabels="7"
          [defaultLabel]="'Régimes...'"
          [filterPlaceHolder]="'Régimes...'"
          [style]="{'width':'250px'}"
          appendTo="body">
        </p-multiSelect>
      </div>

      <div class="mg-r-5">
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="dd/mm/yy"
          tooltipPosition="bottom"
          showDelay="500"
          selectionMode="range"
          [numberOfMonths]="2"
          [readonlyInput]="true"
          [(ngModel)]="selectedPeriode"
          (onSelect)="changeSelectedPeriode($event)"
          [locale]="localeFr"
          [inputStyle]="{'width':'250px'}"
          [showIcon]="true"
          [showWeek]="true"
        >
        </p-calendar>
      </div>

    </div>
  </div>

  <div class="card-body">
    <div>
      <ng-container *ngIf="!utils.isNullOrEmpty(dataCoutsRepasPeriode)">
        <!-- [height]="220" -->
        <dx-chart
          (onPointClick)="selectData($event)"
          [dataSource]="dataCoutsRepasPeriode"
          title="Coût unitaire des repas"
        >
          <dxi-series color="grey" valueField="cout-theorique" name="Coût unitaire théorique"></dxi-series>
          <dxi-series color="blue" valueField="cout-unitaire-pondere-moyen" name="Coût unitaire pondéré moyen"></dxi-series>
          <dxi-series color="red" valueField="cout-unitaire-pondere" name="Coût unitaire pondéré"></dxi-series>
          <dxo-common-series-settings
            argumentField="date"
            type="spline"
          >
          </dxo-common-series-settings>
          <dxo-margin [bottom]="20"></dxo-margin>
          <dxo-common-axis-settings>
            <dxo-grid [visible]="true"></dxo-grid>
          </dxo-common-axis-settings>
          <dxo-legend verticalAlignment="top" horizontalAlignment="right"></dxo-legend>
          <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60">
            <dxo-label>
              <dxo-format type="decimal"></dxo-format>
            </dxo-label>
          </dxo-argument-axis>
          <dxo-export [enabled]="true"></dxo-export>
          <dxo-tooltip [enabled]="true"></dxo-tooltip>
        </dx-chart>
      </ng-container>
    </div>

    <div class="d-flex">
      <div *ngFor="let mapCelluleRepas of listMapDecoupagesRepas; trackBy : utils.trackByFn;" [style.width.px]="420"
           class="mg-r-15 card-repas animate">

        <ng-container *ngTemplateOutlet="tplHeader;context{firstValue:getFirstValue(mapCelluleRepas)}">

        </ng-container>


        <div [style.max-height.px]="670"  [style.overflow]="'scroll'">
          <ng-container *ngFor="let cellule of getValues(mapCelluleRepas); last as isLast;trackBy : utils.trackByFn; ">

            <table class="border-table2" >
              <tbody>
              <tr>
                <td>
                  <table>
                    <thead class="decoupageRepas">

                    <tr>
                      <th [style.width.px]="mp2Svc.drWidthLibelle">
                        {{cellule.libelleDecoupageRepas | uppercase}}
                      </th>
                      <th [style.width.px]="mp2Svc.drWidthTauxDePrise"
                          [style.text-align]="'right'">
                        <yo-taux-de-prise-decoupagerepas [cellule]="cellule"></yo-taux-de-prise-decoupagerepas>

                      </th>
                      <th [style.width.px]="mp2Svc.drWidthCoutsUnitaires"
                          [style.text-align]="'right'"
                          class="no-overflow">

                        <yo-cout-decoupage-portion [cellule]="cellule"></yo-cout-decoupage-portion>

                      </th>
                      <th
                        [style.width.px]="mp2Svc.drWidthCoutsUnitairesPonderes"
                        [style.text-align]="'right'"
                        class="no-overflow" >
                        <yo-cout-decoupage-pondere [cellule]="cellule"></yo-cout-decoupage-pondere>

                      </th>
                    </tr>
                    </thead>
                    <tbody class="font-12">

                    <ng-container *ngFor="let slot of mp2Svc.getSlots(cellule);trackBy : utils.trackByFn;">
                      <ng-container
                        *ngTemplateOutlet="tplRow;context:{cellule:cellule, menuComposition:mp2Svc.getMenuComposition(cellule,slot.idxSlot),ordre:slot.idxSlot}">
                      </ng-container>
                    </ng-container>


                    <ng-container *ngTemplateOutlet="tplRowCoutRepas;context:{isLast:isLast,cupr:getCoutUnitairePondereRepas(mapCelluleRepas), cutr:getCoutTheoriqueRepas(mapCelluleRepas)}">

                    </ng-container>


                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tplRowCoutRepas let-cupr="cupr" let-cutr="cutr" let-isLast="isLast">

  <tr *ngIf="isLast"
      [class.bg-checked]="cupr<=cutr"
      [class.bg-edit-color]="cupr>cutr"
      [style.height.px]="36"
  >
    <td >COÛT UNITAIRE PONDÉRÉ</td>
    <td></td>
    <td></td>
    <td  [style.text-align]="'right'">{{cupr | currency:'EUR':'symbol':'1.4-4'}}</td>
  </tr>


</ng-template>

<!--TEMPLATE ROW-->
<ng-template #tplRow let-menuComposition="menuComposition" let-ordre="ordre" let-cellule="cellule">

  <ng-container *ngIf="!utils.isNullOrEmpty(menuComposition)">
    <tr>
      <td class="no-overflow cursor" [style.width.px]="mp2Svc.drWidthLibelle" (click)="menuToolbarSvc.openInfosMenuCompo(menuComposition,cellule)">
          {{menuComposition.produitDeclinaisonLibelle}}
      </td>
      <td
        [style.width.px]="mp2Svc.drWidthTauxDePrise"
        [style.text-align]="'right'"
        class="no-overflow font-10">
        {{menuComposition.tauxDePrise | number:'1.2-2'}}%
      </td>
      <td
        [style.width.px]="mp2Svc.drWidthCoutsUnitaires"
        [style.text-align]="'right'"
        class="no-overflow">
        <yo-cout-portion
          [menuComposition]="menuComposition"></yo-cout-portion>
      </td>

      <td
        [style.width.px]="mp2Svc.drWidthCoutsUnitairesPonderes"
        [style.text-align]="'right'"
        class="no-overflow">
        <yo-cout-portion-pondere
          [menuCompo]="menuComposition"></yo-cout-portion-pondere>
      </td>
    </tr>

  </ng-container>

</ng-template>

<ng-template #tplHeader let-firstValue="firstValue">

      <table id="repasTable" class="border-table">
        <thead>
        <tr>
          <th>
            <strong>{{firstValue.libelleRepas | uppercase}}</strong> {{firstValue.dateMenu |  date:'EEEE d LLL yyyy' | titlecase}}
          </th>
        </tr>
        </thead>
      </table>
</ng-template>
