import {Component, OnDestroy, OnInit} from '@angular/core';
import {StocksService} from '../../../../../core/services/gestion-stock/stocks.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {catchError} from 'rxjs/operators';
import {StockDTO} from '../../../../../core/dtos/stock-dto';
import {FormGroup} from '@angular/forms';
import {GenericFormService} from '../../../../../core/services/generics/generic-form.service';
import {GROUP_POSITION, GroupFormFields} from '../../../../../core/suppliers/group-formfields';
import {FormOptions} from '../../../../../core/suppliers/form-options';
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {ProduitDeclinaisonService} from "../../../../../core/services/entities/produit-declinaison.service";
import {ModelViewMatierePremiere} from "../../model-view-matiere-premiere";

@Component({
  selector: 'yo-dialog-modification-lots',
  templateUrl: './dialog-modification-lots.component.html',
  styleUrls: ['./dialog-modification-lots.component.scss']
})
export class DialogModificationLotsComponent implements OnInit, OnDestroy {

  subAction: Subscription;
  matierePremiere: ModelViewMatierePremiere;
  lotSelected: StockDTO;
  displayDialog: boolean = false;
  form: FormGroup;
  formOptions: FormOptions = {width: this.utils.getMaxWidthBody()};
  labelSubmit: string = 'Modifier le lot';
  labelCancel: string = 'Annuler';
  formZonesStockage: FormGroup = new FormGroup({});
  formInfos: FormGroup = new FormGroup({});
  groups: GroupFormFields[] = [];

  constructor(private stocksSvc: StocksService,
              public utils: UtilsService,
              private gfs: GenericFormService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService) {
  }

  ngOnInit(): void {
    this.actionSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAction);
  }

  actionSubscription = (): void => {
    this.subAction = this.stocksSvc.openDialogEditLot$
      .pipe(catchError(err => this.utils.handleError(err)))
      .subscribe(response => {
        this.lotSelected = response.selectedStock;
        this.matierePremiere = response.matierePremiere;
        this.initForm();
        this.displayDialog = true;
      });
  };

  initForm = (): void => {
    const fieldsZoneStockage = this.stocksSvc.getFields(this.lotSelected);
    const fieldsZoneStockageInfoKey = ['unitesProductionZonesDeProduction'];
    const fieldsZoneStockageInfo = this.gfs.getFieldsByKey(fieldsZoneStockageInfoKey, fieldsZoneStockage);
    this.formZonesStockage = this.gfs.toFormGroup(fieldsZoneStockageInfo);

    const fieldsInfosInfoKey = ['quantite', 'prixUnitaire', 'dateEntree'];
    const fieldsInfosInfo = this.gfs.getFieldsByKey(fieldsInfosInfoKey, fieldsZoneStockage);
    this.formInfos = this.gfs.toFormGroup(fieldsInfosInfo);


    this.form = new FormGroup({
      formZonesStockage: this.formZonesStockage,
      formInfos: this.formInfos
    });

    this.groups = [
      {
        titlePanel: 'Zones de stockage',
        fields: fieldsZoneStockageInfo,
        form: this.formZonesStockage,
        titleIcon: 'fas fa-industry',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsZoneStockageInfoKey,
        }
      },
      {
        titlePanel: 'Informations sur le lot',
        fields: fieldsInfosInfo,
        form: this.formInfos,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsInfosInfoKey
        }
      }
    ];
  };

  updateLot = (): void => {
    this.gfs.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.lotSelected.modificationManuelle = true;
      this.lotSelected.idUdpZoneDeStockage = this.form.controls['formZonesStockage']['controls']
        ['unitesProductionZonesDeProduction'].value.id;
      this.lotSelected.dateEntree = this.form.controls['formInfos']['controls']['dateEntree'].value;
      this.lotSelected.prixUnitaire = this.form.controls['formInfos']['controls']['prixUnitaire'].value;
      this.lotSelected.quantite = this.form.controls['formInfos']['controls']['quantite'].value;

      this.stocksSvc.updateStock(this.lotSelected).subscribe(response => {
        if (!this.utils.isNullOrEmpty(response)) {
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, ` Le lot a été mis à jour avec succès.`);
          this.stocksSvc.announceLoadStocks(this.matierePremiere);
          this.produitDeclinaisonSvc.announcePaginationSearchStock({page: 0, size: 50});
          this.closeDialog();
        }
      });
    }
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

}
