import {Component, OnDestroy, OnInit} from "@angular/core";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";
import {ConfirmationService} from "primeng/api";
import {UtilsService} from "../../../../../core/utils/utils.service";
import ModeleConditionnementPlcDTO from "../../../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {combineLatest, Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {PointDeLivraisonDTO} from "../../../../../core/dtos/point-de-livraison-d-t-o";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../../core/services/routemap.service";
import {ClientsBusinessService} from "../../../../../core/services/gestion-clients/clients.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {PointDeLivraisonService} from "../../../../../core/services/entities/point-de-livraison.service";

@Component({
  selector: 'yo-point-de-livraison-conditionnement-liaisons',
  templateUrl: './liaisons.component.html',
  styleUrls: ['./liaisons.component.scss']
})
export class LiaisonsComponent implements OnInit, OnDestroy {

  allMode: string = 'allPages';
  checkBoxesMode: string = 'always';
  selectedRows: number[] = [];

  associationsList: ModeleConditionnementPlcDTO[] = [];
  subscriptionRoute: Subscription;
  subscriptionRights: Subscription;
  subscriptionAssociationSaved: Subscription;

  hasGestionClients = false;
  hasGestionIDistri = false;

  plc: PointDeLivraisonDTO;

  pathFile: String = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-liaisons-plc';

  constructor(public utils: UtilsService,
              public auth2Svc:Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private clientsSvc: ClientsBusinessService,
              private gds: GenericDatagridService,
              private pdlSvc: PointDeLivraisonService,
              private confirmationSvc: ConfirmationService){}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionRights);
    this.utils.unsubscribe(this.subscriptionAssociationSaved)
  }

  ngOnInit(): void {
    this.initRouteSubscription();
    this.initRightsSubscription();
    this.initAssociationSaved();
  }

  initRouteSubscription = (): void => {
    this.subscriptionRoute = this.route.parent.parent.data
      .subscribe((data) => {
        const pointDeLivraison: PointDeLivraisonDTO = data.plcSupplier.pointDeLivraison;
        this.plc = pointDeLivraison;
        this.associationsList = [this.plc.modeleConditionnementPlc];
      });
  };

  initRightsSubscription = (): void => {
    const all$ = combineLatest([this.auth2Svc.hasGestionClients$, this.auth2Svc.hasGestionIDistri$]);
    this.subscriptionRights = all$.subscribe(response => {
      this.hasGestionClients = response[0];
      this.hasGestionIDistri = response[1];
    });
  };

  initAssociationSaved = (): void => {
    this.subscriptionAssociationSaved = this.clientsSvc.announceAnnounceLiaisonPlc_McPlcSaved$
      .subscribe(r => {
        this.gds.getOne(this.pdlSvc.getEntityName(), this.plc.id)
          .subscribe(plc => {
            this.associationsList = [plc.one.modeleConditionnementPlc];
          });
      });
  };

  openEdit = (): void => {
    this.clientsSvc.announceOpenDialogLiaisonPlc_McPlc(this.plc);
  };

  openModel = (model: ModeleConditionnementPlcDTO): void => {
    this.routeMapSvc.goToSecondaryRoute(['gestion-conditionnements-modele-plc', model.id, 'ficheidentite']);
  };

  canEdit = (): boolean => this.hasGestionClients && this.hasGestionIDistri;

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        this.clientsSvc.delete(this.plc.id)
          .subscribe(r => {
            this.associationsList = [];
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Association supprimée avec succès.');
          });
      }
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les modèles points de livraison`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };
    return dms;
  };

}
