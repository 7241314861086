import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ZoneDeProductionDTO} from '../../../../core/dtos/zone-de-production-dto';
import {EquipeDTO} from '../../../../core/dtos/equipe-dto';
import {ZonesDeProductionService} from '../../../../core/services/entities/zones-de-production.service';
import {UnitesDeProductionService} from '../../../../core/services/entities/unites-de-production.service';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {TacheDTO} from '../../../../core/dtos/tache-dto';
import {EquipesService} from '../../../../core/services/entities/equipes.service';
import {TachesService} from '../../../../core/services/entities/taches.service';
import {MZoneProduction} from '../../../../core/models/gestion-unites-production/m-zone-production';

@Injectable()
export class UpParametrageResolverService implements Resolve<UpEquipesSupplier> {

  constructor(private zonesDeProductionService: ZonesDeProductionService,
              private unitesDeProductionService: UnitesDeProductionService,
              private equipesService: EquipesService,
              private tacheService: TachesService) {
  }

  // tslint:disable-next-line:max-line-length
  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UpEquipesSupplier> | Promise<UpEquipesSupplier> | UpEquipesSupplier => {
    const idUniteDeProduction: number = +route.parent.parent.paramMap.get('idUniteDeProduction');
    const uniteDeProduction$ = this.unitesDeProductionService.getUniteDeProductionById(idUniteDeProduction);
    const zoneDeProdList$ = this.zonesDeProductionService.getAll();
    const equipes$ = this.equipesService.getAll();
    const taches$ = this.tacheService.getAll();
    const tachesConfigureesByZonesProductionEquipes$ = this.tacheService.findTachesByZoneProductionAndEquipes(idUniteDeProduction);

    const all$ = combineLatest([uniteDeProduction$, zoneDeProdList$, equipes$, taches$, tachesConfigureesByZonesProductionEquipes$]);
    const result$ = all$.pipe(
      map(results => {
        const uniteDeProduction: UniteDeProductionDTO = results[0].one;
        const ateliers: ZoneDeProductionDTO[] = results[1].resultList;
        const equipes: EquipeDTO[] = results[2].resultList;
        const taches: TacheDTO[] = results[3].resultList;
        const tachesConfigurees: MZoneProduction[] = results[4].resultList;
        return new UpEquipesSupplier(uniteDeProduction, ateliers, equipes, taches, tachesConfigurees);
      }));
    return result$;
  };

}

export class UpEquipesSupplier {

  // Pour remplir le formulaire :
  uniteDeProduction: UniteDeProductionDTO;
  ateliers: ZoneDeProductionDTO[];
  equipes: EquipeDTO[];
  taches: TacheDTO[];

  // Pour préselectionner les bons éléments (article -> equipes -> tâches) avec des Modèles spéciaux :
  tachesConfigurees: MZoneProduction[];

  constructor(uniteDeProduction: UniteDeProductionDTO,
              ateliers: ZoneDeProductionDTO[],
              equipes: EquipeDTO[],
              taches: TacheDTO[],
              tachesConfigurees: MZoneProduction[]) {
    this.uniteDeProduction = uniteDeProduction;
    this.ateliers = ateliers;
    this.equipes = equipes;
    this.taches = taches;
    this.tachesConfigurees = tachesConfigurees;
  }

}
