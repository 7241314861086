import {Component, OnDestroy, OnInit} from '@angular/core';
import VarianteDTO from '../../../../core/dtos/conditionnement/variante-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {VarianteSavedSupplier, VariantesService} from '../../../../core/services/conditionnements/variantes.service';

import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {GenericManageDialogService} from '../../../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {ConfirmationService} from "primeng/api";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";

@Component({
  selector: 'yo-variantes',
  templateUrl: './variantes.component.html',
  styleUrls: ['./variantes.component.scss']
})
export class VariantesComponent implements OnInit, OnDestroy {

  variantesList: VarianteDTO[] = [];

  hasIDistri = false;

  subVarianteSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/ref-variantes';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private confirmationSvc: ConfirmationService,
              private variantesSvc: VariantesService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always'
  }

  ngOnInit(): void {
    this.initVariantes();
    this.initHasGestionIDistri();
    this.varianteSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subVarianteSaved);
  }

  initVariantes = (): void => {
    this.variantesSvc.getAll()
      .subscribe(response => {
        this.variantesList = response.resultList;
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canEdit = (): boolean => this.auth2Svc.hasSitesLocaux() && this.hasIDistri;

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        const variantesIdsToDelete: number[] = this.selectedRows
          .map((idSelected: number) => this.variantesList.find(v => v.id === idSelected))
          .map((variante: VarianteDTO) => variante.id);
        this.variantesSvc.deleteByIds(variantesIdsToDelete)
          .subscribe(o => {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,  `La suppression a bien été prise en compte`);
            this.variantesList = this.variantesList.filter(v => !variantesIdsToDelete.includes(v.id));
          });
      }
    });
  };

  varianteSavedSubscription = (): void => {
    this.subVarianteSaved = this.variantesSvc.varianteSaved$
      .subscribe((response: VarianteSavedSupplier) => {
        if (!response.isUpdate) {
          this.variantesList.push(response.variante);
        }
      });
  };

  openVariante = (v: VarianteDTO): void => {
    this.variantesSvc.announceOpenDialogVariante(v);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les variantes`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
