import {NgModule} from '@angular/core';
import {GestionMarcheRoutingModule} from './gestion-marche-routing.module';
import {MarcheGridComponent} from './marche-grid/marche-grid.component';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MarcheComponent} from './marche/marche.component';
import {FicheIdentiteMarcheComponent} from './marche/fiche-identite-marche/fiche-identite-marche.component';
import {LotsMarcheComponent} from './marche/lots-marche/lots-marche.component';
import {DialogLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/dialog-lot-marche.component';
import {DialogInfoLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/info/dialog-info-lot-marche.component';
import {DialogContextLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/context/dialog-context-lot-marche.component';
import {DxDataGridModule} from 'devextreme-angular';
import {DxoPagerModule} from 'devextreme-angular/ui/nested';
import {GestionLotMarcheService} from '../core/services/gestion-marche/gestion-lot-marche.service';
import {GestionsMarcheService} from '../core/services/gestion-marche/gestions-marche.service';
import {SearchDenreeComponent} from './marche/lots-marche/dialog-lot-marche/context/search-denree/search-denree.component';
import {DialogArticlesLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/articles/dialog-articles-lot-marche.component';
import {DialogPriorisationFournisseurComponent} from './marche/lots-marche/dialog-lot-marche/context/priorisation-fournisseur/dialog-priorisation-fournisseur.component';
import {DialogEngagementLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/engagement/dialog-engagement-lot-marche.component';
import {TableauDeBordComponent} from './marche/tableau-de-bord/tableau-de-bord.component';
import {NgxGaugeModule} from 'ngx-gauge';
import {DialogIncoherenceLotMarcheComponent} from './marche/lots-marche/dialog-lot-marche/incoherence/dialog-incoherence-lot-marche.component';
import {SearchArticlesComponent} from './marche/lots-marche/dialog-lot-marche/articles/search-articles/search-articles.component';
import {ButtonModule} from 'primeng/button';
import {ChipsModule} from 'primeng/chips';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {TooltipModule} from 'primeng/tooltip';
import {TabMenuModule} from 'primeng/tabmenu';
import {ToastModule} from 'primeng/toast';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';
import {TreeTableModule} from 'primeng/treetable';
import {ProgressBarModule} from 'primeng/progressbar';
import {MenuModule} from "primeng/menu";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    MarcheGridComponent,
    MarcheComponent,
    FicheIdentiteMarcheComponent,
    LotsMarcheComponent,
    DialogLotMarcheComponent,
    DialogInfoLotMarcheComponent,
    DialogContextLotMarcheComponent,
    SearchDenreeComponent,
    DialogArticlesLotMarcheComponent,
    DialogPriorisationFournisseurComponent,
    DialogEngagementLotMarcheComponent,
    DialogIncoherenceLotMarcheComponent,
    TableauDeBordComponent,
    SearchArticlesComponent
  ],
  imports: [
    CommonModule,
    GestionMarcheRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ButtonModule,
    CardModule,
    ChipsModule,
    DialogModule,
    PanelModule,
    TooltipModule,
    TabMenuModule,
    ToastModule,
    AutoCompleteModule,
    CalendarModule,
    TreeTableModule,
    ProgressBarModule,
    AccordionModule,
    MenuModule,
    DropdownModule,

    DxDataGridModule,
    DxoPagerModule,

    NgxGaugeModule,

  ],
  providers: [
    GestionsMarcheService,
    GestionLotMarcheService
  ]
})
export class GestionMarcheModule {

}


