import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {ProduitDTO} from '../../../../core/dtos/produit-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {FP_ROUTES} from '../../../../core/constants';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {DxDataGridComponent} from 'devextreme-angular';
import {CataloguesAchatsService, PROVENANCE} from '../../../../core/services/entities/catalogues-achats.service';
import {UnitesDeProductionService} from '../../../../core/services/entities/unites-de-production.service';
import {Subscription} from 'rxjs';
import {catchError, debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {ArticleSupplier} from '../catalogue-achat-resolver.service';
import {FormControl, FormGroup} from '@angular/forms';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {ProduitsService} from '../../../../core/services/entities/produits.service';
import {find as _find} from 'lodash';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../../core/suppliers/search-supplier';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
  selector: 'yo-creer-article',
  templateUrl: './creer-article.component.html',
  styleUrls: ['./creer-article.component.scss']
})
export class CreerArticleComponent implements OnInit, OnDestroy {

  subOpenMajCreateArticle: Subscription;
  subSavedCatalogueAchat: Subscription;
  articleSupplier: ArticleSupplier;
  produitList: ProduitDTO[];
  selectedRowKeysPd = [];
  formSearch: FormGroup = new FormGroup({});

  subFormSearch: Subscription;
  resultProduitSize: number = 100;

  @ViewChild('opCreateArticle') opCreateArticle: OverlayPanel;
  @ViewChild('gridProduit') gridProduit: DxDataGridComponent;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(public utils: UtilsService,
              private udpSvc: UnitesDeProductionService,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              private produitSvc: ProduitsService,
              private catalogueAchatSvc: CataloguesAchatsService) {
  }

  ngOnInit(): void {
    //  Preparer l'affichage de la selection udp et produit avant la création article
    this.initSubscription()
  }


  initForm = () => {
    this.formSearch = new FormGroup({
      produitCtl: new FormControl('')
    });

    // s'abonner aux changements de valeurs*
    this.formSearchSubscription();

    // mettre le focus sur le champ de recherche
    this.utils.focus('searchInput',30,100);
  };




  /**
   * s'abonner aux changements de valeurs
   */
  formSearchSubscription = () => {
    this.utils.unsubscribe(this.subFormSearch);
    this.subFormSearch = this.formSearch.valueChanges.pipe(
      debounceTime(300),
      tap(data => {

        if (data.produitCtl.length === 0) {
          this.produitList = [];
          this.articleSupplier.selectedProduit = null;
          this.selectedRowKeysPd = [];
        }
      }),
      filter(data => data.produitCtl.length > 0),

      switchMap(data => {
        const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
        ssw.filtersMap['produit'] = new SearchSupplier(data.produitCtl);
        ssw.filtersMap['sites'] = new SearchSupplier(undefined, this.auth2Svc.utilisateur.sites.map(item => item.id));
        // ne prendre que les denrees et pas les plats
        ssw.filtersMap['isFabrique'] = new SearchSupplier(false);
        return this.produitSvc.search(`page=0&size=${this.resultProduitSize}`, ssw);
      }),

    ).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.produitList = response.resultList;
      }
    });
  };

  /**
   * Preparer l'affichage de la selection udp et produit avant la création article
   */
  initSubscription = () => {

    this.subOpenMajCreateArticle = this.catalogueAchatSvc.openMajCreateArticle$.pipe(
      map(data => {
        this.articleSupplier = data;
        this.selectedRowKeysPd = [];
        this.articleSupplier.selectedProduit = null;

        // afficher le panel de selection de produit
        this.opCreateArticle.toggle(this.articleSupplier.event);


        // initialiser le formulaire de recherche
        this.initForm();
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe();
  };


  ngOnDestroy = (): void => {
    this.utils.unsubscribe(this.subOpenMajCreateArticle);
    this.utils.unsubscribe(this.subFormSearch);
    this.utils.unsubscribe(this.subSavedCatalogueAchat);
  };

  addArticle = (selectedProduit: ProduitDTO, selectedUdp: UniteDeProductionDTO) => {

    let catalogueAchat: CatalogueAchatDTO;
    const route = 'infos';
    let isNew = true;

    // tap(data => this.router.navigate([`${FP_ROUTES.GFO}`, 'articles','article-affecte', data.produit.id, data.route])),

    // on demande l'ouverture du dialogue
    this.catalogueAchatSvc.announceOpenDialogArticle({
      catalogueAchat,
      produit: selectedProduit,
      uniteDeProduction: selectedUdp,
      route,
      rootRoute: [`${FP_ROUTES.GFO}`, 'articles', 'article-affecte', selectedProduit.id, route],
      isNew,
      bodyHeight: 1000,
      routeProvenance: PROVENANCE.FOURNISSEUR
    });


  };

  rowSelected = (event: number[]) => {
    if (!this.utils.isCollectionNullOrEmpty(event)) {
      this.articleSupplier.selectedProduit = _find(this.produitList, {'id': event[0]});
    }
  };
}

