import {Injectable} from "@angular/core";
import {UnitePmsDto} from "../../dtos/pms/unite-pms-dto";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpParams} from "@angular/common/http";

export const URL_GET_ALL = 'dolrest/unites-pms/all';
export const URL_SAVE = 'dolrest/unites-pms/';
export const URL_DELETE = 'dolrest/unites-pms/';

@Injectable({
  providedIn: 'root'
})
export class UnitePmsService {
  private subjectOpenDialog = new Subject<UnitePmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectUniteSaved = new Subject<UnitePmsSavedSupplier>();
  uniteSaved$ = this.subjectUniteSaved.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupère toutes les unités pms
   * @returns {Observable<UnitePmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<UnitePmsDto>> => {
    return this.httpSvc.get(URL_GET_ALL, null);
  }

  /**
   * Sauvegarde une unité pms
   * @param unite
   */
  save = (unite: UnitePmsDto) : Observable<ResponseWrapper<UnitePmsDto>> => {
    return this.httpSvc.post(URL_SAVE, unite);
  }

  /**
   * Supprime une ou plusieurs unités(s) pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: UnitePmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (unite: UnitePmsDto, isUpdate: boolean = false) : void => {
    this.subjectUniteSaved.next({ isUpdate, unite } as UnitePmsSavedSupplier);
  }
}

export interface UnitePmsSavedSupplier {
  isUpdate: boolean;
  unite: UnitePmsDto;
}
