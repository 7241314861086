import {ObjectDTO} from "./object-dto";

export class MenuConviveDeclinaisonDTO extends ObjectDTO{

  // declinaison
  idDeclinaison: number;
  declinaisonLibelle : string;

  // contrat menu convive
  idContratMenuConvive: number;
  contratMenuConviveLibelle : string;

}
