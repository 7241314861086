import {Component, Input, OnInit} from '@angular/core';
import {DialogMsgSupplier} from '../../../core/suppliers/dialog-msg-supplier';
import {UtilsService} from '../../../core/utils/utils.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'yo-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  displayDialog=false;
  dms:DialogMsgSupplier;

  @Input() lang: string = 'fr';
  @Input() file: string = 'blank';
  @Input() dialogMsgSupplier: DialogMsgSupplier;
  @Input() width: number = 450;
  @Input () set iconClick(value:string){
    this._iconClick=value;
  }

  _iconClick = 'fa fa-question-circle yoni-color';

  constructor(public utils: UtilsService, public domSanitizer:DomSanitizer) {}

  ngOnInit() {}

  initDms = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Aide`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    return dms;
  };

  getHelp: () => void = () => {
    this.dms = this.utils.isNullOrEmpty(this.dialogMsgSupplier) ? this.initDms() : this.dialogMsgSupplier;
    this.dms.logo=`fas fa-question-circle fa-2x  yoni-color`;
    this.displayDialog=true;
  };

  closeDialog = (): void => {
    this.displayDialog=false;
  };


}
