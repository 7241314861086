<div class="row">
  <div class="col-md-12 text-right">
    <yo-button-save (onClick)="save()" [yoDisabled]="!gds.canModify(planAlimentaire)"></yo-button-save>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" novalidate>
      <ng-container *ngFor="let field of fieldsPlanAlimentaire">

        <ng-container [ngSwitch]="field.key">

          <ng-container *ngSwitchCase="'typeCycle'">

            <!--template cycle , prend encompte le type cycle et le nombre d'items par cycle-->
            <ng-container
              *ngTemplateOutlet="tplCycle;context:{form:form,field:field,fields:fieldsPlanAlimentaire,planAlimentaire:planAlimentaire}">
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <yo-dynamic-form-field-bs [fields]="fieldsPlanAlimentaire"
                                      [field]="field"
                                      [form]="form"></yo-dynamic-form-field-bs>
          </ng-container>


        </ng-container>


      </ng-container>
    </form>
  </div>
</div>
<div class="row">
  <div class="col-md-12 font-12">
    <!--<p>-->
    <!--<i class="fas fa-exclamation-triangle danger-color mg-r-5"></i><em> Le cycle peut être en nombre de repas-->
    <!--consécutifs ou en semaine.</em>-->
    <!--</p>-->
    <p>
      🗣️<em> N'oubliez pas d'enregistrer.</em>
    </p>
  </div>
</div>


<!--TEMPLATE CYCLE-->
<ng-template #tplCycle let-planAlimentaire="planAlimentaire" let-field="field" let-fields="fields" let-form="form">
  <div [formGroup]="form">
    <div class="form-group row">
      <label for="typeCycle"
             [class.requiredControl]="true"
             class="genlabel col-sm-2 col-form-label"
             pTooltip=""
             showDelay="500">CYCLE</label>
      <div class="col-sm-10">
        <div class="input-group input-group-sm ">
          <input type="number"
                 pKeyFilter="pint"
                 class="form-control form-control-sm typeCycle"
                 formControlName="nbItemsParCycle">
          <div class="input-group-append ">
            <span class="input-group-text">semaine(s)</span>
          </div>
        </div>
        <!--<select-->
        <!--formControlName="typeCycle"-->
        <!--class="form-control form-control-sm nbItemsParCycle"-->
        <!--id="typeCycle">-->
        <!--&lt;!&ndash;<option [value]="1"> repas consécutif(s)</option>&ndash;&gt;-->
        <!--<option [value]="2"> semaine(s)</option>-->
        <!--</select>-->
      </div>
    </div>
  </div>

</ng-template>
