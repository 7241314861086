import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TachePmsDto} from "../../../../core/dtos/pms/tache-pms-dto";
import {TypeTachePmsDto} from "../../../../core/dtos/pms/type-tache-pms-dto";
import {TypeEquipementPmsDto} from "../../../../core/dtos/pms/type-equipement-pms-dto";
import {TachePmsService} from "../../../../core/services/pms/tache-pms.service";
import {TypeTachePmsService} from "../../../../core/services/pms/type-tache-pms.service";
import {TypeEquipementPmsService} from "../../../../core/services/pms/type-equipement-pms.service";
import {DomSanitizer} from "@angular/platform-browser";
import json from "../../../../../assets/logo/logos.json";
import {ImagePmsDto} from "../../../../core/dtos/pms/image-pms-dto";
import {MotifNonConformiteDTO} from "../../../../core/dtos/motif-non-conformite-dto";
import {MotifNonConformiteService} from "../../../../core/services/conformite/motif-non-conformite.service";
import {TypeUtilisateurPmsService} from "../../../../core/services/pms/type-utilisateur-pms.service";
import {TypeUtilisateurPmsDto} from "../../../../core/dtos/pms/type-utilisateur-pms-dto";
import {OrdreTachePmsDto} from "../../../../core/dtos/pms/ordre-tache-pms-dto";

@Component({
  selector: 'yo-pms-tache-dialog',
  templateUrl: './pms-tache-dialog.component.html',
  styleUrls: ['./pms-tache-dialog.component.scss']
})
export class PmsTacheDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  /**
   * Tâche sélectionnée à modifier ou à créer
   */
  tache: TachePmsDto;

  /**
   * Listes des types de tâche
   */
  typesTacheList: TypeTachePmsDto[];

  /**
   * Liste des types d'équipement
   */
  typesEquipement: TypeEquipementPmsDto[];

  /**
   * Liste des types d'équipement déjà sélectionnés en cas de modification
   */
  typesEquipementSelected: TypeUtilisateurPmsDto[];

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'une tâche';

  /**
   * Déclaration du chemin de l'icône à afficher
   */
  iconPath: ImagePmsDto;

  /**
   * Déclaratione et implémentation de nos données stockées sous format JSON
   */
  jsonFile: any = json;

  /**
   * Liste des icones
   */
  imagesPath: ImagePmsDto[] = [];

  /**
   * Liste de tous les motifs de non conformité
   */
  motifs: MotifNonConformiteDTO[];

  /**
   * Liste des motifs de non conformité déjà sélectionné en cas de modification
   */
  motifsSelected: MotifNonConformiteDTO[];

  /**
   * Liste de tous les types d'utilisateur
   */
  typesUtilisateur: TypeUtilisateurPmsDto[];

  /**
   * Liste des types d'utilisateur déjà sélectionné en cas de modification
   */
  typesUtilisateurSelected: TypeUtilisateurPmsDto[];

  /**
   * Liste des ordres disponibles pour placer la nouvelle tâche
   */
  orders: OrdreTachePmsDto[];

  /**
   * Ordre sélectionné
   */
  orderSelected: OrdreTachePmsDto;
  initOrder: OrdreTachePmsDto;
  maxOrder: number;

  /**
   * Couleur sélectionné
   */
  couleurSelected: string;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private tachePmsSvc: TachePmsService,
              private typeTachePmsSvc: TypeTachePmsService,
              private typeEquipementPmsSvc: TypeEquipementPmsService,
              private typeUtilisateurPmsSvc: TypeUtilisateurPmsService,
              private motifNonConformitePmsSvc: MotifNonConformiteService,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initIconsPath();
    this.initAllOrders();
    this.initTypesTachePms();
    this.initTypesEquipementPms();
    this.initTypesUtilisateurPms();
    this.initMotifsNonConformitePms();
    this.initHasPms();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  /**
   * Initialisation des icônes possibles
   */
  initIconsPath = (): void => {
    for(const icon of this.jsonFile.icons) {
      this.imagesPath.push({
        path: `${this.utils.getIconPath(icon + '.png')}`,
        value: icon + '.png'
      });
    }
  };

  /**
   * Initialisation des ordres possibles pour cette nouvelle tâche
   */
  initAllOrders = (): void => {
    this.orders = [];

    this.tachePmsSvc.getAllOrders()
      .subscribe(response => {
        for (let i = 1; i <= response.resultList.length; i++) {
          this.orders.push({
            value: i
          })
        }

        this.maxOrder = response.resultList.reduce((a, b)=> Math.max(a, b)) + 1;
        this.orders.push({
          value: this.maxOrder
        });
      })
  };

  /**
   * Initialisation des types de tâche possible
   */
  initTypesTachePms = (): void => {
    this.typeTachePmsSvc.getAll()
      .subscribe(response => {
        this.typesTacheList = response.resultList;
      });
  };

  /**
   * Initialisation des types d'équipement possible
   */
  initTypesEquipementPms = (): void => {
    this.typesEquipement = [];
    this.typesEquipementSelected = [];

    this.typeEquipementPmsSvc.getAll()
      .subscribe(response => {
        this.typesEquipement = response.resultList;

        if(this.forUpdate) {
          this.typesEquipementSelected = this.tache.typesEquipement;
          this.form.get('typesEquipement').patchValue(this.typesEquipementSelected);
        }
      });
  };

  /**
   * Initialisation des types d'utilisateur possible
   */
  initTypesUtilisateurPms = (): void => {
    this.typesUtilisateur = [];
    this.typesUtilisateurSelected = [];

    //Récupération de tous les types d'utilisateur existants
    this.typeUtilisateurPmsSvc.getAll()
      .subscribe(response => {

        //Types d'utilisateur que l'on va afficher
        this.typesUtilisateur = response.resultList;

        //Types d'utilisateur déjà sélectionnés (uniquement en cas de modification)
        if(this.forUpdate) {
          this.typesUtilisateurSelected = this.tache.typesUtilisateur;
          this.form.get('typesUtilisateur').patchValue(this.typesUtilisateurSelected);
        }
      })
  };

  /**
   * Initialisation des motifs de non conformité possible
   */
  initMotifsNonConformitePms = (): void => {
    this.motifsSelected = [];
    this.motifs = [];

    //Récupération de tous les motifs de non conformité existants
    this.motifNonConformitePmsSvc.getAll()
      .subscribe(response => {

        //Equipements que l'on va afficher
        this.motifs = response.resultList;

        //Motifs déjà sélectionnés (uniquement en cas de modification)
        if(this.forUpdate) {
          this.motifsSelected = this.tache.motifsNonConformite;
          this.form.get('motifsNonConformite').patchValue(this.motifsSelected);
        }
      });
  };

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.tachePmsSvc.openDialog$
      .subscribe((t: TachePmsDto) => {
        this.displayDialog = true;
        if (!t) {
          this.forUpdate = false;
          this.tache = new TachePmsDto();
          this.tache.id = null;
          this.dialogTitle = 'Création d\'une tâche';
        } else {
          this.forUpdate = true;

          this.iconPath = new ImagePmsDto();
          this.iconPath.value = t.icone;
          this.iconPath.path = this.utils.getIconPath(t.icone.split(".")[0] + '.png');

          this.orderSelected = new OrdreTachePmsDto();
          this.initOrder = new OrdreTachePmsDto();
          this.orderSelected.value = t.ordre;
          this.initOrder.value = t.ordre;
          this.orders.sort((a: any, b: any) => a.value - b.value);

          this.typesUtilisateurSelected = t.typesUtilisateur;
          this.typesEquipementSelected = t.typesEquipement;

          this.tache = t;
          this.dialogTitle = 'Modification d\'une tâche';
        }
        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.iconPath = null;
  };

  canModify = (): boolean => this.tache && this.hasPms;

  /**
   * Méthode pour valider le formulaire
   */
  save = (): void => {
    if (this.form.valid) {
      this.tache.libelle = this.form.controls['libelle'].value;
      this.tache.couleur = this.form.controls['couleur'].value;
      this.tache.icone = this.iconPath.value;
      this.tache.typeTache = this.form.controls['typeTache'].value;
      this.tache.typesEquipement = this.typesEquipementSelected;
      this.tache.typesUtilisateur = this.typesUtilisateurSelected;
      this.tache.motifsNonConformite = this.motifsSelected;
      this.tache.ordre = this.orderSelected.value;

      this.tachePmsSvc.save(this.tache, this.initOrder ? this.initOrder.value : this.maxOrder)
        .subscribe(response => {
          this.tachePmsSvc.announceSaved(response.one, this.tache.id !== undefined && this.tache.id !== null && this.tache.id !== 0);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde de la tâche pms réalisée avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  /**
   * Initialisation du formulaire
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.tache ? this.tache.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
      couleur: new FormControl(this.tache ? this.tache.couleur : '', [Validators.required]),
      icone: new FormControl(this.tache ? this.iconPath : '', null),
      typeTache: new FormControl(this.tache ? this.tache.typeTache : null, [Validators.required]),
      typesEquipement: new FormControl(this.tache ? this.tache.typesEquipement : null),
      typesUtilisateur: new FormControl(this.tache ? this.tache.typesUtilisateur : null, [Validators.required]),
      motifsNonConformite: new FormControl(this.tache ? this.tache.motifsNonConformite : null),
      ordre: new FormControl(this.tache ? this.tache.ordre : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Tâches (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
