<div class="size-input-group d-flex align-items-center justify-content-between mg-b-10" *ngIf="!yoInputHidden">

  <label>{{yoLabel}} <span *ngIf="yoRequired" class="danger-color">*</span> : </label>


    <input
      [disabled]="yoDisabled"
      class="size-input"
      [required]="yoRequired"
      [(ngModel)]="value"
      [pTooltip]="yoLabelTooltip"
      showDelay="1500"
      readonly
      onfocus="removeAttribute('readonly')"
      pInputText/>

</div>
