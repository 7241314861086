import {ObjectDTO} from './object-dto';
import {ReceptionStatutDTO} from './reception-statut-dto';

export class BonReceptionDTO extends ObjectDTO {

  numeroBonReception: string;
  numeroBonLivraison: string;
  dateLivraison: Date;
  temperatureCamion: number;
  livreur: string;
  immatriculation: string;

  // bon commande fournisseur
  idBonCf: number;
  numeroCommandeBonCf: string;

  // statut du bon de reception
  receptionStatut: ReceptionStatutDTO;

  // unite de production
  idUniteDeProduction: number;
  libelleUniteDeProduction: string;

  // transient a un litige ou une non conformite
  hasMncOrLitige: boolean;

  //site
  siteId: number;

  //frais de port
  francoDePortTva: number;
  francoDePort: number;
  fournisseurFdpMontant: number;
  fournisseurFdpPoids: number;
  fournisseurFdpCout: number;
  fournisseurFdpTva: number;
  booleanfournisseurFdpActif: boolean;

}
