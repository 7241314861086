<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  (onHide)="closeDialog(false)"
  [style]="{'width':'590px',  'max-height': '700px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-lg fa-edit mg-r-5"></i>{{title}}
    </div>
  </ng-template>
  <yo-form [form]="form" [groups]="groups" [options]="formOptions">
  </yo-form>
  <hr>
  <div class="d-flex justify-content-end">
    <!-- Bouton Enregistrer -->
    <span class="mg-r-5">
        <button pButton icon="fas fa-save"
                [disabled]="!form?.valid"
                (click)="saveContratMenu()"
                type="button">
        </button>
      </span>

    <!-- Bouton Annuler -->
    <button
      class="mg-l-5 p-button-secondary"
      pButton type="button"
      (click)="closeDialog(false)"
      icon="fas fa-times">
    </button>
  </div>
</p-dialog>
