import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import {EquipementANettoyerPmsDto} from "../../dtos/pms/equipement-a-nettoyer-pms-dto";

export const URL_GET_ALL = 'dolrest/equipements-nettoyer-pms/all';
export const URL_SAVE = 'dolrest/equipements-nettoyer-pms/';
export const URL_DELETE = 'dolrest/equipements-nettoyer-pms/';

@Injectable({
  providedIn: 'root'
})
export class EquipementANettoyerPmsService {
  private subjectOpenDialog = new Subject<EquipementANettoyerPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectEquipementNettoyerSaved = new Subject<EquipementANettoyerPmsSavedSupplier>();
  equipementNettoyerSaved$ = this.subjectEquipementNettoyerSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  getEntityName = () : string => {
    return "equipementnettoyerpms";
  }

  /**
   * Récupère tous les equipements à nettoyer pms
   * @returns {Observable<EquipementANettoyerPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<EquipementANettoyerPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);


  /**
   * Sauvegarde un equipements à nettoyer pms
   * @param equipementNettoyer
   * @param file
   */
  save = (equipementNettoyer: EquipementANettoyerPmsDto, file: any) : Observable<ResponseWrapper<EquipementANettoyerPmsDto>> => {
    let formData = new FormData();
    formData.set('equipementanettoyer', JSON.stringify(equipementNettoyer));
    formData.set('file', file);
    return this.httpSvc.post(URL_SAVE, formData);
  }

  /**
   * Supprime un ou plusieurs equipement(s) à nettoyer pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: EquipementANettoyerPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (equipementNettoyer: EquipementANettoyerPmsDto, isUpdate: boolean = false) : void => {
    this.subjectEquipementNettoyerSaved.next({ isUpdate, equipementNettoyer } as EquipementANettoyerPmsSavedSupplier);
  }
}

export interface EquipementANettoyerPmsSavedSupplier {
  isUpdate: boolean;
  equipementNettoyer: EquipementANettoyerPmsDto;
}
