import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {MenuItem} from "primeng/api";
import {UtilsService} from "../../../../core/utils/utils.service";
import {combineLatest, Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {SelectButton} from "primeng/selectbutton";
import {ItemNavigationService} from "../../../../core/services/technique/item-navigation.service";

@Component({
  selector: 'yo-point-de-livraison-conditionnement',
  templateUrl: './point-de-livraison-conditionnement.component.html',
  styleUrls: ['./point-de-livraison-conditionnement.component.scss']
})
export class PointDeLivraisonConditionnementComponent implements OnInit, OnDestroy {

  @ViewChild('selectItems') selectItems: SelectButton;

  items: MenuItem[] = [
    {label: 'Liaisons', routerLink: ['liaisons']},
    {
      label: 'Règles regroupement',
      routerLink: ['regles-regroupements']
    }
  ];

  selectedItem = this.items[0];

  subscriptionRoute: Subscription;
  subscriptionRights: Subscription;
  subscriptionAssociationSaved: Subscription;
  subActiveSecondaryRoute: Subscription;

  hasGestionClients = false;
  hasGestionIDistri = false;

  plc: PointDeLivraisonDTO;
  idClient: number;

  constructor(public utils: UtilsService,
              public auth2Svc:Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private itemNavSvc: ItemNavigationService,
              private cd: ChangeDetectorRef){}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionRights);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
  }

  ngOnInit(): void {
    this.initRouteSubscription();
    this.initRightsSubscription();
    this.activeSecondaryRouteSubscription();
  }

  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {

      this.selectedItem = this.itemNavSvc.activeSecondaryRoute(event, this.items);
      if (this.utils.isNullOrEmpty(this.selectedItem)) {
        this.selectedItem = this.items[0];
        this.goToSelectionUrl(this.selectedItem.routerLink);
      }

    });
  };

  changeSelectedItem = (event: any) => {
    this.goToSelectionUrl(event.value.routerLink);
  };

  goToSelectionUrl = (link: string[]) => {
    const url = [`point-de-livraison`, this.plc.client.id, this.plc.id, 'conditionnement', link[0]];
    this.routeMapSvc.goToSecondaryRoute(url)
      .then(() => this.cd.detectChanges())
      .then(() => this.cd.markForCheck())
      .catch(e => console.error(e));
  };

  initRouteSubscription = (): void => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data) => {
        this.plc = data.plcSupplier.pointDeLivraison;
        this.idClient = data.plcSupplier.idClient;
      });
  };

  initRightsSubscription = (): void => {
    const all$ = combineLatest([this.auth2Svc.hasGestionClients$, this.auth2Svc.hasGestionIDistri$]);
    this.subscriptionRights = all$.subscribe(response => {
      this.hasGestionClients = response[0];
      this.hasGestionIDistri = response[1];
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les modèles points de livraison`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };
    return dms;
  };

}
