import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {UtilsService} from "../../utils/utils.service";
import {PrintOptionInventaire} from "../../models/gestion-stock/print-inventaire.model";

;

export const URL_POST_PRINT_INVENTAIRE =`dolrest/gestion-stocks/print/inventaire`;

@Injectable()
export class PrintInventaireService {

  constructor(
              private utils: UtilsService,
              private httpClient: HttpClient){

  }

  private subjectDisplayDialogPrintInventaire= new Subject();
  public displayDialogPrintInventaire$ = this.subjectDisplayDialogPrintInventaire.asObservable();

  public announceDisplayDialogPrintInventaire( isOpenDialog: boolean){
    this.subjectDisplayDialogPrintInventaire.next(isOpenDialog);
  }

  printInventaire(printOptionInventaire: PrintOptionInventaire) {


    return this.httpClient.post(URL_POST_PRINT_INVENTAIRE, printOptionInventaire,{
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  }

}
