import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {ProduitRouteMapService} from '../../../../core/services/gestionproduits/produit-routemap.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {SecteurDTO} from '../../../../core/dtos/secteur-dto';
import {SiteDTO} from '../../../../core/dtos/site-dto';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';


@Injectable({
  providedIn: 'root'
})
export class FournisseurFicheIdentiteResolverService implements Resolve<FournisseurFicheIdentiteSupplier> {

  constructor(private auth2Svc: Auth2Service,
              private produitRouteMap: ProduitRouteMapService,
              private gestionFournisseurSvc :GestionFournisseurService,
              private gds: GenericDatagridService,
              private utils: UtilsService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FournisseurFicheIdentiteSupplier> | Promise<FournisseurFicheIdentiteSupplier> | FournisseurFicheIdentiteSupplier => {

    const fs = new FournisseurFicheIdentiteSupplier();
    const idFournisseur: number = +route.paramMap.get('idFournisseur');
    let fournisseur$ = this.gestionFournisseurSvc.getFournisseuById(idFournisseur);
    let secteurs$ = this.gds.getAll('secteur', ['code,asc'], true);

    // MODIFICATION, on recupere le fournisseur du back
    if (this.utils.isNumberGt0(idFournisseur)) {
      const initAll$ = combineLatest([fournisseur$, secteurs$]);

      return initAll$.toPromise().then(items => {

        if (!this.utils.isResponseSupplierError(items[0])) {
          fs.fournisseur = items[0].one;
          fs.secteurs =  items[1].resultList;
          this.gestionFournisseurSvc.announceFournisseur(fs.fournisseur);
          return fs;
        }
      });
    }
    // CREATION, on créé le fournisseur depuis le front (seulement fiche identite)
    else {
      fs.fournisseur = this.initFournisseur();
      this.gestionFournisseurSvc.announceFournisseur(fs.fournisseur);
      return fs;
    }
  };

  initFournisseur = (): FournisseurDTO => {

    let site = new SiteDTO();

    //on initialise le fournisseur
    const fournisseur = new FournisseurDTO();
    fournisseur.id = 0;
    fournisseur.libelle = '';
    fournisseur.code = '';
    fournisseur.actif = true;
    fournisseur.site = site;
    fournisseur.adresse = new AdresseDTO(site, 0);

    return fournisseur;
  };
}

export class FournisseurFicheIdentiteSupplier {
  fournisseur: FournisseurDTO;
  filialeList:  FournisseurDTO[] = [];
  secteurs: SecteurDTO[] =[];
}
