import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {catchError} from 'rxjs/operators';
import {UniteDeProduction__denreeInterditeDto} from '../../../../core/dtos/unite-de-production__denree-interdite-dto';
import {UniteDeProduction__denreesInterditesService} from '../../../../core/services/entities/unite-de-production__denrees-interdites.service';

@Component({
  selector: 'yo-dialog-modifier-denree-interdite',
  templateUrl: './dialog-modifier-denree-interdite.component.html',
  styleUrls: ['./dialog-modifier-denree-interdite.component.scss']
})
export class DialogModifierDenreeInterditeComponent implements OnInit, OnDestroy {

  subOpenDialog: Subscription;
  selectedUdpDenreeInterdite: UniteDeProduction__denreeInterditeDto;

  displayDialog = false;
  ssw: SearchSupplierWrapper;

  localeFr = DATEPICKER_FR;

  constructor(public utils: UtilsService,
              private udpDenreeInterditeSvc: UniteDeProduction__denreesInterditesService) {
  }

  ngOnInit() {
    this.subscriptionOpenDialog();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  subscriptionOpenDialog() {
    this.subOpenDialog = this.udpDenreeInterditeSvc.openDialogModifierDenree$.pipe(
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {
      this.selectedUdpDenreeInterdite = response.uniteDeProductionDenreeInterditeDto;
      this.displayDialog = true;
    });
  }

  closeDialog() {
    this.displayDialog = false;
  }


  startError(property: string) {
    return `Le champ "${property.toUpperCase()}" `;
  }

  modifierDenreeDeSubstitution() {
    this.udpDenreeInterditeSvc.save(this.selectedUdpDenreeInterdite).subscribe(response => {
      if (!this.utils.isNullOrEmpty(response.one)) {
        this.udpDenreeInterditeSvc.announceLoadDenreesByUdp();
        this.closeDialog();
      } else {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Modification impossible`, '', 8000);
      }
    });
  }

  getMesureDenreeSubstitution() {
    if (this.selectedUdpDenreeInterdite.ratio === 0 ||
      this.selectedUdpDenreeInterdite.denreeInterditeRatioUniteTechnique === 0 ||
      this.selectedUdpDenreeInterdite.denreeSubstitutionRatioUniteTechnique === 0) {
      return 0;
    } else {
      return Number(this.selectedUdpDenreeInterdite.ratio / 100 * (this.selectedUdpDenreeInterdite.denreeInterditeRatioUniteTechnique / this.selectedUdpDenreeInterdite.denreeSubstitutionRatioUniteTechnique));
    }
  }
}
