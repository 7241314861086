import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'yo-confirm-dialog',
  template: `
    <p-confirmDialog header="Confirmation" appendTo="body" i18n-header="@@sharedforms.confirmdialog.header" icon="pi pi-exclamation-triangle" width="425" #cd>
      <p-footer>
        <button type="button" pButton icon="fas fa-check" label="Oui" i18n-label="@@label.yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="fas fa-times" label="Non" i18n-label="@@label.no" (click)="cd.reject()"></button>
      </p-footer>
    </p-confirmDialog>
  `,
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // console.log('confirm dialog');
  }

}
