<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'890px'}"
          [(visible)]="openDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form>
    <div class="row mg-t-10">
      <div class="col-md-12">
        <i class="pi pi-info-circle"></i> Chaque plat sélectionné sera associé au modèle de conditionnement plat actuel ainsi que les unités de production associés à ce dernier</div>
        Les plats déjà associés pour ce modèle de conditionnement plat ne s'afficheront pas à l'écran.
    </div>
    <div class="row mg-t-10">
        <div class="col-md-4">
          <dx-tag-box
            #dxDeclinaisons
            placeholder="Filtrer les plats par déclinaison..."
            (onValueChanged)="onChangeDeclinaisonFilter($event)"
            [dataSource]="allDeclinaisons"
            [showSelectionControls]="true"
            displayExpr="libelle"
            valueExpr="id"
            [searchEnabled]="true"
            applyValueMode="instantly">
          </dx-tag-box>
        </div>
      <div class="col-md-4">
        <dx-tag-box
          #dxTypeFab
          placeholder="Filtrer les plats par type de fabrication..."
          (onValueChanged)="onChangeTypeFabricationFilter($event)"
          [dataSource]="allTaches"
          [showSelectionControls]="true"
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          applyValueMode="instantly">
        </dx-tag-box>
      </div>
      <div class="col-md-4">
        <dx-tag-box
          #dxRegime
          placeholder="Filtrer les plats par régime..."
          (onValueChanged)="onChangeRegimeFilter($event)"
          [dataSource]="allRegimes"
          [showSelectionControls]="true"
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          applyValueMode="instantly">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4">
        <dx-tag-box
          #dxFamilleProduit
          placeholder="Filtrer les plats par famille de produit..."
          (onValueChanged)="onChangeFamilleProduitFilter($event)"
          [dataSource]="allFamillesProduits"
          [showSelectionControls]="true"
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          applyValueMode="instantly">
        </dx-tag-box>
      </div>
      <div class="col-md-4">
        <dx-tag-box
          #dxSites
          placeholder="Filtrer les plats par site..."
          (onValueChanged)="onChangeSiteFilter($event)"
          [dataSource]="sitesUtilisateurConnecte"
          [showSelectionControls]="true"
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          applyValueMode="instantly">
        </dx-tag-box>
      </div>
      <div class="col-md-4">
        <dx-text-box #dxCode placeholder="Filtrer les plats par code..." valueChangeEvent="keyup" (onValueChanged)="onChangeCodeFilter($event)">
        </dx-text-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-12">
        <dx-text-box #dxLabel placeholder="Filtrer les plats par libellé..." valueChangeEvent="keyup" (onValueChanged)="onChangePlatLibelleFilter($event)">
        </dx-text-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-12">
        <div class="p-inputgroup input-group-sm ">
          <dx-data-grid
            [dataSource]="dataSource"
            keyExpr="id"
            [height]="600"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            [remoteOperations]="true"
            (onSelectionChanged)="checkDisabledButtons()"
            #gridPlatsToAdd>

            <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>

            <dxi-column dataField="site.libelle" caption="Site" cellTemplate="siteCellTemplate" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="libelle" caption="Nom du plat" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="produitDeclinaisons" cellTemplate="pdCellTemplate" caption="Déclinaison(s)" [allowFiltering]="false"></dxi-column>
            <dxi-column dataField="code" caption="Code" [allowFiltering]="false"></dxi-column>

            <div *dxTemplate="let cell of 'siteCellTemplate'">
              <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"
                       [yoDelayMsTooltip]="1500"></yo-site>
            </div>

            <div class="flex-wrap" *dxTemplate="let cell of 'pdCellTemplate'">
              <label *ngFor="let pd of cell.row.data.produitDeclinaisons" class="badge badge-secondary zoom cursor">
                {{ pd?.declinaison?.libelle }}
              </label>
            </div>


            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-paging [pageSize]="50"></dxo-paging>

            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[20, 50, 100]"
              [showNavigationButtons]="false"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} plat(s)">
            </dxo-pager>

          </dx-data-grid>

        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end" style="margin-top: 15px">
      <button
        pButton type="button"
        icon="fas fa-plus"
        label="Ajouter"
        (click)="save()"
        [disabled]="!canSave()"
        [class.disable]="!canSave()">
      </button>
      <button
        class="mg-l-5 p-button-secondary"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>
