import {Injectable} from '@angular/core';

import frMessages from 'devextreme/localization/messages/fr.json';
import config from 'devextreme/core/config';


import frCldrData from 'devextreme-cldr-data/fr.json';
import {loadMessages, locale} from 'devextreme/localization';
import {MSG_KEY, MSG_SEVERITY, PREDICAT_OPERATOR} from '../../constants';
import {UtilsService} from '../../utils/utils.service';
import {GenericRequestSupplier, Sort} from '../../suppliers/generics/generic-request-supplier';
import {confirm} from "devextreme/ui/dialog";

@Injectable({
  providedIn: 'root'
})
export class DevextremeService {

  constructor(private utils: UtilsService) {
  }

  getLocalization(language: string) {

    console.log('localization', language);

    if (language.startsWith('fr')) {
      config({
        defaultCurrency: 'EUR',

      });
    }

    loadMessages(frCldrData);
    loadMessages(frMessages);
    locale(language);

  }

  getHeight(delta: number) {

    return window.innerHeight / delta;

  }

  getWidth(delta: number) {

    return window.innerWidth / delta;
  }

  getRowsSelectedForDeferredMode = (filters: FilterItem[], resultList: any[]): any => {
    if (filters && filters.length && resultList) {
      const ids: number[] = filters.filter(f => f.column === "id").map(f => parseInt(f.value));
      const data: any[] = resultList.filter(r => ids.find(id => id === r.id));
      return {
        data,
        totalCount: data.length
      }
    }
    return null;
  }

  dxToGrsSorts(dxSorts: any[]) {

    // sorts
    const sorts: Sort[] = [];
    if (!this.utils.isCollectionNullOrEmpty(dxSorts)) {
      for (const sort of dxSorts) {
        sorts.push({dir: sort.desc ? 'desc' : 'asc', path: sort.selector, luceneSortType: ''});
      }
    }
    return sorts;
  }

  dxToGrsFilters(dxFilters: any[]): FilterItem[] {

    // filters
    const filters: FilterItem[] = [];
    if (!this.utils.isCollectionNullOrEmpty(dxFilters)) {

      let oneItem = false;

      if (!Array.isArray(dxFilters[0])) {
        oneItem = true;
      }

      // si un seul filtre
      if (oneItem) {
        filters.push({column: dxFilters[0], operator: dxFilters[1], value: dxFilters[2]});
      }
      // si plusieurs filtres
      else {
        for (let i = 0; i < dxFilters.length; i += 2) {
          filters.push({column: dxFilters[i][0], operator: dxFilters[i][1], value: dxFilters[i][2]});
        }
      }
    }

    return filters;
  }

  dxToGrsOperator(dxOperator: OPERATOR): PREDICAT_OPERATOR {

    let predicatOperator = PREDICAT_OPERATOR.Contains;

    switch (dxOperator) {

      case OPERATOR.CONTAINS:
        predicatOperator = PREDICAT_OPERATOR.Contains;
        break;
      case OPERATOR.GTE:
        predicatOperator = PREDICAT_OPERATOR.GreaterThanEquals;
        break;
      case OPERATOR.LTE:
        predicatOperator = PREDICAT_OPERATOR.LessThanEquals;
        break;
    }

    return predicatOperator;

  }

  getGenericRequestSupplierFromDxFilter(dxFilter: FilterItem[], entityName: string) {
    let grs: GenericRequestSupplier = new GenericRequestSupplier(entityName);


    return grs;
  }

  dxDeleteItemsConfirmation = async (itemsToDelete: any[], callback: any) => {
    const questionDeletion = itemsToDelete.length === 1
      ? 'Êtes vous sûr de vouloir supprimer l\'élément sélectionné ?'
      : `Êtes vous sûr de vouloir supprimer les ${itemsToDelete.length} éléments sélectionnés ?`;
    let res = await confirm(questionDeletion, "Suppression");
    if (res && !this.utils.isCollectionNullOrEmpty(itemsToDelete))
      callback(itemsToDelete);
  }


}

export enum DATAGRID_ROW_TYPES {
  DATA = 'data',
  HEADER = 'header',

}


export enum OPERATOR {
  CONTAINS = 'contains',
  GTE = '>=',
  LTE = '<=',
  EQUAL = '='
}


export interface FilterItem {
  column: string;
  operator: OPERATOR;
  value: string;
}
