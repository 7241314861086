<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="origineSvc.getHelp()" [width]="800"></yo-help>
    <span class="mg-r-15">
      <button pButton
              label="CRÉER"
              icon="fa fa-plus"
              (click)="createOrigine()">
      </button>
    </span>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="origineList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(100)"
  [width]="'100%'"
  columnResizingMode="widget"
  [cacheEnabled]="false"
  [remoteOperations]="true"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" alignment="center" caption="Actions"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site.libelle" alignment="center" caption="Site"
              [allowFiltering]="false"
              [allowSorting]="false"
              cellTemplate="siteCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" alignment="center" caption="Libelle"
              [allowFiltering]="true" [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="code" alignment="center" caption="Code"
              [allowFiltering]="true" [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="actif" alignment="center" caption="Actif"
              [allowFiltering]="false">
  </dxi-column>


  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell.data.site.libelle"
             [siteId]="cell.data.site.id">
    </yo-site>
  </div>

  <dxo-selection
    [selectAllMode]="'allPages'"
    [showCheckBoxesMode]="'onClick'"
    mode="single">
  </dxo-selection>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[20, 50, 100]"
    [showNavigationButtons]="true"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Origines">
  </dxo-pager>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-sorting mode="multiple"></dxo-sorting>

  <!--    TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="auth2Svc.isSiteLocal(cell.row.data.site.id)?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="auth2Svc.isSiteLocal(cell.row.data.site.id)?'Modifier l\'origine':'Voir l\'origine'"
                    tooltipPosition="right"
                    showDelay="500"
    ></yo-cell-button>

    <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoDisabled]="!auth2Svc.isSiteLocal(cell.row.data.site.id)"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-trash'"
                    [pTooltip] ="!auth2Svc.isSiteLocal(cell.row.data.site.id)? 'Vous n\'avez pas les droits pour supprimer cette origine' :'Supprimer l\'origine'"
                    tooltipPosition="right"
                    showDelay="500"
    ></yo-cell-button>
  </div>
</dx-data-grid>

<!--DIALOG EDITION ORIGINE-->
<yo-dialog-origine></yo-dialog-origine>
