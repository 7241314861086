<p-dialog [header]="dialogTitle"
          [dismissableMask]="true"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px', 'width': '100%'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>


  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <strong><i class="fas fa-calendar mg-r-5"></i>SÉLECTION DES JOURS</strong>
          </div>
          <div class="card-body">
            <!--SELECTION TYPE JOUR-->
            <div class="row">
              <div class="col">
                <p-selectButton (onChange)="changeJourObligatoire()" [options]="typesJours"
                                formControlName="typeJour"></p-selectButton>
              </div>
            </div>
            <!--SELECTION JOURS-->
            <div class="row mg-t-10">
              <div class="col">
                <ng-container *ngTemplateOutlet="tplJours;context:{form:form}"></ng-container>
              </div>
            </div>
            <!--SELECTION DETAIL-->
            <div class="row mg-t-10">
              <div class="col">
                <ng-container *ngTemplateOutlet="tplDetail;context:{form:form}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <strong><i class="fas fa-users mg-r-5"></i>SÉLECTION DES ÉQUIPES</strong>
          </div>
          <div class="card-body">
            <!--SELECTION ÉQUIPE-->
            <div class="row">
              <div class="col">
            <ng-container *ngTemplateOutlet="tplEquipes;context:{form:form}"></ng-container>
              </div>
            </div>
            <div class="row mg-t-10">
              <div class="col">
            <p-checkbox [formControl]="form.controls['ignoreTeam']" label="Ne pas tenir compte des équipes" binary="true"></p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <strong><i class="fas fa-carrot mg-r-5"></i>SÉLECTION DES PLATS</strong>
          </div>
          <div class="card-body">
            <ng-container *ngTemplateOutlet="tplSelectPlats;context:{form:form}"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex mg-t-10">
      <div class="mr-auto"></div>
      <div class="mg-r-5">
        <button
          pButton
          icon="fas fa-print"
          (click)="printPlanProd()"
          label="Plan de production"
          [disabled]="!canPrint()"
        >
        </button>
      </div>
      <div>
        <button
          pButton
          type="text"
          icon="fas fa-print"
          (click)="opPrintFicheTechnique.toggle($event)"
          [disabled]="!canPrint()"
          label="Fiches techniques">
        </button>
      </div>
      <div>



        <p-overlayPanel #opPrintFicheTechnique appendTo="body">
          <ng-template pTemplate>
            <div class="d-flex">
              <div class="mg-r-5">
                Mode d'édition
              </div>
              <div>
                <p-selectButton [options]="printModeOptions"
                                formControlName="printMode"></p-selectButton>
              </div>
            </div>

            <ng-container *ngIf="form.controls['printMode'].value==='CONDENSEE'">
              <div class="d-flex mg-t-14">
                <div class="mr-auto"></div>
                <label for="printOnlyWithParts">Imprimer uniquement les recettes ayant des nombres de portions</label>
                <input id="printOnlyWithParts" class="checkbox-print-only-parts" type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="printOnlyWithParts" />
              </div>
            </ng-container>

            <div class="d-flex mg-t-14">
              <div class="mr-auto"></div>
              <div>
                <button
                  pButton
                  type="text"
                  icon="fas fa-check"
                  (click)="printFichesTechniques()"
                  label="OK">
                </button>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>


  </form>


</p-dialog>

<!--TEMPLATE SELECTION PLATS / FICHES TECHNIQUES (fonctionnellement parlant) -->
<ng-template #tplSelectPlats let-form="form">
  <div *ngIf="ppDetailsList.length > 0" [formGroup]="form">
    <p-listbox [options]="ppDetailsList"
               optionLabel="label"
               [style]="{'margin-bottom':'0px'}"
               [listStyle]="{'height':'250px'}"
               formControlName="ppDetails"
               multiple="multiple"
               checkbox="checkbox"
               filter="filter">
    </p-listbox>
  </div>
</ng-template>

<!--TEMPLATE SELECTION ÉQUIPES-->
<ng-template #tplEquipes let-form="form">
  <div *ngIf="_equipesItems.length > 0" [formGroup]="form">
    <p-tree class="ptree" (onNodeUnselect)="updatePlats()" (onNodeSelect)="updatePlats()"
            [value]="_equipesItems"  selectionMode="checkbox" [filter]="true"
            [(selection)]="equipesSelected"></p-tree>
  </div>
  <div *ngIf="!_equipesItems?.length">
    <p>Veuillez préselectionner une ou plusieurs date(s).</p>
  </div>
</ng-template>

<!--TEMPLATE SELECTION DETAIL-->
<ng-template #tplDetail let-form="form">
  <div [formGroup]="form">
    <ng-container *ngIf="form.controls['typeJour'].value==='jourFab'">
      <p-checkbox [formControl]="form.controls['detailJourConso']" label="Afficher le détail des jours de consommation"
                  binary="true"></p-checkbox>
    </ng-container>
    <ng-container *ngIf="form.controls['typeJour'].value==='jourConso'">
      <p-checkbox [formControl]="form.controls['detailJourFab']" label="Afficher le détail des jours de fabrication"
                  binary="true"></p-checkbox>
    </ng-container>
  </div>
</ng-template>

<!--TEMPLATE SELECTION JOURS-->
<ng-template #tplJours let-form="form">

  <div [formGroup]="form">

    <!--quand jour fab sélectionné-->
    <ng-container *ngIf="form.controls['typeJour'].value==='jourFab'">
      <!--<h5>Sélection des jours de fabrication</h5>-->
      <p-listbox [options]="_datesFab"
                 [style]="{'margin-bottom':'0px'}"
                 [listStyle]="{'height':'150px'}"
                 formControlName="selectedJoursFab"
                 (onChange)="changeJourObligatoire()"
                 multiple="multiple"
                 checkbox="checkbox"
                 filter="filter">
      </p-listbox>
    </ng-container>

    <!--quand jour conso sélectionné-->
    <ng-container *ngIf="form.controls['typeJour'].value==='jourConso'">
      <p-listbox [options]="_datesConso"
                 [style]="{'margin-bottom':'0px'}"
                 [listStyle]="{'height':'150px'}"
                 (onChange)="changeJourObligatoire()"
                 formControlName="selectedJoursConso"
                 multiple="multiple"
                 checkbox="checkbox"
                 filter="filter">
      </p-listbox>
    </ng-container>

    <ng-container *ngIf="!utils.isNullOrEmpty(jourOblibatoire)">
      <span class="danger-color" [innerHTML]="domSvc.bypassSecurityTrustHtml(jourOblibatoire)"></span>
    </ng-container>

  </div>

</ng-template>
