import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TacheDTO} from "../../core/dtos/tache-dto";
import {UtilsService} from "../../core/utils/utils.service";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {GenericDatagridService} from "../../core/services/generics/generic-datagrid.service";
import {RoutemapService} from "../../core/services/routemap.service";
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../core/suppliers/dialog-msg-supplier";
import {TypeTacheDTO} from "../../core/dtos/type-tache-dto";
import {TachesService} from "../../core/services/taches/taches.service";
import {TypesTachesService} from "../../core/services/types-taches/types-taches.service";

@Component({
  selector: 'yo-tache',
  templateUrl: './tache.component.html',
  styleUrls: ['./tache.component.scss']
})
export class TacheComponent implements OnInit, OnDestroy {

  displayDialog = false;

  subOpenDialogTache: Subscription;

  tache: TacheDTO;

  typesTachesList: TypeTacheDTO[];

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'une tâche';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private tacheSvc: TachesService,
              private typeTacheEntitySvc: TypesTachesService) {
  }

  ngOnInit(): void {
    this.initTypesTaches();
    this.initForm();
    this.openDialogTacheSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogTache);
  }

  openDialogTacheSubscription = (): void => {
    this.subOpenDialogTache = this.tacheSvc.openDialogTache$
      .subscribe((tache: TacheDTO) => {
        this.displayDialog = true;
        if (!tache) {
          this.tache = new TacheDTO();
          this.tache.id = 0;
          this.dialogTitle = 'Création d\'une tâche';
        } else {
          this.tache = tache;
        }
        this.initForm();
      });
  };

  initTypesTaches = (): void => {
    this.typeTacheEntitySvc.getAll()
      .subscribe( response => {
        this.typesTachesList = response.resultList;
      });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      this.tache.libelle = this.form.controls['libelle'].value;
      this.tache.code = this.form.controls['code'].value;
      this.tache.actif = this.form.controls['actif'].value;
      this.tache.typeTacheId = this.form.controls['idTypeTache'].value.id;
      this.tacheSvc.save(this.tache)
        .subscribe(response => {
          this.tacheSvc.announceTacheSaved(response.one, this.tache.id !== undefined && this.tache.id !== null && this.tache.id !== 0);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde de la tâche réalisée avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.tache ? this.tache.libelle : '', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      code: new FormControl(this.tache ? this.tache.code : '', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
      actif: new FormControl(this.tache ? this.tache.actif : true, [Validators.required]),
      idTypeTache: new FormControl(this.tache ? this.utils.preSelectSingleList(this.typesTachesList, { id: this.tache.typeTacheId, libelle: this.tache.typeTacheLibelle } as TypeTacheDTO) : [], [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `La tâche`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
