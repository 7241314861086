import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {PlanAlimentaireSupplier} from '../plan-alimentaire-resolver.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {ModelesNutritionnelsService} from '../../../core/services/entities/modeles-nutritionnels.service';
import {ModeleNutritionnelDTO} from '../../../core/dtos/modele-nutritionnel-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {FS_ROUTES, MSG_SEVERITY} from '../../../core/constants';

@Component({
  selector: 'yo-fiche-identite',
  templateUrl: './fiche-identite.component.html',
  styleUrls: ['./fiche-identite.component.scss']
})
export class FicheIdentiteComponent implements OnInit, OnDestroy {

  subPlanAlimentaire:Subscription;
  planAlimentaire : ModeleNutritionnelDTO;

  form:FormGroup;
  fieldsPlanAlimentaire: FormFieldBaseSupplier<any>[];

  constructor(public utils:UtilsService,
              public gds:GenericDatagridService,
              private route: ActivatedRoute,
              private gfs:GenericFormService,
              private modeleNutritionnelSvc:ModelesNutritionnelsService,
              private routemapSvc:RoutemapService) { }

  ngOnInit() {
    this.initForm();
    this.initRouteData()
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPlanAlimentaire);
  }

  initRouteData = () => {
    this.subPlanAlimentaire = this.route.parent.data
      .subscribe((data: { planAlimentaireSupplier: PlanAlimentaireSupplier }) => {
        this.planAlimentaire = data.planAlimentaireSupplier.planAlimentaire;
        this.initForm();
      });
  };

  initForm = () => {
    this.fieldsPlanAlimentaire = this.modeleNutritionnelSvc.getFields(this.planAlimentaire);
    this.form = this.gfs.toFormGroup(this.fieldsPlanAlimentaire);
  };

  save = () => {
    this.gfs.validateAllFormFields(this.form);

    if(this.form.valid){
      this.gfs.saveAndLinkDocument(this.form,this.modeleNutritionnelSvc.getEntityName()).subscribe(response=>{
        if(!this.utils.isResponseSupplierError(response)){
          this.planAlimentaire =response.one;
          this.modeleNutritionnelSvc.announceSavedDTO(response);
          // rafraichir la route avec l'id du plan alimentaire nouvellement persisté.
          this.routemapSvc.goToSecondaryRoute([FS_ROUTES.GGE_MODELENUTRI,this.planAlimentaire.id,'fiche-identite']);
          this.utils.showMsg('plan-alimentaire',MSG_SEVERITY.SUCCESS,`Fiche d'identité enregistrée avec succès.`);
        }
      });
    }
  };
}
