<p-dialog
  [(visible)]="displayDialog"
  [contentStyle]="{'overflow':'visible'}"
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="true">

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Changement de mot de passe
    </div>
  </ng-template>


  <!--CONTENT-->
  <form class="utilisateur-form" #formLogin="ngForm">

    <div class="p-field p-grid">
      <label for="login" class="p-col-fixed width-200" >Adresse mail</label>
      <div class="p-col">
        <input id="login" class="width-250" pInputText name="login" [(ngModel)]="userMail" required>
      </div>
    </div>


    <div class="p-field p-grid">
      <label for="motdepasse" class="p-col-fixed width-200" >Mot de passe</label>
      <div class="p-col">
        <input id="motdepasse" class="width-250" pInputText [type]="hide ? 'password' : 'text'" name="password" [(ngModel)]="userPassword"
               required>
      </div>
    </div>

    <div class="p-field p-grid">
      <label for="confirmmotdepasse" class="p-col-fixed width-200" >Confirmer le mot de passe</label>
      <div class="p-col">
        <input id="confirmmotdepasse" class="width-250" pInputText [type]="hide ? 'password' : 'text'" name="password"
               [(ngModel)]="userPasswordConfirm"
               required>
      </div>
    </div>


    <div *ngIf="printMessage && !isPasswordOk()" class="danger-color">
      {{getPasswordErrorMessage()}}
    </div>

  </form>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <button pButton (click)="sendNewPassword()" label="Envoyer"

            [disabled]="isBoutonEnvoyerDisabled()"></button>
    <button pButton (click)="closeDialog()"
            class="p-button-secondary"
            label="Annuler"></button>
  </ng-template>

</p-dialog>


