import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {StocksSupplier} from '../stocks-resolver.service';
import {FormControl, FormGroup} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {TreeNode} from 'primeng/api';
import {DATEPICKER_FR} from '../../../core/constants';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {
  ProduitDeclinaisonService,
  SearchStockSupplier
} from '../../../core/services/entities/produit-declinaison.service';

@Component({
  selector: 'yo-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subFeature: Subscription;
  subPaginationSearchStock: Subscription;
  subFormSearch: Subscription;
  subLotsDeleted: Subscription;

  formSearch: FormGroup;
  feature: string;

  page = 0;
  size = 50;

  tree: TreeNode[] = [];
  selectedNodes: TreeNode[] = [];
  searchStockSupplier: SearchStockSupplier;

  seuils: any[] = [];

  localeFr = DATEPICKER_FR;

  constructor(
    public utils: UtilsService,
    private route: ActivatedRoute,
    private stocksSvc: StocksService,
    private produitDeclinaisonSvc: ProduitDeclinaisonService) {
    this.seuils = [{ value:"4", label:"Aucun stock"}, {value:"0", label:"Stocks à 0"}, {value:"1", label:"Stocks positifs"}, {value:"3", label:"Tous"}];
  }

  ngOnInit() {
    this.initForm();
    // fonctionnalité du stock (inventaire, historique...)
    this.subscriptionFeature();
    // la pagination a changé (grille inventaire, matiere premiere ...)
    this.subscriptionPaginationSearchStock();
    this.onChangeSearchUpdateFilter();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subFeature);
    this.utils.unsubscribe(this.subPaginationSearchStock);
    this.utils.unsubscribe(this.subLotsDeleted);
  }

  initForm = () => {

    this.formSearch = new FormGroup({
      denree: new FormControl(''),
      codeDenree: new FormControl(''),
      denominationArticle: new FormControl(''),
      referenceInterneArticle: new FormControl(''),
      datePerime: new FormControl(undefined),
      dateEntree: new FormControl(undefined),
      thresholdStocks: new FormControl('3'),
    });
  };

  /**
   * fix bug de rafraichissement de l'arbre kjlnhjk
   * @param selectedNodes
   * @param tree
   */
  fixTreeSelectedNodes = (selectedNodes: TreeNode[], tree: TreeNode[]): TreeNode[] => {

    const elts = [];
    for (const node of tree) {
      if (node.data.niveau === 1) {
        for (const node2 of node.children) {

          for (const sn of selectedNodes) {
            if (node2.data.id === sn.data.id && sn.data.niveau === 2) {
              node.expanded = true;
              elts.push(node2);
            }
          }
        }
      }
    }
    return elts;

  };

  subscriptionFeature = () => {
    this.subFeature = this.stocksSvc.feature$
      .pipe(
        map(value => {
          this.feature = value;
          this.searchStockSupplier = new SearchStockSupplier(this.formSearch.value, this.selectedNodes, this.feature, 0, 50);
        }),
        switchMap(value => this.produitDeclinaisonSvc.searchInStock(this.searchStockSupplier)),
        map(response => this.produitDeclinaisonSvc.announceResultSearchStocks(response, this.searchStockSupplier)),
        catchError(err => this.utils.handleError(err)))
      .subscribe();
  };
  subscriptionPaginationSearchStock = () => {
    this.subPaginationSearchStock = this.produitDeclinaisonSvc.paginationSearchStock$.pipe(
      map(response => this.searchStockSupplier = new SearchStockSupplier(this.formSearch.value, this.selectedNodes, this.feature, response.page, response.size)),
      switchMap(response => this.produitDeclinaisonSvc.searchInStock(this.searchStockSupplier)),
      map(response => this.produitDeclinaisonSvc.announceResultSearchStocks(response, this.searchStockSupplier)),
      // tap(response => console.log('in subscribe subPaginationSearchStock', response)),
      catchError(err => this.utils.handleError(err))
    ).subscribe();

    this.subRoute = this.route.data
      .subscribe((data: { stocksSupplier: StocksSupplier }) => {

        this.tree = data.stocksSupplier.tree;
      });


    // lorqu'il y a eu une suppression de lot périmé, on réinit le filtre date "Périmé le" et on relance la recherhce
    this.subLotsDeleted = this.produitDeclinaisonSvc.lotsDeleted$.pipe(
      tap(data =>this.formSearch.controls['datePerime'].setValue(undefined))
    ).subscribe();
  };



  onChangeSearchUpdateFilter = (): void => {
    this.formSearch.valueChanges.subscribe(e => this.formSearch.setValue(e, { emitEvent: false })); // Migrer à 10.0.4
    this.subFormSearch = this.formSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      map(values => this.searchStockSupplier = new SearchStockSupplier(values, this.selectedNodes, this.feature, 0, 50)),
      switchMap(values => this.produitDeclinaisonSvc.searchInStock(this.searchStockSupplier)),
      map(response => this.produitDeclinaisonSvc.announceResultSearchStocks(response, this.searchStockSupplier)),
      catchError(err => this.utils.handleError(err)))
      .subscribe();
  };

  onChangeLieuxDeStockage = ($event: any) => {
    this.selectedNodes = $event;
    // déclencher la recherche avec un temps de latence debounceTiem du this.formSearch.valueChanges
    this.formSearch.updateValueAndValidity({onlySelf: false, emitEvent: true});
  };
}

