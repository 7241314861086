<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'width':'600px'}"
  [(visible)]="displayDialog">


  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="far fa-lg fa-file-pdf mg-r-5"></i>Impression bilan allergène
    </div>
  </ng-template>

  <div class="d-flex flex-row justify-content-around justify-content-center mg-t-7 mg-b-7">
    <div>
      <label class="mg-r-5">Du : </label>
      <p-calendar pTooltip="Selectionner la date de début"
                  placeholder="date de début"
                  [showOnFocus]="false"
                  [(ngModel)]="startDate"
                  [showIcon]="true"
                  firstDayOfWeek="1"
                  dateFormat="dd/mm/yy"
                  [readonlyInput]="false"
                  [showWeek]="true"
                  [locale]="fr"
                  appendTo="body">
      </p-calendar>
    </div>
    <div>
      <label class="mg-r-5"> au : </label>
      <p-calendar pTooltip="Selectionner la date de fin"
                  placeholder="date de fin"
                  [showOnFocus]="false"
                  [(ngModel)]="endDate"
                  [showIcon]="true"
                  firstDayOfWeek="1"
                  dateFormat="dd/mm/yy"
                  [readonlyInput]="false"
                  [showWeek]="true"
                  [locale]="fr"
                  appendTo="body">
      </p-calendar>
    </div>
  </div>


  <div class="d-flex justify-content-end">
    <button type="button" pButton label="Imprimer" [disabled]="this.isValidForm()" icon="fas fa-print"
            (click)="downloadBilanAllergene()">
    </button>

    <button type="button" pButton class="close-button p-button-secondary" icon="fas fa-times" (click)="closeDialog()">
    </button>
  </div>
</p-dialog>
