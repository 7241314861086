import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ProduitsService, ProduitSupplier} from '../../../core/services/entities/produits.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, TYPES_CODES} from '../../../core/constants';
import {ActivatedRoute} from '@angular/router';
import {ObjectDTO} from '../../../core/dtos/object-dto';
import {FormGroup} from '@angular/forms';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {of, Subscription} from 'rxjs';
import {RoutemapService} from '../../../core/services/routemap.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {MessageService} from 'primeng/api';
import {UniqueCodeService} from '../../../core/services/unique-code.service';
import {catchError, switchMap} from 'rxjs/operators';
import {ContrainteModel} from '../../../core/models/contrainte-model';
import {ProduitContrainteAlimService} from '../../../core/services/entities/produit-contrainte-alim.service';
import {cloneDeep as _cloneDeep} from 'lodash'
import {RegimeAlimentaireService} from '../../../core/services/entities/regime-alimentaire.service';
import {FamillesProduitService} from '../../../core/services/entities/familles-produit.service';
import {GROUP_POSITION, GroupFormFields} from '../../../core/suppliers/group-formfields';

@Component({
  selector: 'yo-ficheidentite-produit2',
  templateUrl: './ficheidentite-produit2.component.html',
  styleUrls: ['./ficheidentite-produit2.component.scss'],
})
export class FicheidentiteProduit2Component implements OnInit {

  @Input() fields: FormFieldBaseSupplier<any>[];
  subscriptionRoute: Subscription;
  subSidenav: Subscription;

  form: FormGroup;
  formInfoGenerales: FormGroup;
  formFabrication: FormGroup;
  formApprovisionnement: FormGroup;
  formSortie: FormGroup;

  produit: ProduitDTO;
  produitSupplier: ProduitSupplier;

  displayInfosGenerales: boolean = true;

  selectedRowKeys: any[];

  groups: GroupFormFields[] = [];

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produit-edition';

  constructor(public produitsSvc: ProduitsService,
              private produitContrainteAlimSvc: ProduitContrainteAlimService,
              private familleProduitSvc: FamillesProduitService,
              private regimeSvc: RegimeAlimentaireService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              private cd: ChangeDetectorRef,
              private msgSvc: MessageService,
              private uniqueCodeSvc: UniqueCodeService,
              public utils: UtilsService) {
  }

  initFormDenree(fields: FormFieldBaseSupplier<any>[], formInfos: FormGroup, formFabrication: FormGroup, formApprovisionnement: FormGroup, formSortie: FormGroup) {


    const fieldsInfoKey = ['guid', 'id', 'site', 'libelle', 'libellelong', 'code', 'actif', 'file'];
    const fieldsInfo = this.gfs.getFieldsByKey(fieldsInfoKey, fields);
    formInfos = this.gfs.toFormGroup(fieldsInfo);

    const fieldsFabricationKey = ['uniteDeMesure', 'ratioVolume', 'typeProduit', 'familleProduit'];
    const fieldsFabrication = this.gfs.getFieldsByKey(fieldsFabricationKey, fields);
    formFabrication = this.gfs.toFormGroup(fieldsFabrication);

    const fieldsApprovisionnementKey = ['zoneDeStockage', 'dlc', 'tacheApprovisionnement'];
    const fieldsApprovisionnement = this.gfs.getFieldsByKey(fieldsApprovisionnementKey, fields);
    formApprovisionnement = this.gfs.toFormGroup(fieldsApprovisionnement);

    const fieldsSortieKey = ['tacheSortie'];
    const fieldsSortie = this.gfs.getFieldsByKey(fieldsSortieKey, fields);
    formSortie = this.gfs.toFormGroup(fieldsSortie);


    const groupFormFields = [
      {
        titlePanel: 'Informations générales',
        fields: fieldsInfo,
        form: formInfos,
        titleIcon: '',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['site', 'libelle', 'libellelong', 'code'],
          col2: ['file', 'actif']
        }
      },
      {
        titlePanel: 'Fabrication',
        fields: fieldsFabrication,
        form: formFabrication,
        titleIcon: '',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['uniteDeMesure', 'ratioVolume', 'typeProduit'],
          col2: ['familleProduit']
        }
      },
      {
        titlePanel: 'Approvisionnement',
        fields: fieldsApprovisionnement,
        form: formApprovisionnement,
        titleIcon: '',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: ['zoneDeStockage', 'dlc', 'tacheApprovisionnement'],

        }
      },
      {
        titlePanel: 'Sortie',
        fields: fieldsSortie,
        form: formSortie,
        titleIcon: '',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: ['tacheSortie'],
        }
      }
    ];

    return {groupFormFields, formInfos, formFabrication, formApprovisionnement, formSortie};

  }

  initFormPlat = (fields: FormFieldBaseSupplier<any>[], formInfos: FormGroup, formFabrication: FormGroup) => {

    const fieldsInfoKey = ['guid', 'id', 'site', 'libelle', 'libellelong', 'code', 'actif', 'file'];
    const fieldsInfo = this.gfs.getFieldsByKey(fieldsInfoKey, fields);
    formInfos = this.gfs.toFormGroup(fieldsInfo);

    const fieldsFabricationKey = ['uniteDeMesure', 'ratioVolume', 'typeProduit', 'tacheFabrication', 'familleProduit', 'regimes', 'recetteNbPortions'];
    const fieldsFabrication = this.gfs.getFieldsByKey(fieldsFabricationKey, fields);
    formFabrication = this.gfs.toFormGroup(fieldsFabrication);


    const groupFormFields = [
      {
        titlePanel: 'Informations générales',
        fields: fieldsInfo,
        form: formInfos,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['site', 'libelle', 'libellelong', 'code'],
          col2: ['file', 'actif']
        }
      },
      {
        titlePanel: 'Fabrication',
        fields: fieldsFabrication,
        form: formFabrication,
        titleIcon: 'fas fa-hands',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['uniteDeMesure', 'ratioVolume', 'typeProduit', 'tacheFabrication'],
          col2: ['regimes', 'familleProduit']
        }
      }
    ];

    return {groupFormFields, formInfos, formFabrication};

  };

  initForm = (produitSupplier: ProduitSupplier) => {

    this.fields = this.produitsSvc.getFields(produitSupplier.produitDTO);

    let formObjects = null;

    let formControls = {};

    switch (produitSupplier.produitDTO.typeProduit.fabrique) {

      // PLAT
      case true:
        formObjects = this.initFormPlat(this.fields, this.formInfoGenerales, this.formFabrication);
        this.formInfoGenerales = formObjects.formInfos;
        this.formFabrication = formObjects.formFabrication;
        formControls = {
          formInfoGenerales: this.formInfoGenerales,
          formFabrication: this.formFabrication,
        };

        break;

      // DENREE
      case false:
        formObjects = this.initFormDenree(this.fields, this.formInfoGenerales, this.formFabrication, this.formApprovisionnement, this.formSortie);
        this.formInfoGenerales = formObjects.formInfos;
        this.formFabrication = formObjects.formFabrication;
        this.formApprovisionnement = formObjects.formApprovisionnement;
        this.formSortie = formObjects.formSortie;
        formControls = {
          formInfoGenerales: this.formInfoGenerales,
          formFabrication: this.formFabrication,
          formApprovisionnement: this.formApprovisionnement,
          formSortie: this.formSortie
        };

        break;
    }

    this.form = new FormGroup(formControls);

    this.groups = formObjects.groupFormFields;
  };


  ngOnInit() {
    // chargement des informations necessaires à la page
    this.routeSubscription();
  }

  /**
   * chargement des informations necessaires à la page
   */
  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .pipe(
        switchMap((data: { produitSupplier: ProduitSupplier }) => {

          this.produitSupplier = data.produitSupplier;
          this.produit = data.produitSupplier.produitDTO;

          if (this.produit.id === 0) {
            const typeProduit = this.produit.typeProduit.fabrique ? 'P' : 'D';
            // A Supprimer dans le futur, doit se faire automatiquement en DDD si champ non renseigné côté backend :
            return this.uniqueCodeSvc.generateUniqueCode(TYPES_CODES.CODE_PRODUIT, typeProduit);
          } else {
            return of(null);
          }
        }),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        if (!this.utils.isNullOrEmpty(response)) {
          this.produit.code = response.one;
        }
        this.initForm(this.produitSupplier);
      });
  };


  saveFicheIdentite = () => {

    //Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);
    try {

      //On enregistre que si le formulaire est valide
      if (this.form.valid) {

        let formToSave: FormGroup;
        switch (this.produit.typeProduit.fabrique) {

          // PLAT
          case true:
            this.produit = this.prepareSavePlat(this.produit, this.formInfoGenerales, this.formFabrication);
            break;

          // DENREE
          case false:
            this.produit = this.prepareSaveDenree(this.produit, this.formInfoGenerales, this.formFabrication, this.formApprovisionnement, this.formSortie);
            break;

        }

        this.produitsSvc.saveProduit(this.produit, this.formInfoGenerales.value.file).subscribe(response => {
          this.produitsSvc.announceSavedDTO(response);
          this.produit = response.one;
          this.produitSupplier.produitDTO = this.produit;
          this.initForm(this.produitSupplier);
          this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', this.produit.typeProduit.fabrique, this.produit.id, 'produit-declinaisons']);
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Enregistrement du produit "${this.produit.libelle.toUpperCase()}"' OK.`);
        });


      }
    } catch (e) {
      this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.ERROR, e);
    }
  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/denrée`;
    dms.logo = 'fa fa-question-circle  yoni-color';


    let p1: Paragraphe = new Paragraphe();
    p1.title = `Identité d'un <b>produit/denrée</b>`;
    p1.lines = [
      `Il s’agit ici de la 1ère étape dans la création d’un <b>produit/denrée</b>. La 2ème étape consistera à ajouter au moins une <b>déclinaison</b> sur laquelle, ou lesquelles vous ajouterez au cours de la 3ème étape au moins un <b>article<b/>.`,
      `On paramètrera ici certains paramètres qui seront réutilisés en phase 2 et 3 afin de simplifier la création :`,
      `<ul>
<li>unité technique</li>
<li>type de fabrication</li>
</ul>`,

    ];


    dms.content = {
      intro: `Un <b>produit/denrée</b> est destiné à être utilisé en tant que composant d’un <b>produit/plat</b>.`,
      paragraphes: [p1]
    };


    return dms;
  };


  openEditObject = (dto: ObjectDTO) => {
  };

  openDeleteObject = (dto: ObjectDTO) => {
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSidenav);
  }

  /**
   * Indique si le produit this.produit peut être modifié.
   */
  canModify = (): boolean => {
    let response = this.gds.canModify(this.produit);
    return response;
  };

  private prepareSavePlat = (produit: ProduitDTO, formInfoGenerales: FormGroup, formFabrication: FormGroup): ProduitDTO => {


    produit.id = formInfoGenerales.controls['id'].value;
    produit.guid = formInfoGenerales.controls['guid'].value;
    produit.site = formInfoGenerales.controls['site'].value;
    produit.libelle = formInfoGenerales.controls['libelle'].value;
    produit.libellelong = formInfoGenerales.controls['libellelong'].value;
    produit.code = formInfoGenerales.controls['code'].value;

    produit.actif = formInfoGenerales.controls['actif'].value;
    produit.typeProduit = formFabrication.controls['typeProduit'].value;
    produit.familleProduit = formFabrication.controls['familleProduit'].value;
    produit.uniteDeMesure = formFabrication.controls['uniteDeMesure'].value;
    produit.ratioVolume = formFabrication.controls['ratioVolume'].value;
    produit.recetteNbPortions = formFabrication.controls['recetteNbPortions'].value;

    const contraintes: ContrainteModel[] = _cloneDeep(formFabrication.controls['regimes'].value);
    const pcaList = this.regimeSvc.convertToEntityContrainteAlim(contraintes, produit, 'id', 'produitId', this.produitContrainteAlimSvc.createEmptyFromContrainte);
    this.produit.produitContrainteAlimList = pcaList;

    const tacheFabrication = formFabrication.controls['tacheFabrication'].value;
    const ptFabrication = this.produitsSvc.initTacheOfProduit(this.produit, tacheFabrication);

    produit.produit__tacheList = [ptFabrication];

    return produit;
  };

  private prepareSaveDenree = (produit: ProduitDTO, formInfoGenerales: FormGroup, formFabrication: FormGroup, formApprovisionnement: FormGroup, formSortie: FormGroup): ProduitDTO => {

    if (this.formApprovisionnement &&  this.formApprovisionnement.controls['zoneDeStockage'] && this.formApprovisionnement.controls['zoneDeStockage'].value) {
      produit.id = formInfoGenerales.controls['id'].value;
      produit.guid = formInfoGenerales.controls['guid'].value;
      produit.site = formInfoGenerales.controls['site'].value;
      produit.libelle = formInfoGenerales.controls['libelle'].value;
      produit.libellelong = formInfoGenerales.controls['libellelong'].value;
      produit.code = formInfoGenerales.controls['code'].value;
      produit.actif = formInfoGenerales.controls['actif'].value;
      produit.typeProduit = formFabrication.controls['typeProduit'].value;
      produit.familleProduit = formFabrication.controls['familleProduit'].value;
      produit.uniteDeMesure = formFabrication.controls['uniteDeMesure'].value;
      produit.ratioVolume = formFabrication.controls['ratioVolume'].value;
      produit.dlc = formApprovisionnement.controls['dlc'].value;

      const tacheAppro = formApprovisionnement.controls['tacheApprovisionnement'].value;
      const ptAppro = this.produitsSvc.initTacheOfProduit(this.produit, tacheAppro);

      const tacheSortie = formSortie.controls['tacheSortie'].value;
      const ptSortie = this.produitsSvc.initTacheOfProduit(this.produit, tacheSortie);

      produit.produit__tacheList = [ptAppro, ptSortie];

      // zds peut ne pas être sélectionné, dans ce cas le form renvoie un '' au lieu d'un null. On doit forcer en null pour que le back comprenne
      const zds = formApprovisionnement.controls['zoneDeStockage'].value;
      produit.zoneDeStockage = this.utils.isNullOrEmpty(zds) ? null : zds;

      // pas nécessaire pour la denree, mais obligatoire pour enregistrement côté back
      produit.produitContrainteAlimList = [];


      return produit;
    } else {
      throw 'Veuillez renseigner une zone de stockage dans le formulaire.';
    }
  };
}
