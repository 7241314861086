<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div class="d-flex flex-rowa align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          pTooltip="Créer un modèle plc"
          (click)="openModelePlc(null)"
          [disabled]="!canCreate()">
        </button>
      </div>
    </div>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="modelesPlcList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé" [width]="100"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">

    <yo-cell-button (yoNavigation)="openModelePlc(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="canModify(cell.row.data)?'Modifier le modèle':'Voir le modèle'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>

    <yo-cell-button (yoNavigation)="openPanelDuplication($event, cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-copy'"
                    [pTooltip]="'Dupliquer le modèle plc '+ cell.row.data.libelle"
                    tooltipPosition="right"
                    showDelay="500"
    ></yo-cell-button>

  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} modèles de plats">
  </dxo-pager>

</dx-data-grid>

<p-overlayPanel #opDuplication>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          pTooltip=""
          showDelay="500">Site sur lequel dupliquer le modèle</label>
        <div class="col-sm-10">
          <p-dropdown [options]="auth2Svc.utilisateur.siteListLocaux"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="duplicationSiteSelected"
                      placeholder="sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'360px'}">
            <ng-template let-site pTemplate="selectedItem">
                <span style="vertical-align:middle; margin-left: .5em">
                <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site></span>
            </ng-template>
            <ng-template let-site pTemplate="item">
              <div style="position: relative;height: 25px;">
                <div style="font-size:14px;margin-top:4px">
                  <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button pButton
              label="Dupliquer"
              icon="fas fa-copy"
              (click)="duplicateModele()">
      </button>
    </div>
  </div>
</p-overlayPanel>

<p-overlayPanel #opImportXls>
  <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
         (change)="importXslx($event)">
</p-overlayPanel>

<!--PANEL EXPORT EXCEL-->
<p-overlayPanel #opExcel>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          pTooltip=""
          showDelay="500">Nombre de modèles à exporter</label>
        <div class="col-sm-10">
          <p-dropdown [options]="exportOptionList"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="selectExportOption"
                      placeholder="Sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'180px'}">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button pButton
              label="Exporter"
              icon="fas fa-file-excel"
              (click)="exportXslx()"
              [disabled]="selectExportOption === undefined ? true : false">
      </button>
    </div>
  </div>

</p-overlayPanel>
