import {Component, OnDestroy, OnInit} from '@angular/core';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {FormControl, FormGroup} from '@angular/forms';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {AdressesService} from '../../../core/services/entities/adresses.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash'
import {SiteService} from '../../../core/site.service';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ColumnsToHideDTO} from "../../../core/dtos/columns-to-hide.dto";
import {ColumnsPdfDTO} from "../../../core/dtos/columns-pdf-dto";
import {ColumnsPdfService} from "../../../core/services/columns-pdf/columns-pdf.service";
import {FormFieldFileSupplier} from "../../../core/suppliers/form-field-file-supplier";
import {DocumentService} from "../../../core/services/document.service";

@Component({
  selector: 'yo-up-ficheidentite',
  templateUrl: './up-ficheidentite.component.html',
  styleUrls: ['./up-ficheidentite.component.scss']
})
export class UpFicheidentiteComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  subSidenav: Subscription;

  subSite: Subscription;
  udpSite$;

  subLibelle: Subscription;
  udpLibelle$;

  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  fieldsDivers: FormFieldBaseSupplier<any>[];
  fieldsAdresseLivraison: FormFieldBaseSupplier<any>[];
  fieldsAdresseFacturation: FormFieldBaseSupplier<any>[];

  fieldsContacts: FormFieldBaseSupplier<any>[];

  form: FormGroup;
  formInfoGenerales: FormGroup;
  formAdresseLivraison: FormGroup;
  formAdresseFacturation: FormGroup;
  formSameAddress: FormGroup;
  formDivers: FormGroup;
  formColumnsBonCommandePdfSelected: FormGroup;
  estampilleVeterinaireFile: FormFieldFileSupplier;
  previewEstampilleVeterinaireFile: File;

  selectedUniteDeProduction: UniteDeProductionDTO;

  isSameAdresse: boolean = false;
  docError: boolean = false;

  ancienNomAdresseDeLivraison: string;
  ancienNomAdresseDeFacturation: string;

  columnsBonCommandePdf: ColumnsPdfDTO[] = [];

  columnsBonCommandePdfSelected: ColumnsPdfDTO[] = [];

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/edition-unites-production';

  constructor(public udpSvc: UnitesDeProductionService,
              public adressesSvc: AdressesService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              public utils: UtilsService,
              private siteService: SiteService,
              private columnsPdfSvc: ColumnsPdfService,
              private documentSvc: DocumentService) {}

  ngOnInit() {
    this.initEmptyFormAndData();
    this.initData();
    this.initEstampilleVeterinaire();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSidenav);
    this.utils.unsubscribe(this.subSite);
  }

  initEstampilleVeterinaire = (): void => {
    this.estampilleVeterinaireFile = new FormFieldFileSupplier({
      key: 'file',
      label: 'Estampille vétérinaire',
      value: '',
      entityName: `${this.udpSvc.getEntityName().toLowerCase()}-estampilleVeterinaire`,
      required: false,
      refresh: new Date().getTime(),
      ordre: 1
    });
  };

  previewImage = (): void => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('previewEstampilleVeterinaireFile').setAttribute('src', e.target.result);
    }
    reader.readAsDataURL(this.previewEstampilleVeterinaireFile);
  };

  onFileChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.previewEstampilleVeterinaireFile = event.target.files[0];
      if (!this.utils.isNullOrEmpty(this.previewEstampilleVeterinaireFile)) {
        this.previewImage();
      }
    }
  };

  initEmptyFormAndData = () => {
    this.adressesSvc.siteReadOnly = true;

    this.form = new FormGroup({});
    this.formInfoGenerales = new FormGroup({});
    this.formAdresseLivraison = new FormGroup({});
    this.formAdresseFacturation = new FormGroup({});
    this.formSameAddress = new FormGroup({sameAddress: new FormControl(true)});
    this.formDivers = new FormGroup({});
    this.formColumnsBonCommandePdfSelected = new FormGroup({columnsBonCommandePdfSelected: new FormControl([]), hideFinancialData: new FormControl(false) });
  };

  /**
   * Initialiser les formulaires infos générales, adresse livraison, adresse facturation  avec l'unite de production
   */
  initForm = (uniteDeProduction: UniteDeProductionDTO) => {

    this.siteService.findSitesLocauxSansUniteDeProductionByUser().subscribe(data => {
      // Création d'une Unité de production
      if (uniteDeProduction.id == 0) {
        let siteListeSansUniteDeProduction: SiteDTO[] = data;
        this.fieldsInfosGenerales = this.udpSvc.getFieldsAvecSitesSansUniteDeProduction(uniteDeProduction, siteListeSansUniteDeProduction);
        // Modification d'une Unité de production
      } else {
        this.fieldsInfosGenerales = this.udpSvc.getFields(uniteDeProduction);
      }
      this.ancienNomAdresseDeFacturation = this.selectedUniteDeProduction.adresseFacturation.nom;
      this.ancienNomAdresseDeLivraison = this.selectedUniteDeProduction.adresseLivraison.nom;

      let isSameAddress = this.adressesSvc.isSameAddress(this.selectedUniteDeProduction.adresseFacturation, this.selectedUniteDeProduction.adresseLivraison);
      this.isSameAdresse = isSameAddress;

      this.fieldsDivers = this.udpSvc.getFieldsDivers(this.selectedUniteDeProduction);

      this.fieldsAdresseLivraison = this.adressesSvc.getFields(uniteDeProduction.adresseLivraison);
      this.fieldsAdresseFacturation = this.adressesSvc.getFields(uniteDeProduction.adresseFacturation);

      this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
      this.formAdresseLivraison = this.gfs.toFormGroup(this.fieldsAdresseLivraison);
      this.formAdresseFacturation = this.gfs.toFormGroup(this.fieldsAdresseFacturation);
      this.formSameAddress = new FormGroup({sameAddress: new FormControl(isSameAddress)});

      this.formDivers = this.gfs.toFormGroup(this.fieldsDivers);

      if (uniteDeProduction.columnsToHideList && uniteDeProduction.columnsToHideList.length) {
        this.columnsBonCommandePdfSelected =
          this.columnsBonCommandePdf.filter(c => uniteDeProduction.columnsToHideList.findIndex((cc: ColumnsToHideDTO) => cc.columnPdf.id === c.id) > -1);
      }

      this.formColumnsBonCommandePdfSelected = new FormGroup({hideFinancialData: new FormControl(uniteDeProduction.hideFinancialDataCommands), columnsBonCommandePdfSelected: new FormControl(this.columnsBonCommandePdfSelected)});

      this.form = new FormGroup({
        formInfoGenerales: this.formInfoGenerales,
        formAdresseLivraison: this.formAdresseLivraison,
        formAdresseFacturation: this.formAdresseFacturation,
        formSameAddress: this.formSameAddress,
        formDivers: this.formDivers,
        formColumnsBonCommandePdfSelected: this.formColumnsBonCommandePdfSelected,
      });

      this.manageAdresseFacturationForm(isSameAddress);
    });
  };

  initData = () => {

    //abonnement à l'annonce de l'ouverture du sidenav, cas où le router n'est pas reloadé, on veut quand meme reinit
    this.subSidenav = this.routeMapSvc.sidenav$.subscribe(sidenav => {
      //on reinitialise les formgroups
      this.initForm(this.selectedUniteDeProduction);
    });

    this.columnsPdfSvc.getAll()
      .subscribe(response => {
        this.columnsBonCommandePdf = response.resultList;
      });

    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {

        this.selectedUniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
        this.initForm(this.selectedUniteDeProduction);

        this.udpSvc.checkEstampilleVeterinaire(this.selectedUniteDeProduction.id)
          .subscribe(r => this.selectedUniteDeProduction.hasEstampilleVeterinaire = r.one);

      });

    this.udpSite$ = this.udpSvc.site$;

    this.subSite = this.udpSite$.subscribe(value => {
      this.selectedUniteDeProduction.site = value;
      if (!this.utils.isCollectionNullOrEmpty(this.fieldsAdresseLivraison)) {
        const field = _find(this.fieldsAdresseLivraison, {'key': 'site'});
        this.formAdresseLivraison.controls[field.key].setValue(value);

      }
      if (!this.utils.isCollectionNullOrEmpty(this.fieldsAdresseFacturation)) {
        const field = _find(this.fieldsAdresseLivraison, {'key': 'site'});
        this.formAdresseFacturation.controls[field.key].setValue(value);
      }

    });

    this.udpLibelle$ = this.udpSvc.libelle$;
    this.subLibelle = this.udpLibelle$.subscribe(value => {
      if (!this.utils.isCollectionNullOrEmpty(this.fieldsAdresseLivraison)) {
        const field = _find(this.fieldsAdresseLivraison, {'key': 'nom'});
        if (UtilsService.staticIsNullOrEmpty(this.ancienNomAdresseDeLivraison)) {
          this.formAdresseLivraison.controls[field.key].setValue(value);
        }

      }
      if (!this.utils.isCollectionNullOrEmpty(this.fieldsAdresseFacturation)) {
        const field = _find(this.fieldsAdresseLivraison, {'key': 'nom'});
        if (UtilsService.staticIsNullOrEmpty(this.ancienNomAdresseDeFacturation)) {
          this.formAdresseFacturation.controls[field.key].setValue(value);
        }
      }


    });

  };

  onChangeSameAddress = event => {
    this.isSameAdresse = event.checked;
    this.manageAdresseFacturationForm(event);
  };

  deleteFile = (field: any) => {
    const entityId = this.selectedUniteDeProduction.id;
    this.documentSvc.removeByEntity(field.entityName, entityId, field.ordre).subscribe(response => {
      // rafraichir l'image ou le document
      field.refresh = new Date().getTime();
      this.udpSvc.checkEstampilleVeterinaire(this.selectedUniteDeProduction.id)
        .subscribe(r => this.selectedUniteDeProduction.hasEstampilleVeterinaire = r.one); // refresh ne fct pas
    });
  };

  saveFicheIdentite = () => {

    //Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);

    //On enregsitre que si le formulaire est valide
    if (this.form.valid) {

      this.selectedUniteDeProduction.libelle = this.formInfoGenerales.controls['libelle'].value;
      this.selectedUniteDeProduction.code = this.formInfoGenerales.controls['code'].value;
      this.selectedUniteDeProduction.actif = this.formInfoGenerales.controls['actif'].value;

      this.selectedUniteDeProduction.adresseLivraison = this.adressesSvc.prepareAdresseForSave(this.formAdresseLivraison, this.udpSvc.getEntityName());
      if (this.formSameAddress.controls['sameAddress'].value) {
        this.selectedUniteDeProduction.adresseFacturation = _cloneDeep(this.selectedUniteDeProduction.adresseLivraison);
        this.selectedUniteDeProduction.adresseFacturation.id = this.formAdresseFacturation.get('id').value;
      } else {
        this.selectedUniteDeProduction.adresseFacturation = this.adressesSvc.prepareAdresseForSave(this.formAdresseFacturation, this.udpSvc.getEntityName());
      }

      this.selectedUniteDeProduction.infosLivraison = this.formDivers.controls['infosLivraison'].value;
      this.selectedUniteDeProduction.infosFacturation = this.formDivers.controls['infosFacturation'].value;
      this.selectedUniteDeProduction.signature = this.formDivers.controls['signature'].value;

      const photo = this.formInfoGenerales.value.file;
      const estampilleVeterinaire = this.previewEstampilleVeterinaireFile;

      const columns = this.formColumnsBonCommandePdfSelected.controls['columnsBonCommandePdfSelected'].value;
      const columnsToHideList: ColumnsToHideDTO[] = columns.map((col: ColumnsPdfDTO) => {
        return ({code: col.code, idUdp: this.selectedUniteDeProduction.id, columnPdf: col});
      });

      this.selectedUniteDeProduction.columnsToHideList = columnsToHideList;
      this.selectedUniteDeProduction.hideFinancialDataCommands = this.formColumnsBonCommandePdfSelected.controls['hideFinancialData'].value;

      this.udpSvc.saveFicheIdentite(this.selectedUniteDeProduction, photo, estampilleVeterinaire).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {
          //annoncer que la fiche d'identite a été sauvegardée. La grille des unites de prod doit être abonnée pour faire la mise à jour
          this.udpSvc.announceSavedDTO(response);
          this.selectedUniteDeProduction = response.one;
          this.initForm(this.selectedUniteDeProduction);
          this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GUP_UPRO, this.selectedUniteDeProduction.id, 'ficheidentite']);
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Enregistrement de l'unité de production "${this.selectedUniteDeProduction.libelle.toUpperCase()}"'.`, '', 3000);

          this.udpSvc.checkEstampilleVeterinaire(this.selectedUniteDeProduction.id)
            .subscribe(r => this.selectedUniteDeProduction.hasEstampilleVeterinaire = r.one);

        }
      });

    }
  };


  /**
   * Activer ou pas le formulaire d'adresse facturation
   * @param {boolean} sameAddress
   */
  private manageAdresseFacturationForm = (sameAddress: boolean) => {
    if (sameAddress) {
      this.form.removeControl('formAdresseFacturation');
    } else {
      this.form.addControl('formAdresseFacturation', this.formAdresseFacturation);
    }
  };


  getHelp = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Fiche d'identité d'une unité de production.`;

    let p1: Paragraphe = new Paragraphe();
    let p2: Paragraphe = new Paragraphe();
    let p3: Paragraphe = new Paragraphe();
    p1.title = `Une unité de production (UP) est un lieu de fabrication auquel un site est rattaché.`;
    p2.title = `Cette UP porte donc tous les paramètres administratifs et fonctionnels nécessaires à l'activité d'un site de production.`;
    p3.title = `Les données administratives à renseigner permettent de déterminer l'identité et les coordonnées d'une UP.`;

    dms.content = {
      intro: ``,
      paragraphes: [p1, p2, p3]

    };

    return dms;
  };
}
