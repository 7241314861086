import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ZoneDeStockageDTO} from '../../dtos/zone-de-stockage-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {UniteDeProduction__ZoneDeStockageDTO} from '../../dtos/unite-de-production__zone-stockage-dto';
import {startCase as _startCase} from 'lodash';
import {shareReplay} from 'rxjs/operators';
import {GenericDatagridService} from '../generics/generic-datagrid.service';

@Injectable()
export class ZonesDeStockageService extends GenericHandler<ZoneDeStockageDTO> {

  zdsEnvironnement: ZoneDeStockageDTO[]=[];

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES ZONES DE STOCKAGE';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE ZONE DE STOCKAGE';
  }


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds:GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });
  }

  getEntityName(): string {
    return 'zoneDeStockage';
  }

  getFields(dto: ZoneDeStockageDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une ZoneDeStockage est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new ZoneDeStockageDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        excelType:'string',
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        excelType:'string',
        maxLength: 50,
        required: true,
        order: 3
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        excelType:'boolean',
        required: false,
        order: 5
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true).pipe(
      shareReplay())
      .subscribe(response => {
        this.zdsEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.zdsEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.zdsEnvironnement);
      });

  }

  createZdsFromUdpZds(udpZds:UniteDeProduction__ZoneDeStockageDTO) : ZoneDeStockageDTO{
    const zds = new ZoneDeStockageDTO();
    zds.actif = udpZds.actifZoneDeStockage;
    zds.code = udpZds.codeZoneDeStockage;
    zds.id = udpZds.idZoneDeStockage;
    zds.libelle = udpZds.libelleZoneDeStockage;

    return zds;
  }

  createEmptyDTO(): ZoneDeStockageDTO {

    return new ZoneDeStockageDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA ZONE DE STOCKAGE '${data.libelle.toUpperCase()}'`;
  }
}
