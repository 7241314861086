import {Component, Input, OnInit} from '@angular/core';
import {MAT_DISPLAY} from '../../../core/constants';

@Component({
  selector: 'yo-button-delete',
  template: `

 
    <ng-container *ngIf="!yoHiddenMode">
      <!--Affichage en mini-->
      <button pButton *ngIf="yoMatDisplay === 'mini'"
              [type]="yoType"
              class="p-button-danger"
              icon="fas fa-trash"
              [pTooltip]="yoMdTooltip"
              [showDelay]="yoMdTooltipShowDelay"
              [disabled]="yoDisabled">        
      </button>

      <!--Affichage en  grand-->
      <button pButton *ngIf="yoMatDisplay === 'normal'"
              [type]="yoType"
              class="p-button-danger"
              icon="fas fa-trash"
              [pTooltip]="yoMdTooltip"
              [showDelay]="yoMdTooltipShowDelay"
              [disabled]="yoDisabled"
              [label]="yoLabel | uppercase"
      >        
      </button>
    </ng-container>


  `,
  styleUrls: ['./button-delete.component.scss']
})
export class ButtonDeleteComponent implements OnInit {

  @Input() yoLabel: string = '';
  @Input() yoDisabled: boolean = false;
  @Input() yoMdTooltip: string = 'Supprimer';
  @Input() yoType: string = 'button';
  @Input() yoMdTooltipShowDelay: number = 1500;
  @Input() yoMatDisplay: MAT_DISPLAY = 'mini';
  @Input() yoHiddenMode: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

}
