<p-dialog
  [(visible)]="displayDialog"
  [contentStyle]="{'overflow':'visible'}"
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="true">

  <!--HEADER-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Changement de mot de passe
    </div>
  </ng-template>


  <!--CONTENT-->
  <form [formGroup]="form" novalidate>

    <p>
      Entrez votre adresse mail puis presser sur le bouton "Envoyer".
      <br/>
      Vous allez recevoir un email vous expliquant la procédure à suivre.
    </p>


    <div class="p-field p-grid">
      <label for="email" class="p-col-fixed width-120 bold" >Adresse mail</label>
      <input pInputText id="email"  class="width-300" [(ngModel)]="theEmail" [ngModelOptions]="{standalone: true}">
    </div>
  </form>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <button pButton (click)="resetPasswordStep1()" label="Envoyer"
            [disabled]="isEnvoyerDisabled()"></button>
    <button pButton (click)="closeDialog()"
            class="p-button-secondary"
            label="Annuler"></button>
  </ng-template>

</p-dialog>




