import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import {TypeEquipementPmsDto} from "../../dtos/pms/type-equipement-pms-dto";

export const URL_GET_ALL = 'dolrest/types-equipement-pms/all';
export const URL_SAVE = 'dolrest/types-equipement-pms/';
export const URL_DELETE = 'dolrest/types-equipement-pms/';

@Injectable({
  providedIn: 'root'
})
export class TypeEquipementPmsService {
  private subjectOpenDialog = new Subject<TypeEquipementPmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectTypeEquipementSaved = new Subject<TypeEquipementPmsSavedSupplier>();
  typeEquipementSaved$ = this.subjectTypeEquipementSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Récupère tous les types d'équipement pms
   * @returns {Observable<TypeEquipementPmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<TypeEquipementPmsDto>> => this.httpSvc.get(URL_GET_ALL, null);


  /**
   * Sauvegarde un type d'équipement pms
   * @param typeEquipement pms
   */
  save = (typeEquipement: TypeEquipementPmsDto) : Observable<ResponseWrapper<TypeEquipementPmsDto>> => {
    return this.httpSvc.post(URL_SAVE, typeEquipement, null);
  }

  /**
   * Supprime un ou plusieurs type(s) d'équipement pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: TypeEquipementPmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (typeEquipement: TypeEquipementPmsDto, isUpdate: boolean = false) : void => {
    this.subjectTypeEquipementSaved.next({ isUpdate, typeEquipement } as TypeEquipementPmsSavedSupplier);
  }
}

export interface TypeEquipementPmsSavedSupplier {
  isUpdate: boolean;
  typeEquipement: TypeEquipementPmsDto;
}
