import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/index";
import ModeleConditionnementPlatDTO from "../../../../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {GestionUniteProduction__ModeleConditionnementPlatService} from "../../../../../core/services/gestion-unites-productions/unite-production__modele-conditionnement-plat.service";
import {ModelesPlatsService} from "../../../../../core/services/conditionnements/modeles-plats.service";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../../../../core/dtos/unite-de-production-modele-conditionnement-plat-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";

@Component({
  selector: 'yo-dialog-ajout-udp-mcp',
  templateUrl: './dialog-ajout-udp-mcp.component.html',
  styleUrls: ['./dialog-ajout-udp-mcp.component.scss']
})
export class DialogAjoutUdpMcpComponent implements OnInit, OnDestroy {

  hasIDistri = false;

  displayDialog = false;

  subOpenDialog: Subscription;
  subAllMcp: Subscription;

  mcpList: ModeleConditionnementPlatDTO[] = [];
  mcpSelectedList: ModeleConditionnementPlatDTO[] = [];

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Création d\'une association';

  idUdp: number;
  associationsList: UniteDeProduction__ModeleConditionnementPlatDto[] = [];

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService,
              private modelsPlatsSvc: ModelesPlatsService) {
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initModels();
    this.initForm();
    this.openDialogSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subAllMcp);
  }

  initModels = (): void => {
    this.subAllMcp = this.modelsPlatsSvc.getAllModelesConditionnementsPlats()
      .subscribe(response => {
        this.mcpList = response.resultList;
      });
  };

  openDialogSubscription = (): void => {
    this.subOpenDialog = this.udpMcpSvc.openDialog$
      .subscribe((id: number) => {
        this.displayDialog = true;
        this.idUdp = id;
        this.initForm();
      });
  };

  initAssociationsForBackEnd = (): UniteDeProduction__ModeleConditionnementPlatDto => {
    const u: UniteDeProduction__ModeleConditionnementPlatDto = new UniteDeProduction__ModeleConditionnementPlatDto();
    u.id = 0;
    u.idUniteDeProduction = this.idUdp;
    u.idModeleConditionnementPlat = 0;
    return u;
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canSave = (): boolean => this.hasIDistri;

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      const modelesSelected = this.form.controls['models'].value;
      this.associationsList = modelesSelected.map(model => {
        const u: UniteDeProduction__ModeleConditionnementPlatDto = this.initAssociationsForBackEnd();
        u.idModeleConditionnementPlat = model.id;
        return u;
      });

      this.udpMcpSvc.save(this.associationsList)
        .subscribe(response => {
          this.udpMcpSvc.announceAssociationsSaved(response.resultList);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Création de l\'association réalisée avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      models: new FormControl(this.mcpSelectedList, [Validators.required]),
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Création d'une association modèle de conditionnement plat - unité de production`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
