import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ProduitDeclinaisonDTO} from "../../../../../core/dtos/produit-declinaison-dto";
import {combineLatest, Subject, Subscription} from "rxjs";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {BesoinsService} from "../../../../../core/services/gestion-commandes/besoins.service";
import {debounceTime} from "rxjs/operators";
import {AppellationDTO} from "../../../../../core/dtos/appellations-dto";
import {AllergeneDTO} from "../../../../../core/dtos/allergene-dto";
import {AppellationsService} from "../../../../../core/services/entities/appellations.service";
import {AllergenesService} from "../../../../../core/services/entities/allergenes-service.service";
import {concat as _concat, find as _find, remove as _remove} from 'lodash'
import {Chips} from "primeng/chips";
import {ProduitDeclinaisonService} from "../../../../../core/services/entities/produit-declinaison.service";
import {
  GenericRequestSupplier,
  Predicat,
  Search,
} from "../../../../../core/suppliers/generics/generic-request-supplier";
import {DxDataGridComponent, DxTreeViewComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {FamillesProduitService} from "../../../../../core/services/entities/familles-produit.service";
import {FamilleProduitDTO} from "../../../../../core/dtos/famille-produit-dto";
import {DeclinaisonsService} from "../../../../../core/services/declinaisons/declinaisons.service";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";

@Component({
  selector: 'yo-ajout-denree-proposition-commande',
  templateUrl: './ajout-denree-proposition-commande.component.html',
  styleUrls: ['./ajout-denree-proposition-commande.component.scss']
})
export class AjoutDenreePropositionCommandeComponent implements OnInit, OnDestroy {

  @ViewChild('basketProduitDeclinaison') basketProduitDeclinaison: Chips;
  @ViewChild('treeViewProduitDeclinaison', { static: false }) treeView: DxTreeViewComponent;
  @ViewChild('gridAjoutDenreePropositionCommande') gridAjoutDenree: DxDataGridComponent;

  displayDialog: boolean = false;
  fullScreen: boolean = true;
  customStore: CustomStore;
  deltaGrid: number = 400;

  produitDeclinaisonList: ProduitDeclinaisonDTO[] = [];
  produitDeclinaisonListSelected: ProduitDeclinaisonDTO[] = [];
  appellationList: AppellationDTO[] = [];
  appellationListSelected: number[] = [];
  allergeneList: AllergeneDTO[] = [];
  allergeneListSelected: number[] = [];
  familleProduitList: FamilleProduitDTO[] = [];
  idFamilleProduitListSelected: number[] = [];
  selectedRowKeys: number[] = [];
  declinaisonList: DeclinaisonDTO[] = [];
  declinaisonListSelected: number[] = [];
  treeBoxValue: string[];
  treeDataSource: any;

  denreeSearchValue: string = '';

  private subjectDenreeLibelleSearch = new Subject<String>();
  denreeLibelleSearch$ = this.subjectDenreeLibelleSearch.asObservable();

  subOpenDialog: Subscription;
  subDenreeLibelleSearch: Subscription;

  isCollapsedSearchPanel: boolean;

  constructor(public utils: UtilsService,
              private cd: ChangeDetectorRef,
              private besoinSvc: BesoinsService,
              private appelationSvc: AppellationsService,
              private allergeneSvc: AllergenesService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private familleProduitSvc: FamillesProduitService,
              private declinaisonSvc: DeclinaisonsService) {
  }

  ngOnInit() {
    this.initData();
    this.subscriptionOpenDialog();
    this.subscriptionDenreeLibelle();
    this.customStore = this.initCustomStore();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subDenreeLibelleSearch);
  }

  initData = (): Promise<any> => {
    const all$ = combineLatest([
      this.appelationSvc.findAll(),
      this.allergeneSvc.findAll(),
      this.familleProduitSvc.findAll(false),
      this.declinaisonSvc.findAllByParams(false)
    ]);

    return all$.toPromise().then(response => {
      this.appellationList = response[0].resultList;
      this.allergeneList = response[1].resultList;
      this.familleProduitList = response[2].resultList;
      this.declinaisonList = response[3].resultList;
    });
  }

  subscriptionOpenDialog = (): void => {
    this.subOpenDialog = this.besoinSvc.openAjoutDenreePropositionCommande$.subscribe(data => {
      this.displayDialog = true;
    });
  }

  subscriptionDenreeLibelle = (): void => {
    this.subDenreeLibelleSearch = this.denreeLibelleSearch$.pipe(debounceTime(500))
      .subscribe(async (data: string) => {
        this.denreeSearchValue = data;
        await this.gridAjoutDenree.instance.refresh();
      });
  }

  announceDenreeLibelleSearch = (data?: any): void => {
    this.subjectDenreeLibelleSearch.next(data.value);
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.allergeneListSelected = [];
    this.appellationListSelected = [];
    this.declinaisonListSelected = [];
    this.denreeSearchValue = '';

    this.produitDeclinaisonListSelected = [];

    //reinitialisation du treeview
    this.idFamilleProduitListSelected = [];
    this.treeBoxValue = [];
    this.treeView?.instance.unselectAll();
  }

  isDisabledSaveBtn = (): boolean => {
    return this.produitDeclinaisonListSelected.length > 0;
  }

  onRemoveChipProduitDeclinaison = (event: any): void => {
    _remove(this.produitDeclinaisonListSelected, (item: ProduitDeclinaisonDTO) => item.id === event.value.id);
  }

  addDenreeToBasket = (produitDeclinaison: ProduitDeclinaisonDTO): void => {
    const selectedDenree: ProduitDeclinaisonDTO = _find(this.produitDeclinaisonListSelected, (item: ProduitDeclinaisonDTO) => {
      return item.id === produitDeclinaison.id;
    });
    if (!selectedDenree) {
      this.produitDeclinaisonListSelected.push(produitDeclinaison);
      this.ajustGrid();
      this.basketProduitDeclinaison?.cd.markForCheck();
    }
  }

  sendProduitDeclinaisonList = (): void => {
    this.produitDeclinaisonSvc.announceProduitDeclinaisonListPropCommande(this.produitDeclinaisonListSelected);
    this.closeDialog();
  }

  onToggleSearchPanel = (event: any): void => {
    this.isCollapsedSearchPanel = event.collapsed;
    this.ajustGrid();
  }

  ajustGrid = (): void => {
    if (this.isCollapsedSearchPanel) {
      this.deltaGrid = (this.produitDeclinaisonListSelected && this.produitDeclinaisonListSelected.length) ? 655 : 380;
    } else {
      this.deltaGrid = (this.produitDeclinaisonListSelected && this.produitDeclinaisonListSelected.length) ? 645 : 440;
    }
  }

  onChangeAppellation = (event: any): void => {
    this.appellationListSelected = event.value;
    this.gridAjoutDenree.instance.refresh();
  }

  onChangeAllergene = (event: any): void => {
    this.allergeneListSelected = event.value;
    this.gridAjoutDenree.instance.refresh();
  }

  onChangeDeclinaison = (event: any): void => {
    this.declinaisonListSelected = event.value;
    this.gridAjoutDenree.instance.refresh();
  }

  onChangeParentDenreeSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.idFamilleProduitListSelected = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.gridAjoutDenree.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.familleProduitList.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = _concat([...result], this.reflexionSearchId([...this.familleProduitList], fp.id))
        }
      })
    });

    result = _concat([...result], idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = _concat([...idResultList], this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  getGenericRequestSupplier = (idAppelationList: number[], idAllergeneList: number[], denreeSearchValue: string, idFamilleProduitListSelected: number[],
                               idDeclinaisonListSelected: number[]): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier(this.produitDeclinaisonSvc.getEntityName());
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = `produitDeclinaison.produit.typeProduit.fabrique`;
    // predicat1.operator = PREDICAT_OPERATOR.Equals;
    // predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = false + '';
    search.predicats.push(predicat1);

    if (denreeSearchValue.length) {
      const predicat4 = new Predicat();
      predicat4.path = `produitDeclinaison.libelle`;
      // predicat4.operator = PREDICAT_OPERATOR.Contains;
      // predicat4.type = PREDICAT_TYPE.String;
      predicat4.value = denreeSearchValue;
      search.predicats.push(predicat4);
    }

    if (idAppelationList.length) {
      const predicat = new Predicat();
      predicat.path = `produitDeclinaison.appelation`;
      // predicat.operator = PREDICAT_OPERATOR.Equals;
      // predicat.type = PREDICAT_TYPE.Boolean;
      predicat.ids = idAppelationList;
      search.predicats.push(predicat);
    }

    if (idAllergeneList.length) {
      const predicat = new Predicat();
      predicat.path = `produitDeclinaison.allergene`;
      // predicat.operator = PREDICAT_OPERATOR.Equals;
      // predicat.type = PREDICAT_TYPE.Boolean;
      predicat.ids = idAllergeneList;
      search.predicats.push(predicat);
    }

    if (idFamilleProduitListSelected.length) {
      const predicat = new Predicat();
      predicat.path = `produitDeclinaison.produit.familleProduit`;
      // predicat.operator = PREDICAT_OPERATOR.Equals;
      // predicat.type = PREDICAT_TYPE.Boolean;
      predicat.ids = idFamilleProduitListSelected;
      search.predicats.push(predicat);
    }

    if (idDeclinaisonListSelected.length) {
      const predicat = new Predicat();
      predicat.path = `produitDeclinaison.declinaison`;
      // predicat.operator = PREDICAT_OPERATOR.Equals;
      // predicat.type = PREDICAT_TYPE.Boolean;
      predicat.ids = idDeclinaisonListSelected;
      search.predicats.push(predicat);
    }

    grs.search = search;

    grs.page = this.gridAjoutDenree.instance.pageIndex() + 1;
    grs.size = this.gridAjoutDenree.instance.pageSize();

    return grs;
  }

  initCustomStore = (): CustomStore => {
    const cs = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const grs: GenericRequestSupplier = this.getGenericRequestSupplier(this.appellationListSelected, this.allergeneListSelected, this.denreeSearchValue, this.idFamilleProduitListSelected, this.declinaisonListSelected);
        return this.produitDeclinaisonSvc.searchProduitDeclinaisonByParams(grs, true).toPromise()
          .then(response => {
            this.produitDeclinaisonList = response.resultList;
            return {
              data: this.produitDeclinaisonList,
              totalCount: response.totalElements
            }
        });
      },
      update: (key, values) => {
        return new Promise((resolve, reject) => resolve({
          data: values,
          totalCount: this.produitDeclinaisonList.length
        }));
      }
    });
    return cs;
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }
}
