<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  positionTop="10"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [modal]="true">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
     <span class="mg-r-5">
     <i class="fas fa-book-open mg-r-5"></i> Catalogue des articles de la filiale {{filiale?.libelle}}
    </span>
    </div>
  </ng-template>

  <div class="d-flex justify-content-between mg-b-10">
    <div>
      <p-checkbox
        (onChange)="onChangeIsVisibleCaInherted()"
        [binary]="true"
        label="Articles hérités"
        [(ngModel)]="isVisibleCaInherited">
      </p-checkbox>
    </div>
  </div>


  <dx-data-grid
    [dataSource]="customStore"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(220)"
    [width]="utils.getWindowAvailableWidth(100)"
    [remoteOperations]="true"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>
    <dxi-column dataField="id" caption="Actions" [width]="100"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="produitArticle.libelle" caption="Article" [width]="500"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="fournisseurOwnerLibelle" caption="Hérité de"
                [width]="60"
                [allowFiltering]="false"
                [allowSorting]="false"
                cellTemplate="fournisseurOwnerLibelleCellTemplate">
    </dxi-column>

    <dxi-column dataField="referenceArticle"
                caption="Réf.Fournisseur"
                [width]="200"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="uniteDeCommande.libelle" caption="Unité de commande"
                [width]="350"
                [allowFiltering]="true">
    </dxi-column>


    <dxo-filter-row
      [visible]="true"></dxo-filter-row>

    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[20, 50, 100]"
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} Articles">
    </dxo-pager>

    <!--    TEMPLATE-->

    <div *dxTemplate="let cell of 'fournisseurOwnerLibelleCellTemplate'">
      <div class="size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
           [pTooltip]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
      <span class="fa-stack fa-2x">
        <i class="fas fa-circle fa-stack-2x"></i>
        <strong class="fa-stack-1x custom-letter">H</strong>
      </span>
      </div>
    </div>

    <div *dxTemplate="let cell of 'actionsCellTemplate'">

      <ng-container *ngIf="!isDisabledDeleteBtn(cell.row.data)">
        <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoMaxWidth]="true"
                        [yoWidthPercent]="30"
                        [yoTextAlign]="'center'"
                        [yoIconClass]="'fa fa-trash'"
                        [pTooltip]="'Supprimer l\'article'"
                        tooltipPosition="right"
                        showDelay="500"
        ></yo-cell-button>
      </ng-container>

    </div>

  </dx-data-grid>

</p-dialog>
