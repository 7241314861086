<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button type="button"
            pButton
            icon="fa fa-plus"
            label="CRÉER"
            (click)="openNewEnvironnement()"
            [pTooltip]="'Créer un environnement'">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="environnementsForGrille"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" cellTemplate="templateActions" caption="Actions" [allowFiltering]="false" [width]="100"></dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
  <dxi-column dataField="code" caption="Code"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'">
    <div class="d-flex flex-row">
      <yo-cell-button (yoNavigation)="openEnvironnement(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-edit'"
                      [yoTooltip]="'Modifier l\'environnement '+ cell.row.data.libelle">
      </yo-cell-button>
      <yo-cell-button (yoNavigation)="confirmDelete(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-trash'"
                      [yoTooltip]="'Supprimer l\'environnement '+ cell.row.data.libelle">
      </yo-cell-button>
    </div>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} environnement(s)">
  </dxo-pager>

</dx-data-grid>
