<p-toast id="toast-root" key="root" baseZIndex="9999"  [position]="MSG_POSITION.BOTTOM_RIGHT">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1" [className]="'wrapper-msg-error'">
      <div style="text-align: center; font-weight: bold" innerHtml="{{message.summary}}"></div>
    </div>
  </ng-template>
</p-toast>
<p-toast key="root_error" baseZIndex="9999" [position]="MSG_POSITION.BOTTOM_RIGHT">
  <ng-template let-message pTemplate="message">
    <div class="d-flex flex-column">
      <div>
        <i class="pi pi-exclamation-triangle"></i>
        <div>
          <strong>{{message.summary}}</strong>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="mr-auto">
        </div>
        <div>
          <button type="button" pButton (click)="yoErrorHelp.getHelp()" label="Détails"
                  class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>


<yo-confirm-dialog></yo-confirm-dialog>

<p-sidebar
  [closeOnEscape]="false"

  appendTo="body"
  [style]="{width:'90%'}"
  [(visible)]="utils.sidenav"
  position="right"
  (onHide)="onHideSideBar()"
  #sidebar
>
  <router-outlet name="secondary"></router-outlet>
  <p-toast key="sidebar" [position]="MSG_POSITION.BOTTOM_RIGHT"></p-toast>
  <p-toast key="sidebar_center" [position]="MSG_POSITION.BOTTOM_CENTER"></p-toast>
</p-sidebar>

<router-outlet name="tertiary"></router-outlet>

<ng-container *ngIf="isLoggedIn$ | async; else notLogged">
  <div class="d-flex toolbar" [style.height.px]="HEADER_HEIGHT_PX">
    <div class="mr-auto mg-l-40">
      <yo-breadcrumb [selectedItem]="itemNavSvc.selectedMenuItem"></yo-breadcrumb>
    </div>
    <div>

      <ng-container *ngIf="environnement$ | async as environnement">
        <span
          class="font-12 cursor mg-r-10"
          pTooltip="Environnement sélectionné : {{selectedEnvironnementDTO?.libelle}}"
          showDelay="1000"
          (click)="initItemsMenuEnvrionnementsAndDisplay($event)"
          #environnement>
          <i class="fa fa-globe mg-r-5"></i>{{selectedEnvironnementDTO?.libelle}}
        </span>

        <p-menu id="menuEnvironnements" #menuEnvironnements  [popup]="true" [model]="itemsMenuEnvironnements"></p-menu>

      </ng-container>


      <span (click)="openPreferences()" #loggedIn [pTooltip]="getResponsiveLabelLogin('long')"
            class="font-12 cursor mg-r-10"
            showDelay="500">
        <i class="fa fa-user-circle mg-r-5"></i>
        {{getResponsiveLabelLogin(bandeauWidthType)}}
      </span>

      <span class="mg-r-10">
              <i (click)="logout()" class="fa fa-power-off font-22 mg-r-5 cursor"></i>
      </span>

    </div>
  </div>

  <div class="d-flex">
    <ng-container>
      <div class="d-flex left-small-menu">
        <yo-left-app-menu></yo-left-app-menu>
      </div>
      <div class="d-flex flex-grow-1">
        <main #mainContent class="content">
          <router-outlet></router-outlet>
        </main>
      </div>
    </ng-container>
  </div>
</ng-container>


<!--REDIRIGER VERS PAGE DE LOGIN-->
<ng-template #notLogged>
  <router-outlet></router-outlet>
</ng-template>

<yo-loading></yo-loading>
<yo-help [dialogMsgSupplier]="utils.detailError" iconClick="hidden" [width]="800" #yoErrorHelp></yo-help>

<yo-preferences-dialog-edit></yo-preferences-dialog-edit>

<p-blockUI [blocked]="isBlockedUI"></p-blockUI>
