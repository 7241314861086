import {Component} from '@angular/core';
import {ZonesDeStockageService} from '../../core/services/entities/zones-de-stockage.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-zones-de-stockage',
  templateUrl: './zones-de-stockage.component.html',
  styleUrls: ['./zones-de-stockage.component.scss']
})
export class ZonesDeStockageComponent {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/zones-de-stockage';

  constructor(public zoneDeStockageSvc:ZonesDeStockageService) { }


}
