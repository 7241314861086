import {Component, OnDestroy, OnInit} from '@angular/core';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {FamilleGemrcnDTO} from '../../../core/dtos/famille-gemrcn-dto';
import {ActivatedRoute} from '@angular/router';
import {GemrcnSupplier} from './gemrcn-resolver.service';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {sortBy as _sortBy, values as _values} from 'lodash'
import {FamilleNutritionnelleProduitDTO} from '../../../core/dtos/famillenutritionelle-produit-dto';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ProduitsService, ProduitSupplier} from '../../../core/services/entities/produits.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'yo-valeurnutri-produit',
  templateUrl: './gemrcn-produit.component.html',
  styleUrls: ['./gemrcn-produit.component.scss']
})
export class GemrcnProduitComponent implements OnInit, OnDestroy {

  private subInitAll: Subscription;
  private subParentRoute: Subscription;


  checkedMap: { [key: string]: boolean } = {};


  familleGemrcnList: FamilleGemrcnDTO[] = [];
  produitDeclinaisonList: ProduitDeclinaisonDTO[];
  produit: ProduitDTO;

  cols: any[] = [];

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-gemrcn';

  constructor(private route: ActivatedRoute,
              public gds: GenericDatagridService,
              private produitSvc: ProduitsService,
              public auth2Svc: Auth2Service,
              private msgSvc: MessageService,
              private ggSvc: GestionGemrcnService,
              public utils: UtilsService) {
  }


  initCheckedMap = (familleGemrcnList: FamilleGemrcnDTO[], produitDeclinaisonList: ProduitDeclinaisonDTO[]): { [key: string]: boolean } => {
    let checkedMap: { [key: string]: boolean } = {};

    if (!this.utils.isCollectionNullOrEmpty(familleGemrcnList)) {
      if (!this.utils.isCollectionNullOrEmpty(produitDeclinaisonList)) {
        for (let fg of familleGemrcnList) {
          for (let pd of produitDeclinaisonList) {
            let firstElement = this.utils.getFirstElement(pd.famillesNutritionnellesProduits.filter(item => item.idFamille === fg.id));
            checkedMap[this.getCheckedMapKey(fg.id, pd.id)] = firstElement ? true : false;
          }
        }
      }
    }

    // console.log(checkedMap);
    return checkedMap;
  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Familles GEMRCN d'un <b>produit/plat</b>`;
    p1.lines = [
      `Une <b>déclinaison</b> peut avoir plusieurs familles GEMRCN.`,
    ];

    dms.content = {
      intro: `Une famille GEMRCN d’une <b>déclinaison</b> permet de réaliser une analyse de fréquence dans un menu.`,
      paragraphes: [p1]
    };

    return dms;
  };


  getCheckedMapKey = (idFamilleGemrcn: number, idProduitDeclinaison: number): string => idFamilleGemrcn + '-' + idProduitDeclinaison;

  getCheckedModel = (idFamilleGemrcn: number, idProduitDeclinaison: number): boolean => this.checkedMap[this.getCheckedMapKey(idFamilleGemrcn, idProduitDeclinaison)];

  changeCheckedModel = (idFamilleGemrcn: number, idProduitDeclinaison: number, event: any) => {
    this.checkedMap[this.getCheckedMapKey(idFamilleGemrcn, idProduitDeclinaison)] = event.checked;
  };

  ngOnInit() {


    this.subParentRoute = this.route.parent.data
      .subscribe((data: { produitSupplier: ProduitSupplier }) => {
        this.produit = data.produitSupplier.produitDTO;
      });

    this.subInitAll = this.route.data.subscribe((data: { gemrcnSupplier: GemrcnSupplier }) => {

      this.familleGemrcnList = data.gemrcnSupplier.familleGemrcnList;
      this.familleGemrcnList = _sortBy(this.familleGemrcnList, 'ordre');
      this.produitDeclinaisonList = data.gemrcnSupplier.produitDeclinaisonList;
      this.produitDeclinaisonList = this.produitSvc.sortListeProduitDeclinesDTO(this.produitDeclinaisonList);
      this.checkedMap = this.initCheckedMap(this.familleGemrcnList, this.produitDeclinaisonList);

      this.cols = this.initCols(this.produitDeclinaisonList);
    });

  }

  initCols = (produitDeclinaisonList: ProduitDeclinaisonDTO[]) => {

    const cols = [];
    cols.push({field: 'libelle', header: 'Famille GEMRCN'});

    if (!this.utils.isCollectionNullOrEmpty(produitDeclinaisonList)) {

      produitDeclinaisonList.forEach(pd => cols.push({
        field: pd.id,
        header: pd.fabrique?pd.declinaison.libelle:pd.libelle
      }));

    }

    return cols;
  };

  /**
   * affecter les familles gemrcn sélectionnées aux produits declinaisons
   * @param {{[p: string]: boolean}} checkedMap
   * @param {ProduitDeclinaisonDTO[]} produitDeclinaisonList
   * @param {FamilleGemrcnDTO[]} familleGemrcnList
   * @returns {ProduitDeclinaisonDTO[]}
   */
  convertCheckedMapToProduitDeclinaisonList = (checkedMap: { [key: string]: boolean }, produitDeclinaisonList: ProduitDeclinaisonDTO[], familleGemrcnList: FamilleGemrcnDTO[]): ProduitDeclinaisonDTO[] => {

    let pdl: ProduitDeclinaisonDTO[] = [];
    const values: boolean[] = _values<boolean>(checkedMap);
    for (let pd of produitDeclinaisonList) {
      pd.famillesNutritionnellesProduits = [];
      for (let fg of familleGemrcnList) {
        const checked = checkedMap[this.getCheckedMapKey(fg.id, pd.id)];
        if (checked) {
          const fnp = new FamilleNutritionnelleProduitDTO();
          fnp.idFamille = fg.id;
          fnp.idProduitDecline = pd.id;
          pd.famillesNutritionnellesProduits.push(fnp);
        }
      }
      pdl.push(pd);
    }
    return pdl;
  };

  saveFamillesGemrcn = () => {

    if (!this.utils.isCollectionNullOrEmpty(this.familleGemrcnList)) {

      //affecter les familles gemrcn sélectionnées aux produits declinaisons
      this.produitDeclinaisonList = this.convertCheckedMapToProduitDeclinaisonList(this.checkedMap, this.produitDeclinaisonList, this.familleGemrcnList);
      this.produitDeclinaisonList = this.produitSvc.sortListeProduitDeclinesDTO(this.produitDeclinaisonList);

      //enregister les familles dans le back;
      this.produitSvc.saveFamillesGemrcn(this.produitDeclinaisonList).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.utils.showMsg(MSG_KEY.SIDEBAR,MSG_SEVERITY.SUCCESS,`Les familles GEMRCN ont été enregistrées avec succès.`);
          }
        },
        error => this.utils.handleError(error, true));
    }
  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subInitAll);
    this.utils.unsubscribe(this.subParentRoute);
  }

}
