import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {AppellationsSupplier} from './appellations-resolver.service';
import {ActivatedRoute} from '@angular/router';
import {AppellationsService} from '../../../core/services/entities/appellations.service';
import {find as _find} from 'lodash'
import {ProduitAppellationDTO} from '../../../core/dtos/produit-appellation-dto';
import {ProduitAppellationService} from '../../../core/services/entities/produit-appellation.service';
import {MessageService} from 'primeng/api';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";

@Component({
  selector: 'yo-appellations',
  templateUrl: './appellations.component.html',
  styleUrls: ['./appellations.component.scss']
})
export class AppellationsComponent implements OnInit, OnDestroy {

  subAppellations: Subscription;
  appellationsSupplier: AppellationsSupplier;
  produit: ProduitDTO;
  totalRecords: number = 0;

  appellationEntityName: string;
  refreshImg: number = 1;
  selectAll: boolean = true;

  mapSelection: Map<string, ProduitAppellationDTO> = new Map();
  mapActiveRow: Map<number, boolean> = new Map();
  mapActiveCol: Map<number, boolean> = new Map();

  cols: any[] = [];

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-appellations';

  constructor(public utils: UtilsService,
              private cd: ChangeDetectorRef,
              private auth2Svc: Auth2Service,
              private msgSvc: MessageService,
              private produitAppellationSvc: ProduitAppellationService,
              public appellationSvc: AppellationsService,
              private route: ActivatedRoute,
              public gds: GenericDatagridService) {
  }

  ngOnInit() {
    this.appellationEntityName = this.appellationSvc.getEntityName();
    this.subAppellations = this.route.data.subscribe((data: { appellationsSupplier: AppellationsSupplier }) => {
      this.appellationsSupplier = data.appellationsSupplier;
      this.produit = data.appellationsSupplier.produit;
      this.totalRecords = this.appellationsSupplier.appellations.length;
      this.refreshImg = new Date().getTime();

      this.cols = this.initCols(this.appellationsSupplier);
      this.mapSelection = this.initMapSelection(this.appellationsSupplier);
      this.mapActiveCol = this.initMapActiveCol(this.appellationsSupplier, this.mapSelection);
      this.mapActiveRow = this.initMapActiveRow(this.appellationsSupplier, this.mapSelection);
      this.selectAll = this.initSelectAll(this.mapSelection);

    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAppellations);
  }

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/denrée`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Signes d’Identification de Qualité d'un <b>produit/denrée</b>`;
    p1.lines = [
      `Ils sont liés à la <b>déclinaison</b> afin que tous les <b>articles</b> qui lui sont rattachés portent ces informations consommateurs.`,
      `Ces informations seront par exemple reprises dans les <b>menus</b>.`,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };

    return dms;
  };


  /**
   * Activer ou désactiver la case à cocher Tout sélectionner si tous les élements sont cochés
   * @param mapSelection
   */
  initSelectAll = (mapSelection: Map<string, ProduitAppellationDTO>): boolean => {
    let selectAll = true;
    if (mapSelection) {
      mapSelection.forEach((value, key) => {
        if (!value.actif) {
          selectAll = false;
        }
      });
    }

    return selectAll;
  };

  /**
   * Initialiser la map de selection
   */
  initMapSelection = (appellationsSupplier: AppellationsSupplier): Map<string, ProduitAppellationDTO> => {
    const selectionMap: Map<string, ProduitAppellationDTO> = new Map();
    if (!this.utils.isNullOrEmpty(appellationsSupplier)) {

      if (!this.utils.isCollectionNullOrEmpty(appellationsSupplier.appellations) && !this.utils.isCollectionNullOrEmpty(appellationsSupplier.produitsDeclinaisons)) {
        let produitsAppellations = appellationsSupplier.produitsAppellations;
        if (this.utils.isCollectionNullOrEmpty(produitsAppellations)) {
          produitsAppellations = [];
        }

        for (let app of appellationsSupplier.appellations) {
          for (let pd of appellationsSupplier.produitsDeclinaisons) {
            const paExists = _find(produitsAppellations, {'produitDeclinaisonId': pd.id, 'appellationId': app.id})
            if (paExists) {

              selectionMap.set(this.getKeySelectionMap(pd.id, app.id), paExists);
            } else {
              const pa = this.produitAppellationSvc.createEmptyProduitAppellation(pd, app);
              selectionMap.set(this.getKeySelectionMap(pd.id, app.id), pa);
            }
          }
        }
      }
    }
    return selectionMap;
  };

  /**
   * Clé de sélection du produitAppellation
   * @param idProduitDeclinaison
   * @param idAppellation
   */
  getKeySelectionMap = (idProduitDeclinaison: number, idAppellation: number): string => idProduitDeclinaison + '-' + idAppellation;

  /**
   * Initialiser les colonnes
   * @param appellationsSupplier
   */
  initCols = (appellationsSupplier: AppellationsSupplier): any[] => {
    const cols = [];
    cols.push({field: 'appellation.libelle', header: 'APPELLATION'});
    cols.push({field: 'appellation.logo', header: 'LOGO'});

    if (!this.utils.isNullOrEmpty(appellationsSupplier)) {
      const produitsDeclinaisons = this.appellationsSupplier.produitsDeclinaisons;
      if (!this.utils.isCollectionNullOrEmpty(produitsDeclinaisons)) {
        produitsDeclinaisons.forEach(pd => cols.push({
          field: pd.id,
          header: pd.fabrique ? pd.declinaison.libelle : pd.libelle
        }));
      }
    }
    return cols;
  };

  getActiveProduitAppellation = (idAppellation: number, idProduitDeclinaison: number): boolean =>
    this.mapSelection.get(this.getKeySelectionMap(idProduitDeclinaison, idAppellation)).actif;

  setActiveProduitAppellation = (idAppellation: number, idProduitDeclinaison: number, $event: boolean) => {

    const key = this.getKeySelectionMap(idProduitDeclinaison, idAppellation);

    this.mapSelection.get(key).actif = $event;

    const rowKey = this.mapSelection.get(key).appellationId;

    let activeRow = true;
    let activeAll = true;
    this.mapSelection.forEach((value, keySel) => {
      // check ligne
      if (value.appellationId === rowKey) {
        if (!value.actif) {
          activeRow = false;
        }
      }
      // check all
      if (!value.actif) {
        activeAll = false;
      }
    });

    this.mapActiveRow.set(rowKey, activeRow);
    this.selectAll = activeAll;
  };

  updateSelectAll = ($event: boolean) => {
    this.mapSelection.forEach((value, key) => value.actif = $event);

    for (let key of Array.from(this.mapActiveRow.keys())) {
      this.mapActiveRow.set(key, $event);
    }
  };

  save = () => {
    const produitsAppellations: ProduitAppellationDTO[] = [];
    this.mapSelection.forEach((value, key) => {
      produitsAppellations.push(value);
    });

    this.produitAppellationSvc.saveAppellations(produitsAppellations, this.appellationsSupplier.idProduit).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        const summary = `Les appellations du produit sont correctement enregistrées .`;
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, summary, '', 3000);
      }
    });
  };

  getActiveRow = (idAppellation: number) => this.mapActiveRow.get(idAppellation);

  setActiveRow = (idAppellation: number, $event: boolean) => {

    this.mapActiveRow.set(idAppellation, $event);

    let allActive = true;
    this.mapSelection.forEach((value, key) => {

      if (value.appellationId === idAppellation) {
        value.actif = $event;
      }

      if (!value.actif) {
        allActive = false;
      }
    });

    this.selectAll = allActive;

    for (let key of Array.from(this.mapActiveCol.keys())) {
      this.mapActiveCol.set(key, allActive);
    }

  };

  private initMapActiveCol = (appellationsSupplier: AppellationsSupplier, mapSelection: Map<string, ProduitAppellationDTO>): Map<number, boolean> => {
    const mapActiveCol: Map<number, boolean> = new Map();

    if (!this.utils.isNullOrEmpty(appellationsSupplier)) {
      if (!this.utils.isCollectionNullOrEmpty(appellationsSupplier.produitsDeclinaisons)) {

        for (let pd of appellationsSupplier.produitsDeclinaisons) {
          let active = true;
          mapSelection.forEach((value, key) => {
            if (value.produitDeclinaisonId === pd.id) {
              if (!value.actif) {
                active = false;
              }
            }
          });
          mapActiveCol.set(pd.id, active);
        }
      }
    }
    return mapActiveCol;
  };

  private initMapActiveRow = (appellationsSupplier: AppellationsSupplier, mapSelection: Map<string, ProduitAppellationDTO>): Map<number, boolean> => {
    const mapActiveRow: Map<number, boolean> = new Map();

    if (!this.utils.isNullOrEmpty(appellationsSupplier)) {
      if (!this.utils.isCollectionNullOrEmpty(appellationsSupplier.appellations)) {

        for (let app of appellationsSupplier.appellations) {
          let active = true;
          mapSelection.forEach((value, key) => {
            if (value.appellationId === app.id) {
              if (!value.actif) {
                active = false;
              }
            }
          });
          mapActiveRow.set(app.id, active);
        }
      }
    }
    return mapActiveRow;
  };


  canModify = () => {
    // et qu'il fait partie des sites locaux de l'environnement de l'utilisateur
    return !!_find(this.auth2Svc.utilisateur.siteListLocaux, {'id': this.appellationsSupplier.produitSiteId});
  };
}

