<h5>
  <label class="badge badge-secondary">
    <i class="fa fa-user yoni-color  mg-r-5"></i>
    <ng-container *ngIf="yoUtilisateur.id === 0; else editMode">
      {{'Créer un utilisateur'}}
    </ng-container>
    <ng-template #editMode>
      {{yoUtilisateur.nom | uppercase}}  {{yoUtilisateur.prenom | titlecase}}
    </ng-template>
  </label>
</h5>

<div class="d-flex flex-column">
  <div [style.height.px]="utils.getWindowAvailableHeight(110)" style="overflow-y: auto;">
    <form [formGroup]="formGroupCtrl" autocomplete="off">


      <p-panel header="Informations générales" [style]="{'margin-bottom': '10px'}">
        <div formGroupName="utilisateurGroup">

          <div class="d-flex flex-row">
            <div class="mg-r-50">
              <yo-input-text
                [yoUppercase]="true"
                [yoLowercase]="true"
                formControlName="nom"
                [yoFormControl]="nomCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="100"
                [yoLabel]="'Nom '"
                [yoRequired]="true"
                [(ngModel)]="yoUtilisateur.nom">
              </yo-input-text>

              <yo-input-text
                [yoUppercase]="true"
                [yoLowercase]="true"
                formControlName="prenom"
                [yoFormControl]="prenomCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="100"
                [yoLabel]="'Prénom '"
                [yoRequired]="true"
                [(ngModel)]="yoUtilisateur.prenom">
              </yo-input-text>

              <yo-input-text
                [yoUppercase]="true"
                [yoLowercase]="true"
                formControlName="email"
                [yoFormControl]="emailCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="100"
                [yoLabel]="'Email '"
                [yoRequired]="true"
                [(ngModel)]="yoUtilisateur.email">
              </yo-input-text>

              <yo-input-text
                [yoUppercase]="true"
                [yoLowercase]="true"
                formControlName="code"
                [yoFormControl]="codeCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="100"
                [yoLabel]="'Code '"
                [yoRequired]="true"
                [(ngModel)]="yoUtilisateur.code">
              </yo-input-text>
            </div>

            <div>
              <yo-input-text
                [yoUppercase]="true"
                [yoLowercase]="true"
                formControlName="login"
                [yoFormControl]="loginCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="100"
                [yoLabel]="'Login '"
                [yoDisabled]="yoUtilisateur.id > 0"
                [yoRequired]="true"
                [(ngModel)]="yoUtilisateur.login">
              </yo-input-text>

              <yo-input-password
                formControlName="motDePasse"
                [yoFormControl]="motDePasseCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="10"
                [yoLabel]="'Mot de passe *'"
                [yoRequired]="false"
                [(ngModel)]="motDePasse">
              </yo-input-password>

              <yo-input-password
                class="mg-b-5"
                formControlName="motDePasseConfirmation"
                [yoFormControl]="motDePasseConfirmationCtrl"
                [yoMinLength]="3"
                [yoMaxLength]="10"
                [yoLabel]="'Confirmation *'"
                [yoRequired]="false"
                [(ngModel)]="motDePasseConfirmation">
              </yo-input-password>

              <p-checkbox
                class="mg-b-10"
                formControlName="actif"
                binary="true"
                value="actif"
                label="Actif"
                [(ngModel)]="yoUtilisateur.actif">
              </p-checkbox>
            </div>
          </div>

        </div>
      </p-panel>
    </form>

    <p-tabMenu [activeItem]="tabMenuUser[0]" [model]="tabMenuUser"></p-tabMenu>

    <div *ngIf="tabVisibility === TAB_FICHE_UTILISATEUR.profil">
      <h3 class="first">Liste des profils <span class="danger-color">*</span></h3>
      <!-- PROFILS-->
      <div class="d-flex flex-row">
        <div class="mg-r-15">

          <p-listbox
            filter="true"
            appendTo="body"
            [listStyle]="{'min-height':'280px','max-height':'280px'}"
            [style]="{'width':'300px'}"
            [options]="arrayAllProfilDTO"
            (onChange)="changeProfil($event)"
            [(ngModel)]="selectedProfil"
            [ngModelOptions]="{standalone: true}"
            optionLabel="libelle"
          >
            <ng-template let-item pTemplate="item">
              {{item.libelle}}
            </ng-template>
          </p-listbox>
        </div>


        <div
          *ngIf="!utils.isNullOrEmpty(selectedProfil) && !utils.isCollectionNullOrEmpty(selectedProfil.roleList)">
          <p-table
            [value]="selectedProfil.roleList"
            [scrollable]="true"
            [scrollHeight]="utils.scrollHeightContent(506)"
            styleClass="p-datatable-sm"
          >

            <ng-template pTemplate="header">
              <tr>
                <th style="width:300px">Rôle</th>
                <th>Description</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td style="width:300px">{{rowData.libelle}}</td>
                <td>{{rowData.description}}</td>
              </tr>
            </ng-template>

          </p-table>
        </div>

      </div>
    </div>

    <!--ENVIRONNEMENT-->
    <div *ngIf="tabVisibility === TAB_FICHE_UTILISATEUR.environnement">
      <h3 class="first">Liste des environnements <span class="danger-color">*</span></h3>
      <div class="flex-row flex-row">
        <div class="mg-t-10">
          <p-listbox
            multiple="multiple"
            filter="true"
            [checkbox]="true"
            [listStyle]="{'min-height':'280px','max-height':'280px'}"
            [style]="{'width':'300px'}"
            defaultLabel="Selectionner..."
            [options]="environnementList"
            optionLabel="libelle"
            [(ngModel)]="environmentsSelected"
          >
          </p-listbox>
        </div>
      </div>
    </div>

    <!--ENVIRONNEMENT PLC-->
    <div *ngIf="tabVisibility === TAB_FICHE_UTILISATEUR.envPlc">
      <h3 class="first">Liste des env. point de livraison <span class="danger-color">*</span></h3>
      <!-- PROFILS-->
      <div class="d-flex flex-row">
        <div class="mg-r-15">

          <p-listbox
            filter="true"
            appendTo="body"
            [listStyle]="{'min-height':'280px','max-height':'280px'}"
            [style]="{'width':'300px'}"
            [options]="envPointDeLivraison"
            [(ngModel)]="envPointDeLivraisonSelected"
            [ngModelOptions]="{standalone: true}"
            optionLabel="libelle">
            <ng-template let-item pTemplate="item">
              {{item.libelle}}
            </ng-template>
          </p-listbox>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row mg-t-10">
      <div class="mr-auto"></div>
      <div>
        <yo-button-save
          (click)="save()"
          [pTooltip]="yoUtilisateur.id === 0 ?'Enregistrer le nouvel utilisateur':'Sauvegarde des modifications'"
          yoType="submit">
        </yo-button-save>
      </div>
    </div>


