import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {WorkflowInstanceDTO} from '../../core/dtos/workflow-instance-dto';
import {WorkflowsService} from '../../core/services/entities/workflows.service';
import {UtilsService} from '../../core/utils/utils.service';
import {RunStepDTO} from '../../core/dtos/run-step-dto';
import {MenuItem} from 'primeng/api';
import {ErreurFonctionnelleDto} from '../../core/dtos/erreur-fonctionnelle-dto';
import {sortBy as _sortBy} from 'lodash'
import {Subscription} from 'rxjs';
import {MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {SlideMenu} from 'primeng/slidemenu';


@Component({
  selector: 'yo-button-next-steps',
  templateUrl: './button-next-steps.component.html',
  styleUrls: ['./button-next-steps.component.scss']
})
export class ButtonNextStepsComponent implements OnInit, AfterViewInit, OnDestroy {

  subWorkflowInstance: Subscription;

  nextStepItems: MenuItem[];

  displayDialogPassageCommande: boolean = false;
  listeBesoinEnErreur: boolean = true;
  lbErreurs: number;
  continueWithErrorsToPassageCommande: boolean = false;
  lbdActifs: number;

  @Input() workflowInstance: WorkflowInstanceDTO;

  // objet d'entree necessaire à la tache courante pour son fonctionnement
  @Input() runInput: any;
  @Input() play: boolean; // true : button play en haut ; false : button suivant en bas

  @Output() onError = new EventEmitter();

  @ViewChild('menu') menu: SlideMenu;

  constructor(public workflowSvc: WorkflowsService,
              private cd: ChangeDetectorRef,
              public utils: UtilsService) {
  }

  ngOnInit() {
    this.nextStepItems = this.createMenuItems(this.workflowInstance);
    this.subscriptionWorkflowInstance();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subWorkflowInstance);
  }

  ngAfterViewInit(): void {
    if (this.menu && this.nextStepItems)
      this.menu.viewportHeight = 85 * this.nextStepItems.length;
  }

  subscriptionWorkflowInstance(){
    this.subWorkflowInstance = this.workflowSvc.workflowInstance$.subscribe(workflowInstance => {
      this.workflowInstance = workflowInstance;
      this.nextStepItems = this.createMenuItems(this.workflowInstance);
      this.cd.markForCheck();
    });
  }

  /**
   * Creer les etapes
   * @param workflowInstance
   */
  createMenuItems(workflowInstance: WorkflowInstanceDTO): MenuItem[] {
    const items = [];
    if (!this.utils.isCollectionNullOrEmpty(workflowInstance.nextSteps)) {
      workflowInstance.nextSteps = _sortBy(workflowInstance.nextSteps, 'niveau');
      for (let nextStep of workflowInstance.nextSteps) {
        const stepItem = {
          label: nextStep.workflowStatutLibelle.toUpperCase(),
          icon: nextStep.niveau <= workflowInstance.niveauWorkflowEtape ? 'fas fa-backward' : 'fas fa-forward space',
          command: (event) => {
            this.goToNextStepSpec(workflowInstance, nextStep.workflowStatutCode, this.runInput)
          }
        };
        items.push(stepItem);
      }
    }
    return items;
  }


  /**
   * Lancer le calcul de la commande fournisseur
   * @param wi
   */
  goToCommande(wi: WorkflowInstanceDTO) {
    if (this.lbdActifs < 1) {
      this.utils.showMsg(MSG_KEY.NEXT_STEP, MSG_SEVERITY.WARN, `Aucun article n'est sélectionné.`, `Vous ne pouvez pas passer la commande.`);
    } else {
      // lancer l'étape de calcul de commandes fournisseurs,
      this.goToNextStep(wi, 'calcul-commandes-fournisseurs', undefined);
    }
  }


  /**
   * Aller à l'étape suivante du workflow
   * Gere le cas particulier du besoin vers la commande
   * @param wi
   * @param nextStep
   * @param runInput
   */
  goToNextStepSpec(wi: WorkflowInstanceDTO, nextStep: string, runInput: any) {
    this.displayDialogPassageCommande = false;
    this.goToNextStep(wi, nextStep, runInput);
  }


  createRunStep(workflowInstance: WorkflowInstanceDTO, nextStepCode: string, runInput: any): RunStepDTO {
    const runStep = new RunStepDTO();
    runStep.idWorkflowInstance = workflowInstance.id;
    runStep.nextStepCode = nextStepCode;
    runStep.recompute = this.workflowSvc.isTaskToRecompute(workflowInstance.codeWorkflowStatut);
    runStep.runInput = runInput;

    return runStep;
  }

  /**
   * Passer à l'étape suivante du workflow
   * @param workflowInstance
   * @param nextStep
   * @param runInput
   */
  goToNextStep(workflowInstance: WorkflowInstanceDTO, nextStep: string, runInput: any) {

    const runStep = this.createRunStep(workflowInstance, nextStep, runInput);

    this.workflowSvc.goToNextStep(runStep).subscribe(response => {

      if(response){
        if (!this.utils.isNullOrEmpty(response.additionalProperties['erreurs'])) {
          // s'il y a des erreurs de validation sur la tâche, on notifie l'utilisateur
          let erreurs: ErreurFonctionnelleDto[] = response.additionalProperties['erreurs'];

          if (!this.utils.isCollectionNullOrEmpty(erreurs)) {
            this.onError.emit(erreurs);
          }
        }

        const workflowInstance = response.one;
        this.workflowSvc.announceWorkflowInstance(workflowInstance);
        this.workflowSvc.openTask(workflowInstance);

        // fermer la fenetre de dialog si elle est présente
        this.displayDialogPassageCommande = false;
      }
    });
  }
}
