<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  (onHide)="closeDialog(false)"
  [resizable]="false"
  [style]="{'width':'1030px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-edit mg-r-5"></i> {{title}}
    </div>
  </ng-template>

  <div class="size-form">
    <yo-form [form]="form" [groups]="groups" [options]="formOptions">
    </yo-form>
  </div>

  <ng-template pTemplate="footer" class="d-flex justify-content-end">
        <span class="mg-r-5">
        <button *ngIf="isVisibleSaveBtn()" type="button" pButton [disabled]="!form?.valid" (click)="initSaveFiliale()" icon="fas fa-save"></button>
        </span>
    <span class="mg-r-5">
        <button type="button" pButton icon="fas fa-times" class="p-button-secondary"
                (click)="closeDialog(false)"></button>
        </span>
  </ng-template>
</p-dialog>
