import {ObjectDTO} from "./object-dto";
import {WorkflowEtapeDto} from "./workflow-etape-dto";
import {ListeBesoinsHistoriqueDto} from "./liste-besoins-historique-dto";
import {ListeBesoinsJourDto} from "./liste-besoins-jour-dto";
import {TypeEffectifDTO} from "./type-effectif-dto";
import {WorkflowInstanceDTO} from "./workflow-instance-dto";
import {TreeNode} from "primeng/api";

export class ListeBesoinsDto extends ObjectDTO {

  code: string;
  libelle: string;
  actif: boolean;
  archive: boolean;
  date: number;

  typeEffectif: TypeEffectifDTO;

  workflowInstance: WorkflowInstanceDTO;

  // @deprecated
  workflowEtape: WorkflowEtapeDto;

  // @deprecated
  listeBesoinHistoriqueList: ListeBesoinsHistoriqueDto[] =[];

  // @deprecated
  listeBesoinJourList: ListeBesoinsJourDto[] =[];

  selectionPlcs: TreeNode[] =[];
  dateDebut: Date;
  dateFin: Date;
}
