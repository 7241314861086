import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {catchError, switchMap} from "rxjs/operators";
import {Subscription} from "rxjs/index";
import {
  DialogStockAjoutLotSupplier,
  ProduitDeclinaisonService
} from "../../../../core/services/entities/produit-declinaison.service";
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY, TYPE_LIST_UDP} from "../../../../core/constants";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {ModelViewMatierePremiere} from "../model-view-matiere-premiere";
import {ModelViewInventaire} from "../../inventaire/model-view-inventaire";
import {UnitesDeProductionService} from "../../../../core/services/entities/unites-de-production.service";
import {GestionFournisseurService} from "../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service";
import {FournisseurDTO} from "../../../../core/dtos/fournisseur-d-t-o";
import {CatalogueAchatDTO} from "../../../../core/dtos/catalogue-achat-dto";
import {CataloguesAchatsService} from "../../../../core/services/entities/catalogues-achats.service";
import {GroupeAchatCaViewModel} from "../../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {UniteDeProduction__zoneDeStockageService} from "../../../../core/services/entities/unite-de-production__zone-de-stockage.service";
import {ZoneDeStockageDTO} from "../../../../core/dtos/zone-de-stockage-dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProduitDeclinaisonDTO} from "../../../../core/dtos/produit-declinaison-dto";
import * as moment from "moment";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {UnitedemesureDTO} from "../../../../core/dtos/unitedemesure-dto";
import {GenericFormService} from "../../../../core/services/generics/generic-form.service";
import {StockDTO} from "../../../../core/dtos/stock-dto";
import {StocksService} from "../../../../core/services/gestion-stock/stocks.service";

@Component({
  selector: 'yo-dialog-lot-ajout',
  templateUrl: './dialog-lot-ajout.component.html',
  styleUrls: ['./dialog-lot-ajout.component.scss']
})
export class DialogLotAjoutComponent implements OnInit, OnDestroy {

  localeFr = DATEPICKER_FR;

  displayDialog: boolean = false;
  isDisplayStockPanel: boolean = false;

  form: FormGroup;

  fournisseurList: FournisseurDTO[] = [];
  founisseurSelected: FournisseurDTO;
  uniteProductionList: UniteDeProductionDTO[] = [];
  uniteProductionSelected: UniteDeProductionDTO;
  catalogueAchatList: CatalogueAchatDTO[] = [];
  selectedGacaViewModel: GroupeAchatCaViewModel;

  zoneStockageList: ZoneDeStockageDTO[] = [];
  zoneStockageSelected: ZoneDeStockageDTO;

  selectedMatierePremiere: ModelViewMatierePremiere;
  selectedInventaire: ModelViewInventaire;

  idProduitDeclinaison: number;
  produitDeclinaison: ProduitDeclinaisonDTO;

  subOpenDialogAjoutLot: Subscription;

  uniteDeFacturation: UnitedemesureDTO;
  uniteDeStockage: UnitedemesureDTO;

  stock: StockDTO = new StockDTO();

  constructor(public utils: UtilsService,
              private gfs: GenericFormService,
              private cd: ChangeDetectorRef,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private udpSvc: UnitesDeProductionService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private udpZdsSvc: UniteDeProduction__zoneDeStockageService,
              private catalogueAchatSvc: CataloguesAchatsService,
              private gds: GenericDatagridService,
              private stockSvc: StocksService) {
  }

  ngOnInit() {
    this.initForm();
    this.subscriptionOpenDialog();
    this.initUdpList();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogAjoutLot);
  }


  closeDialog = () => {
    this.displayDialog = false;
    this.isDisplayStockPanel = false;
    this.clearData();
  };

  clearData = () => {
    this.catalogueAchatList = [];
    this.fournisseurList = [];
    this.selectedGacaViewModel = undefined;
    this.form.reset();
  };

  subscriptionOpenDialog = () => {
    this.subOpenDialogAjoutLot = this.produitDeclinaisonSvc.openDialogAjoutLot$.pipe(
      switchMap((response: DialogStockAjoutLotSupplier) => {
        this.displayDialog = true;
        this.selectedMatierePremiere = response.selectedMatierePremiere;
        this.selectedInventaire = response.selectedInventaire;

        this.idProduitDeclinaison = this.selectedMatierePremiere ? this.selectedMatierePremiere.id : this.selectedInventaire.id;
        this.loadProduitDeclinaison();
        this.loadZoneStockageByUdp(this.uniteProductionSelected.id);

        return this.gestionFournisseurSvc.getFournisseurListByIdUdpAndIdProduitDeclinaison(this.uniteProductionSelected.id, this.idProduitDeclinaison);
      }),
      catchError(err => this.utils.handleError(err)))
      .subscribe(data => {

          this.fournisseurList = data.resultList;

          if (this.fournisseurList.length) {
            this.founisseurSelected = this.fournisseurList[0];
            this.initForm();
            this.loadCatalogueAchat();
          } else {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `L'unité de production ${this.uniteProductionSelected.libelle} ne possède pas de fournisseur(s) pour la Denrée :"${this.selectedMatierePremiere.libelle}".`);
          }
        }
      );
  };

  initUdpList = () => {
    this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe((response: ResponseWrapper<UniteDeProductionDTO>) => {
      this.uniteProductionList = response.resultList;
      if (response.resultList.length > 0) {
        this.uniteProductionSelected = response.resultList[0];
      }
    });
  };

  loadProduitDeclinaison = () => {
    this.gds.getOne(this.produitDeclinaisonSvc.getEntityName(), this.idProduitDeclinaison).subscribe(response => {
      this.produitDeclinaison = response.one;
    });
  };

  loadZoneStockageByUdp = (idUdp: number) => {
    this.udpSvc.getZoneDeStockageByIdUdp(idUdp).subscribe((response: ResponseWrapper<ZoneDeStockageDTO>) => {
      this.zoneStockageList = response.resultList;
      if (this.zoneStockageList.length === 0) {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `L'unité de production ${this.uniteProductionSelected.libelle} ne possède pas de zone(s) de stockage".`);
      }
    });
  };

  onChangeUdp = event => {
    this.uniteProductionSelected = event.value;
    //  clear données du formulaire
    this.clearData();
    // load zone de stockage
    this.loadZoneStockageByUdp(this.uniteProductionSelected.id);

    this.gestionFournisseurSvc.getFournisseurListByIdUdpAndIdProduitDeclinaison(this.uniteProductionSelected.id, this.idProduitDeclinaison).subscribe((response: ResponseWrapper<FournisseurDTO>) => {
      this.fournisseurList = response.resultList
      if (this.fournisseurList.length === 0) {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `L'unité de production ${this.uniteProductionSelected.libelle} ne possède pas de fournisseur(s) pouvant fournir la denrée ${this.selectedMatierePremiere.libelle}.`);
        this.clearData();
      }
    });
  };

  onChangeFournisseur = event => {
    this.founisseurSelected = event;
    this.loadCatalogueAchat(true);
  };

  loadCatalogueAchat = (fromChangeInput?: boolean) => {
    if (!this.utils.isNullOrEmpty(this.uniteProductionSelected) && !this.utils.isNullOrEmpty(this.idProduitDeclinaison) && !this.utils.isNullOrEmpty(this.founisseurSelected)) {
      this.catalogueAchatSvc.getCatalogueAchatByIdAndIdPdAndIdFournisseur(this.uniteProductionSelected.id,
        this.idProduitDeclinaison, this.founisseurSelected.id).subscribe((response: ResponseWrapper<CatalogueAchatDTO>) => {
        this.catalogueAchatList = response.resultList;
        if(this.catalogueAchatList.length === 1 && this.catalogueAchatList[0].groupeAchatCaList.length === 1){
          this.updateGacaViewModel(this.catalogueAchatList[0].groupeAchatCaList[0], this.catalogueAchatList[0], fromChangeInput);
        }
      });
    }
  };

  updateGacaViewModel = (gaca: any, catalogueAchat: CatalogueAchatDTO, fromChangeInput?: boolean) => {
    if (gaca) {
      this.selectedGacaViewModel = gaca;
      this.form.controls['dateEntree'].setValue(new Date());
      this.form.controls['dlc'].setValue(this.getDlcDate(new Date(), this.produitDeclinaison));
      this.form.controls['lotFabriquant'].setValue('');
      this.form.controls['quantite'].setValue(0);
      this.form.controls['prixUnitaire'].setValue(this.selectedGacaViewModel.prixUF);

      this.uniteDeStockage = catalogueAchat.uniteDeStockage;
      this.uniteDeFacturation = catalogueAchat.uniteDeFacturation;
      if (!fromChangeInput) {
        this.catalogueAchatSvc.findArticlePrefere(this.idProduitDeclinaison, this.uniteProductionSelected.id)
          .subscribe(res => {
            if (res.one && res.one.groupeAchatCa && res.one.groupeAchatCa.catalogueAchat && res.one.groupeAchatCa.catalogueAchat.fournisseur && res.one.groupeAchatCa.catalogueAchat.fournisseur.id) {
              this.founisseurSelected = this.fournisseurList.find(f => f.id === res.one.groupeAchatCa.catalogueAchat.fournisseur.id);
            }
          });
      }
      this.isDisplayStockPanel= true;
      this.cd.markForCheck();
    }
  };

  initForm = () => {

    if (this.produitDeclinaison) {
      let dlc = this.getDlcDate(new Date(), this.produitDeclinaison);
      this.form = new FormGroup({
        quantite: new FormControl(0, [Validators.required, Validators.min(0.0001)]),
        prixUnitaire: new FormControl(0, [Validators.required]),
        lotFabriquant: new FormControl(''),
        dlc: new FormControl(dlc, Validators.required),
        dateEntree: new FormControl(new Date(), Validators.required)
      });
    } else {
      this.form = new FormGroup({
        quantite: new FormControl(0, [Validators.required, Validators.min(0.0001)]),
        prixUnitaire: new FormControl(0, [Validators.required]),
        lotFabriquant: new FormControl(''),
        dlc: new FormControl(new Date(), Validators.required),
        dateEntree: new FormControl(new Date(), Validators.required)
      });
    }
  };

  getDlcDate = (date: Date, produitDeclinaison: ProduitDeclinaisonDTO) => {
    const momentDlc = moment(date);
    let dateDlc = new Date();

    if (produitDeclinaison && produitDeclinaison.dlc > 0) {
      dateDlc = momentDlc.add(produitDeclinaison.dlc, 'days').toDate();
    }
    return dateDlc;
  };


  ajouterLot = () => {
    this.gfs.validateAllFormFields(this.form);
    if (this.form.valid && !this.utils.isNullOrEmpty(this.zoneStockageSelected) && !this.utils.isNullOrEmpty(this.selectedGacaViewModel)) {
      this.stock.idUdp = this.uniteProductionSelected.id;
      this.stock.produitDeclinaisonCode = this.produitDeclinaison.code;
      this.stock.quantite = this.form.controls['quantite'].value;
      this.stock.prixUnitaire = this.form.controls['prixUnitaire'].value;
      this.stock.lotArticleFournisseur = this.form.controls['lotFabriquant'].value;
      this.stock.idZoneDeStockage = this.zoneStockageSelected.id;
      this.stock.zdsLibelle = this.zoneStockageSelected.libelle;
      this.stock.idCatalogueAchat = this.selectedGacaViewModel.idCatalogueAchat;
      this.stock.dlc = this.form.controls['dlc'].value;
      this.stock.dateEntree = this.form.controls['dateEntree'].value;
      this.stock.dluo = this.form.controls['dateEntree'].value;
      this.stockSvc.saveStock(this.stock).subscribe(response => {
        if (!this.utils.isNullOrEmpty(response)) {
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, ` Le lot a été enregistrée avec succès.`);
          // actualiser les lignes de stock
          this.stockSvc.announceLoadStocks(this.selectedMatierePremiere);
          this.produitDeclinaisonSvc.announcePaginationSearchStock({page: 0, size: 50});
          this.closeDialog();
        }
      });
    }else{
      if(this.form.controls['quantite'].invalid){
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `Veuillez renseigner la quantité.`);
      }
      if(this.utils.isNullOrEmpty(this.zoneStockageSelected)){
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `Veuillez renseigner la zone de stockage.`);
      }
      if(this.utils.isNullOrEmpty(this.selectedGacaViewModel)){
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `Veuillez selectionner un article.`);
      }
    }
  };

  isDisabledSaveBtn = () => !this.form.valid;

  onChangeZoneStokage = (event: any) => {
    this.zoneStockageSelected = event.value;
  };
}
