import {Component, OnInit} from '@angular/core';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {FamillesGemrcnService} from '../../core/services/entities/familles-gemrcn.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-familles-gemrcn',
  templateUrl: './familles-gemrcn.component.html',
  styleUrls: ['./familles-gemrcn.component.scss']
})
export class FamillesGemrcnComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/familles-gemrcn';

  constructor(public familleGemrcnSvc: FamillesGemrcnService,
              public gds: GenericDatagridService) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

  }

}
