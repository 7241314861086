import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {UtilsService} from "../utils/utils.service";

export const URL_GET_UNIQUE_CODE=`dolrest/unique-code`;

@Injectable({
  providedIn: 'root'
})
export class UniqueCodeService {

  constructor(private http:HttpClient ,private utils:UtilsService) { }

  /**
   * generer un code unique
   * @param typeCode type de code à générer
   * @param codeMetier utilisé pour générer un code unique (pas obligatoire)
   * @return {Observable<any>}
   */
  generateUniqueCode = (typeCode:string,codeMetier?:string) => {


    return this.http.get(URL_GET_UNIQUE_CODE, {
      params: new HttpParams()
        .set('typeCode', typeCode)
        .set('codeMetier', codeMetier)
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

}
