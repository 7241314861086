<div *ngIf="elementsToPreview" class="row mg-t-15">
  <div id="preview" class="col-sm-12">
    <dx-data-grid
      id="gridContainer"
      [dataSource]="elementsToPreview"
      [allowColumnResizing]="true"
      [height]="utils.getWindowAvailableHeight(382)"
      [allowColumnReordering]="true"
      [showBorders]="true">

      <dxi-column dataField="libelleAtelier" caption="Actions" cellTemplate="actionCellTemplate"></dxi-column>
      <dxi-column dataField="libelleAtelier" caption="Atelier associé" [groupIndex]="0"></dxi-column>
      <dxi-column dataField="libelleEquipe" caption="Equipe associée"></dxi-column>
      <dxi-column dataField="libelleTypeTache" caption="Type de Tâche associée"></dxi-column>
      <dxi-column dataField="libelleTache" caption="Tâche associée"></dxi-column>

      <div *dxTemplate="let cell of 'actionCellTemplate'">
        <div class="chart-cell">
          <i
            (click)="deleteAssociation(cell.data.udpId, cell.data.idAtelier, cell.data.idEquipe, cell.data.idTache)"
            [pTooltip]="toolTipDeleteAssociation"
            showDelay="500"
            class="delete fa fa-trash-alt"></i>
        </div>
      </div>

      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    </dx-data-grid>

  </div>
</div>
