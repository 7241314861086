<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    PARAMÈTRES
  </ng-template>
  <ng-template pTemplate="right">
    <yo-button-save (onClick)="save()"></yo-button-save>
  </ng-template>
</p-toolbar>

<div class="row mg-t-5 mg-l-5">
  <div class="col-md-12">
    <form>
      <div class="form-group row">
        <label [class.requiredControl]="true"
               class="genlabel col-sm-3 col-form-label"
               [style.max-width.px]="150"
               showDelay="500">Logo</label>
        <div class="col-sm-6 max-width-150">
          <input type="file"
                 accept="image/jpeg, image/png"
                 name="bgFile"
                 (change)="onChangeImage($event,'logo')">
          <div>
            <img src="dolrest/utils/parametre-image?imageName=logo&date={{refresh}}" height="32">
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label [class.requiredControl]="true"
               class="genlabel col-sm-3 col-form-label"
               [style.max-width.px]="150"
               showDelay="500">Fond d'écran</label>
        <div class="col-sm-6 max-width-150">
          <input type="file"
                 name="logoFile"
                 accept="image/jpeg, image/png"
                 (change)="onChangeImage($event,'background')">

            <img style="border:1px dashed gray;" src="dolrest/utils/parametre-image?imageName=background&date={{refresh}}" height="200">

        </div>
      </div>

    </form>
  </div>
</div>
