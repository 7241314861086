<p-dialog
  (onHide)="closeDialog()"
  [dismissableMask]="true"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-in-alt margin-right-5"></i> Modifier le lot
    </div>
  </ng-template>

  <ng-container *ngIf="!utils.isNullOrEmpty(lotSelected)">
    <yo-form [form]="form" [groups]="groups" [options]="formOptions"></yo-form>
  </ng-container>

  <ng-template pTemplate="footer" class="d-flex justify-content-end">
    <div class="d-flex">
      <div class="ml-auto">
        <button type="button" pButton [disabled]="!form?.valid"  icon="fas fa-save" (click)="updateLot()" [pTooltip]="labelSubmit" [label]="labelSubmit" showDelay="500"></button>
        <button type="button" pButton icon="fas fa-times" class="p-button-secondary" [pTooltip]="labelCancel" [label]="labelCancel" showDelay="500"
                (click)="closeDialog()"></button>
      </div>
    </div>
  </ng-template>

</p-dialog>

