import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldCalendarSupplier} from '../../suppliers/form-field-calendar-supplier';
import * as moment from 'moment';
import {FormFieldTextareaSupplier} from '../../suppliers/form-field-textarea-supplier';
import {LotMarcheDTO} from '../../dtos/gerstion-marche/lot-marche-dto';


export enum FK_LM {
  id ='id',
  code = 'code',
  libelle = 'libelle',
  descriptif= 'descriptif',
  dateDebut = 'dateDebut',
  dateFin = 'dateFin',
  valeurEngagement = 'valeurEngagement',
  volumeEngagement = 'volumeEngagement',
  actif = 'actif'
}


@Injectable({
  providedIn: 'root'
})
export class LotMarcheService extends GenericHandler<LotMarcheDTO> {

  getFields(dto: LotMarcheDTO): FormFieldBaseSupplier<any>[] {

    const isReadOnly = !this.canModify(dto.marche);
    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
      dto.id = 0;
      dto.actif = true;
    }else{
      dto.dateDebut= moment( dto.dateDebut).toDate();
      dto.dateFin= moment( dto.dateFin).toDate();
    }


    let formFieldCode: FormFieldTextboxSupplier;
    if (dto.id > 0) {
      formFieldCode = new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4,
        readonly: true,
      })
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        readonly:isReadOnly
      }),

      new FormFieldTextareaSupplier({
        key: 'descriptif',
        label: 'Description',
        minLength: 0,
        maxLength: 250,
        value: dto.descriptif,
        required: false,
        order: 3,
        readonly:isReadOnly
      }),

      new FormFieldCalendarSupplier({
        key: 'dateDebut',
        label: 'Date de début',
        value: dto.dateDebut,
        required: true,
        showTime: false,
        dateFormat: 'dd/mm/yy',
        order: 5,
        readonly:isReadOnly
      }),

      new FormFieldCalendarSupplier({
        key: 'dateFin',
        label: 'Date de fin',
        value:dto.dateFin,
        required: true,
        showTime: false,
        dateFormat: 'dd/mm/yy',
        order: 6,
        readonly:isReadOnly
      }),

      new FormFieldTextboxSupplier({
        key: 'valeurEngagement',
        label: 'Engagement (€)',
        type: 'number',
        help: `Valeur de l'engagement en Euro`,
        readonly: isReadOnly,
        value: dto.valeurEngagement,
        required: false,
        min: 0.0001,
        suffix: '€',
        width: 150,
        order: 7
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        value: dto.actif,
        required: false,
        order: 9,
        readonly: isReadOnly,
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 10
      })
    ];

    if (dto.id > 0) {
      formFields.push(formFieldCode);
    }

    return formFields.sort((a, b) => a.order - b.order);
  }

  createEmptyDTO(): LotMarcheDTO {
    return new LotMarcheDTO();
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: LotMarcheDTO): string {
    return "";
  }

  getEntityName(): string {
    return 'marche';
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

}
