import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {UtilsService} from '../../utils/utils.service';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {PlanProductionDTO} from '../../dtos/plan-production-dto';
import {FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {PlanProductionDetailReajustementDTO} from '../../dtos/plan-production-detail-reajustement-dto';
import {PlanProductionDetailDTO} from '../../dtos/plan-production-detail-dto';
import {FICHE_TECHNIQUE_PRINT_MODE} from "../../../gestion-production/pp/plans-de-production/print-settings/print-settings.component";
import {HttpService} from "../technique/http.service";

export const URL_POST_SEARCH_PLANS_PROD = `dolrest/gestion-production/searchPlansProduction`;
export const URL_POST_SEARCH_PLAN_PROD_DETAILS = `dolrest/gestion-production/searchPlanProductionDetails`;
export const URL_GET_PLAN_PROD_DETAILS = `dolrest/gestion-production/getPlanProductionDetails`;
export const URL_GET_ERREURS = `dolrest/gestion-production/planProductionDetail/erreurs`;
export const URL_GET_PRINT_SETTINGS = `dolrest/gestion-production/planProduction/printSettings`;
export const URL_POST_PRINT_PDF_RECAP_FAB = `dolrest/gestion-production/print/pdf/recapitulatif-fabrication`;
export const URL_POST_PRINT_PDF_RECETTES = `dolrest/gestion-production/print/pdf/recettes`;
export const URL_POST_PRINT_PDF_SORTIES_STOCKS = `dolrest/gestion-production/print/pdf/sorties-stocks`;

export const URL_POST_SAVE_DETAILS = `dolrest/gestion-production/saveproductiondetail`;

export const URL_DELETE_PLANS_PRODS = `dolrest/gestion-production/deletePlansProductions`;
export const URL_GET_PLAN_PROD = `dolrest/gestion-production/getPlanProduction`;
export const URL_GET_UPDATE_ACTIF = `dolrest/gestion-production/production-detail/detail/selection`;
export const URL_GET_UPDATE_ALLACTIFS = `dolrest/gestion-production/production-detail/detail/allActifs`;
export const URL_GET_PLAN_PRODUCTION_BY_ID_AND_DATES = `dolrest/gestion-production/planProduction/getByIdAndDatesPlanProduction`;
export const URL_GET_LIGNES_SORTIE_STOCKS = `dolrest/gestion-production/planProduction/getLignesSortieStock`;
export const URL_GET_PLAN_PRODUCTION_DETAILS_REAJUSTEMENT_LIST = `dolrest/gestion-production/planProduction/getPlanProductionDetailReajustementList`;
export const URL_GET_EQUIPE = `dolrest/gestion-production/getAllUniteDeProductionEquipe`;
export const URL_GET_INIT_PLAN_PROD = `dolrest/gestion-production/initializePlanProd`;

export const URL_FIND_ZONE_DE_PRODUCTION_BY_SITE_ID = `dolrest/gestion-production/planProduction/findZoneDeProductionBySiteId`;
export const URL_FIND_ZONE_DE_STOCKAGE_BY_SITE_ID = `dolrest/gestion-production/planProduction/findZoneDeStockageBySiteId`;
export const URL_SAUVE_PLAN_PRODUCTION_DETAIL_REAJUSTEMENT = `dolrest/gestion-production/planProduction/sauvePlanProductionDetailReajustement`;
export const URL_DECLENCHER_PLAN_PRODUCTION = `dolrest/gestion-production/planProduction/declencherSortiesPlanProduction`;
export const URL_GET_PLAN_PROD_DETAILS_FOR_FILTERS_SETTINGS_PRINT = `dolrest/gestion-production/getPlanProductionDetailsForFiltersSettingsPrint`;

export type TYPE_JOUR = 'jourFab' | 'jourConso';

@Injectable({
  providedIn: 'root'
})


export class ProductionService {

  /**
   * Contient le nombre de détails sélectionnés.
   */
  nbActifs: number = 0;


  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private utils: UtilsService) {

  }

  /**
   * Récupérer les plans de production selon certains critères. La recherche est paginée
   */
  searchPlansProduction = (ssw: SearchSupplierWrapper, urlParams: string) => {
    return this.http.post(URL_POST_SEARCH_PLANS_PROD + urlParams, ssw).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  saveDetailsPlanProd = (planProductionDetailList: PlanProductionDetailDTO[]) => {
    return this.http.post(URL_POST_SAVE_DETAILS, planProductionDetailList).pipe(
      catchError(err => this.utils.handleError(err, true))
    );

  };

  getDetails = (idPlanProduction: number) => {
    return this.http.get(URL_GET_PLAN_PROD_DETAILS, {
      params: new HttpParams().set('idPlanProduction', idPlanProduction + '')
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };


  getErreurs = (idPlanProdDetail: number) => {
    return this.http.get(URL_GET_ERREURS, {
      params: new HttpParams().set('idPlanProdDetail', idPlanProdDetail + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  /**
   *
   * @param idWorkflowIstance instance de workflow où se trouve les details du plan de prod à imprimer
   * @param idsDetailsToPrint détails du plan de prod à imprimer
   */
  getPrintSettings = (idPlanProd: number, nbActifs: number): Observable<ResponseWrapper<PlanProductionDTO>> => {
    // Positionnement de nombre de détails sélectionnés.
    this.nbActifs = nbActifs;

    return this.http.get(URL_GET_PRINT_SETTINGS, {
      params: new HttpParams()
        .set('idPlanProd', idPlanProd + '')

    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getByIdAndDatesPlanProduction = (idPlanProd: number, dateList: Date[], jourConso: boolean) => {
    let theDateList = dateList.map(date => this.utils.getYYYYMMDD(moment(date))).join(',');
    return this.http.get(URL_GET_PLAN_PRODUCTION_BY_ID_AND_DATES, {
      params: new HttpParams()
        .set('idPlanProd', idPlanProd + '')
        .set('dateList', theDateList + '')
        .set('jourConso', jourConso + '')

    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };


  /**
   * Impression des sorties de stock au format pdf
   * @param planProd
   * @param dateFab
   */
  printSortiesStockPDF = (idPlanProd: number, idZoneDeProduction: number, idZoneDeStockage: number, dateFab: Date) => {
    const fd = new FormData();
    fd.set('idPlanDeProduction', idPlanProd + '');
    fd.set('idZoneDeProduction', idZoneDeProduction + '');
    fd.set('idZoneDeStockage', idZoneDeStockage + '');
    fd.set('dateFabrication', this.utils.getYYYYMMDD(moment(dateFab)));

    return this.http.post(URL_POST_PRINT_PDF_SORTIES_STOCKS, fd, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  getPlanProductionDetailsForFiltersSettingsPrint = (planProd: PlanProductionDTO, form: FormGroup) => {
    const typeJour: TYPE_JOUR = form.controls['typeJour'].value;

    let selectedJours = form.controls['selectedJoursConso'].value;
    if (typeJour === 'jourFab') {
      selectedJours = form.controls['selectedJoursFab'].value;
    }
    const idsUdpEquipes = form.controls['selectedEquipes'].value ? form.controls['selectedEquipes'].value.map(udpEquipe => udpEquipe.id).join(',') : [];
    selectedJours = selectedJours ? selectedJours.map(date => this.utils.getYYYYMMDD(moment(date))).join(',') : [];

    const fd = new FormData();
    fd.set('idPlanProduction', planProd.id + '');
    fd.set('typeJour', typeJour);
    fd.set('selectedJours', selectedJours);
    fd.set('selectedUdpIdsEquipes', idsUdpEquipes);
    return this.httpSvc.post(URL_GET_PLAN_PROD_DETAILS_FOR_FILTERS_SETTINGS_PRINT, fd);
  };

  /**
   * Impression des fiches techniques liées au pla de production planProd
   * @param planProd
   * @param form
   */
  printFichesTechniques = (planProd: PlanProductionDTO, form: FormGroup, printOnlyRecipesWithParts?: boolean) => {

    const typeJour: TYPE_JOUR = form.controls['typeJour'].value;
    const printMode: FICHE_TECHNIQUE_PRINT_MODE = form.controls['printMode'].value;

    let selectedJours = form.controls['selectedJoursConso'].value;
    let detail: boolean = form.controls['detailJourFab'].value;
    if (typeJour === 'jourFab') {
      detail = form.controls['detailJourConso'].value;
      selectedJours = form.controls['selectedJoursFab'].value;
    }
    const idsUdpEquipes = form.controls['selectedEquipes'].value ? form.controls['selectedEquipes'].value.map(udpEquipe => udpEquipe.id).join(',') : [];
    selectedJours = selectedJours ? selectedJours.map(date => this.utils.getYYYYMMDD(moment(date))).join(',') : [];

    const idsPlatsToFilter = form.controls['ppDetails'].value ? form.controls['ppDetails'].value.map(udpEquipe => udpEquipe.id).join(',') : [];

    let ignoreTeam: boolean = form.controls['ignoreTeam'].value;

    const printRecipesWithParts: boolean = printOnlyRecipesWithParts || false;
    const fd = new FormData();
    fd.set('idPlanProduction', `${planProd.id}`);
    fd.set('typeJour', form.controls['typeJour'].value);
    fd.set('detail', `${detail}`);
    fd.set('selectedJours', selectedJours);
    fd.set('selectedUdpIdsEquipes', idsUdpEquipes);
    fd.set('printMode', printMode);
    fd.set('idsPlatsToFilter', idsPlatsToFilter);
    fd.set('nbDeclinaisonsPerTableAllowed', '10');
    fd.set('printOnlyRecipesWithParts', `${printRecipesWithParts}`);
    fd.set('ignoreTeam', `${ignoreTeam}`);

    return this.http.post(URL_POST_PRINT_PDF_RECETTES, fd, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  printRecapFabPDF = (planProd: PlanProductionDTO, form: FormGroup) => {

    const typeJour: TYPE_JOUR = form.controls['typeJour'].value;

    let selectedJours = form.controls['selectedJoursConso'].value;
    let detail: boolean = form.controls['detailJourFab'].value;
    if (typeJour === 'jourFab') {
      detail = form.controls['detailJourConso'].value;
      selectedJours = form.controls['selectedJoursFab'].value;
    }
    const idsUdpEquipes = form.controls['selectedEquipes'].value ? form.controls['selectedEquipes'].value.map(udpEquipe => udpEquipe.id).join(',') : [];
    selectedJours = selectedJours ? selectedJours.map(date => this.utils.getYYYYMMDD(moment(date))).join(',') : [];

    const idsPlatsToFilter = form.controls['ppDetails'].value ? form.controls['ppDetails'].value.map(udpEquipe => udpEquipe.id).join(',') : [];

    let ignoreTeam: boolean = form.controls['ignoreTeam'].value;

    const fd = new FormData();
    fd.set('idPlanProduction', `${planProd.id}`);
    fd.set('typeJour', form.controls['typeJour'].value);
    fd.set('detail', `${detail}`);
    fd.set('selectedJours', selectedJours);
    fd.set('selectedUdpIdsEquipes', idsUdpEquipes);
    fd.set('idsPlatsToFilter', idsPlatsToFilter);
    fd.set('ignoreTeam', `${ignoreTeam}`);

    return this.http.post(URL_POST_PRINT_PDF_RECAP_FAB, fd, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  createPlanProdFromWorkflowInstance = (wi: WorkflowInstanceDTO) => {
    // persister le nouveau plan de prod avec l'instance
    let pp: PlanProductionDTO = new PlanProductionDTO();
    pp.site = wi.site;
    pp.libelle = wi.libelle;
    pp.actif = true;
    pp.date = new Date().getTime();
    pp.archive = false;
    pp.workflowInstance = wi;
    pp.code = wi.code;

    return pp;
  };


  /**
   * Supprimer des plans de production
   * @param pp
   */
  deletePlansProds = (plansProductions: PlanProductionDTO[]) => {
    const fd = new FormData();
    fd.set('idsPlanProduction', plansProductions ? plansProductions.map(pp => pp.id).join(',') : '');

    return this.http.post(URL_DELETE_PLANS_PRODS, fd).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  getPlanProd = (workflowInstance: WorkflowInstanceDTO): Observable<ResponseWrapper<PlanProductionDTO>> => {
    return this.http.get(URL_GET_PLAN_PROD, {
      params: new HttpParams().set('idWorkflowInstance', workflowInstance.id + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  updateActif = (idProductionDetail: number, selection: boolean): Observable<ResponseWrapper<boolean>> => {
    return this.http.get(URL_GET_UPDATE_ACTIF, {
      params: new HttpParams()
        .set('idProductionDetail', idProductionDetail + '')
        .set('selection', selection ? '1' : '0')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  updateAllActifs = (planProd: PlanProductionDTO, allActif: boolean) => {
    return this.http.get(URL_GET_UPDATE_ALLACTIFS, {
      params: new HttpParams()
        .set('idPlanProd', planProd.id + '')
        .set('allActifs', allActif ? '1' : '0')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  findZoneDeStockageBySiteId = (siteId: number) => this.http.get(URL_FIND_ZONE_DE_STOCKAGE_BY_SITE_ID, {
    params: new HttpParams()
      .set('siteId', siteId + '')

  }).pipe(
    catchError(error => this.utils.handleError(error, true))
  );

  sauverProductionDetailReajustement = (planProductionDetailReajustementDTO: PlanProductionDetailReajustementDTO) => this.http.post(URL_SAUVE_PLAN_PRODUCTION_DETAIL_REAJUSTEMENT, planProductionDetailReajustementDTO).pipe(
    catchError(error => this.utils.handleError(error, true))
  );

  getPlanProductionDetailReajustementList = (idPlanProd: number, dateList: Date[]) => {
    let theDateList = dateList.map(date => this.utils.getYYYYMMDD(moment(date))).join(',');
    return this.http.get(URL_GET_PLAN_PRODUCTION_DETAILS_REAJUSTEMENT_LIST, {
      params: new HttpParams()
        .set('idPlanProd', idPlanProd + '')
        .set('dateList', theDateList + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getEquipeList = () => {
    return this.http.get(URL_GET_EQUIPE).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };
}
