<p-toast position="bottom-right" key="plan-alimentaire"></p-toast>
<p-card>

  <div class="d-flex flex-column">

    <h5>
      <label class="badge badge-secondary">MODÈLE DE MENU ➡️{{pas?.planAlimentaire?.libelle | uppercase}} </label>
    </h5>

    <p-tabMenu
      [model]="items"
      [activeItem]="activeItem"
    >
    </p-tabMenu>
    <p-card>
      <div class="card-size">
        <router-outlet></router-outlet>
      </div>
    </p-card>
  </div>
</p-card>
