<div *ngIf="flatResults && flatResults.length">

  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <div>
        <i class="fas fa-asterisk mg-r-5 mg-l-15"></i> D&Eacute;TAILS
      </div>
    </ng-template>
    <ng-template pTemplate="right">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    </ng-template>
  </p-toolbar>

  <dx-data-grid
    id="tab-calcul-conditionnement"
    [dataSource]="flatResults"
    keyExpr="id"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>

    <dxi-column dataField="atelierLabel" caption="Atelier" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="varianteLabel" caption="Variante" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="equipeLabel" caption="Equipe" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="pointLivraisonLabel" caption="Point livraison client" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="prestationLabel" caption="Prestation" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="regimeLabel" caption="Régime" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true"
                [groupIndex]="3">
    </dxi-column>
    <dxi-column dataField="repasLabel" caption="Repas" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="nbCommandes" caption="Nb. commandes" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="poidsNetUc" caption="Poids net UC" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="qteConditionnee" caption="Quantité conditionnée" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="queueUc" caption="Reste" [width]="100"
                [filterOperations]="['contains']"
                cellTemplate="resteTemplate"
                [allowGrouping]="true">
    </dxi-column>

    <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateConsommation" caption="Date consommation" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateLimiteConsommation" caption="Date l.consommation" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateLivraison" caption="Date livraison" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="datePreparationLivraison" caption="Date préparation livraison" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>

    <div *dxTemplate="let cell of 'resteTemplate'">
      <span [className]="cell.value ? 'reste' : ''">{{cell.value}}</span>
    </div>

    <dxo-summary>
      <dxi-group-item
        column="qteConditionnee"
        summaryType="sum"
        displayFormat="Quantité conditionnée : {0}">
      </dxi-group-item>
      <dxi-group-item
        column="queueUc"
        summaryType="sum"
        displayFormat="Reste : {0}">
          <dxo-value-format type="fixedPoint" precision="3"></dxo-value-format>
      </dxi-group-item>
      <dxi-group-item
        column="nbCommandes"
        summaryType="sum"
        displayFormat="Nb.commandes : {0}">
      </dxi-group-item>
      <dxi-group-item
        column="poidsNetUc"
        summaryType="sum"
        displayFormat="Poids net UC : {0}">
      </dxi-group-item>
    </dxo-summary>


    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} conditionnement(s)">
    </dxo-pager>

  </dx-data-grid>

</div>

