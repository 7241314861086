import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {ActivatedRoute} from "@angular/router";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {ProduitSupplier} from "../../../../../core/services/entities/produits.service";
import {Subscription} from "rxjs/index";
import {Udp_ModeleConditionnementPlat__ProduitDTO} from "../../../../../core/dtos/unite-de-production_modele-conditionnement-plat__produit.dto";
import {ProduitDTO} from "../../../../../core/dtos/produit-dto";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../../../../core/dtos/unite-de-production-modele-conditionnement-plat-dto";
import {GestionUniteProduction__ModeleConditionnementPlatService} from "../../../../../core/services/gestion-unites-productions/unite-production__modele-conditionnement-plat.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {ModeleImplementationMcpDto} from "../../../../../core/dtos/conditionnement/plat/modele-implementation-mcp-dto";
import {ImplementationModelesConditionnementsPlatsService} from "../../../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {UnitedemesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {InstructionDeclinaisonModel} from "../../../../../core/models/gestion-conditionnements/instruction-declinaison";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-dialog-edit-implementation-modele-conditionnement-produit',
  templateUrl: './dialog-edit-implementation-modele-conditionnement-produit.component.html',
  styleUrls: ['./dialog-edit-implementation-modele-conditionnement-produit.component.scss']
})
export class DialogEditImplementationModeleConditionnementProduitComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  subOpenDialog: Subscription;

  implementation: Udp_ModeleConditionnementPlat__ProduitDTO;
  produit: ProduitDTO;
  isFabrique: boolean;
  declinaisons: DeclinaisonDTO[] = [];
  unitesList: UnitedemesureDTO[] = [];
  declinaisonsPoidsFTList: any[] = [];
  declinaisonsNbUCList: any[] = [];
  instructionsByDeclinaison: InstructionDeclinaisonModel[] = [];
  uniteAConditionner: UnitedemesureDTO;

  udpMcpList: UniteDeProduction__ModeleConditionnementPlatDto[] = [];

  form: FormGroup = new FormGroup({});
  dialogTitle = 'Création d\'une implémentation';

  displayDialog: boolean = false;

  hasIDistri: boolean = false;

  displayFormNonNourri: boolean = false;
  modelLoaded: ModeleImplementationMcpDto;

  @ViewChild("gridInstructions") gridInstructions: DxDataGridComponent;

  constructor(private utils: UtilsService,
              private route: ActivatedRoute,
              private udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService,
              private implMcpSvc: ImplementationModelesConditionnementsPlatsService,
              private authSvc: Auth2Service,
              private gds: GenericDatagridService) {

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subOpenDialog);
  }

  ngOnInit(): void {
    this.initRights();
    this.initUnitesAConditionner();
    this.routeSubscription();
    this.openDialogSubscription();
    this.initUdpMcpList();
    this.initForm();
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { produitSupplier: ProduitSupplier }) => {
        this.produit = data.produitSupplier.produitDTO;
        this.isFabrique = data.produitSupplier.isFabrique;
        this.declinaisons = this.produit.produitDeclinaisons.map(pd => pd.declinaison);
        this.declinaisonsPoidsFTList = this.produit.produitDeclinaisons.map(pd =>  ({ declinaison: pd.declinaison, produitDeclinaisonId: pd.id, poidsNet: pd.totalPoidsNetRecetteUT }) );
        this.declinaisonsNbUCList = this.produit.produitDeclinaisons.map(pd => ({ declinaison: pd.declinaison, produitDeclinaisonId: pd.id, nbUc: 0 }) );
        });
  };

  changeUdpModel = (e: any) => {
    this.displayFormNonNourri = e.value && e.value.mode && e.value.mode === 'NE_NOURRI_PAS_UN_CONVIVE';

    this.uniteAConditionner = this.unitesList.find(u => u.id === e.value.uniteAConditionner.id );
    this.form.controls['uniteAConditionner'].setValue(this.unitesList.find(u => u.id === e.value.uniteAConditionner.id ));
  };

  openDialogSubscription = () => {
    this.subOpenDialog = this.implMcpSvc.openDialog$
      .subscribe(response => {
        this.displayDialog = true;
        this.modelLoaded = response;
        this.initForm();
      });
  };

  initRights = (): void => {
    this.authSvc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initUdpMcpList = (): void => {
    this.udpMcpSvc.findAll()
      .subscribe(response => this.udpMcpList = response.resultList);
  };

  initUnitesAConditionner = (): void => {
    this.gds.getAll('unitedemesure')
      .subscribe(response => {
        this.unitesList = response.resultList;
      });
  };

  initForm = (): void => {
    // TODO : Valeurs d'origine si on a chargé par l'id :
    const udpMcp = this.modelLoaded ? this.udpMcpList.find(u => u.id === this.modelLoaded.udpMcpId) : null;
    const remarques = this.modelLoaded ? this.modelLoaded.remarques : '';
    let uniteAConditionner = this.modelLoaded ? this.unitesList.find(u => u.id === this.modelLoaded.uniteAConditionnerId) : null;

    if(!uniteAConditionner && this.uniteAConditionner) {
      uniteAConditionner = this.unitesList.find(u => u.id === this.uniteAConditionner.id);
    }

    const poidsUc = this.modelLoaded ? this.modelLoaded.poidsUc : 0;
    this.displayFormNonNourri = this.modelLoaded && this.modelLoaded.mode === "NE_NOURRI_PAS_UN_CONVIVE";
    this.declinaisonsNbUCList = this.modelLoaded ? this.modelLoaded.nbUcByDeclinaisons.map(o => ({ declinaison: this.declinaisons.find(d => d.id === o.idDeclinaison), nbUc: o.nbUc, produitDeclinaisonId: o.produitDeclinaisonId  }) ) : this.declinaisonsNbUCList;

    if (this.modelLoaded && this.modelLoaded.instructions) {
      this.instructionsByDeclinaison = this.modelLoaded.instructions.map(row => {
        return { libelle: row.libelle,
          declinaisons: row.declinaisons,
          values: row.values
        } as InstructionDeclinaisonModel;
      });
    }

    this.form = new FormGroup({
      udpMcp: new FormControl(udpMcp, [Validators.required]),
      remarques: new FormControl(remarques, [Validators.maxLength(255)]),
      uniteAConditionner: new FormControl(uniteAConditionner, [Validators.required]),
      poidsUc: new FormControl(poidsUc, this.displayFormNonNourri ? [Validators.required] : [])
    });
  };

  canSave = (): boolean => this.hasIDistri;

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  addRowInstruction = (): void => {
    this.instructionsByDeclinaison.push({ libelle: '',
      valuesByDeclinaison: this.initValuesByDeclinaisonRow(),
      declinaisons: this.declinaisons.map(d => d.id),
      values: this.declinaisons.map(d => 0)
    } as InstructionDeclinaisonModel);
  };

  initValuesByDeclinaisonRow = (): Map<number, number> => {
    const map = new Map<number, number>();
    this.declinaisons.forEach(declinaison => map.set(declinaison.id, 0));
    return map;
  };

  save = (): void => {
    if (this.form.valid) {
      const udpMcp = this.form.controls['udpMcp'].value;
      const remarques = this.form.controls['remarques'].value;
      const uniteAConditionner = this.form.controls['uniteAConditionner'].value;
      const poidsUC = this.form.controls['poidsUc'].value;
      const modelToSend: ModeleImplementationMcpDto = new ModeleImplementationMcpDto();
      modelToSend.mcpId = udpMcp.idModeleConditionnementPlat;
      modelToSend.poidsUc = poidsUC;
      modelToSend.uniteAConditionnerId = uniteAConditionner.id;
      modelToSend.remarques = remarques;
      modelToSend.udpMcpId = udpMcp.id;
      modelToSend.produitId = this.produit.id;
      modelToSend.nbUcByDeclinaisons = this.declinaisonsNbUCList.map(o => ({ idDeclinaison: o.declinaison.id, nbUc: o.nbUc, produitDeclinaisonId: o.produitDeclinaisonId }) );
      modelToSend.poidsNetFTByDeclinaisons = this.declinaisonsPoidsFTList.map(o => ({ idDeclinaison: o.declinaison.id, poidsNet: o.poidsNet, produitDeclinaisonId: o.produitDeclinaisonId }) );
      modelToSend.instructions = this.instructionsByDeclinaison;
      modelToSend.update = this.modelLoaded ? true : false;

      this.implMcpSvc.save(modelToSend)
        .subscribe(o => {
          this.displayDialog = false;
          this.implMcpSvc.announceModelSaved(o.one, this.modelLoaded !== undefined && this.modelLoaded !== null);
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  deleteRowInstruction = ($event, rowIndex): void => {
    this.instructionsByDeclinaison.splice(rowIndex, 1);
  };

  changeLibelleInstruction = ($event, rowIndex): void => {
    this.instructionsByDeclinaison[rowIndex].libelle = $event.value;
  };

  changeValueInstruction = ($event, rowIndex, columnIdx): void => {
    const value = $event.value;
    this.instructionsByDeclinaison[rowIndex].values[columnIdx] = value;
    this.instructionsByDeclinaison[rowIndex].valuesByDeclinaison.set(this.instructionsByDeclinaison[rowIndex].declinaisons[columnIdx], value);
  };

  changeUniteAConditionner = ($event): void => {
    this.uniteAConditionner = $event.value;
  };

  changePoidsNetFicheTechnique = ($event, cell): void => {
    const value = $event.value;
    const columnIdx = cell.columnIndex;
    this.declinaisonsPoidsFTList[columnIdx].poidsNet = value;
  };

  changeNbUc = ($event, cell): void => {
    const value = $event.value;
    const columnIdx = cell.columnIndex;
    this.declinaisonsNbUCList[columnIdx].nbUc = value;
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Création d'une implémentation d'un modèle de conditionnement plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
