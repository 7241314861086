import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem, TreeNode} from 'primeng/api';
import {PrintCoutRevientPlatService} from '../../../core/services/gestion-plc/print-cout-revient-plat.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {of, Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {DATEPICKER_FR, MIME_TYPE, TYPE_EFFECTIF} from '../../../core/constants';
import {FlatNode} from '../../../core/models/flatNode';
import {SelectionRepasPlcsService} from '../../../core/services/selection-plcs/selection-repas-plcs.service';
import {debounceTime} from 'rxjs/operators';
import {PrintCoutRevientPlatDTO} from '../../../core/dtos/clients/print-cout-revient-plat-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {DataTreeNodeDTO} from '../../../core/dtos/data-tree-node-dto';
import {FrontStorageService} from '../../../core/services/storage/front-storage.service';
import * as moment from 'moment';

export enum STEP_PRINT_COUT_REVIENT_PLAT {
  selectionPlc,
  selectionEffectifDate
}


@Component({
  selector: 'yo-print-cout-revient-plat',
  templateUrl: './print-cout-revient-plat.component.html',
  styleUrls: ['./print-cout-revient-plat.component.scss']
})
export class PrintCoutRevientPlatComponent implements OnInit, OnDestroy {

  localeFr = DATEPICKER_FR;

  displayDialog: boolean;
  treePlc: TreeNode[] = [];
  selectedPlcs: TreeNode[] = [];
  typeEffectif: string;
  items: MenuItem[];
  activeIndex: number;

  displayPreviewBtn: boolean;
  displayNextBtn: boolean;
  displayPrintBtn: boolean;

  datesSaisieEffectifs: Date[] = [];
  dateCreationMenu: Date[] = [];
  dateMin: Date;
  userMessage: string;
  dateProductionSelected: Date = moment( moment(new Date()).format('DD/MM/YYYY'),'DD/MM/YYYY').toDate();
  periodeSelected: Date[] = [];
  newMonth: any;

  STEP_PRINT_COUT_REVIENT_PLAT = STEP_PRINT_COUT_REVIENT_PLAT;
  TYPE_EFFECTIF = TYPE_EFFECTIF;


  subDisplayDialog: Subscription;
  subChangeMonths: Subscription;


  constructor(private printCrpSvc: PrintCoutRevientPlatService,
              private selectionRepasPlcsSvc: SelectionRepasPlcsService,
              private storageSvc:FrontStorageService,
              public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.initStepper();
    this.initOpenDialog();
    this.initStepperEvent();
    this.getCalendarValidDates();

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayDialog);
    this.utils.unsubscribe(this.subChangeMonths);
  }

  initStepperEvent = () => {
    this.printCrpSvc.getPlcTreeNode().subscribe((response: ResponseWrapper<TreeNode>) => {
      this.treePlc = response.resultList;

      let printCoutRevientPlat: any = this.storageSvc.secureLS.get("printCoutRevientPlat");
      if (!this.utils.isNullOrEmpty(printCoutRevientPlat)) {
        this.selectedPlcs = [];
        this.selectedPlcs = this.utils.isNullOrEmpty(printCoutRevientPlat.selectedPlcs) ? [] : [... <TreeNode[]>printCoutRevientPlat.selectedPlcs];
        this.periodeSelected = printCoutRevientPlat.periode;
        this.dateProductionSelected = new Date(printCoutRevientPlat.dateProductionSelected);
        this.typeEffectif = printCoutRevientPlat.typeEffectif;
      }
    });

    this.subChangeMonths = this.selectionRepasPlcsSvc.changeMonths$.pipe(
      debounceTime(500)).subscribe(event => {
      this.newMonth = event;
      this.getCalendarValidDates();
    });

  };

  initOpenDialog = () => {
    this.subDisplayDialog = this.printCrpSvc.displayDialogPrintCoutRevientPlat$.subscribe((response: boolean) => {
      this.displayDialog = response;
    });
  };

  initStepper = () => {

    this.items = [{
      label: 'Points de livraison',
      command: (event: any) => {
        this.activeIndex = STEP_PRINT_COUT_REVIENT_PLAT.selectionPlc;
      }
    },
      {
        label: 'Effectif / Dates',
        command: (event: any) => {
          this.activeIndex = STEP_PRINT_COUT_REVIENT_PLAT.selectionEffectifDate;
        }
      }
    ];

    this.changeStep(STEP_PRINT_COUT_REVIENT_PLAT.selectionPlc);
    this.typeEffectif = this.TYPE_EFFECTIF.fabrication;
  };

  closeDialog = () => {
    this.displayDialog = false;
  };

  printCouRevientPlat = () => {



    const plcNodes = this.utils.selectedNodesLevel(this.selectedPlcs, 5);
    let repasPlcs = [];

    if (!this.utils.isCollectionNullOrEmpty(plcNodes)) {
      repasPlcs = plcNodes.map(node => node.data);
    }

    let printCoutRevientPlat: PrintCoutRevientPlatDTO = new PrintCoutRevientPlatDTO();
    printCoutRevientPlat.dataTreeNodeDtoList = repasPlcs;
    printCoutRevientPlat.periode = this.periodeSelected;
    printCoutRevientPlat.dateProductionSelected = this.dateProductionSelected;
    printCoutRevientPlat.typeEffectif = this.typeEffectif;

    // Save array in local storage as string
    let printCoutRevientPlatForLocalStorage = {... printCoutRevientPlat};
    printCoutRevientPlatForLocalStorage['selectedPlcs'] = this.selectedPlcs;
    this.storageSvc.secureLS.set("printCoutRevientPlat",printCoutRevientPlat);

    this.printCrpSvc.print(printCoutRevientPlat).subscribe(response => {
      // naming file
      let reportName = 'cout_revient_plat';

      let blob = new Blob([response], {
        type: MIME_TYPE.PDF // must match the Accept type
      });

      // save file
      fs_saveAs(blob, reportName);
      return of(blob);
    });

  };


  onChangeSelectionRepas = event => {
    this.selectedPlcs = event;
  };

  changeStep = (step: number) => {
    this.activeIndex = step;

    switch (step) {
      case STEP_PRINT_COUT_REVIENT_PLAT.selectionPlc: {

        // state btn
        this.displayPreviewBtn = false;
        this.displayNextBtn = true;
        this.displayPrintBtn = false;

        //mettre le tree en mode selectionnable
        this.treeNodeSelectable(true);

        break;
      }
      case STEP_PRINT_COUT_REVIENT_PLAT.selectionEffectifDate: {

        // state btn
        this.displayPreviewBtn = true;
        this.displayNextBtn = false;
        this.displayPrintBtn = true;

        //mettre le tree en mode readOnly
        this.getCalendarValidDates();
        this.treeNodeSelectable(false);
        break;
      }
    }
  };

  treeNodeSelectable = (isSelectable: boolean) => {
    this.treePlc.forEach(node => {
      this.setNodeAndChildSelectable(node, isSelectable);
    });
  };

  setNodeAndChildSelectable = (node: TreeNode, isSelectable: boolean) => {
    node.selectable = isSelectable;
    if (node.children) {
      node.children.forEach(childNode => {
        this.setNodeAndChildSelectable(childNode, isSelectable);
      });
    }
  };

  goBackToStep = () => {
    this.changeStep(this.activeIndex - 1);
  };

  goToStep = () => {
    this.changeStep(this.activeIndex + 1);
  };

  getCalendarValidDates = () => {

    let startDate: Date = new Date();
    startDate = new Date(startDate.getFullYear(), 0, 1);
    let endDate: Date = new Date();
    this.periodeSelected = [startDate, endDate];

    if (!this.utils.isCollectionNullOrEmpty(this.selectedPlcs)) {
      let startDate = new Date();

      if (this.selectedPlcs.length > 0) {

        const plcSpecNodes: TreeNode[] = this.utils.selectedNodesLevel(this.selectedPlcs, 5);
        const flatNodeList: FlatNode[] = FlatNode.treeNodeToFlatNodeList(plcSpecNodes);
        this.selectionRepasPlcsSvc.changeMonthsHttpRequest(this.newMonth, flatNodeList, startDate, 2).subscribe(response => {
          if (response) {

            this.datesSaisieEffectifs = response.additionalProperties['datesSaisieEffectifs'];
            this.dateCreationMenu = response.additionalProperties['dateCreationMenu'];
            this.dateMin = response.additionalProperties['dateMin'];
            this.userMessage = response.additionalProperties['userMessage'];

            this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

            if (!this.utils.isCollectionNullOrEmpty(this.datesSaisieEffectifs)) {
              this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
            }

            if (!this.utils.isCollectionNullOrEmpty(this.dateCreationMenu)) {
              this.dateCreationMenu = this.dateCreationMenu.map(item => this.utils.convertNumberDateToDate(item));
            }
          }
        });
      }
    }
  };

  changeDates = (event: any) => {
    this.selectionRepasPlcsSvc.announceChangeMonths(event);
  };

  selectPlcsNode = (result: TreeNode[], treePlc: TreeNode[], dataTreeNodeDtoList: DataTreeNodeDTO[]) => {
    treePlc.forEach( node =>{
      if(node.children.length > 0){
        this.selectPlcsNode(result, node.children, dataTreeNodeDtoList);
      }else{
        dataTreeNodeDtoList.forEach( (data: DataTreeNodeDTO) =>{
          if(data.niveau === 5){
            if(data.idPointDeLivraison === node.data.idPointDeLivraison && data.idContratMenuConvive === node.data.idContratMenuConvive && data.idRepas === node.data.idRepas){
              result.push(node);
            }
          }
        });
      }
    });
  };

}
