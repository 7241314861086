import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContratMenuConvive__EquipeDTO} from '../../../../core/dtos/contratmenuconvive-equipe-dto';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {ContratsMenusConvivesEquipesService} from '../../../../core/services/entities/contrats-menus-convives-equipes.service';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';

import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {cloneDeep as _cloneDeep} from 'lodash'
import {Subscription} from 'rxjs';
import {TacheDTO} from '../../../../core/dtos/tache-dto';
import {CmcEquipeDialogSupplier} from "../../../../core/suppliers/gestionmenus/cmc-equipe-dialog-supplier";

@Component({
  selector: 'yo-cmc-equipe-dialog-edit',
  templateUrl: './cmc-equipe-dialog-edit.component.html',
  styleUrls: ['./cmc-equipe-dialog-edit.component.scss']
})
export class CmcEquipeDialogEditComponent implements OnInit, AfterViewInit, OnDestroy {

  displayDialog: boolean = false;

  form: FormGroup;

  cmcEquipe: ContratMenuConvive__EquipeDTO;
  tacheFabricationList: TacheDTO[];
  udpEquipeList: UniteDeProduction__EquipeDTO[];
  udpList: {
    id,
    label,
    ateliers: {
      id,
      label,
      equipes: UniteDeProduction__EquipeDTO[]
    }[]
  }[];
  contratMenuConvive: ContratMenuConviveDTO;

  selectedFabricationList: TacheDTO;
  selectedUdpEquipe: UniteDeProduction__EquipeDTO;

  subFormChange: Subscription;

  data:CmcEquipeDialogSupplier;
  subDisplayCmcEquipeDialog: Subscription;

  constructor(public gds: GenericDatagridService,
              public utils: UtilsService,
              private gfs: GenericFormService,
              private cmcEquipeSvc: ContratsMenusConvivesEquipesService) {}

  ngOnInit() {
    this.initEmptyFormGroup();
    this.displayCmcEquipeDialogSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFormChange);
    this.utils.unsubscribe(this.subDisplayCmcEquipeDialog);
  }

  ngAfterViewInit(): void {
    this.subFormChange = this.form.valueChanges.subscribe(values => {
      this.selectedUdpEquipe = values.udpEquipe;
      this.cmcEquipe.idUdpEquipe = this.selectedUdpEquipe.id;
    });
  }

  displayCmcEquipeDialogSubscription = () => {
    this.subDisplayCmcEquipeDialog = this.cmcEquipeSvc.displayCmcEquipeDialog$.subscribe( data => {
      this.data = data;
      this.displayDialog= true;
      this.initData();
    });
  };

  initData = () => {
    // avec le cloneDeep, on decorele la grille de la fenetre
    if (!this.utils.isNullOrEmpty(this.data)) {
      this.cmcEquipe = _cloneDeep(this.data.cmcEquipe);
      this.tacheFabricationList = _cloneDeep(this.data.tacheFabricationList);
      this.udpEquipeList = _cloneDeep(this.data.udpEquipeList);
      this.contratMenuConvive = _cloneDeep(this.data.contratMenuConvive);
      this.initForm();
    }
  };

  initEmptyFormGroup = () => {
    this.form = new FormGroup({
      typeFabrication: new FormControl(),
      udpEquipe: new FormControl()
    });
  };

  initForm = () => {
    this.selectedFabricationList = this.cmcEquipeSvc.initTacheFabrication(this.cmcEquipe, this.tacheFabricationList);
    this.selectedUdpEquipe = this.cmcEquipeSvc.initUdpEquipe(this.cmcEquipe, this.udpEquipeList);
    this.udpList = this.cmcEquipeSvc.initUdpList(this.udpEquipeList);

    this.form = new FormGroup({
      typeFabrication: new FormControl(this.selectedFabricationList, Validators.required),
      udpEquipe: new FormControl(this.selectedUdpEquipe, Validators.required)
    });
  };

  saveContratMenuConviveEquipe = () => {

    //On enregistre que si le formulaire est valide
    if (this.form.valid) {

      this.cmcEquipe.tacheId = this.form.controls['typeFabrication'].value.id;
      this.cmcEquipe.idUdpEquipe = this.form.controls['udpEquipe'].value.id;
      this.cmcEquipe.idContratMenuConvive = this.contratMenuConvive.id;
      this.cmcEquipe.actif=true;

      this.cmcEquipeSvc.saveCmcEquipe(this.cmcEquipe).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          // annoncer à la grille des cmcEquipes qu'un element a changé ou a été créé
          this.cmcEquipeSvc.announceSavedDTO(response);
          this.cmcEquipe = response.one;
          // si c'est un item nouvellement créé, on ferme la fenetre
          if(response.newObj){
            this.closeDialog();
          }
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, 'Affectation de l\'équipe "${this.cmcEquipe.equipeLibelle.toUpperCase()}" au contrat "${this.cmcEquipe.contratMenuConviveLibelle.toUpperCase()}" enregistré.', '', 3000);
        }
      });
    } else {
      //Afficher les erreurs de validation
      this.gfs.validateAllFormFields(this.form);
    }
  };

  /**
   * Le type de fabrication n'est plus modifiable une fois que le {@link ContratMenuConvive__EquipeDTO} a été créé.
   */
  isTypeDeFabricationDisabled = () => {
    let response: boolean = this.cmcEquipe ? this.cmcEquipe.id > 0 : false;
    return response;
  };

  closeDialog = () => {
    this.displayDialog = false;
  };
}

