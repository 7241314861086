import {Component, Input, OnInit} from '@angular/core';
import {forEach as _forEach} from 'lodash';
import {BilanAllergeneSearchDto} from '../../../../core/dtos/gestionmenus/bilan-allergene-search-dto';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {SelectItem} from 'primeng/api';
import {UtilsService} from '../../../../core/utils/utils.service';
import {DATEPICKER_FR, DATES_FORMAT, MIME_TYPE, USER_PREFERENCE} from '../../../../core/constants';
import * as moment from 'moment';
import {saveAs as fs_saveAs} from 'file-saver';
import {of} from 'rxjs';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {PrintMenuService} from '../../../../core/services/gestionmenus/print-menu.service';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';


@Component({
  selector: 'yo-print-bilan-allergene',
  templateUrl: './print-bilan-allergene.component.html',
  styleUrls: ['./print-bilan-allergene.component.scss']
})
export class DialogPrintBilanAllergeneComponent implements  OnInit {

  @Input() public selectedStartDateMenus : Date;
  @Input() public contratsMenuConvives :ContratMenuConviveDTO[];
  @Input() public regimeList: ContratMenuConvive__ContrainteAlimDTO[];

  startDate: Date;
  endDate: Date;
  selectedRegimes:SelectItem[] = [];
  regimeOptionList: SelectItem[] = [];
  fr = DATEPICKER_FR;
  displayDialog: boolean;


  constructor(
    private printMenuService: PrintMenuService,
    private preferencesUtilisateurService : PreferencesUtilisateurService,
    public utils: UtilsService) {}

  ngOnInit(): void {
    this.printMenuService.displayDialogBilanAllergene$.subscribe((response:boolean)=>{
      this.displayDialog = true;

      this.initRegimeOptionList();
      this.startDate = this.selectedStartDateMenus;
      this.endDate = this.setEndDate(this.startDate);
    });
  }

  setEndDate = (startDate: Date): Date => {
    let endDate : Date;
    const offset: number = this.preferencesUtilisateurService.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS)[0];
    endDate =  new Date(startDate);
    endDate.setDate(endDate.getDate() + offset);
    return endDate;
  };

  downloadBilanAllergene = (): void => {
    const params: BilanAllergeneSearchDto = this.getBilanAllergeneMenuDTO()
    const print$  = this.printMenuService.printBillanAllergene(params);
    print$.subscribe( response => {

        // naming file
        let reportName = "bilan-allergene_du"+params.startDate+"au"+params.endDate;

        let blob = new Blob([response], {
          type: MIME_TYPE.PDF // must match the Accept type
        });

        // save file
      fs_saveAs(blob,reportName);

        return of(blob);
    });

    this.displayDialog=false;
  };

  getBilanAllergeneMenuDTO = (): BilanAllergeneSearchDto => {
    const bilanAllergeneMenu: any = {
      startDate:  moment(this.startDate).clone().format(DATES_FORMAT.YYYYMMDD),
      endDate: moment(this.endDate).clone().format(DATES_FORMAT.YYYYMMDD),
      site: this.contratsMenuConvives[0].site,
      contratsMenuConviveId: this.contratsMenuConvives[0].id,
      regimeListId: this.selectedRegimes
    };

    return bilanAllergeneMenu;
  };

  initRegimeOptionList = () => {
    _forEach(this.regimeList, (item: ContratMenuConvive__ContrainteAlimDTO)  => {
      this.regimeOptionList.push({label: item.libelle, value: item.id})
    });
  };

  isValidForm = (): boolean => {
    const isStartDateValid : boolean =  !this.utils.isNullOrEmpty(this.startDate);
    const isEndDateValid : boolean =  !this.utils.isNullOrEmpty(this.endDate);
    return !(isStartDateValid && isEndDateValid);
  };

  closeDialog = () => {
    this.displayDialog=false;
  };

}
