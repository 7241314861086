import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";

@Component({
  selector: 'yo-readonly',
  template: `
    <div class="row margin-yo-readonlybottom">
      <div class="col-md-3">
        <label>{{yoLabel}}</label>
      </div>
      <div class="col-md-9" *ngIf="yoType==='string'">
        {{yoValue}}
      </div>
      <div class="col-md-9" *ngIf="yoType==='boolean'">
        <p-checkbox [binary]="true" [ngModel]="yoValue" [disabled]="true"></p-checkbox>
      </div>
      <div class="col-md-9" *ngIf="yoType==='list'">
        <div *ngFor="let o of yoValue">
          <a (click)="goTo(o.id)"><i [class]="yoIconclass"></i> {{o.libelle}}</a>
        </div>
      </div>
      <div class="col-md-9" *ngIf="yoType==='link'">
        <a  class="left" (click)="goToArr(yoLinkArr,yoLinkQueryParams)"><i [class]="yoIconclass"></i> {{yoValue}}</a>
      </div>
    </div>`,
  styleUrls: ['./readonly.component.scss']
})
/**
 * Composant pour afficher un item de formulaire en mode lecture
 */
export class ReadonlyComponent implements OnInit {

  @Input() yoLabel: string;
  @Input() yoValue: string;
  //types disponibles : string, boolean, list, link
  @Input() yoType: string;
  //lien de navigation
  @Input() yoLink: string;
  //tableau de navigation utilisé par angular router
  @Input() yoLinkArr: string[];
  //parametres optionnels de navigation  utilisé par angular router
  @Input() yoLinkQueryParams:any= {};
  @Input() yoIconclass: string;

  constructor(private route: ActivatedRoute,
              private router: Router,) {
  }

  goTo(id: number) {
    this.router.navigate([this.yoLink, id]);
  }

  goToArr(link: any[], optionalNavParams:any) {

    if(optionalNavParams){
      let navigationExtras: NavigationExtras = {
        queryParams: optionalNavParams,
      };

      this.router.navigate(link, navigationExtras);
    }else{
      this.router.navigate(link);
    }

  }

  ngOnInit() {

    //console.log(this.yoType)
  }


}
