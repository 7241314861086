import {ObjectDTO} from "./object-dto";
import {UnitedemesureDTO} from "./unitedemesure-dto";

export class UniteDeProduction__ModeleConditionnementPlatDto extends ObjectDTO {

  idUniteDeProduction: number;
  libelleUniteDeProduction: string;

  idModeleConditionnementPlat: number;
  libelleModeleConditionnementPlat: string;

  mode: string;

  uniteAConditionner: UnitedemesureDTO;

}
