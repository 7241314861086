import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpParams} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import {TypeTachePmsDto} from "../../dtos/pms/type-tache-pms-dto";

export const URL_GET_ALL = 'dolrest/types-tache-pms/all';
export const URL_SAVE = 'dolrest/types-tache-pms/';
export const URL_DELETE = 'dolrest/types-tache-pms/';

@Injectable({
  providedIn: 'root'
})
export class TypeTachePmsService {
  private subjectOpenDialog = new Subject<TypeTachePmsDto>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  private subjectTypeTacheSaved = new Subject<TypeTachePmsSavedSupplier>();
  typeTacheSaved$ = this.subjectTypeTacheSaved.asObservable();

  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Récupère tous les types de tâche pms
   * @returns {Observable<TypeTachePmsDto>}
   */
  getAll = () : Observable<ResponseWrapper<TypeTachePmsDto>> => this.httpSvc.get(URL_GET_ALL, null);


  /**
   * Sauvegarde un type de tâche pms
   * @param typeTache pms
   */
  save = (typeTache: TypeTachePmsDto) : Observable<ResponseWrapper<TypeTachePmsDto>> => this.httpSvc.post(URL_SAVE, typeTache, null);

  /**
   * Supprime un ou plusieurs type(s) de tâche pms en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds = (idsToDelete: number[]) : Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  announceOpenDialog = (param: TypeTachePmsDto) : void => {
    this.subjectOpenDialog.next(param);
  }

  announceSaved = (typeTache: TypeTachePmsDto, isUpdate: boolean = false) : void => {
    this.subjectTypeTacheSaved.next({ isUpdate, typeTache } as TypeTachePmsSavedSupplier);
  }
}

export interface TypeTachePmsSavedSupplier {
  isUpdate: boolean;
  typeTache: TypeTachePmsDto;
}
