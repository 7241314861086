<ng-container *ngIf="canDisplayAllergenes()">
  <!--<span style="padding:2px;"   (dblclick)="openProduitOnAllergene()" [pTooltip]="getInfoAllergenes()" showDelay="500"><label class="badge badge-danger cursor">Al</label></span>-->

  <div [pTooltip]="getInfoAllergenes()" [escape]="false" class="no-overflow">

  <ng-container *ngFor="let pa of _produitsAllergenes">
    <!--on n'affiche que si le produit allergene est actif-->
    <ng-container *ngIf="pa.actif">
      <span class="cursor pg-2" showDelay="500">

      <yo-img-entity [ordre]="1" [refresh]="refreshImg" [entityName]="allergeneSvc.getEntityName()"
                     [displayNoImage]="false" format="thumb"
                     [entityId]="pa.allergeneId"
                     [openable]="false"
                     [height]="18"
      ></yo-img-entity>
      </span>
    </ng-container>

  </ng-container>
  </div>

</ng-container>
