import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {catchError} from 'rxjs/operators';
import {MenuSearchDto} from '../../dtos/gestionmenus/menu-search-dto';
import {BilanAllergeneSearchDto} from '../../dtos/gestionmenus/bilan-allergene-search-dto';
import {DATES_FORMAT} from '../../constants';
import * as moment from 'moment';
import {Subject} from 'rxjs';

export const URL_POST_PRINT_MENU = `dolrest/print_menus/print_menu_on_period`;
export const URL_POST_PRINT_BILAN_ALLERGENE = `dolrest/print_menus/print_bilan_allergene`;
export const URL_POST_UPLOAD_MENU_LOGO =  `dolrest/print_menus/upload_menu_logo`;
export const URL_GET_PRINT_COUT_MATIERE = `dolrest/print_menus/print_coutmatiere`;

@Injectable()
export class PrintMenuService {

  private subjectDisplayDialogBilanAllergene = new Subject();
  public displayDialogBilanAllergene$ = this.subjectDisplayDialogBilanAllergene.asObservable();

  private subjectDisplayDialogPrintMenu = new Subject();
  public displayDialogPrintMenu$ = this.subjectDisplayDialogPrintMenu.asObservable();

  constructor( private httpClient: HttpClient,
               private utils: UtilsService){}

  public announceDisplayBilanAllegene = (isDisplayDialog: boolean) => {
    this.subjectDisplayDialogBilanAllergene.next(isDisplayDialog);
  };

  public announceDisplayPrintMenu = (isDisplayDialog: boolean) => {
    this.subjectDisplayDialogPrintMenu.next(isDisplayDialog);
  };

  printMenu = (menuSearchDto: MenuSearchDto) => {
    return this.httpClient.post(URL_POST_PRINT_MENU,menuSearchDto, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/pdf')
    }).pipe(
      catchError(error => this.utils.handleError(error))
    );
  };

  printBillanAllergene = (bilanAllergeneMenuDTO: BilanAllergeneSearchDto) => {
    return this.httpClient.post(URL_POST_PRINT_BILAN_ALLERGENE,bilanAllergeneMenuDTO, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/pdf')
    }).pipe(
      catchError(error => this.utils.handleError(error))
    );
  };

  uploadMenuLogo = (menuLogo : File) => {
    const fd = new FormData();
    fd.set('menuLogo', menuLogo);

    return this.httpClient.post(URL_POST_UPLOAD_MENU_LOGO, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  printCoutMatiere = (startDate: Date, endDate: Date, cmcId: number, regimeId: number , repasList: number[]) => this.httpClient.get(URL_GET_PRINT_COUT_MATIERE, {
    params: new HttpParams()
      .set("startDate", moment(startDate).clone().format(DATES_FORMAT.YYYYMMDD))
      .set("endDate", moment(endDate).clone().format(DATES_FORMAT.YYYYMMDD))
      .set("cmcId", cmcId + '')
      .set("regimeId", regimeId + '')
      .append('repasList', repasList.join(', '))
  }).pipe(
    catchError(error => this.utils.handleError(error, true))
  );
}
