import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, filter} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {


  private manageErrors$ = (changes: Observable<any>) => changes.pipe(
    catchError(err => this.utils.handleError(err)),
    filter(response => !this.utils.isResponseSupplierError(response)));

  constructor(private http: HttpClient, private utils: UtilsService) {
  }

  /**
   * GET si erreur, on la catche
   * @param url
   * @param params
   */
  get(url: string, params: HttpParams = null) {

    return this.http.get(url, {
      params: params
    })
      .pipe(this.manageErrors$);

  }

  /**
   * POST si erreur, on la catche
   * @param url
   * @param body
   * @param params
   */
  post(url: string, body, params: HttpParams = null) {

     return this.http.post(url, body, {
      params: params
    }).pipe(this.manageErrors$);

  }


  /**
   * DELETE, si erreur on la catche
   * @param url
   * @param params
   */
  delete(url: string, params: HttpParams = null) {

    return this.http.delete(url, {
      params: params
    }).pipe(this.manageErrors$);

  }
}
