<div class="d-flex flex-row align-content-center mg-t-5 mg-b-5">
  <div class="mg-r-30 align-self-center">
    <label class="mg-r-5">Recherche denrée : </label>
    <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
           <input pInputText
                  type="text" [(ngModel)]="produitDeclinaisonSearch"
                  (ngModelChange)='changedSearchProduitDeclinaisonLibelle($event)'
                  placeholder="Denrées ..."
                  class="input-search-denree">
        </span>
  </div>
  <div class="mg-r-5 align-self-center">
    <label class="mg-r-5">Fournisseurs : </label>
    <p-multiSelect [options]="fournisseurList"
                   appendTo="body"
                   optionLabel="libelle"
                   (onChange)="changeFournisseurSelection($event)"
                   [panelStyle]="{minWidth:'300px'}"
                   selectedItemsLabel="{0} fournisseurs selectionnés"
                   [style]="{minWidth:'300px'}">
    </p-multiSelect>
  </div>
</div>
<dx-data-grid
  [dataSource]="customStore"
  [remoteOperations]="true"
  keyExpr="id"
  height="75vh"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="produitDeclinaisonCode" caption="Denrée"
              [allowFiltering]="false"
              cellTemplate="declinaisonCellTemplate">
  </dxi-column>
  <dxi-column dataField="produitDeclinaisonCode" caption="Code denrée"
              [allowFiltering]="false">
  </dxi-column>
  <dxi-column dataField="produitArticleLibelle" caption="Article"
              cellTemplate="articleCellTemplate"
              [width]="200">
  </dxi-column>
  <dxi-column dataField="fournisseur"
              caption="Fournisseur"
              cellTemplate="fournisseurCellTemplate"
              [width]="200">
  </dxi-column>
  <dxi-column dataField="produitArticle.reference" caption="Ref article"
              [width]="200">
  </dxi-column>
  <dxi-column dataField="groupeAchatCaList"
              cellTemplate="groupeAchatCaCellTemplate"
              caption="Prix d'achat en UF"
              [width]="150">
  </dxi-column>
  <dxi-column dataField="groupeAchatCaList"
              name="prixkg"
              cellTemplate="prixKgCellTemplate"
              caption="Prix au Kg"
              [width]="150">
  </dxi-column>

  <!-- PRODUIT DELINAISON GROUPING TEMPLATE -->
  <div *dxTemplate="let cell of 'declinaisonCellTemplate'">

    <div class="d-flex flex-row align-items-center">

      <label [style.fontWeight]="'bold'" [style.color]="colorsDenrees[cell.row.data.produitDeclinaisonCode]" [pTooltip]="cell.row.data?.produitDeclinaisonLibelle" class="font-12 custom-letter-grey mg-b-0 align-self-center">
        <i class="fas fa-apple-alt " [pTooltip]="isManuelFavoriteAppleColor(cell) ? 'Préféré activé manuellement' : ''" [ngClass]="isManuelFavoriteAppleColor(cell) ? 'modification-mamuelle':'custom-letter-grey' "></i>
         {{ cell.row.data?.produitDeclinaisonLibelle | uppercase}}
      </label>

    </div>
  </div>

  <!-- ARTICLE -->
  <div *dxTemplate="let cell of 'articleCellTemplate'">
    <div class="no-overflow">
      <label class="font-12" pTooltip="{{cell.data.produitArticle.libelle}}">
        {{cell.data.produitArticle.libelle | uppercase}}
      </label>
    </div>
  </div>

  <!-- FOURNISSEUR -->
  <div class="d-flex justify-content-between" *dxTemplate="let cell of 'fournisseurCellTemplate'">
    <label class="font-12">
      {{cell.data.fournisseur.libelle | uppercase}}
    </label>
    <div class="size-icon" *ngIf="!utils.isNullOrEmpty(cell.data.fournisseurOwnerLibelle)"
         [pTooltip]="'Hérité du founisseur : ' +cell.data.fournisseurOwnerLibelle">
      <span class="fa-stack fa-2x">
        <i class="fas fa-circle fa-stack-2x"></i>
        <strong class="fa-stack-1x custom-letter">H</strong>
      </span>
    </div>
  </div>

  <!-- PRIX ACHAT CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'groupeAchatCaCellTemplate'">
    <div *ngFor="let groupeAchatCa of cell.data?.groupeAchatCaList" class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center">
        <ng-container [ngSwitch]="groupeAchatCa.prefere">
          <i *ngSwitchCase="true" class="mg-r-5 fas fa-star cursor"
             [ngClass]="groupeAchatCa.modificationManuelle ? 'modification-mamuelle': ''"
             [pTooltip]="groupeAchatCa.modificationManuelle ?'Article préféré activé par modification manuelle ('+groupeAchatCa.creerPar +')':'Article préféré'"></i>
          <i *ngSwitchCase="false" class="mg-r-5 far fa-star cursor rotate-1-turn"
             pTooltip="Rendre l'article comme préféré"
             (click)="updatePrefere(cell.data, groupeAchatCa)"></i>
          <ng-container *ngSwitchCase="undefined">
            <!--                // DO NOTHING-->
          </ng-container>
        </ng-container>
        <div>{{groupeAchatCa.prixUF | currency:'EUR':'symbol':'1.4-4'}}
          / {{cell.data.uniteDeFacturation.abreviation | slice:0:5}}
          <span
            [pTooltip]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *dxTemplate="let cell of 'prixKgCellTemplate'">
    <div *ngFor="let groupeAchatCa of cell.data?.groupeAchatCaList" class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center">
        <div>{{groupeAchatCa.prixKg | currency:'EUR':'symbol':'1.4-4'}}
          <span
            [pTooltip]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
        </div>
      </div>
    </div>
  </div>

  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="true"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} article(s) préféré(s)">
  </dxo-pager>

</dx-data-grid>


