import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {UtilisateurService} from './core/utilisateur.service';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {UtilsService} from './core/utils/utils.service';

import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {DeviceDetectorModule} from 'ngx-device-detector';
// Service Worker
import {RouterModule} from '@angular/router';

import {ConfirmationService} from 'primeng/api';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {AdministrationModule} from './gestion-referentiel/administration.module';
import {MonbureauModule} from './monbureau/monbureau.module';
import {JwtModule} from '@auth0/angular-jwt';
import {ToastModule} from 'primeng/toast';
import {SidebarModule} from 'primeng/sidebar';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {CardModule} from 'primeng/card';
import {GestionUnitesproductionModule} from './gestion-unitesproduction/gestion-unitesproduction.module';
import {GestionFournisseursModule} from './gestion-fournisseurs/gestion-fournisseurs.module';
import {GestionproduitsModule} from './gestion-produits/gestionproduits.module';
import {GestionmenusModule} from './gestion-menus/gestionmenus.module';
import {GestioncontratsModule} from './gestion-contrats/gestioncontrats.module';
import {GestiongemrcnModule} from './gestion-gemrcn/gestiongemrcn.module';
import {GestionListesBesoinsModule} from './gestion-listes-besoins/gestion-listes-besoins.module';
import {GestionPlcModule} from './gestion-plc/gestion-plc.module';
import {GestionCommandesFournisseursModule} from './gestion-commandes-fournisseurs/gestion-commandes-fournisseurs.module';
import {GestionStocksModule} from './gestion-stocks/gestion-stocks.module';
import {GestionClientsModule} from './gestion-clients/gestion-clients.module';
import {GestionProcessusModule} from './gestion-processus/gestion-processus.module';
import {GestionProductionModule} from './gestion-production/gestion-production.module';
import {GestionMarcheModule} from './gestion-marche/gestion-marche.module';
import {GestionConditionnementsModule} from './gestion-conditionnements/gestionconditionnements.module';

import {BlockUIModule} from 'primeng/blockui';
import {RequeteurGraphqlModule} from "./requeteur-graphql/requeteur-graphql.module";
import { MarkdownModule } from 'ngx-markdown';


// Angular 5 new locale impl
registerLocaleData(localeFr);


@NgModule({
    imports: [

        // CORE
        CoreModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        JwtModule,
        BrowserAnimationsModule,
        BrowserModule,
        // BrowserModule.withServerTransition({ appId: 'serverApp' })

        // ROUTING
        RouterModule,
        AppRoutingModule,

        // SHARED
        SharedModule,

        BlockUIModule,
        ToastModule,
        SidebarModule,
        TooltipModule,
        ButtonModule,
        DialogModule,
        MenuModule,
        CardModule,

        // FEATURES
        GestionFournisseursModule,
        GestionproduitsModule,
        GestionmenusModule,
        GestioncontratsModule,
        GestiongemrcnModule,
        GestionUnitesproductionModule,
        GestionListesBesoinsModule,
        AdministrationModule,
        MonbureauModule,
        GestionPlcModule,
        GestionCommandesFournisseursModule,
        GestionProductionModule,
        GestionProcessusModule,
        GestionClientsModule,
        GestionStocksModule,
        GestionMarcheModule,
        GestionConditionnementsModule,
        RequeteurGraphqlModule,

        MarkdownModule.forRoot()

        // SERVICE WORKER
        // ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production})

    ],
  declarations: [
    AppComponent,
  ],
  providers: [
    UtilsService,
    UtilisateurService, {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
