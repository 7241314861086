import {ObjectDTO} from './object-dto';
import {WorkflowEtape__UtilisateurDTO} from './workflow_etape__utilisateur-dto';
import {WorkflowEtapeDto} from './workflow-etape-dto';
import {VUE_SORTIES_POUR_PRODUCTION} from '../constants';

export class WorkflowInstanceDTO extends ObjectDTO {


  code: string;
  libelle: string;
  actif: boolean;
  archive: boolean;
  reglePrefereParametrageDefaut: boolean;
  lbArticlesEcraserModifManuel: boolean;
  vueSortiesPourProduction: VUE_SORTIES_POUR_PRODUCTION;


  // workflow
  workflowId: number;
  workflowLibelle: number;

  // workflow etape
  idWorkflowEtape: number;
  niveauWorkflowEtape: number;

  // workflow statut
  libelleWorkflowStatut: string;
  codeWorkflowStatut: string;
  hexCouleurWorkflowStatut: string;

  // workflow etape utilisateur
  actors: WorkflowEtape__UtilisateurDTO[] = [];

  // prochaines etapes de l'instance
  nextSteps: WorkflowEtapeDto[];

  reglePrefereId: number;
  reglePrefereLibelle: string;
  reglePrefereCode: string;
  reglePrefereDescription: string;
  typeEffectif:string;

}
