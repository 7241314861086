<ng-container *ngIf="!utils.isCollectionNullOrEmpty(_stocks)">

  <div style="width: calc( 100vw  - 380px )">
    <p-table
      [value]="_stocks"
      [columns]="cols"
      [responsive]="true"
      [scrollable]="true"
      [rows]="50"
      [rowsPerPageOptions]="[5,10,15,20,50,100,200,500]"
      [paginator]="true"
      [scrollHeight]="'200px'"
      styleClass="p-datatable-sm"
      [lazy]="true"
      selectionMode="single"
      (onRowSelect)="onRowSelect($event)"
      [(selection)]="selectedStock"
      dataKey="id"
      #dt>

      <!--COLGROUP-->
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
            <col *ngSwitchCase="'actions'" style="width:150px">
            <col *ngSwitchCase="'lotArticle'" style="width:150px">
            <col *ngSwitchCase="'produitArticleLibelle'" style="width:100px">
            <col *ngSwitchCase="'fournisseur'" style="width:250px">
            <col *ngSwitchCase="'dateEntree'" style="width:150px">
            <col *ngSwitchCase="'dlc'" style="width:100px">
            <col *ngSwitchCase="'quantite'" style="width:230px">
            <col *ngSwitchCase="'quantiteEnKilo'" style="width:150px">
            <col *ngSwitchCase="'prixUnitaireKilo'" style="width:150px">
            <col *ngSwitchCase="'prixUnitaireUS'" style="width:150px">
            <col *ngSwitchCase="'prixUnitaire'" style="width:150px">
            <col *ngSwitchCase="'zdsLibelle'" style="width:200px">
            <col *ngSwitchCase="'modifiePar'" style="width:150px">
            <col *ngSwitchCase="'dateDerniereModification'" style="width:200px">
          </ng-container>
        </colgroup>
      </ng-template>

      <ng-template pTemplate="caption">
        <div class="d-flex">
          <div class="mr-auto">
            Détail des lots en stock pour la denrée {{matierePremiere?.libelleProduit}}
          </div>

          <div class="d-flex" id="switch-lots">
            <div class="mg-r-10">
              Afficher les lots vides
            </div>
            <div>
              <p-inputSwitch [(ngModel)]="showEmpty"></p-inputSwitch>
            </div>
          </div>
        </div>
      </ng-template>

      <!--HEADER-->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'actions'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'lotArticle'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'produitArticleLibelle'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'fournisseur'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'dateEntree'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'dlc'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'quantite'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'quantiteEnKilo'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'prixUnitaireKilo'">
              <th pTooltip="Prix Unitaire Hors Taxe au Kilo">
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'prixUnitaireUS'">
              <th pTooltip="Prix Unitaire Hors Taxe en Unité de Stockage">
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'prixUnitaire'">
              <th pTooltip="Prix Unitaire Hors Taxe en Unité de Facturation">
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'zdsLibelle'">
              <th>
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'modifiePar'">
              <th pTooltip="Login de l'utilisateur ayant effectué la dernière modification manuelle">
                {{col.header}}
              </th>
            </ng-container>
            <ng-container *ngSwitchCase="'dateDerniereModification'">
              <th pTooltip="Date de dernière modification">
                {{col.header}}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>

      <!--BODY-->
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
        <tr [pSelectableRow]="rowData" [class.modification-manuelle]="rowData.modificationManuelle"
            *ngIf="showEmpty || rowData.quantite > 0">
          <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
            <td *ngSwitchCase="'actions'" style="text-align: left">
              <yo-cell-button
                (click)="openDialogSortie(rowData)"
                [yoTooltipShowDelay]="1500"
                [yoMaxWidth]="true"
                [yoWidthPercent]="30"
                [yoTextAlign]="'center'"
                [yoIconClass]="'fas fa-minus'"
                [pTooltip]="'Procéder manuellement à une sortie partielle ou totale d\'un lot de matières premières'"
                [yoDisabled]="true"
                tooltipPosition="right"
                showDelay="1000"
              ></yo-cell-button>
              <yo-cell-button
                (click)="openDialogEditLot(rowData)"
                [yoTooltipShowDelay]="1500"
                [yoMaxWidth]="true"
                [yoWidthPercent]="30"
                [yoTextAlign]="'center'"
                [yoIconClass]="'fa fa-edit'"
                [pTooltip]="'Modifier le lot'"
                [yoDisabled]="true"
                tooltipPosition="right"
                showDelay="500"
              ></yo-cell-button>
            </td>
            <td *ngSwitchCase="'lotArticle'" style="text-align: left">
              {{rowData.lotArticle}}
            </td>
            <td *ngSwitchCase="'produitArticleLibelle'" class="text-left">
              <div class="font10" [pTooltip]="rowData.produitArticleLibelle" showDelay="500">
                {{rowData.produitArticleLibelle}}
              </div>
            </td>
            <td *ngSwitchCase="'fournisseur'" style="text-align: left">
              {{rowData.fournisseurLibelle}}
            </td>
            <td *ngSwitchCase="'dateEntree'" style="text-align: right">
              {{rowData.dateEntree | date:'dd/MM/yy'}}
            </td>
            <td *ngSwitchCase="'dlc'" style="text-align: right" [class.perime]="stockSvc.isPerime(rowData.dlc,ssw)">
              {{rowData.dlc | date:'dd/MM/yy'}}
            </td>
            <td *ngSwitchCase="'quantite'" style="text-align: right">
              {{rowData.quantite | number:'1.4-4'}} {{rowData.udmStockLibelle}}
            </td>
            <td *ngSwitchCase="'quantiteEnKilo'" style="text-align: right">
              {{rowData.quantiteEnKilo | number:'1.4-4'}}
            </td>
            <td *ngSwitchCase="'prixUnitaireKilo'" style="text-align: right;">
              {{rowData.prixUnitaireKilo  | currency:'EUR':'symbol':'1.4-4' }}
            </td>
            <td *ngSwitchCase="'prixUnitaireUS'" style="text-align: right;">
              {{rowData.prixUnitaireUS  | currency:'EUR':'symbol':'1.4-4' }} / {{rowData.udmStockLibelle}}
            </td>
            <td *ngSwitchCase="'prixUnitaire'" style="text-align: right;">
              {{rowData.prixUnitaire  | currency:'EUR':'symbol':'1.4-4' }} / {{rowData.udmFacturationLibelle}}
            </td>
            <td *ngSwitchCase="'zdsLibelle'" style="text-align: left;">
              <div>
                <i class="fas fa-industry mg-r-5"></i>{{rowData.udpLibelle}}
              </div>
              <div class="mg-l-10 mg-t-5">
                <i class="fas fa-cubes mg-r-5"></i>{{rowData.zdsLibelle}}
              </div>
            </td>
            <td *ngSwitchCase="'modifiePar'" style="text-align: right;">
              {{ rowData.modifiePar || '-' }}
            </td>
            <td *ngSwitchCase="'dateDerniereModification'" style="text-align: right;">
              {{ rowData.dateDerniereModification ? utils.getFrenchDateHHMM(rowData.dateDerniereModification) : '-' }}
            </td>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr>
          <td style="text-align:left; font-size: 11px">
            <i class="fas fa-square col-edited-color mg-r-5 border"></i>Modification manuelle
        </tr>
      </ng-template>

    </p-table>
  </div>

</ng-container>

<yo-dialog-modification-lots></yo-dialog-modification-lots>

