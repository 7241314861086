import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {RoutemapService} from '../../../../core/services/routemap.service';

import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import ConditionnementVarianteDTO from "../../../../core/dtos/conditionnement/conditionnement-variante-dto";
import {
  ConditionnementsVarianteService,
  ConditionnementVarianteSavedSupplier
} from "../../../../core/services/conditionnements/conditionnement-variante.service";
import {ConfirmationService} from "primeng/api";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";

@Component({
  selector: 'yo-conditionnements-variantes',
  templateUrl: './conditionnements-variantes.component.html',
  styleUrls: ['./conditionnements-variantes.component.scss']
})
export class ConditionnementsVariantesComponent implements OnInit, OnDestroy {

  conditionnementsVariantesList: ConditionnementVarianteDTO[] = [];

  hasIDistri = false;

  subConditionnementVarianteSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/ref-conditionnements-variantes';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private confirmationSvc: ConfirmationService,
              private cvSvc: ConditionnementsVarianteService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit(): void {
    this.initConditionnementsVariantes();
    this.initHasGestionIDistri();
    this.conditionnementVarianteSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subConditionnementVarianteSaved);
  }

  initConditionnementsVariantes = (): void => {
    this.cvSvc.getAll()
      .subscribe(response => {
        this.conditionnementsVariantesList = response.resultList;
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canEdit = (): boolean => this.auth2Svc.hasSitesLocaux() && this.hasIDistri;

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        const idsToDelete: number[] = this.selectedRows
          .map((idSelected: number) => this.conditionnementsVariantesList.find(cv => cv.id === idSelected))
          .map((cv: ConditionnementVarianteDTO) => cv.id);
        this.cvSvc.deleteByIds(idsToDelete)
          .subscribe(o => {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,  `La suppression a bien été prise en compte`);
            this.conditionnementsVariantesList =
              this.conditionnementsVariantesList.filter(v => !idsToDelete.includes(v.id));
          });
      }
    });
  };

  conditionnementVarianteSavedSubscription = (): void => {
    this.subConditionnementVarianteSaved = this.cvSvc.conditionnementVarianteSaved$
      .subscribe((response: ConditionnementVarianteSavedSupplier) => {
        if (!response.isUpdate) {
          response.conditionnementsVariantes.forEach(cv => {
            if (!this.conditionnementsVariantesList.find(it => it.variante.id === cv.variante.id && it.conditionnement.id === cv.conditionnement.id)) {
              this.conditionnementsVariantesList.push(cv);
            }
          });
        }
      });
  };

  openConditionnementVariante = (cv: ConditionnementVarianteDTO): void => {
    this.cvSvc.announceOpenDialogConditionnementVariante(cv);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les conditionnements variantes`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
