<p-dialog
  [dismissableMask]="false"
  [closable]="true"
  [closeOnEscape]="true"
  (onHide)="closeDialog()"
  [(visible)]="displayDialog"
  showEffect="fade"
  [style]="{'width':'1010px','height':utils.getWindowAvailableHeight(100)+'px'}"
  [contentStyle]="{'overflow':'hidden'}"
  [modal]="true"
  #pDialog>

  <ng-container *ngIf="!utils.isNullOrEmpty(pDialog)">
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        <span [innerHTML]="domSanitizer.bypassSecurityTrustHtml(getTitle(produitDeclinaison))"> </span>
      </div>
    </ng-template>

    <div [style.height.px]="utils.getWindowAvailableHeight(240)" style="overflow-y:auto;overflow-x: hidden;">
      <yo-form [groups]="groups" [form]="form" [options]="{'width':980}"></yo-form>
    </div>

    <ng-template pTemplate="footer">
      <div class="d-flex">
        <div class="mr-auto">
        </div>
        <div>
          <button type="button" class="p-button-secondary" pButton icon="fas fa-cog" (click)="menuopt.toggle($event)"></button>
          <p-menu #menuopt [popup]="true" appendTo="body" [model]="items"></p-menu>
        </div>
        <div>
          <button pButton icon="fas fa-save" (click)="saveProduitDeclinaison()"
                  [disabled]="!gds.canModify(produitDeclinaison)" pTooltip="Enregistrer" tooltipPosition="top"
                  showDelay="500" class="p-button-success"></button>
        </div>
        <div>
          <button *ngIf="produitDeclinaison?.id !== 0" pButton icon="fas fa-trash"
                  (click)="openDeleteDialog(produitDeclinaison)"
                  [disabled]="!canDelete(produitDeclinaison)"
                  pTooltip="Supprimer" tooltipPosition="top"
                  showDelay="500" class="p-button-danger">
          </button>
        </div>
        <div>
          <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                  class="p-button-secondary"></button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</p-dialog>


<yo-dialog-change-unite-technique></yo-dialog-change-unite-technique>
