<div class="card-size">

  <div class="mg-b-10">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>

    <button pButton
            class="mg-r-15"
            label="CRÉER"
            icon="fa fa-plus"
            [pTooltip]="'Création d\'un marché'"
            (click)="openEditObject(undefined)"
            [disabled]="!canCreate()">
    </button>
  </div>

  <dx-data-grid
    [dataSource]="marcheList"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(182)"
    width="100%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>
    <dxi-column dataField="id" caption="Actions" [width]="100"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="libelle" caption="Marché" [width]="350"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="site" caption="Site" [width]="200" cellTemplate="siteCellTemplate"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="code" caption="Code" [width]="150" [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="descriptif" caption="Description"
                [width]="350"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="dateDebut" caption="Date de début" [width]="150" dataType="date"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="dateFin" caption="Date de fin" [width]="150" dataType="date"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="actif" caption="Actif" [width]="80"
                [allowFiltering]="true">
    </dxi-column>

    <!--  ACTIONS CELL TEMPLATE-->
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"></yo-site>
    </div>

    <div *dxTemplate="let cell of 'actionsCellTemplate'">
      <ng-container *ngIf="canModify(cell.row.data)">
        <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoMaxWidth]="true"
                        [yoWidthPercent]="30"
                        [yoTextAlign]="'center'"
                        [yoIconClass]="'fa fa-edit'"
                        pTooltip="Modifier le marché"
                        tooltipPosition="right"
                        showDelay="500"
        ></yo-cell-button>

        <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoMaxWidth]="true"
                        [yoWidthPercent]="30"
                        [yoTextAlign]="'center'"
                        [yoIconClass]="'fa fa-trash'"
                        pTooltip="Supprimer le marché"
                        tooltipPosition="right"
                        showDelay="500"
        ></yo-cell-button>
      </ng-container>
      <ng-container *ngIf="!canModify(cell.row.data)">
        <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoMaxWidth]="true"
                        [yoWidthPercent]="30"
                        [yoTextAlign]="'center'"
                        [yoIconClass]="'fa fa-eye'"
                        pTooltip="Voir le marché"
                        tooltipPosition="right"
                        showDelay="500"
        ></yo-cell-button>
      </ng-container>
    </div>
    <dxo-pager
      [showPageSizeSelector]="false"
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} marchés">
    </dxo-pager>

  </dx-data-grid>
</div>



