<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','height':'500px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'800px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-12 genlabel required" pTooltip="Libellé">Sélectionner les denrée(s) à ne pas approvisionner <span class="danger-color">*</span> </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-12">
        <p-table
          [value]="denrees"
          [columns]="columns"
          (onLazyLoad)="loadData($event)"
          [rows]="50"
          [rowsPerPageOptions]="[10,20,50,100,200,500]"
          [paginator]="true"
          [lazy]="true"
          [totalRecords]="totalProduits"
          selectionMode="multiple"
          [scrollHeight]="'250px'"
          styleClass="p-datatable-sm"
          [responsive]="true"
          [scrollable]="true"
          [(selection)]="denreesSelected"
          dataKey="id" #dtr>
          <ng-template pTemplate="header">
            <tr>
              <th [width]="'80px'">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
            <tr>
              <th></th>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <ng-container *ngSwitchCase="'libelle'">
                  <input pInputText style="width: 100%" type="text" (input)="dtr.filter($event.target.value, 'denree', undefined)" />
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pd let-rowIndex="rowIndex">
            <tr>
              <td [width]="'80px'">
                <p-tableCheckbox  [value]="pd"></p-tableCheckbox >
              </td>
              <td *ngFor="let col of columns">
                {{pd[col.field]}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="d-flex justify-content-end" style="margin-top: 15px">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Enregistrer"
        [disabled]="!canSave()"
        [class.disable]="!canSave()">
      </button>
      <button
        class="mg-l-5 p-button-secondary"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>
