import {Component, Input, OnInit} from '@angular/core';
import {PrintInventaireService} from '../../../../core/services/gestion-stock/print-inventaire.service';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {PrintOptionInventaire} from '../../../../core/models/gestion-stock/print-inventaire.model';

export enum PrintOption {
  detailsByLot = 'detailsByLot',
  detailsAccounting = 'detailsAccounting',
  showQuantite = 'showQuantite',
  hideEmpty = 'hideEmpty',
  dlc = 'dlc'
}

@Component({
  selector: 'yo-dialog-print-inventaire',
  templateUrl: './dialog-print-inventaire.component.html',
  styleUrls: ['./dialog-print-inventaire.component.scss']
})
export class DialogPrintInventaireComponent implements OnInit{

  @Input() public searchSupplierWrapper: SearchSupplierWrapper;

  public  printOption = PrintOption;
  public displayDialog: boolean = false;

  // par defaut, on exclu les elements vides https://jira.groupeyoni.fr/browse/DO-684
  public printOptions : string[] = [PrintOption.hideEmpty];

  constructor(private printInventaireService: PrintInventaireService){}

  public ngOnInit() {
    this.printInventaireService.displayDialogPrintInventaire$.subscribe( (response: boolean)=>{
      this.displayDialog = response;
    });
  }

  public closeDialog = () => {
    this.displayDialog = false;
  };

  public printInventaire = () => {

    const printOptionInventaire : PrintOptionInventaire = {
      searchSupplierWrapper : this.searchSupplierWrapper,
      printOptions : this.printOptions
    };

    this.printInventaireService.printInventaire(printOptionInventaire).subscribe(response => {
      let blob = new Blob([response], {type: 'application/pdf'});

      const dateTitle : string  = moment(new Date).format('DD-MM-YYYY').toString();
      let titleFile: string = 'inventaire-'+dateTitle+'.pdf';
      saveAs(blob, titleFile);
    });
    this.closeDialog();
  };

}
