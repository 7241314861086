<div class="row">

  <!--FILTRES-->
  <div class="col-md-auto">


    <h3 class="first">Familles de plats</h3>
    <p-multiSelect [options]="famillesPlats"
                   [(ngModel)]="selectedFamillesPlats"
                   optionLabel="libelle"
                   [displaySelectedLabel]="false"
                   (onChange)="refreshGrid($event)"
                   defaultLabel="Familles de plats..."
                   [panelStyle]="{minWidth:'12em'}">
      <ng-template let-famille pTemplate="item">

        <div style="display:inline;position: relative;height: 25px;">
          <span style="font-size:14px;margin-top:4px;margin-left:30px;"><yo-site
            [siteLibelleTooltip]="famille.site.libelle"
            [siteId]="famille.site.id"></yo-site>
            {{famille.libelle}}</span>
        </div>

      </ng-template>
    </p-multiSelect>


    <div class="width-200 genlabel-color font-12 mg-t-10">
      <i class="fas fa-info-circle mg-r-5"></i>
      <ul>
        <li>
          Elle donne la liste des familles de plat qui seront disponibles lors de la constitution des menus.
        </li>
      </ul>
    </div>

  </div>


  <!--GRILLE-->
  <div class="col">

    <ng-container *ngIf="!isGridDisplayable()">
      <div class="row">
        <div class="col-md-auto">
          <p class="font-14 genlabel-color">
            <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
            Aucune famille de plat paramétrée. Au préalable, vérifiez que vous avez créer un planning.
          </p>
        </div>
      </div>
      <div class="col text-right">
        <yo-button-save (onClick)="save()" [yoDisabled]="!gds.canModify(planAlimentaire)"></yo-button-save>
      </div>
    </ng-container>

    <ng-container *ngIf="isGridDisplayable()">
      <p-table
        [scrollHeight]="utils.scrollHeightContent(150)"
        styleClass="p-datatable-sm"
        [scrollable]="true"
        [frozenWidth]="colFamillePlatWidth+'px'"
        [value]="selectedFamillesPlats">

        <!--FROZEN COLUMNS HEADER-->
        <ng-template pTemplate="frozenheader">
          <tr>
            <th>Familles de plats</th>
          </tr>
        </ng-template>

        <!--FROZEN COLUMNS BODY-->
        <ng-template pTemplate="frozenbody" let-rowData>
          <tr>
            <td>
              <yo-site [siteLibelleTooltip]="rowData.site.libelle" [siteId]="rowData.site.id"></yo-site>
              {{rowData.libelle}}
            </td>
          </tr>
        </ng-template>


        <!--CAPTION-->
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-md-6 text-left font-12">
              🗣️<em> N'oubliez pas d'enregistrer votre travail.</em>
            </div>
            <div class="col-md-6 text-right">
              <yo-button-save (onClick)="save()" [yoDisabled]="!gds.canModify(planAlimentaire)"></yo-button-save>
            </div>
          </div>
        </ng-template>

        <!--HEADER-->
        <ng-template pTemplate="header">

          <tr>
            <ng-container *ngFor="let repas of selectedRepasList ">

              <ng-container *ngFor="let composanteRepas of selectedComposantesRepas">
                <th [style.width.px]="cellWidth">
                  <div class="no-overflow" showDelay="500" [pTooltip]="repas.libelle+' > '+composanteRepas.libelle">
                        <span style="font-weight:normal;font-size:12px"
                              class="mg-r-5">  {{repas.libelle}}</span> {{composanteRepas.libelle}}
                  </div>
                </th>
              </ng-container>

            </ng-container>
          </tr>
        </ng-template>

        <!--BODY-->
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>


            <!--CELLULES-->
            <ng-container *ngFor="let repas of selectedRepasList ">

              <ng-container *ngFor="let composanteRepas of selectedComposantesRepas">
                <ng-container *ngTemplateOutlet="tplCell;context:{cell:getCell(rowData,repas,composanteRepas)}">
                </ng-container>
              </ng-container>

            </ng-container>

          </tr>

        </ng-template>

      </p-table>

    </ng-container>

  </div>

</div>


<!--TEMPLACE CELLULE-->
<ng-template #tplCell let-cell="cell">

  <td [style.width.px]="cellWidth" class="cursor" [class.cellChecked]="cell.actif" (click)="selectCell(cell)">

  </td>

</ng-template>
