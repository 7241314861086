import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {SiteDTO} from '../../../../core/dtos/site-dto';
import VarianteDTO from '../../../../core/dtos/conditionnement/variante-dto';
import {VariantesService} from '../../../../core/services/conditionnements/variantes.service';

@Component({
  selector: 'yo-variante',
  templateUrl: './variante.component.html',
  styleUrls: ['./variante.component.scss']
})
export class VarianteComponent implements OnInit, OnDestroy {

  hasIDistri = false;

  displayDialog = false;

  subOpenDialogVariante: Subscription;

  variante: VarianteDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'une variante';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private variantesSvc: VariantesService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasGestionIDistri();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogVariante);
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialogVariante = this.variantesSvc.openDialogVariante$
      .subscribe((v: VarianteDTO) => {
        this.displayDialog = true;
        if (!v) {
          this.variante = new VarianteDTO();
          this.variante.id = 0;
          this.dialogTitle = 'Création d\'une variante';
        } else {
          this.variante = v;
        }
        this.initForm();
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  canModify = (): boolean => this.variante && this.hasIDistri && this.gds.canModify(this.variante);

  save = (): void => {
    if (this.form.valid) {
      this.variante.site = { id: this.form.controls['site'].value.id } as SiteDTO;
      this.variante.libelle = this.form.controls['libelle'].value;
      this.variante.code = this.form.controls['code'].value;
      this.variantesSvc.save(this.variante)
        .subscribe(response => {
          this.variantesSvc.announceVarianteSaved(response.one, this.variante.id !== undefined && this.variante.id !== null && this.variante.id !== 0);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde de la variante réalisée avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      site: new FormControl(this.variante ? this.findAllLocalSites().find(s => s.id === this.variante.site?.id) : null, [Validators.required]),
      libelle: new FormControl(this.variante ? this.variante.libelle : '', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      code: new FormControl(this.variante ? this.variante.code : '', [Validators.required, Validators.maxLength(50), Validators.minLength(2)])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `La variante`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
