import {Injectable} from '@angular/core';
import {WorkflowInstanceDTO} from '../../../core/dtos/workflow-instance-dto';
import {SortiePourProductionDTO} from '../../../core/dtos/sortie-pour-production-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {WorkflowInstanceService} from '../../../core/services/entities/workflow-instance.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {combineLatest, Observable} from 'rxjs';
import {SortiePourProductionService} from '../../../core/services/entities/sortie-pour-production.service';
import {PlanProductionDTO} from '../../../core/dtos/plan-production-dto';
import {PlanProductionService} from '../../../core/services/entities/plan-production.service';
import {GenericRequestSupplier, Predicat, Search} from '../../../core/suppliers/generics/generic-request-supplier';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../../core/constants';


@Injectable({
  providedIn: 'root'
})
export class SortiesProductionResolverService implements Resolve<SortiesProductionSupplier> {

  constructor(private gds: GenericDatagridService,
              private workflowInstanceSvc: WorkflowInstanceService,
              private sppSvc: SortiePourProductionService,
              private planProdSvc: PlanProductionService) {}

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SortiesProductionSupplier> | Promise<SortiesProductionSupplier> | SortiesProductionSupplier => {

    let sps = new SortiesProductionSupplier();
    const idWorkflowInstance: number = +route.parent.paramMap.get('idWorkflowInstance');
    const workflowInstance$ = this.gds.getOne(this.workflowInstanceSvc.getEntityName(), idWorkflowInstance);
    const sortiesPourProductionList$ = this.sppSvc.getByIdWorkflowInstance(idWorkflowInstance);
    const planProduction$ = this.gds.search(this.filterPlanProd(idWorkflowInstance));

    const all$ = combineLatest([workflowInstance$, sortiesPourProductionList$, planProduction$]);

    return all$.toPromise().then(data => {
      sps.workflowInstance = data[0].one;
      sps.sortiesPourProductionList = data[1].resultList;
      sps.planProduction = data[2].resultList[0];

      return sps;
    });
  };

  private filterPlanProd = (idWorkflowInstance: number) => {
    const planProdEntityName = this.planProdSvc.getEntityName().toLowerCase();

    const predicat1 = new Predicat();
    predicat1.path = `${planProdEntityName}.workflowInstance.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idWorkflowInstance + '';

    const search = new Search();
    search.predicats = [predicat1];

    const grs = new GenericRequestSupplier(this.planProdSvc.getEntityName());
    grs.search = search;
    return grs;
  };
}

export class SortiesProductionSupplier {
  workflowInstance: WorkflowInstanceDTO;
  planProduction: PlanProductionDTO;
  sortiesPourProductionList: SortiePourProductionDTO[] = [];
}
