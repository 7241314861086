import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {
  CatalogueAchatDialogSupplier,
  CataloguesAchatsService
} from '../../../../../core/services/entities/catalogues-achats.service';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {CatalogueAchatDTO} from '../../../../../core/dtos/catalogue-achat-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import {AppellationsService} from '../../../../../core/services/entities/appellations.service';
import {AppellationDTO} from '../../../../../core/dtos/appellations-dto';
import {ProduitArticleAppellationDTO} from '../../../../../core/dtos/produit-article-appellation-dto';
import {DxDataGridComponent} from 'devextreme-angular';
import {DATAGRID_ROW_TYPES} from '../../../../../core/services/technique/devextreme.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';
import {MessageService} from 'primeng/api';
import {UniteDeProductionDTO} from '../../../../../core/dtos/unite-de-production-dto';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';

@Component({
  selector: 'yo-appellations',
  templateUrl: './article-appellations.component.html',
  styleUrls: ['./article-appellations.component.scss']
})
export class ArticleAppellationsComponent implements OnInit, OnDestroy {

  subArticleAppellations: Subscription;
  catalogueAchat: CatalogueAchatDTO;
  appellationList: AppellationDTO[];
  produitArticleAppellationList: ProduitArticleAppellationDTO[];
  cads:CatalogueAchatDialogSupplier;
  uniteDeProduction: UniteDeProductionDTO;

  @ViewChild('grid') grid: DxDataGridComponent;

  constructor(public utils: UtilsService,
              private msgSvc:MessageService,
              public auth2Svc: Auth2Service,
              public catalogueAchatSvc: CataloguesAchatsService, private gds: GenericDatagridService, public appellationSvc: AppellationsService) {
  }

  ngOnInit(): void {

    this.initArticleAppellationsSubscription();
  }

  initArticleAppellationsSubscription = () => {

    this.subArticleAppellations = this.catalogueAchatSvc.articleAppellations$.pipe(
      filter(data => data),
      tap(data=>this.cads=data),
      tap(data => this.uniteDeProduction = _cloneDeep(data.uniteDeProduction)),
      tap(data => this.catalogueAchat = _cloneDeep(data.catalogueAchat)),
      switchMap(data => this.gds.getAll(this.appellationSvc.getEntityName(), this.appellationSvc.getSort(), true)),
      switchMap(data => {
        this.appellationList = data.resultList;
        return this.catalogueAchatSvc.getProduitArticleAppellations(this.catalogueAchat.produitArticle.id);
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe(data => {
      this.produitArticleAppellationList = data.resultList;
      this.grid.selectedRowKeys = this.preselectRows(this.appellationList, this.produitArticleAppellationList);
    });

  };


  preselectRows = (appellationList: AppellationDTO[], produitArticleAppellationList: ProduitArticleAppellationDTO[]) => {
    const idSelectedRows = [];

    if (!this.utils.isCollectionNullOrEmpty(produitArticleAppellationList) && !this.utils.isCollectionNullOrEmpty(appellationList)) {
      for (const paa of produitArticleAppellationList) {
        const appellation = _find(appellationList, {'id': paa.appellationId});
        if (appellation) {
          idSelectedRows.push(appellation.id);
        }

      }
    }

    return idSelectedRows;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subArticleAppellations);
  }

  saveAppellations = () => {

    const selectedAppellationList = this.grid.instance.getSelectedRowsData();

    this.catalogueAchatSvc.saveProduitArticleAppellations(this.catalogueAchat.produitArticle.id,selectedAppellationList).subscribe(response=>{
      if(!this.utils.isResponseSupplierError(response)){
        this.utils.showMsg(MSG_KEY.DIALOG_EDIT_ARTICLE,MSG_SEVERITY.SUCCESS,'Appellations enregistrées');
      }
    });

  };

  closeDialog = () => {
    this.catalogueAchatSvc.announceCloseDialogArticle(undefined);
  };



  onRowPrepared = (event: any) => {

    if (event.rowType === DATAGRID_ROW_TYPES.DATA ) {
      event.rowElement.style.height = '64px';

    }
  };
}
