<div class="d-flex flex-column size-info-general" [style.max-width.px]="utils.getMaxWidthBody()">

  <div>
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <label class="space"><strong>Groupe achat {{groupeAchat.libelle | uppercase}}</strong></label>
  </div>

  <div class="flex-grow-1">
    <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getMaxWidthBody()}">
    </yo-form>
  </div>

  <div>
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        🗣️<em class="mg-r-5"> N'oubliez pas d'enregistrer votre travail.</em>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-save" (click)="saveFicheIdentite()"
                [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500" class="p-button-success"></button>
      </div>
      <div>
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="utils.sidenav=false"
                class="p-button-secondary"></button>
      </div>
    </div>
  </div>
</div>
