import {Component, forwardRef, Input} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ValueAccessorBase} from "../value-accessor-base";

@Component({
  selector:'yo-input-password',
  templateUrl: './input-password.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true
    }
  ],
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent extends ValueAccessorBase<string> {

  @Input() yoLabel: string;
  @Input() yoRequired: boolean;
  @Input() yoMinLength: number;
  @Input() yoMaxLength: number;
  @Input() yoFormControl: FormControl;
}
