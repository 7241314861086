import {catchError, finalize} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {StockReservationDTO} from '../../dtos/stock-reservation-dto';
import {ZoneDeProductionDTO} from '../../dtos/zone-de-production-dto';
import {ZoneDeStockageDTO} from '../../dtos/zone-de-stockage-dto';
import {PlanProductionDTO} from '../../dtos/plan-production-dto';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {MValidationDenree} from '../../models/gestion-production/stocks/validation/m-validation-denree';
import {startCase as _startCase} from 'lodash';

export const URL_POST_RETRIEVE_STOCKS_RESERVATIONS = `dolrest/gestion-production/planProduction/stocks-reservations`;
export const URL_POST_UPDATE_VALIDATION_STOCKS_RESERVATIONS = `dolrest/gestion-production/planProduction/stocks-reservations/update`;


@Injectable({
  providedIn: 'root'
})
export class StocksReservationsService extends GenericHandler<StockReservationDTO> {


  private subjectStocksReservations = new BehaviorSubject(undefined);
  stocksResas$ = this.subjectStocksReservations.asObservable();

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['quantiteReservee,desc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES STOCKS RÉSERVATIONS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN STOCK RÉSERVATION';
  }

  constructor(utils: UtilsService,
              private gds: GenericDatagridService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);


  }

  getEntityName(): string {
    return 'StockReservation';
  }

  getFields(dto: StockReservationDTO): FormFieldBaseSupplier<any>[] {

    return [];
  }

  getAllFromEnvironnement(): void {

  }

  createEmptyDTO(): StockReservationDTO {
    return new StockReservationDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LE STOCK RÉSERVATION '${data.libelle.toUpperCase()}'`;
  }

  /**
   * on recupere les stocks résa à partir d'un jour de plan de prod, des zones de stockage et des ateliers sélectionnés
   * @param zdp
   * @param zds
   * @param jourFab
   * @param planProd
   */
  getStocksResa(zdp: ZoneDeProductionDTO, zds: ZoneDeStockageDTO, jourFab: Date, planProd: PlanProductionDTO) {

    const fd = new FormData();
    fd.set('idZoneDeProduction', zdp.id + '');
    fd.set('idZoneDeStockage', zds.id + '');
    fd.set('idPlanProduction', planProd.id + '');
    fd.set('jourFabrication', this.utils.getYYYYMMDD(moment(jourFab)));

    return this.http.post(URL_POST_RETRIEVE_STOCKS_RESERVATIONS, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }

  announceStocksResas(response: ResponseWrapper<StockReservationDTO>) {
    this.subjectStocksReservations.next(response);
  }


  updateValidationStocksReservations(idPlanDeProduction: number, idZoneDeProduction: number, idZoneDeStockage: number, jourFabrication: Date, denreesStock: MValidationDenree[]) {

    return this.http.post(URL_POST_UPDATE_VALIDATION_STOCKS_RESERVATIONS, denreesStock, {
      params: new HttpParams().set('idPlanProduction', idPlanDeProduction + '')
        .set('idZoneDeProduction', idZoneDeProduction + '')
        .set('idZoneDeStockage', idZoneDeStockage + '')
        .set('jourFabrication', this.utils.getYYYYMMDD(moment(jourFabrication)))
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }
}
