import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {RegimeAlimentaireDTO} from '../../dtos/regime-alimentaire-dto';
import {UtilsService} from '../../utils/utils.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {find as _find, startCase as _startCase} from 'lodash';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {shareReplay} from 'rxjs/operators';
import {ContrainteModel} from '../../models/contrainte-model';
import {TextureService} from './texture.service';
import {ObjectDTO} from '../../dtos/object-dto';


@Injectable({
  providedIn: 'root'
})
export class RegimeAlimentaireService extends GenericHandler<RegimeAlimentaireDTO> {

  regimesEnvironnement: RegimeAlimentaireDTO[] = [];

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              private textureSvc: TextureService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  createEmptyDTO(): RegimeAlimentaireDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
    this.gds.getAll(this.getEntityName(), this.getSort(), true).pipe(
      shareReplay())
      .subscribe(response => {
        this.regimesEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.regimesEnvironnement = response.resultList;
        }

        this.announceObjects(this.regimesEnvironnement);
      });
  }

  getCreateNewObjectLabel(): string {
    return "CRÉER UN RÉGIME ALIMENTAIRE";
  }

  getEditObjectLabel(data: RegimeAlimentaireDTO): string {
    return `MODIFIER LE RÉGIME '${data.libelle.toUpperCase()}'`;
  }

  getEntityName(): string {
    return "RegimeAlimentaire";
  }

  getFields(dto: RegimeAlimentaireDTO): FormFieldBaseSupplier<any>[] {

    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new RegimeAlimentaireDTO();
      dto.actif = true;

      // Si la liste des sites utilisables contient un élément et un seul
      // on affecte ce site au RegimeAlimentaireDTO en création.
      if (this.optionsSites.length == 1) {
        dto.site = this.optionsSites[0];
      }
    }

    let formFieldSite: FormFieldBaseSupplier<any> = this.createFormFieldBaseSupplierForSite(dto, 1);
    let formFields: FormFieldBaseSupplier<any>[] = [

      // new FormFieldDropdownSupplier({
      //   key: 'site',
      //   label: 'Site',
      //   readonly: !this.canModifySite(dto),
      //   type: 'externalRef',
      //   value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
      //   order: 1,
      //   options: this.optionsSites
      // }),
      formFieldSite,


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType: 'string',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType: 'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType: 'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 5
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);

  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return true;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getTitle(): string {
    return "GESTION DES RÉGIMES";
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  /**
   * Récupérer toutes les combinatoires des contraintes alimentaires (regime+texture)
   */
  getRegimesContraintes(): ContrainteModel[] {
    const contraintes = [];

    for (const regime of this.regimesEnvironnement) {
      for (const texture of this.textureSvc.texturesEnvironnement) {
        const contrainte = new ContrainteModel(texture, regime);
        contraintes.push(contrainte);
      }
    }

    return contraintes;
  }

  /**
   * Initialiser les contraintes alimenataires par defaut
   * @param createEmptyFromContrainte
   * @return {T[] | any[]}
   */
  initContraintesAlimentaires<T extends ObjectDTO>(createEmptyFromContrainte: (cm: ContrainteModel) => T): T[] {

    const regimesContraintes = this.getRegimesContraintes();
    if (!this.utils.isCollectionNullOrEmpty(regimesContraintes)) {

      const rc = regimesContraintes[0];

      return [createEmptyFromContrainte(rc)];
    }

    return [];

  }


  /**
   * Préselectionner les contraintes alimentaires d'un objet (produit, contratMenuConvive)
   * @param contraintes
   * @param contraintesAlims
   */
  preselectContraintesAlim <T extends ObjectDTO> (contraintes: ContrainteModel[], contraintesAlims: T[]): ContrainteModel[] {

    const result = [];

    for (const contrainte of contraintes) {
      const elt = _find(contraintesAlims, {
        'textureId': contrainte.texture.id,
        'regimeId': contrainte.regime.id
      });
      if (!this.utils.isNullOrEmpty(elt)) {
        result.push(contrainte);
      }
    }


    return result;
  }

  /**
   * Convertir une liste de contraintes alimentaires en EntityContrainteAlimDTO
   * @param contraintes
   * @param instance
   * @param propertyInstance
   * @param propertyEntity
   * @param createEmptyFromContrainte
   * @return {any[]}
   */
  convertToEntityContrainteAlim <T extends ObjectDTO, O extends ObjectDTO>(contraintes: ContrainteModel[],instance:T,propertyInstance:string,propertyEntity:string,createEmptyFromContrainte: (cm: ContrainteModel) => O):O[]{

    const entityContrainteAlimList = [];

    if(!this.utils.isCollectionNullOrEmpty(contraintes) && !this.utils.isNullOrEmpty(instance)){

      for (const contrainte of contraintes) {

        const pca = createEmptyFromContrainte(contrainte);
        pca[propertyEntity]=instance[propertyInstance];
        entityContrainteAlimList.push(pca);

      }

    }

    return entityContrainteAlimList;

  }

}
