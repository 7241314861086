import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {StockMouvementStatutDTO} from "../../../../core/dtos/stock-mouvement-statut-dto";
import {StockMouvementStatutService} from "../../../../core/services/gestion-stock/stock-mouvement-statut.service";

@Component({
  selector: 'yo-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.scss']
})
export class StockMouvementStatutComponent implements OnInit, OnDestroy {

  hasStocks = false;

  displayDialog = false;

  subOpenDialogStatut: Subscription;

  statut: StockMouvementStatutDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'un statut de mouvement de stock';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private smsSvc: StockMouvementStatutService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasGestionStocks();
    this.openDialogStatutSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogStatut);
  }

  openDialogStatutSubscription = (): void => {
    this.subOpenDialogStatut = this.smsSvc.openDialogStatut$
      .subscribe((s: StockMouvementStatutDTO) => {
        this.displayDialog = true;
        if (!s) {
          this.statut = new StockMouvementStatutDTO();
          this.statut.id = 0;
          this.dialogTitle = 'Création d\'un statut de mouvement de stock';
        } else {
          this.statut = Object.assign({}, s);
        }
        this.initForm();
      });
  };

  initHasGestionStocks = (): void => {
    this.auth2Svc.hasStocks$.subscribe(response => this.hasStocks = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  canModify = (): boolean => {
    return this.hasStocks; // return this.statut && this.hasStocks && this.statut.modifiable;
  };

  save = (): void => {
    if (this.form.valid) {
      this.statut.libelle = this.form.controls['libelle'].value;
      this.statut.code = this.form.controls['code'].value;
      this.statut.description = this.form.controls['description'].value;
      this.statut.entree = this.form.controls['entree'].value;
      this.statut.sortie = this.form.controls['sortie'].value;
      this.statut.modifiable = this.form.controls['modifiable'].value;
      this.smsSvc.saveSpecific(this.statut)
        .subscribe(response => {
          this.smsSvc.announceStatutSaved(response.one, this.statut.id !== undefined && this.statut.id !== null && this.statut.id !== 0);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde du statut réalisé avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.statut ? this.statut.libelle : '', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      code: new FormControl(this.statut ? this.statut.code : '', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]),
      description: new FormControl(this.statut ? this.statut.description : '', [Validators.required]),
      entree: new FormControl(this.statut ? this.statut.entree : false, []),
      sortie: new FormControl(this.statut ? this.statut.sortie : false, []),
      modifiable: new FormControl(this.statut ? this.statut.modifiable : false, [])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Le motif d'un mouvement de stock`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
