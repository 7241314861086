<p-dialog
  [(visible)]="displayDialog"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [modal]="true"
  position="top"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Ajouter des Fournisseurs
    </div>
  </ng-template>

  <p-steps [model]="itemsSteper" [activeIndex]="activeIndex"></p-steps>

  <div class="d-flex flex-grow-1" [style.height]="'calc(68vh)'">
    <div [class.hidden]="activeIndex === 1">
      <div class="d-flex justify-content-center align-items-center mg-t-10 mg-b-10">
        <dx-data-grid
          [dataSource]="fournisseurList"
          keyExpr="id"
          [height]="utils.getWindowAvailableHeight(400)"
          [width]="utils.getWindowAvailableWidth(300)"
          (onSelectionChanged)="onChangeSelection($event)"
          width="100%"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #grid>
          <dxi-column dataField="site" alignment="left" caption="Site" [width]="300"
                      [allowFiltering]="false" [allowSorting]="false"
                      cellTemplate="siteCellTemplate">
          </dxi-column>
          <dxi-column dataField="libelle" caption="Fournisseur" [width]="300"
                      [filterOperations]="['contains']"
                      [allowFiltering]="true">
          </dxi-column>
          <dxi-column dataField="code" caption="Code" [width]="300"
                      [filterOperations]="['contains']"
                      [allowFiltering]="true">
          </dxi-column>


          <div *dxTemplate="let cell of 'siteCellTemplate'">
            <yo-site [siteLibelle]="cell.data?.site?.libelle"
                     [siteId]="cell.data?.site?.id">
            </yo-site>
          </div>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-selection mode="multiple"
                         [selectAllMode]="true"
                         [showCheckBoxesMode]="'always'">
          </dxo-selection>
        </dx-data-grid>
      </div>
    </div>


    <!-- MAJORATION -->
    <div [class.hidden]="activeIndex === 0">

      <div class="d-flex justify-content-center align-items-center mg-t-10 mg-b-10 input-ratio">
        <label class="mg-r-5"> Ratio : </label>
        <div class="p-inputgroup" [style.width.px]="100">
          <input type="text" pInputText [(ngModel)]="ratioMassiveApply">
          <button type="button"
                  pButton pRipple
                  icon="fas fa-check"
                  (click)="massiveApplyMajoration()"
                  [pTooltip]="'Appliquer ce ratio à tous les fournisseurs'">
          </button>
        </div>
      </div>
      <dx-data-grid
        [dataSource]="gaFournisseurList"
        [height]="utils.getWindowAvailableHeight(400)"
        [width]="utils.getWindowAvailableWidth(500)"
        width="100%"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [showBorders]="true"
        #gridratio>
        <dxi-column dataField="fournisseur.libelle" caption="Fournisseur"
                    [filterOperations]="['contains']"
                    [allowFiltering]="false" [allowEditing]="false">
        </dxi-column>
        <dxi-column dataField="ratioPrixUF" caption="Ratio Prix UF" width="200"
                    [filterOperations]="['contains']"
                    [allowFiltering]="false" [allowEditing]="true">
        </dxi-column>
        <dxo-editing
          mode="cell"
          [allowUpdating]="true">
        </dxo-editing>

      </dx-data-grid>
    </div>

  </div>
  <div class="d-flex justify-content-end">
        <span class="mg-r-5">
          <button type="button" pButton
                  [label]="activeIndex === 0 ? 'Suivant' : 'Ajouter'"
                  (click)="activeIndex === 0 ? addFournisseurSelected() : sendFournisseurSelected()"
                  [disabled]="fournisseurListSelected.length === 0">
          </button>
        </span>

    <button type="button" pButton class="close-button p-button-secondary" icon="fas fa-times"
            (click)="closeDialog()">
    </button>
  </div>
</p-dialog>
