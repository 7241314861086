<div *ngIf="utils.isNullOrEmpty(listeBesoin)">
  <i class="fas fa-exclamation-triangle danger-color fa-2x"></i>Aucune liste de besoins trouvée pour le processus
  <strong>{{workflowInstance?.libelle}}</strong>.
</div>


<div *ngIf="!utils.isNullOrEmpty(listeBesoin)">

  <div class="d-flex justify-content-between">
    <div>
      <i class="fas fa-calendar-check mg-r-5"></i>{{listeBesoin?.workflowInstance?.libelle | uppercase}},
      BESOINS MENUS
    </div>
    <div>




      <span class="mg-r-5" [class.disable]="listeBesoin.archive">
         <!--PASSAGE À L'ETAPE SUIVANTE DU WORKFLOW-->
            <ng-container *ngIf="!utils.isNullOrEmpty(listeBesoin)">
              <yo-button-next-steps [workflowInstance]="listeBesoin.workflowInstance" [play]="true">
              </yo-button-next-steps>
            </ng-container>
          </span>
    </div>
  </div>

  <div>
    <div class="d-flex justify-content-between">
      <p-card class="width-250">
        <div class="d-flex flex-column">

          <div class="text-left">
            <p-selectButton [options]="optionsReglePrefere"
                            [(ngModel)]="workflowInstance.lbArticlesEcraserModifManuel"
                            (onChange)="changeRPEcraserModificationManuelle($event)"
                            [class.disable]="!besoinsSvc.canModify(listeBesoin)"
            >
            </p-selectButton>
          </div>

          <p-listbox [options]="reglesPreferes"
                     [(ngModel)]="selectedReglePrefere"
                     [style]="{'width':'220px'}"
                     class="mg-b-15"
                     [disabled]="!besoinsSvc.canModify(listeBesoin)"
                     pTooltip="Paramétrage des articles préférés"
                     showDelay="500"
                     tooltipPosition="right"
                     (onChange)="changeReglePrefere($event)">
          </p-listbox>

          <p-table [value]="getUdpInfoRegleValues()"
                   [scrollable]="true"
                   [scrollHeight]="utils.scrollHeightContent(500)"
                   styleClass="p-datatable-sm"
          >

            <ng-template pTemplate="header">
              <tr>
                <th>Unité de production</th>
                <th>Règle</th>
              </tr>
            </ng-template>

            <!--            si regle choisi est le parametrage par defaut, on affiche les infos de ce parametrage-->
            <ng-template pTemplate="body" let-rowData>
              <tr *ngIf="selectedReglePrefere === reglePrefereDefaut.value">
                <td><i class="fas fa-industry mg-r-5"></i>{{rowData.udpLibelle}}</td>
                <td>{{rowData.reglePrefereLibelle}}</td>
              </tr>

              <!--                    sinon on affiche les up affectées par la regle choisie-->
              <tr *ngIf="selectedReglePrefere != reglePrefereDefaut.value">
                <td><i class="fas fa-industry mg-r-5"></i>{{rowData.libelle}}</td>
                <td>{{getSelectedRegleLabel(this.selectedReglePrefere)}}</td>
              </tr>
            </ng-template>

          </p-table>
        </div>
        <div>
          <span
            class="note">Seul le scénario "Paramétrage par défaut" prend en compte les modifications manuelles.</span>
          <br/>
          <span class="note">({{typeEffectif}})</span>
        </div>
      </p-card>

      <div class="flex-grow-1 mg-l-5">
        <router-outlet></router-outlet>
      </div>
    </div>


  </div>
</div>












