import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FournisseurDTO} from '../../dtos/fournisseur-d-t-o';
import {DevextremeService, FilterItem} from '../technique/devextreme.service';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {CataloguesAchatsService, FK_CatalogueAchat} from '../entities/catalogues-achats.service';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {HttpService} from "../technique/http.service";


@Injectable({
  providedIn: 'root'
})
export class GestionFournisseurCatalogueAchatService {

  private subjectDisplayCatalogueAchatFournisseur = new Subject<any>();
  displayCatalogueAchatFournisseur$ = this.subjectDisplayCatalogueAchatFournisseur.asObservable();


  constructor(private catalogueAchatSvc: CataloguesAchatsService,
              private dxSvc: DevextremeService,
              private httpSvc: HttpService,
              private auth2Svc: Auth2Service,
              private utils: UtilsService) {
  }


  announceDisplayCatalogueAchatFournisseur = (fournisseur: FournisseurDTO) => {
    this.subjectDisplayCatalogueAchatFournisseur.next(fournisseur);
  };

  filterByUniteDeProduction = (idUdp: number, page: number, pageSize: number, filters: FilterItem[], sorts: Sort[], isVisibleCaInherited: boolean, selectedFamillesPlatsIds: number[] = []) => {
    const grs = new GenericRequestSupplier(this.catalogueAchatSvc.getEntityName());
    const search = new Search();
    search.predicats = [];


    const rootPath = this.catalogueAchatSvc.getEntityName().toLowerCase();

    const idsSites = this.auth2Svc.utilisateur.sites.map(site => site.id);

    const predicatSites = new Predicat();

    predicatSites.path = 'catalogueachat.site.id';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type = PREDICAT_TYPE.Integer;
    predicatSites.ids = idsSites;
    search.predicats.push(predicatSites);

    if (!isVisibleCaInherited) {
      const predicat2 = new Predicat();
      predicat2.path = `${rootPath}.parent`;
      predicat2.operator = PREDICAT_OPERATOR.IsNull;
      predicat2.type = PREDICAT_TYPE.Integer;
      search.predicats.push(predicat2);
    }

    if (selectedFamillesPlatsIds && selectedFamillesPlatsIds.length) {
      const predicat = new Predicat(); // Déguelasse
      predicat.path = `catalogueachat.produitArticle.produitDeclinaison.produit.familleProduit.id`;
      predicat.operator = PREDICAT_OPERATOR.In;
      predicat.type = PREDICAT_TYPE.Integer;
      predicat.values = selectedFamillesPlatsIds.map(id => `${id}`);
      search.predicats.push(predicat);
    }


    if (!this.utils.isCollectionNullOrEmpty(filters)) {


      for (const filter of filters) {

        let path = '';
        switch (filter.column) {
          case FK_CatalogueAchat.produitArticleLibelle:
            path = `${rootPath}.produitArticle.libelle`;
            break;
          case FK_CatalogueAchat.referenceArticle:
            path = `${rootPath}.referenceArticle`;
            break;
          case FK_CatalogueAchat.prix:
            path = `${rootPath}.prix`;
            break;
          case FK_CatalogueAchat.codeApi:
            path = `${rootPath}.codeApi`;
            break;
          case FK_CatalogueAchat.fournisseurLibelle:
            path = `${rootPath}.fournisseur.libelle`;
            break;
          case FK_CatalogueAchat.denreeLibelle:
            path = `${rootPath}.produitArticle.produitDeclinaison.libelle`;
            break;
          case FK_CatalogueAchat.familleProduit:
            path = `${rootPath}.produitArticle.produitDeclinaison.produit.familleProduit.libelle`;
            break;
        }


        const predicat = new Predicat();
        predicat.path = path;
        let operator = this.dxSvc.dxToGrsOperator(filter.operator);
        predicat.operator = operator;
        predicat.type = operator === PREDICAT_OPERATOR.LessThanEquals || operator === PREDICAT_OPERATOR.GreaterThanEquals ? PREDICAT_TYPE.Integer : PREDICAT_TYPE.String;
        predicat.value = filter.value + '';

        search.predicats.push(predicat);
      }
    }

    grs.search = search;

    grs.page = page + 1;
    grs.size = pageSize === undefined ? 20 : pageSize;

    if (!this.utils.isCollectionNullOrEmpty(sorts)) {
      for (const sort of sorts) {
        switch (sort.path) {
          case FK_CatalogueAchat.produitArticleLibelle:
            sort.path = `${rootPath}.produitArticle.libelle`;
            break;
          case FK_CatalogueAchat.referenceArticle:
            sort.path = `${rootPath}.referenceArticle`;
            break;
          case FK_CatalogueAchat.prix:
            sort.path = `${rootPath}.prix`;
            break;
          case FK_CatalogueAchat.codeApi:
            sort.path = `${rootPath}.codeApi`;
            break;
          case FK_CatalogueAchat.fournisseurLibelle:
            sort.path = `${rootPath}.fournisseur.libelle`;
            break;
          case FK_CatalogueAchat.denreeLibelle:
            sort.path = `${rootPath}.produitArticle.produitDeclinaison.libelle`;
            break;
          case FK_CatalogueAchat.familleProduit:
            sort.path = `${rootPath}.produitArticle.produitDeclinaison.produit.familleProduit.libelle`;
            break;
        }
      }
    }

    grs.search.sorts = sorts;

    return grs;
  };


  filterByFournisseur = (idFournisseur: number, page: number, pageSize: number, filters: FilterItem[], sorts: Sort[], isVisibleCaInherited: boolean) => {

    const grs = new GenericRequestSupplier(this.catalogueAchatSvc.getEntityName());
    const search = new Search();
    search.predicats = [];


    const rootPath = this.catalogueAchatSvc.getEntityName().toLowerCase();

    const predicat1 = new Predicat();
    predicat1.path = `${rootPath}.fournisseur.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idFournisseur + '';
    search.predicats.push(predicat1);

    if (!isVisibleCaInherited) {
      const predicat2 = new Predicat();
      predicat2.path = `${rootPath}.parent.is.null`;
      predicat2.operator = PREDICAT_OPERATOR.IsNull;
      predicat2.type = PREDICAT_TYPE.Integer;
      search.predicats.push(predicat2);
    }


    if (!this.utils.isCollectionNullOrEmpty(filters)) {


      for (const filter of filters) {

        let path = '';
        switch (filter.column) {
          case FK_CatalogueAchat.produitArticleLibelle:
            path = `${rootPath}.produitArticle.libelle`;
            break;
          case FK_CatalogueAchat.referenceArticle:
            path = `${rootPath}.referenceArticle`;
            break;
          case FK_CatalogueAchat.uniteDeComandeLibelle:
            path = `${rootPath}.uniteDeCommande.libelle`;
            break;
        }


        const predicat = new Predicat();
        predicat.path = path;
        let operator = this.dxSvc.dxToGrsOperator(filter.operator);
        predicat.operator = operator;
        predicat.type = operator === PREDICAT_OPERATOR.LessThanEquals || operator === PREDICAT_OPERATOR.GreaterThanEquals ? PREDICAT_TYPE.Integer : PREDICAT_TYPE.String;
        predicat.value = filter.value + '';

        search.predicats.push(predicat);
      }
    }

    grs.search = search;

    grs.page = page + 1;
    grs.size = pageSize;

    if (!this.utils.isCollectionNullOrEmpty(sorts)) {
      for (const sort of sorts) {
        switch (sort.path) {
          case FK_CatalogueAchat.produitArticleLibelle:
            sort.path = `${rootPath}.produitArticle.libelle`;
            break;
          case FK_CatalogueAchat.referenceArticle:
            sort.path = `${rootPath}.referenceArticle`;
            break;
          case FK_CatalogueAchat.uniteDeComandeLibelle:
            sort.path = `${rootPath}.uniteDeCommande.libelle`;
            break;
        }
      }
    }

    grs.search.sorts = sorts;

    return grs;

  };
}
