import {Injectable} from "@angular/core";
import {HttpService} from "../../technique/http.service";
import {Subject} from "rxjs/index";
import ModeleConditionnementPlatDTO from "../../../dtos/conditionnement/modele-conditionnement-plat-dto";

export const URL_FIND_ALL_BY_MCP_ID = `dolrest/conditionnements/udp-mcp-produits/listByIdMcp/`;

@Injectable({
  providedIn: 'root'
})
export class UdpMcpProduitsService {

  private subjectBindWithPlatsAsked = new Subject<ModeleConditionnementPlatDTO>();
  bindWithPlatsAsked$ = this.subjectBindWithPlatsAsked.asObservable();

  constructor(private httpSvc: HttpService) {}

  findAllByMcpId = (mcpId: number) => this.httpSvc.get(`${URL_FIND_ALL_BY_MCP_ID}${mcpId}`);

  openDialogBindWithPlats = (model: ModeleConditionnementPlatDTO) => {
    this.subjectBindWithPlatsAsked.next(model);
  };

}
