<div class="d-flex">

  <div class="mg-r-5">

    <p-card>
      <h3 class="first">Unités de production</h3>
      <div>
        <p-dropdown
          [options]="uniteDeProductionList"
          [style]="{'max-width':'200px','width':'200px'}"
          optionLabel="libelle"
          filter="true"
          (onChange)="onChangeSelectedUniteDeProduction($event)"
          [(ngModel)]="uniteDeProductionSelected">
        </p-dropdown>
      </div>

      <h3 class="first">Déclinaisons</h3>
      <p-listbox [options]="produitDeclinaisonMarcheList" [(ngModel)]="produitDeclinaisonMarcheSelected"
                 optionLabel="produitDeclinaisonLibelle"
                 [filter]="true"
                 filter="true"
                 [style]="{'width':'300px'}" [listStyle]="{'max-height':'450px', 'height':'450px'}">
        <ng-template let-item pTemplate="item">
          <div class="d-flex">
            <div class="mr-auto">
              {{item.produitDeclinaisonLibelle}}
              <br/>
              <span class="font-10" pTooltip="Code de la denrée">
            {{item.produitDeclinaisonCode}}
            </span>
            </div>
            <div>
              <span class="font-14 badge badge-primary mg-r-5" [escape]="false"
                    [pTooltip]="getTooltipMarcheList(item.marcheList,false)">{{item.nbMarches}}</span>
              <span class="font-14 badge badge-success" [escape]="false"
                    [pTooltip]="getTooltipMarcheList(item.marcheEnCoursList,true)">{{item.nbMarchesEnCours}}</span>
            </div>
          </div>
        </ng-template>
      </p-listbox>
    </p-card>
  </div>

  <div class="d-flex flex-grow-1" style="max-height:80vh;overflow: auto;">
    <p-card>
      <ng-container
        [ngSwitch]="utils.isNullOrEmpty(produitDeclinaisonMarcheSelected) || utils.isCollectionNullOrEmpty(produitDeclinaisonMarcheSelected.marcheList)">

        <!--      ERREUR AUCUN MARCHE-->
        <ng-container *ngSwitchCase="true">
          <i class="fas fa-exclamation-triangle mg-r-5"></i>Aucun marché associé
          à {{produitDeclinaisonMarcheSelected?.produitDeclinaisonLibelle}} pour l'unité de
          production {{uniteDeProductionSelected?.libelle}} !
        </ng-container>

        <!--  AFFICHAGE    DES MARCHES-->
        <ng-container *ngSwitchCase="false">
          <yo-marche-produit [produitDeclinaisonMarche]="produitDeclinaisonMarcheSelected"
                             [uniteDeProduction]="uniteDeProductionSelected"></yo-marche-produit>
        </ng-container>
      </ng-container>
    </p-card>
  </div>
</div>
