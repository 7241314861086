<div class="d-flex flex-row">
  <div class="max-width-300 d-flex  flex-column mg-r-15">
    <div>
      <h3 class="first">Période</h3>
      <p-dropdown [options]="periodes"
                  [style]="{'width':'300px','height':'36px'}"
                  [(ngModel)]="selectedPeriode"
                  optionLabel="modeleNutritionnelLibelle"
                  placeholder="sélection..."
                  showClear="true"
                  filter="true"
                  (onChange)="loadTauxDePrisePrev(selectedPeriode,selectedPointDeLivraison)"
                  [panelStyle]="{minWidth:'10em'}"
                  dataKey="id">

        <ng-template let-item pTemplate="selectedItem">
          <div style="display:inline;position: relative;height: 25px;">
                  <span style="font-size:14px;margin-top:4px" [pTooltip]="selectedPeriode?.modeleNutritionnelLibelle"
                        showDelay="500"
                        [innerHTML]="sanitizer.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(item))"></span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div style="display:inline;position: relative;height: 25px;">
                  <span style="font-size:14px;margin-top:4px" [pTooltip]="selectedPeriode?.modeleNutritionnelLibelle"
                        showDelay="500"
                        [innerHTML]="sanitizer.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(item))"></span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="mg-t-15">
      <h3 class="first">{{utils.getTotalRecordsLabel('point de livraison', pointsDeLivraison?.length)}} </h3>
      <p-listbox [options]="pointsDeLivraison" [(ngModel)]="selectedPointDeLivraison"
                 [style]="{'width':'260px'}"
                 (onChange)="loadTauxDePrisePrev(selectedPeriode,selectedPointDeLivraison)"
                 [listStyle]="{'max-height':'55vh','min-height':'55vh'}"
                 filter="filter" optionLabel="libelle">
        <ng-template let-item pTemplate="item">
          <i class="fas fa-map-pin mg-r-5 pn-border-color"></i>{{item.libelle}}
        </ng-template>
        <ng-template pTemplate="header">
                  <span class="mg-r-5">
                  <button pButton pTooltip="Ajouter un point de livraison"
                          showDelay="500"
                          class="mg-r-5"
                          icon="fas fa-plus"
                          class="p-button-secondary"
                          (click)="displayDialogAddPlc()">
                  </button>
                  </span>

          <span class="mg-r-5">
                  <button pButton pTooltip="Supprimer un point de livraison" showDelay="500"
                          class="p-button-secondary mg-r-5"
                          icon="fas fa-minus"
                          (click)="displayDialogRemovePlc()"
                          [disabled]="utils.isCollectionNullOrEmpty(pointsDeLivraison)">
                  </button>
                  </span>


        </ng-template>
      </p-listbox>
    </div>
  </div>

  <div class="table-width-size">
    <!--          afficher le parametrage du point de livraison-->
    <router-outlet></router-outlet>
  </div>

</div>

<!--DIALOG DE SUPPRESSION DES POINTS DE LIVRAISON-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'600px'}"
  positionTop="75"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayPlcRemoveDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-map-pin mg-r-5"></i> Supprimer des points de livraison
    </div>
  </ng-template>


  <!--CONTENT-->
  <p-listbox [(ngModel)]="selectedPointsDeLivraisonToRemove"
             multiple="multiple"
             checkbox="checkbox"
             filter="filter"
             [style]="{'width':'556px'}"
             [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
             [options]="pointsDeLivraisonToRemove"
             optionLabel="libelle">
    <ng-template let-item let-i="index" pTemplate="item">
      <i class="fas fa-map-pin mg-r-5"></i>   {{item.libelle}} <span
      class="genlabel font-12">({{item.adresse.ville}})</span>
    </ng-template>
  </p-listbox>

  <span class="genlabel font-10" *ngIf="pointsDeLivraisonToRemove.length>1">{{pointsDeLivraisonToRemove.length}} points de livraison à supprimer</span>
  <span class="genlabel font-12" *ngIf="pointsDeLivraisonToRemove.length<1"><i
    class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun point de livraison à supprimer</span>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" pTooltip="Supprimer les points de livraison sélectionnés" showDelay="500"
                class="mg-r-5"
                (click)="detachSelectedPointsDeLivraison($event)"></button>
        <button pButton icon="fas fa-times" (click)="displayPlcRemoveDialog=false"
                class="p-button-secondary"></button>
      </div>
    </div>

  </ng-template>

</p-dialog>


<!--DIALOG DE SELECTION DES POINTS DE LIVRAISON-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'600px'}"
  positionTop="75"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayPlcAddDialog">


  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-map-pin mg-r-5"></i> Ajouter des points de livraison
    </div>
  </ng-template>


  <!--CONTENT-->
  <p-listbox [(ngModel)]="selectedPointsDeLivraisonToAdd"
             multiple="multiple"
             checkbox="checkbox"
             filter="filter"
             [style]="{'width':'556px'}"
             [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
             [options]="pointsDeLivraisonToAdd"
             optionLabel="libelle">
    <ng-template let-item let-i="index" pTemplate="item">
      <i class="fas fa-map-pin mg-r-5"></i>   {{item.libelle}} <span
      class="genlabel font-12">({{item.adresse.ville}})</span>
    </ng-template>
  </p-listbox>

  <span class="genlabel font-10" *ngIf="pointsDeLivraisonToAdd.length>1">{{pointsDeLivraisonToAdd.length}} points de livraison disponibles</span>
  <span class="genlabel font-12" *ngIf="pointsDeLivraisonToAdd.length<1"><i
    class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun point de livraison disponible</span>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" pTooltip="Ajouter les points de livraison sélectionnés" showDelay="500"
                class="mg-r-5"
                (click)="attachSelectedPointsDeLivraison($event)"></button>
        <button pButton icon="fas fa-times" (click)="displayPlcAddDialog=false"
                class="p-button-secondary"></button>
      </div>
    </div>

  </ng-template>

</p-dialog>


