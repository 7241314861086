import {NgModule} from "@angular/core";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./shared/login/login.component";


export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'monbureau',
    pathMatch: 'full',
  },

  // {
  //   path : 'monbureau',
  //   loadChildren : 'app/monbureau/monbureau.module#MonbureauModule'
  // },
  // {
  //   path : 'gestion-commandes',
  //   loadChildren : 'app/gestion-commandes/gestion-commandes.module#GestionListesBesoinsModule',
  // },
  // {
  //   path :FS_ROUTES.GESTION_LISTES_BESOINS_FOU,
  //   loadChildren : 'app/gestion-commandes/gestion-commandes.module#GestionListesBesoinsModule',
  // },
  {
    path: 'logout',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class AppRoutingModule {
}

