<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button pButton
            type="button"
            icon="fas fa-plus"
            label="CRÉER"
            [pTooltip]="'Créer un utilisateur'"
            (click)="openNewUtilisateur()">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="utilisateursForGrille"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="id" caption="Actions" cellTemplate="templateActions" [width]="100" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="nom" caption="Nom"></dxi-column>
  <dxi-column dataField="prenom" caption="Prénom"></dxi-column>
  <dxi-column dataField="login" caption="Login"></dxi-column>
  <dxi-column dataField="email" caption="Email"></dxi-column>
  <dxi-column dataField="profil.libelle" caption="Profil"></dxi-column>
  <dxi-column dataField="actif" caption="Actif"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'">
    <yo-cell-button (yoNavigation)="openUtilisateur(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-edit'"
                    [yoTooltip]="'Modifier l\'utilisateur '+ cell.row.data.login"
    >
    </yo-cell-button>
    <yo-cell-button (yoNavigation)="confirmDelete(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-trash'"
                    [yoTooltip]="'Supprimer l\'utilisateur '+ cell.row.data.login"
    >
    </yo-cell-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} utilisateur(s)">
  </dxo-pager>

</dx-data-grid>
