import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FamilleGemrcnDTO} from '../../dtos/famille-gemrcn-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {CouleursService} from './couleurs.service';
import {SelectItem} from 'primeng/api';
import {CouleurDTO} from '../../dtos/couleur-dto';


@Injectable()
export class FamillesGemrcnService extends GenericHandler<FamilleGemrcnDTO> {




  getTotalRecordsLabel(): string {
    return "Famille GEMRCN";
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['ordre,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES FAMILLES GEMRCN';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE FAMILLE GEMRCN';
  }


  getEntityName(): string {
    return 'FamilleNutritionnelle';
  }


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private couleursSvc: CouleursService) {
    super(utils, auth2Svc, router, http, title);
  }

  getFields(dto: FamilleGemrcnDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une FamilleGemrcn est active.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new FamilleGemrcnDTO();
      dto.actif = true;

      // Si la liste des sites ne contient qu'un élément, on affecte ce site au FamilleGemrcnDTO en création.
      if (this.optionsSites.length == 1) {
        dto.site = this.optionsSites[0];
      }
    }

    const readonly = !this.canModify(dto);


    let formFieldSite: FormFieldBaseSupplier<any> = this.createFormFieldBaseSupplierForSite(dto, 1);

    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType:'string',
        readonly: readonly,
        minLength: 2,
        maxLength: 200,
        value: dto.libelle,
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: readonly,
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4,
      }),

      new FormFieldTextboxSupplier({
        key: 'ordre',
        label: 'Ordre',
        excelType:'string',
        readonly: readonly,
        value: dto.ordre,
        type: 'number',
        required: true,
        order: 5
      }),

      new FormFieldDropdownSupplier({
        key: 'couleur',
        label: 'Couleur',
        readonly: readonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.couleursSvc.couleursEnvironnement,dto.couleur),
        order: 2,
        options: this.couleursSvc.couleursEnvironnement,
        required: true,
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getSelectedItemCouleur(couleur:CouleurDTO,couleurs:SelectItem[]):CouleurDTO{

    if(!this.utils.isCollectionNullOrEmpty(couleurs) && !this.utils.isNullOrEmpty(couleur)){

      for(let c of couleurs){
        if(c.value.id === couleur.id){
          return c.value;
        }
      }

    }

    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): FamilleGemrcnDTO {
    return new FamilleGemrcnDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA FAMILLE GEMRCN '${data.libelle.toUpperCase()}'`;
  }
}
