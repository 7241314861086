import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../dtos/unite-de-production__secteur-fournisseur-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {Title} from '@angular/platform-browser';
import {SecteurFournisseurDTO} from '../../dtos/secteurfournisseur-dto';
import {UniteDeProductionDTO} from '../../dtos/unite-de-production-dto';
import {UniteDeProduction__LivraisonDTO} from '../../dtos/unite-de-production__livraison-dto';
import {ObjectDTO} from '../../dtos/object-dto';

import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {startCase as _startCase} from 'lodash';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {HttpService} from "../technique/http.service";

export const URL_POST_SAVE_FILIALES_PREFEREES = `dolrest/gestion-unites-de-production/save/unite-de-production/articles/filiales-preferees`;
export const URL_GET_BY_UDP_SECTEUR_FOURNISSEUR = `dolrest/gestion-unites-de-production/findudpsecteurfournisseurbyIdudpandidfiliale`;
export const URL_POST_SAVE_UDP_SECTEUR_FOURNISSEUR = `dolrest/gestion-unites-de-production/saveudpsecteurfournisseur`;
export const URL_GET_UDP_LIVRAISON_BY_ID_UDP = `dolrest/gestion-unites-de-production/get-udp-livraison-by-id-udp`;

@Injectable()
export class UniteDeProduction__secteurFournisseurService extends GenericHandler<UniteDeProduction__SecteurFournisseurDTO> {


  private subjectUdpSecteurFournisseur = new Subject<UniteDeProduction__SecteurFournisseurDTO>();
  public udpSecteurFournisseur$: Observable<UniteDeProduction__SecteurFournisseurDTO> = this.subjectUdpSecteurFournisseur.asObservable();

  private subjectUdpLivraison = new Subject<UniteDeProduction__LivraisonDTO[]>();
  public udpLivraison$: Observable<UniteDeProduction__LivraisonDTO[]> = this.subjectUdpLivraison.asObservable();

  private subjectIsDisabledButtonComputeMealPrice = new Subject<ProgressionComputeUdpInterface>();
  public isDisabledButtonComputeMealPrice$: Observable<ProgressionComputeUdpInterface> = this.subjectIsDisabledButtonComputeMealPrice.asObservable();

  private subjectIsDisabledButtonComputePrefere= new Subject<ProgressionComputeUdpInterface>();
  public isDisabledButtonComputePrefere$: Observable<ProgressionComputeUdpInterface> = this.subjectIsDisabledButtonComputePrefere.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private httpSvc : HttpService) {
    super(utils, auth2Svc, router, http, title);
  }

  announceUdpSecteurFournisseur = (udpSf: UniteDeProduction__SecteurFournisseurDTO) => {
    this.subjectUdpSecteurFournisseur.next(udpSf)
  };

  announceUdpLivraison = (udpSf: UniteDeProduction__LivraisonDTO[]) => {
    this.subjectUdpLivraison.next(udpSf)
  };

  // à sa place ??
  announceIsDisabledButtonComputeMealPrice = (isDisabled: boolean, progression?: number) => {
    this.subjectIsDisabledButtonComputeMealPrice.next({ isDisabled, progression } as ProgressionComputeUdpInterface);
  };

  // à sa place ??
  announceIsDisabledButtonComputePrefere = (isDisabled: boolean, progression?: number) => {
    this.subjectIsDisabledButtonComputePrefere.next({ isDisabled, progression } as ProgressionComputeUdpInterface)
  };

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getFields = (dto: UniteDeProduction__SecteurFournisseurDTO): FormFieldBaseSupplier<any>[] => undefined;

  getSort = (): string[] => ['id,asc'];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => undefined;

  openDeleteDialog = (dto: UniteDeProduction__SecteurFournisseurDTO) => {
  };

  getEntityName = (): string => 'UniteDeProduction__SecteurFournisseur';

  getTitle = (): string => 'GESTION DES SECTEURS PAR FILIALE ET UNITÉ DE PRODUCTION';

  getCreateNewObjectLabel = (): string => undefined;

  /**
   * Verifier si le secteur fournisseur est actif
   */
  secteurFournisseurActif = (secteurFournisseur: SecteurFournisseurDTO): boolean => {

    let response = secteurFournisseur.fournisseurActif && secteurFournisseur.secteur.actif && secteurFournisseur.actif;

    return response;
  };

  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): UniteDeProduction__SecteurFournisseurDTO => new UniteDeProduction__SecteurFournisseurDTO();

  getEditObjectLabel = (data: ObjectDTO): string => "";


  filterByUniteDeProduction = (uniteDeProduction: UniteDeProductionDTO) => {

    const grs = new GenericRequestSupplier(this.getEntityName());

    const enLc = this.getEntityName().toLowerCase();

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = `${enLc}.uniteDeProduction.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = uniteDeProduction.id + '';

    search.predicats = [predicat1];

    const sort1 = new Sort();
    sort1.dir = PREDICAT_DIR.Ascendant;
    sort1.path = `${enLc}.secteurFournisseur.fournisseur.libelle`;

    const sort2 = new Sort();
    sort2.dir = PREDICAT_DIR.Ascendant;
    sort2.path = `${enLc}.secteurFournisseur.secteur.libelle`;

    search.sorts = [sort1, sort2];

    grs.search = search;

    return grs;

  };


  saveFilialesPreferees = (selectedUdpSfList: UniteDeProduction__SecteurFournisseurDTO[], udp: UniteDeProductionDTO) => {
    return this.http.post(URL_POST_SAVE_FILIALES_PREFEREES, selectedUdpSfList, {
      params: new HttpParams().set('idUniteDeProduction', udp.id + '')
    }).pipe(
      catchError(err => this.utils.handleError(err)),
    );

    return of(null);
  };

  getUdpSecteurFournisseurByUdpAndSf = (idUdp: number, idFournisseur: number, idSecteur: number) => {
    return this.http.get(URL_GET_BY_UDP_SECTEUR_FOURNISSEUR, {
      params: new HttpParams()
        .set('idUniteDeProduction', idUdp + '')
        .set('idFournisseur', idFournisseur + '')
        .set('idSecteur', idSecteur + '')
    }).pipe(
      catchError(err => this.utils.handleError(err))
    );
  };

  saveUdpSecteurFournisseur = (selectedUdpSecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO) => {
    return this.http.post(URL_POST_SAVE_UDP_SECTEUR_FOURNISSEUR, selectedUdpSecteurFournisseur)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  getUdpLivraisonList = (idUdp :number) => {
    const params = new HttpParams()
      .set('idUniteDeProduction', idUdp + '');
    return this.httpSvc.get(URL_GET_UDP_LIVRAISON_BY_ID_UDP, params);
  };
}


export class FournisseurLivraisonsAffectationSupplier {

  joursSemaine: { value, viewValue }[];
  secteursFournisseurs: SecteurFournisseurDTO[];
  uniteDeProduction: UniteDeProductionDTO;
  livraisonsOfUDP: UniteDeProduction__LivraisonDTO[];
  newIsChecked: boolean;


  constructor(joursSemaine: { value; viewValue }[], secteursFournisseurs: SecteurFournisseurDTO[], uniteDeProduction: UniteDeProductionDTO, livraisonsOfUDP: UniteDeProduction__LivraisonDTO[], newIsChecked: boolean = true) {
    this.joursSemaine = joursSemaine;
    this.secteursFournisseurs = secteursFournisseurs;
    this.uniteDeProduction = uniteDeProduction;
    this.livraisonsOfUDP = livraisonsOfUDP;
    this.newIsChecked = newIsChecked;
  }

}

export interface ProgressionComputeUdpInterface {
  isDisabled: boolean, progression?: number;
}
