import {Component, OnDestroy, OnInit} from "@angular/core";
import {combineLatest, Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import ModeleConditionnementPlcDTO
  from "../../../../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {UtilsService} from "../../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../../core/services/security/auth2.service";
import {ModelesPlcService} from "../../../../../../core/services/conditionnements/plc/modeles-plc.service";
import {PointDeLivraisonDTO} from "../../../../../../core/dtos/point-de-livraison-d-t-o";
import {ClientsBusinessService} from "../../../../../../core/services/gestion-clients/clients.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../../core/constants";

@Component({
  selector: 'yo-liaison-plc-mc-plc',
  templateUrl: './liaison-plc-mc-plc.component.html',
  styleUrls: ['./liaison-plc-mc-plc.component.scss']
})
export class LiaisonPlcMcPlcComponent implements OnInit, OnDestroy {

  hasIDistri: boolean = false;
  hasClient: boolean = false;
  displayDialog = false;
  dialogTitle = "Création d'une liaison entre le point de livraison et son modèle";

  subOpenDialog: Subscription;
  subAllModels: Subscription;
  subRights: Subscription;

  mcPlcSelected: ModeleConditionnementPlcDTO;
  models: ModeleConditionnementPlcDTO[] = [];
  plc: PointDeLivraisonDTO;

  form: FormGroup = new FormGroup({});

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private plcSvc: ClientsBusinessService,
              private modelsPlcSvc: ModelesPlcService) {}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subAllModels);
    this.utils.unsubscribe(this.subRights);
  }

  ngOnInit(): void {
    this.openDialogSubscription();
    this.initModels();
    this.initForm();
    this.initRightsSubscription();
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  initModels = (): void => {
    this.subAllModels = this.modelsPlcSvc.getAllModelesConditionnementsPlc()
      .subscribe(response => {
        this.models = response.resultList
      });
  };

  initForm = (): void => {
    this.form = new FormGroup({
      mcPlc: new FormControl(null, [Validators.required]),
    });
  };

  initRightsSubscription = (): void => {
    const all$ = combineLatest([this.auth2Svc.hasGestionClients$, this.auth2Svc.hasGestionIDistri$]);
    this.subRights = all$.subscribe(response => {
      this.hasClient =  Boolean(response[0]).valueOf();
      this.hasIDistri = Boolean(response[1]).valueOf();
    });
  };

  save = (): void => {
    if (this.form.valid) {
      const modelSelected = this.form.controls['mcPlc'].value;
      this.plcSvc.save(this.plc.id, modelSelected.id)
        .subscribe(response => {
          this.plcSvc.announceLiaisonPlc_McPlcSaved(true);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Création de l\'association réalisée avec succès.');
          this.closeDialog();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  canSave = (): boolean => this.hasClient && this.hasIDistri;

  openDialogSubscription = (): void => {
    this.subOpenDialog = this.plcSvc.announceOpenDialogLiaisonPlc_McPlc$
      .subscribe((plc: PointDeLivraisonDTO) => {
        this.displayDialog = true;
        this.plc = plc;
        this.initForm();
      });
  };

}
