import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {EquipeDTO} from '../../dtos/equipe-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {catchError} from 'rxjs/operators';


@Injectable()
export class EquipesService extends GenericHandler<EquipeDTO> {


  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES ÉQUIPES';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE ÉQUIPE';
  }


  constructor(utils: UtilsService, auth2Svc: Auth2Service,
              router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);
  }

  getEntityName(): string {
    return 'equipe';
  }

  getFields(dto: EquipeDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une Equipe est active.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new EquipeDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        excelType: 'string',
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        excelType: 'string',
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        excelType: 'boolean',
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      })

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): EquipeDTO {
    return new EquipeDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER L'ÉQUIPE '${data.libelle.toUpperCase()}'`;
  }

  getAll() {
    return this.gds.getAll(this.getEntityName(), ['code,asc'], true)
      .pipe(
        catchError(err => this.utils.handleError(err, true))
      );
  }

}
