
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <div>
          <i class="fas fa-book-reader mg-r-5 mg-l-15"></i> R&Eacute;SUM&Eacute;
        </div>
      </ng-template>
      <ng-template pTemplate="right">
        <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
        <button type="button"
                pButton
                icon="fas fa-highlighter"
                class="mr-1"
                (click)="printMenu.toggle($event)"
                pTooltip="Générer le bon de conditionnement"
                tooltipPosition="bottom"
                showDelay="500">
        </button>
        <button
          pButton
          pTooltip="Générer le bon de livraison"
          icon="fas fa-shipping-fast"
          class="mr-1"
          (click)="printLivraison(idPlanProduction)">
        </button>
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="helpSummaries()" [width]="800"></yo-help>
      </ng-template>
    </p-toolbar>

    <dx-data-grid
      id="tab-calcul-conditionnement-details"
      [dataSource]="headersResults"
      keyExpr="id"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      (onRowPrepared)="onRowPrepared($event)"
      (onRowClick)="loadChildren($event)"
      #gridHeaders>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxi-column dataField="ficheTechniqueLabel" caption="Plat" [width]="100"
                  [filterOperations]="['contains']"
                  [allowGrouping]="true"
                  [autoExpandGroup]="true"
                  [groupIndex]="1">
      </dxi-column>
      <dxi-column dataField="statutConditionnement" caption="Statut" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="atelierLabel" caption="Atelier" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="Variante" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="equipeLabel" caption="Equipe" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="pointLivraisonLabel" caption="Point livraison client" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="prestationLabel" caption="Prestation" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="regimeLabel" caption="Régime" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="repasLabel" caption="Repas" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="nbCommandes" caption="Nb. commandes" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="poidsNetUc" caption="Poids net UC" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="qteConditionnee" caption="Quantité conditionnée" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="queueUc" caption="Reste" [width]="100" cellTemplate="resteTemplate"
                  [filterOperations]="['contains']">
      </dxi-column>

      <dxi-column dataField="udpLibelle" caption="Unité de production" [width]="100"
                  [filterOperations]="['contains']"
                  [allowGrouping]="true"
                  [groupIndex]="0">
      </dxi-column>
      <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="dateConsommation" caption="Date consommation" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="dateLimiteConsommation" caption="Date l.consommation" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="dateLivraison" caption="Date livraison" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>
      <dxi-column dataField="datePreparationLivraison" caption="Date préparation livraison" [width]="100"
                  [filterOperations]="['contains']">
      </dxi-column>

      <div *dxTemplate="let cell of 'resteTemplate'">
        <span [className]="cell.value ? 'reste' : ''">{{cell.value}}</span>
      </div>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[1, 2, 20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} résumé(s)">
      </dxo-pager>

    </dx-data-grid>

    <div class="mg-t-5 mg-l-0" id="wrapper-lots">
      <div [style.width.px]="'90%'">
        <yo-preparation-calcul-conditionnement-details [idParentSelected]="idParentSelected"></yo-preparation-calcul-conditionnement-details>
      </div>
    </div>

