import {Injectable} from "@angular/core";

import {HttpClient, HttpParams} from "@angular/common/http";
import {HttpService} from "../technique/http.service";
import {ConfigurationPortailDTO} from "../../dtos/portail/configuration-portail-dto";
import {Subject} from "rxjs";
import {UtilsService} from "../../utils/utils.service";
import {Auth2Service} from "../security/auth2.service";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {GenericDatagridService} from "../generics/generic-datagrid.service";
import {GenericHandler} from "../generics/generic-handler";
import { ObjectDTO } from "app/core/dtos/object-dto";
import { DialogMsgSupplier } from "app/core/suppliers/dialog-msg-supplier";
import { FormFieldBaseSupplier } from "app/core/suppliers/form-fieldbase-supplier";
import {FormFieldTextboxSupplier} from "../../suppliers/form-field-textbox-supplier";
import {FormFieldDropdownSupplier} from "../../suppliers/form-field-dropdown-supplier";
import {PointDeLivraisonDTO} from "../../dtos/point-de-livraison-d-t-o";
import {JOURS_SEMAINES, JourSemaine, UNITE_BLOCAGE_CONFIGURATION_PORTAIL} from "../../constants";
import {ClientsService} from "../entities/clients.service";

export const URL_GET_CONFIG_PLC = 'dolrest/portail/configuration-portail/get-by-id-plc';
export const URL_GET_ALL_CONFIG_PLC = 'dolrest/portail/configuration-portail-plc/get-all';
export const URL_POST_SAVE_CONFIG_PLC = 'dolrest/portail/configuration-portail-plc/save/';
export const URL_DELETE = 'dolrest/portail/configuration-portail-plc/delete';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationPortailService extends GenericHandler<ConfigurationPortailDTO> {

  protected optionPointDeLivraisonList: PointDeLivraisonDTO[] = [];
  protected optionJourSemaine: JourSemaine[] = [];
  protected optionUniteBlocage: any[] = [];

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService,
              private clientsSvc: ClientsService,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
    this.initOptionList();
  }

  private initOptionList(){

    this.optionJourSemaine = JOURS_SEMAINES;
    this.optionUniteBlocage = UNITE_BLOCAGE_CONFIGURATION_PORTAIL;

   //todo récupération des clients avec les points de livraison

  }

  private subjectOpenDialogForm = new Subject<ConfigurationPortailDTO>();
  subjectOpenDialogFormConfigPortailPlc$ = this.subjectOpenDialogForm.asObservable();

  private subjectRefreshConfigPlcList = new Subject();
  subjectRefreshConfigPlcList$ = this.subjectRefreshConfigPlcList.asObservable();

  announceOpenDialogAddPlc = (cfgPortail: ConfigurationPortailDTO) => {
    this.subjectOpenDialogForm.next(cfgPortail);
  };

  announceRefreshConfigPlcList = () => {
    this.subjectRefreshConfigPlcList.next();
  };

  //***********  HTTP METHOD  ************//

  getById = (idPlc: number) => {
    const params: HttpParams = new HttpParams().set('idPlc', idPlc + '');
    return this.httpSvc.get(URL_GET_CONFIG_PLC, params);
  };

  /**
   * recuperer la liste des configuration portail d'un point de livraison exsitant
   */
  getAll = () => this.httpSvc.get(URL_GET_ALL_CONFIG_PLC);

  deleteByIds = (ids: number[]) => {
    const params: HttpParams = new HttpParams().set("idsToDelete", ids.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

  /**
   * sauvegarde de la configuration portail d'un point de livraison
   * @param configurationPortailPlc
   */
  saveConfigurationPlc = (configurationPortailPlc: ConfigurationPortailDTO) => this.httpSvc.post(`${URL_POST_SAVE_CONFIG_PLC}`, configurationPortailPlc);

  //**** GenericHandler METHODE ****//

  getFields = (dto: ConfigurationPortailDTO): FormFieldBaseSupplier<any> [] => {
    const isReadonly = !this.canModify(dto);
    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
      dto.id = 0;
    }


    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldDropdownSupplier({
        key: 'Plc',
        label: 'Point de livraison',
        readonly: false,
        // type: 'linkedField',
        type: 'externalRef',
        value: this.optionPointDeLivraisonList[0],
        order: 1,
        options: this.optionPointDeLivraisonList,
        required: true,
      }),

      new FormFieldDropdownSupplier({
        key: 'jourSemaine',
        label: 'Jour limite de saisi',
        readonly: false,
        // type: 'linkedField',
        type: 'externalRef',
        value: this.optionJourSemaine[0],
        order: 2,
        options: this.optionJourSemaine,
        required: true,
      }),

      new FormFieldTextboxSupplier({
        key: 'nombreUniteBlocage',
        label: 'nombreUniteBlocage',
        excelType:'number',
        readonly: !this.canModify(dto),
        value: dto.nombreUniteBlocage,
        maxLength: 50,
        required: true,
        order: 3
      }),

      new FormFieldDropdownSupplier({
        key: 'uniteBlocage',
        label: 'Unité de blocage',
        readonly: false,
        // type: 'linkedField',
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionUniteBlocage, dto.uniteBlocage),
        order: 4,
        options: this.optionUniteBlocage,
        required: true,
      }),


      new FormFieldTextboxSupplier({
        key: 'minValeurSaisi',
        label: 'Effectif minimum de saisie',
        excelType:'number',
        readonly: !this.canModify(dto),
        value: dto.minValeurSaisie,
        maxLength: 50,
        required: true,
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'maxValeurSaisi',
        label: 'Effectif maximum de saisie',
        excelType:'number',
        readonly: !this.canModify(dto),
        value: dto.maxValeurSaisie,
        maxLength: 50,
        required: true,
        order: 6
      })
    ];

    return formFields.sort((a, b) => a.order - b.order);
  };
  getSort = (): string[] => {
    throw new Error("Method not implemented.");
  };
  getHelp = (): DialogMsgSupplier => {
    throw new Error("Method not implemented.");
  };
  getOas = (): boolean => {
    throw new Error("Method not implemented.");
  };
  getEntityName = (): string => {
    throw new Error("Method not implemented.");
  };
  getTotalRecordsLabel = (): string => {
    throw new Error("Method not implemented.");
  };
  getTitle = (): string => {
    throw new Error("Method not implemented.");
  };
  getCreateNewObjectLabel = (): string => {
    throw new Error("Method not implemented.");
  };
  getEditObjectLabel = (data: ObjectDTO): string => {
    throw new Error("Method not implemented.");
  };
  getAllFromEnvironnement = (): void => {
    throw new Error("Method not implemented.");
  };
  createEmptyDTO = (): ConfigurationPortailDTO => {
    throw new Error("Method not implemented.");
  };

}
