<div style="overflow: auto" [class.max-height]="!moreDetails">

  <table class="table "
         showDelay="500">
    <colgroup>
      <col style="width:250px;vertical-align: top;">
      <col style="width:40px;vertical-align: top;">
      <col>
    </colgroup>
    <thead>
    <tr>
      <th>Jours de consommation disponibles dans l'offre alimentaire</th>
      <th>
      </th>
      <th>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-end align-items-start">
          <yo-cell-button (yoNavigation)="openUdpFabrication()"
                          [yoTooltipShowDelay]="1500"
                          [yoMaxWidth]="true"
                          [yoWidthPercent]="30"
                          [yoTextAlign]="'center'"
                          [yoIconClass]="'fas fa-info'"
                          pTooltip="Aller sur les fabrications de l'unité de production"
                          tooltipPosition="top">
          </yo-cell-button>
          </div>
          <div>Jours de fabrication</div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container
      *ngIf="utils.isCollectionNullOrEmpty(cmcEquipeSvc.getFabrications(contratMenuConvive,cmcEquipe,udpEquipeList))">
      <tr>
        <td colspan="3"><i class="fas fa-exclamation-triangle danger-color space"></i>Aucun jour de fabrication
          paramétré sur cette équipe de production
        </td>
      </tr>
    </ng-container>
    <tr
      *ngFor=" let fab of cmcEquipeSvc.getFabrications(contratMenuConvive,cmcEquipe,udpEquipeList)">
      <td>
        {{ joursSemaine[fab.jourSemaine - 1].viewValue}}
      </td>
      <td>
        <i class="fa fa-2x fa-long-arrow-right yoni-color"></i>
      </td>
      <td>
        <label>{{cmcEquipeSvc.getJourFabName(fab.jourSemaine, fab.joursDecalage)}}</label>
      </td>
    </tr>
    </tbody>
  </table>
</div>
