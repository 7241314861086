import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import {
  JOURS_SEMAINES,
  JourSemaine,
  MENUS_SEARCH_MODES,
  MSG_KEY,
  MSG_SEVERITY,
  USER_PREFERENCE,
  USER_TYPE_PREFERENCE
} from '../../core/constants';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {EffectifsService} from '../../core/services/gestionmenus/effectifs.service';
import {MessageService, SelectItem} from 'primeng/api';
import {cloneDeep as _cloneDeep} from 'lodash'
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';

@Component({
  selector: 'yo-menus-settings',
  templateUrl: './menus-settings.component.html',
  styleUrls: ['./menus-settings.component.scss'],
})
export class MenusSettingsComponent implements OnInit, OnDestroy {

  private counter: number = 0;

  //subscriptions
  subSettings: Subscription;
  subPreferencesUtilisateur: Subscription;

  form: FormGroup;

  nbJoursPrefUtilisateur: number = 1;
  joursSemainesPrefUtilisateur: number[] = [];

  coutGlobalMode: number = 2;
  coutGlobalModeSelected: SelectItem;
  enableCoherenceArticle: boolean = false;

  displayFamillesGemrcn = false;
  displayAppellations = false;
  displayAllergenes = false;
  displayCoutsUnitaires = false;
  displayCoutsUnitairesPonderes = false;
  displayTauxDePrise = false;

  searchMode: number;
  searchModes = MENUS_SEARCH_MODES;

  displayFormSearch: boolean = true;

  pivot: number[] = [];

  joursSemaineToDisplay = _cloneDeep(JOURS_SEMAINES);
  joursSemainesSelected: JourSemaine[] = [];


  constructor(private fb: FormBuilder,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              private cd: ChangeDetectorRef,
              private utils: UtilsService,
              private route: ActivatedRoute,
              public effectifsSvc: EffectifsService,
              private msgSvc: MessageService,
              public auth2Svc: Auth2Service,
              private mp2Svc: MenusPlanning2Service,
              private prefUserSvc: PreferencesUtilisateurService) {
  }


  ngOnInit() {

    this.initForm();

    this.subPreferencesUtilisateur = this.prefUserSvc.preferencesOfUser$.subscribe(prefs => {
      this.initForm();
    });

  }

  private transformJourSemaine(jsList: number[]) {

    const arr: JourSemaine[] = [];

    for (const indexJourSemaine of jsList) {
      for (const jourSemaine of JOURS_SEMAINES) {
        if (jourSemaine.value === indexJourSemaine) {
          arr.push(jourSemaine);
        }
      }
    }

    return arr;

  }

  /**
   * Récupérer les valeurs des préférences utilisateurs et les assigner au formulaire de saisie
   */
  private initForm() {

    this.nbJoursPrefUtilisateur = +this.prefUserSvc.getPreferenceUtilisateurValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS);
    this.joursSemainesPrefUtilisateur = this.prefUserSvc.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_SELECT_JOURS_SEMAINE);
    this.joursSemainesSelected = this.transformJourSemaine(this.joursSemainesPrefUtilisateur);

    this.enableCoherenceArticle = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_COHERENCE_ARTICLE);

    this.displayFormSearch = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FORMSEARCH);

    // this.enableSaveImmediatly = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_TOOLBAR_SAVEIMMEDIATLY);

    this.coutGlobalMode = this.prefUserSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_COUTGLOBAL_MODE);
    this.coutGlobalModeSelected = this.effectifsSvc.coutGlobalModeItems.filter(val => val.value.idx === this.coutGlobalMode)[0];

    this.pivot = this.prefUserSvc.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_PLANNING_PIVOT);

    this.searchMode = this.prefUserSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_RECHERCHE_MODE);

    this.displayAllergenes = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_ALLERGENES);
    this.displayAppellations = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_APPELLATIONS);
    this.displayFamillesGemrcn = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FAMILLES_GEMRCN);
    this.displayCoutsUnitaires = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES);
    this.displayCoutsUnitairesPonderes = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES_PONDERES);
    this.displayTauxDePrise = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_TAUX_DE_PRISE);


    this.form = this.fb.group({
      nbJoursCtrl: new FormControl(this.nbJoursPrefUtilisateur, [Validators.required, Validators.min(1), Validators.max(90)]),
      joursSemaineCtrl: new FormControl(this.joursSemainesSelected, [Validators.required]),
      displayFormSearchCtrl: new FormControl(this.displayFormSearch),
      // coutGlobalModeSelectedCtrl: new FormControl(this.coutGlobalModeSelected, Validators.required),
      // enableSaveImmediatlyCtrl: new FormControl(this.enableSaveImmediatly),
      enableCoherenceArticleCtrl: new FormControl(this.enableCoherenceArticle),
      searchModeCtrl: new FormControl(this.searchMode, [Validators.required]),
      displayAllergenesCtrl: new FormControl(this.displayAllergenes, [Validators.required]),
      displayAppellationsCtrl: new FormControl(this.displayAppellations, [Validators.required]),
      displayFamillesGemrcnCtrl: new FormControl(this.displayFamillesGemrcn, [Validators.required]),
      displayTauxDePriseCtrl: new FormControl(this.displayTauxDePrise, [Validators.required]),
      displayCoutsUnitairesCtrl: new FormControl(this.displayCoutsUnitaires, [Validators.required]),
      displayCoutsUnitairesPonderesCtrl: new FormControl(this.displayCoutsUnitairesPonderes, [Validators.required]),
    });

    this.cd.markForCheck();
  }


  savePreferencesUtilisateur() {

    if (this.form && this.form.valid) {
      this.nbJoursPrefUtilisateur = this.form.controls['nbJoursCtrl'].value;
      this.joursSemainesPrefUtilisateur = this.form.controls['joursSemaineCtrl'].value.map(item => item.value);
      this.displayFormSearch = this.form.controls['displayFormSearchCtrl'].value;
      // this.coutGlobalModeSelected = this.form.controls['coutGlobalModeSelectedCtrl'].value;
      // this.enableSaveImmediatly = this.form.controls['enableSaveImmediatlyCtrl'].value;
      this.enableCoherenceArticle = this.form.controls['enableCoherenceArticleCtrl'].value;
      this.searchMode = this.form.controls['searchModeCtrl'].value;

      this.displayAllergenes = this.form.controls['displayAllergenesCtrl'].value;
      this.displayAppellations = this.form.controls['displayAppellationsCtrl'].value;
      this.displayFamillesGemrcn = this.form.controls['displayFamillesGemrcnCtrl'].value;
      this.displayTauxDePrise = this.form.controls['displayTauxDePriseCtrl'].value;
      this.displayCoutsUnitaires = this.form.controls['displayCoutsUnitairesCtrl'].value;
      this.displayCoutsUnitairesPonderes = this.form.controls['displayCoutsUnitairesPonderesCtrl'].value;


      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS, USER_TYPE_PREFERENCE.NUMBER, this.nbJoursPrefUtilisateur);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_SELECT_JOURS_SEMAINE, USER_TYPE_PREFERENCE.NUMBERARR, this.joursSemainesPrefUtilisateur);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_COHERENCE_ARTICLE, USER_TYPE_PREFERENCE.BOOLEAN, this.enableCoherenceArticle);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FORMSEARCH, USER_TYPE_PREFERENCE.BOOLEAN, this.displayFormSearch);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_RECHERCHE_MODE, USER_TYPE_PREFERENCE.NUMBER, this.searchMode);

      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_APPELLATIONS, USER_TYPE_PREFERENCE.BOOLEAN, this.displayAppellations);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_ALLERGENES, USER_TYPE_PREFERENCE.BOOLEAN, this.displayAllergenes);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FAMILLES_GEMRCN, USER_TYPE_PREFERENCE.BOOLEAN, this.displayFamillesGemrcn);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_TAUX_DE_PRISE, USER_TYPE_PREFERENCE.BOOLEAN, this.displayTauxDePrise);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES, USER_TYPE_PREFERENCE.BOOLEAN, this.displayCoutsUnitaires);
      this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GESTIONMENUS_DISPLAY_COUTS_UNITAIRES_PONDERES, USER_TYPE_PREFERENCE.BOOLEAN, this.displayCoutsUnitairesPonderes);

      this.prefUserSvc.savePreferencesUtilisateur().subscribe(
        prefUserDTOs => {
          this.mp2Svc.announceChangeSettings();
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Les préférences du menu ont été modifiées.`);


        });
    } else {
      this.gfs.validateAllFormFields(this.form);
    }
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSettings);
    this.utils.unsubscribe(this.subPreferencesUtilisateur);
  }

  log() {
    this.counter++;
    console.log('changedetection', this.counter, this.form.valid);
  }


}


