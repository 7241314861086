import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, OUTLET_SECONDARY, SECURITY} from "../core/constants";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {GestionProductionRootComponent} from "./gestion-production-root/gestion-production-root.component";
import {PlansDeProductionComponent} from "./pp/plans-de-production/plans-de-production.component";
import {PpComponent} from "./pp/pp.component";
import {ValidationStocksComponent} from "./pp/plans-de-production/validation-stocks/validation-stocks.component";

const routes: Routes = [
  {
    path: FP_ROUTES.GESTION_PRODUCTION,
    component: GestionProductionRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers:'always',
    data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
    children:[
      {
        path:'pp',
        component:PpComponent,
        runGuardsAndResolvers:'always',
        data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
        children:[
          {
            path:'plans-de-production',
            runGuardsAndResolvers:'always',
            component:PlansDeProductionComponent,
            data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION}
          }
        ]
      }
    ]
  },{
    path: `${FP_ROUTES.GESTION_PRODUCTION}/validation-stocks`,
    component: ValidationStocksComponent,
    outlet: OUTLET_SECONDARY,
    canActivate: [Auth2Guard],
    data: {role: SECURITY.ROLES.ROLE_UTILISATEUR, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
    runGuardsAndResolvers: 'always'
  }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionProductionRoutingModule { }
