import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClientDTO} from '../../../../core/dtos/client-dto';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../../core/suppliers/form-fieldbase-supplier';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {AdressesService} from '../../../../core/services/entities/adresses.service';
import {ConfirmationService} from 'primeng/api';
import {ContactsService} from '../../../../core/services/entities/contacts.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {ContactDTO} from '../../../../core/dtos/contact-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash';
import {PointDeLivraisonDTO} from '../../../../core/dtos/point-de-livraison-d-t-o';
import {PointDeLivraisonService} from '../../../../core/services/entities/point-de-livraison.service';
import {FP_ROUTES, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";

@Component({
  selector: 'yo-point-de-livraison-fiche-identite',
  templateUrl: './point-de-livraison-fiche-identite.component.html',
  styleUrls: ['./point-de-livraison-fiche-identite.component.scss']
})
export class PointDeLivraisonFicheIdentiteComponent implements OnInit, OnDestroy {

  displayDialog: boolean;

  pointDeLivraison: PointDeLivraisonDTO;
  client: ClientDTO;

  formPointDeLivraison: FormGroup;
  fieldsPointDeLivraison: FormFieldBaseSupplier<any>[];

  // adresse du point de livraison
  formAdressePdl: FormGroup;
  fieldsAdressePdl: FormFieldBaseSupplier<any>[];

  formContact1: FormGroup;
  fieldsContact1: FormFieldBaseSupplier<any>[];

  formContact2: FormGroup;
  fieldsContact2: FormFieldBaseSupplier<any>[];


  subLibelle: Subscription;
  pointDeLivraisonLibelle$;

  subscriptionRoute: Subscription;

  ancienNomAdressePointDeLivraison: string;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              public gds: GenericDatagridService,
              public gfs: GenericFormService,
              public adresseSvc: AdressesService,
              public pdlSvc: PointDeLivraisonService,
              private confirmationSvc: ConfirmationService,
              public contactsSvc: ContactsService,
              private routeMapSvc: RoutemapService) {
  }

  ngOnInit() {
    this.initRouteSubscription();
    this.initForms(this.pointDeLivraison);
    this.pointDeLivraisonLibelle$ = this.pdlSvc.libelle$;
    this.initPointDeLivraisonLibelleSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLibelle);
    this.utils.unsubscribe(this.subscriptionRoute);
  }


  initForms = (pointDeLivraison: PointDeLivraisonDTO) => {

    this.fieldsPointDeLivraison = this.pdlSvc.getFields(pointDeLivraison);
    this.formPointDeLivraison = this.gfs.toFormGroup(this.fieldsPointDeLivraison);

    this.fieldsAdressePdl = this.adresseSvc.getFields(pointDeLivraison ? pointDeLivraison.adresse : null);
    this.formAdressePdl = this.gfs.toFormGroup(this.fieldsAdressePdl);

    this.fieldsContact1 = this.contactsSvc.getFields(pointDeLivraison ? pointDeLivraison.contact1 : null);
    this.formContact1 = this.gfs.toFormGroup(this.fieldsContact1);

    this.fieldsContact2 = this.contactsSvc.getFields(pointDeLivraison ? pointDeLivraison.contact2 : null);
    this.formContact2 = this.gfs.toFormGroup(this.fieldsContact2);

    if (!this.utils.isNullOrEmpty(this.pointDeLivraison) && !this.utils.isNullOrEmpty(this.pointDeLivraison.adresse)) {
      this.ancienNomAdressePointDeLivraison = this.pointDeLivraison.adresse.nom;
    } else {
      this.ancienNomAdressePointDeLivraison = null;
    }
  };

  initRouteSubscription = (): void => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data) => {
        this.pointDeLivraison = data.plcSupplier.pointDeLivraison;
        this.client = this.utils.isNullOrEmpty(this.pointDeLivraison) ? data.client : this.pointDeLivraison.client;
        this.initForms(this.pointDeLivraison);
      });
  };

  initPointDeLivraisonLibelleSubscription = (): void => {
    this.subLibelle = this.pointDeLivraisonLibelle$.subscribe(value => {
      if (!this.utils.isCollectionNullOrEmpty(this.fieldsAdressePdl)) {
        const field = _find(this.fieldsAdressePdl, {'key': 'nom'});
        if (UtilsService.staticIsNullOrEmpty(this.ancienNomAdressePointDeLivraison)) {
          this.formAdressePdl.controls[field.key].setValue(value);
        }
      }
    });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = () => {

    this.gfs.validateAllFormFields(this.formPointDeLivraison);
    this.gfs.validateAllFormFields(this.formAdressePdl);
    this.gfs.validateAllFormFields(this.formContact1);
    this.gfs.validateAllFormFields(this.formContact2);

    if (this.formPointDeLivraison.valid && this.formAdressePdl.valid && this.formContact1.valid && this.formContact2.valid) {

      const pdl = this.gfs.mapFormToDTO(this.formPointDeLivraison) as PointDeLivraisonDTO;
      const adressePdl = this.gfs.mapFormToDTO(this.formAdressePdl) as AdresseDTO;
      const contact1 = this.gfs.mapFormToDTO(this.formContact1) as ContactDTO;
      const contact2 = this.gfs.mapFormToDTO(this.formContact2) as ContactDTO;

      pdl.adresse = adressePdl;
      // le site de l'adresse doit être le meme que site du client
      pdl.adresse.site = this.client.site;
      pdl.contact1 = contact1;
      pdl.contact2 = contact2;

      this.pointDeLivraison = _cloneDeep(pdl);

      this.pdlSvc.save(this.client, pdl).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Point de livraison ${pdl.libelle} enregistré.`,);
          response.one.client = this.client;
          this.pointDeLivraison = response.one;
          this.pdlSvc.announceSavedDTO(response);
          this.routeMapSvc.goToSecondaryRoute(['point-de-livraison', this.client.id, this.pointDeLivraison.id, 'ficheidentite']);
        }
      });
    }
  };

  deletePointDeLivraison = (pointDeLivraison: PointDeLivraisonDTO) => {
    this.confirmationSvc.confirm({
      message: `Etes vous sûr de vouloir supprimer le point de livraison "${pointDeLivraison.libelle}" ?`,
      accept: () => {
        this.gfs.deleteOne(this.pdlSvc.getEntityName(), pointDeLivraison.id).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Point de livraison ${pointDeLivraison.libelle} supprimé.`);
            this.routeMapSvc.goToPrimaryRoute([FP_ROUTES.GESTION_CLIENTS, 'clients', this.client.id]);
            // annoncer que le point de livraison a été supprimé à l'arbre
            this.pdlSvc.announceDeletedDTO(response);
          }
        });
      }
    });
  };
}
