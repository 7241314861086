import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {HttpClient} from '@angular/common/http';
import {CiqualService} from '../../../core/services/gestionproduits/ciqual.service';
import {ProduitnomenclatureDTO} from '../../../core/dtos/produitnomenclature-dto';
import {ProduitAllergeneDTO} from '../../../core/dtos/produit-allergene-dto';
import {DocumentDTO} from '../../../core/dtos/document-dto';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {ProduitDeclinaisonSupplier} from './produit-declinaison-resolver.service';
import {DeclinaisonsService} from '../../../core/services/entities/declinaisons.service';
import {DeclinaisonDTO} from '../../../core/dtos/declinaison-dto';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ProduitDeclinaisonDenreeService} from "../../../core/services/gestionproduits/produit-declinaison-denree.service";
import {ConfirmationService} from "primeng/api";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";

@Component({
  selector: 'yo-declinaisons-produit',
  templateUrl: './declinaisons-produit.component.html',
  styleUrls: ['./declinaisons-produit.component.scss']
})
export class DeclinaisonsProduitComponent implements OnInit, OnDestroy {

  @ViewChild('menuActions') menuActions;

  subscriptionRoute: Subscription;
  subSidenav: Subscription;
  subDeleteObject: Subscription;
  subSaveObject: Subscription;

  produit: ProduitDTO;
  produitDeclinaisonADupliquer: ProduitDeclinaisonDTO;
  produitNomenclatureList: ProduitnomenclatureDTO[];
  produitDeclinaisonUtilises: ProduitDeclinaisonDTO[];
  displayedColumns = ['actions', 'libelle', 'declinaison', 'prixAchat', 'uniteDeMesure', 'code', 'codeComptable', 'dlc', 'actif'];

  listProduitAllergenes: ProduitAllergeneDTO[] = [];

  urlImageProduit = '';
  documentImageProduit: DocumentDTO;

  /**
   * Indique si le dialogue est visible.
   */
  displayDialog: boolean = false;

  /**
   * Toutes les déclinaisons visibles par l'utilisateur connecté
   */
  allDeclinaisons: DeclinaisonDTO[];

  /**
   * Les déclinaisons sélécetionnées dans le dialogue.
   */
  selectedDeclinaisons: DeclinaisonDTO[] = [];

  produitsDeclinaisonsEcrasables: ProduitDeclinaisonDTO[] = [];
  ecrasementDesProduitsDeclinaisonsExistants: string = 'true';

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-declinaisons';

  constructor(public produitDeclinaisonSvc: ProduitDeclinaisonService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              private cd: ChangeDetectorRef,
              public utils: UtilsService,
              private http: HttpClient,
              private ciqualService: CiqualService,
              public produitDecliDenreeSvc: ProduitDeclinaisonDenreeService,
              private confirmationSvc: ConfirmationService,
              private produitsService: ProduitsService,
              private declinaisonsSvc: DeclinaisonsService,) {
  }


  ngOnInit() {

    //Abonnement à la suppression d'un produit declinaison à partir du bouton de la fiche d'édition
    this.deleteProduitDeclinaisonSubscription();

    // chargement des données nécessaires à la page
    this.routeSubscription();


  }

  deleteProduitDeclinaisonSubscription = () => {
    this.subDeleteObject = this.gfs.deletedId$.subscribe(response => {
      this.produit.produitDeclinaisons = this.produit.produitDeclinaisons.filter(item => item.id !== response.one);
    });
  };

  routeSubscription = () => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { produitDeclinaisonSupplier: ProduitDeclinaisonSupplier }) => {
        this.produit = data.produitDeclinaisonSupplier.produitDTO;
        this.produitNomenclatureList = data.produitDeclinaisonSupplier.produitNomenclatureList;
        this.produitDeclinaisonUtilises = data.produitDeclinaisonSupplier.produitDeclinaisonUtilises;
        this.listProduitAllergenes = data.produitDeclinaisonSupplier.produitAllergeneDTOList;
      });
  };


  openEditObject = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO) => {
    this.produitDeclinaisonSvc.announceOpenDialogProduitDeclinaison({
      produit: produit,
      produitDeclinaison: produitDeclinaison
    });
  };

  /**
   * Ouverture du dialogue permettant la duplication des Produits déclinaisons.
   * @param produit
   * @param produitDeclinaison
   */
  openDialogDuplicationDeclinaison = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO) => {

    this.calculeProduitsDeclinaisonEcrasables();
    this.displayDialog = true;
    this.produitDeclinaisonADupliquer = produitDeclinaison;

    this.allDeclinaisons = [];

    let nbDeclinaisons = 0;
    this.declinaisonsSvc.declinaisonEnvironnement.forEach(declinaison => {
      if (declinaison.id != this.produitDeclinaisonADupliquer.declinaison.id) {
        this.allDeclinaisons.push(declinaison);
        nbDeclinaisons++;
      }

    });
    // On ne garde que les déclinaisons ayant la même valeur pour le champ fabrique que celle du ProduitDeclinaison passé en paramètre.
    this.allDeclinaisons =
      this.allDeclinaisons.filter(declinaison => declinaison.typeProduit.fabrique == produitDeclinaison.fabrique);

  };

  /**
   * Le dialogue devient invisible.
   */
  onHideDuplicationDialog = () => {
    this.displayDialog = false;
  };

  /**
   * Duplication de la déclinaison this.produitDeclinaisonADupliquer vers les déclinaisons sélectionnées (
   * this.selectedDeclinaisons).
   */
  duplicationDeclinaison = () => {
    if (!this.utils.isNullOrEmpty(this.produitDeclinaisonADupliquer)) {
      let ecrasement: boolean = true;
      if (this.ecrasementDesProduitsDeclinaisonsExistants === 'false') {
        ecrasement = false;
      }

      this.produitDeclinaisonSvc.duplicationProduitDeclinaison(this.produitDeclinaisonADupliquer, this.selectedDeclinaisons, ecrasement).subscribe(response => {

        this.produit.produitDeclinaisons = response.resultList;
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, 'Duplication effectuée', '', 3000);

        //Mise a jour du prix uniquement si c'est un plat qui est dupliquer
        if (this.produitDeclinaisonADupliquer.fabrique) {
          const idProduitDecliList: number[] = [];
          this.produit.produitDeclinaisons.forEach((pd: ProduitDeclinaisonDTO) => {
            idProduitDecliList.push(pd.id);
          });
        }

        /// Fermeture du dialogue
        this.onHideDuplicationDialog();

      });
    }
  };

  openDeleteObject = (dto: ProduitDeclinaisonDTO) => {
    this.confirmationSvc.confirm({
      message: `Etes vous sûr de vouloir supprimer le produit déclinaison "${dto.libelle}" ?`,
      accept: () => {
        this.produitDeclinaisonSvc.deleteById(dto).subscribe((response: ResponseWrapper<IsDeletableObject>) => {
          if(response.one.deletable) this.produit.produitDeclinaisons = this.produit.produitDeclinaisons.filter(item => item.id !== dto.id);
          this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
        });
      }
    });
  };

    ngOnDestroy(): void {
      this.utils.unsubscribe(this.subscriptionRoute);
      this.utils.unsubscribe(this.subSidenav);
      this.utils.unsubscribe(this.subDeleteObject);
      this.utils.unsubscribe(this.subSaveObject);
    }

    help = (): DialogMsgSupplier => {
      let dms = new DialogMsgSupplier();
      dms.title = `Paramétrage d'un produit/plat`;
      dms.logo = 'fa fa-question-circle  yoni-color';

      let p1: Paragraphe = new Paragraphe();
      p1.title = `Déclinaison d'un <b>produit/plat</b>`;
      p1.lines = [
        `On retrouve ici les paramètres qui permettrons de donner une identité à la fiche technique qui contiendra quant à elle des composants :`,
        `<ul>
<li>unité technique</li>
<li>ration entre unité technique et l’unité universelle le Kg</li>
<li>type de fabrication</li>
</ul>`,
      ];

      dms.content = {
        intro: `Une <b>déclinaison</b> d'un <b>produit/plat</b> comporte les paramètres orientés « production ».`,
        paragraphes: [p1]
      };

      return dms;
    };


    /**
     * Impression PDF des données du {@link ProduitDeclinaisonDTO} passé en paramètre.
     * @param {ProduitDeclinaisonDTO} produitDeclinaison
     */
    printIncoData = (produitDeclinaison: ProduitDeclinaisonDTO) => {
      this.produitDeclinaisonSvc.printNutritionalInformations(produitDeclinaison.libelle, produitDeclinaison.id);
    };


    getTitre = (): string => {
      if (this.utils.isNullOrEmpty(this.produitDeclinaisonADupliquer)) {
        return '';
      } else {

        let titre: string = 'Dupliquer la déclinaison ' + this.produitDeclinaisonADupliquer.declinaison.libelle + ' sur les autres déclinaisons.';
        return titre;
      }
    };

    calculeProduitsDeclinaisonEcrasables = () => {
      this.produitsDeclinaisonsEcrasables = [];

      this.selectedDeclinaisons.forEach(declinaison => {
        this.produit.produitDeclinaisons.forEach(produitDeclinaison => {
          if (produitDeclinaison.declinaison.id === declinaison.id) {
            // console.log("Attention : écrasement de la déclinaison " + declinaison.libelle + "(" + declinaison.id + ")" +
            //   " produit decl : " + produit.libelle + "(" + produit.id + ")");
            this.produitsDeclinaisonsEcrasables.push(produitDeclinaison);
          }
        });
      });

      this.produitsDeclinaisonsEcrasables.forEach(produitDeclinaison => {
        console.log('Attention écrasement du produit decl : ' + produitDeclinaison.libelle + '(' + produitDeclinaison.id + ')');
      })
    };

    changeDeclinaison = (event: any) => {
      this.calculeProduitsDeclinaisonEcrasables();
    };

    canDuplicateDeclinaisonDialog = () => {
      if (this.utils.isNullOrEmpty(this.selectedDeclinaisons)) {
        return false;
      } else {
        let response: boolean = this.selectedDeclinaisons.length > 0;
        return response;
      }
    };

    /**
     * Indique si le {@link ProduitDeclinaisonDTO} passé en paramètre peut être dupliqué.
     * Pour pouvoir ce faire il faut :
     * - que le site propriétaire du produit soit dans l'environnement courant en site local.
     * - le nombre de déclinaisons sur lesquelles dupliquer le {@link ProduitDeclinaisonDTO} soit supérieur à 1
     */
    canDuplicateDeclinaison = (produitDeclinaison: ProduitDeclinaisonDTO) => {
      // Les déclinaisons utilisables
      const declinainsonsEnvironnement = !this.utils.isCollectionNullOrEmpty(this.declinaisonsSvc.declinaisonEnvironnement) ? this.declinaisonsSvc.declinaisonEnvironnement : [];

      let declinaisonsUtilisables: DeclinaisonDTO[] = declinainsonsEnvironnement.filter(declinaison => declinaison.typeProduit.fabrique == produitDeclinaison.fabrique);
      let response = this.gds.canModify(this.produit) && declinaisonsUtilisables.length > 0;
      return response;
    };

    openDialogPlatUsingSelectedDenree = (rowData: ProduitDeclinaisonDTO) => {
      this.produitDecliDenreeSvc.announceOpenDialogListplatUsinsDenree(rowData);
    };

  }
