import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {CouleurDTO} from '../../dtos/couleur-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {shareReplay} from 'rxjs/operators';
import {startCase as _startCase} from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class CouleursService  extends GenericHandler<CouleurDTO> {


  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  couleursEnvironnement: CouleurDTO[];

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService,
  ) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  createEmptyDTO(): CouleurDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {

    console.log("CouleursService#getAllFromEnvironnement()");
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true).pipe(
      shareReplay())
      .subscribe(response => {
        this.couleursEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.couleursEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.couleursEnvironnement);
      });

  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: CouleurDTO): string {
    return "";
  }

  getEntityName(): string {
    return "couleur";
  }

  getFields(dto: CouleurDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "GESTION DES COULEURS";
  }
}
