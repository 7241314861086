import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ContratMenuConviveSupplier} from './contratmenu-convive-resolver.service';
import {ContratsMenusConvivesService} from '../../core/services/gestioncontrats/contrats-menus-convives.service';
import {TYPE_PRESTATION_ID} from '../../core/constants';
import {MenuItem} from 'primeng/api';
import {ItemNavigationService} from '../../core/services/technique/item-navigation.service';

@Component({
  selector: 'yo-contratmenu-convive',
  templateUrl: './contratmenu-convive.component.html',
  styleUrls: ['./contratmenu-convive.component.scss']
})
export class ContratmenuConviveComponent implements OnInit, OnDestroy {

  tabContratMenuConvive: MenuItem[] = [];
  selectedTabCmc: MenuItem;
  contratMenuConvive: ContratMenuConviveDTO = new ContratMenuConviveDTO();

  subscriptionRoute: Subscription;
  subSavedContratMenuConvive: Subscription;
  subActiveSecondaryRoute:Subscription;

  constructor(public utils: UtilsService, private route: ActivatedRoute,
              private itemNavSvc:ItemNavigationService,
              private cmcSvc: ContratsMenusConvivesService) {
  }

  ngOnInit() {
    // Chargement de la page, recup du contrat menu convive à partir du resolver
    this.routeSubscription();
    // Lorsque le contrat menu convive est enregistré on recalcule les onglets à rendre disponible
    this.savedContratMenuConviveSubscription();
    // fix kjlnhjk tabmenu route
    this.activeSecondaryRouteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSavedContratMenuConvive);
  }

  /**
   * Lorsque le contrat menu convive est enregistré on recalcule les onglets à rendre disponible
   */
  savedContratMenuConviveSubscription = () => {
    this.subSavedContratMenuConvive = this.cmcSvc.savedDto$.subscribe(response => {
      if (response.one) {
        this.contratMenuConvive = response.one as ContratMenuConviveDTO;
        this.initTaMenuProduit(this.contratMenuConvive);
      }
    });
  };

  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.selectedTabCmc = this.itemNavSvc.activeSecondaryRoute(event, this.tabContratMenuConvive);
    });
  };

  /**
   *     Chargement de la page, recup du contrat menu convive à partir du resolver
   */
  routeSubscription = () => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { contratMenuConviveSupplier: ContratMenuConviveSupplier }) => {
        this.contratMenuConvive = data.contratMenuConviveSupplier.contratMenuConvive;
        this.initTaMenuProduit(this.contratMenuConvive);
      });
  };

  private initTaMenuProduit = (contratMenuConvive: ContratMenuConviveDTO) => {

    this.tabContratMenuConvive = [];
    this.tabContratMenuConvive.push({label: `Fiche d'identité`, routerLink: ['ficheidentite']});

    //Si le produit existe
    if (this.utils.isNumberGt0(contratMenuConvive.id) && contratMenuConvive.typePrestationDTO.id === TYPE_PRESTATION_ID.Menu) {
      this.tabContratMenuConvive.push({label: `Planning`, routerLink: ['planning']});
      this.tabContratMenuConvive.push({label: `Équipes`, routerLink: ['equipes']});
      this.tabContratMenuConvive.push({label: `Points de livraison`, routerLink: ['points-de-livraison']});
    }
    if (this.utils.isNumberGt0(contratMenuConvive.id) && contratMenuConvive.typePrestationDTO.id === TYPE_PRESTATION_ID.PriseDeCommande) {
      this.tabContratMenuConvive.push({label: `Équipes`, routerLink: ['equipes']});
      this.tabContratMenuConvive.push({label: `Points de livraison`, routerLink: ['points-de-livraison-gcom']});
    }
  };
}
