<p-toast key="modelenutri" position="bottom-right"></p-toast>

<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button
      type="button"
      pButton
      label="CRÉER"
      (click)="openModeleNutri(undefined)"
      icon="fas fa-plus"
      class="mg-r-5"
      pTooltip="Créer un modèle de menu"
      [disabled]="!canCreate()"
      [class.disable]="!canCreate()">
    </button>

  </ng-template>
  <ng-template pTemplate="right">
    <button
      type="button"
      pButton
      icon="fa fa-trash"
      (click)="deletePlansAlimentaires()"
      [disabled]="isDisabledBin()"
      pTooltip="Supprimer les modèles de prestation sélectionnés">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="plansAlimentaires"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site.libelle" caption="Site"
              [allowFiltering]="true"
              [allowGrouping]="true"
              cellTemplate="actionsSiteTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Modèle de menu"
              [allowFiltering]="true"
              [allowGrouping]="false">
  </dxi-column>
  <dxi-column dataField="code" caption="Code"
              [allowFiltering]="true"
              [allowGrouping]="false">
  </dxi-column>
  <dxi-column dataField="actif" caption="Actif"
              [allowFiltering]="true"
              [allowGrouping]="false">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <td style="text-align: left;">
      <yo-cell-button (yoNavigation)="openModeleNutri(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="45"
                      class="mg-r-5"
                      yoTextAlign="center"
                      yoIconClass="fa fa-edit"
                      yoTooltip="Modifier le modèle de menu"
                      [yoViewMode]="!gds.canModify(cell.row.data)"
      ></yo-cell-button>
      <yo-cell-button (yoNavigation)="openPanelDuplication($event,cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="45"
                      class="mg-r-5"
                      yoTextAlign="center"
                      yoIconClass="fas fa-copy"
                      yoTooltip="Dupliquer le modèle de menu"
      ></yo-cell-button>
    </td>
  </div>
  <div *dxTemplate="let cell of 'actionsSiteTemplate'">
    <td>
      <yo-site [siteLibelle]="cell.row.data.site.libelle" [siteId]="cell.row.data.site.id"></yo-site>
    </td>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} modèles">
  </dxo-pager>

</dx-data-grid>

<!--PANEL DUPLICATION-->
<p-overlayPanel #opDuplication>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          pTooltip=""
          showDelay="500">Site sur lequel dupliquer le modèle de menu</label>
        <div class="col-sm-10">
          <p-dropdown [options]="auth2Svc.utilisateur.siteListLocaux"
                      optionLabel="libelle"
                      filter="true"
                      placeholder="sélection..."
                      [autoDisplayFirst]="false"
                      [(ngModel)]="duplicationSite"
                      [style]="{'width':'360px'}">
            <ng-template let-site pTemplate="selectedItem">
          <span style="vertical-align:middle; margin-left: .5em">
            <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site></span>
            </ng-template>
            <ng-template let-site pTemplate="item">
              <div style="position: relative;height: 25px;">
                <div style="font-size:14px;margin-top:4px">
                  <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site>
                </div>
              </div>
            </ng-template>
          </p-dropdown>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button pButton label="Dupliquer"
              icon="fas fa-copy"
              (click)="duplicateModeleNutri(planAlimentaireToDuplicate,duplicationSite)"
              [disabled]="duplicationSite === undefined">
      </button>
    </div>
  </div>
</p-overlayPanel>
