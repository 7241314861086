import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WorkflowsService} from '../../core/services/entities/workflows.service';
import {WorkflowDto} from '../../core/dtos/workflow-dto';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {UtilsService} from '../../core/utils/utils.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteDTO} from '../../core/dtos/site-dto';
import {ListeBesoinsService} from "../../core/services/entities/liste-besoins.service";
import {InitProcessusSupplier} from "../../core/suppliers/gestion-processus/init-processus-supplier";

@Component({
  selector: 'yo-init-processus',
  templateUrl: './init-processus.component.html',
  styleUrls: ['./init-processus.component.scss']
})
export class InitProcessusComponent implements OnInit, OnDestroy {

  subOpenDialogInitProcessus: Subscription

  ips: InitProcessusSupplier;

  displayDialog: boolean = false;

  subForm: Subscription;
  subWorkflows: Subscription;
  workflows: WorkflowDto[];
  displayWorkflow: boolean;
  @ViewChild('mermaid') mermaid: ElementRef;

  form: FormGroup;

  constructor(
    private cd: ChangeDetectorRef,
    private listeBesoinsSvc: ListeBesoinsService,
    public domSvc: DomSanitizer,
    public workflowSvc: WorkflowsService,
    private utils: UtilsService,
    public auth2Svc: Auth2Service,
    private gfs: GenericFormService) {
  }


  ngOnInit() {
    this.initForm();
    this.subscriptionOpenDialogInitProcessus();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subForm);
    this.utils.unsubscribe(this.subWorkflows);
    this.utils.unsubscribe(this.subOpenDialogInitProcessus);
  }

  subscriptionOpenDialogInitProcessus(){
    this.subOpenDialogInitProcessus = this.workflowSvc.openDialogInitProcessus$.subscribe((data: InitProcessusSupplier) => {
      this.ips = data;
      this.displayDialog = true;
      this.subWorkflows = this.workflowSvc.getWorkflows(this.ips.requiredSteps).subscribe(response => {
        this.workflows = [];
        if (!this.utils.isResponseSupplierError(response)) {
          this.workflows = response.resultList;
          this.initForm();
        }
      });
    });
  }

  initForm() {

    let workflow: WorkflowDto = undefined;
    let site: SiteDTO = undefined;
    let libelle = '';

    if (!this.utils.isCollectionNullOrEmpty(this.workflows)) {
      workflow = this.workflows[0];
      libelle = workflow.libelle + ' ' + this.utils.getFrenchDateHHMM(new Date().getTime());
      libelle = libelle.toUpperCase();
    }
    if (!this.utils.isCollectionNullOrEmpty(this.auth2Svc.utilisateur.siteListLocaux)) {
      site = this.auth2Svc.utilisateur.siteListLocaux[0];
    }

    this.form = new FormGroup({
      libelle: new FormControl(libelle, Validators.required),
      workflow: new FormControl(workflow, Validators.required),
      displayWorkflow: new FormControl(false),
      sites: new FormControl(site, Validators.required),
    });

    this.workflowSvc.showMermaidFlow(this.mermaid, workflow);

    this.utils.unsubscribe(this.subForm);
    this.subForm = this.form.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(values => {
        this.workflowSvc.showMermaidFlow(this.mermaid, values.workflow);
        this.cd.markForCheck();
      });

  }

  showWorkflow($event) {
    this.displayWorkflow = true;
  }

  save() {

    this.gfs.validateAllFormFields(this.form);
    if (this.form.valid) {

      //creation workflow instance
      this.workflowSvc.startWorkflow(this.form).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.workflowSvc.announceWorkflowInstanceSaved(response.one);
          this.displayDialog = false;
        }
      });
    }
  }

  canStartWorkflow() {
    const workflow = this.form.controls['workflow'].value;
    return this.workflowSvc.canStartWorkflow(workflow);
  }

  closeDialog() {
    this.displayDialog = false;
  }
}
