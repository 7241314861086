<p-dialog
  [modal]="true"
  [style]="{'width':'90wh'}"
  [contentStyle]="{'overflow':'visible'}"
  (close)="closeDialog()"
  [(visible)]="displayDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-edit"></i>
      Traitement de masse du plan de production
    </div>
  </ng-template>

  <div class="d-flex flex-column">
    <div class="d-flex">
      <p class="width-150 mg-r-5">Date de fabrication : </p>
      <p-calendar [(ngModel)]="dateFab" [locale]="localeFr"
                  showIcon="true" firstDayOfWeek="1"
                  dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="d-flex">
      <p class="width-150  mg-r-5">Date limite de consommation : </p>
      <p-calendar [(ngModel)]="dlc" [locale]="localeFr"
                  showIcon="true" firstDayOfWeek="1"
                  dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="d-flex">
      <p class="width-150 mg-r-5">Équipe : </p>
      <p-dropdown [options]="udpEquipeList"
                  [(ngModel)]="udpEquipe"
                  [style]="{'width':'300px'}"
                  placeholder="Selection..."
                  optionLabel="equipeLibelle"
                  filter="true"
                  [disabled]="udpEquipeList.length === 0">
        <ng-template let-itemSelected pTemplate="selectedItem">
          <div class="d-flex flex-row">
            <div class="mg-r-30"><i class="fas fa-store mg-r-5"></i>{{itemSelected.zoneDeProductionLibelle}}</div>
            <div><i class="fas fa-users mg-r-5"></i> {{itemSelected.equipeLibelle}}</div>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="d-flex width-500" >
            <div class="width-300">
              <i class="fas fa-store mg-r-5"></i>{{item.zoneDeProductionLibelle}}
            </div>
            <div class="width-200">
              <i class="fas fa-users mg-r-5"></i> {{item.equipeLibelle}}
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check"
                class="mg-r-5"
                [disabled]="disabledSaveBtn()"
                (click)="sendBulkDetailSupplier()"></button>
        <button pButton icon="fas fa-times" (click)="closeDialog()"
                class="p-button-secondary"></button>
      </div>
    </div>

  </ng-template>
</p-dialog>
