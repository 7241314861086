<!--CHAMP TAUX DE PRISE D'UN MENU COMPO, visible seulement dans la vue économat -->
<ng-container *ngIf="displayTauxDePrise">


  <span class="cursor font-10" style="padding-right:4px;" pTooltip="Taux de prise" showDelay="500"
        (click)="editTauxDePrise()">{{externalMenuCompo.tauxDePrise | number:'1.2-2'}} %</span>


</ng-container>


<!--DIALOG TAUX DE PRISE-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialogTauxDePrise"
  (onShow)="onShow()"
  [focusOnShow]="false">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <yo-icon-produitfab [fabrique]="internalMenuCompo?.fabrique"></yo-icon-produitfab>
      {{internalMenuCompo?.produitDeclinaisonLibelle}} - Modifier le taux de prise
    </div>
  </ng-template>

  <!--CONTENT-->
  <ng-container *ngIf="!utils.isNullOrEmpty(externalMenuCompo)">
    <div class="input-group input-group-sm  ">
      <div class="input-group-prepend ">
        <span class="input-group-text">Taux de prise</span>
      </div>
      <input
        #txprise
        class="text-right"
        type="text"
        pKeyFilter="pint"
        style="width: 40px;"
        [(ngModel)]="internalMenuCompo.tauxDePrise"
        (keypress)="saveOnKeyPressEnter($event)"
        (keydown)="utils.keydownNumber($event,internalMenuCompo,'tauxDePrise',true)"/>
      <div class="input-group-append ">
          <span class="input-group-text "> %
          </span>
      </div>
    </div>

  </ng-container>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" class="mg-r-5"
                (click)="saveMenuComposition(internalMenuCompo)"></button>
        <button pButton icon="fas fa-times" (click)="this.closeDialog(false)"
                class="p-button-secondary"></button>
      </div>
    </div>

  </ng-template>


</p-dialog>
