<div class="card">

  <div class="card-header">
    <div class="row">
      <div class="col-md-6">
        <span class="mg-r-5">RÉAJUSTER LES SORTIES DE STOCK</span>
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="getHelp()" [width]="800"></yo-help>
      </div>
      <div class="col-md-6 text-right">
        <span class="mg-r-5">
          <button
            pButton
            icon="fas fa-print"
            (click)="printPDFSortieStocks()"
            showDelay="1500"
            label="Imprimer les sorties de stock"
            pTooltip="Imprimer les sorties de stocks du plan de production pour la sélection en PDF">
          </button>
        </span>

        <button
          pButton
          icon="fas fa-save"
          (click)="saveReajustementStock()"
          label="Enregistrer les sorties de stock">
        </button>
      </div>
    </div>
  </div>

  <div class="card-body">

    <!--    ENTETE-->
    <div class="row entete">
      <div class="col-md-1 ">
        NUMÉRO DE LOT
      </div>
      <div class="col-md-2">
        DÉNOMINATION DE LA DENRÉE
      </div>
      <div class="col-md-1">
        NUMÉRO DE LOT DU FABRIQUANT
      </div>
      <div class="col-md-2">
        DLC / DLUO
      </div>
      <div class="col-md-2">
        <span showDelay="500" pTooltip="Quantité à sortir en unité technique ">QTÉ À SORTIR (UT)</span>
      </div>
      <div class="col-md-2">
        QUANTITÉ DÉJÀ SORTIE (UT)
      </div>
      <div class="col-md-1">
        QUANTITÉ EN STOCK (UT)
      </div>
      <div class="col-md-1">
        PLAT
      </div>
    </div>

    <div style="height:80vh;overflow-y:auto;overflow-x: hidden">

      <ng-container *ngFor="let denreeStock of denreesStock">
        <!--        DENREE-->
        <div class="row denree">

          <div class="col-md-6">

            <span class="mg-r-5 font-12">{{denreeStock.code}}</span>
            <label class="genlabel "> <strong>{{denreeStock.denomination}}</strong></label>

          </div>
          <div class="col-md-2 ">
            <!--        QUANTITE À SORTIR DENREE-->
            <ng-container *ngTemplateOutlet="tplQuantiteASortir;context:{denree:denreeStock}"></ng-container>

          </div>
          <div class="col-md-2 ">
            <!--        QUANTITE DEJA SORTIE DENREE-->
            <ng-container *ngTemplateOutlet="tplQuantiteDejaSortieDenree;context:{denree:denreeStock}"></ng-container>
          </div>
          <div class="col-md-1 ">
            <!--        QUANTITE EN STOCK DENREE-->
            <ng-container *ngTemplateOutlet="tplQuantiteEnStockDenree;context:{denree:denreeStock}"></ng-container>
          </div>
        </div>

        <ng-container *ngFor="let sortieZoneDeStock of denreeStock.sortieZoneDeStockList">
          <!--        ZDS-->
          <div class="row zds">
            <div class="col-md-12">
              <label class="genlabel">Zone de stockage : </label>
              {{sortieZoneDeStock.udpLibelle}} {{sortieZoneDeStock.zdsLibelle}}
            </div>
          </div>

          <ng-container *ngFor="let lotArticle of sortieZoneDeStock.lotArticleStockList">

            <!--          LOT ARTICLE-->
            <div class="row lotArticle">
              <div class="col-md-3 font-12">
                {{lotArticle.numeroLot}}
              </div>
              <div class="col-md-1">
                <ng-container
                  *ngTemplateOutlet="tplNumeroLotFabriquant;context:{lotArticle:lotArticle,denree:denreeStock}">
                </ng-container>
              </div>

              <div class="col-md-2">
                <!--              DLC  -->
                <ng-container
                  *ngTemplateOutlet="tplDlc;context:{lotArticle:lotArticle,denree:denreeStock}">
                </ng-container>
              </div>
              <div class="col-md-2 text-right ">

              </div>
              <div class="col-md-2 ">
                <!--                  QUANTITÉ DÉJÀ SORTIE LOT ARTICLE-->
                <ng-container
                  *ngTemplateOutlet="tplQuantiteDejaSortieLotArticle;context:{lotArticle:lotArticle,denree:denreeStock}">
                </ng-container>

              </div>
              <div class="col-md-1 ">
              <!--                QUANTITÉ EN STOCK LOT ARTICLE-->
                <ng-container
                  *ngTemplateOutlet="tplQuantiteEnStockLotArticle;context:{lotArticle:lotArticle,denree:denreeStock}">
                </ng-container>
              </div>

              <div class="col-md-1 font-10">
                {{lotArticle.codePlat}}
              </div>
            </div>

          </ng-container>

        </ng-container>


      </ng-container>

    </div>
  </div>


</div>


<!--TEMPLATE QUANTITE DÉJÀ SORTIE LOT ARTICLE-->
<ng-template #tplQuantiteDejaSortieLotArticle let-lotArticle="lotArticle" let-denree="denree">

      <input type="number"
             class="text-right mg-r-5"
             [ngModel]="lotArticle.quantiteDejaSortie"
             (ngModelChange)="changeQuantiteDejaSortie($event,lotArticle, denree)"
             style="width:75px;height:34px;"
             pKeyFilter="pnum">

      <span class="mg-r-5">{{lotArticle.uniteDeMesure}}</span>
      <span class="genlabel font-10">({{lotArticle.quantiteDejaSortieUT | number:'1.4-4' }} {{denree.uniteTechnique}})</span>

</ng-template>

<!--TEMPLATE QUANTITE DÉJÀ SORTIE DENREE-->
<ng-template #tplQuantiteDejaSortieDenree  let-denree="denree">

 {{denree.quantiteDejaSortieUT | number:'1.4-4' }} {{denree.uniteTechnique}}

</ng-template>

<!--TEMPLATE QUANTITÉ À SORTIR-->
<ng-template #tplQuantiteASortir let-denree="denree" >


  <label class="genlabel mg-r-5">
    <strong>{{denree.quantiteASortir | number:'1.4-4'}} {{denree.uniteTechnique}}</strong>
  </label>
  <i class="fas fa-check new-color" *ngIf="denree.denreeValid"></i>
  <i class="fas fa-times danger-color" *ngIf="!denree.denreeValid"></i>

</ng-template>

<!--TEMPLATE QUANTITÉ EN STOCK DENREE-->
<ng-template #tplQuantiteEnStockDenree let-denree="denree" >

  <div [class.out-of-stock]="denree.epuisee">
    <label class=" mg-r-5">
      <strong>{{denree.quantiteEnStockUT | number:'1.4-4'}} {{denree.uniteTechnique}}</strong>
    </label>
  </div>


</ng-template>


<!--TEMPLATE NUMERO LOT FABRIQUANT-->
<ng-template #tplNumeroLotFabriquant let-lotArticle="lotArticle" let-denree="denree">


  <input type="text"
         class="text-right font-12"
         [(ngModel)]="lotArticle.numeroLotFabriquant"
         style="max-width:120px;height:34px;">


</ng-template>

<!--TEMPLATE CALENDRIER DLC-->
<ng-template #tplDlc let-lotArticle="lotArticle" let-denree="denree">


        <p-calendar
          firstDayOfWeek="1"
          appendTo="body"
          showIcon="true"
          [locale]="fr"
          showDelay="500"
          showButtonBar="true"
          class="vertical-align"
          [(ngModel)]="lotArticle.dlc"
          dateFormat="dd/mm/yy"
          readonlyInput="true"
          [showWeek]="true"
        >
        </p-calendar>


</ng-template>

<!--TEMPLATE QUANTITÉ EN STOCK LOT ARTICLE-->
<ng-template #tplQuantiteEnStockLotArticle let-denree="denree" let-lotArticle="lotArticle" >

  <div [class.out-of-stock]="lotArticle.epuise">

    <span class="mg-r-5 font-12">{{lotArticle.quantiteEnStock }} {{lotArticle.uniteDeMesure}}</span>
    <span class="font-10">({{lotArticle.quantiteEnStockUT  }} {{denree.uniteTechnique}})</span>

  </div>


</ng-template>
