import {Component, OnDestroy, OnInit} from '@angular/core';
import {TokenResolverService, TokenSupplier} from '../../gestion-referentiel/token-resolver.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../core/utils/utils.service';
import {MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {PasswordService} from '../../core/services/mail/password.service';

@Component({
  selector: 'yo-utilisateur-auth',
  templateUrl: './utilisateur-auth.component.html',
  styleUrls: ['./utilisateur-auth.component.scss']
})
export class UtilisateurAuthComponent implements OnInit, OnDestroy {

  userMail: string;
  userPassword: string;
  userPasswordConfirm: string;
  token: string;
  displayDialog = false;

  hide: boolean = true;

  subRoute: Subscription;
  subOpenDialog: Subscription;
  subSendNewPassword: Subscription;

  tokenSupplier: TokenSupplier = null;
  printMessage: boolean = false;

  constructor(private tokenResolverService: TokenResolverService,
              private route: ActivatedRoute,
              private utils: UtilsService,
              private router: Router,
              private auth2Svc: Auth2Service,
              private passwordService: PasswordService) {
  }

  ngOnInit() {

    this.routeSubscription();


    this.utils.setTitle('Changement de mot de passe');
  }

  routeSubscription() {

    this.subRoute = this.route.data
      .subscribe(response => {
        this.tokenSupplier = response.tokenSupplier;
        this.displayDialog = true;
      });
  }

  sendNewPasswordSubscription() {
    this.subSendNewPassword = this.passwordService.sendNewPassword(this.userMail, this.tokenSupplier.token, this.userPassword).subscribe(response => {
      if (response.inError) {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, response.resultMessage, '', 8000);
      } else {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, response.resultMessage, '', 3000);
        this.auth2Svc.redirectToLoginPage();
      }
    });
  }


  sendNewPassword() {
    this.printMessage = true;
    if (this.validation()) {
      this.sendNewPasswordSubscription();
    }
  }

  isBoutonEnvoyerDisabled(): boolean {
    let disabled = this.utils.isNullOrEmpty(this.userMail) ||
      this.utils.isNullOrEmpty(this.userPassword) ||
      this.utils.isNullOrEmpty(this.userPasswordConfirm);
    return disabled
  }

  isPasswordOk(): boolean {
    let ok: boolean = !this.utils.isNullOrEmpty(this.userPassword) &&
      !this.utils.isNullOrEmpty(this.userPasswordConfirm) && this.userPassword === this.userPasswordConfirm && this.userPassword.length >= 10;
    return ok;
  }

  getPasswordErrorMessage(): string {
    if (!(this.userPassword === this.userPasswordConfirm)) {
      return "Mauvaise confirmation de mot de passe."
    }

    if ((this.userPassword.length < 10)) {
      return "Le mot de passe doit être d'au moins 10 caractères";
    }

    return "";
  }

  validation(): boolean {
    return this.isPasswordOk();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subSendNewPassword);
  }

  closeDialog() {
    this.displayDialog = false;
  }
}
