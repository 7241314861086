import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MenuItem} from 'primeng/api';
import {STOCKS_FEATURES} from 'app/core/constants';
import {Subscription} from 'rxjs';
import {ItemNavigationService} from '../core/services/technique/item-navigation.service';

@Component({
  selector: 'yo-gestion-stocks-root',
  templateUrl: './gestion-stocks-root.component.html',
  styleUrls: ['./gestion-stocks-root.component.scss']
})
export class GestionStocksRootComponent implements OnInit {

  subActivePrimaryRoute: Subscription;
  stockFeatures : MenuItem[] = [];
  selectedFeature:MenuItem;

  constructor(private titleSvc:Title,    private itemNavSvc: ItemNavigationService,) { }

  ngOnInit() {
    this.titleSvc.setTitle('Gestion des stocks');
    this.stockFeatures = [
      {label:'Matières premières', icon:'fas fa-cubes', routerLink: [`stocks/${STOCKS_FEATURES.MATIERES_PREMIERES}`]},
      {label:'Mouvements', icon:'fas fa-cubes', routerLink: [`${STOCKS_FEATURES.MOUVEMENTS}/grille-mouvements`]},
      {label:'Inventaire', icon:'fas fa-cubes', routerLink: [`stocks/${STOCKS_FEATURES.INVENTAIRE}`]},
    ];
    this.activePrimaryRouteSubscription();
  }

  /**
   * selectionner l'item actif selon la route demandée
   */
  activePrimaryRouteSubscription() {
    this.subActivePrimaryRoute = this.itemNavSvc.primaryRoute$.subscribe((event) => {
      this.selectedFeature = this.itemNavSvc.activePrimaryRoute(event, this.stockFeatures);
    });
  }

}
