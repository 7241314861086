<dx-data-grid
  [dataSource]="incoherenceList"
  [height]="utils.getWindowAvailableHeight(400)"
  [width]="utils.getWindowAvailableWidth(300)"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #gridIncoherence>

  <dxi-column dataField="id" alignment="center" caption="Actions" [width]="60"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" alignment="left" caption="Unité de production" [width]="200"
              [allowGrouping]="true" [groupIndex]="0">
  </dxi-column>
  <dxi-column dataField="Erreur" alignment="left" caption="Incohérences" [width]="500"
              [allowGrouping]="false" cellTemplate="erreurMessageCellTemplate">
  </dxi-column>
  <dxi-column dataField="Erreur" alignment="left" caption="Processus de résolution" [width]="500"
              [allowGrouping]="false" cellTemplate="erreurDetailCellTemplate">
  </dxi-column>

  <!--  TEMPLATE-->
  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <div class="d-flex ">
      <yo-cell-button (yoNavigation)="resolveIncoherence(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      yoIconClass="fas fa-prescription-bottle-alt"
                      pTooltip="Résoudre l'incohérence"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </div>
  </div>

  <div *dxTemplate="let cell of 'erreurMessageCellTemplate'">
    {{cell.row.data.erreur.message}}
  </div>
  <div *dxTemplate="let cell of 'erreurDetailCellTemplate'">
    {{cell.row.data.erreur.detail}}
  </div>
<!--OPTIONS-->
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
</dx-data-grid>

<hr/>
<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Visualisation des incohérances de configuration</label></div>
  </div>
  <div>
    <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
  </div>
</div>
