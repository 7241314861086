import {ObjectDTO} from './object-dto';

export class ContratMenuConvive__EquipeDTO extends ObjectDTO {

  actif: boolean;

  // unite de production equipe
  idUdpEquipe: number;
  udpEquipeActif: boolean;

  // unite de production
  idUniteDeProduction: number;
  uniteDeProductionLibelle: string;


  // contrat menu convive
  idContratMenuConvive: number;
  contratMenuConviveActif: boolean;
  contratMenuConviveLibelle: string;

  // equipe
  idEquipe: number;
  equipeLibelle: string;


  // zone de production / atelier
  idZoneDeProduction: number;
  zoneDeProductionLibelle: string;

  // tache de fabrication
  tacheLibelle: string;
  tacheActif: boolean;
  tacheId: number;

}
