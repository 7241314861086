<!--<div class="row margin-bottom">-->
<!--  <div class="col-md-3">-->
<!--    <label *ngIf="yoLabel">{{yoLabel}} <span-->
<!--      [class]="yoRequired ?  'required' : '' ">{{yoRequired ? ' *' : ''}}</span> </label>-->
<!--  </div>-->
<!--  <div class="col-md-6">-->
<div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">

  <!--  <label class="mg-r-5 label-size" *ngIf="yoLabel" [class.requiredControl]="yoRequired">{{yoLabel}} : </label>-->
  <label>{{yoLabel}} : </label>


  <input
    class="size-input"
    type="password"
    [required]="yoRequired"
    [(ngModel)]="value"
    pInputText/>
  <!--    <i class="fa fa-info-circle save-color"-->
  <!--       matTooltip="Le champ {{yoLabel | lowercase}} doit être une chaîne de caractères"></i>-->
  <!--  </div>-->
  <!--  <div class="col-md-3">-->
  <!--    <yo-validation [yoLabel]="yoLabel" [yoMinLength]="yoMinLength" [yoMaxLength]="yoMaxLength"-->
  <!--                   [yoFormControl]="yoFormControl"></yo-validation>-->
  <!--  </div>-->
</div>
