<p-dialog
  [(visible)]="displayDialog"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [modal]="true"
  position="top"
  [style]="{'min-width':'70wh'}"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
    <i class="fas fa-plus mg-r-5"></i>
    <span class="mg-l-5">AJOUTER DES POINTS DE LIVRAISON</span>
    </div>
  </ng-template>


  <div class="mg-t-5" [style.max-height]="'calc(80vh)'" style=" overflow-y:auto;overflow-x: hidden">
    <dx-data-grid
      [dataSource]="plcList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(300)"
      [width]="utils.getWindowAvailableWidth(300)"
      (onSelectionChanged)="onChangeSelection($event)"
      [(selectedRowKeys)]="selectedRows"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      [remoteOperations]="true"
      #gridPlc>

      <dxi-column dataField="client.site.libelle" caption="Site" [width]="150"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="client.libelle" caption="Client" [width]="150"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="libelle" caption="Point de livraison" [width]="200"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="code" caption="Code"
                  [width]="200"
                  [allowFiltering]="true">
      </dxi-column>

      <dxo-filter-row
        [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection [mode]="'multiple'"></dxo-selection>
      <dxo-paging [pageSize]="50"></dxo-paging>

      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [showNavigationButtons]="false"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} items">
      </dxo-pager>


    </dx-data-grid>
  </div>
  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
    </div>
    <div>
      <span class="mg-r-5">
        <button pButton icon="fas fa-plus" (click)="addPlc()"
                [disabled]="selectedPlcList.length === 0" pTooltip="Ajouter" tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </span>
      <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
    </div>
  </div>

</p-dialog>
