<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div>
      <i class="fas fa-cubes mg-r-5 mg-l-15"></i> MATIÈRES PREMIÈRES
    </div>
  </ng-template>
  <ng-template pTemplate="right">
    <button pButton icon="fas fa-trash"
            class="mg-r-15"
            label="Supprimer les lots périmés"
            showDelay="1000"
            pTooltip="Supprimer les lots périmés. Inactif, si le champ 'Périmé le' n'est pas renseigné."
            (click)="openDialogPerimes()">
    </button>
  </ng-template>
</p-toolbar>

<p-table
  [value]="matieresPremieres"
  [columns]="cols"
  [responsive]="true"
  [scrollable]="true"
  [rows]="50"
  [rowsPerPageOptions]="[5,10,15,20,50,100,200,500]"
  [paginator]="true"
  [scrollHeight]="utils.scrollHeightContent(635)"
  styleClass="p-datatable-sm"
  [totalRecords]="totalDenrees"
  (onLazyLoad)="lazyLoad($event)"
  selectionMode="single"
  (onRowSelect)="onRowSelect($event)"
  [(selection)]="selectedMatierePremiere"
  [lazy]="true"
  dataKey="id"
  #dt>

  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
        <col *ngSwitchCase="'action'" style="width:80px">
        <col *ngSwitchCase="'libelle'">
        <col *ngSwitchCase="'code'" >
        <col *ngSwitchCase="'quantiteEnKilo'" >
        <col *ngSwitchCase="'pump'" >
        <col *ngSwitchCase="'totalHT'" >
      </ng-container>
    </colgroup>
  </ng-template>

  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">
        <ng-container *ngSwitchCase="'action'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'libelle'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'code'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'quantiteEnKilo'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'pump'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'totalHT'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
    <tr *ngIf="rowData.noStock" [pSelectableRow]="rowData" class="no-stock">
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
        <td *ngSwitchCase="'action'" style="text-align: left">
                  <span class="mg-r-5">
                      <button pButton icon="fas fa-plus" class="p-button-secondary" showDelay="1000"
                              pTooltip="Ajout d'un nouveau lot" (click)="openDialogAjoutLot(rowData)"></button>
                  </span>
        </td>
        <td *ngSwitchCase="'libelle'" style="text-align: left">
          {{rowData.libelle}}
        </td>
        <td *ngSwitchCase="'code'" style="text-align: left">
          {{rowData.code}}
        </td>
        <td *ngSwitchCase="'quantiteEnKilo'" [attr.colspan]="3" style="text-align: left">
          <i class="fas fa-ban danger-color mg-r-5"></i> Pas de stock
        </td>
      </ng-container>
    </tr>
    <tr *ngIf="!rowData.noStock" [pSelectableRow]="rowData" class="stock cursor">
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
        <td *ngSwitchCase="'action'" style="text-align: left">
                   <span class="mg-r-5">
                      <button pButton icon="fas fa-plus" class="p-button-secondary" showDelay="1000"
                              pTooltip="Ajout d'un nouveau lot" (click)="openDialogAjoutLot(rowData)"></button>
                  </span>
        </td>
        <td *ngSwitchCase="'libelle'" style="text-align: left">
          {{rowData.libelle}}
        </td>
        <td *ngSwitchCase="'code'" style="text-align: left">
          {{rowData.code}}
        </td>
        <td *ngSwitchCase="'quantiteEnKilo'" style="text-align: right">
          <span class="mg-r-5">{{rowData.quantiteEnKilo | number:'1.4-4'}}</span>
          <ng-container *ngIf="!utils.isCollectionNullOrEmpty(rowData.articlesSansUniteDeStockage)">
            <i class="fas fa-exclamation-triangle danger-color" [escape]="false"
               [pTooltip]="getNoUniteDeStockTooltip(rowData)"></i>
          </ng-container>
        </td>
        <td *ngSwitchCase="'pump'" style="text-align: right;">
          {{rowData.pump  | currency:'EUR':'symbol':'1.4-4' }}
        </td>
        <td *ngSwitchCase="'totalHT'" style="text-align: right;">
          {{rowData.totalHT  | currency:'EUR':'symbol':'1.4-4' }}
        </td>
      </ng-container>
    </tr>
  </ng-template>

  <!--PAGINATOR-->
  <ng-template pTemplate="paginatorright" let-state>
  </ng-template>

  <!--SUMMARY-->
  <ng-template pTemplate="summary">
    <tr>
      <td style="text-align:left;">
        {{utils.getTotalRecordsLabel('denrée', totalDenrees)}}
    </tr>
  </ng-template>

</p-table>

<div class="row mg-t-20 mg-l-0" id="wrapper-lots">
  <div [style.width.px]="utils.getScreenAvailableWidth(395)">
    <yo-lots [stocks]="stocks" [matierePremiere]="selectedMatierePremiere" [ssw]="ssw"></yo-lots>
  </div>
</div>


<yo-dialog-perimes></yo-dialog-perimes>
<yo-dialog-lot-ajout></yo-dialog-lot-ajout>
<yo-dialog-sortie></yo-dialog-sortie>
