import {ObjectDTO} from './object-dto';
import {ProduitArticleDTO} from './produit-article-dto';
import {UnitedemesureDTO} from './unitedemesure-dto';
import {LotMarchePdLumpCaDto} from './gerstion-marche/lot-marche-pd-lump-ca-dto';
import {FournisseurDTO} from './fournisseur-d-t-o';
import {GroupeAchatCaViewModel} from "../models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";

export class CatalogueAchatDTO extends ObjectDTO {

  caParentId : number;

  // produit declinaison
  produitDeclinaisonLibelle: string;
  produitDeclinaisonCode: string;
  produitDeclinaisonId: number;
  produitDeclinaisonActif: boolean;

  // produit article
  produitArticle: ProduitArticleDTO;
  produitArticleLibelle : string;
  produitArticleReferenceArticle : string;

  // unites de mesure
  uniteDeCommande: UnitedemesureDTO;
  uniteDeFacturation: UnitedemesureDTO;
  uniteDeStockage: UnitedemesureDTO;
  uniteTechnique: UnitedemesureDTO;

  fournisseurOwnerLibelle: string;
  fournisseur : FournisseurDTO;

  // catalogue achat
  prix: number;
  prixPrecedent: number;
  prixMoyen: number;
  referenceArticle: string;
  ratioUniteCommandeUniteBase: number;
  ratioUniteFacturationUniteBase: number;
  ratioUniteStockageUniteBase: number;
  ratioUniteTechnique: number;
  minimumDeCommande: number;
  actif: boolean;
  bio: boolean;
  produitCarne: boolean;
  circuitCourt: boolean;
  codeApi: string;

  prixOrigine: number;

  //Init ligne
  quantite: number;

  // infos associé à un article de type marché
  lmPdUpCa: LotMarchePdLumpCaDto;

  // produit
  produitId: number;

  // Famille produit
  idFamilleProduit: number;
  libelleFamilleProduit: number;

  // prix
  groupeAchatCaList :GroupeAchatCaViewModel[] = [];

}
