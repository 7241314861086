<p-accordion (onOpen)="onOpenMarche($event)">


  <ng-container *ngFor="let pdMarche of produitDeclinaisonMarche.marcheList">
    <p-accordionTab [selected]="pdMarche.id===idActiveMarche">
      <ng-template pTemplate="header">
        Marché : {{pdMarche.libelle | uppercase}}, Période : {{pdMarche.dateDebut | date:'dd/MM/yyyy'}}
        - {{pdMarche.dateFin | date:'dd/MM/yyyy'}}<span
        *ngIf="this.utils.contains(produitDeclinaisonMarche.marcheEnCoursList,pdMarche)">,
        <span class="new-color">EN COURS</span></span>
      </ng-template>


      <ng-container *ngIf="!utils.isNullOrEmpty(marcheIndicateur)">

        <!--        ENGAGEMENT-->
        <h3 class="first">
          ENGAGEMENT POUR <strong>{{produitDeclinaisonMarche.produitDeclinaisonLibelle | uppercase}}
          - {{produitDeclinaisonMarche.produitDeclinaisonCode | uppercase}}</strong>
        </h3>
        <div class="row">
          <div class="col-md-12">

            <ngx-gauge type="full"
                       size="150"
                       cap="round"
                       [escape]="false"
                       [pTooltip]="'Volume à atteindre : '+marcheIndicateur.volumeEngagement+' kg<br/><br/>Volume atteint : '+marcheIndicateur.volumeCourant+' kg'"
                       [thick]="10"
                       [thresholds]="gestionMarcheSvc.getGaugeThreshold(marcheIndicateur.volumeEngagement)"
                       [min]="0"
                       [max]="marcheIndicateur.volumeEngagement"
                       [value]="marcheIndicateur.volumeCourant">
              <ngx-gauge-append>
                {{gestionMarcheSvc.displayGaugeAppend('T', 'kg', marcheIndicateur.volumeCourant)}}
              </ngx-gauge-append>
              <ngx-gauge-label>
                <span [innerHTML]="sanitizeSvc.bypassSecurityTrustHtml(gestionMarcheSvc.displayGaugeLabel(marcheIndicateur.volumeCourant,'Volume','en tonnes'))"></span>
              </ngx-gauge-label>
              <ngx-gauge-value>
                {{gestionMarcheSvc.displayGaugeValue(marcheIndicateur.volumeCourant)}}
              </ngx-gauge-value>
            </ngx-gauge>

            <ngx-gauge type="full"
                       size="150"
                       cap="round"
                       [escape]="false"
                       [pTooltip]="'Montant à atteindre : '+marcheIndicateur.montantEngagement+' €<br/><br/>Montant atteint : '+marcheIndicateur.montantCourant+' €'"
                       [thick]="10"
                       [thresholds]="gestionMarcheSvc.getGaugeThreshold(marcheIndicateur.montantEngagement)"
                       [min]="0"
                       [max]="marcheIndicateur.montantEngagement"
                       [value]="marcheIndicateur.montantCourant">
              <ngx-gauge-append>
                {{gestionMarcheSvc.displayGaugeAppend('K €', '€', marcheIndicateur.montantCourant)}}
              </ngx-gauge-append>
              <ngx-gauge-label>
                <span [innerHTML]="sanitizeSvc.bypassSecurityTrustHtml(gestionMarcheSvc.displayGaugeLabel(marcheIndicateur.montantCourant,'Montant','en kilo euros'))"></span>
              </ngx-gauge-label>
              <ngx-gauge-value>
                {{gestionMarcheSvc.displayGaugeValue(marcheIndicateur.montantCourant)}}
              </ngx-gauge-value>
            </ngx-gauge>
          </div>
        </div>


        <!--        LOTS MARCHES-->
        <div class="mg-t-20">
          <h3 class="first">ARTICLES AU MARCHÉ</h3>
          <dx-data-grid
            id="gridArticleLotMarche"
            [dataSource]="marcheIndicateur.articleIndicateurList"
            [showBorders]="true"
            [showRowLines]="true"
            [height]="dxSvc.getHeight(3)"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [rowAlternationEnabled]="true"
            #gridArticleLotMarche>

            <dxi-column dataField="produitArticleLibelle" caption="Article" [width]="240"></dxi-column>

            <dxi-column dataField="catalogueAchatFiliale" caption="Fournisseur"
            ></dxi-column>

            <dxi-column dataField="catalogueAchatSecteur" caption="Secteur"
            ></dxi-column>


            <dxi-column dataField="lotMarcheLibelle" caption="Lot Marché"
                        cellTemplate="lotMarcheCellTemplate"
            ></dxi-column>

            <dxi-column dataField="catalogueAchatPrix" caption="Prix"
                        cellTemplate="prixAchatCellTemplate"
            ></dxi-column>

            <dxi-column dataField="id" caption="Répartition"
                        cellTemplate="repartitionCellTemplate"
                        headerCellTemplate="repartitionHeaderCellTemplate"
            ></dxi-column>


            <!--  COLUMN CHOOSER-->
            <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

            <!--  EXCEL EXPORT-->
            <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>



            <!--  RECHERCHE FRONT-->
            <dxo-search-panel [visible]="true"></dxo-search-panel>

            <!--  FILTERING-->
            <dxo-header-filter
              [visible]="true"></dxo-header-filter>

            <!--  SORTING-->
            <dxo-sorting mode="multiple"></dxo-sorting>

            <!--  GROUPING-->
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>


            <!--  MARCHE CELL TEMPLATE-->
            <div *dxTemplate="let cell of 'marcheCellTemplate'">
              <div class="no-overflow">
                <label class="font-12" pTooltip="{{cell.row.data.marcheLibelle}}">
                  {{cell.row.data.marcheLibelle | uppercase}}
                </label>
                <br/>
                <span class="font-10 cursor" pTooltip="code du marché"><i
                  class="fas fa-barcode mg-r-5"></i>{{cell.row.data.marcheCode | uppercase}}</span>
              </div>
            </div>

            <!--  LOT MARCHE CELL TEMPLATE-->
            <div *dxTemplate="let cell of 'lotMarcheCellTemplate'">
              <div class="no-overflow">
                <label class="font-12" pTooltip="{{cell.row.data.lotMarcheLibelle}}">
                  {{cell.row.data.lotMarcheLibelle | uppercase}}
                </label>
                <br/>
                <span class="font-10 cursor" pTooltip="code du lot marché"> <i
                  class="fas fa-barcode mg-r-5"></i> {{cell.row.data.lotMarcheCode | uppercase}}</span>
                <br/>
                <span class="font-10 cursor" pTooltip="période"> <i
                  class="fas fa-calendar mg-r-5"></i> {{cell.row.data.lotMarcheDateDebut   | date:'dd/MM/yyyy'}}
                  -  {{cell.row.data.lotMarcheDateFin   | date:'dd/MM/yyyy'}}</span>
              </div>
            </div>


            <!-- PRIX ACHAT CELL TEMPLATE-->
            <div *dxTemplate="let cell of 'prixAchatCellTemplate'">
              {{cell.row.data.catalogueAchatPrix  | currency:'EUR':'symbol':'1.4-4'}}
              / {{cell.row.data.catalogueAchatUFAbreviation}}
            </div>

            <!-- REPARTITION HEADER CELL TEMPLATE-->
            <div *dxTemplate="let cell of 'repartitionHeaderCellTemplate'">
              <div tooltipPosition="top" pTooltip="Répartition des volumes et montants entre les articles au marché">
                {{cell.column.caption}}
              </div>
            </div>

            <!-- REPARTITION CELL TEMPLATE-->
            <div *dxTemplate="let cell of 'repartitionCellTemplate'">
              <ngx-gauge type="semi"
                         size="80"
                         cap="round"
                         [thick]="6"
                         [thresholds]="gestionMarcheSvc.getGaugeThreshold(getRepartitionVolumeTotal(marcheIndicateur))"
                         [min]="0"
                         [max]="getRepartitionVolumeTotal(marcheIndicateur)"
                         [value]="cell.row.data.volumeCourant">
                <ngx-gauge-append>
                  {{gestionMarcheSvc.displayGaugeAppend('T', 'kg', cell.row.data.volumeCourant)}}
                </ngx-gauge-append>
                <ngx-gauge-label>
                  <span [innerHTML]="sanitizeSvc.bypassSecurityTrustHtml(gestionMarcheSvc.displayGaugeLabel(cell.row.data.volumeCourant,'Volume','en tonnes'))"></span>
                </ngx-gauge-label>
                <ngx-gauge-value>
                  {{gestionMarcheSvc.displayGaugeValue(cell.row.data.volumeCourant)}}
                </ngx-gauge-value>
              </ngx-gauge>

              <ngx-gauge type="semi"
                         size="80"
                         cap="round"
                         [thick]="6"
                         [thresholds]="gestionMarcheSvc.getGaugeThreshold(getRepartitionMontantTotal(marcheIndicateur))"
                         [min]="0"
                         [max]="getRepartitionMontantTotal(marcheIndicateur)"
                         [value]="cell.row.data.montantCourant">
                <ngx-gauge-append>
                  {{gestionMarcheSvc.displayGaugeAppend('K €', '€', cell.row.data.montantCourant)}}
                </ngx-gauge-append>
                <ngx-gauge-label>
                  <span [innerHTML]="sanitizeSvc.bypassSecurityTrustHtml(gestionMarcheSvc.displayGaugeLabel(cell.row.data.montantCourant,'Montant','en kilo euros'))"></span>
                </ngx-gauge-label>
                <ngx-gauge-value>
                  {{gestionMarcheSvc.displayGaugeValue(cell.row.data.montantCourant)}}
                </ngx-gauge-value>
              </ngx-gauge>
            </div>
          </dx-data-grid>
        </div>

      </ng-container>


    </p-accordionTab>
  </ng-container>

</p-accordion>

