<ng-container *ngIf="!utils.isNullOrEmpty(bonReceptionLigne)">

  <ng-container *ngIf="hasLitigeOrMnc(bonReceptionLigne)">

    <i class="fas fa-exclamation-triangle danger-color"
       pTooltip="Il y a une non conformité ou un litige sur cet article" showDelay="500"></i>

  </ng-container>


</ng-container>

<ng-container *ngIf="!utils.isNullOrEmpty(bonReception)">

  <ng-container *ngIf="bonReception.hasMncOrLitige">

    <i class="fas fa-exclamation-triangle danger-color"
       pTooltip="Il y a une non conformité ou un litige sur ce bon de réception" showDelay="500"></i>
  </ng-container>

</ng-container>
