import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';

@Component({
  selector: 'yo-site',
  template: `
    <ng-container [ngSwitch]="SITE_TYPE_VALUE">
      <ng-container *ngSwitchCase="SITE_TYPE.LOCAL">
        <span pTooltip="SITE LOCAL {{siteLibelleTooltip}}" showDelay="1500" class="mg-r-5"> <i
          class="fas fa-map-marker site-principal-color"></i> {{siteLibelle}}</span>
      </ng-container>
      <ng-container *ngSwitchCase="SITE_TYPE.REFERENT">
        <span pTooltip="SITE RÉFÉRENT {{siteLibelleTooltip}}" showDelay="1500" class="mg-r-5"> <i
          class="fas fa-map-marker site-secondaire-color"></i> {{siteLibelle}}</span>
      </ng-container>
      <ng-container *ngSwitchCase="SITE_TYPE.NONE">
        <span pTooltip="SITE ETRANGER {{siteLibelleTooltip}}" showDelay="1500" class="mg-r-5"> <i
          class="fas fa-map-marker site-etranger-color"></i> {{siteLibelle}}</span>
      </ng-container>
    </ng-container>

  `,
  styleUrls: ['./site.component.scss'],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class SiteComponent implements OnInit, OnDestroy {

  SITE_TYPE_VALUE = SITE_TYPE.NONE;
  SITE_TYPE = SITE_TYPE;

  @Input() set siteId(value: number) {

    if (this.authUser.isSiteLocal(value)) {
      this.SITE_TYPE_VALUE = SITE_TYPE.LOCAL;
    } else if (this.authUser.isSiteReferent(value)) {
      this.SITE_TYPE_VALUE = SITE_TYPE.REFERENT;
    } else {
      this.SITE_TYPE_VALUE = SITE_TYPE.NONE;
    }
  }

  @Input() siteLibelleTooltip;
  @Input() siteLibelle;

  @Input() yoDelayMsTooltip;

  subUser: Subscription;


  constructor(private utils: UtilsService, public authUser: Auth2Service, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subUser = this.authUser.user$.subscribe(user => this.cd.markForCheck());
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subUser);
  }

}

export enum SITE_TYPE {
  LOCAL = 'LOCAL',
  REFERENT = 'REFERENT',
  NONE = 'NONE',
}
