import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'yo-base-production',
  templateUrl: './base-production.component.html',
  styleUrls: ['./base-production.component.scss']
})
export class BaseProductionComponent implements OnInit {
  dialogTitle: string;

  _datesConso: SelectItem[];

  @Input() displayDialog: boolean;
  @Output() onClose = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    console.log("BaseProductionComponent.ngOnInit displayDialog = " + this.displayDialog);
  }

}
