import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ConditionnementVarianteDTO from '../../dtos/conditionnement/conditionnement-variante-dto';
import VarianteDTO from '../../dtos/conditionnement/variante-dto';
import {HttpParams} from "@angular/common/http";

export const URL_GET_ALL_BY_ID_MCP = `dolrest/conditionnements/conditionnements-variantes/listByIdMcp`;
export const URL_GET_ALL = `dolrest/conditionnements/conditionnements-variantes/list`;
export const URL_SAVE = `dolrest/conditionnements/conditionnements-variantes/save`;
export const URL_DELETE = `dolrest/conditionnements/conditionnements-variantes/delete`;

@Injectable({
  providedIn: 'root'
})
export class ConditionnementsVarianteService {

  private subjectOpenDialogConditionnementVariante = new Subject<VarianteDTO>();
  openDialogConditionnementVariante$ = this.subjectOpenDialogConditionnementVariante.asObservable();

  private subjectConditionnementVarianteSaved = new Subject<ConditionnementVarianteSavedSupplier>();
  conditionnementVarianteSaved$ = this.subjectConditionnementVarianteSaved.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les conditionnements variantes en fonction d'un identifiant
   * de modèle de conditionnement de plat
   * @returns {Observable<ConditionnementVarianteDTO>}
   */
  getAllByIdMcp = (idMcp: number): Observable<ResponseWrapper<ConditionnementVarianteDTO>> => this.httpSvc.get(`${URL_GET_ALL_BY_ID_MCP}/${idMcp}`, null);

  /**
   * Récupérer tous les conditionnements variantes
   * @returns {Observable<ConditionnementVarianteDTO>}
   */
  getAll = (): Observable<ResponseWrapper<ConditionnementVarianteDTO>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde une association
   * @param conditionnementVariante
   */
  save = (conditionnementVariante: ConditionnementVarianteDTO[]) => this.httpSvc.post(URL_SAVE, conditionnementVariante);

  /**
   * Supprime un ou plusieurs conditionnement(s) variantes en fonction de
   * leurs identifiants.
   * @param cvIdsToDelete
   */
  deleteByIds = (cvIdsToDelete: number[]): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", cvIdsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

  announceOpenDialogConditionnementVariante = (param: ConditionnementVarianteDTO) => {
    this.subjectOpenDialogConditionnementVariante.next(param);
  };

  announceConditionnementVarianteSaved = (conditionnementsVariantes: ConditionnementVarianteDTO[], isUpdate: boolean = false) => {
    this.subjectConditionnementVarianteSaved.next({ isUpdate, conditionnementsVariantes } as ConditionnementVarianteSavedSupplier);
  };

}

export interface ConditionnementVarianteSavedSupplier {
  isUpdate: boolean;
  conditionnementsVariantes: ConditionnementVarianteDTO[];
}

