<div
  *ngIf="loading$ | async"
  class="loading-button"
     pTooltip="chargement en cours..."
     style="z-index:999999;">
  <dx-load-indicator
    id="large-indicator"
    height="80"
    width="80"
  ></dx-load-indicator>
</div>
