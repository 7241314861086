import {Component, OnInit, ViewChild} from "@angular/core";
import {OrigineService} from "../../core/services/entities/origines-service";
import {OrigineDTO} from "../../core/dtos/origine-dto";
import CustomStore from "devextreme/data/custom_store";
import {GenericDatagridService} from "../../core/services/generics/generic-datagrid.service";
import {UtilsService} from "../../core/utils/utils.service";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {Subscription} from "rxjs";
import {DxDataGridComponent} from "devextreme-angular";
import {ConfirmationService} from "primeng/api";
import {confirm} from 'devextreme/ui/dialog';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";

@Component({
  selector: 'yo-origine-grid',
  templateUrl: './origine-grid.component.html',
  styleUrls: ['./origine-grid.component.scss']
})
export class OrigineGridComponent implements OnInit {

  @ViewChild('grid') grid: DxDataGridComponent;

  subRefreshDialogListPlatGrid: Subscription;
  origineList: OrigineDTO[] = [];
  customStore: CustomStore;
  subOrigines: Subscription;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/origines';

  constructor(public origineSvc: OrigineService,
              public utils: UtilsService,
              private gds: GenericDatagridService,
              private confirmationService: ConfirmationService,
              public auth2Svc: Auth2Service) {
  }

  ngOnInit(): void {
    this.initOriginesSubscription();
    this.initRefreshList();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOrigines);
    this.utils.unsubscribe(this.subRefreshDialogListPlatGrid);
  }

  initOriginesSubscription = (): void => {
    this.subOrigines = this.origineSvc.findAll().subscribe(response => {
      this.origineList = response.resultList
    });
  };

  initRefreshList = (): void => {
    this.subRefreshDialogListPlatGrid = this.origineSvc.refreshDialogListPlatGrid$.subscribe((response) => {
      this.initOriginesSubscription();
    });
  };

  createOrigine = (): void => {
    this.origineSvc.announceOpenDialogOrigine(this.origineSvc.createEmptyDTO());
  };

  openEditObject = (origine: OrigineDTO): void => {
    this.origineSvc.announceOpenDialogOrigine(origine);
  };

  openDeleteObject = async (origine: OrigineDTO) => {
    let res = await confirm(`Êtes vous sûr de vouloir supprimer cette origine`, `Supprimer une origine`)

    if (res)
      this.origineSvc.deleteOrigine(origine.id).subscribe(() => {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,  `Origine ${origine.code} a bien été supprimée.`);
        this.origineSvc.announceRefreshOrigineListGrid(true);
      });
  };
}
