import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ConviveDTO} from '../../dtos/convive-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {SiteDTO} from '../../dtos/site-dto';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";

export const URL_GET_ALL = `dolrest/prestations/search`;

@Injectable()
export class ConvivesService extends GenericHandler<ConviveDTO> {

  convivesEnvironnement: ConviveDTO[];

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private httpSvc: HttpService,
              private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });
  }

  findAll(): Observable<ResponseWrapper<ConviveDTO>> {
    return this.httpSvc.post(`${URL_GET_ALL}?size=999`, new SearchSupplierWrapper());
  }

  // Toutes les fonctions en-dessous ne sont pas à leurs places :

  getTotalRecordsLabel(): string {
    return 'prestation';
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getTitle(): string {
    return 'GESTION DES PRESTATIONS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE PRESTATION';
  }

  getEntityName(): string {
    return 'convive';
  }

  getFields(dto: ConviveDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut un Convive est actif.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new ConviveDTO();
      // dto.site = this.auth2Svc.utilisateur.sitePrincipal as SiteDTO;
      dto.actif=true;
    }

    // const isSiteLocal = this.auth2Svc.isSiteLocal(dto.site.id);

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        excelType:'string',
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        excelType:'string',
        maxLength: 50,
        required: true,
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        excelType:'boolean',
        required: false,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {
    console.log("ConvivesService#getAllFromEnvironnement()");
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.convivesEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.convivesEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.convivesEnvironnement);

      });
  }

  createEmptyDTO(): ConviveDTO {
    let convive = new ConviveDTO();
    convive.site = new SiteDTO();
    // convive.site.libelle = this.auth2Svc.utilisateur.sitePrincipal.libelle;
    // convive.site.id = this.auth2Svc.utilisateur.sitePrincipal.id;
    return convive;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA PRESTATION '${data.libelle.toUpperCase()}'`;
  }

}
