<!--ICONES MENUS-->
<div class="root">

  <ng-container *ngFor="let item of itemNavSvc.menuItems;  index as i;">

    <ng-container
      *ngTemplateOutlet="tplItemFlMenu;context:{item:item,index:i}">
    </ng-container>

  </ng-container>

  <p-tieredMenu appendTo="body" #menu   [popup]="true"  [model]="subMenuItems"></p-tieredMenu>

</div>

<!--ICONE VERSION-->
<div class="mg-l-14">
  <yo-infos-version [displayLabel]="false" class="site-secondaire-color"></yo-infos-version>
</div>


<!--TEMPLATE ICON MENU FIRST LEVEL-->
<ng-template #tplItemFlMenu let-item="item" let-index="index">

  <!--        HACK PRIMENG pour positionner le menu en haut à droite de l'élément-->
  <div [attr.id]="'submenudolapp'+index" (click)="openRealMenu(item,menu,$event)">
  </div>


  <div
    [class.selected]="itemNavSvc.selectedFlMenuItem===item "
    [class.pre-selected]="itemNavSvc.newFlMenuItem===item "
    (click)="openMenu('submenudolapp'+index,menu)"
    [pTooltip]="item.label"
  >
    <i [attr.class]="item.icon+' item-icon cursor zoom'"></i>
  </div>


</ng-template>



