import {Injectable} from '@angular/core';
import {MSG_KEY, MSG_SEVERITY} from "../../constants";
import {ObjectDTO} from "../../dtos/object-dto";
import {GenericDatagridService} from "./generic-datagrid.service";
import {UtilsService} from "../../utils/utils.service";
import {RoutemapService} from "../routemap.service";
import {Auth2Service} from "../security/auth2.service";
import {Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ConfirmationService} from "primeng/api";
import {GenericFormService} from "./generic-form.service";

@Injectable()
export class GenericManageDialogService {

  private subjectDeleted = new Subject<ResponseWrapper<number>>();
  deleted$ = this.subjectDeleted.asObservable();


  constructor(
    private auth2Svc: Auth2Service,
    private utils: UtilsService,
    private routeMapSvc: RoutemapService,
    private confirmationSvc: ConfirmationService,
    private gfs: GenericFormService,
    public gds: GenericDatagridService) {
  }

  openEdit(dto: ObjectDTO, startRoute: string) {
    let id = 0;
    if (!this.utils.isNullOrEmpty(dto)) {
      id = dto.id;
    }
    this.routeMapSvc.goToSecondaryRoute([startRoute, id, 'ficheidentite']);

  }

  /**
   * Suppression des éléments sélectionnés, une confirmation est demandée
   * @param selectedValues
   */
  deleteValues(values: ObjectDTO[], totalRecords: number, selectedValues: ObjectDTO[], entityName: string) {
    this.confirmationSvc.confirm({
      message: `Êtes vous sûr de vouloir supprimer les éléments sélectionnés ?`,
      accept: () => {


        const ids = selectedValues.map(sv => sv.id);
        this.gfs.deleteList(entityName, ids).subscribe(response => {


          const idsNotDeleted = response.additionalProperties['idsNotDeleted'];
          const idsDeleted = response.additionalProperties['idsDeleted'];


          const eltsNotDeleted = this.gds.mapObjectsFromIds(idsNotDeleted, selectedValues);
          if (!this.utils.isCollectionNullOrEmpty(eltsNotDeleted)) {

            const labels = eltsNotDeleted.map(elt => elt.libelle).join(', ');

            this.utils.showMsg(MSG_KEY.GENERIC_PN, MSG_SEVERITY.ERROR, `Impossible de supprimer les éléments suivants: ${labels}`, '', 8000);

          }


          const eltsDeleted = this.gds.mapObjectsFromIds(idsDeleted, selectedValues);
          if (!this.utils.isCollectionNullOrEmpty(eltsDeleted)) {

            const labels = eltsDeleted.map(elt => elt.libelle).join(', ');

            this.utils.showMsg(MSG_KEY.GENERIC_PN, MSG_SEVERITY.SUCCESS, `Les éléments suivants ont été supprimés : ${labels}`);

            this.gds.deleteGridRows(values, eltsDeleted);
            totalRecords = totalRecords - eltsDeleted.length;

          }


        });
      }
    });
  }
}
