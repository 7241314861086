<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [breakpoint]="640"
  [style]="{'width':'1200px'}"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <ng-container *ngTemplateOutlet="tplDialogHeader;context:{bonCf:bonCf}"></ng-container>
    </div>
  </ng-template>

  <p-scrollPanel [style]="getScrollPanelStyle()">

    <dx-data-grid
      [dataSource]="mails"
      keyExpr="id"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="statut" width="180" caption="Etat" cellTemplate="templateStatut" alignement="left"></dxi-column>
      <dxi-column dataField="emitterNom" caption="Emetteur" cellTemplate="templateEmitterNom" alignement="left"></dxi-column>
      <dxi-column dataField="receiver" caption="Destinataire" cellTemplate="templateReceiver" alignement="left"></dxi-column>
      <dxi-column dataField="sentDate" width="150" caption="Date d'envoi" cellTemplate="templateSentDate" alignement="left"></dxi-column>
      <dxi-column dataField="readDate" width="150" caption="Date de lecture" cellTemplate="templateReadDate" alignement="left"></dxi-column>
      <dxi-column dataField="erreur" width="150" caption="Statut du mail" cellTemplate="templateErreur" alignement="left"></dxi-column>

      <div *dxTemplate="let cell of 'templateSentDate'">
        {{cell.row.data.sentDate  | date:'dd/MM/yyyy HH:mm:ss'}}
      </div>

      <div *dxTemplate="let cell of 'templateReadDate'">
        <ng-container *ngIf="cell.row.data.readDate">
          {{cell.row.data.readDate  | date:'dd/MM/yyyy HH:mm:ss'}}
        </ng-container>
      </div>

      <div *dxTemplate="let cell of 'templateStatut'" class="text-center">
        <label
          showDelay="1000"
          class="badge mg-r-5 font-14 cursor"
          [class.badge-warning]="cell.row.data.statut === BONCF_MAIL_STATUT.EN_COURS_ENVOI"
          [class.badge-primary]="cell.row.data.statut === BONCF_MAIL_STATUT.ENVOYE"
          [class.badge-success]="cell.row.data.statut === BONCF_MAIL_STATUT.LU"
          [class.badge-danger]="cell.row.data.statut === BONCF_MAIL_STATUT.EN_ERREUR"
        >
          {{mailHistoSvc.getLabelStatut(cell.row.data.statut)}}
        </label>
      </div>

      <div *dxTemplate="let cell of 'templateEmitterNom'" class="wrap-text">
        {{cell.row.data.emitterNom | titlecase}} {{cell.row.data.emitterPrenom | titlecase}}
        - {{cell.row.data.emitterEmail | lowercase}}
      </div>

      <div *dxTemplate="let cell of 'templateReceiver'" class="wrap-text">
        {{cell.row.data.receiver | lowercase}}
      </div>

      <div *dxTemplate="let cell of 'templateErreur'">
        <ng-container *ngIf="utils.isNullOrEmpty(cell.row.data.erreur)">
          <yo-check [checked]="true"></yo-check>
          Aucune erreur
        </ng-container>
        <ng-container *ngIf="!utils.isNullOrEmpty(cell.row.data.erreur)">
          <yo-check [checked]="false"></yo-check>
          {{cell.row.data.erreur}}
        </ng-container>
      </div>

    </dx-data-grid>

  </p-scrollPanel>

  <!--  FOOTER-->
  <ng-template pTemplate="footer">

    <!--    BOUTONS D'ACTIONS-->
    <ng-container *ngTemplateOutlet="tplActionButtons"></ng-container>

  </ng-template>

</p-dialog>

<!--TEMPLATE DES BOUTONS D'ACTION-->
<ng-template #tplActionButtons>
  <button type="button" pButton
          class="p-button-secondary"
          icon="fas fa-times" (click)="closeDialog()" pTooltip="Fermer"
          showDelay="500"></button>
</ng-template>

<!--TEMPLATE HEADER DIALOG-->
<ng-template #tplDialogHeader let-bonCf="bonCf">

  <ng-container *ngIf="bonCf">

    <i class="fas fa-mail-bulk mg-r-5"></i>Historique des mails pour la commande {{bonCf?.numeroBcf}}

  </ng-container>


</ng-template>
