import {Component, OnInit} from '@angular/core';
import {EquipesService} from '../../core/services/entities/equipes.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-equipes',
  templateUrl: './equipes.component.html',
  styleUrls: ['./equipes.component.scss']
})
export class EquipesComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/equipes';

  constructor(public equipeSvc:EquipesService) { }

  ngOnInit() {
  }

}
