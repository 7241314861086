import {Component, Input, OnInit} from "@angular/core";
import {ModelViewInventaire} from "../model-view-inventaire";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {UtilsService} from "../../../../core/utils/utils.service";
import {StocksService} from "../../../../core/services/gestion-stock/stocks.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'yo-dialog-confirm-save',
  templateUrl: './dialog-confirm-save.component.html',
  styleUrls: ['./dialog-confirm-save.component.scss']
})
export class DialogConfirmSaveComponent implements  OnInit {

  @Input() public lignesInventaire: ModelViewInventaire[];

  public displayDialog: boolean;
  public isKeepItClose: boolean = false;
  private subOpenDialogConfirmSave :Subscription;

  constructor(private utilsService :UtilsService,
              private stocksService: StocksService) {}

  public ngOnInit(){
    this.subOpenDialogConfirmSave = this.stocksService.openDialogConfirmSave$.subscribe((response:boolean) => {
      this.displayDialog = response;
    });
  }

  public closeDialog(){
    this.displayDialog = false;
    this.stocksService.annonceIsConfirmCancelled(true);
  }

  public confirm = () => {
    this.stocksService.saveInventaire(this.lignesInventaire).subscribe(response => {
      if (!this.utilsService.isResponseSupplierError(response)) {
        this.utilsService.showMsg(MSG_KEY.ROOT,MSG_SEVERITY.SUCCESS, `Modifications enregistrées.`);
        this.stocksService.annonceIsConfirmCancelled(false);
      }
    });
    this.displayDialog = false;
  };

  public onChangeIsKeepOpenable = () => {
    this.stocksService.announceIsKeepItClose(this.isKeepItClose);
  };

}
